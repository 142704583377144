import React, { useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ApiKey, ApiUrl, WebUrl, TransactionType, TransactionStatus, LanguageKey, AccessRight } from "../../util/Constant";
import ApiEngine from "../../util/ApiEngine"
import { setBusy, setIdle, showMessage } from '../../redux/AppAction';

import SweetAlert from "react-bootstrap-sweetalert";
import { checkIfPermissionExist } from "../../util/PermissionChecker";
import { Panel, PanelBody } from "./../../components/panel/panel.jsx";
import PromotionDetail from "../configuration/PromotionDetail";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import queryString from 'query-string';

const MemberPromotionStatement = props => {
  let _history = useHistory();
  const { t, i18n } = useTranslation(LanguageKey._PRIMARY);
  const [promoData, setPromoData] = useState([]);
  const _dispatch = useDispatch();
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [activeFlag, setaAtiveFlag] = useState("");
  let role1IsAllowed = checkIfPermissionExist(
    AccessRight._RESET_ROLLOVER_PERMISSION,
    true
  );
  const [isRefund, setIsRefund] = useState(false);

  async function cancelPromo(status) {
    if (status == "Active") {
      var responseJson = await ApiEngine.post(ApiUrl._API_CANCEL_PROMO + "?memberId=" + props.id + "&refund=" + isRefund);
      if (responseJson[ApiKey._API_SUCCESS_KEY]) {
        _dispatch(showMessage(responseJson[ApiKey._API_SUCCESS_KEY], t(responseJson[ApiKey._API_MESSAGE_KEY])));
        getBonusData();
      }
      else {
        _dispatch(showMessage(responseJson[ApiKey._API_SUCCESS_KEY], t(responseJson[ApiKey._API_MESSAGE_KEY])));
        getBonusData();
      }
    }
  }

  /// <summary>
  /// Author : -
  /// </summary>
  useEffect(() => {
    getBonusData();
  }, [])

  /// <summary>
  /// Author : -
  /// </summary>
  async function getBonusData() {
    var responseJson = await ApiEngine.get(ApiUrl._API_GET_MEMBER_PROMOTION + "?memberId=" + props.id);

    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      setPromoData(responseJson[ApiKey._API_DATA_KEY]);
    }
  }


  function TitleTooltip({ children, placement = "top", title = "" }) {
    return (
      <OverlayTrigger
        placement={placement}
        overlay={<Tooltip id={`tooltip-${placement}`} className="tooltip-font-size">{title}</Tooltip>}
      >
        {/* Render the child element */}
        {children}
      </OverlayTrigger>
    );
  }

  // Use JSX inside the TitleTooltip for better formatting
  const tooltipContent = (
    <>
      Notes:<br />
      1. Revert: Cancel the promotion and burn coins. <br />
      2. Revert and Refund: Cancel the promotion, burn coins, and refund the credit used for the promotion to wallet.
    </>
  );

  const revertNotes = (
    <>
      * Important Notes:<br />
      1. Check if the player has purchased Featured Spins using Coins from the Provider BackOffice. DO NOT ALLOW REVERT if player purchased Featured Spins. <br />
      2. Please 1 to 5 minutes to receive the latest Coin Rollover / promotion rollover progress before reverting bonus for player. <br />
      3. All Coins in the Provider Game Wallet will be transferred back to Platform Wallet once we click CONFIRM in "Revert and Refund" pop up.
    </>
  );

  const handleClick = (id) => {
    console.log(id)
    const targetUrl = `${WebUrl._URL_CLAIMED_PROMOTION_DETAIL}?id=${id}`;

    // Open the URL in a new tab
    window.open(targetUrl, '_blank');
  };

  return (
    <>
      {showConfirmation && (
        <SweetAlert
          warning
          showCancel
          confirmBtnText={t("CONFIRM")}
          cancelBtnText={t("CANCEL")}
          confirmBtnBsStyle="danger"
          title={isRefund ? t("ARE_YOU_SURE_TO_CANCEL_THE_PROMO_AND_REFUND") : t("ARE_YOU_SURE_TO_CANCEL_THE_PROMO")}
          onConfirm={() => {
            cancelPromo("Active");
            setShowConfirmation(false);
          }}
          onCancel={() => {
            setShowConfirmation(false);
          }}
        >
          {t("UNABLE_TO_REVERT")}
        </SweetAlert>
      )}
      <Panel>
        <PanelBody>
          <div id="rollover">
            {
              promoData.length > 0 &&
              promoData.map((item) => {
                var promoDetail = item.remark == null ? "" : JSON.parse(item.remark)
                var isAllowRevert = item.rolloverBalance == item.rolloverTarget && item.bonusWallet == item.initialBalance ? true : false;
                return (
                  <div className="card card-rollover mb-2">
                    <table className="table mb-0 table-rollover table-valign-middle" style={{ "borderCollapse": "collapse" }}>
                      <tr>
                        <td style={{ fontSize: "13px", width: "70%" }}>{item["claimTime"]}</td>
                        {promoDetail != "" &&
                          <th>
                            <b>{promoDetail.Name}</b>
                            <div className="btn btn-primary ml-2"
                              onClick={() => {
                                handleClick(item["claimId"])
                              }}
                            >{t("VIEW")}
                            </div>

                          </th>
                        }
                      </tr>
                      <tr>
                        <td>{t("Coins")}</td>
                        <th style={{ paddingRight: "4px", display: "flex" }}>{item["bonusWallet"]} / {item["initialBalance"]}
                          <div
                            className="tool-tip-with-text ml-2"
                          >
                            <TitleTooltip
                              placement="top"
                              title="Current Coins Balance / Initial Coins Balance"
                            >
                              <img
                                src={require("../../../src/assets/img/icon-info.png")}
                                className="img-responsive"
                                alt="icon"
                                style={{ height: "1rem" }}
                              />

                            </TitleTooltip>
                          </div>

                        </th>
                      </tr>
                      <tr>
                        <td>{t("ROLLOVER_PROGRESS")}</td>
                        <th style={{ paddingRight: "4px" }}>{item["isCleared"] == 0 ? item["rolloverTarget"] - item["rolloverBalance"] : item["rolloverTarget"]} / {item["rolloverTarget"]}</th>
                      </tr>
                      {item["transactionId"] &&
                        <tr>
                          <td>{t("TRANSACTION_ID")}</td>
                          <th style={{ paddingRight: "4px" }}>{item["transactionId"]}</th>
                        </tr>
                      }
                      <tr>
                        <td><b>{!item.isCleared ? revertNotes : ""}</b></td>
                        <th style={{ paddingRight: "4px" }}>
                          <div style={{ display: "flex" }}>
                            {!item.isCleared &&
                              <button
                                type="button"
                                className="btn btn-danger"
                                onClick={() => {
                                  setIsRefund(false);
                                  setShowConfirmation(true)
                                }}

                              >
                                {t("Revert")}
                              </button>
                            }

                            {!item.isCleared &&
                              <button
                                type="button"
                                className="btn btn-danger ml-3"
                                onClick={() => {
                                  setIsRefund(true);
                                  setShowConfirmation(true)
                                }}
                              >
                                {t("Revert and Refund")}
                              </button>
                            }

                            {item.isCleared &&
                              <span className="text-approved font-bold">Cleared</span>
                            }

                            <div
                              className="tool-tip-with-text ml-2"
                            >
                              <TitleTooltip
                                placement="top"
                                title={tooltipContent}
                              >
                                <img
                                  src={require("../../../src/assets/img/icon-info.png")}
                                  className="img-responsive"
                                  alt="icon"
                                  style={{ height: "1rem" }}
                                />
                              </TitleTooltip>
                            </div>
                          </div>
                        </th>
                      </tr>
                    </table>
                  </div>
                );
              })
            }
          </div>
        </PanelBody>
      </Panel>
    </>
  );
};

export default MemberPromotionStatement;