import React, { useState, useEffect } from "react";
import ReactTable from "../../../components/constantComponent/reactTable/ReactTable";
import { Panel, PanelBody } from "./../../../components/panel/panel.jsx";
import { useHistory } from "react-router-dom";
import {
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { navigateTo, stringIsNullOrEmpty } from "../../../util/Util";
import {
  ApiUrl,
  ApiKey,
  WebUrl,
  AccessRight,
  LanguageKey,
} from "../../../util/Constant";
import NavigationButton from "../../../components/constantComponent/button/NavigationButton";
import { checkIfPermissionExist } from "../../../util/PermissionChecker";
import { useTranslation } from "react-i18next";
import { fetch } from "whatwg-fetch";

/// <summary>
/// Author : -
/// </summary>
const ManageBank = (props) => {
  const { t } = useTranslation(LanguageKey._PRIMARY);
  let _history = useHistory();
  const [bankData, setBankData] = useState([]);
  let _tableColumns = [
    {
      Header: "BANK_CODE",
      accessor: "bankCode",
    },
    {
      Header: "BANK_SHORT_NAME",
      accessor: "bankShortName",
    },
    {
      Header: "BANK_NAME",
      accessor: "bankName",
    },
    {
      Header: "BANK_WEBSITE",
      accessor: "bankWebsite",
      Cell: ({ row }) => {
        return (
          <a
            href="javascript:;"
            onClick={() => window.open(row.original.bankWebsite)}
          >
            {row.original.bankWebsite}
          </a>
        );
      },
      width: 200,
    },
    {
      Header: "MINIMUM_DEPOSIT",
      accessor: "minimumDeposit",
    },
    {
      Header: "MINIMUM_WITHDRAWAL",
      accessor: "minimumWithdrawal",
    },
    {
      Header: "MAXIMUM_DEPOSIT",
      accessor: "maximumDeposit",
    },
    {
      Header: "MAXIMUM_WITHDRAWAL",
      accessor: "maximumWithdrawal",
    },
    {
      Header: "STATUS",
      accessor: "status",
      Cell: ({ row }) => {
        return <div>{renderStatus(row.original.status)}</div>;
      },
      minWidth: 100,
    },
    {
      Header: "MEMBER_SITE_DISPLAY",
      accessor: "status",
      id: "memberSiteStatus",
      Cell: ({ row }) => {
        return (
          <div>
            {renderStatus(row.original.enableDeposit, t("DEPOSIT"))}
            {renderStatus(row.original.enableWithdrawal, t("WITHDRAWAL"))}
          </div>
        );
      },
      minWidth: 100,
    },
    {
      id: "action",
      Header: "",
      Cell: ({ row }) =>
        checkIfPermissionExist(AccessRight._BANK_SETTING_PERMISSION, true) && (
          <div className="btn-group m-r-5 m-b-5">
            <UncontrolledButtonDropdown>
              <DropdownToggle caret color="default">
                <i className="fas fa-cog"></i>
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem
                  onClick={() => {
                    navigateTo(
                      _history,
                      WebUrl._URL_CREATE_OR_EDIT_BANK,
                      row.original
                    );
                  }}
                >
                  <span className="text-warning">{t("EDIT")}</span>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledButtonDropdown>
          </div>
        ),
      disableSortBy: true,
      disableFilters: true,
      width: 1,
      style: { overflow: "visible" },
    },
  ];

  /// <summary>
  /// Author : -
  /// </summary>
  const renderStatus = (status, text = "") => {
    let className = "";
    let statusText = "";

    if (status) {
      className = "badge-green";
      statusText = t("ACTIVE");
    } else {
      className = "badge-danger";
      statusText = t("SUSPENDED");
    }

    return (
      <span className={`badge badge-secondary ${className}`}>
        {stringIsNullOrEmpty(text) ? statusText : text}
      </span>
    );
  };

  /// <summary>
  /// Author : -
  /// </summary>
  useEffect(() => {
    fetch(ApiUrl._API_GET_USER_BANK + "?all=true", {
      method: ApiKey._API_GET,
      headers: {
        "Content-Type": ApiKey._API_FORM_URLENCODED,
        "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
          setBankData(responseJson[ApiKey._API_DATA_KEY]);
        }
      });
  }, []);

  return (
    <div>
      <h1 className="page-header">
        {t("BANKS")}
        {checkIfPermissionExist(AccessRight._BANK_SETTING_PERMISSION, true) && (
          <NavigationButton
            history={_history}
            url={WebUrl._URL_CREATE_OR_EDIT_BANK}
            buttonText={t("ADD_NEW_BANK")}
          />
        )}
      </h1>
      <Panel>
        <PanelBody>
          <ReactTable data={bankData} columns={_tableColumns} />
        </PanelBody>
      </Panel>
    </div>
  );
};

export default ManageBank;
