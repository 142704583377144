import React, { useState, useEffect } from "react";
import ReactTable from "../../components/constantComponent/reactTable/ReactTable";
import { Panel, PanelBody } from "./../../components/panel/panel.jsx";
import "../../assets/css/custom.css";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import {
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { ApiKey, ApiUrl, LanguageKey } from "../../util/Constant";
import { setBusy, setIdle } from "../../redux/AppAction";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { fetch } from "whatwg-fetch";

/// <summary>
/// Author : -
/// </summary>

const MemberProductMaintenance = (props) => {
  const { t } = useTranslation(LanguageKey._PRIMARY);
  let _history = useHistory();
  const _dispatch = useDispatch();
  const _location = useLocation();
  const [productData, setProductData] = useState([]);

  let _tableColumns = [
    {
      Header: "NAME",
      accessor: "productName",
    },
    {
      Header: "CODE",
      accessor: "productCode",
    },
    {
      Header: "LINK_STATUS",
      accessor: "accountLinked",
      Cell: ({ row }) => (
        <div className="btn-group m-r-5 m-b-5">
          {row.original.accountLinked == 1 ? (
            <span className="badge badge-secondary badge-green">
              {t("LINKED")}
            </span>
          ) : (
            <span className="badge badge-secondary badge-danger">
              {t("UNLINK")}
            </span>
          )}
        </div>
      ),
      disableSortBy: true,
      style: { overflow: "visible" },
    },
    {
      Header: "STATUS",
      accessor: "status",
      Cell: ({ row }) => (
        <div className="btn-group m-r-5 m-b-5">
          {row.original.assigned == 1 ? (
            <span className="badge badge-secondary badge-green">
              {t("ACTIVE")}
            </span>
          ) : (
            <span className="badge badge-secondary badge-danger">
              {t("MAINTENANCE")}
            </span>
          )}
        </div>
      ),
      disableSortBy: true,
      style: { overflow: "visible" },
    },
    {
      id: "action",
      Header: "",
      Cell: ({ row }) => (
        <div className="btn-group m-r-5 m-b-5">
          <UncontrolledButtonDropdown>
            <DropdownToggle caret color="default">
              <i className="fas fa-cog"></i>
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem
                onClick={() => {
                  updateProductAssignment(row.original);
                }}
              >
                {row.original.assigned ? t("DEACTIVATE") : t("ACTIVATE")}
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledButtonDropdown>
        </div>
      ),
      disableSortBy: true,
      disableFilters: true,
      width: 100,
      style: { overflow: "visible" },
    },
  ];

  /// <summary>
  /// Author : -
  /// </summary>
  function updateProductAssignment(data) {
    _dispatch(setBusy());
    fetch(
      ApiUrl._API_UPDATE_MEMBER_PRODUCT_ACTIVENESS +
        "?memberId=" +
        _location.state["id"] +
        "&accountProductId=" +
        data["accountProductId"] +
        "&enable=" +
        !data["assigned"] +
        "&assignment=true",
      {
        method: ApiKey._API_POST,
        headers: {
          "Content-Type": ApiKey._API_FORM_URLENCODED,
          "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
        },
      }
    )
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
          updateProductData();
        }
        _dispatch(setIdle());
      });
  }

  /// <summary>
  /// Author : -
  /// </summary>
  function updateProductData() {
    fetch(
      ApiUrl._API_GET_ASSIGNED_MEMBER_PRODUCT +
        "?memberId=" +
        _location.state["id"],
      {
        method: ApiKey._API_GET,
        headers: {
          "Content-Type": ApiKey._API_FORM_URLENCODED,
          "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
        },
      }
    )
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
          setProductData(responseJson[ApiKey._API_DATA_KEY]);
        }
      });
  }

  /// <summary>
  /// Author : -
  /// </summary>
  useEffect(() => {
    updateProductData();
  }, []);

  return (
    <div>
      <h1 className="page-header">{t("MEMBER_PRODUCT_MAINTENANCE")}</h1>
      <Panel>
        <PanelBody>
          <ReactTable data={productData} columns={_tableColumns} />
        </PanelBody>
      </Panel>
    </div>
  );
};

export default MemberProductMaintenance;
