import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { Panel, PanelBody } from "./../../../components/panel/panel.jsx";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import NavigationButton from "../../../components/constantComponent/button/NavigationButton";
import { stringIsNullOrEmpty, createFormBody } from "../../../util/Util";
import { ApiKey, ApiUrl, LanguageKey, SmsType } from "../../../util/Constant";
import { useLocation } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { showMessage } from "../../../redux/AppAction";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import ApiEngine from "../../../util/ApiEngine";

/// <summary>
/// Author : -
/// </summary>
const SmsContentDetail = (props) => {
  let _history = useHistory();
  const _DFAULT_LANGUAGE_VALUE = -1;
  const { t } = useTranslation(LanguageKey._PRIMARY);
  const _location = useLocation();
  const _dispatch = useDispatch();
  const {
    register,
    unregister,
    handleSubmit,
    errors,
    reset,
    setError,
    setValue,
    control,
  } = useForm();
  const [smsContentId, setSmsContentId] = useState();
  const [cursorPosition, setCursorPosition] = useState(0);
  const [status, setStatus] = useState(true);
  const [languages, setLanguages] = useState([]);
  const [languageContent, setLanguageContent] = useState([]);
  const [selectedLanguage, setSelectedLanguage] = useState(
    _DFAULT_LANGUAGE_VALUE
  );
  const [contentError, setContentError] = useState("");

  const TAG_OPTIONS = [
    { value: "{pin}", label: "pin" },
    { value: "{phonenumber}", label: "phonenumber" },
    { value: "{wallet}", label: "wallet" },
    { value: "{datetime}", label: "datetime" },
    { value: "{withdrawalamount}", label: "withdrawalamount" },
    { value: "{depositamount}", label: "depositamount" },
    { value: "{id}", label: "id" },
    { value: "{status}", label: "status" },
    { value: "{TAC}", label: "TAC" },
  ];

  /// <summary>
  /// Author : -
  /// </summary>
  const textareaBlurHandler = (e) => {
    setCursorPosition(e.target.selectionStart);
  };

  /// <summary>
  /// Author : -
  /// </summary>
  const tagChangeHandler = (e) => {
    let currentContentValue = languageContent.filter(
      (i) => i.id == selectedLanguage
    )[0]?.value;
    let processedValue = "";

    if (stringIsNullOrEmpty(currentContentValue)) {
      processedValue = e.value;
    } else {
      processedValue =
        currentContentValue.substr(0, cursorPosition) +
        e.value +
        currentContentValue.substr(cursorPosition);
    }

    setLanguageContent(
      languageContent.map((content) =>
        content.id == selectedLanguage
          ? { ...content, value: processedValue }
          : content
      )
    );

    setCursorPosition(cursorPosition + e.value.length);
  };

  /// <summary>
  /// Author : -
  /// </summary>
  useEffect(() => {
    init();
  }, []);

  /// <summary>
  /// Author : -
  /// </summary>
  const init = async () => {
    try {
      setValue("smsTypeId", SmsType._PHONE_VERIFICATION_TYPE);
      let storedLanguageContent = [];
      let storedContent = "";

      if (_location.state) {
        var apiUrl =
          ApiUrl._API_GET_USER_SMS_CONTENT_BY_ID +
          "?includeLanguageContent=true";
        apiUrl += "&id=" + _location.state.id;
        let responseJson = await ApiEngine.get(apiUrl);

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
          setSmsContentId(responseJson[ApiKey._API_DATA_KEY]["id"]);
          setStatus(responseJson[ApiKey._API_DATA_KEY]["status"]);
          setValue(
            "smsTypeId",
            responseJson[ApiKey._API_DATA_KEY]["smsTypeId"]
          );
          storedLanguageContent =
            responseJson[ApiKey._API_DATA_KEY]["smsContentLanguages"];
          storedContent = responseJson[ApiKey._API_DATA_KEY]["content"];
        } else {
          throw t(responseJson[ApiKey._API_MESSAGE_KEY]);
        }
      }

      let languageJson = await ApiEngine.get(
        ApiUrl._API_GET_PREFERENCE_LANGUAGE
      );

      if (languageJson[ApiKey._API_SUCCESS_KEY]) {
        let tempLanguages = [
          { label: "Default", value: _DFAULT_LANGUAGE_VALUE },
        ];
        let tempContents = [
          { name: "content", value: storedContent, id: _DFAULT_LANGUAGE_VALUE },
        ];

        languageJson[ApiKey._API_DATA_KEY].map((lang) => {
          let tempContent = "";

          if (
            storedLanguageContent.filter((i) => i.languageId == lang.id)
              .length > 0
          ) {
            tempContent =
              storedLanguageContent.filter((i) => i.languageId == lang.id)[0]
                .content ?? "";
          }

          tempLanguages.push({ label: lang.name, value: lang.id });
          tempContents.push({
            name: "content_" + lang.id,
            value: tempContent,
            id: lang.id,
          });
        });

        setLanguageContent(tempContents);
        setLanguages(tempLanguages);
      } else {
        throw t(languageJson[ApiKey._API_MESSAGE_KEY]);
      }
    } catch (err) {
      _dispatch(showMessage(false, err));
    }
  };

  /// <summary>
  /// Author : -
  /// </summary>
  const submitForm = async (data, e) => {
    if (
      stringIsNullOrEmpty(
        languageContent.filter((i) => i.id == _DFAULT_LANGUAGE_VALUE)[0].value
      )
    ) {
      setContentError("PLEASE_ENTER_DEFAULT_CONTENT");
      setSelectedLanguage(_DFAULT_LANGUAGE_VALUE);
      return;
    }
    setContentError("");

    let params = {
      status: data.status,
      smsTypeId: data.smsTypeId,
    };

    if (!stringIsNullOrEmpty(smsContentId)) {
      params["id"] = smsContentId;
    }

    languageContent.map((content) => {
      params[content.name] = content.value;
    });

    let formBody = createFormBody(params);
    let responseJson = await ApiEngine.post(
      ApiUrl._API_CREATE_OR_UPDATE_SMS_CONTENT,
      formBody
    );

    _dispatch(
      showMessage(
        responseJson[ApiKey._API_SUCCESS_KEY],
        t(responseJson[ApiKey._API_MESSAGE_KEY])
      )
    );

    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      setSmsContentId(responseJson[ApiKey._API_DATA_KEY]["id"]);
      _history.replace({
        pathname: _location.pathname,
        state: {
          id: responseJson[ApiKey._API_DATA_KEY]["id"],
        },
      });
    }
  };

  return (
    <div>
      <h1 className="page-header">
        {smsContentId ? t("EDIT_SMS_CONTENT") : t("ADD_SMS_CONTENT")}
        <NavigationButton history={_history} />
      </h1>
      <div className="row">
        <div className="col-xl-12">
          <Panel>
            <PanelBody>
              <form onSubmit={handleSubmit(submitForm)}>
                <div className="row">
                  <div className="col-lg-8">
                    <div className="form-group row m-b-15">
                      <label className="col-md-4 col-form-label">
                        <b>{t("SMS_TYPE")}</b>
                      </label>
                      <div className="col-md-7">
                        <Controller
                          name="smsTypeId"
                          control={control}
                          render={({ value, onChange }) => {
                            return (
                              <select
                                className="form-control"
                                onChange={(e) => onChange(e.target.value)}
                                value={value}
                              >
                                <option
                                  value={SmsType._PHONE_VERIFICATION_TYPE}
                                >
                                  {t("SMS_TYPE_PHONE_VERIFICATION")}
                                </option>
                                <option
                                  value={
                                    SmsType._RESET_PASSWORD_VEERIFICATION_TYPE
                                  }
                                >
                                  {t("SMS_TYPE_RESET_PASSWORD")}
                                </option>
                                <option value={SmsType._DEPOSIT_TYPE}>
                                  {t("SMS_TYPE_DEPOSIT")}
                                </option>
                                <option value={SmsType._WITHDRAWAL_TYPE}>
                                  {t("SMS_TYPE_WITHDRAWAL")}
                                </option>
                                <option value={SmsType._PIN_RESET}>
                                  {t("SMS_TYPE_PIN_RESET")}
                                </option>
                                <option value={SmsType._TAC_CODE}>
                                  {t("SMS_TYPE_TAC_CODE")}
                                </option>
                              </select>
                            );
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-8">
                    <div className="form-group row m-b-15">
                      <label className="col-md-4 col-form-label">
                        <b>{t("LANGUAGE")}</b>
                      </label>
                      <div className="col-md-7">
                        <Select
                          value={languages.filter(
                            (option) => option.value == selectedLanguage
                          )}
                          options={languages}
                          onChange={(e) => {
                            setSelectedLanguage(e.value);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-8">
                    <div className="form-group row m-b-15">
                      <label className="col-md-4 col-form-label">
                        <b>
                          {t("CONTENT")} (
                          {
                            languages.filter(
                              (option) => option.value == selectedLanguage
                            )[0]?.label
                          }
                          )
                        </b>
                      </label>
                      <div className="col-md-7">
                        <Select
                          options={TAG_OPTIONS}
                          onChange={tagChangeHandler}
                        />
                        <textarea
                          className="form-control"
                          rows="5"
                          style={{ marginTop: "10px" }}
                          value={
                            languageContent.filter(
                              (i) => i.id == selectedLanguage
                            )[0]?.value
                          }
                          onBlur={textareaBlurHandler}
                          onChange={(e) =>
                            setLanguageContent(
                              languageContent.map((content) =>
                                content.id == selectedLanguage
                                  ? { ...content, value: e.target.value }
                                  : content
                              )
                            )
                          }
                        ></textarea>
                        {!stringIsNullOrEmpty(contentError) && (
                          <div className="invalid-feedback">
                            {t(contentError)}
                          </div>
                        )}
                        <div className="m-t-10" style={{ lineHeight: "28px" }}>
                          <b>Tags to be used for dynamic value, </b>
                          <span className="label label-blue f-s-12 m-2">
                            &#123;TAC&#125;
                          </span>
                          <span className="label label-blue f-s-12 m-2">
                            &#123;pin&#125;
                          </span>
                          <span className="label label-blue f-s-12 m-2">
                            &#123;phonenumber&#125;
                          </span>
                          <span className="label label-blue f-s-12 m-2">
                            &#123;wallet&#125;
                          </span>
                          <span className="label label-blue f-s-12 m-2">
                            &#123;datetime&#125;
                          </span>
                          <span className="label label-blue f-s-12 m-2">
                            &#123;withdrawamount&#125;
                          </span>
                          <span className="label label-blue f-s-12 m-2">
                            &#123;depositamount&#125;
                          </span>
                          <span className="label label-blue f-s-12 m-2">
                            &#123;id&#125;
                          </span>
                          <span className="label label-blue f-s-12 m-2">
                            &#123;status&#125;
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-8">
                    <div className="form-group row m-b-15">
                      <label className="col-md-4 col-form-label">
                        <b>{t("STATUS")}</b>
                      </label>
                      <div className="col-md-7">
                        <div className="switcher">
                          <input
                            type="checkbox"
                            name="status"
                            id="status"
                            onChange={(e) => setStatus(e.target.checked)}
                            value={true}
                            checked={status}
                            ref={register}
                          />
                          <label htmlFor="status"></label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <hr />
                <div className="row">
                  <div className="col-lg-3">
                    <div className="form-group">
                      <button type="submit" className="btn btn-primary">
                        {t("SUBMIT")}
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </PanelBody>
          </Panel>
        </div>
      </div>
    </div>
  );
};

export default withRouter(SmsContentDetail);
