﻿import React, { useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ApiKey, ApiUrl, WebUrl, TransactionType, TransactionStatus, LanguageKey, AccessRight } from "../../util/Constant";
import ApiEngine from "../../util/ApiEngine"
import { setBusy, setIdle, showMessage } from '../../redux/AppAction';

import SweetAlert from "react-bootstrap-sweetalert";
import { checkIfPermissionExist } from "../../util/PermissionChecker";

const RollOverStatement = props => {
    let _history = useHistory();
    const { t, i18n } = useTranslation(LanguageKey._PRIMARY);
    const [transactionData, setTransactionData] = useState([]);
    const _dispatch = useDispatch();
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [activeFlag, setaAtiveFlag] = useState("");
    let role1IsAllowed = checkIfPermissionExist(
          AccessRight._RESET_ROLLOVER_PERMISSION,
          true
        );

    async function resetRollover(status) {
        if (status == "Active") {
            var responseJson =  await ApiEngine.get(ApiUrl._API_RESET_ROLLOVER_RECORD + "?memberId=" + props.id);
            if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                _dispatch(showMessage(responseJson[ApiKey._API_SUCCESS_KEY], t(responseJson[ApiKey._API_MESSAGE_KEY])));
                getBonusData();
            }
            else
            {
                _dispatch(showMessage(responseJson[ApiKey._API_SUCCESS_KEY], t(responseJson[ApiKey._API_MESSAGE_KEY])));
                getBonusData();
            }
        }
    }

    /// <summary>
    /// Author : -
    /// </summary>
  useEffect(() => {
      getBonusData();
  }, [])

    /// <summary>
    /// Author : -
    /// </summary>
    async function getBonusData() {
        var responseJson = await ApiEngine.get(ApiUrl._API_GET_ROLLOVER_RECORD + "?memberId=" + props.id);

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            setTransactionData(responseJson[ApiKey._API_DATA_KEY]);
        }
    }

  

   

    return (
        <>
            {showConfirmation && (
                <SweetAlert
                    warning
                    showCancel
                    confirmBtnText={t("CONFIRM")}
                    cancelBtnText={t("CANCEL")}
                    confirmBtnBsStyle="danger"
                    title={t("ARE_YOU_SURE_TO_RESET_THE_ROLLOVER")}
                    onConfirm={() => {
                        resetRollover("Active");
                        setShowConfirmation(false);
                    }}
                    onCancel={() => {
                        setShowConfirmation(false);
                    }}
                >
                    {t("UNABLE_TO_REVERT")}
                </SweetAlert>
            )}
            <div id="rollover" className="container mt-3" style={{ "margin-bottom": "10px" }}>
                {
                    transactionData.map((item) => {
                        var statusHtml = "";
                        statusHtml = item["isLatestRecord"] == 1 ?
                            <span className={item["rolloverProgress"] == item["rolloverTarget"] ? "badge badge-secondary badge-green clickable" : "badge badge-secondary badge-danger clickable-span"}
                                onClick={() => { item["status"] !== "Cleared" && role1IsAllowed ? setShowConfirmation(true) && setaAtiveFlag(item["status"]) : setaAtiveFlag(item["status"]) }}
                            >{item["status"]}</span> :
                            <span className="badge badge-secondary badge-warning">{item["status"]}</span>;

                        return (
                            <div className="card card-rollover mb-2">
                                <table className="table mb-0 table-rollover table-valign-middle" style={{ "borderCollapse": "collapse" }}>
                                    <tr>
                                        <td style={{ fontSize: "13px", width:"70%" }}>{item["date"]}</td>
                                        <td>{statusHtml}</td>
                                    </tr>
                                    <tr>
                                        <td>{t("AMOUNT")}</td>
                                        <th style={{ paddingRight: "4px" }}>{item["amount"]}</th>
                                    </tr>
                                    <tr>
                                        <td>{t("ROLLOVER_PROGRESS")}</td>
                                        <th style={{ paddingRight: "4px" }}>{item["isLatestRecord"] == 1 ? item["rolloverProgress"] : item["rolloverTarget"]} / {item["rolloverTarget"]}</th>
                                    </tr>
                                </table>
                            </div>
                        );
                    })
                }
            </div>
        </>
    );
};

export default RollOverStatement;