import React, { useState, useEffect } from "react";
import ReactTable from "../../components/constantComponent/reactTable/ReactTable";
import { useLocation } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { Panel, PanelBody } from "./../../components/panel/panel.jsx";
import "../../assets/css/custom.css";
import { useHistory } from "react-router-dom";
import {
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { navigateTo } from "../../util/Util";
import { ApiUrl, ApiKey, WebUrl, LanguageKey } from "../../util/Constant";
import NavigationButton from "../../components/constantComponent/button/NavigationButton";
import { setBusy, setIdle, showMessage } from "../../redux/AppAction";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { fetch } from "whatwg-fetch";

/// <summary>
/// Author : -
/// </summary>

const CompanyProductMaintenance = (props) => {
  const { t } = useTranslation(LanguageKey._PRIMARY);
  let _history = useHistory();
  const _location = useLocation();
  const _dispatch = useDispatch();
  const [productData, setProductData] = useState([]);

  let _tableColumns = [
    {
      Header: "PRODUCT_NAME",
      accessor: "productName",
      style: {
        whiteSpace: "unset",
        wordBreak: "break-word",
      },
    },
    {
      Header: "SHORT_CODE",
      accessor: "productCode",
    },
    {
      Header: "STATUS",
      accessor: "assigned",
      Cell: ({ row }) => (
        <div className="btn-group m-r-5 m-b-5">
          {row.original.assigned == 1 ? (
            <span className="badge badge-secondary badge-green">
              {t("ENABLED")}
            </span>
          ) : (
            <span className="badge badge-secondary badge-danger">
              {t("DISABLED")}
            </span>
          )}
        </div>
      ),
      disableSortBy: true,
      style: { overflow: "visible" },
    },
    {
      id: "action",
      Header: "",
      Cell: ({ row }) => (
        <div className="btn-group m-r-5 m-b-5">
          <UncontrolledButtonDropdown>
            <DropdownToggle caret color="default">
              <i className="fas fa-cog"></i>
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem
                onClick={() => {
                  updateProductAssignment(row.original);
                }}
              >
                {row.original.assigned ? t("DEACTIVATE") : t("ACTIVATE")}
              </DropdownItem>
              {row.original.assigned && (
                <DropdownItem
                  onClick={() => {
                    navigateTo(
                      _history,
                      WebUrl._URL_COMPANY_PRODUCT_API_INFO,
                      row.original
                    );
                  }}
                >
                  <span className="text-warning">{t("EDIT_API_INFO")}</span>
                </DropdownItem>
              )}
              {row.original.assigned && (
                <DropdownItem
                  onClick={() => {
                    navigateTo(
                      _history,
                      WebUrl._URL_COMPANY_PRODUCT_ACCOUNT_INFO,
                      row.original
                    );
                  }}
                >
                  <span className="text-primary">
                    {t("EDIT_ACCOUNT_INFORMATION")}
                  </span>
                </DropdownItem>
              )}
              {row.original.assigned && (
                <DropdownItem onClick={() => syncProductData(row.original)}>
                  <span className="text-primary">{t("SYNC_PRODUCT")}</span>
                </DropdownItem>
              )}
            </DropdownMenu>
          </UncontrolledButtonDropdown>
        </div>
      ),
      disableSortBy: true,
      disableFilters: true,
      width: 100,
      style: { overflow: "visible" },
    },
  ];

  /// <summary>
  /// Author : -
  /// </summary>
  useEffect(() => {
    GetAssignmentData();
  }, []);

  /// <summary>
  /// Author : -
  /// </summary>
  function GetAssignmentData() {
    fetch(
      ApiUrl._API_GET_MASTER_PRODUCT_ASSIGNMENT_DATA +
        "?companyId=" +
        _location.state["id"],
      {
        method: ApiKey._API_GET,
        headers: {
          "Content-Type": ApiKey._API_FORM_URLENCODED,
          "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
        },
      }
    )
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
          setProductData(responseJson[ApiKey._API_DATA_KEY]);
        }
      });
  }

  /// <summary>
  /// Author : -
  /// </summary>
  function updateProductAssignment(data) {
    _dispatch(setBusy());
    fetch(
      ApiUrl._API_UPDATE_USER_ASSIGNMENT +
        "?userId=" +
        _location.state["id"] +
        "&productId=" +
        data["productId"] +
        "&enable=" +
        !data["assigned"] +
        "&assignment=true",
      {
        method: ApiKey._API_POST,
        headers: {
          "Content-Type": ApiKey._API_FORM_URLENCODED,
          "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
        },
      }
    )
      .then((response) => response.json())
      .then((responseJson) => {
        _dispatch(setIdle());
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
          GetAssignmentData();
        }
      });
  }

  /// <summary>
  /// Author : -
  /// </summary>
  function syncProductData(data) {
    _dispatch(setBusy());
    fetch(
      ApiUrl._API_SYNC_PRODUCT_DATA +
        "?userId=" +
        _location.state["id"] +
        "&productId=" +
        data["productId"],
      {
        method: ApiKey._API_POST,
        headers: {
          "Content-Type": ApiKey._API_FORM_URLENCODED,
          "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
        },
      }
    )
      .then((response) => response.json())
      .then((responseJson) => {
        _dispatch(setIdle());
        _dispatch(
          showMessage(
            responseJson[ApiKey._API_SUCCESS_KEY],
            t(responseJson[ApiKey._API_MESSAGE_KEY])
          )
        );
      });
  }

  return (
    <div>
      <h1 className="page-header">
        {t("COMPANY_PRODUCT_MAINTENANCE")}{" "}
        <NavigationButton history={_history} />
      </h1>
      <Panel>
        <PanelBody>
          <ReactTable filterable data={productData} columns={_tableColumns} />
        </PanelBody>
      </Panel>
    </div>
  );
};

export default withRouter(CompanyProductMaintenance);
