import React, { useState, useEffect } from "react";
import { Panel, PanelBody } from "./../../components/panel/panel.jsx";
import { useForm } from "react-hook-form";
import { ApiKey, ApiUrl, LanguageKey } from "../../util/Constant";
import { createFormBody } from "../../util/Util";
import { setBusy, setIdle, showMessage } from "../../redux/AppAction";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { fetch } from "whatwg-fetch";
import PinInput from 'react-pin-input';
import ApiEngine from "../../util/ApiEngine";

/// <summary>
/// Author : -
/// </summary>

const ChangePinNumber = (props) => {
  const { t } = useTranslation(LanguageKey._PRIMARY);
  const _dispatch = useDispatch();
  const { register, handleSubmit, errors } = useForm();

  const [oldTransactionPinNumber, setOldTransactionPinNumber] = useState("");
  const [transactionPinNumber, setTransactionPinNumber] = useState("");
  const [confirmTransactionPinNumber, setConfirmTransactionPinNumber] = useState("");
  const [oldPinEntered, setOldPinEntered] = useState(false);
  const [pinEntered, setPinEntered] = useState(false);
  const [confirmPinEntered, setConfirmPinEntered] = useState(false);

  /// <summary>
  /// Author : -
  /// </summary>
  async function checkPinValue(data, e) {
    // check if pin has 4 digits (valid pin)
    // if true then save pin
    if (data.length == 4) {
      setPinEntered(true);
    }
    else {
      setPinEntered(false);
    }

  }

  /// <summary>
  /// Author : -
  /// </summary>
  async function checkOldPinValue(data, e) {
    // check if pin has 4 digits (valid pin)
    // if true then save pin
    if (data.length == 4) {
      setOldPinEntered(true);
    }
    else {
      setOldPinEntered(false);
    }

  }

  /// <summary>
  /// Author : -
  /// </summary>
  async function checkConfirmPinValue(data, e) {
    // check if pin has 4 digits (valid pin)
    // if true then save pin
    if (data.length == 4) {
      setConfirmPinEntered(true);
    }
    else {
      setConfirmPinEntered(false);
    }

  }

  /// <summary>
  /// Author : -
  /// </summary>
  const submitForm = async (data, e) => {
    if (!pinEntered || !oldPinEntered || !confirmPinEntered) {
      _dispatch(
        showMessage(false, t("INVALID_PIN_NUMBER"))
      );
      return;
    }

    if (transactionPinNumber != confirmTransactionPinNumber) {
      _dispatch(
        showMessage(false, t("CONFIRM_PIN_NUMBER_AND_PIN_NUMBER_IS_NOT_TALLY"))
      );
      return;
    }

    // verify old pin
    let params = { transactionPinNumber: oldTransactionPinNumber };
    let formBody = createFormBody(params);
    let responseJson = await ApiEngine.post(ApiUrl._API_VERIFY_USER_TRANSACTION_PIN_NUMBER, formBody);
    if (!responseJson[ApiKey._API_SUCCESS_KEY]) {
      _dispatch(showMessage(false, t("INCORRECT_OLD_PIN_NUMBER")));
      return;
    }

    // update new pin
    let params2 = {
      transactionPinNumber: transactionPinNumber,
    };

    let formBody2 = createFormBody(params2);
    _dispatch(setBusy());

    fetch(ApiUrl._API_UPDATE_PIN_NUMBER, {
      method: ApiKey._API_POST,
      headers: {
        "Content-Type": ApiKey._API_FORM_URLENCODED,
        "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
      },
      body: formBody2,
    })
      .then((response) => response.json())
      .then((responseJson) => {
        _dispatch(setIdle());
        _dispatch(
          showMessage(
            responseJson[ApiKey._API_SUCCESS_KEY],
            t(responseJson[ApiKey._API_MESSAGE_KEY])
          )
        );
      });
  };

  return (
    <div>
      <h1 className="page-header">{t("CHANGE_PIN_NUMBER")}</h1>
      <div className="row">
        <div className="col-xl-12">
          <Panel>
            <PanelBody>
              <form onSubmit={handleSubmit(submitForm)}>
                <div className="row">
                  <div className="col-lg-8">
                    <div className="form-group row m-b-15">
                      <label className="col-md-4 col-form-label">
                        <b>{t("OLD_PIN_NUMBER")}</b>
                      </label>
                      <div className="col-md-7">
                        <PinInput
                          length={4}
                          initialValue={oldTransactionPinNumber}
                          secret={true}
                          focus={true}
                          ref={register}
                          type="numeric"
                          onChange={(value, index) => {
                            setOldTransactionPinNumber(value);
                            checkOldPinValue(value, index);
                          }}
                          inputMode="numeric"
                          inputStyle={{ border: "0", margin: "0px 10px", width: "40px", height: "40px", backgroundColor: "transparent", borderBottom: "2px solid #002e6c" }}
                          inputFocusStyle={{ borderBottom: "2px solid #FFC159" }}
                          regexCriteria={/^[ 0-9_@./#&+-]*$/}
                        />
                        {errors.transactionPinNumber && (
                          <div className="invalid-feedback">
                            {t(errors.transactionPinNumber.message)}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="form-group row m-b-15">
                      <label className="col-md-4 col-form-label">
                        <b>{t("NEW_PIN_NUMBER")}</b>
                      </label>
                      <div className="col-md-7">
                        <PinInput
                          length={4}
                          initialValue={transactionPinNumber}
                          secret={true}
                          focus={true}
                          ref={register}
                          type="numeric"
                          onChange={(value, index) => {
                            setTransactionPinNumber(value);
                            checkPinValue(value, index);
                          }}
                          inputMode="numeric"
                          inputStyle={{ border: "0", margin: "0px 10px", width: "40px", height: "40px", backgroundColor: "transparent", borderBottom: "2px solid #002e6c" }}
                          inputFocusStyle={{ borderBottom: "2px solid #FFC159" }}
                          regexCriteria={/^[ 0-9_@./#&+-]*$/}
                        />
                        {errors.transactionPinNumber && (
                          <div className="invalid-feedback">
                            {t(errors.transactionPinNumber.message)}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="form-group row m-b-15">
                      <label className="col-md-4 col-form-label">
                        <b>{t("CONFIRM_PIN_NUMBER")}</b>
                      </label>
                      <div className="col-md-7">
                        <PinInput
                          length={4}
                          initialValue={confirmTransactionPinNumber}
                          secret={true}
                          focus={true}
                          ref={register}
                          type="numeric"
                          onChange={(value, index) => {
                            setConfirmTransactionPinNumber(value);
                            checkConfirmPinValue(value, index);
                          }}
                          inputMode="numeric"
                          inputStyle={{ border: "0", margin: "0px 10px", width: "40px", height: "40px", backgroundColor: "transparent", borderBottom: "2px solid #002e6c" }}
                          inputFocusStyle={{ borderBottom: "2px solid #FFC159" }}
                          regexCriteria={/^[ 0-9_@./#&+-]*$/}
                        />
                        {errors.transactionPinNumber && (
                          <div className="invalid-feedback">
                            {t(errors.transactionPinNumber.message)}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <hr />
                <div className="row">
                  <div className="col-lg-3">
                    <div className="form-group">
                      <button type="submit" className="btn btn-primary">
                        {t("SUBMIT")}
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </PanelBody>
          </Panel>
        </div>
      </div>
    </div>
  );
};

export default ChangePinNumber;
