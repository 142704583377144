import React, { useState, useEffect } from "react";
import { Panel, PanelBody } from "./../../components/panel/panel.jsx";
import { useHistory } from "react-router-dom";
import NavigationButton from "../../components/constantComponent/button/NavigationButton";
import { ApiKey, ApiUrl, LanguageKey } from "../../util/Constant";
import { stringIsNullOrEmpty, createFormBody } from "../../util/Util";
import { useLocation } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { setBusy, setIdle, showMessage } from "../../redux/AppAction";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { fetch } from "whatwg-fetch";
import ApiEngine from "../../util/ApiEngine.js";
import Select from "react-select";

/// <summary>
/// Author : -
/// </summary>

const RemarkDetail = (props) => {
  const { t } = useTranslation(LanguageKey._PRIMARY);
  let _history = useHistory();
  const _location = useLocation();
  const _dispatch = useDispatch();

  const [description, setDescription] = useState("");
  const [status, setStatus] = useState(true);
  const [remarkId, setRemarkId] = useState("");

  const { register, setValue, handleSubmit, errors, control } = useForm();

  const [remarkTypeOptions, setRemarkTypeOptions] = useState([])
  const [submitError, setSubmitError] = useState("");
  const [submitSuccess, setSubmitSuccess] = useState("");

  /// <summary>
  /// Author : -
  /// </summary>
  const submitForm = async (data, e) => {
    let params = {
      description: data.description,
      status: data.status,
      remarkTypeId: data.type
    };

    if (!stringIsNullOrEmpty(remarkId)) {
      params["id"] = remarkId;
    }

    let formBody = createFormBody(params);

    console.log(params)
    _dispatch(setBusy());

    fetch(ApiUrl._API_CREATE_OR_UPDATE_REMARK, {
      method: ApiKey._API_POST,
      headers: {
        "Content-Type": ApiKey._API_FORM_URLENCODED,
        "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
      },
      body: formBody,
    })
      .then((response) => response.json())
      .then((responseJson) => {
        _dispatch(setIdle());
        _dispatch(
          showMessage(
            responseJson[ApiKey._API_SUCCESS_KEY],
            t(responseJson[ApiKey._API_MESSAGE_KEY])
          )
        );

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
          setRemarkId(responseJson[ApiKey._API_DATA_KEY]["id"]);
        }
      });
  };

  /// <summary>
  /// Author : -
  /// </summary>
  useEffect(() => {
    if (_location.state) {
      var apiUrl = ApiUrl._API_GET_USER_REMARK_BY_ID;
      apiUrl += "?id=" + _location.state.id;
      fetch(apiUrl, {
        method: ApiKey._API_GET,
        headers: {
          "Content-Type": ApiKey._API_FORM_URLENCODED,
          "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
        },
      })
        .then((response) => response.json())
        .then((responseJson) => {
          if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            setRemarkId(responseJson[ApiKey._API_DATA_KEY]["id"]);
            setDescription(responseJson[ApiKey._API_DATA_KEY]["description"]);
            setStatus(responseJson[ApiKey._API_DATA_KEY]["status"]);
            setValue("type", responseJson[ApiKey._API_DATA_KEY]["remarkTypeId"]);
          }
        });
    }
    getRemarkType()
  }, []);

  const getRemarkType = async ()=>{
    var responseJson = await ApiEngine.get(
      `${ApiUrl._API_REMARK_TYPE}`
    );

    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      const remarkTypeOptions= responseJson[ApiKey._API_DATA_KEY].map((value,index) => {
        return {
          label: value.name,
			    value: value.id,
        }
      })

      setRemarkTypeOptions(remarkTypeOptions)

    }

      
  }

  return (
    <div>
      <h1 className="page-header">
        {stringIsNullOrEmpty(remarkId) ? t("EDIT_REMARK") : t("ADD_REMARK")}
        <NavigationButton history={_history} />
      </h1>
      <div className="row">
        <div className="col-xl-12">
          <Panel>
            <PanelBody>
              <form onSubmit={handleSubmit(submitForm)}>
                <div className="row">
                  <div className="col-lg-8">
                    <div className="form-group row m-b-15">
                      <label className="col-md-4 col-form-label">
                        <b>{t("REMARK")}</b>
                      </label>
                      <div className="col-md-7">
                        <input
                          type="text"
                          className="form-control"
                          placeholder={t("PLEASE_ENTER_REMARK")}
                          name="description"
                          ref={register({ required: "PLEASE_ENTER_REMARK" })}
                          defaultValue={description}
                        />
                        {errors.description && (
                          <div className="invalid-feedback">
                            {t(errors.description.message)}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-8">
                    <div className="form-group row m-b-15">
                      <label className="col-md-4 col-form-label">
                        <b>{t("TYPE")}</b>
                      </label>
                      <div className="col-md-7 col-form-label">
                          <Controller
                            control={control}
                            name="type"
                            render={({ onChange, value }) => (
                              <Select
                                options={remarkTypeOptions}
                                value={remarkTypeOptions.filter(
                                  (option) => option.value == value
                                )}
                                onChange={(e) => {
                                  onChange(e.value);
                                }}
                              />
                            )}
                            rules={{ required: "PLEASE_SELECT_STATUS" }}
                          />
                          {errors.status && (
                            <div className="invalid-feedback">
                              {t(errors.status.message)}
                            </div>
                          )}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-8">
                    <div className="form-group row m-b-15">
                      <label className="col-md-4 col-form-label">
                        <b>{t("STATUS")}</b>
                      </label>
                      <div className="col-md-7 col-form-label">
                        <div className="switcher">
                          <input
                            type="checkbox"
                            name="status"
                            id="status"
                            onChange={(e) => setStatus(e.target.checked)}
                            value={true}
                            checked={status}
                            ref={register}
                          />
                          <label htmlFor="status"></label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <hr />
                <div className="row">
                  <div className="col-lg-3">
                    <div className="form-group">
                      <button type="submit" className="btn btn-primary">
                        {t("SUBMIT")}
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </PanelBody>
          </Panel>
        </div>
      </div>
    </div>
  );
};

export default RemarkDetail;
