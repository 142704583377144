import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { Panel, PanelBody } from '../../components/panel/panel';
import Select from "react-select";
import ApiEngine from '../../util/ApiEngine';
import { ApiKey, ApiUrl, LanguageKey, WebUrl } from '../../util/Constant';
import { createFormBody } from '../../util/Util';
import { /*setSaveAdminLog,*/ showMessage } from '../../redux/AppAction';
import { useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { navigateTo } from "../../util/Util";


const CustomProductRanking = (props) => {
  const _dispatch = useDispatch();
  let _history = useHistory();
  const _location = useLocation();
  const { t, i18n } = useTranslation(LanguageKey._PRIMARY);
  const [gameListString, setGameListString] = useState("");
  const [textareaString, setTextareaString] = useState("");
  const [masterProductId, setMasterProductId] = useState("");
  const [productTitle, SetProductTitle] = useState("");
  const [PostInit, SetPostInit] = useState(true);
  const [inputIsNewGame, SetInputIsNewGame] = useState(true);
  const [inputIsRecommended, SetInputIsRecommended] = useState(false);
  const [placeholderEG, setPlaceholderEG] = useState(t("CUSTOM_PRODCUT_LIST_INFO"));


  async function init() {

    var apiUrl = ApiUrl._API_GET_ACCOUNT_PRODUCT_RANKING;
    let responseJson = await ApiEngine.get(apiUrl);

    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      let text = "";
      text = responseJson[ApiKey._API_DATA_KEY].replace(/,/g, "\n")
      setGameListString(text.trimEnd());
    }
  }
  useEffect(() => {
    init();
  }, []);

  const updateProductRank = async () => {
    let params = {};
    params = {
      "productListList": gameListString,
    };
    //_dispatch(setSaveAdminLog("MANAGE_VENDOR_NEW_GAME", _history.location.pathname, params));
    let responseJson = await ApiEngine.post(ApiUrl._API_UPDATE_ACCOUNT_PRODUCT_RANKING, createFormBody(params));
    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      _dispatch(showMessage(responseJson[ApiKey._API_SUCCESS_KEY], t(responseJson[ApiKey._API_MESSAGE_KEY])));
    }
  }


  return (
    <div>
      <div className="row">
        <div className="col-xl-12">
          <h1 className="page-header">{t("MANAGE_PRODUCT_RANKING")}</h1>
          <a className="btn btn-primary" href={'/product/userProducts'} target='_blank'>
            {t("VIEW_PRODUCT_LIST")}   </a>
          <br />
          <br />
          <Panel>
            <PanelBody>
              <div className="col-lg-8">
                <div className="form-group row m-b-15">
                  <label className="col-md-4 col-form-label">
                    <b>{t("ENTER_PRODUCT_NAME_LIST")}
                      <b style={{ color: "red" }} >
                        {placeholderEG.split('\n').map((line, index) => (
                          <React.Fragment key={index}>
                            {line}<br />
                          </React.Fragment>
                        ))}
                      </b>
                    </b>
                  </label>
                  <div className="col-4">
                    <textarea
                      className="form-control"
                      rows="10"
                      style={{
                        marginTop: "10px"
                      }}
                      onChange={(e) => setGameListString(e.target.value)}
                      defaultValue={gameListString}
                    >
                    </textarea>
                  </div>
                  <div>
                  </div>
                </div>
              </div>
              <hr />
              <div className="row">
                <div className="col-lg-3">
                  <div className="form-group">
                    <button type="button" className="btn btn-primary" onClick={() => updateProductRank()}>
                      {t("SUBMIT")}
                    </button>
                  </div>
                </div>
              </div>
            </PanelBody>
          </Panel>
        </div>
      </div>
    </div>
  )
}

export default CustomProductRanking;