import React, { useState, useEffect } from "react";
import ReactTable from "../../components/constantComponent/reactTable/ReactTable";
import { Panel, PanelBody } from "./../../components/panel/panel.jsx";
import { useHistory } from "react-router-dom";
import {
	UncontrolledButtonDropdown,
	DropdownToggle,
	DropdownMenu,
	DropdownItem,
} from "reactstrap";
import { navigateTo, numberWithCurrencyFormat } from "../../util/Util";
import {
	ApiUrl,
	ApiKey,
	WebUrl,
	AccessRight,
	LanguageKey,
	DEFAULT_PAGE_SIZE,
} from "../../util/Constant";
import ApiEngine from "../../util/ApiEngine";
import { checkIfPermissionExist } from "../../util/PermissionChecker";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

/// <summary>
/// Author : -
/// </summary>

const ManageMemberDCPoints = (props) => {
	const { t } = useTranslation(LanguageKey._PRIMARY);
	let _history = useHistory();
	const _dispatch = useDispatch();
	const [memberDCPtsData, setMemberDCPtsData] = useState([]);

	let _tableColumns = [
		{
			Header: "USERNAME",
			accessor: "username",
			Cell: ({ row }) => (
				<div style={{ textAlign: "center" }}>{row.original.username}</div>
			),
		},
		{
			Header: "POINTS",
			accessor: "dcPoints",
			Cell: ({ row }) => (
				<div style={{ textAlign: "center" }}>
					{row.original.dcPoints}
				</div>
			),
			disableFilters: true,
		},
		{
			Header: "POINT_QUOTA",
			accessor: "pointQuota",
			Cell: ({ row }) => (
				<div style={{ textAlign: "center" }}>
					{row.original.pointQuota}
				</div>
			),
			disableFilters: true,
		},
		{
			id: "action",
			Header: "",
			Cell: ({ row }) =>
				checkIfPermissionExist(
					AccessRight._MEMBER_DC_POINTS_PERMISSION,
					true
				) && (
					<div className="btn-group m-r-5 m-b-5">
						<UncontrolledButtonDropdown>
							<DropdownToggle caret color="default">
								<i className="fas fa-cog"></i>
							</DropdownToggle>
							<DropdownMenu container="body" flip={false} right>
								<DropdownItem
									onClick={() => {
										navigateTo(
											_history,
											WebUrl._URL_MEMBER_DC_POINTS_DETAIL,
											row.original
										);
									}}
								>
									<span className="text-warning">{t("VIEW")}</span>
								</DropdownItem>
							</DropdownMenu>
						</UncontrolledButtonDropdown>
					</div>
				),
			disableSortBy: true,
			disableFilters: true,
			width: 100,
			style: { overflow: "visible" },
		},
	];

	const init = async () => {
		let apiUrl = ApiUrl._API_GET_MEMBER_DC_POINTS;

		let responseJson = await ApiEngine.get(apiUrl);

		if (responseJson[ApiKey._API_SUCCESS_KEY]) {
			setMemberDCPtsData(responseJson[ApiKey._API_DATA_KEY]);
		}
	};

	/// <summary>
	/// Author : -
	/// </summary>
	useEffect(() => {
		init();
	}, []);

	return (
		<div>
			<h1 className="page-header">{t("MEMBER_POINTS")}</h1>
			<Panel>
				<PanelBody>
					<ReactTable
						filterable
						data={memberDCPtsData}
						columns={_tableColumns}
					/>
				</PanelBody>
			</Panel>
		</div>
	);
};

export default ManageMemberDCPoints;
