import React from 'react';
/// <summary>
/// Author : -
/// To Check Chat Content Type And Display Accordingly
/// </summary>
export function Display(data) {
    //return hehe;
    if(data.messageType == "Text")
    {
        return <div data-type="text"><span>{data.content}</span></div>;
    }
    else if(data.messageType == "Image")
    {
        return <div data-type="image"><a href={data.content}><img src={data.content} /></a></div>;
    }
    else if(data.messageType == "Voice")
    {
        return <div data-type="audio"><audio controls><source src={data.content} type="audio/ogg"/></audio></div>;
    }
    else
    {
        return data.content;
    }
}
