import React, { useState, useEffect } from "react";
import { Route, Link } from "react-router-dom";
import { PageSettings } from "./../../config/page-settings.js";
import { useSelector } from "react-redux";
import { LanguageKey, ApiKey, ApiUrl, WebUrl } from "../../util/Constant";
import { useTranslation } from "react-i18next";
import { fetch } from "whatwg-fetch";
import menus from "./menu.jsx";
import MemberMenu from "./member-menu.jsx";

/// <summary>
/// Author : -
/// </summary>
const SidebarNavList = (props) => {
	const { t } = useTranslation(LanguageKey._PRIMARY);
	const [active, setActive] = useState(-1);
	const [clicked, setClicked] = useState(-1);
	const [numberLabel, setNumberLabel] = useState("");
	const [subNumberLabel, setSubNumberLabel] = useState("");
	const [fullMenu, setFullMenu] = useState(props.fullMenu);
	const [childMenuArr, setChildMenuArr] = useState([]);
	const state = useSelector((state) => state);
	const [pendingDepositCount, setPendingDepositCount] = useState("");
	const [pendingPointsRedemptionCount, setPendingPointsRedemptionCount] =
		useState("");
	const [livechatCount, setLivechatCount] = useState("");

	useEffect(() => {
		//addNumbering();
		checkTitle();
		setInterval(checkTitle, 60000);
	}, []);

	const checkTitle = () => {
		if (props.data.title === "ONLINE_BANKING") {
			checkPendingDeposit();
		}
		if (props.data.title === "LIVECHAT") {
			livechatNotification();
		}

		if (
			props.data.title === "DAILY_TASKS" ||
			props.data.title === "CHECK_IN_POINTS" ||
			props.data.title === "REDEMPTIONS"
		) {
			checkPendingPointsRedemption();
		}
	};

	const checkPendingDeposit = async () => {
		await fetch(ApiUrl._API_CHECK_PENDING_DEPOSIT, {
			method: ApiKey._API_GET,
			headers: {
				Accept: ApiKey._API_APPLICATION_JSON,
				"X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
			},
		})
			.then((response) => response.json())
			.then((responseJson) => {
				if (responseJson[ApiKey._API_SUCCESS_KEY]) {
					setPendingDepositCount(responseJson[ApiKey._API_DATA_KEY]);
				}
			});
	};

	const livechatNotification = async () => {
		await fetch(ApiUrl._API_GET_RECENT_NOTIFICATIONS, {
			method: ApiKey._API_GET,
			headers: {
				Accept: ApiKey._API_APPLICATION_JSON,
				"X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
			},
		})
			.then((response) => response.json())
			.then((responseJson) => {
				if (responseJson[ApiKey._API_SUCCESS_KEY]) {
					setLivechatCount(responseJson[ApiKey._API_DATA_KEY]);
				}
			});
	};

	const checkPendingPointsRedemption = async () => {
		await fetch(ApiUrl._API_CHECK_PENDING_POINTS_REDEMPTION, {
			method: ApiKey._API_GET,
			headers: {
				Accept: ApiKey._API_APPLICATION_JSON,
				"X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
			},
		})
			.then((response) => response.json())
			.then((responseJson) => {
				if (responseJson[ApiKey._API_SUCCESS_KEY]) {
					setPendingPointsRedemptionCount(responseJson[ApiKey._API_DATA_KEY]);
				}
			});
	};

	const addNumbering = () => {
		if (typeof fullMenu !== "undefined") {
			fullMenu.map((menu, index) => {
				if (
					props.data.children ||
					props.data.title === "DASHBOARD" ||
					props.data.title === "COMPANY"
				) {
					if (props.data.title === menu.title) {
						setNumberLabel(index + 1 + ". ");
					}
				} else {
					if (menu.children) {
						menu.children.map((childMenu, i) => {
							if (props.data.title === childMenu.title) {
								setSubNumberLabel(index + 1 + "." + (i + 1));
							}
						});
					}
				}
			});
		}
	};

	/// <summary>
	/// Author : -
	/// </summary>
	const handleExpand = (e, i, match) => {
		e.preventDefault();
		setActive(active === i ? -1 : i);
		setClicked(1);
	};

	var icon = props.data.icon && <i className={props.data.icon}></i>;
	var img = props.data.img && (
		<div className="icon-img">
			<img src={props.data.img} alt="" />
		</div>
	);
	var caret = props.data.children && !props.data.badge && (
		<b className="caret"></b>
	);
	var label = props.data.label && (
		<span className="label label-theme m-l-5">{props.data.label}</span>
	);
	var badge = props.data.badge && (
		<span className="badge pull-right">{props.data.badge}</span>
	);
	var title = props.data.title && (
		<span>
			<span className="menu-number-label">
				{
					props.data
						.numberLabel /*props.data.children || props.data.title === "DASHBOARD" || props.data.title === "COMPANY" ? numberLabel : subNumberLabel*/
				}
			</span>
			{t(props.data.title)} {label}
			{props.data.title === "ONLINE_BANKING" && pendingDepositCount > 0 && (
				<span className="pending-deposit-tag">{pendingDepositCount}</span>
			)}
			{props.data.title === "LIVECHAT" && livechatCount > 0 && (
				<span className="pending-deposit-tag">{livechatCount}</span>
			)}
			{(props.data.title === "DAILY_TASKS" ||
				props.data.title === "CHECK_IN_POINTS" ||
				props.data.title === "REDEMPTIONS") &&
				pendingPointsRedemptionCount > 0 && (
					<span className="pending-deposit-tag">
						{pendingPointsRedemptionCount}
					</span>
				)}
		</span>
	);
	var highlight = props.data.highlight && (
		<i className="fa fa-paper-plane text-theme"></i>
	);

	return (
		<PageSettings.Consumer>
			{({
				handleSidebarOnMouseOver,
				handleSidebarOnMouseOut,
				pageSidebarMinified,
			}) => {
				return (
					<Route
						path={props.data.path}
						exact={props.data.exact}
						children={({ match }) => {
							return (
								<li
									className={
										(match ? "active " : "") +
										(props.active ||
										(props.clicked === -1 && match) ||
										props.data.search
											? "expand "
											: "closed ") +
										(props.data.children ? "has-sub " : "")
									}
								>
									{props.data.children ? (
										<Link
											to={props.data.path}
											onMouseOver={(e) =>
												handleSidebarOnMouseOver(e, props.data)
											}
											onMouseOut={(e) => handleSidebarOnMouseOut(e, props.data)}
											onClick={props.expand}
										>
											{caret} {badge} {img} {icon} {title} {highlight}
										</Link>
									) : props.data.path === WebUrl._URL_LIVE_MONITOR ||
									  props.data.path === WebUrl._URL_SPORTS_LIVE_MONITOR ? (
										<div
											onClick={() => {
												window.open(props.data.path);
											}}
										>
											{caret} {img} {icon} {badge} {title} {highlight}
										</div>
									) : (
										<Link to={props.data.path}>
											{caret} {img} {icon} {badge} {title} {highlight}
										</Link>
									)}
									{props.data.children && (
										<ul
											className={
												"sub-menu " +
												((props.active ||
													(props.clicked === -1 && match) ||
													props.data.search) &&
												!pageSidebarMinified
													? "d-block "
													: "d-none")
											}
										>
											{props.data.children &&
												props.data.children.map((submenu, i) => {
													return (
														<SidebarNavList
															data={submenu}
															key={i}
															expand={(e) => handleExpand(e, i, match)}
															active={i === props.active}
															clicked={props.clicked}
															fullMenu={props.fullMenu}
														/>
													);
												})}
										</ul>
									)}
								</li>
							);
						}}
					/>
				);
			}}
		</PageSettings.Consumer>
	);
};

export default SidebarNavList;
