import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import { SessionKey } from "../../../util/Constant";
import { stringIsNullOrEmpty } from '../../../util/Util';
import { useSelector } from 'react-redux';

/// <summary>
/// Author : -
/// </summary>
const DropdownLanguage = props => {
    const { i18n } = useTranslation();
    const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);
    const [options, setOptions] = useState([]);
    const languageState = useSelector(state => state.languageState);
    /// <summary>
    /// Author : -
    /// </summary>
    useEffect(() => {
        let languageOptions = [];
        languageState.languages.map((language, index) => {
            languageOptions.push({ value: language.code, label: <div><span><img style={{ height: "12px" }} className="flag-icon" src={language.icon} /></span><span className="flag-txt"> {language.name}</span></div> });
        });

        setOptions(languageOptions);
        let selectedLanguage = languageState.languages.find(language => language.code == i18n.language)

        if (stringIsNullOrEmpty(selectedLanguage) && languageState.languages.length > 0) {
            handleLanguageChange({ value: languageState.languages[0].code });
        }
    }, [languageState.languages]);

    const customStyles = {
        menuList: (provided, state) => ({
            ...provided,
            paddingTop: 0,
            paddingBottom: 0,
        }),
        menu: base => ({
            ...base,
            marginTop: 0
        })
    }

    /// <summary>
    /// Author: -
    /// </summary>
    const handleLanguageChange = (e) => {
        setSelectedLanguage(e.value);
        i18n.changeLanguage(e.value);
        sessionStorage.setItem(SessionKey._Language, e.value);
    }

    return (
        <li className="dropdown">
            <Select
                value={options.filter(option => option.value == selectedLanguage)}
                onChange={handleLanguageChange}
                options={options}
                className={"lang-selector"}
                isSearchable={false}
                components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                styles={customStyles}
            />
        </li>
    );
}

export default DropdownLanguage;
