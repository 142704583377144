import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import NavigationButton from "../../components/constantComponent/button/NavigationButton";
import { ApiKey, ApiUrl, LanguageKey, ContentTypeString, DisplayDevice } from "../../util/Constant";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import { stringIsNullOrEmpty, createMultiPartFormBody } from "../../util/Util";
import { setBusy, setIdle, showMessage } from "../../redux/AppAction";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { fetch } from "whatwg-fetch";
import {
    Panel,
    PanelHeader,
    PanelBody,
} from "./../../components/panel/panel.jsx";

/// <summary>
/// Author : -
/// </summary>

const WeeklyRescueDetail = (props) => {
    const { t } = useTranslation(LanguageKey._PRIMARY);
    let _history = useHistory();
    const _DETAIL_TAB = "1";
    const _location = useLocation();
    const _dispatch = useDispatch();
    const { register, unregister, handleSubmit, errors, setValue } = useForm();
    const [weeklyRescueId, setWeeklyRescueId] = useState("");
    const [name, setName] = useState("");
    const [minLosses, setminLosses] = useState("")
    const [rescuePercentage, setRescuePercentage] = useState("");
    const [maxBonus, setMaxBonus] = useState("");
    const [turnover, setTurnover] = useState("");

    const {
        register: accountFormRegister,
        handleSubmit: accountFormHandleSubmit,
        errors: accountFormErrors,
        unregister: accountFormUnregister,
        setValue: accountFormSetValue,
    } = useForm();


    const _OPTION_STYLES = {
        control: (base) => ({
            ...base,
            "min-height": "34px",
            height: "34px",
        }),
        valueContainer: (base) => ({
            ...base,
            height: "34px",
            "min-height": "34px",
        }),
        indicatorsContainer: (base) => ({
            ...base,
            height: "34px",
            "min-height": "34px",
        }),
        input: (base) => ({
            height: "34px",
            "min-height": "34px",
        }),
    };

    /// <summary>
    /// Author : -
    /// </summary>
    useEffect(() => {
        init();
    }, []);

    /// <summary>
    /// Author : -
    /// </summary>
    async function init() {
        if (_location.state) {
            setWeeklyRescueId(_location.state.id)
            var apiUrl = ApiUrl._API_GET_WEEKLY_RESCUE_BY_ID;
            apiUrl += "?id=" + _location.state.id;
            fetch(apiUrl, {
                method: ApiKey._API_GET,
                headers: {
                    "Content-Type": ApiKey._API_FORM_URLENCODED,
                    "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
                },
            })
                .then((response) => response.json())
                .then((responseJson) => {
                    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                        setWeeklyRescueId(responseJson[ApiKey._API_DATA_KEY][0]["id"])
                        setName(responseJson[ApiKey._API_DATA_KEY][0]["name"])
                        setminLosses(responseJson[ApiKey._API_DATA_KEY][0]["minlosses"])
                        setRescuePercentage(responseJson[ApiKey._API_DATA_KEY][0]["rescuePercentage"])
                        setMaxBonus(responseJson[ApiKey._API_DATA_KEY][0]["maxBonus"])
                        setTurnover(responseJson[ApiKey._API_DATA_KEY][0]["turnover"])
                    }
                });
        }
    }

    /// <summary>
    /// Author : -
    /// </summary>
    const submitForm = async (data, e) => {

        let params = {
            id : weeklyRescueId,
            minlosses: data.minLosses,
            rescuePercentage: data.rescuePercentage,
            maxBonus: data.maxBonus,
            turnover: data.turnover
        };
        let formBody = createMultiPartFormBody(params);
        _dispatch(setBusy());

        fetch(ApiUrl._API_CREATE_OR_UPDATE_WEEKLY_RESCUE, {
            method: ApiKey._API_POST,
            headers: {
                "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
            },
            body: formBody,
        })
            .then((response) => response.json())
            .then((responseJson) => {
                _dispatch(setIdle());
                _dispatch(
                    showMessage(
                        responseJson[ApiKey._API_SUCCESS_KEY],
                        t(responseJson[ApiKey._API_MESSAGE_KEY])
                    )
                );

                if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                    setWeeklyRescueId(responseJson[ApiKey._API_DATA_KEY]["id"]);
                }
            });
    };

    return (
        <div>
            <h1 className="page-header">
          {t("EDIT_WEEKLY_RESCUE")  /*localization*/}
                <NavigationButton history={_history} />
            </h1>
            <div className="row">
                <div className="col-xl-12">
                    <Panel>
                        <PanelBody>
                            <form onSubmit={handleSubmit(submitForm)}>
                                <div className="row">
                                    <div className="col-lg-8">
                                        <div className="form-group row m-b-15">
                                            <label className="col-md-4 col-form-label">
                                                <b>{t("RANK" /*localization*/)}</b>
                                            </label>
                                            <div className="col-md-7">
                                                <input
                                                    className="form-control"
                                                    rows="5"
                                                    style={{ marginTop: "10px" }}
                                                    ref={register({ required: "PLEASE_ENTER_TEXT" })}
                                                    name="name"
                                                    defaultValue={name}
                                                    disabled
                                                />
                                                {errors.text && (
                                                    <div className="invalid-feedback">
                                                        {t(errors.text.message)}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-8">
                                        <div className="form-group row m-b-15">
                                            <label className="col-md-4 col-form-label">
                                                <b>{t("MIN_LOSSES" /*localization*/)}</b>
                                            </label>
                                            <div className="col-md-7">
                                                <input
                                                    className="form-control"
                                                    type="number"
                                                    rows="5"
                                                    style={{ marginTop: "10px" }}
                                                    ref={register({ required: "PELASE_ENTER_MIN_LOSSES" })}
                                                    name="minLosses"
                                                    defaultValue={minLosses}
                                                />
                                                {errors.text && (
                                                    <div className="invalid-feedback">
                                                        {t(errors.text.message)}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-8">
                                        <div className="form-group row m-b-15">
                                            <label className="col-md-4 col-form-label">
                                                <b>{t("RESCUE_PERCENTAGE" /*localization*/)}</b>
                                            </label>
                                            <div className="col-md-7">
                                                <input
                                                    type="number"
                                                    className="form-control"
                                                    rows="5"
                                                    style={{ marginTop: "10px" }}
                                                    ref={register({ required: "PLEASE_ENTER_RESCUE_PERCENTAGE" })}
                                                    name="rescuePercentage"
                                                    defaultValue={rescuePercentage}
                                                />
                                                {errors.text && (
                                                    <div className="invalid-feedback">
                                                        {t(errors.text.message)}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-8">
                                        <div className="form-group row m-b-15">
                                            <label className="col-md-4 col-form-label">
                                                <b>{t("MAX_BONUS" /*localization*/)}</b>
                                            </label>
                                            <div className="col-md-7">
                                                <input
                                                    type="number"
                                                    className="form-control"
                                                    rows="5"
                                                    style={{ marginTop: "10px" }}
                                                    ref={register({ required: "PLEASE_ENTER_MAX_BONUS" })}
                                                    name="maxBonus"
                                                    defaultValue={maxBonus}
                                                />
                                                {errors.text && (
                                                    <div className="invalid-feedback">
                                                        {t(errors.text.message)}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-8">
                                        <div className="form-group row m-b-15">
                                            <label className="col-md-4 col-form-label">
                                                <b>{t("TURNOVER" /*localization*/)}</b>
                                            </label>
                                            <div className="col-md-7">
                                                <input
                                                    type="number"
                                                    className="form-control"
                                                    rows="5"
                                                    style={{ marginTop: "10px" }}
                                                    ref={register({ required: "PLEASE_ENTER_TURNOVER" })}
                                                    name="turnover"
                                                    defaultValue={turnover}
                                                />
                                                {errors.text && (
                                                    <div className="invalid-feedback">
                                                        {t(errors.text.message)}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <hr />
                                <div className="row">
                                    <div className="col-lg-3">
                                        <div className="form-group">
                                            <button type="submit" className="btn btn-primary">
                                                {t("SUBMIT")}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </PanelBody>
                    </Panel>
                </div>
            </div>
        </div>
    );
};

export default WeeklyRescueDetail;
