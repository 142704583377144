import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { Panel, PanelBody } from "./../../components/panel/panel.jsx";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Dropzone from "react-dropzone";
import NavigationButton from "../../components/constantComponent/button/NavigationButton";
import {
	ApiKey,
	ApiUrl,
	DisplayMode,
	LanguageKey,
	ContentTypeString,
	AllVipOption,
} from "../../util/Constant";
import {
	stringIsNullOrEmpty,
	createMultiPartFormBody,
	imagePathToFileObject,
} from "../../util/Util";
import { useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { setBusy, setIdle, showMessage } from "../../redux/AppAction";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import ManageConfigurationLanguage from "./ManageConfigurationLanguage";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import classnames from "classnames";
import { fetch } from "whatwg-fetch";

/// <summary>
/// Author : -
/// </summary>

const PromotionDetail = (props) => {
	const _DETAIL_TAB = "1";
	const _LANGUAGE_TAB = "2";
	let _history = useHistory();
	const { t } = useTranslation(LanguageKey._PRIMARY);
	const _location = useLocation();
	const _dispatch = useDispatch();
	const { register, unregister, handleSubmit, errors } = useForm();
	const [displayMode, setDisplayMode] = useState("");

	const [description, setDescription] = useState("");
	const [title, setTitle] = useState("");
	const [content, setContent] = useState("");
	const [url, setUrl] = useState("");
	const [promotionId, setPromotionId] = useState("");
	const [promotionRefId, setPromotionRefId] = useState("");
	const [status, setStatus] = useState(true);

	const [desktopFile, setDesktopFile] = useState([]);
	const [mobileFile, setMobileFile] = useState([]);
	const [desktopImage, setDesktopImage] = useState();
	const [mobileImage, setMobileImage] = useState();
	const [isNewDesktopImage, setIsNewDesktopImage] = useState(false);
	const [isNewMobileImage, setIsNewMobileImage] = useState(false);
	const [activeTab, setActiveTab] = useState(_DETAIL_TAB);
	const [vip, setVip] = useState();
	const [vipOption, setVipOption] = useState([]);

	/// <summary>
	/// Author : -
	/// </summary>
	const toggle = (tab) => {
		if (activeTab !== tab) {
			setActiveTab(tab);
		}
	};

	/// <summary>
	/// Author : -
	/// </summary>
	useEffect(() => {
		register({ name: "content" }, { required: "PLEASE_ENTER_CONTENT" });
		register({ name: "vip" }, { required: "PLEASE_SELECT_VIP_LEVEL" });
		init();
	}, []);

	/// <summary>
	/// Author : -
	/// </summary>
	async function init() {
		if (_location.state) {
			var apiUrl = ApiUrl._API_GET_USER_PROMOTION_BY_ID;
			let statePromotionId = _location.state.id || _location.state.refId;
			apiUrl += "?id=" + statePromotionId;
			await fetch(apiUrl, {
				method: ApiKey._API_GET,
				headers: {
					"Content-Type": ApiKey._API_FORM_URLENCODED,
					"X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
				},
			})
				.then((response) => response.json())
				.then(async (responseJson) => {
					if (responseJson[ApiKey._API_SUCCESS_KEY]) {
						if (_location.state.id) {
							setPromotionId(responseJson[ApiKey._API_DATA_KEY]["id"]);
							setPromotionRefId("");
						} else if (_location.state.refId) {
							setPromotionId("");
							setPromotionRefId(responseJson[ApiKey._API_DATA_KEY]["id"]);
						}

						setDisplayMode(responseJson[ApiKey._API_DATA_KEY]["displayModeId"]);
						setContent(responseJson[ApiKey._API_DATA_KEY]["content"]);
						setTitle(responseJson[ApiKey._API_DATA_KEY]["title"]);
						setDescription(responseJson[ApiKey._API_DATA_KEY]["description"]);
						setVip(
							stringIsNullOrEmpty(responseJson[ApiKey._API_DATA_KEY]["vipId"])
								? AllVipOption._VALUE
								: responseJson[ApiKey._API_DATA_KEY]["vipId"]
						);
						setUrl(responseJson[ApiKey._API_DATA_KEY]["url"]);
						setStatus(responseJson[ApiKey._API_DATA_KEY]["status"]);

						if (
							!stringIsNullOrEmpty(
								responseJson[ApiKey._API_DATA_KEY]["desktopImage"]
							)
						) {
							setDesktopImage(
								responseJson[ApiKey._API_DATA_KEY]["desktopImage"]
							);
							if (_location.state.refId) {
								setIsNewDesktopImage(true);
								let imageFile = await imagePathToFileObject(
									responseJson[ApiKey._API_DATA_KEY]["desktopImage"]
								);
								setDesktopFile(imageFile);
							}
						}

						if (
							!stringIsNullOrEmpty(
								responseJson[ApiKey._API_DATA_KEY]["mobileImage"]
							)
						) {
							setMobileImage(responseJson[ApiKey._API_DATA_KEY]["mobileImage"]);
							if (_location.state.refId) {
								setIsNewMobileImage(true);
								let imageFile = await imagePathToFileObject(
									responseJson[ApiKey._API_DATA_KEY]["mobileImage"]
								);
								setMobileFile(imageFile);
							}
						}

						unregister("vip");
						unregister("content");
					}
				});
		}

		await fetch(ApiUrl._API_GET_USER_VIP, {
			method: ApiKey._API_GET,
			headers: {
				"Content-Type": ApiKey._API_FORM_URLENCODED,
				"X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
			},
		})
			.then((response) => response.json())
			.then((responseJson) => {
				if (responseJson[ApiKey._API_SUCCESS_KEY]) {
					const vipList = [
						{ label: AllVipOption._LABEL, value: AllVipOption._VALUE },
					];
					responseJson[ApiKey._API_DATA_KEY].forEach(function (vipLevel) {
						vipList.push({ label: vipLevel.name, value: vipLevel.id });
					});

					setVipOption(vipList);
				}
			});
	}

	/// <summary>
	/// Author : -
	/// </summary>
	const submitForm = async (data, e) => {
		let params = {
			title: data.title,
			displayModeId: data.displayMode,
			description: data.description,
			url: data.url,
			rank: data.rank,
			status: data.status,
			content: content,
			isNewDesktopFile: isNewDesktopImage,
			isNewMobileFile: isNewMobileImage,
			vipId: stringIsNullOrEmpty(vip) ? "" : vip,
		};

		if (vip != AllVipOption._VALUE) {
			params["vipId"] = vip;
		}

		if (!stringIsNullOrEmpty(desktopFile)) {
			params["desktopFile"] = desktopFile;
		}

		if (stringIsNullOrEmpty(desktopImage)) {
			params["desktopImageRemoved"] = true;
		}

		if (!stringIsNullOrEmpty(mobileFile)) {
			params["mobileFile"] = mobileFile;
		}

		if (stringIsNullOrEmpty(mobileImage)) {
			params["mobileImageRemoved"] = true;
		}

		if (!stringIsNullOrEmpty(promotionId)) {
			params["id"] = promotionId;
		}

		if (!stringIsNullOrEmpty(promotionRefId)) {
			params["refId"] = promotionRefId;
		}

		let formBody = createMultiPartFormBody(params);
		_dispatch(setBusy());

		fetch(ApiUrl._API_CREATE_OR_UPDATE_PROMOTION, {
			method: ApiKey._API_POST,
			headers: {
				"X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
			},
			body: formBody,
		})
			.then((response) => response.json())
			.then((responseJson) => {
				_dispatch(setIdle());
				_dispatch(
					showMessage(
						responseJson[ApiKey._API_SUCCESS_KEY],
						t(responseJson[ApiKey._API_MESSAGE_KEY])
					)
				);

				if (responseJson[ApiKey._API_SUCCESS_KEY]) {
					setIsNewDesktopImage(false);
					setIsNewMobileImage(false);
					setPromotionRefId("");
					setPromotionId(responseJson[ApiKey._API_DATA_KEY]["id"]);

					_history.replace({
						pathname: _location.pathname,
						state: {
							id: responseJson[ApiKey._API_DATA_KEY]["id"],
						},
					});
				}
			});
	};

	/// <summary>
	/// Author : -
	/// </summary>
	const logoDrop = (acceptedFiles, isDesktop) => {
		if (acceptedFiles.length !== 0) {
			const file = acceptedFiles[0];

			const reader = new FileReader();
			reader.onloadend = () => {
				if (isDesktop) {
					setDesktopFile(file);
					setDesktopImage(reader.result);
					setIsNewDesktopImage(true);
				} else {
					setMobileFile(file);
					setIsNewMobileImage(true);
					setMobileImage(reader.result);
				}
			};
			reader.readAsDataURL(file);
		}
	};

	return (
		<div>
			<h1 className="page-header">
				{promotionId ? t("EDIT_PROMOTION") : t("ADD_PROMOTION")}
				<NavigationButton history={_history} />
			</h1>
			{promotionId && (
				<Nav tabs>
					<NavItem>
						<NavLink
							className={classnames({ active: activeTab === _DETAIL_TAB })}
							onClick={() => {
								toggle(_DETAIL_TAB);
							}}
						>
							{t("DETAIL")}
						</NavLink>
					</NavItem>
					<NavItem>
						<NavLink
							className={classnames({ active: activeTab === _LANGUAGE_TAB })}
							onClick={() => {
								toggle(_LANGUAGE_TAB);
							}}
						>
							{t("LANGUAGE")}
						</NavLink>
					</NavItem>
				</Nav>
			)}
			<TabContent activeTab={activeTab}>
				<TabPane tabId={_DETAIL_TAB}>
					<div className="row">
						<div className="col-xl-12">
							<Panel>
								<PanelBody>
									<form onSubmit={handleSubmit(submitForm)}>
										<div className="row">
											<div className="col-lg-8">
												<div className="form-group row m-b-15">
													<label className="col-md-4 col-form-label">
														<b>{t("TITLE")}</b>
													</label>
													<div className="col-md-7">
														<input
															type="text"
															className="form-control"
															placeholder={t("PLEASE_ENTER_TITLE")}
															defaultValue={title}
															ref={register({ required: "PLEASE_ENTER_TITLE" })}
															name="title"
														/>
														{errors.title && (
															<div className="invalid-feedback">
																{t(errors.title.message)}
															</div>
														)}
													</div>
												</div>
											</div>
											<div className="col-lg-8">
												<div className="form-group row m-b-15">
													<label className="col-md-4 col-form-label">
														<b>{t("DESCRIPTION")}</b>
													</label>
													<div className="col-md-7">
														<input
															type="text"
															className="form-control"
															placeholder={t("PLEASE_ENTER_DESCRIPTION")}
															defaultValue={description}
															ref={register({
																required: "PLEASE_ENTER_DESCRIPTION",
															})}
															name="description"
														/>
														{errors.description && (
															<div className="invalid-feedback">
																{t(errors.description.message)}
															</div>
														)}
													</div>
												</div>
											</div>
											<div className="col-lg-8">
												<div className="form-group row m-b-15">
													<label className="col-md-4 col-form-label">
														<b>{t("DISPLAY_MODE")}</b>
													</label>
													<div className="col-md-7">
														<select
															className="form-control"
															name="displayMode"
															ref={register}
															onChange={(e) => setDisplayMode(e.target.value)}
															value={displayMode}
														>
															<option value={DisplayMode._ALL}>
																{t("ALL")}
															</option>
															<option value={DisplayMode._PUBLIC}>
																{t("PUBLIC")}
															</option>
															<option value={DisplayMode._MEMBER}>
																{t("MEMBER")}
															</option>
														</select>
													</div>
												</div>
											</div>
											<div className="col-lg-8">
												<div className="form-group row m-b-15">
													<label className="col-md-4 col-form-label">
														<b>{t("URL")}</b>
													</label>
													<div className="col-md-7">
														<input
															type="text"
															className="form-control"
															placeholder={t("PLEASE_ENTER_URL")}
															name="url"
															ref={register({ required: "PLEASE_ENTER_URL" })}
															defaultValue={url}
														/>
														{errors.url && (
															<div className="invalid-feedback">
																{t(errors.url.message)}
															</div>
														)}
													</div>
												</div>
											</div>
											<div className="col-lg-8">
												<div className="form-group row">
													<label className="col-md-4 col-form-label">
														<b>{t("VIP_LEVEL")}</b>
													</label>
													<div className="col-md-7">
														<Select
															name="vip"
															options={vipOption}
															placeholder={
																vipOption.filter(
																	(option) => option.value == vip
																)[0] !== undefined
																	? vipOption.filter(
																			(option) => option.value == vip
																	  )[0].label
																	: ""
															}
															value={vipOption.filter(
																(option) => option.value == vip
															)}
															onChange={(e) => {
																unregister("vip");
																setVip(e.value);
															}}
														/>
														{errors.vip && (
															<div className="invalid-feedback">
																{t(errors.vip.message)}
															</div>
														)}
													</div>
												</div>
											</div>
											<div className="col-lg-8">
												<div className="form-group row m-b-15">
													<label className="col-md-4 col-form-label">
														<b>{t("CONTENT")}</b>
													</label>
													<div className="col-md-7">
														<CKEditor
															editor={ClassicEditor}
															data={content}
															name="content"
															onInit={(editor) => {
																editor.setData(content);
															}}
															onChange={(event, editor) => {
																const data = editor.getData();
																setContent(data);
																if (stringIsNullOrEmpty(data)) {
																	register(
																		{ name: "content" },
																		{ required: "PLEASE_ENTER_CONTENT" }
																	);
																} else {
																	unregister("content");
																}
															}}
														/>
														{errors.content && (
															<div className="invalid-feedback">
																{t(errors.content.message)}
															</div>
														)}
													</div>
												</div>
											</div>
											<div className="col-lg-8">
												<div className="form-group row m-b-15">
													<label className="col-md-4 col-form-label">
														<b>{t("DESKTOP_IMAGE_UPLOAD")}</b>
													</label>
													<div className="col-md-7">
														<Dropzone
															accept={"image/*"}
															onDrop={(acceptedFiles) =>
																logoDrop(acceptedFiles, true)
															}
														>
															{({ getRootProps, getInputProps }) => (
																<section>
																	<div
																		className="dropzone"
																		style={{
																			minHeight: "200px",
																			textAlign: "center",
																		}}
																		{...getRootProps()}
																	>
																		<input {...getInputProps()} />
																		<h4 style={{ color: "grey" }}>
																			{t(
																				"DRAG_N_DROP_SOME_FILES_HERE_OR_CLICK_TO_SELECT_FILES"
																			)}
																		</h4>
																		{!stringIsNullOrEmpty(desktopImage) && (
																			<aside className="thumbsContainer">
																				<div className="thumb">
																					<div className="thumbInner">
																						<img
																							src={desktopImage}
																							className="dropzone-img"
																						/>
																					</div>
																				</div>
																			</aside>
																		)}
																	</div>
																</section>
															)}
														</Dropzone>
														<br />
														<button
															type="button"
															className="btn btn-danger"
															onClick={() => {
																setDesktopImage("");
																setDesktopFile();
															}}
														>
															{t("REMOVE_IMAGE")}
														</button>
													</div>
												</div>
											</div>
											<div className="col-lg-8">
												<div className="form-group row m-b-15">
													<label className="col-md-4 col-form-label">
														<b>{t("MOBILE_IMAGE_UPLOAD")}</b>
													</label>
													<div className="col-md-7">
														<Dropzone
															accept={"image/*"}
															onDrop={(acceptedFiles) =>
																logoDrop(acceptedFiles, false)
															}
														>
															{({ getRootProps, getInputProps }) => (
																<section>
																	<div
																		className="dropzone"
																		style={{
																			minHeight: "200px",
																			textAlign: "center",
																		}}
																		{...getRootProps()}
																	>
																		<input {...getInputProps()} />
																		<h4 style={{ color: "grey" }}>
																			{t(
																				"DRAG_N_DROP_SOME_FILES_HERE_OR_CLICK_TO_SELECT_FILES"
																			)}
																		</h4>
																		{!stringIsNullOrEmpty(mobileImage) && (
																			<aside className="thumbsContainer">
																				<div className="thumb">
																					<div className="thumbInner">
																						<img
																							src={mobileImage}
																							className="dropzone-img"
																						/>
																					</div>
																				</div>
																			</aside>
																		)}
																	</div>
																</section>
															)}
														</Dropzone>
														<br />
														<button
															type="button"
															className="btn btn-danger"
															onClick={() => {
																setMobileImage("");
																setMobileFile();
															}}
														>
															{t("REMOVE_IMAGE")}
														</button>
													</div>
												</div>
											</div>
											<div className="col-lg-8">
												<div className="form-group row m-b-15">
													<label className="col-md-4 col-form-label">
														<b>{t("STATUS")}</b>
													</label>
													<div className="col-md-7">
														<div className="switcher">
															<input
																type="checkbox"
																name="status"
																id="status"
																onChange={(e) => setStatus(e.target.checked)}
																value={true}
																checked={status}
																ref={register}
															/>
															<label htmlFor="status"></label>
														</div>
													</div>
												</div>
											</div>
											<div className="col-lg-2"></div>
										</div>
										<hr />
										<div className="row">
											<div className="col-lg-3">
												<div className="form-group">
													<button type="submit" className="btn btn-primary">
														{t("SUBMIT")}
													</button>
												</div>
											</div>
										</div>
									</form>
								</PanelBody>
							</Panel>
						</div>
					</div>
				</TabPane>
				<TabPane tabId={_LANGUAGE_TAB}>
					{promotionId && (
						<div className="row">
							<div className="col-lg-12">
								<ManageConfigurationLanguage
									id={promotionId}
									type={ContentTypeString._PROMOTION}
								/>
							</div>
						</div>
					)}
				</TabPane>
			</TabContent>
		</div>
	);
};

export default withRouter(PromotionDetail);
