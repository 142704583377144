import React, { useState, useEffect } from "react";
import ReactTable from "../../components/constantComponent/reactTable/ReactTable";
import { Panel, PanelBody } from "./../../components/panel/panel.jsx";
import "../../assets/css/custom.css";
import { useHistory } from "react-router-dom";
import {
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { navigateTo } from "../../util/Util";
import NavigationButton from "../../components/constantComponent/button/NavigationButton";
import {
  ApiUrl,
  ApiKey,
  WebUrl,
  AccessRight,
  LanguageKey,
} from "../../util/Constant";
import { checkIfPermissionExist } from "../../util/PermissionChecker";
import { useTranslation } from "react-i18next";
import { fetch } from "whatwg-fetch";

/// <summary>
/// Author : -
/// </summary>

const ScriptIndex = (props) => {
  const { t } = useTranslation(LanguageKey._PRIMARY);
  let _history = useHistory();
  const [scriptData, setScriptData] = useState([]);
  const [title, setTitle] = useState([]);
  const [createOrUpdateUrl, setCreateOrUpdateUrl] = useState([]);

  let _tableColumns = [
    {
      Header: "TITLE",
      accessor: "title",
      disableSortBy: true,
    },
    {
      Header: "POSITION",
      accessor: "positionString",
    },
    {
      Header: "STATUS",
      accessor: "status",
      Cell: ({ row }) => (
        <div className="btn-group m-r-5 m-b-5">
          {row.original.status == 1 ? (
            <span className="badge badge-secondary badge-green">
              {t("ACTIVE")}
            </span>
          ) : (
            <span className="badge badge-secondary badge-danger">
              {t("SUSPENDED")}
            </span>
          )}
        </div>
      ),
      disableSortBy: true,
      style: { overflow: "visible" },
    },
    {
      id: "action",
      Header: "",
      Cell: ({ row }) =>
        checkIfPermissionExist(
          AccessRight._SCRIPT_SETTING_PERMISSION,
          true
        ) && (
          <div className="btn-group m-r-5 m-b-5">
            <UncontrolledButtonDropdown>
              <DropdownToggle caret color="default">
                <i className="fas fa-cog"></i>
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem
                  onClick={() => {
                    navigateTo(_history, createOrUpdateUrl, row.original);
                  }}
                >
                  <span className="text-warning">{t("EDIT")}</span>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledButtonDropdown>
          </div>
        ),
      disableSortBy: true,
      disableFilters: true,
      width: 100,
      style: { overflow: "visible" },
    },
  ];

  /// <summary>
  /// Author : -
  /// </summary>
  useEffect(() => {
    setTitle(props["isSeo"] ? "SEO" : "SCRIPT");
    setCreateOrUpdateUrl(
      props["isSeo"]
        ? WebUrl._URL_CREATE_OR_UPDATE_SEO
        : WebUrl._URL_CREATE_OR_UPDATE_SCRIPT
    );
    fetch(
      ApiUrl._API_GET_USER_SCRIPT + "?isSeo=" + props["isSeo"] + "&all=true",
      {
        method: ApiKey._API_GET,
        headers: {
          "Content-Type": ApiKey._API_FORM_URLENCODED,
          "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
        },
      }
    )
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
          setScriptData(responseJson[ApiKey._API_DATA_KEY]);
        }
      });
  }, []);

  return (
    <div>
      <h1 className="page-header">
        {t(title + "_LISTING")}
        {checkIfPermissionExist(
          AccessRight._SCRIPT_SETTING_PERMISSION,
          true
        ) && (
          <NavigationButton
            history={_history}
            url={createOrUpdateUrl}
            buttonText={t("ADD_NEW_" + title)}
          />
        )}
      </h1>
      <Panel>
        <PanelBody>
          <ReactTable data={scriptData} columns={_tableColumns} />
        </PanelBody>
      </Panel>
    </div>
  );
};

export default ScriptIndex;
