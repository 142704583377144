import React, { useState, useEffect } from "react";
import { Panel, PanelBody } from "./../../../components/panel/panel.jsx";
import { useHistory } from "react-router-dom";
import NavigationButton from "../../../components/constantComponent/button/NavigationButton";
import DateRangePicker from "react-bootstrap-daterangepicker";
import DateTime from "react-datetime";
import {
  ApiKey,
  ApiUrl,
  BonusSettingType,
  BonusMethodType,
  LanguageKey,
  ContentTypeString,
  AllVipOption,
} from "../../../util/Constant";
import {
  stringIsNullOrEmpty,
  createMultiPartFormBody,
  imagePathToFileObject,
} from "../../../util/Util";
import { useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { setBusy, setIdle, showMessage } from "../../../redux/AppAction";
import { useDispatch } from "react-redux";
import moment from "moment";
import Select from "react-select";
import Dropzone from "react-dropzone";
import "react-datetime/css/react-datetime.css";
import { useTranslation } from "react-i18next";
import ManageConfigurationLanguage from "../ManageConfigurationLanguage";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import classnames from "classnames";
import { fetch } from "whatwg-fetch";

/// <summary>
/// Author : -
/// </summary>

const BonusDetail = (props) => {
  const _DETAIL_TAB = "1";
  const _LANGUAGE_TAB = "2";
  const { t } = useTranslation(LanguageKey._PRIMARY);
  let _history = useHistory();
  const _location = useLocation();
  const _dispatch = useDispatch();
  const { register, unregister, handleSubmit, errors, setError } = useForm();

  const [showPlayer, setShowPlayer] = useState(true);
  const [title, setTitle] = useState("");
  const [code, setCode] = useState("");

  const [bonusId, setBonusId] = useState("");
  const [bonusRefId, setBonusRefId] = useState("");
  const [methodId, setMethodId] = useState(BonusMethodType._AMOUNT);
  const [settingTypeId, setSettingTypeId] = useState("");
  const [rank, setRank] = useState("");
  const [maximumDeposit, setMaximumDeposit] = useState("");
  const [minimumDeposit, setMinimumDeposit] = useState("");
  const [content, setContent] = useState("");
  const [turnover, setTurnover] = useState("");
  const [remark, setRemark] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [amount, setAmount] = useState("");

  const [bonusStartTime, setBonusStartTime] = useState("00:00");
  const [bonusEndTime, setBonusEndTime] = useState("00:00");
  const [bonusDayData, setBonusDayData] = useState([]);

  const [bonusTypeOption, setBonusTypeOption] = useState([]);
  const [bonusTypeId, setBonusTypeId] = useState("");
  const [logoImage, setLogoImage] = useState(null);
  const [logoFile, setLogoFile] = useState(null);
  const [isNewImage, setIsNewImage] = useState(false);
  const [vip, setVip] = useState();
  const [vipOption, setVipOption] = useState([]);
  const [claimCount, setClaimCount] = useState("");

  const MAXIMUM_DEPOSIT_AMOUNT = 100000;

  const _DAY_OPTION = [
    { value: 0, label: "Sunday" },
    { value: 1, label: "Monday" },
    { value: 2, label: "Tuesday" },
    { value: 3, label: "Wednesday" },
    { value: 4, label: "Thursday" },
    { value: 5, label: "Friday" },
    { value: 6, label: "Saturday" },
  ];

  const _DAY_MAPPER = {
    0: "Sunday",
    1: "Monday",
    2: "Tuesday",
    3: "Wednesday",
    4: "Thursday",
    5: "Friday",
    6: "Saturday",
  };

  const [activeTab, setActiveTab] = useState(_DETAIL_TAB);

  /// <summary>
  /// Author : -
  /// </summary>
  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  /// <summary>
  /// Author : -
  /// </summary>
  useEffect(() => {
    register({ name: "bonusTypeId" }, { required: "PLEASE_SELECT_BONUS_TYPE" });
    register(
      { name: "bonusDay" },
      { required: "PLEASE_ENTER_AT_LEAST_ONE_DAY" }
    );
    register({ name: "vip" }, { required: "PLEASE_SELECT_VIP_LEVEL" });
    init();
  }, []);

  /// <summary>
  /// Author : -
  /// </summary>
  async function init() {
    if (_location.state) {
      let stateBonusId = _location.state.id || _location.state.refId;
      var apiUrl = ApiUrl._API_GET_USER_BONUS_BY_ID;
      apiUrl += "?id=" + stateBonusId;
      await fetch(apiUrl, {
        method: ApiKey._API_GET,
        headers: {
          "Content-Type": ApiKey._API_FORM_URLENCODED,
          "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
        },
      })
        .then((response) => response.json())
        .then(async (responseJson) => {
          if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            if (_location.state.id) {
              setBonusId(responseJson[ApiKey._API_DATA_KEY]["id"]);
              setBonusRefId("");
            } else if (_location.state.refId) {
              setBonusId("");
              setBonusRefId(responseJson[ApiKey._API_DATA_KEY]["id"]);
            }

            setTitle(responseJson[ApiKey._API_DATA_KEY]["title"]);
            setCode(responseJson[ApiKey._API_DATA_KEY]["code"]);
            setContent(responseJson[ApiKey._API_DATA_KEY]["content"]);
            setRemark(responseJson[ApiKey._API_DATA_KEY]["remark"]);
            setStartDate(responseJson[ApiKey._API_DATA_KEY]["startDateString"]);
            setEndDate(responseJson[ApiKey._API_DATA_KEY]["endDateString"]);

            setBonusTypeId(responseJson[ApiKey._API_DATA_KEY]["bonusTypeId"]);

            setMethodId(responseJson[ApiKey._API_DATA_KEY]["methodId"]);
            setSettingTypeId(
              responseJson[ApiKey._API_DATA_KEY]["settingTypeId"]
            );
            setShowPlayer(responseJson[ApiKey._API_DATA_KEY]["isShowPlayer"]);
            setAmount(responseJson[ApiKey._API_DATA_KEY]["amount"]);
            setMinimumDeposit(
              responseJson[ApiKey._API_DATA_KEY]["minimumDeposit"]
            );
            setMaximumDeposit(
              responseJson[ApiKey._API_DATA_KEY]["maximumBonusAmount"]
            );
            setTurnover(responseJson[ApiKey._API_DATA_KEY]["turnover"]);
            setBonusStartTime(
              responseJson[ApiKey._API_DATA_KEY]["bonusStartTime"]
            );
            setBonusEndTime(responseJson[ApiKey._API_DATA_KEY]["bonusEndTime"]);
            var bonusArr = [];

            for (
              var i = 0;
              i < responseJson[ApiKey._API_DATA_KEY]["bonusDay"].length;
              i++
            ) {
              bonusArr.push({
                value: responseJson[ApiKey._API_DATA_KEY]["bonusDay"][i],
                label:
                  _DAY_MAPPER[
                    responseJson[ApiKey._API_DATA_KEY]["bonusDay"][i]
                  ],
              });
            }

            setBonusDayData(bonusArr);

            if (
              !stringIsNullOrEmpty(
                responseJson[ApiKey._API_DATA_KEY]["bonusImage"]
              )
            ) {
              setLogoImage(responseJson[ApiKey._API_DATA_KEY]["bonusImage"]);
              if (_location.state.refId) {
                setIsNewImage(true);
                let imageFile = await imagePathToFileObject(
                  responseJson[ApiKey._API_DATA_KEY]["bonusImage"]
                );
                setLogoFile(imageFile);
              }
            }
            setVip(
              stringIsNullOrEmpty(responseJson[ApiKey._API_DATA_KEY]["vipId"])
                ? AllVipOption._VALUE
                : responseJson[ApiKey._API_DATA_KEY]["vipId"]
            );
            setClaimCount(responseJson[ApiKey._API_DATA_KEY]["claimCount"]);
            unregister("bonusTypeId");
            unregister("bonusDay");
            unregister("vip");
          }
        });
    }

    await fetch(ApiUrl._API_GET_BONUS_TYPE, {
      method: ApiKey._API_GET,
      headers: {
        "Content-Type": ApiKey._API_FORM_URLENCODED,
        "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
          const bonusTypeList = [];
          responseJson[ApiKey._API_DATA_KEY].forEach(function (bonus) {
            bonusTypeList.push({ label: bonus.name, value: bonus.id });
          });

          setBonusTypeOption(bonusTypeList);
        }
      });

    await fetch(ApiUrl._API_GET_USER_VIP, {
      method: ApiKey._API_GET,
      headers: {
        "Content-Type": ApiKey._API_FORM_URLENCODED,
        "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
          const vipList = [
            { label: AllVipOption._LABEL, value: AllVipOption._VALUE },
          ];
          responseJson[ApiKey._API_DATA_KEY].forEach(function (vipLevel) {
            vipList.push({ label: vipLevel.name, value: vipLevel.id });
          });

          setVipOption(vipList);
        }
      });
  }

  /// <summary>
  /// Author : -
  /// Used to set logo
  /// </summary>
  const logoDrop = (acceptedFiles) => {
    if (acceptedFiles.length !== 0) {
      const file = acceptedFiles[0];
      setLogoFile(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setLogoImage(reader.result);
        setIsNewImage(true);
      };
      reader.readAsDataURL(file);
    }
  };

  /// <summary>
  /// Author : -
  /// </summary>
  const handleEvent = (event, picker) => {
    setStartDate(moment(picker.startDate).format("YYYY-MM-DD"));
    setEndDate(moment(picker.endDate).format("YYYY-MM-DD"));
  };

  /// <summary>
  /// Author : -
  /// Editted : - - Added Checking for date range
  /// </summary>
  const submitForm = async (data, e) => {
    if (stringIsNullOrEmpty(startDate) || stringIsNullOrEmpty(endDate)) {
      setError("dateRange", {
        type: "Required",
        message: "PLEASE_SELECT_DATE_RANGE",
      });
      return;
    }

    var bonusDataString = [];
    for (var i = 0; bonusDayData != null && i < bonusDayData.length; i++) {
      bonusDataString.push(bonusDayData[i].value);
    }

    let params = {
      code: data.code,
      title: data.title,
      content: data.content,
      settingTypeId: data.settingTypeId,
      isShowPlayer: data.showPlayer,
      methodId: data.methodId,
      minimumDeposit: data.minimumDeposit,
      maximumBonusAmount: data.maximumDeposit,
      turnover: data.turnover,
      remark: data.remark,
      startDate: startDate,
      endDate: endDate,
      bonusTypeId: bonusTypeId,
      amount: data.amount,
      bonusStartTime: bonusStartTime,
      bonusEndTime: bonusEndTime,
      bonusDay: bonusDataString.join(),
      isNewBonusImage: isNewImage,
      claimCount: data.claimCount,
      vipId: stringIsNullOrEmpty(vip) ? "" : vip,
    };

    if (!stringIsNullOrEmpty(bonusId)) {
      params["id"] = bonusId;
    }

    if (!stringIsNullOrEmpty(bonusRefId)) {
      params["refId"] = bonusRefId;
    }

    if (!stringIsNullOrEmpty(logoFile)) {
      params["bonusImage"] = logoFile;
    }

    if (stringIsNullOrEmpty(logoImage)) {
      params["bonusImageRemoved"] = true;
    }

    if (!stringIsNullOrEmpty(bonusId)) {
      params["id"] = bonusId;
    }

    let formBody = createMultiPartFormBody(params);
    _dispatch(setBusy());

    fetch(ApiUrl._API_CREATE_OR_UPDATE_BONUS, {
      method: ApiKey._API_POST,
      headers: {
        "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
      },
      body: formBody,
    })
      .then((response) => response.json())
      .then((responseJson) => {
        _dispatch(setIdle());
        _dispatch(
          showMessage(
            responseJson[ApiKey._API_SUCCESS_KEY],
            t(responseJson[ApiKey._API_MESSAGE_KEY])
          )
        );

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
          setIsNewImage(false);
          setBonusRefId("");
          setBonusId(responseJson[ApiKey._API_DATA_KEY]["id"]);

          _history.replace({
            pathname: _location.pathname,
            state: {
              id: responseJson[ApiKey._API_DATA_KEY]["id"],
            },
          });
        }
      });
  };

  return (
    <div>
      <h1 className="page-header">
        {bonusId ? t("EDIT_BONUS_SETTING") : t("ADD_BONUS_SETTING")}
        <NavigationButton history={_history} />
      </h1>
      {bonusId && (
        <Nav tabs>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === _DETAIL_TAB })}
              onClick={() => {
                toggle(_DETAIL_TAB);
              }}
            >
              {t("DETAIL")}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === _LANGUAGE_TAB })}
              onClick={() => {
                toggle(_LANGUAGE_TAB);
              }}
            >
              {t("LANGUAGE")}
            </NavLink>
          </NavItem>
        </Nav>
      )}
      <TabContent activeTab={activeTab}>
        <TabPane tabId={_DETAIL_TAB}>
          <div className="row">
            <div className="col-xl-12">
              <Panel>
                <PanelBody>
                  <form onSubmit={handleSubmit(submitForm)}>
                    <div className="row">
                      <div className="col-lg-8">
                        <div className="form-group row m-b-15">
                          <label className="col-md-4 col-form-label">
                            <b>{t("CODE")}</b>
                          </label>
                          <div className="col-md-7">
                            <input
                              type="text"
                              className="form-control"
                              placeholder={t("PLEASE_ENTER_CODE")}
                              name="code"
                              ref={register({ required: "PLEASE_ENTER_CODE" })}
                              defaultValue={code}
                            />
                            {errors.code && (
                              <div className="invalid-feedback">
                                {t(errors.code.message)}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-8">
                        <div className="form-group row m-b-15">
                          <label className="col-md-4 col-form-label">
                            <b>{t("TITLE")}</b>
                          </label>
                          <div className="col-md-7">
                            <input
                              type="text"
                              className="form-control"
                              placeholder={t("PLEASE_ENTER_TITLE")}
                              name="title"
                              ref={register({ required: "PLEASE_ENTER_TITLE" })}
                              defaultValue={title}
                            />
                            {errors.title && (
                              <div className="invalid-feedback">
                                {t(errors.title.message)}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-8">
                        <div className="form-group row m-b-15">
                          <label className="col-md-4 col-form-label">
                            <b>{t("CONTENT")}</b>
                          </label>
                          <div className="col-md-7">
                            <input
                              type="text"
                              className="form-control"
                              placeholder={t("PLEASE_ENTER_CONTENT")}
                              name="content"
                              ref={register({
                                required: "PLEASE_ENTER_CONTENT",
                              })}
                              defaultValue={content}
                            />
                            {errors.content && (
                              <div className="invalid-feedback">
                                {t(errors.content.message)}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-8">
                        <div className="form-group row m-b-15">
                          <label className="col-md-4 col-form-label">
                            <b>{t("VALID_DATE")}</b>
                          </label>
                          <div className="col-md-7">
                            <div
                              className="form-group"
                              style={{ display: "flex" }}
                            >
                              <div
                                className="input-group"
                                style={{ width: "unset" }}
                              >
                                <div className="input-group-prepend">
                                  <span className="input-group-text">
                                    <i className="fa fa-calendar"></i>
                                  </span>
                                </div>
                                <div style={{ width: "80%" }}>
                                  <DateRangePicker
                                    alwaysShowCalendars={true}
                                    onApply={handleEvent}
                                    ranges={{
                                      Today: [moment(), moment()],
                                      Yesterday: [
                                        moment().subtract(1, "days"),
                                        moment().subtract(1, "days"),
                                      ],
                                      "Last 7 Days": [
                                        moment().subtract(6, "days"),
                                        moment(),
                                      ],
                                      "Last 30 Days": [
                                        moment().subtract(29, "days"),
                                        moment(),
                                      ],
                                      "This Month": [
                                        moment().startOf("month"),
                                        moment().endOf("month"),
                                      ],
                                      "Last Month": [
                                        moment()
                                          .subtract(1, "month")
                                          .startOf("month"),
                                        moment()
                                          .subtract(1, "month")
                                          .endOf("month"),
                                      ],
                                      "This Year": [
                                        moment().startOf("year"),
                                        moment().endOf("year"),
                                      ],
                                    }}
                                  >
                                    <input
                                      type="text"
                                      name="dateRange"
                                      className="form-control"
                                      value={startDate + " - " + endDate}
                                    />
                                  </DateRangePicker>
                                </div>
                              </div>
                            </div>
                            {errors.dateRange && (
                              <div className="invalid-feedback">
                                {t(errors.dateRange.message)}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-8">
                        <div className="form-group row m-b-15">
                          <label className="col-md-4 col-form-label">
                            <b>{t("CLAIM_PERIOD")}</b>
                          </label>
                          <div className="col-md-7">
                            <Select
                              name="bonusDay"
                              ref={register}
                              value={bonusDayData}
                              options={_DAY_OPTION}
                              isMulti
                              onChange={(e) => {
                                if (e == null || e.length == 0) {
                                  register(
                                    { name: "bonusDay" },
                                    {
                                      required: "PLEASE_ENTER_AT_LEAST_ONE_DAY",
                                    }
                                  );
                                } else {
                                  unregister("bonusDay");
                                }
                                setBonusDayData(e);
                              }}
                            />
                            {errors.bonusDay && (
                              <div className="invalid-feedback">
                                {t(errors.bonusDay.message)}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-8">
                        <div className="form-group row m-b-15">
                          <label className="col-md-4 col-form-label">
                            <b>{t("CLAIM_PERIOD_TIME")}</b>
                          </label>
                          <div className="col-md-7" style={{ display: "flex" }}>
                            <DateTime
                              name="bonusStartTime"
                              dateFormat={false}
                              ref={register}
                              value={moment(
                                moment().format("YYYY-MM-DD") +
                                  " " +
                                  bonusStartTime,
                                "YYYY-MM-DD HH:mm"
                              )}
                              onChange={(e) => {
                                if (e instanceof moment) {
                                  setBonusStartTime(e.format("HH:mm"));
                                  unregister("bonusStartTime");
                                } else {
                                  register(
                                    { name: "bonusStartTime" },
                                    {
                                      required: "PLEASE_ENTER_BONUS_START_TIME",
                                    }
                                  );
                                }
                              }}
                              timeFormat="HH:mm"
                              inputProps={{ placeholder: "Start Time" }}
                            />
                            {errors.bonusStartTime && (
                              <div className="invalid-feedback">
                                {t(errors.bonusStartTime.message)}
                              </div>
                            )}
                            <label
                              style={{
                                marginLeft: "10px",
                                marginRight: "10px",
                              }}
                              className="col-form-label"
                            >
                              To
                            </label>
                            <DateTime
                              name="bonusEndTime"
                              dateFormat={false}
                              ref={register}
                              value={moment(
                                moment().format("YYYY-MM-DD") +
                                  " " +
                                  bonusEndTime,
                                "YYYY-MM-DD HH:mm"
                              )}
                              onChange={(e) => {
                                if (e instanceof moment) {
                                  setBonusEndTime(e.format("HH:mm"));
                                  unregister("bonusEndTime");
                                } else {
                                  register(
                                    { name: "bonusEndTime" },
                                    { required: "PLEASE_ENTER_BONUS_END_TIME" }
                                  );
                                }
                              }}
                              timeFormat="HH:mm"
                              inputProps={{ placeholder: "End Time" }}
                            />
                            {errors.bonusEndTime && (
                              <div className="invalid-feedback">
                                {t(errors.bonusEndTime.message)}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-8">
                        <div className="form-group row m-b-15">
                          <label className="col-md-4 col-form-label">
                            <b>{t("SETTING_TYPE")}</b>
                          </label>
                          <div className="col-md-7">
                            <select
                              className="form-control"
                              name="settingTypeId"
                              ref={register}
                              onChange={(e) => setSettingTypeId(e.target.value)}
                              value={settingTypeId}
                            >
                              <option value={BonusSettingType._MAIN}>
                                {t("MAIN")}
                              </option>
                              <option value={BonusSettingType._SECONDARY}>
                                {t("SECONDARY")}
                              </option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-8">
                        <div className="form-group row m-b-15">
                          <label className="col-md-4 col-form-label">
                            <b>{t("TYPE")}</b>
                          </label>
                          <div className="col-md-7">
                            <Select
                              name="bonusTypeId"
                              options={bonusTypeOption}
                              placeholder={
                                bonusTypeOption.filter(
                                  (option) => option.value == bonusTypeId
                                )[0] !== undefined
                                  ? bonusTypeOption.filter(
                                      (option) => option.value == bonusTypeId
                                    )[0].label
                                  : ""
                              }
                              value={bonusTypeOption.filter(
                                (option) => option.value == bonusTypeId
                              )}
                              onChange={(e) => {
                                unregister("bonusTypeId");
                                setBonusTypeId(e.value);
                              }}
                            />
                            {errors.bonusTypeId && (
                              <div className="invalid-feedback">
                                {t(errors.bonusTypeId.message)}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-8">
                        <div className="form-group row m-b-15">
                          <label className="col-md-4 col-form-label">
                            <b>{t("ISSHOWPLAYER")}</b>
                          </label>
                          <div className="col-md-7">
                            <div className="switcher">
                              <input
                                type="checkbox"
                                name="showPlayer"
                                id="showPlayer"
                                onChange={(e) =>
                                  setShowPlayer(e.target.checked)
                                }
                                value={true}
                                checked={showPlayer}
                                ref={register}
                              />
                              <label htmlFor="showPlayer"></label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-8">
                        <div className="form-group row m-b-15">
                          <label className="col-md-4 col-form-label">
                            <b>{t("METHOD")}</b>
                          </label>
                          <div className="col-md-7">
                            <select
                              className="form-control"
                              name="methodId"
                              ref={register}
                              onChange={(e) => setMethodId(e.target.value)}
                              value={methodId}
                            >
                              <option value={BonusMethodType._AMOUNT}>
                                {t("AMOUNT")}
                              </option>
                              <option value={BonusMethodType._PERCENT}>
                                {t("PERCENT")}
                              </option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-8">
                        <div className="form-group row m-b-15">
                          <label className="col-md-4 col-form-label">
                            <b>{t("AMOUNT")}</b>
                          </label>
                          <div className="col-md-7">
                            <div className="input-group mb-3">
                              <input
                                type="number"
                                className="form-control"
                                defaultValue={amount}
                                style={{ height: "auto" }}
                                name="amount"
                                ref={register({
                                  required: "PLEASE_ENTER_AMOUNT",
                                  min: {
                                    value: 0,
                                    message:
                                      "PLEASE_ENTER_VALUE_NOT_LESS_THAN_ZERO",
                                  },
                                  max: {
                                    value:
                                      methodId == BonusMethodType._AMOUNT
                                        ? MAXIMUM_DEPOSIT_AMOUNT
                                        : 100,
                                    message: "PLEASE_ENTER_VALUE_NOT_MORE_THAN",
                                  },
                                })}
                              />
                              {errors.amount && (
                                <div className="invalid-feedback">
                                  {t(errors.amount.message, {
                                    value:
                                      methodId == BonusMethodType._AMOUNT
                                        ? MAXIMUM_DEPOSIT_AMOUNT
                                        : 100,
                                  })}
                                </div>
                              )}
                              <div className="input-group-append">
                                <span className="input-group-text">
                                  {methodId &&
                                    (methodId == BonusMethodType._AMOUNT
                                      ? ""
                                      : "%")}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-8">
                        <div className="form-group row m-b-15">
                          <label className="col-md-4 col-form-label">
                            <b>{t("MINIMUM_DEPOSIT")}</b>
                          </label>
                          <div className="col-md-7">
                            <input
                              type="number"
                              className="form-control"
                              placeholder={t("PLEASE_ENTER_MINIMUM_DEPOSIT")}
                              name="minimumDeposit"
                              defaultValue={minimumDeposit}
                              ref={register({
                                required: "PLEASE_ENTER_MINIMUM_DEPOSIT",
                                min: {
                                  value: 0,
                                  message:
                                    "PLEASE_ENTER_VALUE_NOT_LESS_THAN_ZERO",
                                },
                              })}
                            />
                            {errors.minimumDeposit && (
                              <div className="invalid-feedback">
                                {t(errors.minimumDeposit.message)}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-8">
                        <div className="form-group row m-b-15">
                          <label className="col-md-4 col-form-label">
                            <b>{t("MAXIMUM_DEPOSIT")}</b>
                          </label>
                          <div className="col-md-7">
                            <input
                              type="number"
                              className="form-control"
                              placeholder={t("PLEASE_ENTER_MAXIMUM_DEPOSIT")}
                              name="maximumDeposit"
                              defaultValue={maximumDeposit}
                              ref={register({
                                required: "PLEASE_ENTER_MAXIMUM_DEPOSIT",
                                min: {
                                  value: 0,
                                  message:
                                    "PLEASE_ENTER_VALUE_NOT_LESS_THAN_ZERO",
                                },
                              })}
                            />
                            {errors.maximumDeposit && (
                              <div className="invalid-feedback">
                                {t(errors.maximumDeposit.message)}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-8">
                        <div className="form-group row">
                          <label className="col-md-4 col-form-label">
                            <b>{t("TURNOVER")}</b>
                          </label>
                          <div className="col-md-7">
                            <div style={{ display: "flex" }}>
                              <input
                                style={{ width: "45%" }}
                                type="number"
                                className="form-control"
                                placeholder={t("PLEASE_ENTER_TURNOVER")}
                                name="turnover"
                                defaultValue={turnover}
                                ref={register({
                                  required: "PLEASE_ENTER_TURNOVER",
                                  min: {
                                    value: 0,
                                    message:
                                      "PLEASE_ENTER_VALUE_NOT_LESS_THAN_ZERO",
                                  },
                                })}
                              />
                              {errors.turnover && (
                                <div className="invalid-feedback">
                                  {t(errors.turnover.message)}
                                </div>
                              )}
                              <input
                                type="text"
                                style={{ marginLeft: "10px", width: "55%" }}
                                readonly
                                className="form-control"
                                value={
                                  "x (" +
                                  t("DEPOSIT_AMOUNT") +
                                  " + " +
                                  t("BONUS_AMOUNT") +
                                  ")"
                                }
                              ></input>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-8">
                        <div className="form-group row">
                          <label className="col-md-4 col-form-label">
                            <b>{t("REMARK")}</b>
                          </label>
                          <div className="col-md-7">
                            <input
                              type="text"
                              className="form-control"
                              placeholder={t("PLEASE_ENTER_REMARK")}
                              name="remark"
                              ref={register({
                                required: "PLEASE_ENTER_REMARK",
                              })}
                              defaultValue={remark}
                            />
                            {errors.remark && (
                              <div className="invalid-feedback">
                                {t(errors.remark.message)}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-8">
                        <div className="form-group row">
                          <label className="col-md-4 col-form-label">
                            <b>{t("VIP_LEVEL")}</b>
                          </label>
                          <div className="col-md-7">
                            <Select
                              name="vip"
                              options={vipOption}
                              placeholder={
                                vipOption.filter(
                                  (option) => option.value == vip
                                )[0] !== undefined
                                  ? vipOption.filter(
                                      (option) => option.value == vip
                                    )[0].label
                                  : ""
                              }
                              value={vipOption.filter(
                                (option) => option.value == vip
                              )}
                              onChange={(e) => {
                                unregister("vip");
                                setVip(e.value);
                              }}
                            />
                            {errors.vip && (
                              <div className="invalid-feedback">
                                {t(errors.vip.message)}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-8">
                        <div className="form-group row m-b-15">
                          <label className="col-md-4 col-form-label">
                            <b>{t("CLAIM_COUNT")}</b>
                            <div>{t("ZERO_WILL_BE_REPRESENTED_AS_INFINITY")}</div>
                          </label>
                          <div className="col-md-7">
                            <input
                              type="number"
                              className="form-control"
                              placeholder={t("PLEASE_ENTER_CLAIM_COUNT")}
                              name="claimCount"
                              defaultValue={claimCount}
                              ref={register({
                                required: "PLEASE_ENTER_CLAIM_COUNT",
                                min: {
                                  value: 0,
                                  message:
                                    "PLEASE_ENTER_VALUE_NOT_LESS_THAN_ZERO",
                                },
                              })}
                            />
                            {errors.claimCount && (
                              <div className="invalid-feedback">
                                {t(errors.claimCount.message)}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-8">
                        <div className="form-group row m-b-15">
                          <label className="col-md-4 col-form-label">
                            <b>{t("IMAGE_UPLOAD")}</b>
                          </label>
                          <div className="col-md-7">
                            <Dropzone
                              accept={"image/*"}
                              onDrop={(acceptedFiles) =>
                                logoDrop(acceptedFiles)
                              }
                            >
                              {({ getRootProps, getInputProps }) => (
                                <section>
                                  <div
                                    className="dropzone"
                                    style={{
                                      minHeight: "200px",
                                      textAlign: "center",
                                    }}
                                    {...getRootProps()}
                                  >
                                    <input {...getInputProps()} />
                                    <h4 style={{ color: "grey" }}>
                                      {t(
                                        "DRAG_N_DROP_SOME_FILES_HERE_OR_CLICK_TO_SELECT_FILES"
                                      )}
                                    </h4>
                                    {!stringIsNullOrEmpty(logoImage) && (
                                      <aside className="thumbsContainer">
                                        <div className="thumb">
                                          <div className="thumbInner">
                                            <img
                                              src={logoImage}
                                              className="dropzone-img"
                                            />
                                          </div>
                                        </div>
                                      </aside>
                                    )}
                                  </div>
                                </section>
                              )}
                            </Dropzone>
                          </div>
                        </div>
                      </div>
                    </div>
                    <hr />
                    <div className="row">
                      <div className="col-lg-3">
                        <div className="form-group">
                          <button type="submit" className="btn btn-primary">
                            {t("SUBMIT")}
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </PanelBody>
              </Panel>
            </div>
          </div>
        </TabPane>
        <TabPane tabId={_LANGUAGE_TAB}>
          {bonusId && (
            <div className="row">
              <div className="col-lg-12">
                <ManageConfigurationLanguage
                  id={bonusId}
                  type={ContentTypeString._BONUS}
                />
              </div>
            </div>
          )}
        </TabPane>
      </TabContent>
    </div>
  );
};

export default BonusDetail;
