import React, { useState, useEffect } from "react";
import ReactTable from "../../components/constantComponent/reactTable/ReactTable";
import { Panel, PanelBody } from "./../../components/panel/panel.jsx";
import moment from "moment";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { withRouter } from "react-router-dom";
import { ApiKey, ApiUrl, LanguageKey } from "../../util/Constant";
import UserIpPanel from "./UserIpPanel";
import UserIpDetailPanel from "./UserIpDetailPanel";
import { useTranslation } from "react-i18next";
import { fetch } from "whatwg-fetch";

/// <summary>
/// Author : -
/// </summary>

const ManageIPMonitor = (props) => {
  const { t } = useTranslation(LanguageKey._PRIMARY);
  const USERNAME_OPTION = "USERNAME";
  const IP_OPTION = "IP";
  const [startDate, setStartDate] = useState(
    moment().startOf("day").format("YYYY-MM-DD HH:mm:ss")
  );
  const [endDate, setEndDate] = useState(
    moment().endOf("day").format("YYYY-MM-DD HH:mm:ss")
  );
  const [ipMonitorRecords, setIpMonitorRecords] = useState([]);
  const [selectedOption, setSelectedOption] = useState(USERNAME_OPTION);
  const [modal, setModal] = useState(false);
  const [nestedModal, setNestedModal] = useState(false);
  const [closeAll, setCloseAll] = useState(false);
  const [groupedState, setGroupedState] = useState({});
  const [ipDetailState, setIpDetailState] = useState({});
  const [searchText, setSearchText] = useState("");

  /// <summary>
  /// Author : -
  /// </summary>
  const toggle = () => setModal(!modal);

  /// <summary>
  /// Author : -
  /// </summary>
  const toggleNested = () => {
    setNestedModal(!nestedModal);
    setCloseAll(false);
  };

  /// <summary>
  /// Author : -
  /// </summary>
  const toggleAll = () => {
    setNestedModal(!nestedModal);
    setCloseAll(true);
  };

  /// <summary>
  /// Author : -
  /// </summary>
  useEffect(() => {
    if (props.location.state) {
      setSearchText(props.location.state.ip);
      setSelectedOption(IP_OPTION);
    }
    GetIpMonitorRecord();
  }, []);

  /// <summary>
  /// Author : -
  /// </summary>
  const GetIpMonitorRecord = async () => {
    let apiUrl = ApiUrl._API_GET_IP_MONITOR_RECORD;
    apiUrl += "?startDate=" + startDate + "&endDate=" + endDate;

    if (selectedOption == USERNAME_OPTION) {
      apiUrl += "&username=" + searchText;
    } else {
      apiUrl += "&ipAddress=" + searchText;
    }

    await fetch(apiUrl, {
      method: ApiKey._API_GET,
      headers: {
        "Content-Type": ApiKey._API_APPLICATION_JSON,
        "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
          setIpMonitorRecords(responseJson[ApiKey._API_DATA_KEY]);
        }
      });
  };

  /// <summary>
  /// Author : -
  /// </summary>
  const prepareStateForGroupedIP = (rowState) => {
    let dateObj = {
      startDate: startDate,
      endDate: endDate,
    };
    let newState = Object.assign(rowState, dateObj);
    setGroupedState(newState);
  };

  /// <summary>
  /// Author : -
  /// </summary>
  const prepareStateForUserIpDetail = (rowState) => {
    let dateObj = {
      startDate: startDate,
      endDate: endDate,
    };
    let newState = Object.assign(rowState, dateObj);
    setIpDetailState(newState);
  };

  let _IPColumns = [
    {
      Header: "IP",
      accessor: "ipAddress",
    },
    {
      Header: "COUNTRY",
      accessor: "country",
    },
    {
      Header: "UNIQUE_USER_COUNT",
      accessor: "uniqueUserCount",
    },
    {
      id: "action",
      Header: "",
      Cell: ({ row }) => {
        return (
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => {
              toggle();
              prepareStateForGroupedIP(row.original);
            }}
          >
            {t("SHOW_USERS")}
          </button>
        );
      },
      disableFilters: true,
      disableSortBy: true,
    },
  ];

  /// <summary>
  /// Author : -
  /// </summary>
  const handleEvent = (event, picker) => {
    setStartDate(moment(picker.startDate).format("YYYY-MM-DD HH:mm:ss"));
    setEndDate(moment(picker.endDate).format("YYYY-MM-DD HH:mm:ss"));
  };

  /// <summary>
  /// Author : -
  /// </summary>
  const handleSelectChange = (event) => {
    setSelectedOption(event.target.value);
  };

  /// <summary>
  /// Author : -
  /// </summary>
  const handleSearchTextChange = (event) => {
    setSearchText(event.target.value);
  };

  return (
    <div>
      <h1 className="page-header">{t("IP_MONITOR")}</h1>
      <Panel>
        <PanelBody>
          <div className="row">
            <div className="col-lg-12">
              <div className="row">
                <div className="col-lg-4">
                  <div
                    className="form-group"
                    style={{ display: "flex", width: "100%" }}
                  >
                    <div className="input-group" style={{ width: "100%" }}>
                      <div className="input-group-prepend">
                        <span className="input-group-text">
                          <i className="fa fa-calendar"></i>
                        </span>
                      </div>
                      <div style={{ width: "80%" }}>
                        <DateRangePicker
                          containerStyles={{ width: "100%" }}
                          startDate={startDate}
                          endDate={endDate}
                          onApply={handleEvent}
                          alwaysShowCalendars={true}
                          locale={{ format: "YYYY-MM-DD HH:mm:ss" }}
                          timePicker={true}
                          timePickerSeconds={true}
                          ranges={{
                            Today: [moment(), moment()],
                            Yesterday: [
                              moment().subtract(1, "days"),
                              moment().subtract(1, "days"),
                            ],
                            "Last 7 Days": [
                              moment().subtract(6, "days"),
                              moment(),
                            ],
                            "Last 30 Days": [
                              moment().subtract(29, "days"),
                              moment(),
                            ],
                            "This Month": [
                              moment().startOf("month"),
                              moment().endOf("month"),
                            ],
                            "Last Month": [
                              moment().subtract(1, "month").startOf("month"),
                              moment().subtract(1, "month").endOf("month"),
                            ],
                          }}
                        >
                          <input
                            type="text"
                            className="form-control"
                            value={startDate + " - " + endDate}
                          />
                        </DateRangePicker>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="input-group mb-3">
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        <i className="fas fa-user"></i>
                      </span>
                    </div>
                    <input
                      type="text"
                      className="form-control"
                      placeholder={t("PLEASE_ENTER_VALUE")}
                      value={searchText}
                      onChange={handleSearchTextChange}
                      style={{ height: "auto" }}
                    ></input>
                    <div className="input-group-prepend">
                      <select
                        className="form-control"
                        value={selectedOption}
                        onChange={handleSelectChange}
                      >
                        <option>{t(USERNAME_OPTION)}</option>
                        <option>{t(IP_OPTION)}</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3">
                  <button
                    type="button"
                    onClick={() => {
                      GetIpMonitorRecord();
                    }}
                    className="btn btn-primary"
                  >
                    {t("SEARCH")}
                  </button>
                </div>
              </div>
              <hr />
              <ReactTable data={ipMonitorRecords} columns={_IPColumns} />
              <Modal isOpen={modal} toggle={toggle} size="xl">
                <ModalHeader toggle={toggle}>{t("USER_IP")}</ModalHeader>
                <ModalBody>
                  <UserIpPanel
                    toggleNested={toggleNested}
                    data={groupedState}
                    prepareStateForUserIpDetail={prepareStateForUserIpDetail}
                  />
                  <Modal
                    isOpen={nestedModal}
                    toggle={toggleNested}
                    size="xl"
                    onClosed={closeAll ? toggle : undefined}
                  >
                    <ModalHeader>{t("USER_IP_DETAILS")}</ModalHeader>
                    <ModalBody>
                      <UserIpDetailPanel data={ipDetailState} />
                    </ModalBody>
                  </Modal>
                </ModalBody>
              </Modal>
            </div>
          </div>
        </PanelBody>
      </Panel>
    </div>
  );
};

export default withRouter(ManageIPMonitor);
