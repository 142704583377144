import React, { useState, useEffect } from "react";
import ReactTable from "../../components/constantComponent/reactTable/ReactTable";
import { Panel, PanelBody } from "./../../components/panel/panel.jsx";
import moment from "moment";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { showMessage } from "../../redux/AppAction";
import { useDispatch, useSelector } from "react-redux";
import {
  ApiKey,
  ApiUrl,
  LanguageKey,
  DEFAULT_PAGE_SIZE,
} from "../../util/Constant";
import { stringIsNullOrEmpty, numberWithCurrencyFormat } from "../../util/Util";
import { useTranslation } from "react-i18next";
import ApiEngine from "../../util/ApiEngine.js";
import Select from "react-select";
import { AsyncPaginate } from "react-select-async-paginate";

/// <summary>
/// Author : -
/// </summary>

const ManageBonusCommReport = (props) => {
  let _dispatch = useDispatch();
  const { t } = useTranslation(LanguageKey._PRIMARY);
  const [reportData, setReportData] = useState([]);
  const [startDate, setStartDate] = useState(
    moment().startOf("day").format("YYYY-MM-DD HH:mm:ss")
  );
  const [endDate, setEndDate] = useState(
    moment().format("YYYY-MM-DD HH:mm:ss")
  );
  const [userHierachy, setUserHierachy] = useState([]);
  const _username = useSelector(
    (state) => state["authState"]["userData"]["username"]
  );
  const [allMembers, setAllMembers] = useState([]);
  const [selectedMember, setSelectedMember] = useState("");
  const [allBonusMembers, setAllBonusMembers] = useState([]);
  const [selectedBonusMember, setSelectedBonusMember] = useState("");
  const ALL_OPTION_LABEL = "All";
  const ALL_OPTION_VALUE = "all";
  const [selectedProduct, setSelectedProduct] = useState(ALL_OPTION_VALUE);
  const [productList, setProductList] = useState([]);
  const [playerId, setPlayerId] = useState();
  const [bonusPlayerId, setBonusPlayerId] = useState();
  const _PAGING_COUNT = 10;

  /// <summary>
  /// Author : -
  /// </summary>
  const _OPTION_STYLES = {
    control: (base) => ({
      ...base,
      "min-height": "34px",
      height: "34px",
    }),
    valueContainer: (base) => ({
      ...base,
      height: "34px",
      "min-height": "34px",
    }),
    indicatorsContainer: (base) => ({
      ...base,
      height: "34px",
      "min-height": "34px",
    }),
    input: (base) => ({
      height: "34px",
      "min-height": "34px",
    }),
  };

  let _tableColumns = [
    {
      Header: "TRANSACTION_ID",
      accessor: "id",
      Cell: ({ row }) => {
        return (
          <button
            type="button"
            className="btn btn-link"
            onClick={() => {
              readSubReport(row.original.id);
            }}
          >
            {row.original.id}
          </button>
        );
      },
      disableSortBy: true,
    },
    {
      Header: "DATE",
      accessor: "createdTime",
      disableSortBy: true,
    },
    {
      Header: "USERNAME",
      accessor: "transferPerson",
      disableSortBy: true,
    },
    {
      Header: "TRASFER_PLAYER",
      accessor: "transferPlayer",
      disableSortBy: true,
    },
    {
      Header: "WALLET_BEFORE_BALANCE",
      accessor: "walletBefore",
      Cell: ({ row }) => {
        return numberWithCurrencyFormat(parseFloat(row.original.walletBefore));
      },
      disableSortBy: true,
    },
    {
      Header: "DEPOSIT_AMOUNT",
      Cell: ({ row }) => {
        return numberWithCurrencyFormat(
          -parseFloat(row.original.depositAmount)
        );
      },
      disableSortBy: true,
    },
    {
      Header: "WALLET_AFTER_BALANCE",
      accessor: "walletAfter",
      Cell: ({ row }) => {
        return numberWithCurrencyFormat(parseFloat(row.original.walletAfter));
      },
      disableSortBy: true,
    },
    {
      Header: "BONUS_RECEIVE_MEMBER",
      accessor: "targetMember",
      disableSortBy: true,
    },
    {
      Header: "BONUS_RECEIVE_PLAYER",
      accessor: "targetPlayer",
      disableSortBy: true,
    },
    {
      Header: "BONUS_RATE",
      accessor: "bonusRate",
      Cell: ({ row }) => {
        return numberWithCurrencyFormat(parseFloat(row.original.bonusRate));
      },
      disableSortBy: true,
    },
    {
      Header: "GAME_WALLET_BEFORE_BALANCE",
      accessor: "gameWalletBefore",
      Cell: ({ row }) => {
        return numberWithCurrencyFormat(
          parseFloat(row.original.gameWalletBefore)
        );
      },
      disableSortBy: true,
    },
    {
      Header: "AMOUNT",
      Cell: ({ row }) => {
        if (row.original.transferPlayer === row.original.targetPlayer) {
          return numberWithCurrencyFormat(
            parseFloat(row.original.depositAmount)
          );
        } else {
          return numberWithCurrencyFormat(0);
        }
      },
      disableSortBy: true,
    },
    {
      Header: "BONUS",
      accessor: "bonus",
      Cell: ({ row }) => {
        return numberWithCurrencyFormat(parseFloat(row.original.bonus));
      },
      disableSortBy: true,
    },
    {
      Header: "GAME_WALLET_AFTER_BALANCE",
      accessor: "gameWalletAfter",
      Cell: ({ row }) => {
        return numberWithCurrencyFormat(
          parseFloat(row.original.gameWalletAfter)
        );
      },
      disableSortBy: true,
    },
  ];

  /// <summary>
  /// Author : -
  /// </summary>
  useEffect(() => {
    init();
  }, []);

  /// <summary>
  /// Author : -
  /// </summary>
  async function init() {
    await fetch(ApiUrl._API_GET_MASTER_PRODUCT_ASSIGNMENT_DATA, {
      method: ApiKey._API_GET,
      headers: {
        "Content-Type": ApiKey._API_FORM_URLENCODED,
        "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
          if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            const accountProductOptionList = [
              { label: ALL_OPTION_LABEL, value: ALL_OPTION_VALUE },
            ];
            responseJson[ApiKey._API_DATA_KEY].forEach(function (option) {
              accountProductOptionList.push({
                label: option.productName,
                value: option.id,
              });
            });

            setProductList(accountProductOptionList);
          }
        } else {
          _dispatch(
            showMessage(
              responseJson[ApiKey._API_SUCCESS_KEY],
              t(responseJson[ApiKey._API_MESSAGE_KEY])
            )
          );
        }
      });
  }

  /// <summary>
  /// Author : -
  /// </summary>
  const handleEvent = (event, picker) => {
    setStartDate(moment(picker.startDate).format("YYYY-MM-DD HH:mm:ss"));
    setEndDate(moment(picker.endDate).format("YYYY-MM-DD HH:mm:ss"));
  };

  /// <summary>
  /// Author : -
  /// </summary>
  async function readReport(memberId, bonusMemberId, searchDownline = true) {
    try {
      var apiUrl =
        ApiUrl._API_MANAGE_TRANSFER_WALLET +
        "?dateFrom=" +
        startDate +
        "&dateTo=" +
        endDate +
        "&searchDownline=" +
        searchDownline;

      if (!stringIsNullOrEmpty(memberId)) {
        apiUrl += "&memberId=" + memberId;
      }

      if (!stringIsNullOrEmpty(bonusMemberId)) {
        apiUrl += "&bonusMemberId=" + bonusMemberId;
      }

      if (selectedProduct != ALL_OPTION_VALUE) {
        apiUrl += "&masterProductId=" + selectedProduct;
      }

      if (!stringIsNullOrEmpty(playerId)) {
        apiUrl += "&playerId=" + playerId;
      }

      if (!stringIsNullOrEmpty(bonusPlayerId)) {
        apiUrl += "&bonusPlayerId=" + bonusPlayerId;
      }

      var responseJson = await ApiEngine.get(apiUrl);
      console.log(responseJson[ApiKey._API_DATA_KEY]);
      if (responseJson[ApiKey._API_SUCCESS_KEY]) {
        setReportData(responseJson[ApiKey._API_DATA_KEY]);
      } else {
        throw responseJson[ApiKey._API_MESSAGE_KEY];
      }
    } catch (error) {
      _dispatch(showMessage(false, t(error)));
    }
  }

  async function readSubReport(targetTransferId) {
    try {
      var apiUrl =
        ApiUrl._API_MANAGE_TRANSFER_WALLET +
        "?dateFrom=" +
        startDate +
        "&dateTo=" +
        endDate +
        "&searchDownline=" +
        true;

      if (!stringIsNullOrEmpty(targetTransferId)) {
        apiUrl += "&targetTransferId=" + targetTransferId;
        apiUrl += "&isSubReport=" + true;
      }

      var responseJson = await ApiEngine.get(apiUrl);
      console.log(responseJson[ApiKey._API_DATA_KEY]);
      if (responseJson[ApiKey._API_SUCCESS_KEY]) {
        setReportData(responseJson[ApiKey._API_DATA_KEY]);
      } else {
        throw responseJson[ApiKey._API_MESSAGE_KEY];
      }
    } catch (error) {
      _dispatch(showMessage(false, t(error)));
    }
  }

  /// <summary>
  /// Author : Wong
  /// </summary>
  async function getMemberList(search, loadOptions, { page }, type) {
    console.log(type);
    var responseJson = await ApiEngine.get(
      `${ApiUrl._API_GET_USER_MEMBER_LIST}?start=${
        (page - 1) * DEFAULT_PAGE_SIZE
      }&count=${DEFAULT_PAGE_SIZE}&keyword=${search}`
    );

    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      let data = responseJson[ApiKey._API_DATA_KEY]["data"];
      let formattedData = data.map((m) => {
        return { label: m.username, value: m.id };
      });

      return {
        options: formattedData,
        hasMore: formattedData.length == _PAGING_COUNT,
        additional: {
          page: page + 1,
        },
      };
    }
  }

  return (
    <div>
      <h1 className="page-header">
        {stringIsNullOrEmpty(props.id) && t("WALLET_TRANSFER_REPORT")}
      </h1>
      <Panel>
        <PanelBody>
          <div className="row">
            <div className="col-lg-3">
              <div className="form-group">
                <label>
                  <b>{t("DATE")}</b>
                </label>
                <div
                  className="input-group"
                  style={{ width: "100%", flexFlow: "nowrap" }}
                >
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <i className="fa fa-calendar"></i>
                    </span>
                  </div>
                  <div style={{ width: "80%" }}>
                    <DateRangePicker
                      containerStyles={{ width: "100%" }}
                      startDate={startDate}
                      endDate={endDate}
                      onApply={handleEvent}
                      alwaysShowCalendars={true}
                      locale={{ format: "YYYY-MM-DD HH:mm:ss" }}
                      timePicker={true}
                      timePickerSeconds={true}
                      ranges={{
                        Today: [moment().startOf("day"), moment().endOf("day")],
                        Yesterday: [
                          moment().subtract(1, "days").startOf("day"),
                          moment().subtract(1, "days").endOf("day"),
                        ],
                        "Last 7 Days": [
                          moment().subtract(6, "days").startOf("day"),
                          moment().endOf("day"),
                        ],
                        "Last 30 Days": [
                          moment().subtract(29, "days").startOf("day"),
                          moment().endOf("day"),
                        ],
                        "This Month": [
                          moment().startOf("month"),
                          moment().endOf("month"),
                        ],
                        "Last Month": [
                          moment().subtract(1, "month").startOf("month"),
                          moment().subtract(1, "month").endOf("month"),
                        ],
                        "This Year": [
                          moment().startOf("year"),
                          moment().endOf("year"),
                        ],
                      }}
                    >
                      <input
                        type="text"
                        className="form-control"
                        value={startDate + " - " + endDate}
                      />
                    </DateRangePicker>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="form-group">
                <label>
                  <b>{t("USERNAME_TRANSFER")}</b>
                </label>
                <AsyncPaginate
                  debounceTimeout={250}
                  loadOptions={(search, loadOptions, obj) =>
                    getMemberList(
                      search,
                      loadOptions,
                      { page: obj.page },
                      "TRANSFER"
                    )
                  }
                  additional={{
                    page: 1,
                  }}
                  value={selectedMember}
                  onChange={(e) => setSelectedMember(e)}
                />
              </div>
            </div>
            <div className="col-lg-3">
              <div className="form-group">
                <label>
                  <b>{t("TRASFER_PLAYER")}</b>
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={playerId}
                  placeholder={t("PLAYERID")}
                  readOnly={!stringIsNullOrEmpty(props.playeid)}
                  onChange={(e) => {
                    setPlayerId(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="col-lg-3">
              <div className="form-group">
                <label>
                  <b>{t("GAME_NAME")}</b>
                </label>
                <Select
                  styles={_OPTION_STYLES}
                  value={productList.filter(
                    (product) => product.value == selectedProduct
                  )}
                  options={productList}
                  onChange={(e) => {
                    setSelectedProduct(e.value);
                  }}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-3">
              <div className="form-group">
                <label>
                  <b>{t("BONUS_RECEIVE_MEMBER")}</b>
                </label>

                <AsyncPaginate
                  debounceTimeout={250}
                  loadOptions={(search, loadOptions, obj) =>
                    getMemberList(
                      search,
                      loadOptions,
                      { page: obj.page },
                      "BONUS"
                    )
                  }
                  additional={{
                    page: 1,
                  }}
                  value={selectedBonusMember}
                  onChange={(e) => setSelectedBonusMember(e)}
                />
              </div>
            </div>
            <div className="col-lg-3">
              <div className="form-group">
                <label>
                  <b>{t("BONUS_RECEIVE_PLAYER")}</b>
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={bonusPlayerId}
                  placeholder={t("PLAYERID")}
                  readOnly={!stringIsNullOrEmpty(props.playebonusid)}
                  onChange={(e) => {
                    setBonusPlayerId(e.target.value);
                  }}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-2">
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => {
                  readReport(
                    selectedMember.value,
                    selectedBonusMember.value,
                    false
                  );
                }}
              >
                <i className="fas fa-search"></i> {t("SEARCH")}
              </button>
            </div>
          </div>
          <hr />
          <ReactTable data={reportData} columns={_tableColumns} />
        </PanelBody>
      </Panel>
    </div>
  );
};

export default ManageBonusCommReport;
