import React, { useState, useEffect } from "react";
import ReactTable from "../../components/constantComponent/reactTable/ReactTable";
import { Panel, PanelBody } from "./../../components/panel/panel.jsx";
import "../../assets/css/custom.css";
import { useHistory } from "react-router-dom";
import {
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { navigateTo } from "../../util/Util";
import { ApiKey, ApiUrl, WebUrl, LanguageKey } from "../../util/Constant";
import { setBusy, setIdle } from "../../redux/AppAction";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { fetch } from "whatwg-fetch";
import ToggleButton from "react-toggle-button";
import NavigationButton from "../../components/constantComponent/button/NavigationButton";

/// <summary>
/// Author : -
/// </summary>

const ProductMaintenance = (props) => {
  const { t } = useTranslation(LanguageKey._PRIMARY);
  const _dispatch = useDispatch();
  let _history = useHistory();
  const [productData, setProductData] = useState([]);
  const { loginUserId } = useSelector((state) => ({
    loginUserId: state.authState.userData.userId,
  }));

  let _tableColumns = [
    {
      Header: "NAME",
      accessor: "productName",
    },
    {
      Header: "CODE",
      accessor: "productCode",
    },
    {
      Header: "STATUS",
      accessor: "status",
      Cell: ({ row }) => (
        <div className="btn-group m-r-5 m-b-5">
          {row.original.status == 1 ? (
            <span className="badge badge-secondary badge-green">
              {t("ACTIVE")}
            </span>
          ) : (
            <span className="badge badge-secondary badge-danger">
              {t("MAINTENANCE")}
            </span>
          )}
        </div>
      ),
      disableSortBy: true,
      style: { overflow: "visible" },
    },
    {
      Header: "UM",
      accessor: "productUm",
      Cell: ({ row }) => (
        <div className="btn-group m-r-5 m-b-5">
          <ToggleButton
            value={row.original.productUm}
            onToggle={(value) => {
              updateProductUM(row.original)
            }}
          />
        </div>
      ),
      disableSortBy: true,
      style: { overflow: "visible" },
    },
    {
      Header: "RANK",
      accessor: "rank",
    },
    {
      id: "action",
      Header: "",
      Cell: ({ row }) => (
        <div className="btn-group m-r-5 m-b-5">
          <UncontrolledButtonDropdown>
            <DropdownToggle caret color="default">
              <i className="fas fa-cog"></i>
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem
                onClick={() => {
                  updateProductAssignment(row.original);
                }}
              >
                {row.original.status ? t("DEACTIVATE") : t("ACTIVATE")}
              </DropdownItem>
              <DropdownItem
                onClick={() => {
                  navigateTo(
                    _history,
                    WebUrl._URL_COMPANY_PRODUCT_ACCOUNT_INFO,
                    row.original
                  );
                }}
              >
                <span className="text-primary">
                  {t("EDIT_ACCOUNT_INFORMATION")}
                </span>
              </DropdownItem>
              <DropdownItem
                onClick={() => {
                  navigateTo(
                    _history,
                    WebUrl._URL_MANAGE_PRODUCT_GAME_SETTING,
                    row.original
                  );
                }}
              >
                <span className="text-primary">{t("MANAGE_GAME_SETTING")}</span>
              </DropdownItem>
              <DropdownItem
                onClick={() => {
                  navigateTo(
                    _history,
                    WebUrl._URL_MANAGE_PRODUCT_GAME_RANK,
                    row.original
                  );
                }}
              >
                <span className="text-primary">{t("CUSTOM_GAME_SETTING")}</span>
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledButtonDropdown>
        </div>
      ),
      disableSortBy: true,
      disableFilters: true,
      width: 100,
      style: { overflow: "visible" },
    },
  ];

  /// <summary>
  /// Author : -
  /// </summary>
  function updateProductAssignment(data) {
    _dispatch(setBusy());
    fetch(
      ApiUrl._API_UPDATE_USER_ASSIGNMENT +
        "?userId=" +
        loginUserId +
        "&productId=" +
        data["productId"] +
        "&enable=" +
        !data["status"],
      {
        method: ApiKey._API_POST,
        headers: {
          "Content-Type": ApiKey._API_FORM_URLENCODED,
          "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
        },
      }
    )
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
          updateProductData();
        }
        _dispatch(setIdle());
      });
  }

  /// <summary>
  /// Author : -
  /// </summary>
  function updateProductUM(data) {
    _dispatch(setBusy());
    fetch(
      ApiUrl._API_UPDATE_USER_PRODUCT_MAINTENANCE +
      "?userId=" +
      loginUserId +
      "&productId=" +
      data["productId"] +
      "&um=" +
      !data["productUm"],
      {
        method: ApiKey._API_POST,
        headers: {
          "Content-Type": ApiKey._API_FORM_URLENCODED,
          "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
        },
      }
    )
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
          updateProductData();
        }
        _dispatch(setIdle());
      });
  }

  /// <summary>
  /// Author : -
  /// </summary>
  function updateProductData() {
    fetch(ApiUrl._API_GET_MASTER_PRODUCT_ASSIGNMENT_DATA, {
      method: ApiKey._API_GET,
      headers: {
        "Content-Type": ApiKey._API_FORM_URLENCODED,
        "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
          setProductData(responseJson[ApiKey._API_DATA_KEY]);
        }
      });
  }

  /// <summary>
  /// Author : -
  /// </summary>
  useEffect(() => {
    updateProductData();
  }, []);

  return (
    <div>
      <h1 className="page-header">{t("PRODUCT_MAINTENANCE")}
        <NavigationButton
          history={_history}
          url={WebUrl._URL_MANAGE_ACCOUNT_PRODUCT_RANK}
          buttonText={t("CUSTOM_PRODUCT_RANKING")}
        />
      </h1>
      <Panel>
        <PanelBody>
          <ReactTable
            filterable data={productData}
            columns={_tableColumns}
          />
        </PanelBody>
      </Panel>
    </div>
  );
};

export default ProductMaintenance;
