import React, { useState, useEffect } from "react";
import ReactTable from "../../components/constantComponent/reactTable/ReactTable";
import { Panel, PanelBody } from "../../components/panel/panel.jsx";
import { useHistory } from "react-router-dom";
import {
	UncontrolledButtonDropdown,
	DropdownToggle,
	DropdownMenu,
	DropdownItem,
} from "reactstrap";
import "react-datepicker/dist/react-datepicker.css";
import NavigationButton from "../../components/constantComponent/button/NavigationButton";
import { navigateTo, numberWithCurrencyFormat } from "../../util/Util";
import {
	ApiKey,
	ApiUrl,
	AccessRight,
	WebUrl,
	LanguageKey,
	DEFAULT_PAGE_SIZE,
	Role,
} from "../../util/Constant";
import { checkIfPermissionExist } from "../../util/PermissionChecker";
import { setBusy, setIdle, showMessage } from "../../redux/AppAction";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import ApiEngine from "../../util/ApiEngine";
import { AsyncPaginate } from "react-select-async-paginate";
import classNames from "classnames";
import moment from "moment";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { stringIsNullOrEmpty, createFormBody } from "../../util/Util";

/// <summary>
/// Author : -
/// </summary>
const ManageMemberWeeklyRescue = (props) => {
	const { t } = useTranslation(LanguageKey._PRIMARY);
	let _history = useHistory();
	const _dispatch = useDispatch();
	var _username = useSelector(
		(state) => state["authState"]["userData"]["username"]
	);

	const [tableData, setTableData] = useState([]);
	const [startDate, setStartDate] = useState(
		moment().startOf("week").format("YYYY-MM-DD")
	);

	const [endDate, setEndDate] = useState(
		moment().endOf("week").format("YYYY-MM-DD")
	);

	const [selectedDate, setSelectedDate] = useState(
		moment().subtract(1, "days").format("YYYY-MM-DD")
	);

	const [showReleaseBtn, setShowReleaseBtn] = useState(true);

	let _tableColumns = [
		{
			id: "action",
			Header: "",
			Cell: ({ row }) =>
				checkIfPermissionExist(
					AccessRight._MANAGE_MEMBER_WEEKLY_RESCUE,
					true
				) &&
				(row.original.isClaim == false ? (
					row.original.isAllowToClaim == "true" ? (
						<div className="btn-group m-r-5 m-b-5">
							<input
								type="checkbox"
								value={row.original.id + "#" + row.original.weekStartDate}
								checked={selectedCheckboxes.includes(
									row.original.id + "#" + row.original.weekStartDate
								)}
								onChange={(e) => handleCheckboxChange(e)}
							/>
						</div>
					) : (
						<div className="btn-group m-r-5 m-b-5"></div>
					)
				) : (
					<div className="btn-group m-r-5 m-b-5">
						<span className="badge badge-secondary badge-green">
							{t("RELEASED")}
						</span>
					</div>
				)),
			disableSortBy: true,
			disableFilters: true,
			width: 50,
			style: { overflow: "visible" },
		},
		{
			Header: "USERNAME",
			accessor: "username",
			minWidth: 100,
			Cell: ({ row }) => {
				return <span>{row.original.username}</span>;
			},
		},
		{
			Header: "VIP",
			accessor: "vipLevel",
			minWidth: 100,
		},
		{
			Header: "WEEKSTARTDATE",
			minWidth: 100,
			Cell: ({ row }) => {
				return moment.utc(row.original.weekStartDate).format("DD-MM-YYYY");
			},
		},
		{
			Header: "WEEKENDDATE",
			minWidth: 100,
			Cell: ({ row }) => {
				return moment.utc(row.original.weekEndDate).format("DD-MM-YYYY");
			},
		},
		{
			Header: "TOTAL_TURNOVER",
			minWidth: 100,
			Cell: ({ row }) => {
				return row.original.turnover.toFixed(2);
			},
		},
		{
			Header: "WIN_LOSS",
			minWidth: 100,
			Cell: ({ row }) => {
				return (row.original.turnover + row.original.sumWinLoss).toFixed(2);
			},
		},
		{
			Header: "MIN_LOSS",
			minWidth: 100,
			Cell: ({ row }) => {
				return row.original.minLoss.toFixed(2);
			},
		},
		{
			Header: "TOTAL_LOSS",
			minWidth: 100,
			Cell: ({ row }) => {
				return (row.original.sumWinLoss * -1).toFixed(2);
			},
		},
		{
			Header: "REWARDAMOUNT",
			minWidth: 100,
			Cell: ({ row }) => {
				return row.original.rewardAmount.toFixed(2);
			},
		},
	];

	const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);

	const handleCheckboxChange = (e) => {
		const { value } = e.target;
		setSelectedCheckboxes((prevSelected) => {
			if (prevSelected.includes(value)) {
				return prevSelected.filter((id) => id !== value);
			} else {
				return [...prevSelected, value];
			}
		});
	};

	/// <summary>
	/// Author : -
	/// </summary>
	useEffect(() => {
		init();
	}, []);

	const init = async () => {

		fetch(
			ApiUrl._API_GET_MEMBER_WEEKLY_RESCUE +
				"?startDate=" +
				startDate +
				"&endDate=" +
				endDate,
			{
				method: ApiKey._API_GET,
				headers: {
					"Content-Type": ApiKey._API_FORM_URLENCODED,
					"X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
				},
			}
		)
			.then((response) => response.json())
			.then((responseJson) => {
				if (responseJson[ApiKey._API_SUCCESS_KEY]) {
					setTableData(responseJson[ApiKey._API_DATA_KEY].data);
				}
			});
	};

	async function getMemberWeeklyRescue() {
		_dispatch(setBusy());
		fetch(
			ApiUrl._API_GET_MEMBER_WEEKLY_RESCUE +
				"?startDate=" +
				startDate +
				"&endDate=" +
				endDate,
			{
				method: ApiKey._API_GET,
				headers: {
					"Content-Type": ApiKey._API_FORM_URLENCODED,
					"X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
				},
			}
		)
			.then((response) => response.json())
			.then((responseJson) => {
				if (responseJson[ApiKey._API_SUCCESS_KEY]) {
					setTableData(responseJson[ApiKey._API_DATA_KEY].data);
				}
				_dispatch(setIdle());
			});
	}

	async function Claim(idList, amountList, startDateList, endDateList) {
		//console.log(idList);

		let tempList = [];
		for (var i = 0; i < idList.length; i++) {
			var splitId = idList[i].split("#");
			tempList.push(splitId[0]);
		}

		//console.log(tempList);
		let params = {
			memberList: tempList,
			amountList: amountList,
			startDate: startDate,
			endDate: endDate,
			//date: startDateList[0],
		};

		// console.log(params);
		let formBody = createFormBody(params);
		_dispatch(setBusy());
		fetch(ApiUrl._API_GENERATE_MEMBER_WEEKLY_RESCUE, {
			method: ApiKey._API_POST,
			headers: {
				"Content-Type": ApiKey._API_FORM_URLENCODED,
				"X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
			},
			body: formBody,
		})
			.then((response) => response.json())
			.then((responseJson) => {
				getMemberWeeklyRescue();
				_dispatch(setIdle());
				_dispatch(
					showMessage(
						responseJson[ApiKey._API_SUCCESS_KEY],
						t(responseJson[ApiKey._API_MESSAGE_KEY])
					)
				);
			});
	}

	async function ClaimAll(idList, amountList, startDateList, endDateList) {
		let params = {
			memberList: idList,
			amountList: amountList,
			startDate: startDate,
			endDate: endDate,
		};

		// console.log(params);
		let formBody = createFormBody(params);
		_dispatch(setBusy());
		fetch(ApiUrl._API_GENERATE_MEMBER_WEEKLY_RESCUE, {
			method: ApiKey._API_POST,
			headers: {
				"Content-Type": ApiKey._API_FORM_URLENCODED,
				"X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
			},
			body: formBody,
		})
			.then((response) => response.json())
			.then((responseJson) => {
				getMemberWeeklyRescue();
				_dispatch(setIdle());
				_dispatch(
					showMessage(
						responseJson[ApiKey._API_SUCCESS_KEY],
						t(responseJson[ApiKey._API_MESSAGE_KEY])
					)
				);
			});
	}

	const handleEvent = (event, picker) => {
		setStartDate(moment(picker.startDate).startOf("week").format("YYYY-MM-DD"));
		setEndDate(moment(picker.startDate).endOf("week").format("YYYY-MM-DD"));
	};

	/* const handleEvent = (event, picker) => {
		setSelectedDate(moment(picker.startDate).format("YYYY-MM-DD"));
	}; */

	return (
		<div>
			<h1 className="page-header">{t("MEMBER_WEEKLY_RESCUE")}</h1>
			<Panel>
				<div className="row">
					<div
						className="input-group"
						style={{
							width: "100%",
							flexFlow: "nowrap",
							marginTop: "14px",
							marginLeft: "25px",
						}}
					>
						<div className="input-group-prepend">
							<span className="input-group-text">
								<i className="fa fa-calendar"></i>
							</span>
						</div>
						<div style={{ width: "30%" }}>
							<DateRangePicker
								containerStyles={{ width: "100%" }}
								singleDatePicker={true}
								onApply={handleEvent}
								alwaysShowCalendars={true}
								locale={{ format: "YYYY-MM-DD" }}
								minDate={"2024-02-19"} // ROSE: Setting the minimum to February 19, 2024 (Launch date)
								maxDate={moment().subtract(1, "days").format("YYYY-MM-DD")}
							>
								<input
									type="text"
									className="form-control"
									value={startDate + " - " + endDate}
								/>
							</DateRangePicker>
						</div>
						<button
							type="button"
							className="btn btn-primary"
							style={{ marginLeft: "12px" }}
							onClick={() => getMemberWeeklyRescue()}
						>
							{t("SEARCH")}
						</button>
					</div>
				</div>
				<div className="row">
					{showReleaseBtn && (
						<div>
							<button
								className="btn btn-primary"
								style={{
									marginLeft: "25px",
									marginTop: "30px",
									width: "100px",
									position: "absolute",
									zIndex: "1",
								}}
								onClick={() =>
									Claim(
										selectedCheckboxes,
										tableData
											.filter(
												(x) =>
													x.isClaim == false &&
													selectedCheckboxes.includes(
														x.id + "#" + x.weekStartDate
													)
											)
											.map((member) => member.rewardAmount),
										tableData
											.filter(
												(x) =>
													x.isClaim == false &&
													selectedCheckboxes.includes(
														x.id + "#" + x.weekStartDate
													)
											)
											.map((member) =>
												moment.utc(member.weekStartDate).format("YYYY-MM-DD")
											),
										tableData
											.filter(
												(x) =>
													x.isClaim == false &&
													selectedCheckboxes.includes(
														x.id + "#" + x.weekStartDate
													)
											)
											.map((member) =>
												moment.utc(member.weekEndDate).format("YYYY-MM-DD")
											)
									)
								}
								disabled={selectedCheckboxes.length > 0 ? false : true}
							>
								{t("RELEASE")}
							</button>
							<button
								className="btn btn-primary"
								style={{
									marginLeft: "140px",
									marginTop: "30px",
									width: "100px",
									position: "absolute",
									zIndex: "1",
								}}
								onClick={() =>
									ClaimAll(
										tableData
											.filter((x) => x.isClaim == false)
											.map((member) => member.id),
										tableData
											.filter((x) => x.isClaim == false)
											.map((member) => member.rewardAmount),
										tableData
											.filter(
												(x) =>
													x.isClaim == false &&
													selectedCheckboxes.includes(
														x.id + "#" + x.weekStartDate
													)
											)
											.map((member) =>
												moment.utc(member.weekStartDate).format("YYYY-MM-DD")
											),
										tableData
											.filter(
												(x) =>
													x.isClaim == false &&
													selectedCheckboxes.includes(
														x.id + "#" + x.weekStartDate
													)
											)
											.map((member) =>
												moment.utc(member.weekEndDate).format("YYYY-MM-DD")
											)
									)
								}
								disabled={
									tableData.filter((x) => x.isClaim == false).length > 0
										? false
										: true
								}
							>
								{t("RELEASE_ALL")}
							</button>
						</div>
					)}

					<div className="col-lg-12">
						<PanelBody>
							{<ReactTable data={tableData} columns={_tableColumns} />}
						</PanelBody>
					</div>
				</div>
			</Panel>
		</div>
	);
};

export default ManageMemberWeeklyRescue;
