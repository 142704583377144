import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { Panel, PanelBody } from "./../../components/panel/panel.jsx";
import { useHistory } from "react-router-dom";
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import NavigationButton from "../../components/constantComponent/button/NavigationButton";
import {
	ApiKey,
	ApiUrl,
	LanguageKey,
	ContentTypeString,
	AllVipOption
} from "../../util/Constant";
import { stringIsNullOrEmpty, createFormBody } from "../../util/Util";
import { useLocation } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { setBusy, setIdle, showMessage } from "../../redux/AppAction";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import ChatSphereContentLanguage from "./ChatSphereContentLanguage";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import classnames from "classnames";
import { fetch } from "whatwg-fetch";
import Select from "react-select";

/// <summary>
/// Author : -
/// </summary>

const ContentDetail = (props) => {
	const _DETAIL_TAB = "1";
	const _LANGUAGE_TAB = "2";
	const { t } = useTranslation(LanguageKey._PRIMARY);
	let _history = useHistory();
	const _location = useLocation();
	const _dispatch = useDispatch();
	const { register, unregister, handleSubmit, errors, control, setValue } = useForm();
	const [contentId, setContentId] = useState("");
	const [content, setContent] = useState("");
	const [status, setStatus] = useState(true);
	const [activeTab, setActiveTab] = useState(_DETAIL_TAB);
	const [chatSphereTypeList, setChatSphereTypeList] = useState([]);
	const [selectedChatSphereType, setSelectedChatSphereType] = useState("");
	const [chatSpherePhoneNumberList, setChatSpherePhoneNumberList] = useState([]);
	const [selectedPhoneNumber, setSelectedPhoneNumber] = useState("");


	/// <summary>
	/// Author : -
	/// </summary>
	const toggle = (tab) => {
		if (activeTab !== tab) {
			setActiveTab(tab);
		}
	};

	/// <summary>
	/// Author : -
	/// </summary>
	useEffect(() => {
		register({ name: "content" }, { required: "PLEASE_ENTER_CONTENT" });
		init();
	}, []);

	/// <summary>
	/// Author : -
	/// </summary>
	async function init() {
		if (_location.state) {
			var apiUrl = ApiUrl._API_GET_CHAT_SPHERE_BY_ID;
			apiUrl += "?id=" + _location.state.id;
			await fetch(apiUrl, {
				method: ApiKey._API_GET,
				headers: {
					"Content-Type": ApiKey._API_FORM_URLENCODED,
					"X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
				},
			})
				.then((response) => response.json())
				.then((responseJson) => {
					if (responseJson[ApiKey._API_SUCCESS_KEY]) {
						setContentId(responseJson[ApiKey._API_DATA_KEY]["id"]);
						setSelectedChatSphereType(responseJson[ApiKey._API_DATA_KEY]["chatSphereActionTypeId"])
						setContent(responseJson[ApiKey._API_DATA_KEY]["content"]);
						setStatus(responseJson[ApiKey._API_DATA_KEY]["status"]);
						setSelectedPhoneNumber(responseJson[ApiKey._API_DATA_KEY]["phoneNumberId"])
						unregister("content");
					}
				});
		}
	}

	/// <summary>
	/// Author : -
	/// </summary>
	const submitForm = async (data, e) => {
		let params = {
			chatSphereTypeActionId: data.selectedChatSphereType,
			status: data.status,
			content: content,
			chatSpherePhoneNumberId: selectedPhoneNumber
		};

		if (!stringIsNullOrEmpty(contentId)) {
			params["id"] = contentId;
		}

		let formBody = createFormBody(params);
		_dispatch(setBusy());

		fetch(ApiUrl._API_CREATE_OR_UPDATE_CHAT_SPHERE_CONTENT, {
			method: ApiKey._API_POST,
			headers: {
				"Content-Type": ApiKey._API_FORM_URLENCODED,
				"X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
			},
			body: formBody,
		})
			.then((response) => response.json())
			.then((responseJson) => {
				_dispatch(setIdle());
				_dispatch(
					showMessage(
						responseJson[ApiKey._API_SUCCESS_KEY],
						t(responseJson[ApiKey._API_MESSAGE_KEY])
					)
				);

				if (responseJson[ApiKey._API_SUCCESS_KEY]) {
					setContentId(responseJson[ApiKey._API_DATA_KEY]["id"]);
				}
			});
	};

	useEffect(() => {
		fetch(ApiUrl._API_GET_CHAT_SPHERE_TYPE, {
			method: ApiKey._API_GET,
			headers: {
				"Content-Type": ApiKey._API_FORM_URLENCODED,
				"X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
			},
		})
			.then((response) => response.json())
			.then((responseJson) => {
				if (responseJson[ApiKey._API_SUCCESS_KEY]) {
					const channelList = [
					];
					responseJson[ApiKey._API_DATA_KEY].forEach(function (channel) {
						channelList.push({ label: channel.name + ", " + channel.channel, value: channel.id });
					});

					setChatSphereTypeList(channelList);
				}
			});
	}, []);

	useEffect(() => {
		fetch(ApiUrl._API_GET_CHAT_SPHERE_PHONE_NUMBER, {
			method: ApiKey._API_GET,
			headers: {
				"Content-Type": ApiKey._API_FORM_URLENCODED,
				"X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
			},
		})
			.then((response) => response.json())
			.then((responseJson) => {

				if (responseJson[ApiKey._API_SUCCESS_KEY]) {
					const phoneList = [
					];
					responseJson[ApiKey._API_DATA_KEY].forEach(function (phone) {
						phoneList.push({ label: phone.phoneNumber, value: phone.id });
					});

					setChatSpherePhoneNumberList(phoneList);
				}
			});
	}, []);

	return (
		<div>
			<h1 className="page-header">
				{contentId ? t("EDIT_CHAT_SPHERE_CONTENT") : t("ADD_CHAT_SPHERE_CONTENT")}
				<NavigationButton history={_history} />
			</h1>
			{contentId && (
				<Nav tabs>
					<NavItem>
						<NavLink
							className={classnames({ active: activeTab === _DETAIL_TAB })}
							onClick={() => {
								toggle(_DETAIL_TAB);
							}}
						>
							{t("DETAIL")}
						</NavLink>
					</NavItem>
					<NavItem>
						<NavLink
							className={classnames({ active: activeTab === _LANGUAGE_TAB })}
							onClick={() => {
								toggle(_LANGUAGE_TAB);
							}}
						>
							{t("LANGUAGE")}
						</NavLink>
					</NavItem>
				</Nav>
			)}
			<TabContent activeTab={activeTab}>
				<TabPane tabId={_DETAIL_TAB}>
					<div className="row">
						<div className="col-xl-12">
							<Panel>
								<PanelBody>
									<form onSubmit={handleSubmit(submitForm)}>
										<div className="row">
											<div className="col-lg-8">
												<div className="form-group row m-b-15">
													<label className="col-md-4 col-form-label">
														<b>{t("CHANNEL")}</b>
													</label>
													<div className="col-md-7">
														<Controller
															control={control}
															name="selectedChatSphereType"
															render={({ onChange, value }) => (
																<Select
																	options={chatSphereTypeList}
																	value={chatSphereTypeList.filter(
																		(option) => option.value == selectedChatSphereType
																	)}
																	onChange={(e) => {
																		onChange(e.value);
																		setSelectedChatSphereType(e.value)

																	}}
																/>
															)}
															rules={{ required: "PLEASE_SELECT_CHANNEL" }}
														/>
														{errors.channel && (
															<div className="invalid-feedback">
																{t(errors.channel.message)}
															</div>
														)}
													</div>
												</div>
											</div>
											<div className="col-lg-8">
												<div className="form-group row m-b-15">
													<label className="col-md-4 col-form-label">
														<b>{t("PHONE_NUMBER")}</b>
													</label>
													<div className="col-md-7">
														<Controller
															control={control}
															name="selectedPhoneNumber"
															defaultValue={chatSpherePhoneNumberList._VALUE}
															render={({ onChange, value }) => {
																return (
																	<Select
																		name="selectedPhoneNumber"
																		options={chatSpherePhoneNumberList}
																		placeholder={
																			chatSpherePhoneNumberList.filter(
																				(option) => option.value == selectedPhoneNumber
																			) !== undefined
																				? chatSpherePhoneNumberList.filter(
																					(option) => option.value == selectedPhoneNumber
																				).label
																				: ""
																		}
																		value={chatSpherePhoneNumberList.filter(
																			(option) => option.value == selectedPhoneNumber
																		)}
																		onChange={(e) => {
																			setSelectedPhoneNumber(e.value);
																			onChange(e.value);
																		}}
																	/>
																);
															}}
															rules={{ required: "PLEASE_SELECT_PHONE_NUMBER" }}
														/>
														{errors.channel && (
															<div className="invalid-feedback">
																{t(errors.channel.message)}
															</div>
														)}
													</div>
												</div>
											</div>
											<div className="col-lg-8">
												<div className="form-group row m-b-15">
													<label className="col-md-4 col-form-label">
														<b>{t("CONTENT")}</b>
													</label>
													<div className="col-md-7">
														<textarea
															className="form-control"
															rows="5"
															style={{ marginTop: "10px" }}
															ref={register}
															name="content"
															defaultValue={content}
															onChange={(e) => {
																setContent(e.target.value);
																setValue("content", e.target.value);
															}}
														></textarea>
														{errors.content && (
															<div className="invalid-feedback">
																{t(errors.content.message)}
															</div>
														)}
														<div className="m-t-10" style={{ lineHeight: "28px" }}>
															<b>May get emoji cheat sheet with this link, </b>
															<a href="https://www.webfx.com/tools/emoji-cheat-sheet/" target="_blank">Emoji Cheat Sheet</a>
														</div>
													</div>
												</div>
											</div>
											<div className="col-lg-8">
												<div className="form-group row m-b-15">
													<label className="col-md-4 col-form-label">
														<b>{t("STATUS")}</b>
													</label>
													<div className="col-md-7">
														<div className="switcher">
															<input
																type="checkbox"
																name="status"
																id="status"
																onChange={(e) => setStatus(e.target.checked)}
																value={true}
																checked={status}
																ref={register}
															/>
															<label htmlFor="status"></label>
														</div>
													</div>
												</div>
											</div>
										</div>
										<hr />
										<div className="row">
											<div className="col-lg-3">
												<div className="form-group">
													<button type="submit" className="btn btn-primary">
														{t("SUBMIT")}
													</button>
												</div>
											</div>
										</div>
									</form>
								</PanelBody>
							</Panel>
						</div>
					</div>
				</TabPane>
				<TabPane tabId={_LANGUAGE_TAB}>
					{contentId && (
						<div className="row">
							<div className="col-lg-12">
								<ChatSphereContentLanguage
									id={contentId}
									type={ContentTypeString._CHAT_SPHERE}
								/>
							</div>
						</div>
					)}
				</TabPane>
			</TabContent>
		</div>
	);
};

export default withRouter(ContentDetail);
