
import React, { useState, useEffect } from "react";
import ReactTable from "../../components/constantComponent/reactTable/ReactTable";
import { Panel, PanelBody } from "./../../components/panel/panel.jsx";
import "../../assets/css/custom.css";
import { useHistory, useLocation } from "react-router-dom";
import {
    UncontrolledButtonDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from "reactstrap";
import { navigateTo, numberWithCurrencyFormat } from "../../util/Util";
import { useForm, Controller } from "react-hook-form";
import { createFormBody } from "../../util/Util";
import { ApiKey, ApiUrl, WebUrl, LanguageKey } from "../../util/Constant";
import { setBusy, setIdle } from "../../redux/AppAction";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { fetch } from "whatwg-fetch";
import { showMessage } from "../../redux/AppAction";
import ApiEngine from "../../util/ApiEngine";

import DateTime from 'react-datetime';
import moment from "moment";
import SweetAlert from "react-bootstrap-sweetalert";


/// <summary>
/// Author : -
/// </summary>

const ShareholdersProcessSales = (props) => {
    const { t } = useTranslation(LanguageKey._PRIMARY);
    const _dispatch = useDispatch();
    const _location = useLocation();
    let _history = useHistory();
    const [productData, setProductData] = useState([]);
    const [poolData, setPoolData] = useState([]);
    const [categoryData, setCategoryData] = useState({});
    const [poolProcessedRow, setPoolProcessedRow] = useState({});
    
    const [poolCategoryData, setPoolCategoryData] = useState({});
    const [categoryTotal, setCategoryTotal] = useState({});
    const [date, setDate] = useState(moment().add(-1, "days").format("DD-MM-YYYY"));
    const [oktoprocess, setOktoprocess] = useState(true);
    const [okcount, setOkcount] = useState(0);
    const [processed, setProcessed] = useState(false);
    const [released, setReleased] = useState(false);
    const { loginUserId } = useSelector((state) => ({
        loginUserId: state.authState.userData.userId,
    }));
    const { handleSubmit, control } = useForm({ mode: "onBlur" });
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [showConfirmationRelease, setShowConfirmationRelease] = useState(false);
    const [initPage, setInitPage] = useState(true);


    const yesterday = moment().subtract(1, 'day');
    const disableFutureDt = current => {
        return current.isBefore(yesterday);
    };

    let _tableColumns = [
        {
            Header: "NAME",
            accessor: "productName",
            Cell: ({ row }) => (
                <div className="text-left">
                    <div>{row.original.productName}</div>
                    { row.original.id > 0 && <>
                        <div><small>Royalty: {row.original.royalty}%</small></div>
                        <div><small>Markup: {row.original.markup}%</small></div>
                    </>
                    }

                </div>
            ),
            disableSortBy: true,
        },
        {
            Header: "TURNOVER",
            accessor: "netSales",
            Cell: ({ row }) => (
                <div className="text-center">
                    <div>
                        <input type="text" className="form-control text-right" disabled value={row.original.grossSales} />
                    </div>
                </div>
            ),
            disableSortBy: true,
            style: { overflow: "visible" },
        },
        {
            Header: "PAYOUT",
            accessor: "payout",
            Cell: ({ row }) => (
                <div className="text-center">
                    { row.original.payout == null ?
                        <span className="badge bg-danger">NOT_SET</span>
                        :
                        <input type="text" className="form-control text-right" disabled value={row.original.payout} />
                    }
                </div>
            ),
            disableSortBy: true,
            style: { overflow: "visible" },
        },
        {
            Header: "NET PROFIT",
            accessor: "netProfit",
            Cell: ({ row }) => (
                <div className="text-center">

                { row.original.payout == null ? <span className="badge bg-danger">NOT_SET</span> : <input type="text" className="form-control text-right" disabled value={row.original.netProfit} />}
                </div>
            ),
            disableSortBy: true,
            style: { overflow: "visible" },
        },
        {
            id: "action",
            Header: "",
            Cell: ({ row }) => (
                <div className="btn-group m-r-5 m-b-5">
                    {
                        !processed && <UncontrolledButtonDropdown>
                        <DropdownToggle caret color="default">
                            <i className="fas fa-cog"></i>
                        </DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem
                                onClick={() => {
                                    navigateTo(
                                        _history,
                                        WebUrl._URL_SHAREHOLDERS_PAYOUT_EDIT,
                                        row.original
                                    );
                                }}
                            >
                                <span className="text-primary">
                                    {t("EDIT_PAYOUT")}
                                </span>
                            </DropdownItem>
                        </DropdownMenu>
                        </UncontrolledButtonDropdown>
                    }
                </div>
            ),
            disableSortBy: true,
            disableFilters: true,
            width: 100,
            style: { overflow: "visible" },
        },
    ];

    const tableStyle = {
        border: "2px solid #000000"
    };

    const greyStyle = {
        border: "none",
        background: "lightgrey"
    };

    const lineRowStyle = {
        borderTop: "2px solid #000000",
        borderBottom: "2px solid #000000",
        fontWeight: "bold"
    };

    const leftBorderStyle = {
        border: "none",
        borderColor: "#000000",
        borderLeft: "2px solid #000000",
        textAlign: "right"
    };

    const rightBorderStyle = {
        border: "none",
        borderColor: "#000000",
        borderRight: "2px solid #000000",
        textAlign: "right"
    };

    const payoutStyle = {
        border: "none",
        borderColor: "#000000",
        textAlign: "right",
        background: "#d9edf7"
    };

    const netProfitStyle = {
        border: "none",
        borderColor: "#000000",
        borderLeft: "2px solid #000000",
        textAlign: "right",
        background: "#fcf8e3"
    };

    const balanceStyle = {
        border: "none",
        borderColor: "#000000",
        textAlign: "right",
        background: "#fcf8e3"
    };

    const textRightStyle = {
        border: "none",
        borderColor: "#000000",
        textAlign: "right"
    };

    const textLeftStyle = {
        border: "none",
        borderColor: "#000000",
        textAlign: "left"
    };

    /// <summary>
    /// Author : -
    /// </summary>
    function getProductData(date) {
        setProductData([]);
        setPoolData([]);
        _dispatch(setBusy());
        setOkcount(0);
        fetch(ApiUrl._API_GET_SHAREHOLDER_MASTER_PRODUCT_DATA + "?date=" + date, {
            method: ApiKey._API_GET,
            headers: {
                "Content-Type": ApiKey._API_FORM_URLENCODED,
                "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
            },
        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                    setProcessed(responseJson[ApiKey._API_DATA_KEY].processed);
                    setReleased(responseJson[ApiKey._API_DATA_KEY].released);
                    let data = responseJson[ApiKey._API_DATA_KEY]["data"];
                    let pool = responseJson[ApiKey._API_DATA_KEY]["pool"];
                    setOktoprocess(true);
                    responseJson[ApiKey._API_DATA_KEY].data.map((product, index) => {
                        if (product.payout == null)
                            setOktoprocess(false);
                    });
                    setProductData(data);
                    setPoolData(pool);
                    let categoryRow = {};
                    let catTotal = {};

                    data.map((e, i) => {
                        let winLose = e.winAmount - e.betAmt;
                        let royaltyAmt = winLose * (e.royalty / 100);
                        let commissionAmt = e.validTurnover * (e.commission / 100);
                        let balanceAmt = 0;
                        balanceAmt = royaltyAmt + commissionAmt + e.rewards;
                        var payoutAmt = e.payout;
                        let netProfitAmt = 0;
                        if (e.payout > 0) {
                            netProfitAmt = e.validTurnover;
                            netProfitAmt -= e.payout;
                        }

                        if (!categoryRow[e.categoryName]) {
                            categoryRow[e.categoryName] = {};
                            if (typeof (e.categoryName) !== "undefined") {
                                catTotal[e.categoryName] = { turnover: 0, validTurnover: 0, winlose: 0, royalty: 0, commission: 0, balance: 0, payout: 0, netProfit: 0, rewards: 0 };
                            }
                            else {
                                catTotal[e.categoryName] = { turnover: e.grossSales, validTurnover: e.validTurnover, payout: e.payout, netProfit: e.netProfit, rewards: e.rewards };
                            }
                        }
                        if (e.productName !== "Settlement") {
                            if (!categoryRow[e.categoryName][e.productName]) {
                                categoryRow[e.categoryName][e.productName] = { turnover: e.grossSales, validTurnover: e.validTurnover, winlose: winLose, royalty: royaltyAmt, balance: balanceAmt, payout: payoutAmt, commission: commissionAmt, netProfit: netProfitAmt, rewards: e.rewards};
                            }
                        }
                        if (typeof (e.categoryName) !== "undefined") {
                            catTotal[e.categoryName].turnover += e.grossSales;
                            catTotal[e.categoryName].validTurnover += e.validTurnover;
                            catTotal[e.categoryName].winlose += winLose;
                            catTotal[e.categoryName].royalty += royaltyAmt;
                            catTotal[e.categoryName].balance += balanceAmt;
                            catTotal[e.categoryName].payout += payoutAmt;
                            catTotal[e.categoryName].commission += commissionAmt;
                            catTotal[e.categoryName].netProfit += netProfitAmt;
                            catTotal[e.categoryName].rewards += e.rewards;
                        }
                    })
                    setCategoryData(categoryRow);
                    setCategoryTotal(catTotal);
                    

                    let poolRow = [];
                    let poolCategoryRow = {};
                    let poolCatTotal = {};

                    pool.map((el, i) =>{
                        poolCategoryRow = {};
                        poolCatTotal = {};
                        el.data.map((e, i) =>{
                            let winLose = e.winAmount - e.betAmt;
                            let royaltyAmt = winLose * (e.royalty / 100);
                            let commissionAmt = e.validTurnover * (e.commission / 100);
                            let balanceAmt = 0;
                            balanceAmt = royaltyAmt + commissionAmt + e.rewards;
                            var payoutAmt = e.payout;
                            let netProfitAmt = 0;
                            if (e.payout > 0) {
                                netProfitAmt = e.validTurnover;
                                netProfitAmt -= e.payout;
                            }

                            if (!poolRow[el.poolName]) {
                                poolRow[el.poolName] = {categoryRow: poolCategoryRow, catTotal: poolCatTotal};
                            }

                            if (!poolRow[el.poolName].categoryRow[e.categoryName]) {
                                poolRow[el.poolName].categoryRow[e.categoryName] = {};
                                if (typeof (e.categoryName) !== "undefined") {
                                    poolRow[el.poolName].catTotal[e.categoryName] = { turnover: 0, validTurnover: 0, winlose: 0, royalty: 0, commission: 0, balance: 0, payout: 0, netProfit: 0, rewards: 0 };
                                }
                                else {
                                    poolRow[el.poolName].catTotal[e.categoryName] = { turnover: e.grossSales, validTurnover: e.validTurnover, payout: e.payout, netProfit: e.netProfit, rewards: e.rewards };
                                }
                            }
                            if (e.productName !== "Settlement") {
                                if (!poolRow[el.poolName].categoryRow[e.categoryName][e.productName]) {
                                    poolRow[el.poolName].categoryRow[e.categoryName][e.productName] = { turnover: e.grossSales, validTurnover: e.validTurnover, winlose: winLose, royalty: royaltyAmt, balance: balanceAmt, payout: payoutAmt, commission: commissionAmt, netProfit: netProfitAmt, rewards: e.rewards};
                                }
                            }
                            if (typeof (e.categoryName) !== "undefined") {
                                poolRow[el.poolName].catTotal[e.categoryName].turnover += e.grossSales;
                                poolRow[el.poolName].catTotal[e.categoryName].validTurnover += e.validTurnover;
                                poolRow[el.poolName].catTotal[e.categoryName].winlose += winLose;
                                poolRow[el.poolName].catTotal[e.categoryName].royalty += royaltyAmt;
                                poolRow[el.poolName].catTotal[e.categoryName].balance += balanceAmt;
                                poolRow[el.poolName].catTotal[e.categoryName].payout += payoutAmt;
                                poolRow[el.poolName].catTotal[e.categoryName].commission += commissionAmt;
                                poolRow[el.poolName].catTotal[e.categoryName].netProfit += netProfitAmt;
                                poolRow[el.poolName].catTotal[e.categoryName].rewards += e.rewards;
                            }
                        });
                    });
                    
                    console.log('poolRow');
                    console.log(poolRow);
                    setPoolProcessedRow(poolRow);

                }
                setInitPage(false);
                _dispatch(setIdle());
            });
    }


    /// <summary>
    /// Author : -
    /// </summary>
    useEffect(() => {
        if (_location.state) {
            if (_location.state.date != "") {
                setDate(_location.state.date);
                getProductData(_location.state.date);
            }
        }
    }, []);



    function preSubmit() {
        setShowConfirmation(true);
    }

    function preSubmitReleased() {
        setShowConfirmationRelease(true);
    }

    const submitProcess = async (data, e) => {
        let params = { date: date };

        let formBody = createFormBody(params);
        let responseJson = await ApiEngine.post(
            ApiUrl._API_UPDATE_SHAREHOLDER_PROCESS,
            formBody
        );
        _dispatch(
            showMessage(
                responseJson[ApiKey._API_SUCCESS_KEY],
                t(responseJson[ApiKey._API_MESSAGE_KEY])
            )
        );

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            getProductData(date);
        }
    };

    const submitRelease = async (data, e) => {
        let params = { date: date };

        setReleased(true);
        let formBody = createFormBody(params);
        let responseJson = await ApiEngine.post(
            ApiUrl._API_UPDATE_SHAREHOLDER_RELEASE,
            formBody
        );
        _dispatch(
            showMessage(
                responseJson[ApiKey._API_SUCCESS_KEY],
                t(responseJson[ApiKey._API_MESSAGE_KEY])
            )
        );
    };

    async function unstuckSH() {
        let apiUrl = ApiUrl._API_UNSTUCK_SH;
        fetch(apiUrl, {
            method: ApiKey._API_POST,
            headers: {
                "Content-Type": ApiKey._API_FORM_URLENCODED,
                "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
            },
            body: createFormBody({ date: date })
        })
            .then((response) => response.json())
            .then((responseJson) => {
                console.log(responseJson[ApiKey._API_SUCCESS_KEY]);
                console.log(responseJson[ApiKey._API_MESSAGE_KEY]);
            });
    }

    const clearSH = async (productName) => {
        let responseJson = await ApiEngine.post(ApiUrl._API_RESET_PRODUCT_PROCESS, createFormBody({ "productName": productName, "date": date }));
        if (responseJson[ApiKey._API_SUCCESS_KEY]){
            window.location.reload();
        }
    }

    return (
        <div>
            {showConfirmation && (
                <SweetAlert
                    warning
                    showCancel
                    confirmBtnText={t("CONFIRM")}
                    cancelBtnText={t("CANCEL")}
                    confirmBtnBsStyle="danger"
                    title={t("CONFIRM_PROCESS_SALES OF " + date + "?")}
                    onConfirm={() => {
                        setShowConfirmation(false);
                        handleSubmit(submitProcess)();
                    }}
                    onCancel={() => {
                        setShowConfirmation(false);
                    }}
                ></SweetAlert>
            )}

            {showConfirmationRelease && (
                <SweetAlert
                    warning
                    showCancel
                    confirmBtnText={t("CONFIRM")}
                    cancelBtnText={t("CANCEL")}
                    confirmBtnBsStyle="danger"
                    title={t("CONFIRM_RELEASE_SALES OF " + date + "?")}
                    onConfirm={() => {
                        setShowConfirmationRelease(false);
                        handleSubmit(submitRelease)();
                    }}
                    onCancel={() => {
                        setShowConfirmationRelease(false);
                    }}
                ></SweetAlert>
            )}

            <h1 className="page-header">{t("SHAREHOLDERS_PRODUCTS_SALES_PROCESS")}</h1>
            <Panel>
                <PanelBody>
                    <h2 className="process-sales-header">
                        <div>Process
                            <div
                                style={{ width: "90px", textAlign: "center", display: "inline-block" }}>
                                <Controller
                                control={control}
                                name="date"
                                render={({ onChange, value }) => (
                                    <DateTime
                                        isValidDate={disableFutureDt}
                                        value={date}
                                        dateFormat="DD-MM-YYYY"
                                        timeFormat={false}
                                        closeOnSelect={true}
                                        onChange={(e) => {
                                            if (e instanceof moment) {
                                                setDate(e.format("DD-MM-YYYY"));
                                                getProductData(e.format("DD-MM-YYYY"));
                                                onChange(e.format("DD-MM-YYYY"));
                                            }
                                            else {
                                                onChange('');
                                            }
                                        }} />
                                )}
                                onChange={(value) => {
                                    setDate(value);
                                }}
                                />
                            </div> sales
                        </div>
                        {processed && <div style={{ color: "white", background: "white", userSelect: "none", fontSize: "10px", textAlign: "center", padding: "5px", height: "25px" }} onClick={() => unstuckSH()}>Unstuck</div>}
                    </h2>




                    {// display message depending whether processed or released yet
                        (!processed && !released) ?
                            (productData.length > 0 && oktoprocess ?
                            <div>
                                <div className="alert alert-success m-b-20">
                                    All payout entered, please click
                                    <button className="btn btn-primary btn-sm" onClick={() => preSubmit()}>PROCESS</button>
                                    to begin shares payout process.
                                </div>
                            </div>
                            :
                            <div className="alert alert-warning m-b-20" role="alert">
                                {initPage ? "Please select date to continue." : "Please enter all payouts to proceed to process."}
                            </div>)
                        : (processed && !released) ?
                            <div>
                                <div className="alert alert-success m-b-20">
                                    All payout processed, please click
                                    <button className="btn btn-primary btn-sm" onClick={() => preSubmitReleased()}>RELEASE</button>
                                    to begin shares payout process.
                                </div>
                            </div>
                        :
                        <div className="alert alert-success m-b-20">
                            <u><b>{date}</b></u> sales processed.
                        </div>
                    }

                    <table key="main-table" className="table table-hover table-process" style={tableStyle}>
                        <tbody>
                            <tr style={lineRowStyle}>
                                <th style={textLeftStyle}>Name</th>
                                <th style={leftBorderStyle}>Turnover</th>
                                <th style={textRightStyle}>V.Turnover</th>
                                <th style={textRightStyle}>Win/Loss</th>
                                <th style={textRightStyle}>Royalty</th>
                                <th style={textRightStyle}>Comm</th>
                                <th style={textRightStyle}>Reward</th>
                                <th style={textRightStyle}>Balance</th>
                                <th style={textRightStyle}>Payout</th>
                                <th style={leftBorderStyle}>Net Profit</th>
                            </tr>

                            {Object.keys(categoryData) && Object.keys(categoryData).length > 0 &&
                                Object.keys(categoryData).map((categoryName, i) => {
                                    if (typeof (categoryTotal[categoryName]) !== "undefined") {
                                        return (
                                            <>
                                                {
                                                    categoryName === "undefined" && <>
                                                        <tr style={{ position: "relative" }} key={i}>
                                                            <td style={textLeftStyle}>{t("Settlement")} {!processed && <div className="reset-sh-btn" onClick={() => clearSH("Settlement")}>{t("DELETE")}</div>}</td>
                                                            <td style={leftBorderStyle}>{
                                                                categoryTotal[categoryName].turnover != null ?
                                                                    numberWithCurrencyFormat(parseFloat(categoryTotal[categoryName].turnover), 3, true)
                                                                    :
                                                                    "-"
                                                            }</td>
                                                            <th style={textRightStyle}>{numberWithCurrencyFormat(parseFloat(categoryTotal[categoryName].validTurnover), 3, true)}</th>
                                                            <td style={textRightStyle}>NIL</td>
                                                            <td style={textRightStyle}>NIL</td>
                                                            <td style={textRightStyle}>NIL</td>
                                                            <td style={textRightStyle}>NIL</td>
                                                            <td style={textRightStyle}>NIL</td>
                                                            <td style={textRightStyle}>{
                                                                !processed && <>
                                                                        {categoryTotal[categoryName].payout !== null ?
                                                                <a style={{ color: "#0078ff", fontSize: "12px", fontWeight: "bold", textDecoration: "underline" }} onClick={() => {
                                                                    navigateTo(
                                                                        _history,
                                                                        WebUrl._URL_SHAREHOLDERS_PAYOUT_EDIT,
                                                                        productData.filter((option) => option.productName == 'Settlement')[0]
                                                                    );
                                                                }} href="#set">{numberWithCurrencyFormat(parseFloat(categoryTotal[categoryName].payout), 3, true)}</a>
                                                                :
                                                                <a style={{ color: "#0078ff", fontSize: "12px", fontWeight: "bold", textDecoration: "underline" }} onClick={() => {
                                                                    navigateTo(
                                                                        _history,
                                                                        WebUrl._URL_SHAREHOLDERS_PAYOUT_EDIT,
                                                                        productData.filter((option) => option.productName == 'Settlement')[0]
                                                                    );
                                                                }} href="#set">Please set here</a>
                                                                        }
                                                                    </>
                                                            }{processed && numberWithCurrencyFormat(parseFloat(categoryTotal[categoryName].payout), 3, true)}</td>
                                                            <td className={(categoryTotal[categoryName].payout * -1 < 0 ? "text-red":"")} style={leftBorderStyle}>{categoryTotal[categoryName].netProfit !== null ? numberWithCurrencyFormat(parseFloat(categoryTotal[categoryName].payout*-1), 3, true) : "NIL"}</td>
                                                        </tr>
                                                        <tr style={greyStyle}>
                                                            <th style={textLeftStyle}></th>
                                                            <th style={leftBorderStyle}></th>
                                                            <th style={textRightStyle}></th>
                                                            <th style={textRightStyle}></th>
                                                            <th style={textRightStyle}></th>
                                                            <th style={textRightStyle}></th>
                                                            <th style={textRightStyle}></th>
                                                            <th style={textRightStyle}></th>
                                                            <th style={textRightStyle}></th>
                                                            <th style={leftBorderStyle}></th>
                                                        </tr>
                                                    </>
                                                }

                                                {
                                                    categoryName !== "undefined" && <>
                                                        <tr key={i} style={lineRowStyle}>
                                                            <td style={textLeftStyle}>{categoryName} <span style={{float:"right"}}>TOTAL</span></td>
                                                            <td className={(categoryTotal[categoryName].turnover < 0 ? "text-red" : "")} style={leftBorderStyle}>{

                                                                categoryTotal[categoryName].turnover != null ?
                                                                    numberWithCurrencyFormat(parseFloat(categoryTotal[categoryName].turnover), 3, true)
                                                                    :
                                                                    "-"
                                                            }</td>
                                                            <td className={(categoryTotal[categoryName].validTurnover < 0 ? "text-red" : "")} style={textRightStyle}>{numberWithCurrencyFormat(parseFloat(categoryTotal[categoryName].validTurnover), 3, true)}</td>
                                                            <td className={(categoryTotal[categoryName].winlose < 0 ? "text-red" : "")} style={textRightStyle}>{numberWithCurrencyFormat(parseFloat(categoryTotal[categoryName].winlose), 3, true)}</td>
                                                            <td className={(categoryTotal[categoryName].royalty < 0 ? "text-red" : "")} style={textRightStyle}>{numberWithCurrencyFormat(parseFloat(categoryTotal[categoryName].royalty), 3, true)}</td>
                                                            <td className={(categoryTotal[categoryName].commission < 0 ? "text-red" : "")} style={textRightStyle}>{numberWithCurrencyFormat(parseFloat(categoryTotal[categoryName].commission), 3, true)}</td>
                                                            <td className={(categoryTotal[categoryName].rewards < 0 ? "text-red" : "")} style={textRightStyle}>{numberWithCurrencyFormat(parseFloat(categoryTotal[categoryName].rewards), 3, true)}</td>
                                                            <td className={(categoryTotal[categoryName].balance < 0 ? "text-red" : "")} style={balanceStyle}>{numberWithCurrencyFormat(parseFloat(categoryTotal[categoryName].balance), 3, true)}</td>
                                                            <td style={payoutStyle}>{
                                                                isNaN(categoryTotal[categoryName].payout) ?
                                                                    "NIL"
                                                                    :
                                                                    numberWithCurrencyFormat(parseFloat(categoryTotal[categoryName].payout), 3, true)
                                                            }</td>
                                                            <td className={(categoryTotal[categoryName].netProfit < 0 ? "text-red" : "")} style={netProfitStyle}>{categoryTotal[categoryName].netProfit !== null ? numberWithCurrencyFormat(parseFloat(categoryTotal[categoryName].netProfit), 3, true) : <span className="badge bg-danger">{t("NOT_SET")}</span>}</td>
                                                        </tr>
                                                    </>
                                                }

                                                {Object.keys(categoryData[categoryName]) && Object.keys(categoryData[categoryName]).length > 0 &&
                                                    Object.keys(categoryData[categoryName]).map((product, i) => {
                                                        return (
                                                            <tr key={i} style={{ position: "relative", ...greyStyle}}>
                                                                <td className="process-category" style={{ textAlign: "left", paddingLeft: "20px" }}>{i + 1}. {product}{!processed && <div className="reset-sh-btn" onClick={() => clearSH(product)}>{t("DELETE")}</div>}</td>
                                                                <td className={(categoryData[categoryName][product].turnover < 0 ? "text-red" : "")} style={leftBorderStyle}>{

                                                                    categoryData[categoryName][product].turnover != null ?
                                                                        numberWithCurrencyFormat(parseFloat(categoryData[categoryName][product].turnover), 3, true)
                                                                        :
                                                                        "-"
                                                                }</td>
                                                                <td className={(categoryData[categoryName][product].validTurnover < 0 ? "text-red" : "")} style={textRightStyle}>{
                                                                    categoryData[categoryName][product].turnover != null ?
                                                                        numberWithCurrencyFormat(parseFloat(categoryData[categoryName][product].validTurnover), 3, true)
                                                                        :
                                                                        "-"
                                                                }</td>
                                                                <td className={(categoryData[categoryName][product].winlose < 0 ? "text-red" : "")} style={textRightStyle}>{
                                                                    categoryData[categoryName][product].turnover != null ?
                                                                        numberWithCurrencyFormat(parseFloat(categoryData[categoryName][product].winlose), 3, true)
                                                                        :
                                                                        "-"
                                                                }</td>
                                                                <td className={(categoryData[categoryName][product].royalty < 0 ? "text-red" : "")} style={textRightStyle}>{
                                                                    categoryData[categoryName][product].turnover != null ?
                                                                        numberWithCurrencyFormat(parseFloat(categoryData[categoryName][product].royalty), 3, true)
                                                                        :
                                                                        "-"
                                                                }</td>
                                                                <td className={(categoryData[categoryName][product].commission < 0 ? "text-red" : "")} style={textRightStyle}>{
                                                                    categoryData[categoryName][product].turnover != null ?
                                                                        numberWithCurrencyFormat(parseFloat(categoryData[categoryName][product].commission), 3, true)
                                                                        :
                                                                        "-"
                                                                }</td>
                                                                <td className={(categoryData[categoryName][product].rewards < 0 ? "text-red" : "")} style={textRightStyle}>{
                                                                    categoryData[categoryName][product].turnover != null ?
                                                                        numberWithCurrencyFormat(parseFloat(categoryData[categoryName][product].rewards), 3, true)
                                                                        :
                                                                        "-"
                                                                }</td>
                                                                <td className={(categoryData[categoryName][product].balance < 0 ? "text-red" : "")} style={textRightStyle}>{
                                                                    categoryData[categoryName][product].turnover != null ?
                                                                        numberWithCurrencyFormat(parseFloat(categoryData[categoryName][product].balance), 3, true)
                                                                        :
                                                                        "-"
                                                                }</td>
                                                                <td style={textRightStyle}>{
                                                                    !processed && <>
                                                                        {
                                                                            categoryData[categoryName][product].payout == null ?
                                                                        <a style={{ color: "#0078ff", fontSize: "12px", fontWeight: "bold", textDecoration: "underline" }} onClick={() => {
                                                                            navigateTo(
                                                                                _history,
                                                                                WebUrl._URL_SHAREHOLDERS_PAYOUT_EDIT,
                                                                                productData.filter((option) => option.productName === product)[0]
                                                                            );
                                                                        }} href="#set">Please set here</a>
                                                                        : 
                                                                        <a style={{ color: "#0078ff", fontSize: "12px", fontWeight: "bold", textDecoration: "underline" }} onClick={() => {
                                                                            console.log(productData.filter((option) => option.productName === product)[0]);
                                                                            navigateTo(
                                                                                _history,
                                                                                WebUrl._URL_SHAREHOLDERS_PAYOUT_EDIT,
                                                                                productData.filter((option) => option.productName === product)[0]
                                                                            );
                                                                        }} href="#set">{numberWithCurrencyFormat(parseFloat(categoryData[categoryName][product].payout), 3, true)}</a>
                                                                        }
                                                                    </>
                                                                }  {processed && numberWithCurrencyFormat(parseFloat(categoryData[categoryName][product].payout),3,true)}
                                                                </td>
                                                                <td className={(categoryData[categoryName][product].validTurnover - categoryData[categoryName][product].payout < 0 ? "text-red" : "")} style={leftBorderStyle}>{
                                                                    categoryData[categoryName][product].payout > 0 ? numberWithCurrencyFormat(parseFloat(categoryData[categoryName][product].validTurnover-categoryData[categoryName][product].payout), 3, true) : ""
                                                                }</td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </>
                                        )
                                    }
                                })
                            }
                        </tbody>
                    </table>

                    {
                        processed &&
                        <div>
                            {poolProcessedRow && Object.keys(poolProcessedRow).length > 0 &&
                                Object.keys(poolProcessedRow).map((poolName, i) => (
                                    <div key={i}>
                                        <h3>{poolName}</h3>
                                        <table key="main-table" className="table table-hover table-process" style={tableStyle}>
                                        <tbody>
                                            <tr style={lineRowStyle}>
                                                <th style={textLeftStyle}>Name</th>
                                                <th style={leftBorderStyle}>Turnover</th>
                                                <th style={textRightStyle}>V.Turnover</th>
                                                <th style={textRightStyle}>Win/Loss</th>
                                                <th style={textRightStyle}>Royalty</th>
                                                <th style={textRightStyle}>Comm</th>
                                                <th style={textRightStyle}>Reward</th>
                                                <th style={textRightStyle}>Balance</th>
                                                <th style={textRightStyle}>Payout</th>
                                                <th style={leftBorderStyle}>Net Profit</th>
                                            </tr>
                                            {console.log('start')}
                                            {console.log(poolName)}
                                            {poolProcessedRow[poolName] && Object.keys(poolProcessedRow[poolName].categoryRow).length > 0 &&
                                                Object.keys(poolProcessedRow[poolName].categoryRow).map((categoryName, i) => {
                                                    console.log('poolName');
                                                    console.log(poolProcessedRow[poolName]);
                                                    console.log(poolProcessedRow[poolName].catTotal);
                                                    
                                                    if (typeof (poolProcessedRow[poolName].catTotal[categoryName]) !== "undefined") {
                                                        return (
                                                            <>
                                                                {
                                                                    categoryName === "undefined" && <>
                                                                        <tr style={{ position: "relative" }} key={i}>
                                                                            <td style={textLeftStyle}>{t("Settlement")} {!processed && <div className="reset-sh-btn" onClick={() => clearSH("Settlement")}>{t("DELETE")}</div>}</td>
                                                                            <td style={leftBorderStyle}>{
                                                                                poolProcessedRow[poolName].catTotal[categoryName].turnover != null ?
                                                                                    numberWithCurrencyFormat(parseFloat(poolProcessedRow[poolName].catTotal[categoryName].turnover), 3, true)
                                                                                    :
                                                                                    "-"
                                                                            }</td>
                                                                            <th style={textRightStyle}>{numberWithCurrencyFormat(parseFloat(poolProcessedRow[poolName].catTotal[categoryName].validTurnover), 3, true)}</th>
                                                                            <td style={textRightStyle}>NIL</td>
                                                                            <td style={textRightStyle}>NIL</td>
                                                                            <td style={textRightStyle}>NIL</td>
                                                                            <td style={textRightStyle}>NIL</td>
                                                                            <td style={textRightStyle}>NIL</td>
                                                                            <td style={textRightStyle}>{processed && numberWithCurrencyFormat(parseFloat(poolProcessedRow[poolName].catTotal[categoryName].payout), 3, true)}</td>
                                                                            <td className={(poolProcessedRow[poolName].catTotal[categoryName].payout * -1 < 0 ? "text-red":"")} style={leftBorderStyle}>{poolProcessedRow[poolName].catTotal[categoryName].netProfit !== null ? numberWithCurrencyFormat(parseFloat(poolProcessedRow[poolName].catTotal[categoryName].payout*-1), 3, true) : "NIL"}</td>
                                                                        </tr>
                                                                        <tr style={greyStyle}>
                                                                            <th style={textLeftStyle}></th>
                                                                            <th style={leftBorderStyle}></th>
                                                                            <th style={textRightStyle}></th>
                                                                            <th style={textRightStyle}></th>
                                                                            <th style={textRightStyle}></th>
                                                                            <th style={textRightStyle}></th>
                                                                            <th style={textRightStyle}></th>
                                                                            <th style={textRightStyle}></th>
                                                                            <th style={textRightStyle}></th>
                                                                            <th style={leftBorderStyle}></th>
                                                                        </tr>
                                                                    </>
                                                                }
                                                             
                                                                {
                                                                    categoryName !== "undefined" && <>
                                                                        <tr key={i} style={lineRowStyle}>
                                                                            <td style={textLeftStyle}>{categoryName} <span style={{float:"right"}}>TOTAL</span></td>
                                                                            <td className={(poolProcessedRow[poolName].catTotal[categoryName].turnover < 0 ? "text-red" : "")} style={leftBorderStyle}>{
                                                                                    numberWithCurrencyFormat(parseFloat(poolProcessedRow[poolName].catTotal[categoryName].turnover), 3, true)
                                                                            }</td>
                                                                            <td className={(poolProcessedRow[poolName].catTotal[categoryName].validTurnover < 0 ? "text-red" : "")} style={textRightStyle}>{numberWithCurrencyFormat(parseFloat(poolProcessedRow[poolName].catTotal[categoryName].validTurnover), 3, true)}</td>
                                                                            <td className={(poolProcessedRow[poolName].catTotal[categoryName].winlose < 0 ? "text-red" : "")} style={textRightStyle}>{numberWithCurrencyFormat(parseFloat(poolProcessedRow[poolName].catTotal[categoryName].winlose), 3, true)}</td>
                                                                            <td className={(poolProcessedRow[poolName].catTotal[categoryName].royalty < 0 ? "text-red" : "")} style={textRightStyle}>{numberWithCurrencyFormat(parseFloat(poolProcessedRow[poolName].catTotal[categoryName].royalty), 3, true)}</td>
                                                                            <td className={(poolProcessedRow[poolName].catTotal[categoryName].commission < 0 ? "text-red" : "")} style={textRightStyle}>{numberWithCurrencyFormat(parseFloat(poolProcessedRow[poolName].catTotal[categoryName].commission), 3, true)}</td>
                                                                            <td className={(poolProcessedRow[poolName].catTotal[categoryName].rewards < 0 ? "text-red" : "")} style={textRightStyle}>{numberWithCurrencyFormat(parseFloat(poolProcessedRow[poolName].catTotal[categoryName].rewards), 3, true)}</td>
                                                                            <td className={(poolProcessedRow[poolName].catTotal[categoryName].balance < 0 ? "text-red" : "")} style={balanceStyle}>{numberWithCurrencyFormat(parseFloat(poolProcessedRow[poolName].catTotal[categoryName].balance), 3, true)}</td>
                                                                            <td style={payoutStyle}>{
                                                                                    numberWithCurrencyFormat(parseFloat(poolProcessedRow[poolName].catTotal[categoryName].payout), 3, true)
                                                                            }</td>
                                                                            <td className={(poolProcessedRow[poolName].catTotal[categoryName].netProfit < 0 ? "text-red" : "")} style={netProfitStyle}>{numberWithCurrencyFormat(parseFloat(poolProcessedRow[poolName].catTotal[categoryName].netProfit), 3, true)}</td>
                                                                        </tr>
                                                                    </>
                                                                }

                                                                {Object.keys(poolProcessedRow[poolName].categoryRow[categoryName]) && Object.keys(poolProcessedRow[poolName].categoryRow[categoryName]).length > 0 &&
                                                                    Object.keys(poolProcessedRow[poolName].categoryRow[categoryName]).map((product, i) => {
                                                                        console.log(product);
                                                                        console.log(poolProcessedRow[poolName].categoryRow[categoryName][product]);
                                                                        
                                                                        return (
                                                                            <tr key={i} style={{ position: "relative", ...greyStyle}}>
                                                                                <td className="process-category" style={{ textAlign: "left", paddingLeft: "20px" }}>{i + 1}. {product}{!processed && <div className="reset-sh-btn" onClick={() => clearSH(product)}>{t("DELETE")}</div>}</td>
                                                                                <td className={(poolProcessedRow[poolName].categoryRow[categoryName][product].turnover < 0 ? "text-red" : "")} style={leftBorderStyle}>{

                                                                                    poolProcessedRow[poolName].categoryRow[categoryName][product].turnover != null ?
                                                                                        numberWithCurrencyFormat(parseFloat(poolProcessedRow[poolName].categoryRow[categoryName][product].turnover), 3, true)
                                                                                        :
                                                                                        "-"
                                                                                }</td>
                                                                                <td className={(poolProcessedRow[poolName].categoryRow[categoryName][product].validTurnover < 0 ? "text-red" : "")} style={textRightStyle}>{
                                                                                    poolProcessedRow[poolName].categoryRow[categoryName][product].turnover != null ?
                                                                                        numberWithCurrencyFormat(parseFloat(poolProcessedRow[poolName].categoryRow[categoryName][product].validTurnover), 3, true)
                                                                                        :
                                                                                        "-"
                                                                                }</td>
                                                                                <td className={(poolProcessedRow[poolName].categoryRow[categoryName][product].winlose < 0 ? "text-red" : "")} style={textRightStyle}>{
                                                                                    poolProcessedRow[poolName].categoryRow[categoryName][product].turnover != null ?
                                                                                        numberWithCurrencyFormat(parseFloat(poolProcessedRow[poolName].categoryRow[categoryName][product].winlose), 3, true)
                                                                                        :
                                                                                        "-"
                                                                                }</td>
                                                                                <td className={(poolProcessedRow[poolName].categoryRow[categoryName][product].royalty < 0 ? "text-red" : "")} style={textRightStyle}>{
                                                                                    poolProcessedRow[poolName].categoryRow[categoryName][product].turnover != null ?
                                                                                        numberWithCurrencyFormat(parseFloat(poolProcessedRow[poolName].categoryRow[categoryName][product].royalty), 3, true)
                                                                                        :
                                                                                        "-"
                                                                                }</td>
                                                                                <td className={(poolProcessedRow[poolName].categoryRow[categoryName][product].commission < 0 ? "text-red" : "")} style={textRightStyle}>{
                                                                                    poolProcessedRow[poolName].categoryRow[categoryName][product].turnover != null ?
                                                                                        numberWithCurrencyFormat(parseFloat(poolProcessedRow[poolName].categoryRow[categoryName][product].commission), 3, true)
                                                                                        :
                                                                                        "-"
                                                                                }</td>
                                                                                <td className={(poolProcessedRow[poolName].categoryRow[categoryName][product].rewards < 0 ? "text-red" : "")} style={textRightStyle}>{
                                                                                    poolProcessedRow[poolName].categoryRow[categoryName][product].turnover != null ?
                                                                                        numberWithCurrencyFormat(parseFloat(poolProcessedRow[poolName].categoryRow[categoryName][product].rewards), 3, true)
                                                                                        :
                                                                                        "-"
                                                                                }</td>
                                                                                <td className={(poolProcessedRow[poolName].categoryRow[categoryName][product].balance < 0 ? "text-red" : "")} style={textRightStyle}>{
                                                                                    poolProcessedRow[poolName].categoryRow[categoryName][product].turnover != null ?
                                                                                        numberWithCurrencyFormat(parseFloat(poolProcessedRow[poolName].categoryRow[categoryName][product].balance), 3, true)
                                                                                        :
                                                                                        "-"
                                                                                }</td>
                                                                                <td style={textRightStyle}>{processed && numberWithCurrencyFormat(parseFloat(poolProcessedRow[poolName].categoryRow[categoryName][product].payout),3,true)}
                                                                                </td>
                                                                                <td className={(poolProcessedRow[poolName].categoryRow[categoryName][product].validTurnover - poolProcessedRow[poolName].categoryRow[categoryName][product].payout < 0 ? "text-red" : "")} style={leftBorderStyle}>{
                                                                                    poolProcessedRow[poolName].categoryRow[categoryName][product].payout > 0 ? numberWithCurrencyFormat(parseFloat(poolProcessedRow[poolName].categoryRow[categoryName][product].validTurnover-poolProcessedRow[poolName].categoryRow[categoryName][product].payout), 3, true) : ""
                                                                                }</td>
                                                                            </tr>
                                                                        )
                                                                    })
                                                                }

                                                                
                                                                

                                                            </>
                                                        )
                                                    }
                                                    })
                                                }
                                        </tbody>
                                    </table>
                                    </div>
                                ))
                            }
                        </div>
                    }
                </PanelBody>

            </Panel>
        </div>
    );
};

export default ShareholdersProcessSales;