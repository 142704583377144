import React, { useState, useEffect } from "react";
import ReactTable from "../../components/constantComponent/reactTable/ReactTable";
import { Panel, PanelBody } from "./../../components/panel/panel.jsx";
import { useHistory, useLocation } from "react-router-dom";
import NavigationButton from "../../components/constantComponent/button/NavigationButton";
import {
	ApiUrl,
	ApiKey,
	WebUrl,
	AccessRight,
	LanguageKey,
	DEFAULT_PAGE_SIZE,
} from "../../util/Constant";
import { numberWithCurrencyFormat, stringIsNullOrEmpty } from "../../util/Util";
import ApiEngine from "../../util/ApiEngine";
import { setBusy, setIdle, showMessage } from "../../redux/AppAction";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { DateRangePicker } from "react-bootstrap-daterangepicker";

/// <summary>
/// Author : -
/// </summary>

const MemberPointsReport = (props) => {
	const { t } = useTranslation(LanguageKey._PRIMARY);
	const _dispatch = useDispatch();
	const _location = useLocation();
	const _history = useHistory();
	const [searchTrigger, setSearchTrigger] = useState("");
	const [startDate, setStartDate] = useState(
		moment().startOf("day").format("YYYY-MM-DD HH:mm:ss")
	);
	const [endDate, setEndDate] = useState(
		moment().endOf("day").format("YYYY-MM-DD HH:mm:ss")
	);
	const [selectedDate, setSelectedDate] = useState("");

	let _tableColumns = [
		{
			Header: "USERNAME",
			accessor: "username",
			Cell: ({ row }) => (
				<div style={{ textAlign: "center" }}>{row.original.username}</div>
			),
			Footer: () => {
				return (
					<span>
						<b>{t("TOTAL")}: </b>
					</span>
				);
			},
			disableSortBy: true,
			disableFilters: true,
		},
		{
			Header: "DATE",
			accessor: "date",
			Cell: ({ row }) => {
				return (
					<div style={{ textAlign: "center" }}>
						{moment(row.original.parsedTime).format("YYYY-MM-DD")}
					</div>
				);
			},
			disableFilters: true,
		},
		{
			Header: "REMARK",
			accessor: "remark",
			Cell: ({ row }) => {
				return <div style={{ textAlign: "center" }}>{row.original.remark}</div>;
			},
			disableFilters: true,
		},
		{
			Header: "POINTS",
			accessor: "pointsAmount",
			Cell: ({ row }) => (
				<div style={{ textAlign: "right" }}>{row.original.pointsAmount}</div>
			),
			Footer: ({ page }) => {
				const totalPoints = page.reduce((sum, currentValue) => {
					if (currentValue.original.pointsAmount) {
						return sum + currentValue.original.pointsAmount;
					} else {
						return sum;
					}
				}, 0);
				return (
					<span>
						<div style={{ textAlign: "right" }}>
							<b>{totalPoints}</b>
						</div>
					</span>
				);
			},
			disableFilters: true,
		},
	];

	const init = async () => {
		var apiUrl =
			ApiUrl._API_GET_OVERALL_POINTS_DETAILS +
			"?StartDate=" +
			moment(startDate).startOf("day").format("YYYY-MM-DD") +
			"&EndDate=" +
			moment(endDate).endOf("day").format("YYYY-MM-DD");

		setSearchTrigger(apiUrl);
	};

	const handleEvent = (event, picker) => {
		setStartDate(moment(picker.startDate).format("YYYY-MM-DD HH:mm:ss"));
		setEndDate(moment(picker.endDate).format("YYYY-MM-DD HH:mm:ss"));
		setSelectedDate("");

		let apiUrl =
			ApiUrl._API_GET_OVERALL_POINTS_DETAILS +
			"?StartDate=" +
			moment(picker.startDate).format("YYYY-MM-DD") +
			"&EndDate=" +
			moment(picker.endDate).format("YYYY-MM-DD");

		setSearchTrigger(apiUrl);
	};

	const handleSelectDate = async (date) => {
		setStartDate(moment(date).startOf("day").format("YYYY-MM-DD HH:mm:ss"));
		setEndDate(moment(date).endOf("day").format("YYYY-MM-DD HH:mm:ss"));
		setSelectedDate(date);

		let apiUrl =
			ApiUrl._API_GET_OVERALL_POINTS_DETAILS +
			"?StartDate=" +
			startDate +
			"&EndDate=" +
			endDate +
			"&SelectedDate=" +
			date;

		setSearchTrigger(apiUrl);
	};

	/// <summary>
	/// Author : -
	/// </summary>
	useEffect(() => {
		init();
	}, []);

	return (
		<div>
			<h1 className="page-header">
				{t("MEMBER_POINTS_REPORT")}
				<NavigationButton history={_history} />
			</h1>
			<Panel>
				<PanelBody>
					<div className="row">
						<div
							className="col-lg-4"
							style={{ display: "flex", alignItems: "end" }}
						>
							<div
								className="form-group"
								style={{
									display: "flex",
									width: "100%",
									flexFlow: "nowrap",
								}}
							>
								<div
									className="input-group"
									style={{ width: "100%", flexFlow: "nowrap" }}
								>
									<div className="input-group-prepend">
										<span className="input-group-text">
											<i className="fa fa-calendar"></i>
										</span>
									</div>
									<div style={{ width: "80%" }}>
										<DateRangePicker
											containerStyles={{ width: "100%" }}
											startDate={startDate}
											endDate={endDate}
											onApply={handleEvent}
											alwaysShowCalendars={true}
											locale={{ format: "YYYY-MM-DD HH:mm:ss" }}
											timePicker={true}
											timePickerSeconds={true}
											ranges={{
												Today: [moment().startOf("day"), moment().endOf("day")],
												Yesterday: [
													moment().subtract(1, "days").startOf("day"),
													moment().subtract(1, "days").endOf("day"),
												],
												"Last 7 Days": [
													moment().subtract(6, "days").startOf("day"),
													moment().endOf("day"),
												],
												"Last 30 Days": [
													moment().subtract(29, "days").startOf("day"),
													moment().endOf("day"),
												],
												"This Month": [
													moment().startOf("month"),
													moment().endOf("month"),
												],
												"Last Month": [
													moment().subtract(1, "month").startOf("month"),
													moment().subtract(1, "month").endOf("month"),
												],
												"This Year": [
													moment().startOf("year"),
													moment().endOf("year"),
												],
											}}
										>
											<input
												type="text"
												className="form-control"
												value={startDate + " - " + endDate}
											/>
										</DateRangePicker>
									</div>
								</div>
							</div>
						</div>
					</div>
					<hr />
					<ReactTable
						filterable
						fetchUrl={searchTrigger}
						renderFooter={true}
						columns={_tableColumns}
					/>
				</PanelBody>
			</Panel>
		</div>
	);
};

export default MemberPointsReport;
