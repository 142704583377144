import React, { useState, useEffect } from "react";
import ReactTable from "../../components/constantComponent/reactTable/ReactTable";
import { ApiKey, ApiUrl, LanguageKey } from "../../util/Constant";
import { useTranslation } from "react-i18next";
import { fetch } from "whatwg-fetch";

/// <summary>
/// Author : -
/// </summary>

const UserIpPanel = (props) => {
  const [groupedUserIp, setGroupedUserIp] = useState([]);
  const { t } = useTranslation(LanguageKey._PRIMARY);
  /// <summary>
  /// Author : -
  /// </summary>
  useEffect(() => {
    init();
  }, []);

  /// <summary>
  /// Author : -
  /// </summary>
  const init = async () => {
    if (props.data) {
      let apiUrl = ApiUrl._API_GET_USER_GROUPED_IP;
      apiUrl +=
        "?startDate=" +
        props.data.startDate +
        "&endDate=" +
        props.data.endDate +
        "&country=" +
        props.data.country +
        "&ipAddress=" +
        props.data.ipAddress;

      await fetch(apiUrl, {
        method: ApiKey._API_GET,
        headers: {
          "Content-Type": ApiKey._API_APPLICATION_JSON,
          "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
        },
      })
        .then((response) => response.json())
        .then((responseJson) => {
          if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            setGroupedUserIp(responseJson[ApiKey._API_DATA_KEY]);
          }
        });
    }
  };

  let _IPUserColumns = [
    {
      Header: "USER_TYPE",
      accessor: "userType",
    },
    {
      Header: "USERNAME",
      accessor: "username",
    },
    {
      Header: "IP_ADDRESS",
      accessor: "ipAddress",
    },
    {
      Header: "COUNTRY",
      accessor: "country",
    },
    {
      Header: "TOTAL_USED_COUNT",
      accessor: "totalCount",
    },
    {
      id: "action",
      Header: "",
      Cell: ({ row }) => {
        return (
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => {
              props.toggleNested();
              props.prepareStateForUserIpDetail(row.original);
            }}
          >
            {t("SHOW_SESSIONS")}
          </button>
        );
      },
      disableFilters: true,
    },
  ];

  return (
    <div>
      <ReactTable data={groupedUserIp} columns={_IPUserColumns} />
    </div>
  );
};

export default UserIpPanel;
