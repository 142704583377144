import React, { useState, useEffect, useMemo, useCallback } from "react";
import ReactTable from "../../components/constantComponent/reactTable/ReactTable";
import {
	Panel,
	PanelHeader,
	PanelBody,
} from "./../../components/panel/panel.jsx";
import { useHistory } from "react-router-dom";
import {
	UncontrolledButtonDropdown,
	DropdownToggle,
	DropdownMenu,
	DropdownItem,
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader,
} from "reactstrap";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import DateRangePicker from "react-bootstrap-daterangepicker";
import moment from "moment";
import { navigateTo } from "../../util/Util";
import {
	ApiKey,
	ApiUrl,
	WebUrl,
	AccessRight,
	TransactionType,
	TransactionStatus,
	PanelType,
	LanguageKey,
	BankingChannelName,
	DEFAULT_PAGE_SIZE,
} from "../../util/Constant";
import { setBusy, setIdle, showMessage } from "../../redux/AppAction";
import { useDispatch, useSelector } from "react-redux";
import {
	stringIsNullOrEmpty,
	createFormBody,
	numberWithCurrencyFormat,
} from "../../util/Util";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import ApiEngine from "../../util/ApiEngine";
import { AsyncPaginate } from "react-select-async-paginate";
import SweetAlert from "react-bootstrap-sweetalert";

const PlayerReconciliation = (props) => {
	const { t } = useTranslation(LanguageKey._PRIMARY);
	let _history = useHistory();
	const _dispatch = useDispatch();
	const [startDate, setStartDate] = useState(
		moment().startOf("day").format("YYYY-MM-DD HH:mm:ss")
	);
	const [endDate, setEndDate] = useState(
		moment().endOf("day").format("YYYY-MM-DD HH:mm:ss")
	);
	const _SELECTED_USERNAME_KEY = "selectedUsernameKey";
	const [reportData, setReportData] = useState([]);
	const [labelUsername, setLabelUsername] = useState("All");
	const [username, setUsername] = useState("");

	const handleEvent = (event, picker) => {
		setStartDate(moment(picker.startDate).format("YYYY-MM-DD HH:mm:ss"));
		setEndDate(moment(picker.endDate).format("YYYY-MM-DD HH:mm:ss"));
	};

	const getSummaryTransaction = async () => {
		try {
			var apiUrl =
				ApiUrl._API_GET_PLAYER_RECONCILIATION +
				"?startDate=" +
				startDate +
				"&endDate=" +
				endDate;

			apiUrl += "&username=" + username;

			var responseJson = await ApiEngine.get(apiUrl);

			if (responseJson[ApiKey._API_SUCCESS_KEY]) {
				setReportData(responseJson[ApiKey._API_DATA_KEY]);
			} else {
				throw responseJson[ApiKey._API_MESSAGE_KEY];
			}
		} catch (error) {
			_dispatch(showMessage(false, t(error)));
		}
	};

	async function getMemberList(search, loadOptions, { page }) {
		var responseJson = await ApiEngine.get(
			`${ApiUrl._API_GET_USER_MEMBER_LIST}?&start=${
				(page - 1) * DEFAULT_PAGE_SIZE
			}&count=${DEFAULT_PAGE_SIZE}&keyword=${search}`
		);

		if (responseJson[ApiKey._API_SUCCESS_KEY]) {
			let data = responseJson[ApiKey._API_DATA_KEY]["data"];
			let formattedData = data.map((m) => {
				return { label: m.username, value: m.username };
			});
			formattedData.unshift({ label: "All", value: "" });

			return {
				options: formattedData,
				hasMore: formattedData.length == DEFAULT_PAGE_SIZE,
				additional: {
					page: page + 1,
				},
			};
		}
	}

	let _tableColumns = [
		{
			Header: t("USERNAME"),
			accessor: "username",
			Cell: ({ row }) => {
				return <>{t(row.original.username)}</>;
			},
			minWidth: 100,
			Footer: () => {
				return (
					<span>
						<b>{t("TOTAL")}: </b>
					</span>
				);
			},
		},

		{
			Header: t("TOTAL_DEPOSIT"),
			accessor: "deposit",
			Cell: ({ row }) => {
				return (
					<>{numberWithCurrencyFormat(parseFloat(row.original.deposit))}</>
				);
			},
			minWidth: 100,
			Footer: ({ page }) => {
				const debitSum = page.reduce((sum, currentValue) => {
					if (currentValue.original.deposit) {
						return sum + parseFloat(currentValue.original.deposit);
					} else {
						return sum;
					}
				}, 0);
				return (
					<span>
						<b>{numberWithCurrencyFormat(parseFloat(debitSum))}</b>
					</span>
				);
			},
		},
		{
			Header: t("TOTAL_WITHDRAWAL"),
			accessor: "withdrawal",
			Cell: ({ row }) => {
				return (
					<>{numberWithCurrencyFormat(parseFloat(row.original.withdrawal))}</>
				);
			},
			minWidth: 100,
			Footer: ({ page }) => {
				const debitSum = page.reduce((sum, currentValue) => {
					if (currentValue.original.withdrawal) {
						return sum + parseFloat(currentValue.original.withdrawal);
					} else {
						return sum;
					}
				}, 0);
				return (
					<span>
						<b>{numberWithCurrencyFormat(parseFloat(debitSum))}</b>
					</span>
				);
			},
		},
		{
			Header: t("TOTAL_BONUSES"),
			accessor: "bonuses",
			Cell: ({ row }) => {
				return (
					<>{numberWithCurrencyFormat(parseFloat(row.original.bonuses))}</>
				);
			},
			minWidth: 100,
			Footer: ({ page }) => {
				const debitSum = page.reduce((sum, currentValue) => {
					if (currentValue.original.bonuses) {
						return sum + parseFloat(currentValue.original.bonuses);
					} else {
						return sum;
					}
				}, 0);
				return (
					<span>
						<b>{numberWithCurrencyFormat(parseFloat(debitSum))}</b>
					</span>
				);
			},
		},
		{
			Header: t("TOTAL_COMMISSION"),
			accessor: "commission",
			Cell: ({ row }) => {
				return (
					<>{numberWithCurrencyFormat(parseFloat(row.original.commission), 3)}</>
				);
			},
			minWidth: 100,
			Footer: ({ page }) => {
				const debitSum = page.reduce((sum, currentValue) => {
					if (currentValue.original.commission) {
						return sum + parseFloat(currentValue.original.commission);
					} else {
						return sum;
					}
				}, 0);
				return (
					<span>
						<b>{numberWithCurrencyFormat(parseFloat(debitSum), 3)}</b>
					</span>
				);
			},
		},
		{
			Header: t("TOTAL_VALID_TURNOVER"),
			accessor: "validTurnover",
			Cell: ({ row }) => {
				return (
					<>{numberWithCurrencyFormat(parseFloat(row.original.validTurnover))}</>
				);
			},
			minWidth: 100,
			Footer: ({ page }) => {
				const debitSum = page.reduce((sum, currentValue) => {
					if (currentValue.original.validTurnover) {
						return sum + parseFloat(currentValue.original.validTurnover);
					} else {
						return sum;
					}
				}, 0);
				return (
					<span>
						<b>{numberWithCurrencyFormat(parseFloat(debitSum))}</b>
					</span>
				);
			},
		},
		{
			Header: t("TOTAL_WIN_AMOUNT"),
			accessor: "winAmount",
			Cell: ({ row }) => {
				return (
					<>{numberWithCurrencyFormat(parseFloat(row.original.winAmount))}</>
				);
			},
			minWidth: 100,
			Footer: ({ page }) => {
				const debitSum = page.reduce((sum, currentValue) => {
					if (currentValue.original.winAmount) {
						return sum + parseFloat(currentValue.original.winAmount);
					} else {
						return sum;
					}
				}, 0);
				return (
					<span>
						<b>{numberWithCurrencyFormat(parseFloat(debitSum))}</b>
					</span>
				);
			},
		},
		{
			Header: t("TOTAL_LOSS"),
			accessor: "totalLoss",
			Cell: ({ row }) => {
				return (
					<>{numberWithCurrencyFormat(parseFloat(row.original.totalLoss))}</>
				);
			},
			minWidth: 100,
			Footer: ({ page }) => {
				const debitSum = page.reduce((sum, currentValue) => {
					if (currentValue.original.totalLoss) {
						return sum + parseFloat(currentValue.original.totalLoss);
					} else {
						return sum;
					}
				}, 0);
				return (
					<span>
						<b>{numberWithCurrencyFormat(parseFloat(debitSum))}</b>
					</span>
				);
			},
		},
		{
			Header: t("TOTAL_WINLOSE"),
			accessor: "totalWinLose",
			Cell: ({ row }) => {
				return (
					<>{numberWithCurrencyFormat(parseFloat(row.original.totalWinLose))}</>
				);
			},
			minWidth: 100,
			Footer: ({ page }) => {
				const debitSum = page.reduce((sum, currentValue) => {
					if (currentValue.original.totalWinLose) {
						return sum + parseFloat(currentValue.original.totalWinLose);
					} else {
						return sum;
					}
				}, 0);
				return (
					<span>
						<b>{numberWithCurrencyFormat(parseFloat(debitSum))}</b>
					</span>
				);
			},
		},
		
		{
			Header: t("LAST_LOGIN"),
			accessor: "lastLogin",
			Cell: ({ row }) => {
				return <>{moment(row.original.lastLogin).format('YYYY-MM-DD HH:mm:ss')}</>;
			},
			minWidth: 100,
		},
	];

	return (
		<div>
			<h1 className="page-header">{t("PLAYER_RECONCILIATION")}</h1>
			<Panel>
				<div className="row">
					<div className="col-lg-12">
						<PanelBody>
							<div className="row">
								<div className="col-lg-4">
									<div className="form-group" style={{ display: "flex" }}>
										<div
											className="input-group"
											style={{ width: "100%", flexFlow: "nowrap" }}
										>
											<div className="input-group-prepend">
												<span className="input-group-text">
													<i className="fa fa-calendar"></i>
												</span>
											</div>
											<div
												className="input-group-append"
												style={{ width: "100%" }}
											>
												<DateRangePicker
													containerStyles={{ width: "100%" }}
													startDate={startDate}
													endDate={endDate}
													onApply={handleEvent}
													alwaysShowCalendars={true}
													locale={{ format: "YYYY-MM-DD HH:mm:ss" }}
													timePicker={true}
													timePickerSeconds={true}
													ranges={{
														Today: [
															moment().startOf("day"),
															moment().endOf("day"),
														],
														Yesterday: [
															moment().subtract(1, "days").startOf("day"),
															moment().subtract(1, "days").endOf("day"),
														],
														"Last 7 Days": [
															moment().subtract(6, "days").startOf("day"),
															moment().endOf("day"),
														],
														"Last 30 Days": [
															moment().subtract(29, "days").startOf("day"),
															moment().endOf("day"),
														],
														"This Month": [
															moment().startOf("month"),
															moment().endOf("month"),
														],
														"Last Month": [
															moment().subtract(1, "month").startOf("month"),
															moment().subtract(1, "month").endOf("month"),
														],
														"This Year": [
															moment().startOf("year"),
															moment().endOf("year"),
														],
													}}
												>
													<input
														type="text"
														className="form-control"
														value={startDate + " - " + endDate}
													/>
												</DateRangePicker>
											</div>
										</div>
									</div>
								</div>

								<div className="col-lg-4">
									<div
										className="input-group member-group mb-3"
										style={{ flexFlow: "nowrap" }}
									>
										<div className="input-group-prepend">
											<span className="input-group-text">
												<i className="fas fa-user"></i>
											</span>
										</div>
										<AsyncPaginate
											placeholder={labelUsername}
											debounceTimeout={250}
											loadOptions={getMemberList}
											additional={{
												page: 1,
											}}
											value={username}
											onChange={(e) => {
												if (!stringIsNullOrEmpty(e.value)) {
													sessionStorage.setItem(_SELECTED_USERNAME_KEY, e.value);
												} else {
													sessionStorage.removeItem(_SELECTED_USERNAME_KEY);
												}
												setLabelUsername(e.label);
												setUsername(e.value);
											}}
										/>
									</div>
								</div>

								<div className="col-lg-2">
									<button
										type="button"
										onClick={() => {
											getSummaryTransaction();
										}}
										className="btn btn-primary"
									>
										{t("SEARCH")}
									</button>
								</div>
							</div>
							<hr />
							<ReactTable
								data={reportData}
								columns={_tableColumns}
								renderFooter={true}
							/>
						</PanelBody>
					</div>
				</div>
			</Panel>
		</div>
	);
};

export default PlayerReconciliation;
