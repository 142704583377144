import React, { useState, useEffect, useMemo } from "react";
import { withRouter } from "react-router-dom";
import { Panel, PanelBody } from "./../../components/panel/panel.jsx";
import NavigationButton from "../../components/constantComponent/button/NavigationButton";
import { ApiKey, ApiUrl, WebUrl, LanguageKey } from "../../util/Constant";
import { createFormBody } from "../../util/Util";
import { useLocation, useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { showMessage } from "../../redux/AppAction";
import { useTranslation } from "react-i18next";
import SweetAlert from "react-bootstrap-sweetalert";
import ApiEngine from "../../util/ApiEngine";

/// <summary>
/// Author : -
/// </summary>
const AccountShareHolderHuatTicket = (props) => {
    const { t } = useTranslation(LanguageKey._PRIMARY);
    let _history = useHistory();
    const _location = useLocation();
    const _dispatch = useDispatch();
    const { register, handleSubmit, errors, setValue, watch } = useForm({ mode: "onBlur" });
    const [productId, setProductId] = useState('');
    const [productName, setProductName] = useState('');
    const [productCode, setProductCode] = useState('');
    const [status, setStatus] = useState(null);
    const [royalty, setRoyalty] = useState(0);
    const [markup, setMarkup] = useState(0);

    const [grosssales, setGrosssales] = useState(0);
    const [validTurnover, setValidTurnover] = useState(0);
    const [winAmt, setWinAmt] = useState(0);
    const [turnover, setTurnover] = useState(0);

    const [commission, setCommission] = useState(0);
    const [balance, setBalance] = useState(0);

    const [date, setDate] = useState('');


    /// <summary>
    /// Author : -
    /// </summary>
    useEffect(() => {
        init();
    }, []);


    /// <summary>
    /// Author : -
    /// </summary>
    function init() {
        if (_location.state) {

            setProductId(_location.state.id);
            setProductName(_location.state.productName);
            setProductCode(_location.state.productCode);
            setStatus(_location.state.status);

            setWinAmt(_location.state.winAmount);
            setTurnover(_location.state.grossSales);
            setValidTurnover(_location.state.validTurnover);
            setDate(_location.state.date);
            
        }
        else {
            _history.push(WebUrl._URL_SHAREHOLDERS_PROCESS_HUAT_TICKET)
        }
    }

    /// <summary>
    /// Author : -
    /// </summary>
    const submitForm = async (data, e) => {
        let params = {
            turnover: data.turnover,
            winAmt: data.winAmt,
            validTurnover: data.validTurnover,
            productId: productId,
            date: date
        };

        let formBody = createFormBody(params);
        let responseJson = await ApiEngine.post(
            ApiUrl._API_UPDATE_SHAREHOLDER_HUAT_TICKET,
            formBody
        );
        _dispatch(
            showMessage(
                responseJson[ApiKey._API_SUCCESS_KEY],
                t(responseJson[ApiKey._API_MESSAGE_KEY])
            )
        );

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            _history.push({
                pathname: WebUrl._URL_SHAREHOLDERS_PROCESS_HUAT_TICKET,
                state: { date: date }
            });
        }
    };

    /// <summary>
    /// Author : -
    /// </summary>
    function preSubmit() {
        handleSubmit(submitForm)();
    }

    return (
        <div>
            <h1 className="page-header">
                Shareholder Edit Huat VT/WinAmt
                <NavigationButton history={_history} />
            </h1>
            <div className="row">
                <div className="col-xl-12">
                    <Panel>
                        <PanelBody>
                            <form onSubmit={handleSubmit(submitForm)}>
                                <div className="row">
                                    <div className="col-lg-6">
                                        <h4>{t("PRODUCT_DETAIL")} - { date }</h4>
                                    </div>
                                </div>
                                
                                
                                <div className="row">
                                    <div className="col-lg-2">
                                        <label>
                                            <b>{t("PRODUCT_NAME")}</b>
                                        </label>
                                        <input type="text" className="form-control" value={productName} disabled />
                                    </div>

                                    <div className="col-lg-2">
                                        <label>
                                            <b>{t("PRODUCT_CODE")}</b>
                                        </label>
                                        <input type="text" className="form-control" value={productCode} disabled />
                                    </div>


                                    <div className="col-lg-2">
                                        <label>
                                            <b>{t("STATUS")}</b>
                                        </label>
                                        <br />
                                        {status == 1 ? (
                                            <span className="badge badge-secondary badge-green">
                                                {t("ACTIVE")}
                                            </span>
                                        ) : (
                                            <span className="badge badge-secondary badge-danger">
                                                {t("MAINTENANCE")}
                                            </span>
                                            )}
                                    </div>

                                </div>
                                {
                                    productId > 0 && <>
                                        <hr />
                                        <div className="row">
                                            <div className="col-lg-2">
                                                <label>
                                                    <b>{t("TURNOVER")}</b>
                                                </label>
                                                <br />
                                                <input
                                                    type="number"
                                                    name="turnover"
                                                    ref={register({
                                                        required: "Please enter Turnover",
                                                        min: {
                                                            value: 0,
                                                            message: "PLEASE_ENTER_VALUE_NOT_LESS_THAN_0",
                                                        },
                                                    })}
                                                    className="form-control text-right"
                                                    placeholder={t("Turnover")}
                                                    value={turnover}
                                                    onChange={(e) => {
                                                            setTurnover(e.target.value);
                                                        }
                                                    }
                                                />
                                                {errors.turnover && (
                                                    <div className="invalid-feedback">
                                                        {t(errors.turnover.message)}
                                                    </div>
                                                )}
                                            </div>
                                            <div className="col-lg-2">
                                                <label>
                                                    <b>{t("VALID_TURNOVER")}</b>
                                                </label>
                                                <br />
                                                <input
                                                    type="number"
                                                    name="validTurnover"
                                                    ref={register({
                                                        required: "Please enter Valid Turnover",
                                                        min: {
                                                            value: 0,
                                                            message: "PLEASE_ENTER_VALUE_NOT_LESS_THAN_0",
                                                        },
                                                    })}
                                                    className="form-control text-right"
                                                    placeholder={t("Valid Turnover")}
                                                    value={validTurnover}
                                                    onChange={(e) => {
                                                        setValidTurnover(e.target.value);
                                                    }
                                                    }
                                                />
                                                {errors.validTurnover && (
                                                    <div className="invalid-feedback">
                                                        {t(errors.validTurnover.message)}
                                                    </div>
                                                )}
                                            </div>
                                            <div className="col-lg-2">
                                                <label>
                                                    <b>{t("WIN_LOSS")}</b>
                                                </label>
                                                <br />
                                                <input
                                                    type="number"
                                                    name="winAmt"
                                                    ref={register({
                                                        required: "Please enter Win Loss",
                                                    })}
                                                    className="form-control text-right"
                                                    placeholder={t("Win Loss")}
                                                    value={winAmt}
                                                    onChange={(e) => {
                                                        setWinAmt(e.target.value);
                                                    }
                                                    }
                                                />
                                                {errors.winAmt && (
                                                    <div className="invalid-feedback">
                                                        {t(errors.winAmt.message)}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <hr />
                                        <div className="row">
                                            <div className="col-lg-3">
                                                <div className="form-group">
                                                    <button
                                                        type="button"
                                                        onClick={() => preSubmit()}
                                                        className="btn btn-primary"
                                                    >
                                                        {t("SUBMIT")}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                }

                            </form>
                        </PanelBody>
                    </Panel>
                </div>
            </div>
        </div>
    );
};

export default withRouter(AccountShareHolderHuatTicket);
