import { createFormBody } from "../util/Util";
import { ApiKey, ApiUrl } from "../util/Constant";
import { setBusy, setIdle, showMessage } from "./AppAction";
import { initFirebase } from '../redux/FirebaseAction';
import i18n from '../i18n';
import { fetch } from 'whatwg-fetch';

export const _LOGIN_SUCCESS = 'LoginSuccess';
export const _LOGIN_FAILED = 'LoginFailed';
export const _LOGOUT = 'Logout';

/// <summary>
/// Author : -
/// expose login action as function to be called from classes
/// </summary>
export function performLogin(username, password, isUser = true, companyCode) {
    return async dispatch => {
        dispatch({
            type: _LOGOUT
        });

        let params = {
            "username": username,
            "password": password,
            "isUser": isUser,
            "companyCode": companyCode
        };

        let formBody = createFormBody(params);
        dispatch(setBusy());
        const response = await fetch(ApiUrl._API_LOGIN, {
            method: ApiKey._API_POST,
            headers: {
                'Content-Type': ApiKey._API_FORM_URLENCODED,
                "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY
            },
            body: formBody
        });

        const responseJson = await response.json();

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            var userData = responseJson[ApiKey._API_DATA_KEY];
            dispatch({
                type: _LOGIN_SUCCESS,
                userData: userData,
            });

            dispatch(initFirebase());
        }
        else {
            dispatch({
                type: _LOGIN_FAILED,
                message: responseJson[ApiKey._API_MESSAGE_KEY],
            });
            dispatch(showMessage(responseJson[ApiKey._API_SUCCESS_KEY], i18n.t(responseJson[ApiKey._API_MESSAGE_KEY])));
        }
        dispatch(setIdle());
    }
}

/// <summary>
/// Author : -
/// this function is to only update user status to login if it is already considered authenticated in cookie
/// </summary>
export const updateLoginUser = (userData) => {
    return async dispatch => {
        dispatch(initFirebase());
        dispatch({
            type: _LOGIN_SUCCESS,
            userData: userData
        });
    }
}

/// <summary>
/// Author : -
/// expose logout action as function to be called from classes
/// </summary>
export const performLogout = () => {
    return async dispatch => {
        const response = await fetch(ApiUrl._API_LOGOUT, {
            method: ApiKey._API_POST,
            headers: {
                'Content-Type': ApiKey._API_FORM_URLENCODED,
                "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY
            }
        });

        await response.json();

        dispatch({
            type: _LOGOUT
        });
    }
}

/// <summary>
/// Author : -
/// this function is to only update user status to logout
/// </summary>
export const resetLogin = () => {
    return async dispatch => {
        dispatch({
            type: _LOGOUT
        });
    }
}