import React, { useState, useEffect } from 'react';
import ReactTable from "../../components/constantComponent/reactTable/ReactTable";
import { Panel, PanelBody } from "./../../components/panel/panel.jsx";
import moment from "moment";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { useTranslation } from "react-i18next";
import { useDispatch } from 'react-redux';
import { setBusy, setIdle, showMessage } from '../../redux/AppAction';
import { ApiKey, ApiUrl, LanguageKey } from '../../util/Constant';
import Select from "react-select";
import { numberWithCurrencyFormat } from '../../util/Util';

const ForfeitedRewardsReport = () => {
  const _dispatch = useDispatch();
  const { t, i18n } = useTranslation(LanguageKey._PRIMARY);
  const [startDate, setStartDate] = useState(moment().add(-7, 'days').format("YYYY-MM-DD"));
  const [endDate, setEndDate] = useState(moment().add(-7, 'days').format("YYYY-MM-DD"));
  const [isClaimed, setIsClaimed] = useState(true);
  const [rewardsDataUrl, setRewardsDataUrl] = useState(ApiUrl._API_GET_CLAIMED_UNCLAIMED_REWARDS_REPORT + "?startDate=" + startDate + "&endDate=" + endDate + "&claimed=" + isClaimed);

  useEffect(() => {
    getRewardsReport();
  }, []);

  const _OPTION_STYLES = {
    control: (base) => ({
      ...base,
      "min-height": "34px",
      height: "34px",
    }),
    valueContainer: (base) => ({
      ...base,
      height: "34px",
      "min-height": "34px",
    }),
    indicatorsContainer: (base) => ({
      ...base,
      height: "34px",
      "min-height": "34px",
    }),
    input: (base) => ({
      height: "34px",
      "min-height": "34px",
    }),
  };

  const _tableColumns = [
    {
      Header: t("ID"),
      accessor: "id",
    },
    {
      Header: t("MEMBER_USERNAME"),
      accessor: "memberUsername",
    },
    {
      Header: t("PRODUCT_NAME"),
      accessor: "productName",
    },
    {
      Header: t("CATEGORY_NAME"),
      accessor: "categoryTitle",
    },
    {
      Header: t("AMOUNT"),
      accessor: "amount",
      Cell: ({ row }) => {
        return <span>{numberWithCurrencyFormat(row.original.amount, 2, true)}</span>
      }
    },
    {
      Header: t("COMMISSION"),
      accessor: "commission",
      Cell: ({ row }) => {
        return <span>{numberWithCurrencyFormat(row.original.commission, 2, true)}</span>
      }
    },
    {
      Header: t("VALID_TURNOVER"),
      accessor: "validTurnover",
      Cell: ({ row }) => {
        return <span>{numberWithCurrencyFormat(row.original.validTurnover, 2, true)}</span>
      }
    },
  ];

  const getRewardsReport = async () => {
    _dispatch(setBusy());
    let apiUrl = ApiUrl._API_GET_CLAIMED_UNCLAIMED_REWARDS_REPORT + "?startDate=" + startDate + "&endDate=" + endDate + "&claimed=" + isClaimed;
    setRewardsDataUrl(apiUrl);
    _dispatch(setIdle());
  }

  /// <summary>
  /// Author : -
  /// </summary>
  const handleEvent = (event, picker) => {
    setStartDate(moment(picker.startDate).format("YYYY-MM-DD"));
    setEndDate(moment(picker.endDate).format("YYYY-MM-DD"));
  };

  return (
    <div>
      <h1 className="page-header">{t("FORFEITED_REWARDS_REPORT")}</h1>
      <Panel>
        <PanelBody>
          <div className="row">
            <div className="col-lg-12">
              <div className="row" style={{ alignItems: "center" }}>
                <div className="col-lg-4">
                  <div className="form-group">
                    <label>
                      <b>{t("DATE")}</b>
                    </label>
                    <div
                      className="input-group"
                      style={{ width: "100%", flexFlow: "nowrap" }}
                    >
                      <div className="input-group-prepend">
                        <span className="input-group-text">
                          <i className="fa fa-calendar"></i>
                        </span>
                      </div>
                      <div style={{ width: "80%" }}>
                        <DateRangePicker
                          containerStyles={{ width: "100%" }}
                          startDate={startDate}
                          endDate={endDate}
                          maxDate={moment().add(-7, 'days')}
                          minDate={moment().add(-14, 'days')}
                          onApply={handleEvent}
                          alwaysShowCalendars={true}
                          locale={{ format: "YYYY-MM-DD HH:mm:ss" }}
                          timePicker={true}
                          timePickerSeconds={true}
                          ranges={{
                            Today: [moment().startOf("day"), moment().endOf("day")],
                            Yesterday: [
                              moment().subtract(1, "days").startOf("day"),
                              moment().subtract(1, "days").endOf("day"),
                            ],
                            "Last 7 Days": [
                              moment().subtract(6, "days").startOf("day"),
                              moment().endOf("day"),
                            ],
                            "Last 30 Days": [
                              moment().subtract(29, "days").startOf("day"),
                              moment().endOf("day"),
                            ],
                            "This Month": [
                              moment().startOf("month"),
                              moment().endOf("month"),
                            ],
                            "Last Month": [
                              moment().subtract(1, "month").startOf("month"),
                              moment().subtract(1, "month").endOf("month"),
                            ],
                            "This Year": [
                              moment().startOf("year"),
                              moment().endOf("year"),
                            ],
                          }}
                        >
                          <input
                            type="text"
                            className="form-control"
                            value={startDate + " - " + endDate}
                          />
                        </DateRangePicker>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3">
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => getRewardsReport()}
                  >
                    {t("SEARCH")}
                  </button>
                </div>
              </div>
              <hr />
              <ReactTable fetchUrl={rewardsDataUrl} columns={_tableColumns} />
            </div>
          </div>
        </PanelBody>
      </Panel>
    </div>
  )
}

export default ForfeitedRewardsReport;