import React, { useState, useEffect } from "react";
import ReactTable from "../../components/constantComponent/reactTable/ReactTable";
import { Panel, PanelBody } from "./../../components/panel/panel.jsx";
import { useHistory } from "react-router-dom";
import {
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { navigateTo } from "../../util/Util";
import { ApiUrl, ApiKey, WebUrl, LanguageKey } from "../../util/Constant";
import { stringIsNullOrEmpty } from "../../util/Util";
import NavigationButton from "../../components/constantComponent/button/NavigationButton";
import { useTranslation } from "react-i18next";
import { fetch } from "whatwg-fetch";

/// <summary>
/// Author : -
/// </summary>
const ManageContactUs = (props) => {
  const { t } = useTranslation(LanguageKey._PRIMARY);
  let _history = useHistory();
  const [contactUsData, setContactUsData] = useState([]);

  let _tableColumns = [
    {
      Header: "IMAGE",
      accessor: "image",
      Cell: ({ row }) => (
        <div style={{ textAlign: "center" }}>
          {!stringIsNullOrEmpty(row.original.image) ? (
            <img
              style={{ width: "40px", height: "40px" }}
              src={row.original.image}
            />
          ) : (
            <span> - </span>
          )}
        </div>
      ),
      disableSortBy: true,
    },
    {
      Header: "TEXT",
      accessor: "text",
    },
    {
      Header: "STATUS",
      accessor: "status",
      Cell: ({ row }) => (
        <div className="btn-group m-r-5 m-b-5">
          {row.original.status == 1 ? (
            <span className="badge badge-secondary badge-green">
              {t("ACTIVE")}
            </span>
          ) : (
            <span className="badge badge-secondary badge-danger">
              {t("SUSPENDED")}
            </span>
          )}
        </div>
      ),
      disableSortBy: true,
      style: { overflow: "visible" },
    },
    {
      id: "action",
      Header: "",
      Cell: ({ row }) => (
        <div className="btn-group m-r-5 m-b-5">
          <UncontrolledButtonDropdown>
            <DropdownToggle caret color="default">
              <i className="fas fa-cog"></i>
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem
                onClick={() => {
                  navigateTo(
                    _history,
                    WebUrl._URL_CREATE_OR_UPDATE_CONTACT_US,
                    row.original
                  );
                }}
              >
                <span className="text-warning">{t("EDIT")}</span>
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledButtonDropdown>
        </div>
      ),
      disableSortBy: true,
      disableFilters: true,
      width: 100,
      style: { overflow: "visible" },
    },
  ];

  /// <summary>
  /// Author : -
  /// </summary>
  useEffect(() => {
    fetch(ApiUrl._API_GET_COMPANY_CONTACT_US + "?all=true", {
      method: ApiKey._API_GET,
      headers: {
        "Content-Type": ApiKey._API_FORM_URLENCODED,
        "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
          setContactUsData(responseJson[ApiKey._API_DATA_KEY]);
        }
      });
  }, []);

  return (
    <div>
      <h1 className="page-header">
        {t("CONTACT_US")}
        <NavigationButton
          history={_history}
          url={WebUrl._URL_CREATE_OR_UPDATE_CONTACT_US}
          buttonText={t("ADD_CONTACT_US")}
        />
      </h1>
      <Panel>
        <PanelBody>
          <ReactTable data={contactUsData} columns={_tableColumns} />
        </PanelBody>
      </Panel>
    </div>
  );
};

export default ManageContactUs;
