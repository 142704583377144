import React, { useState } from "react";
import ReactTable from "../../components/constantComponent/reactTable/ReactTable";
import { Panel, PanelBody } from "./../../components/panel/panel.jsx";
import moment from "moment";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { ApiKey, ApiUrl, LanguageKey } from "../../util/Constant";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { fetch } from "whatwg-fetch";
import { setBusy, setIdle, showMessage } from "../../redux/AppAction";
import { useDispatch } from "react-redux";
import { numberWithCurrencyFormat } from "../../util/Util";

/// <summary>
/// Author : -
/// </summary>

const ManageMemberReport = (props) => {
	const _dispatch = useDispatch();
	const { t } = useTranslation(LanguageKey._PRIMARY);
	const [startDate, setStartDate] = useState(
		moment().format("YYYY-MM-DD")
	);
	const [endDate, setEndDate] = useState(
		moment().format("YYYY-MM-DD")
	);
	const [memberReportDatas, setMemberReportDatas] = useState([]);

	/// <summary>
	/// Author : -
	/// </summary>
	useEffect(() => {
		getMemberReport();
	}, []);

	/// <summary>
	/// Author : -
	/// </summary>
	const getMemberReport = async () => {
		_dispatch(setBusy());
		await fetch(
			ApiUrl._API_GET_MEMBER_REPORT +
				"?startDate=" +
				startDate +
				"&endDate=" +
				endDate,
			{
				method: ApiKey._API_GET,
				headers: {
					"Content-Type": ApiKey._API_FORM_URLENCODED,
					"X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
				},
			}
		)
			.then((response) => response.json())
			.then((responseJson) => {
				if (responseJson[ApiKey._API_SUCCESS_KEY]) {
					setMemberReportDatas(responseJson[ApiKey._API_DATA_KEY]);
				} else {
					_dispatch(
						showMessage(
							responseJson[ApiKey._API_SUCCESS_KEY],
							t(responseJson[ApiKey._API_MESSAGE_KEY])
						)
					);
				}
				_dispatch(setIdle());
			});
	};

	let _tableColumns = [
		{
			Header: t("USERNAME"),
			accessor: "username",
			Cell: ({ row }) => {
				return <>{t(row.original.username)}</>;
			},
			minWidth: 100,
			Footer: () => {
				return (
					<span>
						<b>{t("TOTAL")}: </b>
					</span>
				);
			},
		},
		{
			Header: t("FULLNAME"),
			accessor: "fullName",
			Cell: ({ row }) => {
				return <>{t(row.original.fullName)}</>;
			},
			minWidth: 100,
		},
		{
			Header: "STATUS",
			accessor: "status",
			Cell: ({ row }) => {
				return (
					<span>
						{row.original.status == 1 ? (
							<span className="badge badge-secondary badge-green">
								{t("ACTIVE")}
							</span>
						) : (
							<span className="badge badge-secondary badge-danger">
								{t("SUSPENDED")}
							</span>
						)}
					</span>
				);
			},
			minWidth: 100,
		},
		{
			Header: t("LAST_LOGIN"),
			accessor: "lastLoginDate",
			Cell: ({ row }) => {
				return (
					<>
						{moment(row.original.lastLoginDate).format("YYYY-MM-DD HH:mm:ss")}
					</>
				);
			},
			minWidth: 100,
		},
		{
			Header: t("TOTAL_DEPOSIT"),
			accessor: "deposit",
			Cell: ({ row }) => {
				return (
					<>{numberWithCurrencyFormat(parseFloat(row.original.deposit))}</>
				);
			},
			minWidth: 100,
			Footer: ({ page }) => {
				const debitSum = page.reduce((sum, currentValue) => {
					if (currentValue.original.deposit) {
						return sum + parseFloat(currentValue.original.deposit);
					} else {
						return sum;
					}
				}, 0);
				return (
					<span>
						<b>{numberWithCurrencyFormat(parseFloat(debitSum))}</b>
					</span>
				);
			},
		},
		{
			Header: t("TOTAL_WITHDRAWAL"),
			accessor: "withdrawal",
			Cell: ({ row }) => {
				return (
					<>{numberWithCurrencyFormat(parseFloat(row.original.withdrawal))}</>
				);
			},
			minWidth: 100,
			Footer: ({ page }) => {
				const debitSum = page.reduce((sum, currentValue) => {
					if (currentValue.original.withdrawal) {
						return sum + parseFloat(currentValue.original.withdrawal);
					} else {
						return sum;
					}
				}, 0);
				return (
					<span>
						<b>{numberWithCurrencyFormat(parseFloat(debitSum))}</b>
					</span>
				);
			},
		},
		{
			Header: t("TOTAL_BONUSES"),
			accessor: "bonuses",
			Cell: ({ row }) => {
				return (
					<>{numberWithCurrencyFormat(parseFloat(row.original.bonuses))}</>
				);
			},
			minWidth: 100,
			Footer: ({ page }) => {
				const debitSum = page.reduce((sum, currentValue) => {
					if (currentValue.original.bonuses) {
						return sum + parseFloat(currentValue.original.bonuses);
					} else {
						return sum;
					}
				}, 0);
				return (
					<span>
						<b>{numberWithCurrencyFormat(parseFloat(debitSum))}</b>
					</span>
				);
			},
		},
		{
			Header: t("CLAIMED_COMMISSION"),
			accessor: "claimedCommission",
			Cell: ({ row }) => {
				return (
					<>
						{numberWithCurrencyFormat(
							parseFloat(row.original.claimedCommission),
							3
						)}
					</>
				);
			},
			minWidth: 100,
			Footer: ({ page }) => {
				const debitSum = page.reduce((sum, currentValue) => {
					if (currentValue.original.ClaimedCommission) {
						return sum + parseFloat(currentValue.original.claimedCommission);
					} else {
						return sum;
					}
				}, 0);
				return (
					<span>
						<b>{numberWithCurrencyFormat(parseFloat(debitSum), 3)}</b>
					</span>
				);
			},
		},
		{
			Header: t("UNCLAIMED_COMMISSION"),
			accessor: "unclaimedCommission",
			Cell: ({ row }) => {
				return (
					<>
						{numberWithCurrencyFormat(
							parseFloat(row.original.unclaimedCommission),
							3
						)}
					</>
				);
			},
			minWidth: 100,
			Footer: ({ page }) => {
				const debitSum = page.reduce((sum, currentValue) => {
					if (currentValue.original.unclaimedCommission) {
						return sum + parseFloat(currentValue.original.unclaimedCommission);
					} else {
						return sum;
					}
				}, 0);
				return (
					<span>
						<b>{numberWithCurrencyFormat(parseFloat(debitSum), 3)}</b>
					</span>
				);
			},
		},
		{
			Header: t("TOTAL_VALID_TURNOVER"),
			accessor: "validTurnover",
			Cell: ({ row }) => {
				return (
					<>
						{numberWithCurrencyFormat(parseFloat(row.original.validTurnover))}
					</>
				);
			},
			minWidth: 100,
			Footer: ({ page }) => {
				const debitSum = page.reduce((sum, currentValue) => {
					if (currentValue.original.validTurnover) {
						return sum + parseFloat(currentValue.original.validTurnover);
					} else {
						return sum;
					}
				}, 0);
				return (
					<span>
						<b>{numberWithCurrencyFormat(parseFloat(debitSum))}</b>
					</span>
				);
			},
		},
		{
			Header: t("TOTAL_WIN_AMOUNT"),
			accessor: "winAmount",
			Cell: ({ row }) => {
				return (
					<>{numberWithCurrencyFormat(parseFloat(row.original.winAmount))}</>
				);
			},
			minWidth: 100,
			Footer: ({ page }) => {
				const debitSum = page.reduce((sum, currentValue) => {
					if (currentValue.original.winAmount) {
						return sum + parseFloat(currentValue.original.winAmount);
					} else {
						return sum;
					}
				}, 0);
				return (
					<span>
						<b>{numberWithCurrencyFormat(parseFloat(debitSum))}</b>
					</span>
				);
			},
		},
		{
			Header: t("TOTAL_LOSS"),
			accessor: "totalLoss",
			Cell: ({ row }) => {
				return (
					<>{numberWithCurrencyFormat(parseFloat(row.original.totalLoss))}</>
				);
			},
			minWidth: 100,
			Footer: ({ page }) => {
				const debitSum = page.reduce((sum, currentValue) => {
					if (currentValue.original.totalLoss) {
						return sum + parseFloat(currentValue.original.totalLoss);
					} else {
						return sum;
					}
				}, 0);
				return (
					<span>
						<b>{numberWithCurrencyFormat(parseFloat(debitSum))}</b>
					</span>
				);
			},
		},
		{
			Header: t("TOTAL_WINLOSE"),
			accessor: "totalWinLose",
			Cell: ({ row }) => {
				return (
					<>{numberWithCurrencyFormat(parseFloat(row.original.totalWinLose))}</>
				);
			},
			minWidth: 100,
			Footer: ({ page }) => {
				const debitSum = page.reduce((sum, currentValue) => {
					if (currentValue.original.totalWinLose) {
						return sum + parseFloat(currentValue.original.totalWinLose);
					} else {
						return sum;
					}
				}, 0);
				return (
					<span>
						<b>{numberWithCurrencyFormat(parseFloat(debitSum))}</b>
					</span>
				);
			},
		},
	];

	/// <summary>
	/// Author : -
	/// </summary>
	const handleEvent = (event, picker) => {
		setStartDate(moment(picker.startDate).format("YYYY-MM-DD"));
		setEndDate(moment(picker.endDate).format("YYYY-MM-DD"));
	};

	return (
		<div>
			<h1 className="page-header">{t("MEMBER_REPORT")}</h1>
			<Panel>
				<PanelBody>
					<div className="row">
						<div className="col-lg-12">
							<div className="row">
								<div className="col-lg-4">
									<div
										className="form-group"
										style={{ display: "flex", width: "100%" }}
									>
										<div className="input-group" style={{ width: "100%" }}>
											<div className="input-group-prepend">
												<span className="input-group-text">
													<i className="fa fa-calendar"></i>
												</span>
											</div>
											<div style={{ width: "80%" }}>
												<DateRangePicker
													containerStyles={{ width: "100%" }}
													startDate={startDate}
													endDate={endDate}
													onApply={handleEvent}
													alwaysShowCalendars={true}
													locale={{ format: "YYYY-MM-DD" }}
													ranges={{
														Today: [moment(), moment()],
														Yesterday: [
															moment().subtract(1, "days"),
															moment().subtract(1, "days"),
														],
														"Last 7 Days": [
															moment().subtract(6, "days"),
															moment(),
														],
														"Last 30 Days": [
															moment().subtract(29, "days"),
															moment(),
														],
														"This Month": [
															moment().startOf("month"),
															moment().endOf("month"),
														],
														"Last Month": [
															moment().subtract(1, "month").startOf("month"),
															moment().subtract(1, "month").endOf("month"),
														],
														"This Year": [
															moment().startOf("year"),
															moment().endOf("year"),
														],
													}}
												>
													<input
														type="text"
														className="form-control"
														value={startDate + " - " + endDate}
													/>
												</DateRangePicker>
											</div>
										</div>
									</div>
								</div>
								<div className="col-lg-3">
									<button
										type="button"
										className="btn btn-primary"
										onClick={() => getMemberReport()}
									>
										{t("SEARCH")}
									</button>
								</div>
							</div>
							<hr />
							<ReactTable data={memberReportDatas} columns={_tableColumns} />
						</div>
					</div>
				</PanelBody>
			</Panel>
		</div>
	);
};

export default ManageMemberReport;
