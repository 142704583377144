import React, { useState } from "react";
import { Panel, PanelBody } from "./../../components/panel/panel.jsx";
import { useHistory } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";

/// <summary>
/// Author : -
/// </summary>

const ManageBetSetting = (props) => {
  let history = useHistory();
  const [vipLevel, setVipLevel] = useState("vip0");
  const [game, setGame] = useState("allBet");
  const [search, setSearch] = useState(false);

  const vipLevelOptions = [
    { value: "vip0", label: "VIP 0" },
    { value: "vip1", label: "VIP 1" },
    { value: "vip2", label: "VIP 2" },
  ];
  const gameOptions = [
    { value: "allBet", label: "[LIVECASINO] AllBet" },
    { value: "playTech", label: "[LIVECASINO] PlayTech" },
    { value: "sSport", label: "[SPORT] S-Sport" },
    { value: "iSport", label: "[SPORT] I-Sport" },
    { value: "sexyBaccarat", label: "[LIVECASINO] Sexy Baccarat" },
    { value: "agGaming", label: "[LIVECASINO] AG Gaming" },
    { value: "dreamGamingAsiawin", label: "[LIVECASINO] Dream Gaming Asiawin" },
  ];

  const vipChangeHandler = (e) => {
    try {
      setVipLevel(e.value);
    } catch (ex) {
      console.log(ex.message);
    }
  };

  const gameChangeHandler = (e) => {
    try {
      setGame(e.value);
    } catch (ex) {
      console.log(ex.message);
    }
  };

  return (
    <div>
      <h1 className="page-header">Default Bet Setting</h1>
      <Panel>
        <PanelBody>
          <div className="row">
            <div className="col-lg-3">
              <Select
                placeholder="Please Select VIP Level"
                options={vipLevelOptions}
                value={vipLevelOptions.filter(
                  (option) => option.value === vipLevel
                )}
                onChange={vipChangeHandler}
              />
            </div>
            <div className="col-lg-3">
              <Select
                placeholder="Please Select Game"
                value={gameOptions.filter((option) => option.value === game)}
                onChange={gameChangeHandler}
                options={gameOptions}
              />
            </div>
            <div className="col-lg-3">
              <button
                type="button"
                style={{ marginLeft: "5px" }}
                className="btn btn-primary"
                onClick={() => setSearch(true)}
              >
                Search
              </button>
            </div>
          </div>
          <hr />
          {search && (
            <>
              <div className="row">
                <div className="col-lg-12">
                  <b>Ordinary Handicaps</b>
                  <p>Please select minimum of 1 and maximum of 2 limit.</p>
                  <table className="table table-hover">
                    <thead className="thead-dark">
                      <tr>
                        <th scope="col"></th>
                        <th scope="col">Name</th>
                        <th scope="col">Minimum Bet</th>
                        <th scope="col">Maximum Bet</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="gridCheck1"
                            />
                            <label
                              className="form-check-label"
                              for="gridCheck1"
                            ></label>
                          </div>
                        </td>
                        <td>A</td>
                        <td>20</td>
                        <td>10,000</td>
                      </tr>
                      <tr>
                        <td>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="gridCheck1"
                            />
                            <label
                              className="form-check-label"
                              for="gridCheck1"
                            ></label>
                          </div>
                        </td>
                        <td>B</td>
                        <td>50</td>
                        <td>5,000</td>
                      </tr>
                      <tr>
                        <td>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="gridCheck1"
                            />
                            <label
                              className="form-check-label"
                              for="gridCheck1"
                            ></label>
                          </div>
                        </td>
                        <td>C</td>
                        <td>100</td>
                        <td>10,000</td>
                      </tr>
                      <tr>
                        <td>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="gridCheck1"
                            />
                            <label
                              className="form-check-label"
                              for="gridCheck1"
                            ></label>
                          </div>
                        </td>
                        <td>D</td>
                        <td>200</td>
                        <td>20,000</td>
                      </tr>
                      <tr>
                        <td>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="gridCheck1"
                            />
                            <label
                              className="form-check-label"
                              for="gridCheck1"
                            ></label>
                          </div>
                        </td>
                        <td>E</td>
                        <td>300</td>
                        <td>30,000</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <hr />
              <div className="row">
                <div className="col-lg-3">
                  <button type="button" className="btn btn-primary">
                    Submit
                  </button>
                </div>
              </div>
            </>
          )}
        </PanelBody>
      </Panel>
    </div>
  );
};

export default ManageBetSetting;
