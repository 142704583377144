import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { Panel, PanelBody } from "./../../components/panel/panel.jsx";
import { useHistory } from "react-router-dom";
import Dropzone from "react-dropzone";
import NavigationButton from "../../components/constantComponent/button/NavigationButton";
import { ApiKey, ApiUrl, LanguageKey } from "../../util/Constant";
import { stringIsNullOrEmpty, createFormBody } from "../../util/Util";
import { useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { setBusy, setIdle, showMessage } from "../../redux/AppAction";
import { useTranslation } from "react-i18next";
import { fetch } from "whatwg-fetch";

/// <summary>
/// Author : -
/// </summary>

const CompanyProductApiInfo = (props) => {
  const { t } = useTranslation(LanguageKey._PRIMARY);
  const META_COUNT = 8;
  let _history = useHistory();
  const _location = useLocation();
  const _dispatch = useDispatch();
  const { register, handleSubmit, errors } = useForm();

  const [productId, setProductId] = useState("");
  const [productName, setProductName] = useState("");
  const [productCode, setProductCode] = useState("");
  const [backUrl, setBackurl] = useState("");
  const [reportUrl, setReportUrl] = useState("");
  const [clientCode, setClientCode] = useState("");
  const [clientSecret, setClientSecret] = useState("");

  const [logoImage, setLogoImage] = useState();
  const [apiKeyOption, setApiKeyOption] = useState([]);

  const [apiData, setApiData] = useState([]);

  /// <summary>
  /// Author : -
  /// </summary>
  useEffect(() => {
    setProductId(_location.state["product"]["productId"]);
    setProductName(_location.state["product"]["productName"]);
    setProductCode(_location.state["product"]["productCode"]);
    setClientCode(_location.state["product"]["clientCode"]);
    setClientSecret(_location.state["product"]["clientSecret"]);
    setBackurl(_location.state["product"]["backUrl"]);
    setReportUrl(_location.state["product"]["reportUrl"]);

    if (!stringIsNullOrEmpty(_location.state["product"]["productImage"])) {
      setLogoImage(_location.state["product"]["productImage"]);
    }
    updateApiMeta();
  }, []);

  /// <summary>
  /// Author : -
  /// </summary>
  async function updateApiMeta() {
    await fetch(
      ApiUrl._API_GET_ACCOUNT_PRODUCT_META +
        "?accountProductId=" +
        _location.state["id"],
      {
        method: ApiKey._API_GET,
        headers: {
          "Content-Type": ApiKey._API_FORM_URLENCODED,
          "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
        },
      }
    )
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
          var newData = [];
          for (var i = 0; i < META_COUNT; i++) {
            if (responseJson[ApiKey._API_DATA_KEY].length > i) {
              newData[i] = {
                name: "Parameter " + (i + 1),
                key: responseJson[ApiKey._API_DATA_KEY][i]["metaData"],
                value: responseJson[ApiKey._API_DATA_KEY][i]["metaTypeId"],
              };
            } else {
              newData[i] = {
                name: "Parameter " + (i + 1),
                key: "",
                value: "",
              };
            }
          }

          //this is done intentionally to force refresh on select
          setApiData([]);
          setApiData(newData);
        }
      });

    await fetch(ApiUrl._API_GET_API_KEY_DATA, {
      method: ApiKey._API_GET,
      headers: {
        "Content-Type": ApiKey._API_FORM_URLENCODED,
        "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
          setApiKeyOption(responseJson[ApiKey._API_DATA_KEY]);
        }
      });
  }

  /// <summary>
  /// Author : -
  /// </summary>
  const submitForm = async (data, e) => {
    let params = {
      accountProductId: _location.state["id"],
    };

    Object.keys(data).map((key, value) => {
      params[key] = data[key];
    });

    let formBody = createFormBody(params);
    _dispatch(setBusy());

    fetch(ApiUrl._API_UPDATE_ACCOUNT_PRODUCT_META, {
      method: ApiKey._API_POST,
      headers: {
        "Content-Type": ApiKey._API_FORM_URLENCODED,
        "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
      },
      body: formBody,
    })
      .then((response) => response.json())
      .then((responseJson) => {
        _dispatch(setIdle());

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
          e.target.reset();
          updateApiMeta();
        }

        _dispatch(
          showMessage(
            responseJson[ApiKey._API_SUCCESS_KEY],
            t(responseJson[ApiKey._API_MESSAGE_KEY])
          )
        );
      });
  };

  return (
    <div>
      <h1 className="page-header">
        {productName ? t("EDIT_PRODUCT_API_INFO") : t("ADD_PRODUCT_API_INFO")}
        <NavigationButton history={_history} />
      </h1>
      <div className="row">
        <div className="col-xl-12">
          <Panel>
            <PanelBody>
              <form onSubmit={handleSubmit(submitForm)}>
                <h3>{t("PRODUCT_INFO")}</h3>
                <div className="row">
                  <div className="col-lg-3">
                    <div className="form-group">
                      <label>
                        <b>{t("LOGO")}</b>
                      </label>
                      <Dropzone accept={"image/*"} disabled={true}>
                        {({ getRootProps, getInputProps }) => (
                          <section>
                            <div
                              className="dropzone"
                              style={{
                                minHeight: "200px",
                                textAlign: "center",
                              }}
                              {...getRootProps()}
                            >
                              <input {...getInputProps()} />
                              <h4 style={{ color: "grey" }}>
                                {t("READONLY_LOGO")}
                              </h4>
                              {!stringIsNullOrEmpty(logoImage) && (
                                <aside className="thumbsContainer">
                                  <div className="thumb">
                                    <div className="thumbInner">
                                      <img
                                        src={logoImage}
                                        className="dropzone-img"
                                      />
                                    </div>
                                  </div>
                                </aside>
                              )}
                            </div>
                          </section>
                        )}
                      </Dropzone>
                    </div>
                  </div>
                  <div className="col-lg-9">
                    <div className="row">
                      <div className="col-lg-4">
                        <div className="form-group">
                          <label>
                            <b>{t("PRODUCT_ID")}</b>
                          </label>
                          <input
                            type="text"
                            className="form-control m-b-5"
                            placeholder={t("PLEASE_ENTER_PRODUCT_ID")}
                            defaultValue={productId}
                            readOnly
                          />
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="form-group">
                          <label>
                            <b>{t("PRODUCT_NAME")}</b>
                          </label>
                          <input
                            type="text"
                            className="form-control m-b-5"
                            placeholder={t("PLEASE_ENTER_PRODUCT_NAME")}
                            defaultValue={productName}
                            readOnly
                          />
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="form-group">
                          <label>
                            <b>{t("SHORT_CODE")}</b>
                          </label>
                          <input
                            type="text"
                            className="form-control m-b-5"
                            defaultValue={productCode}
                            readOnly
                          />
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="form-group">
                          <label>
                            <b>{t("BACKOFFICE_URL")}</b>
                          </label>
                          <input
                            type="text"
                            className="form-control m-b-5"
                            defaultValue={backUrl}
                            readOnly
                          />
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="form-group">
                          <label>
                            <b>{t("BACKOFFICEREPORT_URL")}</b>
                          </label>
                          <input
                            type="text"
                            className="form-control m-b-5"
                            defaultValue={reportUrl}
                            readOnly
                          />
                        </div>
                      </div>

                      <div className="col-lg-4">
                        <div className="form-group">
                          <label>
                            <b>{t("CLIENT_CODE")}</b>
                          </label>
                          <input
                            type="text"
                            className="form-control m-b-5"
                            defaultValue={clientCode}
                            readOnly
                          />
                        </div>
                      </div>

                      <div className="col-lg-4">
                        <div className="form-group">
                          <label>
                            <b>{t("CLIENT_SECRET")}</b>
                          </label>
                          <input
                            type="text"
                            className="form-control m-b-5"
                            defaultValue={clientSecret}
                            readOnly
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <hr />
                <h3>{t("API_INFORMATION")}</h3>
                <div className="row">
                  <div className="col-lg-12">
                    <table className="table table-hover">
                      <thead>
                        <tr>
                          <th>{t("PARAMETER")}</th>
                          <th>{t("DATA")}</th>
                          <th>
                            {t("METADATA")}/{t("REMARK")}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {Object.keys(apiData).map((key, value) => {
                          return (
                            <tr key={key}>
                              <td>{apiData[key].name}</td>
                              <td>
                                <input
                                  type="text"
                                  defaultValue={apiData[key]["key"]}
                                  className="form-control m-b-5"
                                  name={"metaKey" + key}
                                  ref={register}
                                />
                              </td>
                              <td>
                                <select
                                  className="form-control"
                                  name={"metaData" + key}
                                  ref={register}
                                >
                                  {Object.keys(apiKeyOption).map(
                                    (innerKey, innerValue) => {
                                      return (
                                        <option
                                          value={apiKeyOption[innerKey]["id"]}
                                          selected={
                                            apiData[key]["value"] ==
                                            apiKeyOption[innerKey]["id"]
                                          }
                                        >
                                          {apiKeyOption[innerKey]["name"]}
                                        </option>
                                      );
                                    }
                                  )}
                                </select>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
                <hr />
                <div className="row">
                  <div className="col-lg-3">
                    <div className="form-group">
                      <button type="submit" className="btn btn-primary">
                        {t("SUBMIT")}
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </PanelBody>
          </Panel>
        </div>
      </div>
    </div>
  );
};

export default withRouter(CompanyProductApiInfo);
