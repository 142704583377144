import React from "react";
import { Panel, PanelBody } from "./../../components/panel/panel.jsx";
import { createFormBody } from "../../util/Util";
import {
  ApiUrl,
  ApiKey,
  LanguageKey,
} from "../../util/Constant";
import { setBusy, setIdle, showMessage } from "../../redux/AppAction";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { fetch } from "whatwg-fetch";

/// <summary>
/// Author : -
/// </summary>

const ManageRecurringJob = (props) => {
  const { t } = useTranslation(LanguageKey._PRIMARY);
  const _dispatch = useDispatch();

  const StartRecurringJob = (jobName) => {
    _dispatch(setBusy);
    let apiUrl = ApiUrl._API_EXECUTE_RECURRING_JOB;
    let params = {
      "jobName": jobName
    };
    let formBody = createFormBody(params);
    fetch(apiUrl, {
      method: ApiKey._API_POST,
      headers: {
        "Content-Type": ApiKey._API_FORM_URLENCODED,
        "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
      },
      body: formBody
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
          _dispatch(
            showMessage(
              responseJson[ApiKey._API_SUCCESS_KEY],
              t("OPERATION_SUCCESS")
            )
          )
        }
      });
    _dispatch(setIdle);
  }
  return (
    <div>
      <h1 className="page-header">
        {t("RECURRING_JOBS")}
      </h1>
      <Panel>
        <PanelBody>
          <table style={{ width: "100%", border: "1px solid #d6dadd" }}>
            <tr style={{ borderBottom: "1px solid #a7aeb5" }}>
              <th style={{ borderRight: "1px solid #d6dadd", height: "60px", paddingLeft: "15px" }}>{t("JOB_TITLE")}</th><th>   </th>
            </tr>
            <tr>
              <td style={{ width: "80%", height: "80px", borderRight: "1px solid #d6dadd", borderBottom: "1px solid #d6dadd", paddingLeft: "15px" }}>CheckTransactionQueue      </td><td style={{ textAlign: "center", borderBottom: "1px solid #d6dadd" }}><button className="job-button" style={{ width: "50%", height: "30px", border: "none", borderRadius: "10px", backgroundColor: "#0f9e3e", color: "white" }} onClick={() => StartRecurringJob("CheckTransactionQueue")}>{t("START")}</button></td>
            </tr>
            <tr>
              <td style={{ width: "80%", height: "80px", borderRight: "1px solid #d6dadd", borderBottom: "1px solid #d6dadd", paddingLeft: "15px" }}>CheckPendingTransaction      </td><td style={{ textAlign: "center", borderBottom: "1px solid #d6dadd" }}><button className="job-button" style={{ width: "50%", height: "30px", border: "none", borderRadius: "10px", backgroundColor: "#0f9e3e", color: "white" }} onClick={() => StartRecurringJob("CheckPendingTransaction")}>{t("START")}</button></td>
            </tr>
            <tr>
              <td style={{ width: "80%", height: "80px", borderRight: "1px solid #d6dadd", borderBottom: "1px solid #d6dadd", paddingLeft: "15px" }}>VipProcessing                </td><td style={{ textAlign: "center", borderBottom: "1px solid #d6dadd" }}><button className="job-button" style={{ width: "50%", height: "30px", border: "none", borderRadius: "10px", backgroundColor: "#0f9e3e", color: "white" }} onClick={() => StartRecurringJob("VipProcessing")}>{t("START")}</button></td>
            </tr>
            <tr>
              <td style={{ width: "80%", height: "80px", borderRight: "1px solid #d6dadd", borderBottom: "1px solid #d6dadd", paddingLeft: "15px" }}>GetJackpotWinner             </td><td style={{ textAlign: "center", borderBottom: "1px solid #d6dadd" }}><button className="job-button" style={{ width: "50%", height: "30px", border: "none", borderRadius: "10px", backgroundColor: "#0f9e3e", color: "white" }} onClick={() => StartRecurringJob("GetJackpotWinner")}>{t("START")}</button></td>
            </tr>
            <tr>
              <td style={{ width: "80%", height: "80px", borderRight: "1px solid #d6dadd", borderBottom: "1px solid #d6dadd", paddingLeft: "15px" }}>CronJobGetWinLossReport      </td><td style={{ textAlign: "center", borderBottom: "1px solid #d6dadd" }}><button className="job-button" style={{ width: "50%", height: "30px", border: "none", borderRadius: "10px", backgroundColor: "#0f9e3e", color: "white" }} onClick={() => StartRecurringJob("CronJobGetWinLossReport")}>{t("START")}</button></td>
            </tr>
            <tr>
              <td style={{ width: "80%", height: "80px", borderRight: "1px solid #d6dadd", borderBottom: "1px solid #d6dadd", paddingLeft: "15px" }}>Process568ProductGameRecord  </td><td style={{ textAlign: "center", borderBottom: "1px solid #d6dadd" }}><button className="job-button" style={{ width: "50%", height: "30px", border: "none", borderRadius: "10px", backgroundColor: "#0f9e3e", color: "white" }} onClick={() => StartRecurringJob("Process568ProductGameRecord")}>{t("START")}</button></td>
            </tr>
            <tr>
              <td style={{ width: "80%", height: "80px", borderRight: "1px solid #d6dadd", borderBottom: "1px solid #d6dadd", paddingLeft: "15px" }}>Process568SportsProductGameRecord  </td><td style={{ textAlign: "center", borderBottom: "1px solid #d6dadd" }}><button className="job-button" style={{ width: "50%", height: "30px", border: "none", borderRadius: "10px", backgroundColor: "#0f9e3e", color: "white" }} onClick={() => StartRecurringJob("Process568SportsProductGameRecord")}>{t("START")}</button></td>
            </tr>
            <tr>
              <td style={{ width: "80%", height: "80px", borderRight: "1px solid #d6dadd", borderBottom: "1px solid #d6dadd", paddingLeft: "15px" }}>ProcessPTCProductGameRecord  </td><td style={{ textAlign: "center", borderBottom: "1px solid #d6dadd" }}><button className="job-button" style={{ width: "50%", height: "30px", border: "none", borderRadius: "10px", backgroundColor: "#0f9e3e", color: "white" }} onClick={() => StartRecurringJob("ProcessPTCProductGameRecord")}>{t("START")}</button></td>
            </tr>
            <tr>
              <td style={{ width: "80%", height: "80px", borderRight: "1px solid #d6dadd", borderBottom: "1px solid #d6dadd", paddingLeft: "15px" }}>ProcessPragmaticPlayGameRecord  </td><td style={{ textAlign: "center", borderBottom: "1px solid #d6dadd" }}><button className="job-button" style={{ width: "50%", height: "30px", border: "none", borderRadius: "10px", backgroundColor: "#0f9e3e", color: "white" }} onClick={() => StartRecurringJob("ProcessPragmaticPlayGameRecord")}>{t("START")}</button></td>
            </tr>
            <tr>
              <td style={{ width: "80%", height: "80px", borderRight: "1px solid #d6dadd", borderBottom: "1px solid #d6dadd", paddingLeft: "15px" }}>M8Settlement  </td><td style={{ textAlign: "center", borderBottom: "1px solid #d6dadd" }}><button className="job-button" style={{ width: "50%", height: "30px", border: "none", borderRadius: "10px", backgroundColor: "#0f9e3e", color: "white" }} onClick={() => StartRecurringJob("M8Settlement")}>{t("START")}</button></td>
            </tr>
            <tr>
              <td style={{ width: "80%", height: "80px", borderRight: "1px solid #d6dadd", borderBottom: "1px solid #d6dadd", paddingLeft: "15px" }}>M8TicketTranslate  </td><td style={{ textAlign: "center", borderBottom: "1px solid #d6dadd" }}><button className="job-button" style={{ width: "50%", height: "30px", border: "none", borderRadius: "10px", backgroundColor: "#0f9e3e", color: "white" }} onClick={() => StartRecurringJob("M8TicketTranslate")}>{t("START")}</button></td>
            </tr>
            <tr>
              <td style={{ width: "80%", height: "80px", borderRight: "1px solid #d6dadd", borderBottom: "1px solid #d6dadd", paddingLeft: "15px" }}>ProcessM8ProductGameRecord  </td><td style={{ textAlign: "center", borderBottom: "1px solid #d6dadd" }}><button className="job-button" style={{ width: "50%", height: "30px", border: "none", borderRadius: "10px", backgroundColor: "#0f9e3e", color: "white" }} onClick={() => StartRecurringJob("ProcessM8ProductGameRecord")}>{t("START")}</button></td>
            </tr>
            <tr>
              <td style={{ width: "80%", height: "80px", borderRight: "1px solid #d6dadd", borderBottom: "1px solid #d6dadd", paddingLeft: "15px" }}>ProcessJClub777ProductGameRecord  </td><td style={{ textAlign: "center", borderBottom: "1px solid #d6dadd" }}><button className="job-button" style={{ width: "50%", height: "30px", border: "none", borderRadius: "10px", backgroundColor: "#0f9e3e", color: "white" }} onClick={() => StartRecurringJob("ProcessJClub777ProductGameRecord")}>{t("START")}</button></td>
            </tr>
            <tr>
              <td style={{ width: "80%", height: "80px", borderRight: "1px solid #d6dadd", borderBottom: "1px solid #d6dadd", paddingLeft: "15px" }}>Process93ConnectProductGameRecord  </td><td style={{ textAlign: "center", borderBottom: "1px solid #d6dadd" }}><button className="job-button" style={{ width: "50%", height: "30px", border: "none", borderRadius: "10px", backgroundColor: "#0f9e3e", color: "white" }} onClick={() => StartRecurringJob("Process93ConnectProductGameRecord")}>{t("START")}</button></td>
            </tr>
            <tr>
              <td style={{ width: "80%", height: "80px", borderRight: "1px solid #d6dadd", borderBottom: "1px solid #d6dadd", paddingLeft: "15px" }}>ProcessJJProductGameRecord  </td><td style={{ textAlign: "center", borderBottom: "1px solid #d6dadd" }}><button className="job-button" style={{ width: "50%", height: "30px", border: "none", borderRadius: "10px", backgroundColor: "#0f9e3e", color: "white" }} onClick={() => StartRecurringJob("ProcessJJProductGameRecord")}>{t("START")}</button></td>
            </tr>
            <tr>
              <td style={{ width: "80%", height: "80px", borderRight: "1px solid #d6dadd", borderBottom: "1px solid #d6dadd", paddingLeft: "15px" }}>ProcessCTProductGameRecord  </td><td style={{ textAlign: "center", borderBottom: "1px solid #d6dadd" }}><button className="job-button" style={{ width: "50%", height: "30px", border: "none", borderRadius: "10px", backgroundColor: "#0f9e3e", color: "white" }} onClick={() => StartRecurringJob("ProcessCTProductGameRecord")}>{t("START")}</button></td>
            </tr>
            <tr>
              <td style={{ width: "80%", height: "80px", borderRight: "1px solid #d6dadd", borderBottom: "1px solid #d6dadd", paddingLeft: "15px" }}>ProcessMega888ProductGameRecord  </td><td style={{ textAlign: "center", borderBottom: "1px solid #d6dadd" }}><button className="job-button" style={{ width: "50%", height: "30px", border: "none", borderRadius: "10px", backgroundColor: "#0f9e3e", color: "white" }} onClick={() => StartRecurringJob("ProcessMega888ProductGameRecord")}>{t("START")}</button></td>
            </tr>
            <tr>
              <td style={{ width: "80%", height: "80px", borderRight: "1px solid #d6dadd", borderBottom: "1px solid #d6dadd", paddingLeft: "15px" }}>ProcessDGProductGameRecord  </td><td style={{ textAlign: "center", borderBottom: "1px solid #d6dadd" }}><button className="job-button" style={{ width: "50%", height: "30px", border: "none", borderRadius: "10px", backgroundColor: "#0f9e3e", color: "white" }} onClick={() => StartRecurringJob("ProcessDGProductGameRecord")}>{t("START")}</button></td>
            </tr>
            <tr>
              <td style={{ width: "80%", height: "80px", borderRight: "1px solid #d6dadd", borderBottom: "1px solid #d6dadd", paddingLeft: "15px" }}>ProcessXEProductGameRecord  </td><td style={{ textAlign: "center", borderBottom: "1px solid #d6dadd" }}><button className="job-button" style={{ width: "50%", height: "30px", border: "none", borderRadius: "10px", backgroundColor: "#0f9e3e", color: "white" }} onClick={() => StartRecurringJob("ProcessXEProductGameRecord")}>{t("START")}</button></td>
            </tr>
            <tr>
              <td style={{ width: "80%", height: "80px", borderRight: "1px solid #d6dadd", borderBottom: "1px solid #d6dadd", paddingLeft: "15px" }}>ProcessLionKingProductGameRecord  </td><td style={{ textAlign: "center", borderBottom: "1px solid #d6dadd" }}><button className="job-button" style={{ width: "50%", height: "30px", border: "none", borderRadius: "10px", backgroundColor: "#0f9e3e", color: "white" }} onClick={() => StartRecurringJob("ProcessLionKingProductGameRecord")}>{t("START")}</button></td>
            </tr>
            <tr>
              <td style={{ width: "80%", height: "80px", borderRight: "1px solid #d6dadd", borderBottom: "1px solid #d6dadd", paddingLeft: "15px" }}>ProcessAdvantPlayProductGameRecord  </td><td style={{ textAlign: "center", borderBottom: "1px solid #d6dadd" }}><button className="job-button" style={{ width: "50%", height: "30px", border: "none", borderRadius: "10px", backgroundColor: "#0f9e3e", color: "white" }} onClick={() => StartRecurringJob("ProcessAdvantPlayProductGameRecord")}>{t("START")}</button></td>
            </tr>
            <tr>
              <td style={{ width: "80%", height: "80px", borderRight: "1px solid #d6dadd", borderBottom: "1px solid #d6dadd", paddingLeft: "15px" }}>ProcessAllBetProductGameRecord  </td><td style={{ textAlign: "center", borderBottom: "1px solid #d6dadd" }}><button className="job-button" style={{ width: "50%", height: "30px", border: "none", borderRadius: "10px", backgroundColor: "#0f9e3e", color: "white" }} onClick={() => StartRecurringJob("ProcessAllBetProductGameRecord")}>{t("START")}</button></td>
            </tr>
            <tr>
              <td style={{ width: "80%", height: "80px", borderRight: "1px solid #d6dadd", borderBottom: "1px solid #d6dadd", paddingLeft: "15px" }}>ProcessVPowerProductGameRecord  </td><td style={{ textAlign: "center", borderBottom: "1px solid #d6dadd" }}><button className="job-button" style={{ width: "50%", height: "30px", border: "none", borderRadius: "10px", backgroundColor: "#0f9e3e", color: "white" }} onClick={() => StartRecurringJob("ProcessVPowerProductGameRecord")}>{t("START")}</button></td>
            </tr>
            <tr>
              <td style={{ width: "80%", height: "80px", borderRight: "1px solid #d6dadd", borderBottom: "1px solid #d6dadd", paddingLeft: "15px" }}>ProcessTFGamingProductGameRecord  </td><td style={{ textAlign: "center", borderBottom: "1px solid #d6dadd" }}><button className="job-button" style={{ width: "50%", height: "30px", border: "none", borderRadius: "10px", backgroundColor: "#0f9e3e", color: "white" }} onClick={() => StartRecurringJob("ProcessTFGamingProductGameRecord")}>{t("START")}</button></td>
            </tr>
            <tr>
              <td style={{ width: "80%", height: "80px", borderRight: "1px solid #d6dadd", borderBottom: "1px solid #d6dadd", paddingLeft: "15px" }}>ProcessAce333ProductGameRecord  </td><td style={{ textAlign: "center", borderBottom: "1px solid #d6dadd" }}><button className="job-button" style={{ width: "50%", height: "30px", border: "none", borderRadius: "10px", backgroundColor: "#0f9e3e", color: "white" }} onClick={() => StartRecurringJob("ProcessAce333ProductGameRecord")}>{t("START")}</button></td>
            </tr>
            <tr>
              <td style={{ width: "80%", height: "80px", borderRight: "1px solid #d6dadd", borderBottom: "1px solid #d6dadd", paddingLeft: "15px" }}>ProcessMarioClubGameRecord  </td><td style={{ textAlign: "center", borderBottom: "1px solid #d6dadd" }}><button className="job-button" style={{ width: "50%", height: "30px", border: "none", borderRadius: "10px", backgroundColor: "#0f9e3e", color: "white" }} onClick={() => StartRecurringJob("ProcessMarioClubGameRecord")}>{t("START")}</button></td>
            </tr>
            <tr>
              <td style={{ width: "80%", height: "80px", borderRight: "1px solid #d6dadd", borderBottom: "1px solid #d6dadd", paddingLeft: "15px" }}>Process918KissGameRecord  </td><td style={{ textAlign: "center", borderBottom: "1px solid #d6dadd" }}><button className="job-button" style={{ width: "50%", height: "30px", border: "none", borderRadius: "10px", backgroundColor: "#0f9e3e", color: "white" }} onClick={() => StartRecurringJob("Process918KissGameRecord")}>{t("START")}</button></td>
            </tr>
            <tr>
              <td style={{ width: "80%", height: "80px", borderRight: "1px solid #d6dadd", borderBottom: "1px solid #d6dadd", paddingLeft: "15px" }}>ProcessPussy888KissGameRecord  </td><td style={{ textAlign: "center", borderBottom: "1px solid #d6dadd" }}><button className="job-button" style={{ width: "50%", height: "30px", border: "none", borderRadius: "10px", backgroundColor: "#0f9e3e", color: "white" }} onClick={() => StartRecurringJob("ProcessPussy888KissGameRecord")}>{t("START")}</button></td>
            </tr>
            <tr>
              <td style={{ width: "80%", height: "80px", borderRight: "1px solid #d6dadd", borderBottom: "1px solid #d6dadd", paddingLeft: "15px" }}>ProcessBooongoGameRecord  </td><td style={{ textAlign: "center", borderBottom: "1px solid #d6dadd" }}><button className="job-button" style={{ width: "50%", height: "30px", border: "none", borderRadius: "10px", backgroundColor: "#0f9e3e", color: "white" }} onClick={() => StartRecurringJob("ProcessBooongoGameRecord")}>{t("START")}</button></td>
            </tr>
            <tr>
              <td style={{ width: "80%", height: "80px", borderRight: "1px solid #d6dadd", borderBottom: "1px solid #d6dadd", paddingLeft: "15px" }}>ProcessAllBetProductGameRecord  </td><td style={{ textAlign: "center", borderBottom: "1px solid #d6dadd" }}><button className="job-button" style={{ width: "50%", height: "30px", border: "none", borderRadius: "10px", backgroundColor: "#0f9e3e", color: "white" }} onClick={() => StartRecurringJob("ProcessAllBetProductGameRecord")}>{t("START")}</button></td>
            </tr>
            <tr>
              <td style={{ width: "80%", height: "80px", borderRight: "1px solid #d6dadd", borderBottom: "1px solid #d6dadd", paddingLeft: "15px" }}>ProcessTFGamingProductGameRecord  </td><td style={{ textAlign: "center", borderBottom: "1px solid #d6dadd" }}><button className="job-button" style={{ width: "50%", height: "30px", border: "none", borderRadius: "10px", backgroundColor: "#0f9e3e", color: "white" }} onClick={() => StartRecurringJob("ProcessTFGamingProductGameRecord")}>{t("START")}</button></td>
            </tr>
            <tr>
              <td style={{ width: "80%", height: "80px", borderRight: "1px solid #d6dadd", borderBottom: "1px solid #d6dadd", paddingLeft: "15px" }}>ProcessAce333ProductGameRecord  </td><td style={{ textAlign: "center", borderBottom: "1px solid #d6dadd" }}><button className="job-button" style={{ width: "50%", height: "30px", border: "none", borderRadius: "10px", backgroundColor: "#0f9e3e", color: "white" }} onClick={() => StartRecurringJob("ProcessAce333ProductGameRecord")}>{t("START")}</button></td>
            </tr>
            <tr>
              <td style={{ width: "80%", height: "80px", borderRight: "1px solid #d6dadd", borderBottom: "1px solid #d6dadd", paddingLeft: "15px" }}>ReSettle568ProductGameRecord  </td><td style={{ textAlign: "center", borderBottom: "1px solid #d6dadd" }}><button className="job-button" style={{ width: "50%", height: "30px", border: "none", borderRadius: "10px", backgroundColor: "#0f9e3e", color: "white" }} onClick={() => StartRecurringJob("ReSettle568ProductGameRecord")}>{t("START")}</button></td>
            </tr>
            <tr>
              <td style={{ width: "80%", height: "80px", borderRight: "1px solid #d6dadd", borderBottom: "1px solid #d6dadd", paddingLeft: "15px" }}>Reward Schedule Check  </td><td style={{ textAlign: "center", borderBottom: "1px solid #d6dadd" }}><button className="job-button" style={{ width: "50%", height: "30px", border: "none", borderRadius: "10px", backgroundColor: "#0f9e3e", color: "white" }} onClick={() => StartRecurringJob("CheckRewardSchedule")}>{t("START")}</button></td>
            </tr>
            <tr>
              <td style={{ width: "80%", height: "80px", borderRight: "1px solid #d6dadd", borderBottom: "1px solid #d6dadd", paddingLeft: "15px" }}>Reward Daily Process </td><td style={{ textAlign: "center", borderBottom: "1px solid #d6dadd" }}><button className="job-button" style={{ width: "50%", height: "30px", border: "none", borderRadius: "10px", backgroundColor: "#0f9e3e", color: "white" }} onClick={() => StartRecurringJob("RewardDailyProcess")}>{t("START")}</button></td>
            </tr>
            <tr>
              <td style={{ width: "80%", height: "80px", borderRight: "1px solid #d6dadd", borderBottom: "1px solid #d6dadd", paddingLeft: "15px" }}>GetGameBetsHistory </td><td style={{ textAlign: "center", borderBottom: "1px solid #d6dadd" }}><button className="job-button" style={{ width: "50%", height: "30px", border: "none", borderRadius: "10px", backgroundColor: "#0f9e3e", color: "white" }} onClick={() => StartRecurringJob("GetGameBetsHistory")}>{t("START")}</button></td>
            </tr>
            <tr>
              <td style={{ width: "80%", height: "80px", borderRight: "1px solid #d6dadd", borderBottom: "1px solid #d6dadd", paddingLeft: "15px" }}>ConvertGameBetsHistory </td><td style={{ textAlign: "center", borderBottom: "1px solid #d6dadd" }}><button className="job-button" style={{ width: "50%", height: "30px", border: "none", borderRadius: "10px", backgroundColor: "#0f9e3e", color: "white" }} onClick={() => StartRecurringJob("ConvertGameBetsHistory")}>{t("START")}</button></td>
            </tr>
            <tr>
              <td style={{ width: "80%", height: "80px", borderRight: "1px solid #d6dadd", borderBottom: "1px solid #d6dadd", paddingLeft: "15px" }}>GenerateCommission </td><td style={{ textAlign: "center", borderBottom: "1px solid #d6dadd" }}><button className="job-button" style={{ width: "50%", height: "30px", border: "none", borderRadius: "10px", backgroundColor: "#0f9e3e", color: "white" }} onClick={() => StartRecurringJob("GenerateCommission")}>{t("START")}</button></td>
            </tr>
            <tr>
              <td style={{ width: "80%", height: "80px", borderRight: "1px solid #d6dadd", borderBottom: "1px solid #d6dadd", paddingLeft: "15px" }}>UpdateRedis</td><td style={{ textAlign: "center", borderBottom: "1px solid #d6dadd" }}><button className="job-button" style={{ width: "50%", height: "30px", border: "none", borderRadius: "10px", backgroundColor: "#0f9e3e", color: "white" }} onClick={() => StartRecurringJob("UpdateRedis")}>{t("START")}</button></td>
            </tr>
            <tr>
              <td style={{ width: "80%", height: "80px", borderRight: "1px solid #d6dadd", borderBottom: "1px solid #d6dadd", paddingLeft: "15px" }}>ProcessAWCGameRecordSummary</td><td style={{ textAlign: "center", borderBottom: "1px solid #d6dadd" }}><button className="job-button" style={{ width: "50%", height: "30px", border: "none", borderRadius: "10px", backgroundColor: "#0f9e3e", color: "white" }} onClick={() => StartRecurringJob("ProcessAWCGameRecordSummary")}>{t("START")}</button></td>
            </tr>
          </table>
        </PanelBody>
      </Panel>
    </div>
  );
};

export default ManageRecurringJob;
