import React, { useState, useEffect } from "react";
import ReactTable from "../../components/constantComponent/reactTable/ReactTable";
import { Panel, PanelBody } from "./../../components/panel/panel.jsx";
import { useHistory } from "react-router-dom";
import {
    UncontrolledButtonDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from "reactstrap";
import "react-datepicker/dist/react-datepicker.css";
import NavigationButton from "../../components/constantComponent/button/NavigationButton";
import {
    navigateTo,
    numberWithCurrencyFormat,
} from "../../util/Util";
import {
    ApiKey,
    ApiUrl,
    AccessRight,
    WebUrl,
    LanguageKey,
    DEFAULT_PAGE_SIZE,
    Role,
} from "../../util/Constant";
import { checkIfPermissionExist } from "../../util/PermissionChecker";
import { setBusy, setIdle, showMessage } from "../../redux/AppAction";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import ApiEngine from "../../util/ApiEngine";
import { AsyncPaginate } from "react-select-async-paginate";
import classNames from "classnames";
import moment from 'moment';
import DateRangePicker from "react-bootstrap-daterangepicker";
import { stringIsNullOrEmpty, createFormBody } from "../../util/Util";


/// <summary>
/// Author : -
/// </summary>
const ManageMember = (props) => {
    const { t } = useTranslation(LanguageKey._PRIMARY);
    let _history = useHistory();
    const _dispatch = useDispatch();
    var _username = useSelector(
        (state) => state["authState"]["userData"]["username"]
    );
  
    const [tableData, setTableData] = useState([]);
    const [selectedDate, setSelectedDate] = useState(moment().format("YYYY-MM-DD"));

    let _tableColumns = [
        {
            id: "action",
            Header: "",
            Cell: ({ row }) =>
                checkIfPermissionExist(
                    AccessRight._MANAGE_BIRTHDAY_REWRDS,
                    true
                ) && ((row.original.isClaim==false)?
                    <div className="btn-group m-r-5 m-b-5">
                        <input
                            type="checkbox"
                            value={row.original.id}
                            checked={selectedCheckboxes.includes(row.original.id)}
                            onChange={(e) => handleCheckboxChange(e)}
                        />
                    </div> :
                    <div className="btn-group m-r-5 m-b-5">
                        <span className="badge badge-secondary badge-green">
                            {t("RELEASED")}
                        </span>
                    </div>

                ),
            disableSortBy: true,
            disableFilters: true,
            width: 50,
            style: { overflow: "visible" },
        },
        {
            Header: "USERNAME",
            accessor: "username",
            minWidth: 100,
            Cell: ({ row }) => {
                return (
                    <span
                    >
                        {row.original.username}
                    </span>
                );
            },
        },
        {
            Header: "DATE_OF_BIRTH",
            accessor: "birthDate",
            minWidth: 100,
        },
       
        {
            Header: "VIP",
            accessor: "vipLevel",
            minWidth: 100,
        },
        {
            Header: "REWARDAMOUNT",
            accessor: "amount",
            minWidth: 100,
        },
        
    ];

    const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);

    const handleCheckboxChange = (e) => {
        const { value } = e.target;
        console.log(e.target)
        setSelectedCheckboxes((prevSelected) => {
            console.log(prevSelected)
            if (prevSelected.includes(value)) {
                return prevSelected.filter((id) => id !== value);
            } else {
                return [...prevSelected, value];
            }
        });
    };

    /// <summary>
    /// Author : -
    /// </summary>
    useEffect(() => {
        init();
    }, []);

    const init = async () => {

        fetch(ApiUrl._API_GET_MEMBER_BIRTHDAY_LIST + "?date=" + selectedDate +"", {
            method: ApiKey._API_GET,
            headers: {
                "Content-Type": ApiKey._API_FORM_URLENCODED,
                "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
            },
        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                    setTableData(responseJson[ApiKey._API_DATA_KEY]);
                }
            });
    }

    async function getBirthdayMemberList() {
        _dispatch(setBusy());
        fetch(ApiUrl._API_GET_MEMBER_BIRTHDAY_LIST + "?date=" + selectedDate + "", {
            method: ApiKey._API_GET,
            headers: {
                "Content-Type": ApiKey._API_FORM_URLENCODED,
                "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
            },
        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                    setTableData(responseJson[ApiKey._API_DATA_KEY]);
                }
                _dispatch(setIdle());
            });
    }

    async function Claim(idList) {
        let params = {
            "memberList": idList,
            "date": selectedDate,
        };
        let formBody = createFormBody(params);
        _dispatch(setBusy());
        fetch(ApiUrl._API_GENERATE_BIRTHDAY_REWARD, {
            method: ApiKey._API_POST,
            headers: {
                'Content-Type': ApiKey._API_FORM_URLENCODED,
                "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY
            },
            body: formBody
        }).then((response) => response.json()).then((responseJson) => {
            getBirthdayMemberList();
            _dispatch(setIdle());
            _dispatch(showMessage(responseJson[ApiKey._API_SUCCESS_KEY], t(responseJson[ApiKey._API_MESSAGE_KEY])));
        });
        
    }

    const handleEvent = (event, picker) => {
        setSelectedDate(moment(picker.startDate).format("YYYY-MM-DD"));
    };


    return (
        <div>
            <h1 className="page-header">
                {t("BIRTHDAY_REWARD")}
            </h1>
            <Panel>
                <div className="row">
                <div
                    className="input-group"
                    style={{ width: "100%", flexFlow: "nowrap" , marginTop:"14px", marginLeft:"25px"}}
                >
                    <div className="input-group-prepend">
                        <span className="input-group-text">
                            <i className="fa fa-calendar"></i>
                        </span>
                    </div>
                    <div style={{ width: "20%" }}>
                        <DateRangePicker
                                containerStyles={{ width: "100%" }}
                                singleDatePicker={true}
                                onApply={handleEvent}
                                alwaysShowCalendars={true}
                                locale={{ format: "YYYY-MM-DD" }}
                                maxDate={moment().format("YYYY-MM-DD") }
                                
                        >
                            <input
                                type="text"
                                className="form-control"
                                value={selectedDate}
                            />
                        </DateRangePicker>
                        </div>
                        <button
                            type="button"
                            className="btn btn-primary"
                            style={{marginLeft:"12px"} }
                            onClick={() => getBirthdayMemberList()}
                        >
                            {t("SEARCH")}
                        </button>
                    </div>
                </div>
                <div className="row">
                    <div>

                        <button className="btn btn-primary" style={{ marginLeft: "25px", marginTop: "30px", width: "100px", position: "absolute", zIndex: "1" }} onClick={() => Claim(selectedCheckboxes)} disabled={selectedCheckboxes.length > 0 ? false : true}>{t("RELEASE")}</button>
                        <button className="btn btn-primary" style={{ marginLeft: "140px", marginTop: "30px", width: "100px", position: "absolute", zIndex: "1" }} onClick={() => Claim(tableData.filter(x => x.isClaim == false).map(member => member.id))} disabled={tableData.filter(x => x.isClaim == false).length>0?false:true}>{t("RELEASE_ALL")}</button>
                    </div>
                    
                    <div className="col-lg-12">
                        <PanelBody>
                            {<ReactTable data={tableData} columns={_tableColumns} />}
                        </PanelBody>
                    </div>
                </div>
            </Panel>
        </div>
    );
};

export default ManageMember;
