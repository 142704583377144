import React, { useState, useEffect, useMemo, useCallback } from 'react';
import ReactTable from '../../components/constantComponent/reactTable/ReactTable';
import { Panel, PanelHeader, PanelBody } from './../../components/panel/panel.jsx';
import { useHistory } from "react-router-dom";
import { UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import 'react-datepicker/dist/react-datepicker.css';
import Select from 'react-select';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import moment from 'moment';
import { navigateTo } from '../../util/Util';
import { ApiKey, ApiUrl, WebUrl, AccessRight, TransactionType, TransactionStatus, PanelType, LanguageKey, BankingChannelName, DEFAULT_PAGE_SIZE } from '../../util/Constant';
import { setBusy, setIdle, showMessage } from "../../redux/AppAction";
import { useDispatch, useSelector } from "react-redux";
import { stringIsNullOrEmpty, createFormBody, numberWithCurrencyFormat } from "../../util/Util";
import { useForm } from "react-hook-form";
import { useTranslation } from 'react-i18next';
import ApiEngine from '../../util/ApiEngine';
import { AsyncPaginate } from "react-select-async-paginate";
import SweetAlert from 'react-bootstrap-sweetalert';

const GiftpaceReport = props => {
    const { t } = useTranslation(LanguageKey._PRIMARY);
    let _history = useHistory();
    const _dispatch = useDispatch();
    const { register, unregister, handleSubmit, errors } = useForm();


    const [showDialog, setShowDialog] = useState()
    const _PAGING_COUNT = 10;
    const [selectedFilterMember, setSelectedFilterMember] = useState();
    const [selectedMember, setSelectedMember] = useState(
        ""
    );
    const [startDate, setStartDate] = useState(
        moment().startOf("day").format("YYYY-MM-DD HH:mm:ss")
    );
    const [endDate, setEndDate] = useState(
        moment().endOf("day").format("YYYY-MM-DD HH:mm:ss")
    );
    const [giftpaceData, setGiftpaceData] = useState([]);

    const _OPTION_STYLES = {
        control: (base) => ({
            ...base,
            "min-height": "34px",
            height: "34px",
        }),
        valueContainer: (base) => ({
            ...base,
            height: "34px",
            "min-height": "34px",
        }),
        indicatorsContainer: (base) => ({
            ...base,
            height: "34px",
            "min-height": "34px",
        }),
        input: (base) => ({
            height: "34px",
            "min-height": "34px",
        }),
    };

    useEffect(() => {
        init();
    }, []);

    /// <summary>
    /// Author : -
    /// </summary>
    const init = async () => {
        
    }

    /// <summary>
    /// Author : -
    /// </summary>
    const getGiftpaceReport = async () => {
        let fetchUrl = ApiUrl._API_GET_GIFTPACE_REPORT;
        fetchUrl += "?StartDate=" + startDate + "&EndDate=" + endDate + "&MemberId=" + selectedMember ;

        fetch(fetchUrl, {
            method: ApiKey._API_GET,
            headers: {
                "Content-Type": ApiKey._API_FORM_URLENCODED,
                "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
            },
        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                    setGiftpaceData(responseJson[ApiKey._API_DATA_KEY]);
                }
            });
    }

    let _tableColumns = [
        {
            Header: t("USERNAME"),
            accessor: "username",
            Cell: ({ row }) => {
                return <>{t(row.original.username)}</>
            },
            minWidth: 100,
            Footer: () => {
                return <span><b>  {t("TOTAL")}: </b></span>
            },
        },
        {
            Header: t("CODE"),
            accessor: "code",
            minWidth: 100

        },
        {
            Header: t("AMOUNT"),
            accessor: "amount",
            Cell: ({ row }) => {
                return <>{t(row.original.amount)}</>
            },
            minWidth: 100,
            Footer: ({ page }) => {
                const debitSum = page.reduce((sum, currentValue) => {
                    if (currentValue.original.amount) {
                        return sum + (parseFloat(currentValue.original.amount));
                    }
                    else {
                        return sum;
                    }
                }, 0);
                return <span><b>{numberWithCurrencyFormat(parseFloat(debitSum))}</b></span>
            }
        },
        {
            Header: t("STATUS"),
            accessor: "status",
        },
        {
            Header: t("CLAIMED_DATE"),
            accessor: "createdTime",
            minWidth: 100
        }
    ]

    /// <summary>
    /// Author : Wong
    /// </summary>
    async function getMemberList(search, loadOptions, { page }) {
        var responseJson = await ApiEngine.get(
            `${ApiUrl._API_GET_USER_MEMBER_LIST}?start=${(page - 1) * DEFAULT_PAGE_SIZE
            }&count=${DEFAULT_PAGE_SIZE}&keyword=${search}`
        );

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            let data = responseJson[ApiKey._API_DATA_KEY]["data"];
            let formattedData = data.map((m) => {
                return { label: m.username, value: m.id };
            });
          formattedData.unshift({ label: "All", value: "" })
            return {
                options: formattedData,
                hasMore: formattedData.length == _PAGING_COUNT,
                additional: {
                    page: page + 1,
                },
            };
        }
    }

    const handleEvent = (event, picker) => {
        setStartDate(moment(picker.startDate).format("YYYY-MM-DD HH:mm:ss"));
        setEndDate(moment(picker.endDate).format("YYYY-MM-DD HH:mm:ss"));
    }; /// <summary>

    return (
        <div>
            {showDialog && (
                <SweetAlert
                    warning
                    confirmBtnText={t("CONFIRM")}
                    confirmBtnBsStyle="danger"
                    title={t("WARNING")}
                    onConfirm={() => {
                        setShowDialog(false);
                    }}
                >
                    {t("MAX_DATE_RANGE_7_DAYS")}
                </SweetAlert>
            )}
            <h1 className="page-header">{t("GIFTPACE_REPORT")}</h1>
            <Panel>
                <div className="row">
                    <div className="col-lg-12">
                        <PanelBody>
                            <div className="row">
                                <div className="col-lg-3">
                                    <div className="form-group">
                                        <label>
                                            <b>{t("DATE")}</b>
                                        </label>
                                        <div
                                            className="input-group"
                                            style={{ width: "100%", flexFlow: "nowrap" }}
                                        >
                                            <div className="input-group-prepend">
                                                <span className="input-group-text">
                                                    <i className="fa fa-calendar"></i>
                                                </span>
                                            </div>
                                            <div style={{ width: "80%" }}>
                                                <DateRangePicker
                                                    containerStyles={{ width: "100%" }}
                                                    startDate={startDate}
                                                    endDate={endDate}
                                                    onApply={handleEvent}
                                                    alwaysShowCalendars={true}
                                                    locale={{ format: "YYYY-MM-DD HH:mm:ss" }}
                                                    timePicker={true}
                                                    timePickerSeconds={true}
                                                    ranges={{
                                                        Today: [moment().startOf("day"), moment().endOf("day")],
                                                        Yesterday: [
                                                            moment().subtract(1, "days").startOf("day"),
                                                            moment().subtract(1, "days").endOf("day"),
                                                        ],
                                                        "Last 7 Days": [
                                                            moment().subtract(6, "days").startOf("day"),
                                                            moment().endOf("day"),
                                                        ],
                                                        "Last 30 Days": [
                                                            moment().subtract(29, "days").startOf("day"),
                                                            moment().endOf("day"),
                                                        ],
                                                        "This Month": [
                                                            moment().startOf("month"),
                                                            moment().endOf("month"),
                                                        ],
                                                        "Last Month": [
                                                            moment().subtract(1, "month").startOf("month"),
                                                            moment().subtract(1, "month").endOf("month"),
                                                        ],
                                                        "This Year": [
                                                            moment().startOf("year"),
                                                            moment().endOf("year"),
                                                        ],
                                                    }}
                                                >
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        value={startDate + " - " + endDate}
                                                    />
                                                </DateRangePicker>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3">
                                    <div className="form-group">
                                        <label>
                                            <b>{t("USERNAME")}</b>
                                        </label>
                                        <AsyncPaginate
                                            debounceTimeout={250}
                                            loadOptions={getMemberList}
                                            additional={{
                                                page: 1,
                                            }}
                                            value={selectedFilterMember}
                                            onChange={(e) => {
                                                setSelectedFilterMember(e);
                                                setSelectedMember(e.value);
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-2">
                                    <button type="button" onClick={() => { getGiftpaceReport(); }} className="btn btn-primary">{t("SEARCH")}</button>
                                </div>
                            </div>
                            <hr />
                            <ReactTable data={giftpaceData} columns={_tableColumns} renderFooter={true} />
                        </PanelBody>
                    </div>
                </div>

            </Panel>
        </div>
    )
}

export default GiftpaceReport;