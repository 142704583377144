import React, { useState, useRef, useEffect } from "react";
import ReactTable from "../../components/constantComponent/reactTable/ReactTable";
import { Panel, PanelBody } from "./../../components/panel/panel.jsx";
import moment from "moment";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { showMessage } from "../../redux/AppAction";
import { useDispatch, useSelector } from "react-redux";
import {
	ApiKey,
	ApiUrl,
	LanguageKey,
	_TICKET_STATUS,
	DEFAULT_PAGE_SIZE,
} from "../../util/Constant";
import {
	stringIsNullOrEmpty,
	isObjectEmpty,
	numberWithCurrencyFormat,
} from "../../util/Util";
import { useTranslation } from "react-i18next";
import ApiEngine from "../../util/ApiEngine.js";
import Select from "react-select";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import classnames from "classnames";
import { AsyncPaginate } from "react-select-async-paginate";
import { CSVLink } from "react-csv";
/// <summary>
/// Author : -
/// </summary>

const ManageWinLossReport3 = (props) => {
	let _dispatch = useDispatch();
	const { t } = useTranslation(LanguageKey._PRIMARY);
	const [reportData, setReportData] = useState({
		viewerReportList: [],
		downlineReportList: [],
	});
	const [startDate, setStartDate] = useState(
		moment().startOf("day").format("YYYY-MM-DD HH:mm:ss")
	);
	const [endDate, setEndDate] = useState(
		moment().endOf("day").format("YYYY-MM-DD HH:mm:ss")
	);
	const [deleteDate, setDeleteDate] = useState(
		moment().startOf("day").format("YYYY-MM-DD")
	);
	const [userHierachy, setUserHierachy] = useState([]);
	var _username = useSelector(
		(state) => state["authState"]["userData"]["username"]
	);
	var _userId = useSelector(
		(state) => state["authState"]["userData"]["userId"]
	);
	const [allMembers, setAllMembers] = useState([]);
	const [gameRecord, setGameRecord] = useState([]);
	const ALL_OPTION_LABEL = "All";
	const ALL_OPTION_VALUE = "all";
	const [selectedProduct, setSelectedProduct] = useState(ALL_OPTION_VALUE);
	const [productList, setProductList] = useState([]);
	const [showGameRecord, setShowGameRecord] = useState(false);
	const [selectedMember, setSelectedMember] = useState(
		useSelector((state) => state["authState"]["userData"]["userId"])
	);
	const [ticketViewingMember, setTicketViewingMember] = useState();
	const [gameDetailUrl, setGameDetailUrl] = useState("");
	const [overallInfo, setOverallInfo] = useState({});
	const _OVERALL_KEYS = {
		TURNOVER: "TOTAL_TURNOVER",
		VTURNOVER: "TOTAL_VTURNOVER",
		AMOUNT: "TOTAL_AMOUNT",
		WIN: "TOTAL_WIN",
	};
	const _PAGING_COUNT = 10;
	const [selectedFilterMember, setSelectedFilterMember] = useState();

	// This is added for the sub reports total "footer"
	const [ticketStatus, setTicketStatus] = useState("1");
	const _REPORT_REF = useRef();
	const _DOWNLINE_REPORT_REF = useRef();
	const _NODE_THEAD = "THEAD";
	const _TEXT_RED = "text-red";
	const [exportDatas, setExportDatas] = useState([]);
	/// <summary>
	/// Author : -
	/// </summary>
	const _OPTION_STYLES = {
		control: (base) => ({
			...base,
			minHeight: "34px",
			height: "34px",
		}),
		valueContainer: (base) => ({
			...base,
			height: "34px",
			minHeight: "34px",
		}),
		indicatorsContainer: (base) => ({
			...base,
			height: "34px",
			minHeight: "34px",
		}),
		input: (base) => ({
			height: "34px",
			minHeight: "34px",
		}),
	};

	let _gameRecordColumns = [
		{
			Header: "DATE",
			accessor: "date",
			Cell: ({ row }) =>
				moment(row.original.date).format("YYYY-MM-DD HH:mm:ss"),
			disableSortBy: true,
			Footer: "Total",
		},
		{
			Header: "SETTLED_ON",
			accessor: "updatedTime",
			Cell: ({ row }) => {
				if (row.original.updatedTime != "-") {
					return moment(row.original.updatedTime).format("YYYY-MM-DD HH:mm:ss");
				} else {
					return "-";
				}
			},
			disableSortBy: true,
			Footer: "",
		},
		{
			Header: "GAME",
			accessor: "gameName",
			disableSortBy: true,
			Footer: "",
		},
		{
			Header: "Ticket Details",
			accessor: "remark",
			Cell: ({ row }) => {
				return (
					<>
						<span>{row.original.betId}</span>
						<br />
						<span>
							{!stringIsNullOrEmpty(row.original.remark)
								? " - " + row.original.remark
								: ""}
						</span>
						<br />
					</>
				);
			},
			disableSortBy: true,
			Footer: "",
		},
		{
			Header: "TURNOVER",
			accessor: "turnover",
			disableSortBy: true,
			Cell: ({ row }) => {
				return numberWithCurrencyFormat(parseFloat(row.original.turnover));
			},
			Footer: ({ page }) => {
				let totalTurnover = 0;
				if (
					!isObjectEmpty(overallInfo) &&
					overallInfo[_OVERALL_KEYS.TURNOVER]
				) {
					totalTurnover = parseFloat(overallInfo[_OVERALL_KEYS.TURNOVER]);
				}
				return (
					<span>
						<b>{numberWithCurrencyFormat(totalTurnover, 3, true)}</b>
					</span>
				);
			},
		},
		{
			Header: "VTURNOVER",
			accessor: "validTurnover",
			disableSortBy: true,
			Cell: ({ row }) => {
				return numberWithCurrencyFormat(parseFloat(row.original.validTurnover));
			},
			Footer: ({ page }) => {
				let totalVTurnover = 0;
				if (
					!isObjectEmpty(overallInfo) &&
					overallInfo[_OVERALL_KEYS.VTURNOVER]
				) {
					totalVTurnover = parseFloat(overallInfo[_OVERALL_KEYS.VTURNOVER]);
				}
				return (
					<span>
						<b>{numberWithCurrencyFormat(totalVTurnover, 3, true)}</b>
					</span>
				);
			},
		},
		{
			Header: "Stake",
			accessor: "amount",
			disableSortBy: true,
			Cell: ({ row }) => {
				return numberWithCurrencyFormat(parseFloat(row.original.amount));
			},
			Footer: ({ page }) => {
				let totalAmount = 0;
				if (!isObjectEmpty(overallInfo) && overallInfo[_OVERALL_KEYS.AMOUNT]) {
					totalAmount = parseFloat(overallInfo[_OVERALL_KEYS.AMOUNT]);
				}
				return (
					<span>
						<b>{numberWithCurrencyFormat(totalAmount, 3, true)}</b>
					</span>
				);
			},
		},
		{
			Header: "STATUS",
			accessor: "statusString",
			disableSortBy: true,
			Footer: "",
		},
		{
			Header: "WinLoss",
			accessor: "netProfit",
			Cell: ({ row }) => {
				let profit = parseFloat(row.original.netProfit);
				return (
					<span className={classnames({ "text-red": profit < 0 })}>
						{numberWithCurrencyFormat(profit, 3, true)}
					</span>
				);
			},
			disableSortBy: true,
			Footer: ({ page }) => {
				let winLossTotal = 0;
				if (
					!isObjectEmpty(overallInfo) &&
					overallInfo[_OVERALL_KEYS.WIN] &&
					overallInfo[_OVERALL_KEYS.AMOUNT]
				) {
					winLossTotal =
						parseFloat(overallInfo[_OVERALL_KEYS.WIN]) -
						parseFloat(overallInfo[_OVERALL_KEYS.AMOUNT]);
				}
				return (
					<span className={classnames({ "text-red": winLossTotal < 0 })}>
						{numberWithCurrencyFormat(winLossTotal, 3, true)}
					</span>
				);
			},
		},
	];

	/// <summary>
	/// Author : -
	/// </summary>
	const handleEvent = (event, picker) => {
		setStartDate(moment(picker.startDate).format("YYYY-MM-DD HH:mm:ss"));
		setEndDate(moment(picker.endDate).format("YYYY-MM-DD HH:mm:ss"));
	}; /// <summary>
	/// Author : -
	/// </summary>
	const handleDeleteEvent = (event, picker) => {
		setDeleteDate(moment(picker.startDate).format("YYYY-MM-DD "));
	};

	async function deleteCache() {
		var responseJson = await ApiEngine.get(
			ApiUrl._API_CLEAR_CACHE + "?deleteDate=" + deleteDate
		);
		if (responseJson[ApiKey._API_SUCCESS_KEY]) {
			_dispatch(
				showMessage(
					responseJson[ApiKey._API_SUCCESS_KEY],
					t(responseJson[ApiKey._API_MESSAGE_KEY])
				)
			);
		} else {
			_dispatch(
				showMessage(
					responseJson[ApiKey._API_SUCCESS_KEY],
					t(responseJson[ApiKey._API_MESSAGE_KEY])
				)
			);
		}
	}

	/// <summary>
	/// Author : -
	/// </summary>
	async function readReport(memberId, searchDownline = true) {
		try {
			var apiUrl =
				ApiUrl._API_GET_WINLOSS_REPORT3 +
				"?dateFrom=" +
				startDate +
				"&dateTo=" +
				endDate +
				"&searchDownline=" +
				searchDownline;

			if (!stringIsNullOrEmpty(memberId)) {
				apiUrl += "&memberId=" + memberId;
			}
			if (selectedProduct != ALL_OPTION_VALUE) {
				apiUrl += "&productid=" + selectedProduct;
			}
			var responseJson = await ApiEngine.get(apiUrl);

			if (responseJson[ApiKey._API_SUCCESS_KEY]) {
				setReportData(responseJson[ApiKey._API_DATA_KEY]);
				updateFilteredDatas(
					responseJson[ApiKey._API_DATA_KEY].downlineReportList
				);
			} else {
				throw responseJson[ApiKey._API_MESSAGE_KEY];
			}

			apiUrl = ApiUrl._API_GET_MEMBER_HIERACHY;

			if (!stringIsNullOrEmpty(memberId)) {
				apiUrl += "?memberId=" + memberId;
			}

			responseJson = await ApiEngine.get(apiUrl);

			if (responseJson[ApiKey._API_SUCCESS_KEY]) {
				setUserHierachy(responseJson[ApiKey._API_DATA_KEY]);
			} else {
				throw responseJson[ApiKey._API_MESSAGE_KEY];
			}

			checkNegativeCells(_DOWNLINE_REPORT_REF);
			checkNegativeCells(_REPORT_REF);
		} catch (error) {
			_dispatch(showMessage(false, t(error)));
		}
	}

	function checkNegativeCells(ref) {
		if (ref.current) {
			Array.from(ref.current.childNodes.values()).map((section) => {
				if (section.nodeName != _NODE_THEAD) {
					Array.from(section.childNodes.values()).map((tr) => {
						Array.from(tr.childNodes.values()).map((td) => {
							if (
								!isNaN(parseFloat(td.innerText)) &&
								parseFloat(td.innerText) < 0
							) {
								td.classList.add(_TEXT_RED);
							} else {
								td.classList.remove(_TEXT_RED);
							}
						});
					});
				}
			});
		}
	}

	/// <summary>
	/// Author : -
	/// </summary>
	async function viewGameRecord(userId, status) {
		setTicketViewingMember(userId);
		let apiUrl =
			ApiUrl._API_GET_GAME_RECORD_BY_MEMBER +
			"?memberId=" +
			userId +
			"&OnlyAcceptMember=true";
		apiUrl +=
			"&startDate=" +
			moment(startDate).format("YYYY-MM-DD HH:mm:ss") +
			"&endDate=" +
			moment(endDate).format("YYYY-MM-DD HH:mm:ss");
		apiUrl += "&ticketStatus=" + status;

		setGameDetailUrl(apiUrl);
		setShowGameRecord(true);
	}

	/// <summary>
	/// Author : -
	/// </summary>
	const renderHierarchyLine = () => {
		let startRenderLink = false;
		return userHierachy.map((x, i) => {
			if (startRenderLink == false && x["id"] == _userId) {
				startRenderLink = true;
			}
			return (
				<div key={i}>
					{startRenderLink ? (
						<a
							href="javascript:;"
							onClick={() => {
								readReport(x["id"]);
							}}
						>
							{x["username"]}
						</a>
					) : (
						<span>{x["username"]}</span>
					)}
					{userHierachy.length != i + 1 ? " / " : ""}
				</div>
			);
		});
	};

	/// <summary>
	/// Author : -
	/// </summary>
	function getOverallInfo(info) {
		setOverallInfo(info);
	}

	/// <summary>
	/// Author : Wong
	/// </summary>
	async function getMemberList(search, loadOptions, { page }) {
		var responseJson = await ApiEngine.get(
			`${ApiUrl._API_GET_USER_MEMBER_LIST}?start=${
				(page - 1) * DEFAULT_PAGE_SIZE
			}&count=${DEFAULT_PAGE_SIZE}&keyword=${search}`
		);

		if (responseJson[ApiKey._API_SUCCESS_KEY]) {
			let data = responseJson[ApiKey._API_DATA_KEY]["data"];
			let formattedData = data.map((m) => {
				return { label: m.username, value: m.id };
			});
			formattedData.unshift({ label: "All", value: _userId });
			return {
				options: formattedData,
				hasMore: formattedData.length == _PAGING_COUNT,
				additional: {
					page: page + 1,
				},
			};
		}
	}

	/// <summary>
	/// Author : -
	/// </summary>
	useEffect(() => {
		init();
	}, []);
	/// <summary>
	/// Author : -
	/// </summary>
	async function init() {
		await fetch(ApiUrl._API_GET_MASTER_PRODUCT_ASSIGNMENT_DATA, {
			method: ApiKey._API_GET,
			headers: {
				"Content-Type": ApiKey._API_FORM_URLENCODED,
				"X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
			},
		})
			.then((response) => response.json())
			.then((responseJson) => {
				if (responseJson[ApiKey._API_SUCCESS_KEY]) {
					if (responseJson[ApiKey._API_SUCCESS_KEY]) {
						const accountProductOptionList = [
							{ label: ALL_OPTION_LABEL, value: ALL_OPTION_VALUE },
						];
						responseJson[ApiKey._API_DATA_KEY].forEach(function (option) {
							accountProductOptionList.push({
								label: option.productName,
								value: option.id,
							});
						});

						setProductList(accountProductOptionList);
					}
				} else {
					_dispatch(
						showMessage(
							responseJson[ApiKey._API_SUCCESS_KEY],
							t(responseJson[ApiKey._API_MESSAGE_KEY])
						)
					);
				}
			});
	}

	let _exportHeaders = [
		{ label: "Username", key: "username" },
		{ label: "Total Turnover", key: "totalTurnover" },
		{ label: "Total Valid Turnover", key: "totalVTurnover" },
		{ label: "Total Win Loss", key: "totalWinLoss" },
		{ label: "Total Bonus", key: "totalBonus" },
		{ label: "Total Commission", key: "totalCommission" },
		{ label: "Total WinLoss + Comm", key: "totalWinLoss_Comm" },
		{ label: "Total Downline", key: "totalDownline" },
		{ label: "D/L Turnover", key: "downlineTurnOver" },
		{ label: "D/L V.TurnOver", key: "downlineVTurnOver" },
		{ label: "D/L WinLoss", key: "downlineWinLoss" },
		{ label: "D/L Bonus", key: "downlineBonus" },
		{ label: "D/L Comm", key: "downlineCommission" },
		{ label: "Turnover", key: "ownTurnover" },
		{ label: "V.Turnover", key: "ownVTurnover" },
		{ label: "Win/Lose", key: "ownWinLoss" },
		{ label: "Bonus", key: "ownBonus" },
		{ label: "Commission", key: "ownCommission" },
		{ label: "Net Profit", key: "netProfit" },
		{ label: "Margin", key: "margin" },
	];

	const updateFilteredDatas = (data) => {
		let newFilteredDatas = [];
		let newExportDatas = [];

		data.map((item, index) => {
			let row = item;

			let newFilteredData = {
				username: row.username,
				totalTurnover: row.totalTurnover,
				totalVTurnover: row.totalVTurnover,
				totalWinLoss: row.totalWinLoss,
				totalBonus: row.totalBonus,
				totalCommission: row.totalCommission,
				totalWinLoss_Comm: row.totalWinLoss_Comm,
				totalDownline: row.totalDownline,
				downlineTurnOver: row.downlineTurnOver,
				downlineVTurnOver: row.downlineVTurnOver,
				downlineWinLoss: row.downlineWinLoss,
				downlineBonus: row.downlineBonus,
				downlineCommission: row.downlineCommission,
				ownTurnover: row.ownTurnover,
				ownVTurnover: row.ownVTurnover,
				ownWinLoss: row.ownWinLoss,
				ownBonus: row.ownBonus,
				ownCommission: row.ownCommission,
				netProfit: row.netProfit,
				margin: row.margin,
			};

			newFilteredDatas.push(newFilteredData);

			newExportDatas.push(newFilteredData);
		});

		newExportDatas.push({
			username: "Total",
			totalTurnover: data.reduce(
				(total, data) => total + data.totalTurnover,
				0
			),
			totalVTurnover: data.reduce(
				(total, data) => total + data.totalVTurnover,
				0
			),
			totalWinLoss: data.reduce((total, data) => total + data.totalWinLoss, 0),
			totalBonus: data.reduce((total, data) => total + data.totalBonus, 0),
			totalCommission: data.reduce(
				(total, data) => total + data.totalCommission,
				0
			),
			totalWinLoss_Comm: data.reduce(
				(total, data) => total + data.totalWinLoss_Comm,
				0
			),
			totalDownline: data.reduce(
				(total, data) => total + data.totalDownline,
				0
			),
			downlineTurnOver: data.reduce(
				(total, data) => total + data.downlineTurnOver,
				0
			),
			downlineVTurnOver: data.reduce(
				(total, data) => total + data.downlineVTurnOver,
				0
			),
			downlineWinLoss: data.reduce(
				(total, data) => total + data.downlineWinLoss,
				0
			),
			downlineBonus: data.reduce(
				(total, data) => total + data.downlineBonus,
				0
			),
			downlineCommission: data.reduce(
				(total, data) => total + data.downlineCommission,
				0
			),
			ownTurnover: data.reduce((total, data) => total + data.ownTurnover, 0),
			ownVTurnover: data.reduce((total, data) => total + data.ownVTurnover, 0),
			ownWinLoss: data.reduce((total, data) => total + data.ownWinLoss, 0),
			ownBonus: data.reduce((total, data) => total + data.ownBonus, 0),
			ownCommission: data.reduce(
				(total, data) => total + data.ownCommission,
				0
			),
			netProfit: data.reduce((total, data) => total + data.netProfit, 0),
			margin: data.reduce((total, data) => total + data.margin, 0),
		});
		setExportDatas(newExportDatas);
	};

	return (
		<div>
			<h1 className="page-header">
				{stringIsNullOrEmpty(props.id) && t("WINLOSS_REPORT")}
			</h1>
			<Panel>
				<PanelBody>
					<div className="row">
						<div className="col-lg-3">
							<div className="form-group">
								<label>
									<b>{t("DATE")}</b>
								</label>
								<div
									className="input-group"
									style={{ width: "100%", flexFlow: "nowrap" }}
								>
									<div className="input-group-prepend">
										<span className="input-group-text">
											<i className="fa fa-calendar"></i>
										</span>
									</div>
									<div style={{ width: "80%" }}>
										<DateRangePicker
											containerStyles={{ width: "100%" }}
											startDate={startDate}
											endDate={endDate}
											onApply={handleEvent}
											alwaysShowCalendars={true}
											locale={{ format: "YYYY-MM-DD HH:mm:ss" }}
											timePicker={true}
											timePickerSeconds={true}
											ranges={{
												Today: [moment().startOf("day"), moment().endOf("day")],
												Yesterday: [
													moment().subtract(1, "days").startOf("day"),
													moment().subtract(1, "days").endOf("day"),
												],
												"Last 7 Days": [
													moment().subtract(6, "days").startOf("day"),
													moment().endOf("day"),
												],
												"Last 30 Days": [
													moment().subtract(29, "days").startOf("day"),
													moment().endOf("day"),
												],
												"This Month": [
													moment().startOf("month"),
													moment().endOf("month"),
												],
												"Last Month": [
													moment().subtract(1, "month").startOf("month"),
													moment().subtract(1, "month").endOf("month"),
												],
												"This Year": [
													moment().startOf("year"),
													moment().endOf("year"),
												],
											}}
										>
											<input
												type="text"
												className="form-control"
												value={startDate + " - " + endDate}
											/>
										</DateRangePicker>
									</div>
								</div>
							</div>
						</div>
						<div className="col-lg-3">
							<div className="form-group">
								<label>
									<b>{t("USERNAME")}</b>
								</label>
								<AsyncPaginate
									debounceTimeout={250}
									loadOptions={getMemberList}
									additional={{
										page: 1,
									}}
									value={selectedFilterMember}
									onChange={(e) => {
										setSelectedFilterMember(e);
										setSelectedMember(e.value);
									}}
								/>
							</div>
						</div>
						<div className="col-lg-3">
							<div className="form-group">
								<label>
									<b>{t("GAME_NAME")}</b>
								</label>
								<Select
									styles={_OPTION_STYLES}
									value={productList.filter(
										(product) => product.value == selectedProduct
									)}
									options={productList}
									onChange={(e) => {
										setSelectedProduct(e.value);
									}}
								/>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-lg-4">
							<button
								type="button"
								className="btn btn-primary"
								onClick={() => {
									readReport(selectedMember, false);
								}}
								style={{ marginRight: "10px" }}
							>
								<i className="fas fa-search"></i> {t("SEARCH")}
							</button>
							<CSVLink
								className="btn btn-primary btn-lg"
								data={exportDatas}
								headers={_exportHeaders}
								filename={
									"WinLossReport(" +
									moment().format("YYYY-MM-DD HH:mm:ss") +
									").csv"
								}
								target="_blank"
							>
								<i className="fas fa-file-csv"></i>
							</CSVLink>
						</div>
					</div>
					<hr />
					<div className="row">
						<div className="col-lg-3">
							<div className="form-group">
								<label>
									<b>{t("DATE")}</b>
								</label>
								<div
									className="input-group"
									style={{ width: "100%", flexFlow: "nowrap" }}
								>
									<div className="input-group-prepend">
										<span className="input-group-text">
											<i className="fa fa-calendar"></i>
										</span>
									</div>
									<div style={{ width: "80%" }}>
										<DateRangePicker
											containerStyles={{ width: "100%" }}
											singleDatePicker={true}
											onApply={handleDeleteEvent}
											alwaysShowCalendars={true}
											locale={{ format: "YYYY-MM-DD" }}
										>
											<input
												type="text"
												className="form-control"
												value={deleteDate}
											/>
										</DateRangePicker>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-lg-2">
							<button
								type="button"
								className="btn btn-danger"
								onClick={() => {
									deleteCache();
								}}
							>
								<i className="fas fa-trash-alt"></i> {t("Clear_Cache")}
							</button>
						</div>
					</div>
					<hr />
					<div className="hierarchy-line">{renderHierarchyLine()}</div>
					{reportData.downlineReportList.length != 0 && (
						<div className="table-responsive">
							<table
								className="table table-bordered table-winloss-report"
								ref={_REPORT_REF}
							>
								<thead>
									<tr>
										<th rowSpan="2">{t("USERNAME")}</th>
										<th rowSpan="2">{t("TOTAL_TURNOVER")}</th>
										<th rowSpan="2">{t("TOTAL_VTURNOVER")}</th>
										<th rowSpan="2">{t("TOTAL_WINLOSE")}</th>
										<th rowSpan="2">{t("TOTAL_BONUS")}</th>
										<th rowSpan="2">{t("TOTAL_COMMISSION")}</th>
										<th rowSpan="2">{t("TOTAL_WINLOSS_COMM")}</th>
										<th colSpan="6" style={{ textAlign: "center" }}>
											{t("DOWNLINE")}
										</th>
										<th colSpan="8" style={{ textAlign: "center" }}>
											{t("PERSONAL")}
										</th>
									</tr>
									<tr>
										<th>{t("DOWNLINE")}</th>
										<th>{t("DOWNLINE_TURNOVER")}</th>
										<th>{t("DOWNLINE_VTURNOVER")}</th>
										<th>{t("DOWNLINE_WINLOSE")}</th>
										<th>{t("DOWNLINE_BONUS")}</th>
										<th>{t("DOWNLINE_COMMISSION")}</th>
										<th>{t("TURNOVER")}</th>
										<th>{t("VTURNOVER")}</th>
										<th>{t("WINLOSE")}</th>
										<th>{t("BONUS")}</th>
										<th>{t("COMMISSION")}</th>
										<th>{t("NET_PROFIT")}</th>
										<th>{t("MARGIN")}</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td>
											<span className="text-clickable">
												{renderHierarchyLine()}
											</span>
										</td>
										<td>
											{numberWithCurrencyFormat(
												parseFloat(
													reportData.downlineReportList.reduce(
														(total, data) => total + data.totalTurnover,
														0
													)
												),
												3,
												true
											)}
										</td>
										<td>
											{numberWithCurrencyFormat(
												parseFloat(
													reportData.downlineReportList.reduce(
														(total, data) => total + data.totalVTurnover,
														0
													)
												),
												3,
												true
											)}
										</td>
										<td>
											{numberWithCurrencyFormat(
												parseFloat(
													reportData.downlineReportList.reduce(
														(total, data) => total + data.totalWinLoss,
														0
													)
												),
												3,
												true
											)}
										</td>
										<td>
											{numberWithCurrencyFormat(
												parseFloat(
													reportData.downlineReportList.reduce(
														(total, data) => total + data.totalBonus,
														0
													)
												),
												3,
												true
											)}
										</td>
										<td>
											{numberWithCurrencyFormat(
												parseFloat(
													reportData.downlineReportList.reduce(
														(total, data) => total + data.totalCommission,
														0
													)
												),
												3,
												true
											)}
										</td>
										<td>
											{numberWithCurrencyFormat(
												parseFloat(
													reportData.downlineReportList.reduce(
														(total, data) => total + data.totalWinLoss_Comm,
														0
													)
												),
												3,
												true
											)}
										</td>
										<td>
											{parseFloat(
												reportData.downlineReportList.reduce(
													(total, data) => total + data.totalDownline,
													0
												)
											)}
										</td>
										<td>
											{numberWithCurrencyFormat(
												parseFloat(
													reportData.downlineReportList.reduce(
														(total, data) => total + data.downlineTurnOver,
														0
													)
												),
												3,
												true
											)}
										</td>
										<td>
											{numberWithCurrencyFormat(
												parseFloat(
													reportData.downlineReportList.reduce(
														(total, data) => total + data.downlineVTurnOver,
														0
													)
												),
												3,
												true
											)}
										</td>
										<td>
											{numberWithCurrencyFormat(
												parseFloat(
													reportData.downlineReportList.reduce(
														(total, data) => total + data.downlineWinLoss,
														0
													)
												),
												3,
												true
											)}
										</td>
										<td>
											{numberWithCurrencyFormat(
												parseFloat(
													reportData.downlineReportList.reduce(
														(total, data) => total + data.downlineBonus,
														0
													)
												),
												3,
												true
											)}
										</td>
										<td>
											{numberWithCurrencyFormat(
												parseFloat(
													reportData.downlineReportList.reduce(
														(total, data) => total + data.downlineCommission,
														0
													)
												),
												3,
												true
											)}
										</td>
										<td>
											{numberWithCurrencyFormat(
												parseFloat(
													reportData.downlineReportList.reduce(
														(total, data) => total + data.ownTurnover,
														0
													)
												),
												3,
												true
											)}
										</td>
										<td>
											{numberWithCurrencyFormat(
												parseFloat(
													reportData.downlineReportList.reduce(
														(total, data) => total + data.ownVTurnover,
														0
													)
												),
												3,
												true
											)}
										</td>
										<td>
											{numberWithCurrencyFormat(
												parseFloat(
													reportData.downlineReportList.reduce(
														(total, data) => total + data.ownWinLoss,
														0
													)
												),
												3,
												true
											)}
										</td>
										<td>
											{numberWithCurrencyFormat(
												parseFloat(
													reportData.downlineReportList.reduce(
														(total, data) => total + data.ownBonus,
														0
													)
												),
												3,
												true
											)}
										</td>
										<td>
											{numberWithCurrencyFormat(
												parseFloat(
													reportData.downlineReportList.reduce(
														(total, data) => total + data.ownCommission,
														0
													)
												),
												3,
												true
											)}
										</td>
										<td>
											{numberWithCurrencyFormat(
												parseFloat(
													reportData.downlineReportList.reduce(
														(total, data) => total + data.netProfit,
														0
													)
												),
												3,
												true
											)}
										</td>
										<td>
											{numberWithCurrencyFormat(
												parseFloat(
													reportData.downlineReportList.reduce(
														(total, data) => total + data.margin,
														0
													)
												),
												3,
												true
											)}
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					)}

					{reportData.downlineReportList.length != 0 && (
						<div className="table-responsive">
							<table
								className="table table-bordered table-winloss-report"
								ref={_DOWNLINE_REPORT_REF}
							>
								<thead>
									<tr>
										<th rowSpan="2">{t("USERNAME")}</th>
										<th rowSpan="2">{t("TOTAL_TURNOVER")}</th>
										<th rowSpan="2">{t("TOTAL_VTURNOVER")}</th>
										<th rowSpan="2">{t("TOTAL_WINLOSE")}</th>
										<th rowSpan="2">{t("TOTAL_BONUS")}</th>
										<th rowSpan="2">{t("TOTAL_COMMISSION")}</th>
										<th rowSpan="2">{t("TOTAL_WINLOSS_COMM")}</th>
										<th
											className="shade-2"
											colSpan="6"
											style={{ textAlign: "center" }}
										>
											{t("DOWNLINE")}
										</th>
										<th colSpan="8" style={{ textAlign: "center" }}>
											{t("PERSONAL")}
										</th>
									</tr>
									<tr>
										<th>{t("DOWNLINE")}</th>
										<th>{t("DOWNLINE_TURNOVER")}</th>
										<th>{t("DOWNLINE_VTURNOVER")}</th>
										<th>{t("DOWNLINE_WINLOSE")}</th>
										<th>{t("DOWNLINE_BONUS")}</th>
										<th>{t("DOWNLINE_COMMISSION")}</th>
										<th>{t("TURNOVER")}</th>
										<th>{t("VTURNOVER")}</th>
										<th>{t("WINLOSE")}</th>
										<th>{t("BONUS")}</th>
										<th>{t("COMMISSION")}</th>
										<th>{t("NET_PROFIT")}</th>
										<th>{t("MARGIN")}</th>
									</tr>
								</thead>
								<tbody>
									{reportData.downlineReportList.map((item, index) => {
										return (
											<tr key={index}>
												<td>
													<span
														className="text-clickable"
														onClick={() => {
															readReport(item["userId"]);
														}}
													>
														{item["username"]}
													</span>
												</td>
												<td>
													{numberWithCurrencyFormat(
														parseFloat(item["totalTurnover"]),
														3,
														true
													)}
												</td>
												<td>
													{numberWithCurrencyFormat(
														parseFloat(item["totalVTurnover"]),
														3,
														true
													)}
												</td>
												<td>
													{numberWithCurrencyFormat(
														parseFloat(item["totalWinLoss"]),
														3,
														true
													)}
												</td>
												<td>
													{numberWithCurrencyFormat(
														parseFloat(item["totalBonus"]),
														3,
														true
													)}
												</td>
												<td>
													{numberWithCurrencyFormat(
														parseFloat(item["totalCommission"]),
														3,
														true
													)}
												</td>
												<td>
													{numberWithCurrencyFormat(
														parseFloat(item["totalWinLoss_Comm"]),
														3,
														true
													)}
												</td>
												<td>{item["totalDownline"]}</td>
												<td>
													{numberWithCurrencyFormat(
														parseFloat(item["downlineTurnOver"]),
														3,
														true
													)}
												</td>
												<td>
													{numberWithCurrencyFormat(
														parseFloat(item["downlineVTurnOver"]),
														3,
														true
													)}
												</td>
												<td>
													{numberWithCurrencyFormat(
														parseFloat(item["downlineWinLoss"]),
														3,
														true
													)}
												</td>
												<td>
													{numberWithCurrencyFormat(
														parseFloat(item["downlineBonus"]),
														3,
														true
													)}
												</td>
												<td>
													{numberWithCurrencyFormat(
														parseFloat(item["downlineCommission"]),
														3,
														true
													)}
												</td>
												<td>
													<span
														className="text-clickable"
														onClick={() => {
															viewGameRecord(item["userId"], "1");
														}}
													>
														{numberWithCurrencyFormat(
															parseFloat(item["ownTurnover"]),
															3,
															true
														)}
													</span>
												</td>
												<td>
													<span
														className="text-clickable"
														onClick={() => {
															viewGameRecord(item["userId"], "1");
														}}
													>
														{numberWithCurrencyFormat(
															parseFloat(item["ownVTurnover"]),
															3,
															true
														)}
													</span>
												</td>
												<td>
													{numberWithCurrencyFormat(
														parseFloat(item["ownWinLoss"]),
														3,
														true
													)}
												</td>
												<td>
													{numberWithCurrencyFormat(
														parseFloat(item["ownBonus"]),
														3,
														true
													)}
												</td>
												<td>
													{numberWithCurrencyFormat(
														parseFloat(item["ownCommission"]),
														3,
														true
													)}
												</td>
												<td>
													{numberWithCurrencyFormat(
														parseFloat(item["netProfit"]),
														3,
														true
													)}
												</td>
												<td>
													{numberWithCurrencyFormat(
														parseFloat(item["margin"]),
														3,
														true
													)}
												</td>
											</tr>
										);
									})}
									<tr></tr>
								</tbody>
								<tfoot>
									<tr>
										<td>
											<b>{t("TOTAL")}</b>
										</td>
										<td>
											<b>
												{numberWithCurrencyFormat(
													parseFloat(
														reportData.downlineReportList.reduce(
															(total, data) => total + data.totalTurnover,
															0
														)
													),
													3,
													true
												)}
											</b>
										</td>
										<td>
											<b>
												{numberWithCurrencyFormat(
													parseFloat(
														reportData.downlineReportList.reduce(
															(total, data) => total + data.totalVTurnover,
															0
														)
													),
													3,
													true
												)}
											</b>
										</td>
										<td>
											<b>
												{numberWithCurrencyFormat(
													parseFloat(
														reportData.downlineReportList.reduce(
															(total, data) => total + data.totalWinLoss,
															0
														)
													),
													3,
													true
												)}
											</b>
										</td>
										<td>
											<b>
												{numberWithCurrencyFormat(
													parseFloat(
														reportData.downlineReportList.reduce(
															(total, data) => total + data.totalBonus,
															0
														)
													),
													3,
													true
												)}
											</b>
										</td>
										<td>
											<b>
												{numberWithCurrencyFormat(
													parseFloat(
														reportData.downlineReportList.reduce(
															(total, data) => total + data.totalCommission,
															0
														)
													),
													3,
													true
												)}
											</b>
										</td>
										<td>
											<b>
												{numberWithCurrencyFormat(
													parseFloat(
														reportData.downlineReportList.reduce(
															(total, data) => total + data.totalWinLoss_Comm,
															0
														)
													),
													3,
													true
												)}
											</b>
										</td>
										<td>
											<b>
												{parseFloat(
													reportData.downlineReportList.reduce(
														(total, data) => total + data.totalDownline,
														0
													)
												)}
											</b>
										</td>
										<td>
											<b>
												{numberWithCurrencyFormat(
													parseFloat(
														reportData.downlineReportList.reduce(
															(total, data) => total + data.downlineTurnOver,
															0
														)
													),
													3,
													true
												)}
											</b>
										</td>
										<td>
											<b>
												{numberWithCurrencyFormat(
													parseFloat(
														reportData.downlineReportList.reduce(
															(total, data) => total + data.downlineVTurnOver,
															0
														)
													),
													3,
													true
												)}
											</b>
										</td>
										<td>
											<b>
												{numberWithCurrencyFormat(
													parseFloat(
														reportData.downlineReportList.reduce(
															(total, data) => total + data.downlineWinLoss,
															0
														)
													),
													3,
													true
												)}
											</b>
										</td>
										<td>
											<b>
												{numberWithCurrencyFormat(
													parseFloat(
														reportData.downlineReportList.reduce(
															(total, data) => total + data.downlineBonus,
															0
														)
													),
													3,
													true
												)}
											</b>
										</td>
										<td>
											<b>
												{numberWithCurrencyFormat(
													parseFloat(
														reportData.downlineReportList.reduce(
															(total, data) => total + data.downlineCommission,
															0
														)
													),
													3,
													true
												)}
											</b>
										</td>
										<td>
											<b>
												{numberWithCurrencyFormat(
													parseFloat(
														reportData.downlineReportList.reduce(
															(total, data) => total + data.ownTurnover,
															0
														)
													),
													3,
													true
												)}
											</b>
										</td>
										<td>
											<b>
												{numberWithCurrencyFormat(
													parseFloat(
														reportData.downlineReportList.reduce(
															(total, data) => total + data.ownVTurnover,
															0
														)
													),
													3,
													true
												)}
											</b>
										</td>
										<td>
											<b>
												{numberWithCurrencyFormat(
													parseFloat(
														reportData.downlineReportList.reduce(
															(total, data) => total + data.ownWinLoss,
															0
														)
													),
													3,
													true
												)}
											</b>
										</td>
										<td>
											<b>
												{numberWithCurrencyFormat(
													parseFloat(
														reportData.downlineReportList.reduce(
															(total, data) => total + data.ownBonus,
															0
														)
													),
													3,
													true
												)}
											</b>
										</td>
										<td>
											<b>
												{numberWithCurrencyFormat(
													parseFloat(
														reportData.downlineReportList.reduce(
															(total, data) => total + data.ownCommission,
															0
														)
													),
													3,
													true
												)}
											</b>
										</td>
										<td>
											<b>
												{numberWithCurrencyFormat(
													parseFloat(
														reportData.downlineReportList.reduce(
															(total, data) => total + data.netProfit,
															0
														)
													),
													3,
													true
												)}
											</b>
										</td>
										<td>
											<b>
												{numberWithCurrencyFormat(
													parseFloat(
														reportData.downlineReportList.reduce(
															(total, data) => total + data.margin,
															0
														)
													),
													3,
													true
												)}
											</b>
										</td>
									</tr>
								</tfoot>
							</table>
						</div>
					)}
				</PanelBody>
			</Panel>
			<Modal
				isOpen={showGameRecord}
				toggle={() => {
					setShowGameRecord(false);
					setTicketStatus("1");
				}}
				style={{ paddingBottom: "100px" }}
				className="modal-lg"
				keyboard={true}
			>
				<ModalHeader
					toggle={() => {
						setShowGameRecord(false);
						setTicketStatus("1");
					}}
				>
					{t("SUMMARY")}
				</ModalHeader>
				<ModalBody>
					<div className="row">
						<div className="col-lg-3">
							<div className="form-group mb-0">
								<label>
									<b>{t("TICKET_STATUS")}</b>
								</label>
								<Select
									options={_TICKET_STATUS}
									placeholder={""}
									value={_TICKET_STATUS.filter(
										(x) => x["value"] == ticketStatus
									)}
									onChange={(e) => {
										setTicketStatus(e.value);
										viewGameRecord(ticketViewingMember, e.value);
									}}
								/>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-lg-12">
							<div className="form-group table-selfwin-summary">
								<ReactTable
									initialPageSize={20}
									columns={_gameRecordColumns}
									getOverallInfo={getOverallInfo}
									fetchUrl={gameDetailUrl}
									exportRequired={true}
									renderFooter={true}
								/>
							</div>
						</div>
					</div>
				</ModalBody>
				<ModalFooter>
					<button
						type="button"
						className="btn btn btn-inverse-brand"
						onClick={() => {
							setShowGameRecord(false);
							setTicketStatus("1");
						}}
					>
						{t("CLOSE")}
					</button>
				</ModalFooter>
			</Modal>
		</div>
	);
};

export default ManageWinLossReport3;
