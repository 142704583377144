import React from 'react';
import ReactTable from "../../components/constantComponent/reactTable/ReactTable";
import { Panel, PanelBody } from "./../../components/panel/panel.jsx";
import { useTranslation } from "react-i18next";
import { ApiKey, ApiUrl, WebUrl, LanguageKey } from "../../util/Constant";
import { useEffect } from 'react';
import ApiEngine from '../../util/ApiEngine';
import { useState } from 'react';
import { numberWithCurrencyFormat, stringIsNullOrEmpty } from '../../util/Util';
import moment from 'moment';
import Select from "react-select";
import DateRangePicker from "react-bootstrap-daterangepicker";

const SportsTeamBetReport = () => {
  const { t, i18n } = useTranslation(LanguageKey._PRIMARY);
  const [betData, setBetData] = useState([]);
  const [leagueList, setLeagueList] = useState([]);
  const [selectedLeague, setSelectedLeague] = useState("");
  const [doneInit, setDoneInit] = useState(false);
  const [selectedDate, setSelectedDate] = useState(moment().format('YYYY-MM-DD'));
  const [countdownPeriod, setCountdownPeriod] = useState(0);
  const [intervalId, setIntervalId] = useState();

  const _GET_DATA_INTERVAL_IN_MS = 90000;
  const _SECONDS_IN_MS = 1000;

  /// <summary>
  /// Author : -
  /// </summary>
  const _OPTION_STYLES = {
    control: (base) => ({
      ...base,
      "min-height": "34px",
      height: "34px",
    }),
    valueContainer: (base) => ({
      ...base,
      height: "34px",
      "min-height": "34px",
    }),
    indicatorsContainer: (base) => ({
      ...base,
      height: "34px",
      "min-height": "34px",
    }),
    input: (base) => ({
      height: "34px",
      "min-height": "34px",
    }),
  };

  const _tableColumns = [
    {
      id: "league",
      Header: t("LEAGUE"),
      accessor: "league",
      Cell: ({ row }) => {
        return (<span>{row.original.league}</span>)
      }
    },
    {
      id: "match",
      Header: t("MATCH"),
      accessor: "match",
      Cell: ({ row }) => {
        return (<span>{row.original.match}</span>)
      }
    },
    {
      id: "matchDate",
      Header: t("MATCH_DATE"),
      accessor: "matchDate",
      Cell: ({ row }) => {
        return (<span>{moment(row.original.matchDate).format('YYYY-MM-DD HH:mm:ss')}</span>)
      }
    },
    {
      id: "matchTotalBet",
      Header: t("MATCH_TOTAL_BET") + " (RM)",
      accessor: "matchTotalBet",
      Cell: ({ row }) => {
        return (<span>{numberWithCurrencyFormat(row.original.matchTotalBet, 2, true)}</span>)
      }
    },
    {
      id: "matchHTHdpTotal",
      Header: t("MATCH_HT_HDP_TOTAL") + " (RM)",
      accessor: "matchHTHdpTotal",
      Cell: ({ row }) => {
        return (<span>{numberWithCurrencyFormat(row.original.matchHTHdpTotal, 2, true)}</span>)
      }
    },
    {
      id: "matchFTHdpTotal",
      Header: t("MATCH_FT_HDP_TOTAL") + " (RM)",
      accessor: "matchFTHdpTotal",
      Cell: ({ row }) => {
        return (<span>{numberWithCurrencyFormat(row.original.matchFTHdpTotal, 2, true)}</span>)
      }
    },
    {
      id: "matchHTOUTotal",
      Header: t("MATCH_HT_OU_TOTAL") + " (RM)",
      accessor: "matchHTOUTotal",
      Cell: ({ row }) => {
        return (<span>{numberWithCurrencyFormat(row.original.matchHTOUTotal, 2, true)}</span>)
      }
    },
    {
      id: "matchFTOUTotal",
      Header: t("MATCH_FT_OU_TOTAL") + " (RM)",
      accessor: "matchFTOUTotal",
      Cell: ({ row }) => {
        return (<span>{numberWithCurrencyFormat(row.original.matchFTOUTotal, 2, true)}</span>)
      }
    },
    {
      id: "team1",
      Header: t("TEAM_1"),
      accessor: "team1",
      Cell: ({ row }) => {
        return (<span>{row.original.team1}</span>)
      }
    },
    {
      id: "team1TotalBet",
      Header: t("TEAM_1_TOTAL_BET"),
      accessor: "team1TotalBet",
      Cell: ({ row }) => {
        return (<span>{numberWithCurrencyFormat(row.original.team1TotalBet)}</span>)
      }
    },
    {
      id: "team1BetCount",
      Header: t("TEAM_1_BET_COUNT"),
      accessor: "team1BetCount",
      Cell: ({ row }) => {
        return (<span>{row.original.team1BetCount}</span>)
      }
    },
    {
      id: "team2",
      Header: t("TEAM_2"),
      accessor: "team2",
      Cell: ({ row }) => {
        return (<span>{row.original.team2}</span>)
      }
    },
    {
      id: "team2TotalBet",
      Header: t("TEAM_2_TOTAL_BET"),
      accessor: "team2TotalBet",
      Cell: ({ row }) => {
        return (<span>{numberWithCurrencyFormat(row.original.team2TotalBet)}</span>)
      }
    },
    {
      id: "team2BetCount",
      Header: t("TEAM_2_BET_COUNT"),
      accessor: "team2BetCount",
      Cell: ({ row }) => {
        return (<span>{row.original.team2BetCount}</span>)
      }
    }
  ];

  useEffect(() => {
    init(true);
  }, []);

  /// <summary>
  /// Author:
  /// </summary>
  useEffect(() => {
    if (countdownPeriod > 0) {
      setTimeout(() => {
        let tempPeriod = countdownPeriod - 1;
        setCountdownPeriod(tempPeriod);
      }, _SECONDS_IN_MS);
    }
    else if (doneInit) {
      init(false);
    }
  }, [countdownPeriod]);

  const init = async (isInit) => {
    setDoneInit(false);
    let apiUrl = ApiUrl._API_GET_SPORTS_BET_REPORT + "?league=" + selectedLeague + "&date=" + selectedDate;
    fetch(apiUrl, {
      method: ApiKey._API_GET,
      headers: {
        "Accept": ApiKey._API_APPLICATION_JSON,
        "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY
      }
    }).then((response) => response.json()).then((responseJson) => {
      if (responseJson[ApiKey._API_SUCCESS_KEY]) {
        let data = responseJson[ApiKey._API_DATA_KEY];
        if (data != null) {
          setBetData(data["data"]);
          if (isInit) {
            let tempLeagueList = [];
            tempLeagueList.push({ label: "All", value: "" });
            data["leagueList"].map((leagueName, index) => {
              tempLeagueList.push({ label: leagueName, value: leagueName });
            });
            setLeagueList(tempLeagueList);
          }
        }
        setDoneInit(true);
        setCountdownPeriod(_GET_DATA_INTERVAL_IN_MS / _SECONDS_IN_MS);
      }
    });
  }

  /// <summary>
  /// Author : -
  /// </summary>
  const handleEvent = (event, picker) => {
    setSelectedDate(moment(picker.startDate).format("YYYY-MM-DD"));
  };

  return (
    <div id="sports-bet-report">
      <h1 className="page-header">{t("SPORTS_TEAM_BET_REPORT")}</h1>
      <Panel>
        <PanelBody>
          <div className="row" style={{ alignItems: "flex-start" }}>
            <div className="col-lg-3">
              <div className="form-group">
                <label>
                  <b>{t("LEAGUE")}</b>
                </label>
                <Select
                  styles={_OPTION_STYLES}
                  value={leagueList.filter(
                    (name) => name.value == selectedLeague
                  )}
                  options={leagueList}
                  onChange={(e) => {
                    setSelectedLeague(e.value);
                  }}
                />
              </div>
            </div>
            <div className="col-lg-3">
              <div className="form-group">
                <label>
                  <b>{t("DATE")}</b>
                </label>
                <DateRangePicker
                  containerStyles={{ width: "100%" }}
                  singleDatePicker={true}
                  maxDate={moment()}
                  onApply={handleEvent}
                  alwaysShowCalendars={true}
                  locale={{ format: "YYYY-MM-DD" }}
                >
                  <input
                    type="text"
                    className="form-control"
                    value={selectedDate}
                  />
                </DateRangePicker>
              </div>
            </div>
            <div className="col-lg-2" style={{ marginTop: "25px" }}>
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => {
                  init(false);
                }}
              >
                <i className="fas fa-search"></i> {t("SEARCH")}
              </button>
            </div>
            {!doneInit && <div className="col-lg-2" style={{ marginTop: "25px" }}>{countdownPeriod > 0 && <>{countdownPeriod} {t("SECOND")}<br /></>}{t("LOADING_NEW_DATA")}<br />{t("MAY_TAKE_AWHILE_DUE_TO_MANY_TICKETS")}</div>}
          </div>
          {<>{betData.length > 0 ? <div className="row">
            <div class="col-lg-12 text-right mb-3">{doneInit && t("AMOUNT_FROM_PENDING_BETS_ONLY")}</div>
            <div className="col-lg-12">
              {<table>
                <thead>
                  <tr>
                    <th className="border-lightgrey-1 table-cell-padding-1" rowspan="3" style={{ verticalAlign: "center" }}>{t("LEAGUE")}</th>
                    <th className="border-lightgrey-1 table-cell-padding-1 text-align-center" colspan="12">{t("MATCH")}</th>
                  </tr>
                  <tr>
                    <th rowSpan="2" className="border-lightgrey-1 table-cell-padding-1">{t("MATCH_NAME")}</th>
                    <th rowSpan="2" className="border-lightgrey-1 table-cell-padding-1" style={{ minWidth: "100px" }}>{t("MATCH_DATE")}</th>
                    <th rowSpan="2" className="border-lightgrey-1 table-cell-padding-1 text-align-right">{t("TOTAL_BET") + " (RM)"}</th>
                    <th colSpan="2" className="border-lightgrey-1 background-lightgrey-1 table-cell-padding-1 text-align-center">{t("FT")}</th>
                    <th colSpan="2" className="border-lightgrey-1 table-cell-padding-1 text-align-center">{t("HT")}</th>
                    <th colspan="2" className="border-lightgrey-1 table-cell-padding-1 text-align-center">{t("HOME")}</th>
                    <th colspan="2" className="border-lightgrey-1 table-cell-padding-1 text-align-center">{t("AWAY")}</th>
                    <th rowSpan="2" className="border-lightgrey-1 table-cell-padding-1">{<>{t("OU_TOTAL")}<br />{"(" + t("FT") + " + " + t("HT") + ")"}</>}</th>
                  </tr>
                  <tr>
                    <th className="border-lightgrey-1 background-lightgrey-1 table-cell-padding-1 text-align-right">{t("HDP_TOTAL") + " (RM)"}</th>
                    <th className="border-lightgrey-1 background-lightgrey-1 table-cell-padding-1 text-align-right">{t("OU_TOTAL") + " (RM)"}</th>
                    <th className="border-lightgrey-1 table-cell-padding-1 text-align-right">{t("HDP_TOTAL") + " (RM)"}</th>
                    <th className="border-lightgrey-1 table-cell-padding-1 text-align-right">{t("OU_TOTAL") + " (RM)"}</th>
                    <th className="border-lightgrey-1 table-cell-padding-1 text-align-right">{t("TOTAL_BET") + " (RM)"}</th>
                    <th className="border-lightgrey-1 table-cell-padding-1">{t("BET_COUNT")}</th>
                    <th className="border-lightgrey-1 table-cell-padding-1 text-align-right">{t("TOTAL_BET") + " (RM)"}</th>
                    <th className="border-lightgrey-1 table-cell-padding-1">{t("BET_COUNT")}</th>
                  </tr>
                </thead>
                <tbody>
                  {betData.map((row, index) => {
                    let isStartWithStar = row.league.startsWith("*");
                    let isLiveBets = row.league.endsWith("(Live Bets)") ? " " : "";
                    return (
                      <tr className={isStartWithStar ? "background-color-" + (isLiveBets ? "dark-blue" : "red") : isLiveBets ? "background-color-light-blue" : ""}>
                        <td className="border-lightgrey-1 table-cell-padding-1">{row.league}</td>
                        <td className="border-lightgrey-1 table-cell-padding-1">{row.team1}<span className="font-bold">{" (" + t("HOME") + ")"}</span> vs {row.team2}<span className="font-bold">{" (" + t("AWAY") + ")"}</span></td>
                        <td className="border-lightgrey-1 table-cell-padding-1">{row.matchDate}</td>
                        <td className="border-lightgrey-1 table-cell-padding-1 text-align-right">{numberWithCurrencyFormat(row.matchTotalBet, 2, true)}</td>
                        <td className={"border-lightgrey-1 " + (isStartWithStar ? "background-color-" + (isLiveBets ? "dark-blue" : "red") : isLiveBets ? " background-color-light-blue" : "background-lightgrey-1") + " table-cell-padding-1 text-align-right" + (row.matchFTHdpTotal < 0 ? " text-red" : "")}>{numberWithCurrencyFormat(row.matchFTHdpTotal, 2, true)}</td>
                        <td className={"border-lightgrey-1 " + (isStartWithStar ? "background-color-" + (isLiveBets ? "dark-blue" : "red") : isLiveBets ? " background-color-light-blue" : "background-lightgrey-1") + " table-cell-padding-1 text-align-right" + (row.matchFTOUTotal < 0 ? " text-red" : "")}>{numberWithCurrencyFormat(row.matchFTOUTotal, 2, true)}</td>
                        <td className={"border-lightgrey-1 table-cell-padding-1 text-align-right" + (row.matchHTHdpTotal < 0 ? " text-red" : "")}>{numberWithCurrencyFormat(row.matchHTHdpTotal, 2, true)}</td>
                        <td className={"border-lightgrey-1 table-cell-padding-1 text-align-right" + (row.matchHTOUTotal < 0 ? " text-red" : "")}>{numberWithCurrencyFormat(row.matchHTOUTotal, 2, true)}</td>
                        <td className="border-lightgrey-1 table-cell-padding-1 text-align-right">{numberWithCurrencyFormat(row.team1TotalBet, 2, true)}</td>
                        <td className="border-lightgrey-1 table-cell-padding-1">{row.team1BetCount}</td>
                        <td className="border-lightgrey-1 table-cell-padding-1 text-align-right">{numberWithCurrencyFormat(row.team2TotalBet, 2, true)}</td>
                        <td className="border-lightgrey-1 table-cell-padding-1">{row.team2BetCount}</td>
                        <td className={"border-lightgrey-1 table-cell-padding-1" + (parseFloat(row.matchFTOUTotal) + parseFloat(row.matchHTOUTotal) < 0 ? " text-red" : "")}>{numberWithCurrencyFormat(parseFloat(row.matchFTOUTotal) + parseFloat(row.matchHTOUTotal), 2, true)}</td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>}
              {/*<ReactTable data={betData} columns={_tableColumns} />*/}
            </div>
          </div> :
            <tr>
              <td colSpan="100%" style={{ textAlign: "center" }}>
                {t("NO_DATA_FOUND")}
              </td>
            </tr>}</>}
        </PanelBody>
      </Panel>
    </div>
  )
}

export default SportsTeamBetReport;