import React, { useState, useEffect } from "react";
import ReactTable from "../../components/constantComponent/reactTable/ReactTable";
import { Panel, PanelBody } from "./../../components/panel/panel.jsx";
import {
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { navigateTo } from "../../util/Util";
import { useLocation, useHistory } from "react-router-dom";
import {
  ApiKey,
  WebUrl,
  ApiUrl,
  AccessRight,
  LanguageKey,
} from "../../util/Constant";
import { checkIfPermissionExist } from "../../util/PermissionChecker";
import { useDispatch } from "react-redux";
import { setBusy, setIdle, showMessage } from "../../redux/AppAction";
import { useTranslation } from "react-i18next";
import { fetch } from "whatwg-fetch";

/// <summary>
/// Author : -
/// </summary>
const ManageGame = (props) => {
  const { t } = useTranslation(LanguageKey._PRIMARY);
  let _history = useHistory();
  const _location = useLocation();
  const _dispatch = useDispatch();

  const [submitError, setSubmitError] = useState("");
  const [submitSuccess, setSubmitSuccess] = useState("");
  const [gameData, setGameData] = useState([]);
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const mpId = params.get('id');

  let _tableColumns = [
    {
      Header: "NAME",
      accessor: "gameName",
    },
    {
      Header: "STATUS",
      accessor: "status",
      Cell: ({ row }) => (
        <div className="btn-group m-r-5 m-b-5">
          {row.original.status == 1 ? (
            <span className="badge badge-secondary badge-green">
              {t("ACTIVE")}
            </span>
          ) : (
            <span className="badge badge-secondary badge-danger">
              {t("INACTIVE")}
            </span>
          )}
        </div>
      ),
      disableSortBy: true,
      style: { overflow: "visible" },
    }
  ];

  /// <summary>
  /// Author : -
  /// </summary>
  useEffect(() => {
    readGame();
  }, []);

  /// <summary>
  /// Author : -
  /// </summary>
  function readGame() {
    fetch(
      ApiUrl._API_GET_PRODUCT_GAME + "?productId=" + mpId,
      {
        method: ApiKey._API_GET,
        headers: {
          "Content-Type": ApiKey._API_FORM_URLENCODED,
          "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
        },
      }
    )
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
          setGameData(responseJson[ApiKey._API_DATA_KEY]);
        }
      });
  }

  return (
    <div>
      <h1 className="page-header">
        {t("GAME")}
      </h1>
      <Panel>
        <PanelBody>
          <ReactTable filterable data={gameData} columns={_tableColumns} />
        </PanelBody>
      </Panel>
    </div>
  );
};

export default ManageGame;
