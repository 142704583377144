import React, { useState, useEffect } from "react";
import ReactTable from "../../components/constantComponent/reactTable/ReactTable";
import { Panel, PanelBody } from "./../../components/panel/panel.jsx";
import {
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { navigateTo } from "../../util/Util";
import { useLocation, useHistory } from "react-router-dom";
import { ApiKey, WebUrl, ApiUrl, LanguageKey } from "../../util/Constant";
import { useTranslation } from "react-i18next";
import { fetch } from "whatwg-fetch";

/// <summary>
/// Author : -
/// </summary>
const ManageGame = (props) => {
  const { t } = useTranslation(LanguageKey._PRIMARY);
  let _history = useHistory();
  const _location = useLocation();
  const [gameData, setGameData] = useState([]);

  let _tableColumns = [
    {
      Header: "NAME",
      accessor: "gameName",
    },
    {
      Header: "RANK",
      accessor: "rank",
    },
    {
      Header: "IS_RECOMMENDED",
      accessor: "isRecommended",
      Cell: ({ row }) => (
        <div className="btn-group m-r-5 m-b-5">
          {row.original.isRecommended == 1 ? (
            <span className="badge badge-secondary badge-green">
              {t("RECOMMENDED")}
            </span>
          ) : (
            <span className="badge badge-secondary badge-warning">
              {t("NORMAL")}
            </span>
          )}
        </div>
      ),
      disableSortBy: true,
      style: { overflow: "visible" },
    },
    {
      id: "action",
      Header: "",
      Cell: ({ row }) => (
        <div className="btn-group m-r-5 m-b-5">
          <UncontrolledButtonDropdown>
            <DropdownToggle caret color="default">
              <i className="fas fa-cog"></i>
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem
                onClick={() => {
                  navigateTo(
                    _history,
                    WebUrl._URL_CREATE_OR_UPDATE_PRODUCT_GAME_SETTING,
                    {
                      id: row.original.id,
                      accountProductId: _location.state["id"],
                    }
                  );
                }}
              >
                <span className="text-warning">{t("EDIT")}</span>
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledButtonDropdown>
        </div>
      ),
      disableSortBy: true,
      disableFilters: true,
      width: 100,
      style: { overflow: "visible" },
    },
  ];

  /// <summary>
  /// Author : -
  /// </summary>
  useEffect(() => {
    readSetting();
  }, []);

  /// <summary>
  /// Author : -
  /// </summary>
  function readSetting() {
    fetch(
      ApiUrl._API_GET_PRODUCT_GAME_SETTING +
        "?accountProductId=" +
        _location.state["id"],
      {
        method: ApiKey._API_GET,
        headers: {
          "Content-Type": ApiKey._API_FORM_URLENCODED,
          "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
        },
      }
    )
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
          setGameData(responseJson[ApiKey._API_DATA_KEY]);
        }
      });
  }

  return (
    <div>
      <h1 className="page-header">
        {t("GAME_SETTING")}
        <button
          type="button"
          className="btn btn-primary"
          style={{ float: "right" }}
          onClick={() => {
            _history.push({
              pathname: WebUrl._URL_CREATE_OR_UPDATE_PRODUCT_GAME_SETTING,
              state: { accountProductId: _location.state["id"] },
            });
          }}
        >
          <i className="fa fa-plus-circle m-r-10"></i>
          {t("ADD_NEW_GAME_SETTING")}
        </button>
      </h1>
      <Panel>
        <PanelBody>
          <ReactTable filterable data={gameData} columns={_tableColumns} />
        </PanelBody>
      </Panel>
    </div>
  );
};

export default ManageGame;
