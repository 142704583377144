import React, { useState, useEffect } from "react";
import { Panel, PanelBody } from "./../../../components/panel/panel.jsx";
import { ApiKey, ApiUrl, LanguageKey } from "../../../util/Constant";
import { useForm } from "react-hook-form";
import { stringIsNullOrEmpty, createFormBody } from "../../../util/Util";
import { setBusy, setIdle, showMessage } from "../../../redux/AppAction";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { fetch } from "whatwg-fetch";

const SmsSettings = (props) => {
  const { t } = useTranslation(LanguageKey._PRIMARY);
  const _dispatch = useDispatch();
  const { register, handleSubmit, errors } = useForm();

  const [url, setUrl] = useState("");
  const [username, setUsername] = useState("");
  const [systemName, setSystemName] = useState("");
  const [password, setPassword] = useState("");
  const [settingId, setSettingId] = useState("");

  /// <summary>
  /// Author : -
  /// </summary>
  useEffect(() => {
    fetch(ApiUrl._API_GET_USER_SMS_SETTING, {
      method: ApiKey._API_GET,
      headers: {
        "Content-Type": ApiKey._API_FORM_URLENCODED,
        "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (
          responseJson[ApiKey._API_SUCCESS_KEY] &&
          responseJson[ApiKey._API_DATA_KEY]["id"] != 0
        ) {
          setUrl(responseJson[ApiKey._API_DATA_KEY]["url"]);
          setUsername(responseJson[ApiKey._API_DATA_KEY]["systemUsername"]);
          setSystemName(responseJson[ApiKey._API_DATA_KEY]["systemName"]);
          setPassword(responseJson[ApiKey._API_DATA_KEY]["systemPassword"]);
          setSettingId(responseJson[ApiKey._API_DATA_KEY]["id"]);
        }
      });
  }, []);

  /// <summary>
  /// Author : -
  /// </summary>
  const submitForm = async (data, e) => {
    let params = {
      systemPassword: data.password,
      systemUsername: data.username,
      systemName: data.systemName,
      url: data.url,
    };

    if (!stringIsNullOrEmpty(settingId)) {
      params["id"] = settingId;
    }

    let formBody = createFormBody(params);
    _dispatch(setBusy());

    fetch(ApiUrl._API_CREATE_OR_UPDATE_USER_SMS_SETTING, {
      method: ApiKey._API_POST,
      headers: {
        "Content-Type": ApiKey._API_FORM_URLENCODED,
        "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
      },
      body: formBody,
    })
      .then((response) => response.json())
      .then((responseJson) => {
        _dispatch(setIdle());
        _dispatch(
          showMessage(
            responseJson[ApiKey._API_SUCCESS_KEY],
            t(responseJson[ApiKey._API_MESSAGE_KEY])
          )
        );

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
          setSettingId(responseJson[ApiKey._API_DATA_KEY]["id"]);
        }
      });
  };

  return (
    <div>
      <h1 className="page-header">{t("SMS_SETTINGS")}</h1>
      <div className="row">
        <div className="col-xl-12">
          <Panel>
            <PanelBody>
              <form onSubmit={handleSubmit(submitForm)}>
                <div className="row">
                  <div className="col-lg-8">
                    <div className="form-group row m-b-15">
                      <label className="col-md-4 col-form-label">
                        <b>{t("SYSTEM_NAME")}</b>
                      </label>
                      <div className="col-md-7">
                        <input
                          type="text"
                          className="form-control"
                          placeholder={t("PLEASE_ENTER_SYSTEM_NAME")}
                          defaultValue={systemName}
                          ref={register({
                            required: "PLEASE_ENTER_SYSTEM_NAME",
                          })}
                          name="systemName"
                        />
                        {errors.systemName && (
                          <div className="invalid-feedback">
                            {t(errors.systemName.message)}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-8">
                    <div className="form-group row m-b-15">
                      <label className="col-md-4 col-form-label">
                        <b>{t("USERNAME")}</b>
                      </label>
                      <div className="col-md-7">
                        <input
                          type="text"
                          className="form-control"
                          placeholder={t("PLEASE_ENTER_USERNAME")}
                          defaultValue={username}
                          ref={register({ required: "PLEASE_ENTER_USERNAME" })}
                          name="username"
                        />
                        {errors.username && (
                          <div className="invalid-feedback">
                            {t(errors.username.message)}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-8">
                    <div className="form-group row m-b-15">
                      <label className="col-md-4 col-form-label">
                        <b>{t("PASSWORD")}</b>
                      </label>
                      <div className="col-md-7">
                        <input
                          type="password"
                          className="form-control"
                          placeholder={t("PLEASE_ENTER_PASSWORD")}
                          defaultValue={""}
                          ref={register({ required: "PLEASE_ENTER_PASSWORD" })}
                          name="password"
                        />
                        {errors.password && (
                          <div className="invalid-feedback">
                            {t(errors.password.message)}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-8">
                    <div className="form-group row m-b-15">
                      <label className="col-md-4 col-form-label">
                        <b>{t("API_URL")}</b>
                      </label>
                      <div className="col-md-7">
                        <input
                          type="text"
                          className="form-control"
                          placeholder={t("PLEASE_ENTER_URL")}
                          defaultValue={url}
                          ref={register({ required: "PLEASE_ENTER_URL" })}
                          name="url"
                        />
                        {errors.url && (
                          <div className="invalid-feedback">
                            {t(errors.url.message)}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <hr />
                <div className="row">
                  <div className="col-lg-3">
                    <div className="form-group">
                      <button type="submit" className="btn btn-primary">
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </PanelBody>
          </Panel>
        </div>
      </div>
    </div>
  );
};

export default SmsSettings;
