import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { Panel, PanelBody } from "./../../../components/panel/panel.jsx";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import NavigationButton from "../../../components/constantComponent/button/NavigationButton";

/// <summary>
/// Author : -
/// </summary>

const BankPackageDetail = (props) => {
  let history = useHistory();
  let vipLevelOptions = [
    { value: "vip0", label: "VIP 0" },
    { value: "vip1", label: "VIP 1" },
    { value: "vip2", label: "VIP 2" },
  ];
  const [name, setName] = useState(
    props.location.state !== undefined ? props.location.state.name : ""
  );
  return (
    <div>
      <h1 className="page-header">
        {name ? "Edit Bank Package" : "Add Bank Package"}
        <NavigationButton history={history} />
      </h1>
      <div className="row">
        <div className="col-xl-12">
          <Panel>
            <PanelBody>
              <form>
                <div className="row">
                  <div className="col-lg-2"></div>
                  <div className="col-lg-8">
                    <div className="form-group row m-b-15">
                      <label className="col-md-4 col-form-label">
                        <b>Name</b>
                      </label>
                      <div className="col-md-7">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Please Enter Bank Package Name"
                          defaultValue={name}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-2"></div>

                  <div className="col-lg-2"></div>
                  <div className="col-lg-8">
                    <div className="form-group row m-b-15">
                      <label className="col-md-4 col-form-label">
                        <b>Vip Level</b>
                      </label>
                      <div className="col-md-7">
                        <Select
                          placeholder="Please Select Vip Level"
                          options={vipLevelOptions}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-2"></div>
                </div>
                <hr />
                <div className="row">
                  <div className="col-lg-3">
                    <div className="form-group">
                      <button type="button" className="btn btn-primary">
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </PanelBody>
          </Panel>
        </div>
      </div>
    </div>
  );
};

export default withRouter(BankPackageDetail);
