import React from "react";
import ReactTable from "../../components/constantComponent/reactTable/ReactTable";
import { Panel, PanelHeader } from "./../../components/panel/panel.jsx";
import { useHistory } from "react-router-dom";
import {
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

/// <summary>
/// Author : -
/// </summary>

const InstantTransaction = (props) => {
  let history = useHistory();
  let data = [];
  let tableColumns = [
    {
      Header: "ID",
      accessor: "ID",
      width: 100,
    },
    {
      Header: "Date",
      accessor: "date",
      style: {
        whiteSpace: "unset",
        wordBreak: "break-word",
      },
      minWidth: 100,
    },
    {
      Header: "Username",
      accessor: "username",
      minWidth: 100,
    },
    {
      Header: "FullName",
      accessor: "fullname",
      minWidth: 100,
    },
    {
      Header: "Type",
      accessor: "type",
      minWidth: 100,
    },
    {
      Header: "Status",
      accessor: "status",
      Cell: ({ row }) => {
        return (
          <span>
            {row.original.status == 1 ? (
              <span className="badge badge-secondary badge-green">Approved</span>
            ) : (
              <span className="badge badge-secondary badge-danger">Rejected</span>
            )}
          </span>
        );
      },
      minWidth: 100,
    },
    {
      Header: "Amount",
      accessor: "amount",
      minWidth: 100,
    },
    {
      Header: "Channel",
      accessor: "channel",
      minWidth: 100,
    },
    {
      Header: "Bank",
      accessor: "bank",
      minWidth: 100,
    },
    {
      id: "action",
      Header: "",
      Cell: ({ row }) => (
        <div className="btn-group m-r-5 m-b-5">
          <UncontrolledButtonDropdown>
            <DropdownToggle caret color="default">
              <i className="fas fa-cog"></i>
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem
                onClick={() => {
                  editBtnHandler(row.original);
                }}
              >
                <span className="text-warning">Edit</span>
              </DropdownItem>
              <DropdownItem>
                <span className="text-danger">Delete</span>
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledButtonDropdown>
        </div>
      ),
      disableSortBy: true,
      disableFilters: true,
      width: 100,
      style: { overflow: "visible" },
    },
  ];

  const editBtnHandler = (value) => {
    console.log(value);
  };

  const filterCaseInsensitive = (filter, row) => {
    const id = filter.pivotId || filter.id;
    if (row[id] !== null && typeof row[id] === "string") {
      return row[id] !== undefined
        ? String(row[id].toLowerCase()).startsWith(filter.value.toLowerCase())
        : true;
    } else {
      return String(row[filter.id]) === filter.value;
    }
  };

  return (
    <div>
      <h1 className="page-header">Instant Transaction</h1>
      <Panel>
        <PanelHeader></PanelHeader>
        <ReactTable filterable data={data} columns={tableColumns} />
      </Panel>
    </div>
  );
};

export default InstantTransaction;
