import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import {
  Panel,
  PanelHeader,
  PanelBody,
} from "./../../components/panel/panel.jsx";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Dropzone from "react-dropzone";
import NavigationButton from "../../components/constantComponent/button/NavigationButton";
import {
  ApiKey,
  ApiUrl,
  LanguageKey,
  ContentTypeString,
} from "../../util/Constant";
import { stringIsNullOrEmpty, createMultiPartFormBody } from "../../util/Util";
import { useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { setBusy, setIdle, showMessage } from "../../redux/AppAction";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import ManageConfigurationLanguage from "./ManageConfigurationLanguage";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import classnames from "classnames";
import { fetch } from "whatwg-fetch";
import DateRangePicker from "react-bootstrap-daterangepicker";
import moment from "moment";
import ReactTable from "../../components/constantComponent/reactTable/ReactTable";

/// <summary>
/// Author : -
/// </summary>

const DepositPromotionCategoryDetail = (props) => {
  const _DETAIL_TAB = "1";
  const _LANGUAGE_TAB = "2";
  let _history = useHistory();
  const { t } = useTranslation(LanguageKey._PRIMARY);
  const _location = useLocation();
  const _dispatch = useDispatch();
  const { register, unregister, handleSubmit, errors, setValue } = useForm();

  const [title, setTitle] = useState("");
  const [categoryId, setCategoryId] = useState("");
  const [status, setStatus] = useState(true);
  const [activeTab, setActiveTab] = useState(_DETAIL_TAB);

  const {
    register: accountFormRegister,
    handleSubmit: accountFormHandleSubmit,
    errors: accountFormErrors,
    unregister: accountFormUnregister,
    setValue: accountFormSetValue,
  } = useForm();

  const {
    register: promotionFormRegister,
    handleSubmit: promotionFormHandleSubmit,
    errors: promotionFormErrors,
    unregister: promotionFormUnregister,
    setValue: promotionFormSetValue,
  } = useForm();

  const [vipList, setVipList] = useState([]);

  const [productData, setProductData] = useState([]);
  const [depositPromotionId, setDepositPromotionId] = useState("");
  const [accountProductStatus, setAccountProductStatus] = useState(true);
  const [accountProductModel, setAccountProductModel] = useState(false);
  const [accountproductName, setAccountProductName] = useState("");
  const [selectedMasterProductId, setSelectedMasterProductId] = useState("");
  const [isCreateNewAccount, setIsCreateNewAccount] = useState(true);
  const [depositPromotionProductId, setDepositPromotionProductId] =
    useState("");
  const [image, setImage] = useState();
  const [imageFile, setImageFile] = useState([]);

  const [depositPromotionList, setDepositPromotionList] = useState([]);

  const [desktopFile, setDesktopFile] = useState([]);
  const [mobileFile, setMobileFile] = useState([]);
  const [desktopImage, setDesktopImage] = useState();
  const [mobileImage, setMobileImage] = useState();
  const [isNewDesktopImage, setIsNewDesktopImage] = useState(false);
  const [isNewMobileImage, setIsNewMobileImage] = useState(false);

  const [promoWithCategory, setPromoWithCategory] = useState([]);
  const [promoWithoutCategory, setPromoWithoutCategory] = useState([]);
  const [rank, setRank] = useState("");

  let _tableColumns = [
    {
      Header: "Product",
      accessor: "name",
      disableSortBy: true,
    },
    {
      Header: "STATUS",
      accessor: "status",
      Cell: ({ row }) => (
        <div className="btn-group m-r-5 m-b-5">
          {row.original.status == true ? (
            <span className="badge badge-secondary badge-green">
              {t("ACTIVE")}
            </span>
          ) : (
            <span className="badge badge-secondary badge-danger">
              {t("SUSPENDED")}
            </span>
          )}
        </div>
      ),
      disableSortBy: true,
      style: { overflow: "visible" },
    },
    {
      id: "action",
      Header: "",
      Cell: ({ row }) => (
        <div className="btn-group m-r-5 m-b-5">
          <UncontrolledButtonDropdown>
            <DropdownToggle caret color="default">
              <i className="fas fa-cog"></i>
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem onClick={() => deleteDepositPromotionSetting(row.original.id)}>
                <span className="text-warning">{t("DELETE")}</span>
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledButtonDropdown>
        </div>
      ),
      disableSortBy: true,
      disableFilters: true,
      width: 100,
      style: { overflow: "visible" },
    },
  ];

  const [selectedViplevel, setSelectedViplevel] = useState("");
  /// <summary>
  /// Author : -
  /// </summary>
  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  /// <summary>
  /// Author : -
  /// </summary>
  useEffect(() => {
    register({ name: "content" }, { required: "PLEASE_ENTER_CONTENT" });
    register({ name: "vip" }, { required: "PLEASE_SELECT_VIP_LEVEL" });
    init();
  }, []);

  const _OPTION_STYLES = {
    control: (base) => ({
      ...base,
      "min-height": "34px",
      height: "34px",
    }),
    valueContainer: (base) => ({
      ...base,
      height: "34px",
      "min-height": "34px",
    }),
    indicatorsContainer: (base) => ({
      ...base,
      height: "34px",
      "min-height": "34px",
    }),
    input: (base) => ({
      height: "34px",
      "min-height": "34px",
    }),
  };

  /// <summary>
  /// Author : -
  /// </summary>
  async function init() {
    if (_location.state) {
      setCategoryId(_location.state.id);
      var apiUrl = ApiUrl._API_GET_DEPOSIT_PROMOTION_CATEGORY_BY_ID;
      apiUrl += "?id=" + _location.state.id;
      await fetch(apiUrl, {
        method: ApiKey._API_GET,
        headers: {
          "Content-Type": ApiKey._API_FORM_URLENCODED,
          "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
        },
      })
        .then((response) => response.json())
        .then(async (responseJson) => {
          console.log(responseJson[ApiKey._API_DATA_KEY])
          if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            setCategoryId(responseJson[ApiKey._API_DATA_KEY][0]["id"]);
            setTitle(responseJson[ApiKey._API_DATA_KEY][0]["name"]);
            setStatus(responseJson[ApiKey._API_DATA_KEY][0]["status"]);
            setDesktopImage(responseJson[ApiKey._API_DATA_KEY][0]["image"])
            setRank(responseJson[ApiKey._API_DATA_KEY][0]["rank"])
            unregister("vip");
            unregister("content");
            setPromoWithCategory(responseJson[ApiKey._API_DATA_KEY][0].depositPromotion);
          }
        });
      getDepositPromotionProductWithoutCategory();
      getDepositPromotionProductWithCategory();
    }
  }

  function getDepositPromotionProductWithoutCategory() {
    fetch(ApiUrl._API_GET_DEPOSIT_PROMOTION_WITHOUT_CATEGORY, {
      method: ApiKey._API_GET,
      headers: {
        "Content-Type": ApiKey._API_FORM_URLENCODED,
        "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
          const DepositPromotionList = [];
          responseJson[ApiKey._API_DATA_KEY].forEach(function (product) {
            DepositPromotionList.push({
              label: product.name,
              value: product.id,
            });
          });
          setPromoWithoutCategory(DepositPromotionList);
        }
      });
  }

  /// <summary>
  /// Author : -
  /// </summary>
  const submitForm = async (data, e) => {
    let params = {
      name: data.title,
      status: data.status,
      isNewDesktopFile: isNewDesktopImage,
      rank: data.rank
    };

    if (!stringIsNullOrEmpty(categoryId)) {
      params["id"] = categoryId;
    }

    if (!stringIsNullOrEmpty(desktopFile)) {
      params["desktopFile"] = desktopFile;
    }

    if (stringIsNullOrEmpty(desktopImage)) {
      params["desktopImageRemoved"] = true;
    }

    let formBody = createMultiPartFormBody(params);
    _dispatch(setBusy());

    fetch(ApiUrl._API_CREATE_OR_UPDATE_DEPOSIT_PROMOTION_CATEGORY, {
      method: ApiKey._API_POST,
      headers: {
        "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
      },
      body: formBody,
    })
      .then((response) => response.json())
      .then((responseJson) => {
        _dispatch(setIdle());
        _dispatch(
          showMessage(
            responseJson[ApiKey._API_SUCCESS_KEY],
            t(responseJson[ApiKey._API_MESSAGE_KEY])
          )
        );

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
          setCategoryId(responseJson[ApiKey._API_DATA_KEY]["id"]);
          //getMasterProduct();
        }
      });
  };

  function toggleModal(isAddProduct) {
    if (accountProductModel) {
      setDepositPromotionProductId("");
      setSelectedMasterProductId("");
      setAccountProductStatus(false);
    }

    if (isAddProduct) {
      setIsCreateNewAccount(true);
    }

    setAccountProductModel(!accountProductModel);
  }

  function getDepositPromotionProductWithCategory() {
    fetch(
      ApiUrl._API_GET_DEPOSIT_PROMOTION_WITH_CATEGORY +
      "?id=" +
      (categoryId == "" ? _location.state.id : categoryId),
      {
        method: ApiKey._API_GET,
        headers: {
          "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
        },
      }
    )
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
          setPromoWithCategory(responseJson[ApiKey._API_DATA_KEY]);
        }
      });
  }

  function loadAccountProduct(data) {
    //setDepositPromotionProductId(data.id);
    //setDepositPromotionId(data.depositPromotionId);
    //setAccountProductStatus(data.status);
    //setAccountProductName(data.name);
    //setSelectedMasterProductId(data.masterProductId);
    //setIsCreateNewAccount(false);
    toggleModal();
  }

  function deleteDepositPromotionSetting(id) {
    let params = {
      id: id,
      depositPromotionCategoryId:0
    };

    let formBody = createMultiPartFormBody(params);
    _dispatch(setBusy());

    fetch(ApiUrl._API_CREATE_OR_UPDATE_DEPOSIT_PROMOTION, {
      method: ApiKey._API_POST,
      headers: {
        "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
      },
      body: formBody,
    })
      .then((response) => response.json())
      .then((responseJson) => {
        _dispatch(setIdle());
        _dispatch(
          showMessage(
            responseJson[ApiKey._API_SUCCESS_KEY],
            t(responseJson[ApiKey._API_MESSAGE_KEY])
          )
        );

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
          getDepositPromotionProductWithoutCategory();
          getDepositPromotionProductWithCategory();
          setDepositPromotionId("");
          setAccountProductName("");
          setAccountProductStatus("");
        }
      });
  }

  const submitAccountSettingForm = async (data, e) => {
    let params = {
      depositPromotionCategoryId: categoryId,
      id: depositPromotionId,
    };

    let formBody = createMultiPartFormBody(params);
    _dispatch(setBusy());

    fetch(ApiUrl._API_CREATE_OR_UPDATE_DEPOSIT_PROMOTION, {
    	method: ApiKey._API_POST,
    	headers: {
    		"X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
    	},
    	body: formBody,
    })
    	.then((response) => response.json())
    	.then((responseJson) => {
    		_dispatch(setIdle());
    		_dispatch(
    			showMessage(
    				responseJson[ApiKey._API_SUCCESS_KEY],
    				t(responseJson[ApiKey._API_MESSAGE_KEY])
    			)
    		);

    		if (responseJson[ApiKey._API_SUCCESS_KEY]) {
          getDepositPromotionProductWithoutCategory();
          getDepositPromotionProductWithCategory();
    			setDepositPromotionId("");
    			setAccountProductName("");
    			setAccountProductStatus("");
    			toggleModal();
    		}
    	});
  };

  const logoDrop = (acceptedFiles, isDesktop) => {
    if (acceptedFiles.length !== 0) {
      const file = acceptedFiles[0];

      if (isDesktop) {
        setDesktopFile(file);
      } else {
        setMobileFile(file);
      }

      const reader = new FileReader();
      reader.onloadend = () => {
        if (isDesktop) {
          setDesktopImage(reader.result);
          setIsNewDesktopImage(true);
        } else {
          setIsNewMobileImage(true);
          setMobileImage(reader.result);
        }
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <div>
      <h1 className="page-header">
        {categoryId ? t("EDIT_DEPOSIT_PROMOTION_CATEGORY") : t("ADD_DEPOSIT_PROMOTION_CATEGORY")}
        <NavigationButton history={_history} />
      </h1>
      {categoryId && (
        <Nav tabs>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === _DETAIL_TAB })}
              onClick={() => {
                toggle(_DETAIL_TAB);
              }}
            >
              {t("DETAIL")}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === _LANGUAGE_TAB })}
              onClick={() => {
                toggle(_LANGUAGE_TAB);
              }}
            >
              {t("LANGUAGE")}
            </NavLink>
          </NavItem>
        </Nav>
      )}
      <TabContent activeTab={activeTab}>
        <TabPane tabId={_DETAIL_TAB}>
          <div className="row">
            <div className="col-xl-12">
              <Panel>
                <PanelBody>
                  <form onSubmit={promotionFormHandleSubmit(submitForm)}>
                    <div className="row">
                      <div className="col-lg-8">
                        <div className="form-group row m-b-15">
                          <label className="col-md-4 col-form-label">
                            <b>{t("TITLE")}</b>
                          </label>
                          <div className="col-md-7">
                            <input
                              type="text"
                              className="form-control"
                              placeholder={t("PLEASE_ENTER_TITLE")}
                              defaultValue={title}
                              ref={promotionFormRegister({
                                required: "PLEASE_ENTER_TITLE",
                              })}
                              name="title"
                              rows="5"
                              style={{ marginTop: "10px" }}
                            />
                            {errors.text && (
                              <div className="invalid-feedback">
                                {t(errors.text.message)}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-8">
                        <div className="form-group row m-b-15">
                          <label className="col-md-4 col-form-label">
                            <b>{t("STATUS")}</b>
                          </label>
                          <div className="col-md-7">
                            <div className="switcher">
                              <input
                                type="checkbox"
                                name="status"
                                id="status"
                                onChange={(e) => setStatus(e.target.checked)}
                                value={true}
                                checked={status}
                                ref={promotionFormRegister}
                              />
                              <label htmlFor="status"></label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-8">
                        <div className="form-group row m-b-15">
                          <label className="col-md-4 col-form-label">
                            <b>
                              {t("TITLE_IMAGE")}
                            </b>
                          </label>
                          <div className="col-md-7">
                            <Dropzone
                              accept={"image/*"}
                              onDrop={(acceptedFiles) =>
                                logoDrop(acceptedFiles, true)
                              }
                            >
                              {({ getRootProps, getInputProps }) => (
                                <section>
                                  <div
                                    className="dropzone"
                                    style={{
                                      minHeight: "200px",
                                      textAlign: "center",
                                    }}
                                    {...getRootProps()}
                                  >
                                    <input {...getInputProps()} />
                                    <h4 style={{ color: "grey" }}>
                                      {t(
                                        "DRAG_N_DROP_SOME_FILES_HERE_OR_CLICK_TO_SELECT_FILES"
                                      )}
                                    </h4>
                                    {!stringIsNullOrEmpty(desktopImage) && (
                                      <aside className="thumbsContainer">
                                        <div className="thumb">
                                          <div className="thumbInner">
                                            <img
                                              src={desktopImage}
                                              className="dropzone-img"
                                            />
                                          </div>
                                        </div>
                                      </aside>
                                    )}
                                  </div>
                                </section>
                              )}
                            </Dropzone>
                            <br />
                            <button
                              type="button"
                              className="btn btn-danger"
                              onClick={() => {
                                setDesktopImage("");
                                setDesktopFile();
                              }}
                            >
                              {t("REMOVE_IMAGE")}
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-8">
                        <div className="form-group row m-b-15">
                          <label className="col-md-4 col-form-label">
                            <b>{t("RANK")}</b>
                          </label>
                          <div className="col-md-7">
                            <input
                              type="number"
                              className="form-control"
                              placeholder={t("PLEASE_ENTER_RANK")}
                              defaultValue={rank}
                              ref={promotionFormRegister({
                                required: "PLEASE_ENTER_RANK",
                                min: {
                                  value: 0,
                                  message:
                                    "PLEASE_ENTER_VALUE_NOT_LESS_THAN_ZERO",
                                },
                                max: {
                                  value: 100,
                                  message:
                                    "PLEASE_ENTER_VALUE_NOT_MORE_THAN_100",
                                },
                              })}
                              name="rank"
                              style={{ marginTop: "10px" }}
                            />
                            {errors.rank && (
                              <div className="invalid-feedback">
                                {t(errors.rank.message)}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <hr />
                    <div className="row">
                      <div className="col-lg-3">
                        <div className="form-group">
                          <button type="submit" className="btn btn-primary">
                            {t("SUBMIT")}
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </PanelBody>
              </Panel>
            </div>
          </div>
          {!stringIsNullOrEmpty(categoryId) && (
            <div className="row">
              <div className="col-xl-12">
                <Panel>
                  <PanelHeader>{t("SELECTED_PROMOTION")}</PanelHeader>
                  <PanelBody>
                    <div className="row">
                      <div className="col-xl-12">
                        <button
                          type="button"
                          className="btn btn-primary"
                          style={{ float: "right" }}
                          onClick={() => toggleModal(true)}
                        >
                          <i className="fa fa-plus-circle m-r-10"></i>
                          {t("ADD_PROMOTION")}
                        </button>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-xl-12">
                        <ReactTable
                          data={promoWithCategory}
                          columns={_tableColumns}
                        />
                      </div>
                    </div>
                  </PanelBody>
                </Panel>
              </div>
            </div>
          )}
          <div className="row">
            <Modal isOpen={accountProductModel} toggle={() => toggleModal()}>
              <form
                onSubmit={accountFormHandleSubmit(submitAccountSettingForm)}
              >
                <ModalHeader toggle={() => toggleModal()}>
                  {t(isCreateNewAccount ? "ADD_PROMOTION" : "EDIT_PROMOTION")}
                </ModalHeader>
                <ModalBody>
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="form-group row m-b-15">
                        <label className="col-md-4 col-form-label">
                          <b>{t("PROMOTION")}</b>
                        </label>
                        <div className="col-md-7">
                          <Select
                            name="depositPromotionId"
                            options={promoWithoutCategory}
                            placeholder={
                              promoWithoutCategory.filter(
                                (option) => option.value == depositPromotionId
                              )[0] !== undefined
                                ? promoWithoutCategory.filter(
                                  (option) => option.value == depositPromotionId
                                )[0].label
                                : ""
                            }
                            isDisabled={depositPromotionProductId != ""}
                            value=""
                            onChange={(e) => {
                              setDepositPromotionId(e.value);
                              setSelectedMasterProductId(e.masterProductId);
                              accountFormSetValue("depositPromotionId", e.value);
                            }}
                            ref={accountFormRegister}
                          />
                          {accountFormErrors.depositPromotionId && (
                            <div className="invalid-feedback">
                              {t(accountFormErrors.depositPromotionId.message)}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </ModalBody>
                <ModalFooter>
                  <button
                    type="button"
                    className="btn btn-white"
                    onClick={() => toggleModal()}
                  >
                    {t("CLOSE")}
                  </button>
                  <button type="submit" className="btn btn-primary">
                    {t("SUBMIT")}
                  </button>
                </ModalFooter>
              </form>
            </Modal>
          </div>
        </TabPane>
        <TabPane tabId={_LANGUAGE_TAB}>
          {categoryId && (
            <div className="row">
              <div className="col-lg-12">
                <ManageConfigurationLanguage
                  id={categoryId}
                  type={ContentTypeString._DEPOSIT_PROMOTION_CATEGORY}
                />
              </div>
            </div>
          )}
        </TabPane>
      </TabContent>
    </div>
  );
};

export default withRouter(DepositPromotionCategoryDetail);
