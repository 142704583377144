import { _LOGIN_SUCCESS, _LOGIN_FAILED, _LOGOUT } from './AuthAction';
import { clearPermissionData } from "../util/PermissionChecker"

const _INITIAL_STATE = {
    userData: {},
    isLoggedIn: false,
    loginMessage: "",
    loginStateInitialized:false,
};

/// <summary>
/// Author : -
/// </summary>
export default (state = _INITIAL_STATE, action) => {
    switch (action.type) {
        case _LOGIN_SUCCESS:
            return {
                userData: action.userData,
                isLoggedIn: true,
                loginMessage: "",
                loginStateInitialized:true
            }
            break;
        case _LOGIN_FAILED:
            return {
                loginMessage: action.message,
                isLoggedIn: false,
                userData: {},
                loginStateInitialized :true
            }
            break;
        case _LOGOUT:
            clearPermissionData();
            return {
                userData: {},
                isLoggedIn: false,
                loginMessage: "",
                loginStateInitialized:true,
            };
            break;
        default:
            return state;
            break;
    }
};
