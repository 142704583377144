import React, { useState, useEffect, useMemo } from "react";
import { withRouter } from "react-router-dom";
import { Panel, PanelBody } from "./../../components/panel/panel.jsx";
import NavigationButton from "../../components/constantComponent/button/NavigationButton";
import { ApiKey, ApiUrl, WebUrl, LanguageKey } from "../../util/Constant";
import { setBusy, setIdle, showMessage } from "../../redux/AppAction";

import { createFormBody } from "../../util/Util";
import { useLocation, useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import SweetAlert from "react-bootstrap-sweetalert";
import ApiEngine from "../../util/ApiEngine";

/// <summary>
/// Author : -
/// </summary>
const AccountShareHolderViewPayout = (props) => {
  const { t } = useTranslation(LanguageKey._PRIMARY);
  let _history = useHistory();
  const _location = useLocation();
  const _dispatch = useDispatch();
  const { register, handleSubmit, errors, setValue, watch } = useForm({ mode: "onBlur" });
  const [productId, setProductId] = useState('');
  const [productName, setProductName] = useState('');
  const [productCode, setProductCode] = useState('');
  const [status, setStatus] = useState(null);
  const [royalty, setRoyalty] = useState(0);
  const [markup, setMarkup] = useState(0);
  const [grosssales, setGrosssales] = useState(0);
  const [validTurnover, setValidTurnover] = useState(0);

  const [winAmt, setWinAmt] = useState(0);
  const [commission, setCommission] = useState(0);
  const [rewards, setRewards] = useState(0);
  const [balance, setBalance] = useState(0);

  const [netsales, setNetsales] = useState(0);
  const [payout, setPayout] = useState(null);
  const [netprofit, setNetprofit] = useState('');
  const [date, setDate] = useState('');

  const [birthdayBonus, setBirthdayBonus] = useState(0);
  const [gameRebate, setGameRebate] = useState(0);
  const [weeklyRescue, setWeeklyRescue] = useState(0);
  const [memberProductBonus, setMemberProductBonus] = useState(0);
  const [memberPoints, setMemberPoints] = useState(0);
  const [totalExpenses, setTotalExpenses] = useState(0);


  /// <summary>
  /// Author : -
  /// </summary>
  useEffect(() => {
    init();
  }, []);


  /// <summary>
  /// Author : -
  /// </summary>
  function init() {
    if (_location.state) {
      setDate(_location.state.date);
      setProductId(_location.state.id);
      setProductName(_location.state.productName);
      setProductCode(_location.state.productCode);
      setStatus(_location.state.status);

      getProductData(_location.state.date, _location.state.id);

    }
    else {
      _history.push(WebUrl._URL_SHAREHOLDERS_PROCESS)
    }
  }



  /// <summary>
  /// Author : -
  /// </summary>
  function getProductData(date, productId) {
    _dispatch(setBusy());
    fetch(ApiUrl._API_GET_SHAREHOLDER_ONE_PRODUCT_DATA + "?date=" + date + "&productId=" + productId, {
      method: ApiKey._API_GET,
      headers: {
        "Content-Type": ApiKey._API_FORM_URLENCODED,
        "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
          let data = responseJson[ApiKey._API_DATA_KEY]["data"][0];

          if (typeof (data) !== "undefined" && data.id == productId) {
            setMarkup(data.markup);
            setGrosssales(data.grossSales);
            setValidTurnover(data.validTurnover);
            setNetsales(data.netSales);
            setNetprofit(data.netProfit);
            setMemberPoints(data.memberPoint);
            setMemberProductBonus(data.memberProductBonus);
            setGameRebate(data.gameRebate);
            setBirthdayBonus(data.birthdayBonus);
            setWeeklyRescue(data.weeklyRescue);
            setTotalExpenses(data.totalExpenses);
            let _winLose = data.winAmount - data.grossSales;
            setWinAmt(_winLose);
            let _comm = data.validTurnover * data.commission / 100;
            setCommission(_comm);
            let _royalty = _winLose * data.royalty / 100;
            setRoyalty(_royalty);
            let _rewards = data.rewards;
            setBalance(_royalty + _comm + _rewards);
            setRewards(_rewards);

            if (data.payout > 0)
                setPayout(data.payout);
            else {
                setPayout(data.validTurnover + (_royalty + _comm + _rewards));
            }
          }
          else if (productId == 0) {
          }
        }
        _dispatch(setIdle());
      });
  }

  /// <summary>
  /// Author : -
  /// </summary>
  const submitForm = async (data, e) => {
    let params = {
      payout: data.payout,
      productId: productId,
      date: date
    };

    let formBody = createFormBody(params);
    let responseJson = await ApiEngine.post(
      ApiUrl._API_UPDATE_SHAREHOLDER_PAYOUT,
      formBody
    );
    _dispatch(
      showMessage(
        responseJson[ApiKey._API_SUCCESS_KEY],
        t(responseJson[ApiKey._API_MESSAGE_KEY])
      )
    );

    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      _history.push({
        pathname: WebUrl._URL_SHAREHOLDERS_PROCESS,
        state: { date: date }
      });
    }
  };

  /// <summary>
  /// Author : -
  /// </summary>
  function preSubmit() {
    handleSubmit(submitForm)();
  }

  return (
    <div>
      <h1 className="page-header">
        {t("SHAREHOLDER_VIEW_SALES")}
        <NavigationButton history={_history} />
      </h1>
      <div className="row">
        <div className="col-xl-12">
          <Panel>
            <PanelBody>
              <form onSubmit={handleSubmit(submitForm)}>
                <div className="row">
                  <div className="col-lg-6">
                    <h4>{t("PRODUCT_DETAIL")} - {date}</h4>
                  </div>
                </div>


                <div className="row">
                  <div className="col-lg-2">
                    <label>
                      <b>{t("PRODUCT_NAME")}</b>
                    </label>
                    <input type="text" className="form-control" value={productName} disabled />
                  </div>

                  <div className="col-lg-2">
                    <label>
                      <b>{t("PRODUCT_CODE")}</b>
                    </label>
                    <input type="text" className="form-control" value={productCode} disabled />
                  </div>


                  <div className="col-lg-2">
                    <label>
                      <b>{t("STATUS")}</b>
                    </label>
                    <br />
                    {status == 1 ? (
                      <span className="badge badge-secondary badge-green">
                        {t("ACTIVE")}
                      </span>
                    ) : (
                      <span className="badge badge-secondary badge-danger">
                        {t("MAINTENANCE")}
                      </span>
                    )}
                  </div>

                </div>
                {
                  productId == 0 && <>
                    <hr />
                    <div className="row">
                      <div className="col-lg-2">
                        <label>
                          <b>{t("TOTAL GROSS SALES")}</b>
                        </label>
                        <br />
                        <input type="text" className="form-control text-right" value={grosssales.toFixed(3)} disabled />
                      </div>

                      <div className="col-lg-2">
                        <label>
                          <b>{t("VALID_TURNOVER")}</b>
                        </label>
                        <br />
                        <input type="text" className="form-control text-right" value={validTurnover.toFixed(3)} disabled />
                      </div>
                    </div>
                    <hr />
                    <div className="row">
                      <div className="col-lg-6">
                        <h4>{t("MARKETING_EXPENSES")}</h4>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-2">
                        <label>
                          <b>{t("WEEKLY_RESCUE")}</b>
                        </label>
                        <br />
                        <input type="text" className="form-control text-right" value={weeklyRescue.toFixed(3)} disabled />
                      </div>

                      <div className="col-lg-2">
                        <label>
                          <b>{t("BIRTHDAY_BONUS")}</b>
                        </label>
                        <br />
                        <input type="text" className="form-control text-right" value={birthdayBonus.toFixed(3)} disabled />
                      </div>

                      <div className="col-lg-2">
                        <label>
                          <b>{t("VIP_COMMISSION")}</b>
                        </label>
                        <br />
                        <input type="text" className="form-control text-right" value={gameRebate.toFixed(3)} disabled />
                      </div>

                    </div>
                    <div className="row">
                      <div className="col-lg-2">
                        <label>
                          <b>{t("MEMBER_WALLET_BONUS")}</b>
                        </label>
                        <br />
                        <input type="text" className="form-control text-right" value={memberProductBonus.toFixed(3)} disabled />
                      </div>

                      <div className="col-lg-2">
                        <label>
                          <b>{t("MEMBER_POINT")}</b>
                        </label>
                        <br />
                        <input type="text" className="form-control text-right" value={memberPoints.toFixed(3)} disabled />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-2">
                        <label>
                          <b>{t("TOTAL_EXPENSES")}</b>
                        </label>
                        <br />
                        <input type="text" className="form-control text-right" value={totalExpenses.toFixed(3)} disabled />
                      </div>


                    </div>

                  </>
                }
                {
                  productId > 0 && <>
                    <hr />
                    <div className="row">
                      <div className="col-lg-2">
                        <label>
                          <b>{t("GROSS_SALES")}</b>
                        </label>
                        <br />
                        <input type="text" className="form-control text-right" value={grosssales.toFixed(3)} disabled />
                      </div>
                      <div className="col-lg-2">
                        <label>
                          <b>{t("WIN_LOSS")}</b>
                        </label>
                        <br />
                        <input type="text" className="form-control text-right" value={winAmt.toFixed(3)} disabled />
                      </div>
                      <div className="col-lg-2">
                        <label>
                          <b>{t("VALID_TURNOVER")}</b>
                        </label>
                        <br />
                        <input type="text" className="form-control text-right" value={validTurnover.toFixed(3)} disabled />
                      </div>

                      <div className="col-lg-2">
                        <label>
                          <b>{t("Rewards")}</b>
                        </label>
                        <br />
                        <input type="text" className="form-control text-right" value={rewards.toFixed(3)} disabled />
                      </div>
                    </div>
                    <hr />
                    <div className="row">
                      <div className="col-lg-2">
                        <label>
                          <b>{t("ROYALTY")}</b>
                        </label>
                        <br />
                        <input type="text" className="form-control text-right" value={royalty.toFixed(3)} disabled />
                      </div>
                      <div className="col-lg-2">
                        <label>
                          <b>{t("COMMISSION")}</b>
                        </label>
                        <br />
                        <input type="text" className="form-control text-right" value={commission.toFixed(3)} disabled />
                      </div>
                      <div className="col-lg-2">
                        <label>
                          <b>{t("Rewards")}</b>
                        </label>
                        <br />
                        <input type="text" className="form-control text-right" value={rewards.toFixed(3)} disabled />
                      </div>
                      <div className="col-lg-2">
                        <label>
                          <b>{t("BALANCE")}</b>
                        </label>
                        <br />
                        <input type="text" className="form-control text-right" value={balance.toFixed(3)} disabled />
                      </div>
                    </div>
                  </>
                }

                <hr />
                <div className="row">
                  <div className="col-lg-2">
                    <label>
                      <b>{t("PAYOUT")}</b>
                    </label>

                    <br />

                    <input
                      type="number"
                      name="payout"
                      ref={register({
                        required: "PLEASE_ENTER_PAYOUT",
                        min: {
                          value: 0,
                          message: "PLEASE_ENTER_VALUE_NOT_LESS_THAN_0",
                        },
                      })}
                      className="form-control text-right"
                      placeholder={t("PAYOUT")}
                      value={payout}
                      onChange={(e) => {
                        setPayout(e.target.value);
                        (productId == 0) ?
                          setNetprofit(- e.target.value)
                          :
                          setNetprofit(validTurnover - e.target.value)
                      }
                      }
                    />
                    {errors.payout && (
                      <div className="invalid-feedback">
                        {t(errors.payout.message)}
                      </div>
                    )}
                  </div>

                </div>


                <hr />
                <div className="row">
                  <div className="col-lg-3">
                    <div className="form-group">
                      <button
                        type="button"
                        onClick={() => preSubmit()}
                        className="btn btn-primary"
                      >
                        {t("SUBMIT")}
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </PanelBody>
          </Panel>
        </div>
      </div>
    </div>
  );
};

export default withRouter(AccountShareHolderViewPayout);
