import React, { useState, useEffect } from "react";
import ReactTable from "../../components/constantComponent/reactTable/ReactTable";
import { Panel, PanelBody } from "./../../components/panel/panel.jsx";
import "../../assets/css/custom.css";
import Select from "react-select";
import { useForm } from "react-hook-form";
import {
	UncontrolledButtonDropdown,
	DropdownToggle,
	DropdownMenu,
	DropdownItem,
	Modal,
	ModalBody,
	ModalHeader,
	ModalFooter,
} from "reactstrap";
import {
	createFormBody,
	navigateTo,
	stringIsNullOrEmpty,
	createMultiPartFormBody,
} from "../../util/Util";
import {
	ApiUrl,
	ApiKey,
	ContentTypeString,
	LanguageKey,
} from "../../util/Constant";
import { useTranslation } from "react-i18next";
import ConfigurationLanguageDetail from "../configuration/ConfigurationLanguageDetail";
import { fetch } from "whatwg-fetch";
import ApiEngine from "../../util/ApiEngine";
import { useDispatch } from "react-redux";
import { showMessage, setBusy, setIdle } from "../../redux/AppAction";
import Dropzone from "react-dropzone";
import { useHistory } from "react-router-dom";

/// <summary>
/// Author : -
/// </summary>
const BonusDepositDetailLanguage = (props) => {
	const { handleSubmit, errors, register, content, setValue, clearErrors } =
		useForm();
	const { t } = useTranslation(LanguageKey._PRIMARY);
	const [data, setData] = useState([]);
	const _dispatch = useDispatch();
	let _history = useHistory();
	const [languageDetailModalVisibility, setLanguageDetailModalVisibility] =
		useState(false);

	const _EXCLUDE_TITLE_TYPE = [
		ContentTypeString._VIP_BENEFIT_CONTENT,
		ContentTypeString._VIP_CRITERIA,
	];
	const _EXCLUDE_CONTENT_TYPE = [
		ContentTypeString._VIP_BENEFIT,
		ContentTypeString._PRODUCT_CATEGORY,
	];
	const [isOpen, setIsOpen] = useState(false);
	const [languageDetailId, setLanguageDetailId] = useState("");
	const [tableColumns, setTableColumns] = useState([]);
	const [languageOption, setLanguageOption] = useState([]);
	const [languageId, setLanguageId] = useState("");
	const [bonusDepositData, setBonusDepositData] = useState([]);
	const [editModal, setEditModal] = useState(false);
	const [editLanguageId, setEditLanguageId] = useState("");
	const [bonusImage, setBonusImage] = useState();
	const [bonusImageFile, setBonusImageFile] = useState([]);
	const [popupImage, setPopupImage] = useState();
	const [popupImageFile, setPopupImageFile] = useState([]);
	const [bonusDepositId, setBonusDepositId] = useState("");
	const [editBonusImage, setEditBonusImage] = useState();
	const [editBonusImageFile, setEditBonusImageFile] = useState([]);
	const [editPopupImage, setEditPopupImage] = useState();
	const [editPopupImageFile, setEditPopupImageFile] = useState([]);
	const [bonusName, setBonusName] = useState("");
	const [editBonusName, setEditBonusName] = useState("");
	let _tableColumns = [
		{
			Header: "BONUS_NAME",
			accessor: "title",
			disableSortBy: true,
		},
		{
			Header: "LANGUAGE",
			accessor: "languageName",
			disableSortBy: true,
		},
		{
			Header: "BONUS_IMAGE",
			accessor: "bonusImage",
			Cell: ({ row }) => (
				<div strle={{ textAlign: "center" }}>
					{!stringIsNullOrEmpty(row.original.bonusImage) ? (
						<img
							style={{ width: "150px", height: "200px" }}
							src={row.original.bonusImage}
						/>
					) : (
						<span> - </span>
					)}
				</div>
			),
			disableSortBy: true,
		},
		{
			Header: "POPUP_IMAGE",
			accessor: "popupImage",
			Cell: ({ row }) => (
				<div strle={{ textAlign: "center" }}>
					{!stringIsNullOrEmpty(row.original.popupImage) ? (
						<img
							style={{ width: "150px", height: "200px" }}
							src={row.original.popupImage}
						/>
					) : (
						<span> - </span>
					)}
				</div>
			),
			disableSortBy: true,
		},
		{
			id: "action",
			Header: "",
			Cell: ({ row }) => (
				<div className="btn-group m-r-5 m-b-5">
					<UncontrolledButtonDropdown>
						<DropdownToggle caret color="default">
							<i className="fas fa-cog"></i>
						</DropdownToggle>
						<DropdownMenu>
							<DropdownItem
								onClick={() => {
									setEditModal(true);
									setBonusDepositId(row.original.id);
									setEditLanguageId(row.original.languageId);
									setEditBonusImage(row.original.bonusImage);
									setEditPopupImage(row.original.popupImage);
									setEditBonusName(row.original.title);
								}}
							>
								<span className="text-warning">{t("EDIT")}</span>
							</DropdownItem>
						</DropdownMenu>
					</UncontrolledButtonDropdown>
				</div>
			),
			disableSortBy: true,
			disableFilters: true,
			width: 100,
			style: { overflow: "visible" },
		},
	];
	const onSubmit = async (data) => {
		let params = {
			bonusName: bonusName,
			bonusDepositId: props.id,
			languageId: languageId,
		};

		if (!stringIsNullOrEmpty(bonusImageFile)) {
			params["bonusImage"] = bonusImageFile;
		}

		if (!stringIsNullOrEmpty(popupImageFile)) {
			params["popupImage"] = popupImageFile;
		}

		let formBody = createMultiPartFormBody(params);
		_dispatch(setBusy());

		let responseJson = await ApiEngine.post(
			ApiUrl._API_CREATE_BONUS_DEPOSIT_LANGUAGE,
			formBody
		);
		_dispatch(setIdle());
		_dispatch(
			showMessage(
				responseJson[ApiKey._API_SUCCESS_KEY],
				t(responseJson[ApiKey._API_MESSAGE_KEY])
			)
		);
		setIsOpen(false);
		setLanguageId();
		setBonusImage();
		setPopupImage();
		setBonusName("");
		getBonusDepositContent();
	};
	const bonusImageDrop = (acceptedFiles) => {
		if (acceptedFiles.length !== 0) {
			const file = acceptedFiles[0];
			setBonusImageFile(file);
			const reader = new FileReader();
			reader.onloadend = () => {
				setBonusImage(reader.result);
			};
			reader.readAsDataURL(file);
		}
	};

	const popupImageDrop = (acceptedFiles) => {
		if (acceptedFiles.length !== 0) {
			const file = acceptedFiles[0];
			setPopupImageFile(file);
			const reader = new FileReader();
			reader.onloadend = () => {
				setPopupImage(reader.result);
			};
			reader.readAsDataURL(file);
		}
	};

	const editBonusImageDrop = (acceptedFiles) => {
		if (acceptedFiles.length !== 0) {
			const file = acceptedFiles[0];
			setEditBonusImageFile(file);
			const reader = new FileReader();
			reader.onloadend = () => {
				setEditBonusImage(reader.result);
			};
			reader.readAsDataURL(file);
		}
	};

	const editPopupImageDrop = (acceptedFiles) => {
		if (acceptedFiles.length !== 0) {
			const file = acceptedFiles[0];
			setEditPopupImageFile(file);
			const reader = new FileReader();
			reader.onloadend = () => {
				setEditPopupImage(reader.result);
			};
			reader.readAsDataURL(file);
		}
	};
	/// <summary>
	/// Author : -
	/// </summary>
	useEffect(() => {
		getConfigLanguage();
		getLanguage();
		getPlatform();
		getBonusDepositContent();
		/// Used to Exclude the column of table
		if (_EXCLUDE_CONTENT_TYPE.includes(props.type)) {
			_tableColumns.splice(1, 1);
		} else if (_EXCLUDE_TITLE_TYPE.includes(props.type)) {
			_tableColumns.splice(2, 1);
		}
		setTableColumns(_tableColumns);
	}, []);

	async function getLanguage() {
		await fetch(ApiUrl._API_GET_LANGUAGE, {
			method: ApiKey._API_GET,
			headers: {
				"Content-Type": ApiKey._API_FORM_URLENCODED,
				"X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
			},
		})
			.then((response) => response.json())
			.then((responseJson) => {
				if (responseJson[ApiKey._API_SUCCESS_KEY]) {
					const languageList = [];
					responseJson[ApiKey._API_DATA_KEY].forEach(function (language) {
						languageList.push({ label: language.name, value: language.id });
					});
					setLanguageOption(languageList);
				}
			});
	}
	async function getPlatform() {
		await fetch(ApiUrl._API_GET_MARQUEE_TAG_CONTENT, {
			method: ApiKey._API_GET,
			headers: {
				"Content-Type": ApiKey._API_FORM_URLENCODED,
				"X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
			},
		})
			.then((response) => response.json())
			.then((responseJson) => {
				if (responseJson[ApiKey._API_SUCCESS_KEY]) {
				}
			});
	}
	/// <summary>
	/// Author : -
	/// </summary>
	const getConfigLanguage = async () => {
		await fetch(
			ApiUrl._API_GET_CONFIG_LANGUAGE_BY_TYPE_ID +
				"?type=" +
				props.type +
				"&id=" +
				props.id,
			{
				method: ApiKey._API_GET,
				headers: {
					"Content-Type": ApiKey._API_FORM_URLENCODED,
					"X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
				},
			}
		)
			.then((response) => response.json())
			.then((responseJson) => {
				if (responseJson[ApiKey._API_SUCCESS_KEY]) {
					setData(responseJson[ApiKey._API_DATA_KEY]);
				}
			});
	};
	async function getBonusDepositContent() {
		var apiUrl = ApiUrl._API_GET_BONUS_DEPOSIT_LANGUAGE;
		apiUrl += "?id=" + props.id;
		fetch(apiUrl, {
			method: ApiKey._API_GET,
			headers: {
				"Content-Type": ApiKey._API_FORM_URLENCODED,
				"X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
			},
		})
			.then((response) => response.json())
			.then((responseJson) => {
				if (responseJson[ApiKey._API_SUCCESS_KEY]) {
					setBonusDepositData(responseJson[ApiKey._API_DATA_KEY]);
				}
			});
	}

	const editSubmit = async (data) => {
		let params = {
			bonusName: editBonusName,
			bonusDepositId: bonusDepositId,
			languageId: editLanguageId,
		};
		if (!stringIsNullOrEmpty(editBonusImage)) {
			params["bonusImage"] = editBonusImage;
		}

		if (!stringIsNullOrEmpty(editPopupImage)) {
			params["popupImage"] = editPopupImage;
		}

		let formBody = createMultiPartFormBody(params);
		_dispatch(setBusy());

		let responseJson = await ApiEngine.post(
			ApiUrl._API_UPDATE_BONUS_DEPOSIT_LANGUAGE,
			formBody
		);
		_dispatch(setIdle());
		_dispatch(
			showMessage(
				responseJson[ApiKey._API_SUCCESS_KEY],
				t(responseJson[ApiKey._API_MESSAGE_KEY])
			)
		);
		setEditModal(false);
		getBonusDepositContent();
	};

	return (
		<div>
			<div className="row">
				<ConfigurationLanguageDetail
					visible={languageDetailModalVisibility}
					onFinish={() => {
						setLanguageDetailModalVisibility(false);
						getConfigLanguage();
					}}
					dataTypeId={props.id}
					languageDetailId={languageDetailId}
					languageType={props.type}
				/>
			</div>
			<Panel>
				<PanelBody>
					<button
						type="button"
						onClick={() => {
							setLanguageDetailId("");
							setIsOpen(true);
						}}
						className="btn btn-purple"
					>
						{t("ADD_LANGUAGE")}
					</button>
					<ReactTable
						data={bonusDepositData}
						columns={_tableColumns}
						minRows={1}
					/>
				</PanelBody>
			</Panel>
			<Modal
				fade={false}
				contentClassName="modal-brand modal-bottom modal-numpad withdrawal"
				isOpen={isOpen}
				centered
			>
				<form onSubmit={handleSubmit(onSubmit)}>
					<ModalHeader>{t("ADD_LANGUAGE")}</ModalHeader>
					<ModalBody style={{ minHeight: "30vh" }}>
						<div className="row">
							<div className="col-lg-12">
								<div className="form-group row m-b-15">
									<label className="col-md-4 col-form-label">
										<b>{t("Title")}</b>
									</label>
									<div className="col-md-7">
										<input
											type="text"
											className="form-control"
											placeholder={t("PLEASE_ENTER_BONUS_NAME")}
											name="bonusName"
											ref={register({
												required: "PLEASE_ENTER_BONUS_NAME",
											})}
											value={bonusName}
											onChange={(e) => setBonusName(e.target.value)}
										/>
									</div>
								</div>
							</div>
							<div className="col-lg-12">
								<div className="form-group row m-b-15">
									<label className="col-md-4 col-form-label">
										<b>{t("LANGUAGE")}</b>
									</label>
									<div className="col-md-7">
										<Select
											name="languageId"
											options={languageOption}
											placeholder={
												languageOption.filter(
													(option) => option.value == languageId
												)[0] !== undefined
													? languageOption.filter(
															(option) => option.value == languageId
													  )[0].label
													: ""
											}
											value={languageOption.filter(
												(option) => option.value == languageId
											)}
											onChange={(e) => {
												setLanguageId(e.value);
												setValue("languageId", e.value);
											}}
										/>
									</div>
								</div>
							</div>
							<div className="col-lg-12">
								<div className="form-group row m-b-15">
									<label className="col-md-4 col-form-label">
										<b>{t("BONUS_IMAGE")}</b>
									</label>
									<div className="col-md-7">
										<Dropzone
											accept={"image/*"}
											onDrop={(acceptedFiles) => bonusImageDrop(acceptedFiles)}
										>
											{({ getRootProps, getInputProps }) => (
												<section>
													<div
														className="dropzone"
														style={{
															minHeight: "200px",
															textAlign: "center",
														}}
														{...getRootProps()}
													>
														<input {...getInputProps()} />
														<h4 style={{ color: "grey" }}>
															{t(
																"DRAG_N_DROP_SOME_FILES_HERE_OR_CLICK_TO_SELECT_FILES"
															)}
														</h4>
														{!stringIsNullOrEmpty(bonusImage) && (
															<aside className="thumbsContainer">
																<div className="thumb">
																	<div className="thumbInner">
																		<img
																			src={bonusImage}
																			className="dropzone-img"
																		/>
																	</div>
																</div>
															</aside>
														)}
													</div>
												</section>
											)}
										</Dropzone>
									</div>
								</div>
							</div>
							<div className="form-group row m-b-15">
								<label className="col-md-4 col-form-label">
									<b>{t("POPUP_IMAGE")}</b>
								</label>
								<div className="col-md-7">
									<Dropzone
										accept={"image/*"}
										onDrop={(acceptedFiles) => popupImageDrop(acceptedFiles)}
									>
										{({ getRootProps, getInputProps }) => (
											<section>
												<div
													className="dropzone"
													style={{
														minHeight: "200px",
														textAlign: "center",
													}}
													{...getRootProps()}
												>
													<input {...getInputProps()} />
													<h4 style={{ color: "grey" }}>
														{t(
															"DRAG_N_DROP_SOME_FILES_HERE_OR_CLICK_TO_SELECT_FILES"
														)}
													</h4>
													{!stringIsNullOrEmpty(popupImage) && (
														<aside className="thumbsContainer">
															<div className="thumb">
																<div className="thumbInner">
																	<img
																		src={popupImage}
																		className="dropzone-img"
																	/>
																</div>
															</div>
														</aside>
													)}
												</div>
											</section>
										)}
									</Dropzone>
								</div>
							</div>
						</div>
					</ModalBody>
					<ModalFooter>
						<button
							type="button"
							className="btn btn-white"
							onClick={() => setIsOpen(false)}
						>
							{t("CLOSE")}
						</button>
						<button type="submit" className="btn btn-primary">
							{t("SUBMIT")}
						</button>
					</ModalFooter>
				</form>
			</Modal>
			<Modal
				fade={false}
				contentClassName="modal-brand modal-bottom modal-numpad withdrawal"
				isOpen={editModal}
				centered
			>
				<form onSubmit={handleSubmit(editSubmit)}>
					<ModalHeader>{t("EDIT_LANGUAGE")}</ModalHeader>
					<ModalBody style={{ minHeight: "30vh" }}>
						<div className="row">
							<div className="col-lg-12">
								<div className="form-group row m-b-15">
									<label className="col-md-4 col-form-label">
										<b>{t("Title")}</b>
									</label>
									<div className="col-md-7">
										<input
											type="text"
											className="form-control"
											placeholder={t("PLEASE_ENTER_BONUS_NAME")}
											name="editBonusName"
											ref={register({
												required: "PLEASE_ENTER_BONUS_NAME",
											})}
											value={editBonusName}
											onChange={(e) => setEditBonusName(e.target.value)}
										/>
									</div>
								</div>
							</div>
							<div className="col-lg-12">
								<div className="form-group row m-b-15">
									<label className="col-md-4 col-form-label">
										<b>{t("LANGUAGE")}</b>
									</label>
									<div className="col-md-7">
										<Select
											name="editLanguageId"
											options={languageOption}
											placeholder={
												languageOption.filter(
													(option) => option.value == editLanguageId
												)[0] !== undefined
													? languageOption.filter(
															(option) => option.value == editLanguageId
													  )[0].label
													: ""
											}
											value={languageOption.filter(
												(option) => option.value == editLanguageId
											)}
											onChange={(e) => {
												setEditLanguageId(e.value);
												setValue("editLanguageId", e.value);
											}}
										/>
									</div>
								</div>
							</div>
							<div className="col-lg-12">
								<div className="form-group row m-b-15">
									<label className="col-md-4 col-form-label">
										<b>{t("BONUS_IMAGE")}</b>
									</label>
									<div className="col-md-7">
										<Dropzone
											accept={"image/*"}
											onDrop={(acceptedFiles) =>
												editBonusImageDrop(acceptedFiles)
											}
										>
											{({ getRootProps, getInputProps }) => (
												<section>
													<div
														className="dropzone"
														style={{
															minHeight: "200px",
															textAlign: "center",
														}}
														{...getRootProps()}
													>
														<input {...getInputProps()} />
														<h4 style={{ color: "grey" }}>
															{t(
																"DRAG_N_DROP_SOME_FILES_HERE_OR_CLICK_TO_SELECT_FILES"
															)}
														</h4>
														{!stringIsNullOrEmpty(editBonusImage) && (
															<aside className="thumbsContainer">
																<div className="thumb">
																	<div className="thumbInner">
																		<img
																			src={editBonusImage}
																			className="dropzone-img"
																		/>
																	</div>
																</div>
															</aside>
														)}
													</div>
												</section>
											)}
										</Dropzone>
										<button
											type="button"
											className="btn btn-danger"
											onClick={() => {
												setEditBonusImage("");
												setEditBonusImageFile();
											}}
										>
											{t("REMOVE_IMAGE")}
										</button>
									</div>
								</div>
							</div>
							<div className="form-group row m-b-15">
								<label className="col-md-4 col-form-label">
									<b>{t("POPUP_IMAGE")}</b>
								</label>
								<div className="col-md-7">
									<Dropzone
										accept={"image/*"}
										onDrop={(acceptedFiles) =>
											editPopupImageDrop(acceptedFiles)
										}
									>
										{({ getRootProps, getInputProps }) => (
											<section>
												<div
													className="dropzone"
													style={{
														minHeight: "200px",
														textAlign: "center",
													}}
													{...getRootProps()}
												>
													<input {...getInputProps()} />
													<h4 style={{ color: "grey" }}>
														{t(
															"DRAG_N_DROP_SOME_FILES_HERE_OR_CLICK_TO_SELECT_FILES"
														)}
													</h4>
													{!stringIsNullOrEmpty(editPopupImage) && (
														<aside className="thumbsContainer">
															<div className="thumb">
																<div className="thumbInner">
																	<img
																		src={editPopupImage}
																		className="dropzone-img"
																	/>
																</div>
															</div>
														</aside>
													)}
												</div>
											</section>
										)}
									</Dropzone>
									<button
										type="button"
										className="btn btn-danger"
										onClick={() => {
											setEditPopupImage("");
											setEditPopupImageFile();
										}}
									>
										{t("REMOVE_IMAGE")}
									</button>
								</div>
							</div>
						</div>
					</ModalBody>
					<ModalFooter>
						<button
							type="button"
							className="btn btn-white"
							onClick={() => setEditModal(false)}
						>
							{t("CLOSE")}
						</button>
						<button type="submit" className="btn btn-primary">
							{t("SUBMIT")}
						</button>
					</ModalFooter>
				</form>
			</Modal>
		</div>
	);
};

export default BonusDepositDetailLanguage;
