import React, { useState, useEffect } from "react";
import ReactTable from "../../components/constantComponent/reactTable/ReactTable";
import { Panel, PanelBody } from "./../../components/panel/panel.jsx";
import { useHistory } from "react-router-dom";
import {
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
	UncontrolledButtonDropdown,
	DropdownToggle,
	DropdownMenu,
	DropdownItem,
	Dropdown,
} from "reactstrap";
import NavigationButton from "../../components/constantComponent/button/NavigationButton";
import {
	createFormBody,
	createMultiPartFormBody,
	navigateTo,
	numberWithCurrencyFormat,
	stringIsNullOrEmpty,
} from "../../util/Util";
import {
	ApiUrl,
	ApiKey,
	WebUrl,
	AccessRight,
	LanguageKey,
	DEFAULT_PAGE_SIZE,
} from "../../util/Constant";
import ApiEngine from "../../util/ApiEngine";
import { checkIfPermissionExist } from "../../util/PermissionChecker";
import { setBusy, setIdle, showMessage } from "../../redux/AppAction";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { fetch } from "whatwg-fetch";
import moment from "moment";
import { DateRangePicker } from "react-bootstrap-daterangepicker";
import DatePicker from "react-datepicker";
import { AsyncPaginate } from "react-select-async-paginate";

/// <summary>
/// Author : -
/// </summary>

const ManageDCRedemption = (props) => {
	const { t } = useTranslation(LanguageKey._PRIMARY);
	let _history = useHistory();
	const _dispatch = useDispatch();
	const [redemptionData, setRedemptionData] = useState([]);
	const [selectedMember, setSelectedMember] = useState("");
	const [selectedItemName, setSelectedItemName] = useState("");
	const [labelUsername, setLabelUsername] = useState("All");
	const [labelItemName, setLabelItemName] = useState("All");
	const [searchTrigger, setSearchTrigger] = useState("");
	const [startDate, setStartDate] = useState(
		moment().startOf("week").format("YYYY-MM-DD HH:mm:ss")
	);
	const [endDate, setEndDate] = useState(
		moment().endOf("week").format("YYYY-MM-DD HH:mm:ss")
	);
	const _SELECTED_MEMBER_KEY = "selectedMemberKey";
	const _SELECTED_ITEM_KEY = "selectedItemKey";
	const _SELECTED_STATUS_KEY = "selectedStatusKey";
	const [selectedStatus, setSelectedStatus] = useState("");
	const [labelStatus, setLabelStatus] = useState("All");

	let _tableColumns = [
		{
			Header: "ITEM_NAME",
			accessor: "name",
			Cell: ({ row }) => (
				<div style={{ textAlign: "center" }}>{row.original.name}</div>
			),
		},
		{
			Header: "USERNAME",
			accessor: "username",
			Cell: ({ row }) => (
				<div style={{ textAlign: "center" }}>{row.original.username}</div>
			),
		},
		{
			Header: "PHONE_NUMBER",
			accessor: "phoneNumber",
			Cell: ({ row }) => (
				<div style={{ textAlign: "center" }}>{row.original.phoneNumber}</div>
			),
			disableFilters: true,
		},
		{
			Header: "EMAIL",
			accessor: "email",
			Cell: ({ row }) => (
				<div style={{ textAlign: "center" }}>{row.original.email}</div>
			),
			disableFilters: true,
		},
		{
			Header: "POINTS_SPENDED",
			accessor: "pointsNeededOnRedemption",
			Cell: ({ row }) => (
				<div style={{ textAlign: "center" }}>
					{row.original.pointsNeededOnRedemption}
				</div>
			),
			disableFilters: true,
		},
		{
			Header: "REDEEM_TIME",
			accessor: "redeemTime",
			Cell: ({ row }) => {
				return (
					<div style={{ textAlign: "center" }}>
						{moment(row.original.redeemTime).format("YYYY-MM-DD HH:mm:ss")}
					</div>
				);
			},
			disableFilters: true,
		},
		{
			Header: "STATUS",
			accessor: "received",
			Cell: ({ row }) => (
				<div style={{ textAlign: "center" }}>
					{console.log(row.original)}
					{row.original.received == 1 && (
						<div style={{ textAlign: "center" }}>
							<i className="icon-green-tick fa fa-check"></i>
							<br />
							{moment(row.original.updatedTime).format("YYYY-MM-DD HH:mm:ss")}
						</div>
					)}
					{row.original.received == 0 && row.original.processing == 2 && (
						<div style={{ textAlign: "center" }}>
							<span class="badge badge-secondary badge-danger">{t("CANCELLED")}</span>
						</div>
					)}
					{row.original.processing == 1 && row.original.received == 0 && (
						<div style={{ textAlign: "center" }}>{t("PROCESSING")}</div>
					)}
				</div>
			),
			disableSortBy: true,
			disableFilters: true,
		},
		{
			id: "action",
			Header: "",
			Cell: ({ row }) =>
				checkIfPermissionExist(AccessRight._DC_REDEMPTION_PERMISSION, true) &&
				row.original.received == 0 && row.original.processing != 2 && (
					<div className="btn-group m-r-5 m-b-5">
						<UncontrolledButtonDropdown>
							<DropdownToggle caret color="default">
								<i className="fas fa-cog"></i>
							</DropdownToggle>
							<DropdownMenu container="body" flip={false} right>
								<DropdownItem onClick={() => updateReceived(row.original.id)}>
									<span className="text-warning">{t("MARK_RECEIVED")}</span>
								</DropdownItem>
								<DropdownItem onClick={() => updateProcessing(row.original.id)}>
									<span className="text-warning">{t("MARK_PROCESSING")}</span>
								</DropdownItem>
									<DropdownItem onClick={() => updateCancel(row.original.id)}>
										<span className="text-danger">{t("CANCEL_REDEMPTION")}</span>
									</DropdownItem>
							</DropdownMenu>
						</UncontrolledButtonDropdown>
					</div>
				),
			disableSortBy: true,
			disableFilters: true,
			width: 100,
			style: { overflow: "visible" },
		},
	];

	const updateReceived = async (id) => {
		_dispatch(setBusy());
		let responseJson = await ApiEngine.post(
			ApiUrl._API_UPDATE_DC_REDEMPTION + "?id=" + id
		);

		_dispatch(setIdle());
		_dispatch(
			showMessage(
				responseJson[ApiKey._API_SUCCESS_KEY],
				t(responseJson[ApiKey._API_MESSAGE_KEY])
			)
		);
		init();
	};

	const updateProcessing = async (id) => {
		_dispatch(setBusy());
		let responseJson = await ApiEngine.post(
			ApiUrl._API_UPDATE_PROCESSING_DC_REDEMPTION + "?id=" + id
		);

		_dispatch(setIdle());
		_dispatch(
			showMessage(
				responseJson[ApiKey._API_SUCCESS_KEY],
				t(responseJson[ApiKey._API_MESSAGE_KEY])
			)
		);
		init();
	};

	const updateCancel = async (id) => {
		_dispatch(setBusy());
		let responseJson = await ApiEngine.post(
			ApiUrl._API_UPDATE_CANCEL_DC_REDEMPTION + "?id=" + id
		);

		_dispatch(setIdle());
		_dispatch(
			showMessage(
				responseJson[ApiKey._API_SUCCESS_KEY],
				t(responseJson[ApiKey._API_MESSAGE_KEY])
			)
		);
		init();
	};

	const init = async () => {
		let apiUrl =
			ApiUrl._API_GET_DC_REDEMPTION_RECORD +
			"?startDate=" +
			startDate +
			"&endDate=" +
			endDate;

		if (selectedMember !== "") {
			apiUrl += "&memberId=" + selectedMember;
		}

		if (selectedItemName !== "") {
			apiUrl += "&itemId=" + selectedItemName;
		}

		if (selectedStatus !== "") {
			if (selectedStatus == 1) {
				apiUrl += "&itemReceived=1";
				apiUrl += "&itemProcessing=0";
			}
			if (selectedStatus == 2) {
				apiUrl += "&itemReceived=0";
				apiUrl += "&itemProcessing=1";
			} else {
				apiUrl += "&itemReceived=0";
				apiUrl += "&itemProcessing=0";
			}
		}

		let responseJson = await ApiEngine.get(apiUrl);

		if (responseJson[ApiKey._API_SUCCESS_KEY]) {
			setRedemptionData(responseJson[ApiKey._API_DATA_KEY]);
		}
	};

	const handleEvent = (event, picker) => {
		setStartDate(moment(picker.startDate).format("YYYY-MM-DD HH:mm:ss"));
		setEndDate(moment(picker.endDate).format("YYYY-MM-DD HH:mm:ss"));
	};

	const searchBtnHandler = async () => {
		
		let apiUrl =
			ApiUrl._API_GET_DC_REDEMPTION_RECORD +
			"?startDate=" +
			startDate +
			"&endDate=" +
			endDate;

		if (selectedMember !== "") {
			apiUrl += "&memberId=" + selectedMember;
		}

		if (selectedItemName !== "") {
			apiUrl += "&itemId=" + selectedItemName;
		}

		if (selectedStatus !== "") {
			if (selectedStatus == 1) {
				apiUrl += "&itemReceived=1";
				apiUrl += "&itemProcessing=0";
			}
			if (selectedStatus == 2) {
				apiUrl += "&itemReceived=0";
				apiUrl += "&itemProcessing=1";
			} else {
				apiUrl += "&itemReceived=0";
				apiUrl += "&itemProcessing=0";
			}
		}

		setSearchTrigger(apiUrl);
	};

	useEffect(async () => {
		_dispatch(setBusy());
		let responseJson = await ApiEngine.get(searchTrigger);

		if (responseJson[ApiKey._API_SUCCESS_KEY]) {
			console.log(responseJson[ApiKey._API_DATA_KEY]);
			setRedemptionData(responseJson[ApiKey._API_DATA_KEY]);
		}

		_dispatch(setIdle());
	}, [searchTrigger]);

	/// <summary>
	/// Author : -
	/// </summary>
	useEffect(() => {
		init();
	}, []);

	async function getMemberList(search, loadOptions, { page }) {
		var responseJson = await ApiEngine.get(
			`${ApiUrl._API_GET_USER_MEMBER_LIST}?&start=${
				(page - 1) * DEFAULT_PAGE_SIZE
			}&count=${DEFAULT_PAGE_SIZE}&keyword=${search}`
		);

		if (responseJson[ApiKey._API_SUCCESS_KEY]) {
			let data = responseJson[ApiKey._API_DATA_KEY]["data"];
			let formattedData = data.map((m) => {
				return { label: m.username, value: m.id };
			});
			formattedData.unshift({ label: "All", value: "" });
			return {
				options: formattedData,
				hasMore: formattedData.length == DEFAULT_PAGE_SIZE,
				additional: {
					page: page + 1,
				},
			};
		}
	}

	async function getItemList(search, loadOptions, { page }) {
		let formattedData = [];
		var responseJson = await ApiEngine.get(
			`${ApiUrl._API_GET_DC_ITEM_NAME_LIST}?&start=${
				(page - 1) * DEFAULT_PAGE_SIZE
			}&count=${DEFAULT_PAGE_SIZE}&keyword=${search}`
		);

		if (responseJson[ApiKey._API_SUCCESS_KEY]) {
			let data = responseJson[ApiKey._API_DATA_KEY]["data"];
			formattedData = data.map((m) => {
				return { label: m.itemName, value: m.id };
			});

			return {
				options: formattedData,
				hasMore: formattedData.length == DEFAULT_PAGE_SIZE,
				additional: {
					page: page + 1,
				},
			};
		}
	}

	async function getItemStatus(search, loadOptions, { page }) {
		let formattedData = [
			{
				label: t("RECEIVED"),
				value: 1,
			},
			{
				label: t("PROCESSING"),
				value: 2,
			},
			{
				label: t("PENDING"),
				value: 0,
			},
		];

		return {
			options: formattedData,
			hasMore: formattedData.length == DEFAULT_PAGE_SIZE,
			additional: {
				page: page + 1,
			},
		};
	}

	return (
		<div>
			<h1 className="page-header">{t("REDEMPTIONS")}</h1>
			<Panel>
				<PanelBody>
					<div className="row">
						<div
							className="col-lg-4"
							style={{ display: "flex", alignItems: "end" }}
						>
							<div
								className="form-group"
								style={{
									display: "flex",
									width: "100%",
									flexFlow: "nowrap",
								}}
							>
								<div
									className="input-group"
									style={{ width: "100%", flexFlow: "nowrap" }}
								>
									<div className="input-group-prepend">
										<span className="input-group-text">
											<i className="fa fa-calendar"></i>
										</span>
									</div>
									<div style={{ width: "80%" }}>
										<DateRangePicker
											containerStyles={{ width: "100%" }}
											startDate={startDate}
											endDate={endDate}
											onApply={handleEvent}
											alwaysShowCalendars={true}
											locale={{ format: "YYYY-MM-DD HH:mm:ss" }}
											timePicker={true}
											timePickerSeconds={true}
											ranges={{
												Today: [moment().startOf("day"), moment().endOf("day")],
												Yesterday: [
													moment().subtract(1, "days").startOf("day"),
													moment().subtract(1, "days").endOf("day"),
												],
												"Last 7 Days": [
													moment().subtract(6, "days").startOf("day"),
													moment().endOf("day"),
												],
												"Last 30 Days": [
													moment().subtract(29, "days").startOf("day"),
													moment().endOf("day"),
												],
												"This Month": [
													moment().startOf("month"),
													moment().endOf("month"),
												],
												"Last Month": [
													moment().subtract(1, "month").startOf("month"),
													moment().subtract(1, "month").endOf("month"),
												],
												"This Year": [
													moment().startOf("year"),
													moment().endOf("year"),
												],
											}}
										>
											<input
												type="text"
												className="form-control"
												value={startDate + " - " + endDate}
											/>
										</DateRangePicker>
									</div>
								</div>
							</div>
						</div>
						<div className="col-lg-4">
							<div className="form-group">
								<label>
									<b>{t("USERNAME")}</b>
								</label>
								{
									<AsyncPaginate
										placeholder={labelUsername}
										debounceTimeout={250}
										loadOptions={getMemberList}
										additional={{
											page: 1,
										}}
										value={selectedMember}
										onChange={(e) => {
											if (!stringIsNullOrEmpty(e.value)) {
												sessionStorage.setItem(_SELECTED_MEMBER_KEY, e.value);
											} else {
												sessionStorage.removeItem(_SELECTED_MEMBER_KEY);
											}
											setLabelUsername(e.label);
											setSelectedMember(e.value);
										}}
									/>
								}
							</div>
						</div>
						<div className="col-lg-4">
							<div className="form-group">
								<label>
									<b>{t("ITEM_NAME")}</b>
								</label>
								{
									<AsyncPaginate
										placeholder={labelItemName}
										debounceTimeout={250}
										loadOptions={getItemList}
										additional={{
											page: 1,
										}}
										value={selectedItemName}
										onChange={(e) => {
											if (!stringIsNullOrEmpty(e.value)) {
												sessionStorage.setItem(_SELECTED_ITEM_KEY, e.value);
											} else {
												sessionStorage.removeItem(_SELECTED_ITEM_KEY);
											}
											setLabelItemName(e.label);
											setSelectedItemName(e.value);
										}}
									/>
								}
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-lg-4">
							<div className="form-group">
								<label>
									<b>{t("STATUS")}</b>
								</label>
								{
									<AsyncPaginate
										placeholder={labelStatus}
										debounceTimeout={250}
										loadOptions={getItemStatus}
										additional={{
											page: 1,
										}}
										value={selectedStatus}
										onChange={(e) => {
											if (!stringIsNullOrEmpty(e.value)) {
												sessionStorage.setItem(_SELECTED_STATUS_KEY, e.value);
											} else {
												sessionStorage.removeItem(_SELECTED_STATUS_KEY);
											}
											setLabelStatus(e.label);
											setSelectedStatus(e.value);
										}}
									/>
								}
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-lg-3">
							<button
								type="button"
								className="btn btn-primary"
								onClick={() => {
									//setSearch(true);
									searchBtnHandler();
								}}
							>
								<i className="fas fa-search"></i> {t("SEARCH")}
							</button>
						</div>
					</div>
					<hr />
					<ReactTable
						filterable
						data={redemptionData}
						columns={_tableColumns}
					/>
				</PanelBody>
			</Panel>
		</div>
	);
};

export default ManageDCRedemption;
