import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import NavigationButton from "../../components/constantComponent/button/NavigationButton";
import ReactTable from "../../components/constantComponent/reactTable/ReactTable";
import {
	ApiKey,
	ApiUrl,
	WebUrl,
	TransactionStatus,
	TransactionType,
	LanguageKey,
	ReadWritePermission,
	AccessRight,
} from "../../util/Constant";
import {
	stringIsNullOrEmpty,
	numberWithCommas,
	navigateTo,
	createMultiPartFormBody
} from "../../util/Util";
import moment from "moment";
import { useTranslation } from "react-i18next";
import ApiEngine from "../../util/ApiEngine";
import { useDispatch, useSelector } from "react-redux";
import { setBusy, setIdle, showMessage } from "../../redux/AppAction";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { useForm } from "react-hook-form";
import { checkIfPermissionExist } from "../../util/PermissionChecker";
import Dropzone from "react-dropzone";

/// <summary>
/// Author : -
/// </summary>
const TransactionDetail = (props) => {
	const { t } = useTranslation(LanguageKey._PRIMARY);
	let _history = useHistory();
	let _dispatch = useDispatch();
	const _location = useLocation();
	const authState = useSelector((state) => state.authState);
	const { register, unregister, handleSubmit, errors } = useForm();

	const [txn, setTxn] = useState();
	const [transactionDetail, setTransactionDetail] = useState([]);
	const [auditDetail, setAuditDetail] = useState([]);
	const [systemBalance, setSystemBalance] = useState([]);
	const [previousTransaction, setPreviousTransaction] = useState([]);
	const [withdrawalModal, setWithdrawalModal] = useState(false);

	const [showDetails, setShowDetails] = useState(true);

	const [fullName, setFullName] = useState("");

	const [bankAccountOption, setBankAccountOption] = useState([]);

	const [auditBankOption, setAuditBankOption] = useState([]);
	const [auditBankId, setAuditBankId] = useState();
	const [auditBankAccountId, setAuditBankAccountId] = useState();
	const [fetchPreviousDataUrl, setFetchPreviousDataUrl] = useState(
		ApiUrl._API_GET_PREVIOUS_TRANSACTION +
			"?transactionId=" +
			_location.state.id
	);
	let role1IsAllowed = checkIfPermissionExist(
		AccessRight._PAYMENT_GATEWAY_APPROVAL_PERMISSION,
		true
	);
	let role3IsAllowed = checkIfPermissionExist(
		AccessRight._TRANSACTION_APPROVAL_PERMISSION,
		true
	);
	let role4IsAllowed = checkIfPermissionExist(
		AccessRight._TRANSACTION_REJECTION_PERMISSION,
		true
	);
	const [creditRemarkDetail, setCreditRemarkDetail] = useState([]);
	const [adjustedAmount, setAdjustedAmount] = useState(0);
	const [adjustedAmountError, setAdjustedAmountError] = useState(false);
	const [wingWeiluyApprovalModal, setWingWeiluyApprovalModal] = useState(false);

	const [receiptImage, setReceiptImage] = useState();
	const [receiptFile, setReceiptFile] = useState([]);
	const [isNewReceiptImage, setIsNewReceiptImage] = useState(false);

	/// <summary>
	/// Author : -
	/// </summary>
	useEffect(() => {
		init();
	}, []);

	/// <summary>
	/// Author : -
	/// </summary>
	async function getBankAccount() {
		var apiUrl = ApiUrl._API_GET_BANK_ACCOUNT_BY_BANK_ID;
		apiUrl += "?bankId=" + auditBankId + "&withdrawal=" + true;
		let responseJson = await ApiEngine.get(apiUrl);
		if (responseJson[ApiKey._API_SUCCESS_KEY]) {
			const bankAccountList = [];
			responseJson[ApiKey._API_DATA_KEY].forEach(function (bankAccount) {
				if (bankAccount.withdrawalStatus) {
					bankAccountList.push({
						label: bankAccount.accountNumber,
						value: bankAccount.id,
					});
				}
			});

			setBankAccountOption(bankAccountList);
		} else {
			_dispatch(showMessage(false, t(responseJson[ApiKey._API_MESSAGE_KEY])));
		}
	}

	/// <summary>
	/// Author : -
	/// </summary>
	useEffect(() => {
		if (!stringIsNullOrEmpty(auditBankId)) {
			getBankAccount();
		}
	}, [auditBankId]);

	/// <summary>
	/// Author : -
	/// </summary>
	const getTransactionDetail = async (transactionId, vfullname) => {
		let responseJson = await ApiEngine.get(
			ApiUrl._API_GET_TRANSACTION_BY_ID + "?transactionId=" + transactionId
		);
		if (responseJson[ApiKey._API_SUCCESS_KEY]) {
			let transaction = responseJson[ApiKey._API_DATA_KEY]["transaction"]
				? responseJson[ApiKey._API_DATA_KEY]["transaction"]
				: responseJson[ApiKey._API_DATA_KEY];
			if (!stringIsNullOrEmpty(transaction)) {
				setTxn(transaction);

				// before approve, fullName is null so use vfullname
				// after approve, vfullname is null so will use fullName
				if (vfullname == null) {
					vfullname = fullName;
				}

				if (
					transaction.lockedByFullName != vfullname &&
					transaction.lockedByFullName != null
				) {
					_dispatch(
						showMessage(false, "Transaction has been locked by another user")
					);
					setShowDetails(false);
					return;
				}

				let transactionDetails = [
					{ label: "TRANSACTION_ID", value: transaction.id },
					{
						label: "TRANSACTION_TIME",
						value: moment(transaction.transactionDateTime).format(
							"YYYY-MM-DD HH:mm:ss"
						),
					},
					{
						label: "USERNAME",
						value: transaction.memberUsername,
						id: transaction.memberId,
					},
					{
						label: "SUBMIT_TIME",
						value: moment(transaction.submitTime).format("YYYY-MM-DD HH:mm:ss"),
					},
					{ label: "EN_FULLNAME", value: transaction.memberFullName },
					{ label: "CN_FULLNAME", value: transaction.memberCNFullName },
					{ label: "IP", value: transaction.ipAddress },
					{ label: "TRANSACTION_AMOUNT", value: transaction.amount },
					{ label: "STATUS", value: transaction.approvalStatusString },
					{
						label: transaction.transactionType.toUpperCase() + "_CHANNEL",
						value:
							transaction.transactionTypeId == TransactionType._UPLINE_TRANSFER
								? "Internal"
								: transaction.channel,
					},
					{ label: "PANEL", value: transaction.panel },
				];

				if (transaction.transactionTypeId == TransactionType._DEPOSIT) {
					let bankAccount = responseJson[ApiKey._API_DATA_KEY]["bankAccount"];
					if (!stringIsNullOrEmpty(bankAccount)) {
						let extraDetails = [
							{
								label: "RECEIPT_REFERENCE",
								value: responseJson[ApiKey._API_DATA_KEY]["receiptReference"],
							},
							{
								label: "BANK",
								value: bankAccount.bankName + " - " + bankAccount.accountNumber,
							},
							{
								label: "RECEIPT",
								value:
									responseJson[ApiKey._API_DATA_KEY]["receipt"] != null
										? t("HAVING_RECEIPT")
										: t("NO_RECEIPT_SELECTED"),
							},
							{
								label: "BONUS",
								value:
									responseJson[ApiKey._API_DATA_KEY]["bonus"] != null
										? responseJson[ApiKey._API_DATA_KEY]["bonus"].name
										: t("NO_BONUS_SELECTED"),
							},
						];
						transactionDetails = transactionDetails.concat(extraDetails);
					}
				} else if (
					transaction.transactionTypeId == TransactionType._WITHDRAWAL
				) {
					let extraDetails = [
						{
							label:
								transaction.channel == "TOPKASH" ? "PLATFORM" : "BANK_NAME",
							value: responseJson[ApiKey._API_DATA_KEY]["bankName"],
						},
						{
							label:
								transaction.channel == "TOPKASH"
									? "TOPKASH_USERNAME"
									: "BANK_ACCOUNT_HOLDER_NAME",
							value:
								responseJson[ApiKey._API_DATA_KEY]["bankAccountHolderName"],
						},
						{
							label:
								transaction.channel == "TOPKASH"
									? "TOPKASH_ID"
									: "BANK_ACCOUNT_NUMBER",
							value: responseJson[ApiKey._API_DATA_KEY]["bankAccountNumber"],
						},
					];
					transactionDetails = transactionDetails.concat(extraDetails);
				} else if (
					transaction.transactionTypeId == TransactionType._ADJUSTMENT
				) {
					transactionDetails.push({
						label: "REMARK",
						value: transaction.remark,
					});
				} else if (transaction.transactionTypeId == TransactionType._BONUS) {
					let bonus = responseJson[ApiKey._API_DATA_KEY]["bonus"];
					if (!stringIsNullOrEmpty(bonus)) {
						let extraDetails = [
							{
								label: "DEPOSIT_ID",
								value:
									responseJson[ApiKey._API_DATA_KEY]["depositId"] != null
										? responseJson[ApiKey._API_DATA_KEY]["depositId"]
										: null,
							},
							{
								label: "DEPOSIT_AMOUNT",
								value: responseJson[ApiKey._API_DATA_KEY]["depositAmount"],
							},
							{ label: "MINIMUM_DEPOSIT", value: bonus.minimumDeposit },
							{
								label: "MAXIMUM_BONUS_AMOUNT",
								value: bonus.maximumBonusAmount,
							},
							{ label: "BONUS_TYPE", value: bonus.bonusType },
							{ label: "BONUS_METHOD", value: bonus.methodName },
							{
								label: "BONUS_VALUE",
								value: bonus.amount + " " + bonus.methodName,
							},
							{ label: "BONUS_CAMPAIGN", value: bonus.code },
							{ label: "BONUSTURNOVER", value: "X " + bonus.turnover },
						];
						transactionDetails = transactionDetails.concat(extraDetails);
					}
				} else if (
					transaction.transactionTypeId == TransactionType._UPLINE_TRANSFER
				) {
					let extraDetails = [
						{
							label: "TARGET_MEMBER",
							value: responseJson[ApiKey._API_DATA_KEY]["memberUserFullName"],
						},
					];
					transactionDetails = transactionDetails.concat(extraDetails);
				}

				setTransactionDetail(transactionDetails);

				let auditDetails = [
					{ label: "AUDITED_BY", value: transaction.auditedFullName ?? "-" },
					{
						label: "AUDITED_DATE_TIME",
						value: transaction.auditedTime
							? moment(transaction.auditedTime).format("YYYY-MM-DD HH:mm:ss")
							: "-",
					},
					{
						label: "AUDITED_REMARK",
						value: transaction.auditRemark ? transaction.auditRemark : "-",
					},
				];
				setAuditDetail(auditDetails);

				if (
					transaction.transactionTypeId == TransactionType._CREDIT_REDEMPTION
				) {
					var creditRemark = JSON.parse(transaction.remark);

					let creditRemarkDetails = [
						{ label: "PRODUCT_ID", value: creditRemark.Id },
						{
							label: "NAME",
							value: creditRemark.Name,
						},
						{
							label: "POINTS_NEEDED",
							value: creditRemark.PointsNeeded,
						},
						{
							label: "STOCK",
							value: creditRemark.Stock,
						},
						{
							label: "LIMIT_PER_MEMBER",
							value: creditRemark.LimitPerMember,
						},
						{
							label: "PRODUCT_TYPE",
							value: creditRemark.ProductType,
						},
						{
							label: "VALUE",
							value: creditRemark.Value,
						},
						{
							label: "ROLLOVER",
							value: creditRemark.PointsNeeded,
						},
						{
							label: "CREATED_TIME",
							value: moment(creditRemark.CreatedTime).format(
								"YYYY-MM-DD HH:mm:ss"
							),
						},
						{
							label: "UPDATED_TIME",
							value: moment(creditRemark.UpdatedTime).format(
								"YYYY-MM-DD HH:mm:ss"
							),
						},
					];
					setCreditRemarkDetail(creditRemarkDetails);
				}
			}
		}
	};

	/// <summary>
	/// Author : -
	/// </summary>
	const init = async () => {
		try {
			if (_location.state) {
				let transactionId = _location.state.id;

				let vfullname = "";

				var apiUrl = ApiUrl._API_GET_ACCOUNT;
				apiUrl += "?userId=" + authState.userData.userId;

				fetch(apiUrl, {
					method: ApiKey._API_GET,
					headers: {
						"Content-Type": ApiKey._API_FORM_URLENCODED,
						"X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
					},
				})
					.then((response) => response.json())
					.then((responseJson) => {
						if (responseJson[ApiKey._API_SUCCESS_KEY]) {
							vfullname = responseJson[ApiKey._API_DATA_KEY]["userFullName"];
							setFullName(vfullname);
							getTransactionDetail(transactionId, vfullname);
						}
					});

				let userBankResponseJson = await ApiEngine.get(
					ApiUrl._API_GET_USER_BANK
				);
				if (userBankResponseJson[ApiKey._API_SUCCESS_KEY]) {
					const bankList = [];
					userBankResponseJson[ApiKey._API_DATA_KEY].forEach(function (bank) {
						bankList.push({ label: bank.bankName, value: bank.id });
					});
					setAuditBankOption(bankList);
				}

				let balanceJson = await ApiEngine.get(
					ApiUrl._API_GET_SYSTEM_BALANCE +
						"?memberId=" +
						_location.state.memberId
				);

				if (balanceJson[ApiKey._API_SUCCESS_KEY]) {
					setSystemBalance([balanceJson[ApiKey._API_DATA_KEY]]);
				} else {
					throw balanceJson[ApiKey._API_MESSAGE_KEY];
				}

				getBankAccount();
			}
		} catch (ex) {
			_dispatch(showMessage(false, t(ex)));
		}
	};

	const _systemBalanceColumns = [
		{
			Header: t("DEPOSIT"),
			accessor: "approvedDeposit",
			Cell: ({ row }) => {
				if (row.original.approvedDeposit) {
					return <span>{numberWithCommas(row.original.approvedDeposit)}</span>;
				} else {
					return <>0</>;
				}
			},
		},
		{
			Header: t("WITHDRAWAL"),
			accessor: "approvedWithdrawal",
			Cell: ({ row }) => {
				if (row.original.approvedWithdrawal) {
					return (
						<span style={{ color: "red" }}>
							{numberWithCommas(row.original.approvedWithdrawal)}
						</span>
					);
				} else {
					return <>0</>;
				}
			},
		},
		{
			Header: t("BONUS"),
			accessor: "approvedBonus",
			Cell: ({ row }) => {
				if (row.original.approvedBonus) {
					return <span>{numberWithCommas(row.original.approvedBonus)}</span>;
				} else {
					return <>0</>;
				}
			},
		},
		{
			Header: t("ADJUSTMENT"),
			accessor: "approvedAdjustment",
			Cell: ({ row }) => {
				if (row.original.approvedAdjustment) {
					return (
						<span>{numberWithCommas(row.original.approvedAdjustment)}</span>
					);
				} else {
					return <>0</>;
				}
			},
		},
		{
			Header: t("PENDING_DEPOSIT"),
			accessor: "pendingDeposit",
			Cell: ({ row }) => {
				if (row.original.pendingDeposit) {
					return <span>{numberWithCommas(row.original.pendingDeposit)}</span>;
				} else {
					return <>0</>;
				}
			},
		},
		{
			Header: t("PENDING_WITHDRAWAL"),
			accessor: "pendingWithdrawal",
			Cell: ({ row }) => {
				if (row.original.pendingWithdrawal) {
					return (
						<span>{numberWithCommas(row.original.pendingWithdrawal)}</span>
					);
				} else {
					return <>0</>;
				}
			},
		},
		{
			Header: t("PENDING_ADJUSTMENT"),
			accessor: "pendingAdjustment",
			Cell: ({ row }) => {
				if (row.original.pendingAdjustment) {
					return (
						<span>{numberWithCommas(row.original.pendingAdjustment)}</span>
					);
				} else {
					return <>0</>;
				}
			},
		},
		{
			Header: t("PENDING_BONUS"),
			accessor: "pendingBonus",
			Cell: ({ row }) => {
				if (row.original.pendingBonus) {
					return <span>{numberWithCommas(row.original.pendingBonus)}</span>;
				} else {
					return <>0</>;
				}
			},
		},
	];

	const _previousTransactionColumns = [
		{
			Header: t("DATE"),
			accessor: "submitTime",
			style: {
				whiteSpace: "unset",
				wordBreak: "break-word",
			},
			Cell: ({ row }) => {
				return (
					<>{moment(row.original.submitTime).format("YYYY-MM-DD HH:mm:ss")}</>
				);
			},
			minWidth: 150,
		},
		{
			Header: t("TYPE"),
			accessor: "transactionType",
		},
		{
			Header: "ID",
			accessor: "id",
		},
		{
			Header: t("STATUS"),
			accessor: "approvalStatusString",
		},
		{
			id: "detail",
			Header: t("DETAIL"),
			accessor: "detail",
		},
		{
			id: "credit",
			Header: t("CREDIT"),
			accessor: "amount",
			Cell: ({ row }) => {
				if (row.original.transactionTypeId != TransactionType._WITHDRAWAL) {
					return <>{row.original.amount}</>;
				} else {
					return <></>;
				}
			},
		},
		{
			id: "debit",
			Header: t("DEBIT"),
			accessor: "amount",
			Cell: ({ row }) => {
				if (row.original.transactionTypeId == TransactionType._WITHDRAWAL) {
					return <span style={{ color: "red" }}>-{row.original.amount}</span>;
				} else {
					return <></>;
				}
			},
		},
		{
			Header: t("LIMIT"),
			accessor: "limit",
			Cell: ({ row }) => {
				return <>{row.original.limit}</>;
			},
		},
		{
			Header: t("TURNOVER"),
			accessor: "turnover",
			Cell: ({ row }) => {
				return <a href="#">{row.original.turnover}</a>;
			},
		},
		{
			Header: t("DIFFERENCE"),
			accessor: "differences",
			Cell: ({ row }) => {
				if (row.original.differences < 0) {
					return (
						<span style={{ color: "red" }}>{row.original.differences}</span>
					);
				} else {
					return <>{row.original.differences}</>;
				}
			},
		},
		{
			id: "balance",
			Header: t("BALANCE"),
			accessor: "balance",
		},
	];

	/// <summary>
	/// Author : -
	/// </summary>
	const processTransaction = (action) => {
		let txnTypeId = txn.transactionTypeId;

		if (action == TransactionStatus._APPROVED) {
			if (txnTypeId == TransactionType._WITHDRAWAL) {
				setWithdrawalModal(true);
			} else {
				updateStatus(action, null);
			}
		} else if (action == TransactionStatus._REJECTED) {
			updateStatus(action, null);
		}
	};

	/// <summary>
	/// Author : -
	/// </summary>
	const submitForm = (data, e) => {
		updateStatus(TransactionStatus._APPROVED, auditBankAccountId);
		setWithdrawalModal(false);
	};

	/// <summary>
	/// Author : -
	/// </summary>
	const updateStatus = async (status, auditBankAccountId) => {
		let apiUrl = ApiUrl._API_UPDATE_TRANSACTION_STATUS;
		let transactionId = txn.id;

		apiUrl += "?transactionId=" + transactionId + "&status=" + status;
		if (!stringIsNullOrEmpty(auditBankAccountId)) {
			apiUrl += "&auditBankAccountId=" + auditBankAccountId;
		}

		let responseJson = await ApiEngine.post(apiUrl);
		if (responseJson[ApiKey._API_SUCCESS_KEY]) {
			getTransactionDetail(transactionId);
		}
		_dispatch(
			showMessage(
				responseJson[ApiKey._API_SUCCESS_KEY],
				t(responseJson[ApiKey._API_MESSAGE_KEY])
			)
		);
	};

	const handleAdjustedAmountChange = (event) => {
		const adjustedAmount = event.target.value;
		setAdjustedAmount(event.target.value);
		// Check if the entered age is within the desired range
		if (
			adjustedAmount < (txn.amount * 90) / 100 ||
			adjustedAmount > (txn.amount * 110) / 100
		) {
			setAdjustedAmountError(true);
		} else {
			setAdjustedAmountError(false);
		}
	};

	const updateWingWeiLuyStatus = async (transaction, status) => {

		let params = {
			transactionId: transaction.id,
			status: status,
			amount: adjustedAmount,
		}

		if (!stringIsNullOrEmpty(receiptFile)) {
			params["receiptFile"] = receiptFile;
		}

		let formBody = createMultiPartFormBody(params);

		_dispatch(setBusy());


		fetch(ApiUrl._API_UPDATE_WING_WEILUY_TRANSACTION_STATUS, {
			method: ApiKey._API_POST,
			headers: {
				"X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
			},
			body: formBody,
		})
			.then((response) => response.json())
			.then((responseJson) => {
				_dispatch(setIdle());

				_dispatch(showMessage(responseJson[ApiKey._API_SUCCESS_KEY], t(responseJson[ApiKey._API_MESSAGE_KEY])));
				setWingWeiluyApprovalModal(false);
				init();
			});
	}

	const handleWingWeiluyApproval = () => {
		setWingWeiluyApprovalModal(!wingWeiluyApprovalModal);
	};

	const logoDrop = (acceptedFiles, isDesktop) => {
		if (acceptedFiles.length !== 0) {
			const file = acceptedFiles[0];

			setReceiptFile(file);

			const reader = new FileReader();
			reader.onloadend = () => {
				setIsNewReceiptImage(true);
				setReceiptImage(reader.result);

			};
			reader.readAsDataURL(file);
		}
	};

	return (
		<div>
			<h1 className="page-header">
				{t("TRANSACTION")}
				<NavigationButton history={_history} />
			</h1>
			<div className="row">
				<div className="col-xl-12">
					<div className="row">
						<div className="col-xl-8">
							<div
								className="card text-truncate mb-3"
								style={{ overflow: "visible" }}
							>
								<div className="card-header">
									<b>{t("TRANSACTION_DATA")}</b>
								</div>
								<div className="card-body">
									<div className="row">
										{transactionDetail.map((data, index) => {
											return (
												<div className="col-lg-6" key={index}>
													<div className="form-group row m-b-0">
														<label
															className="col-md-4 col-form-label"
															style={{ whiteSpace: "normal" }}
														>
															<b>{t(data.label)}</b>
														</label>
														<div
															className="col-md-7"
															style={{ whiteSpace: "normal" }}
														>
															<label className="col-form-label">
																{data.label == "Username" ? (
																	<a
																		href="#"
																		onClick={(e) => {
																			e.preventDefault();
																			navigateTo(
																				_history,
																				WebUrl._URL_CREATE_OR_UPDATE_MEMBER,
																				{ id: data.id }
																			);
																		}}
																	>
																		{data.value}
																	</a>
																) : (
																	data.value
																)}
															</label>
															{/*{data.label == "USERNAME" && txn.member.isTestAccount && (<div className="label label-info ml-1">{txn.member.userFullName}</div>)}*/}
															{data.label == "USERNAME" &&
																txn.member.isTestAccount && (
																	<div className="label label-warning ml-1">
																		{t("TEST_ACCOUNT")}
																	</div>
																)}
														</div>
													</div>
												</div>
											);
										})}
									</div>
								</div>
							</div>
						</div>
						<div className="col-xl-4">
							<div
								className="card text-truncate mb-3"
								style={{ overflow: "visible" }}
							>
								<div className="card-header">
									<b>{t("AUDIT_DATA")}</b>
								</div>
								<div className="card-body">
									<div className="row">
										<div className="col-lg-12">
											{auditDetail.map((data, index) => {
												return (
													<div className="col-lg-12" key={index}>
														<div className="form-group row m-b-0">
															<label className="col-md-4 col-form-label">
																<b>{t(data.label)}</b>
															</label>
															<div
																className="col-md-7"
																style={{ whiteSpace: "normal" }}
															>
																<label className="col-form-label">
																	{data.value}
																</label>
															</div>
														</div>
													</div>
												);
											})}
										</div>
									</div>
								</div>
							</div>
							{creditRemarkDetail && creditRemarkDetail.length > 0 && (
								<div
									className="card text-truncate mb-3"
									style={{ overflow: "visible" }}
								>
									<div className="card-header">
										<b>{t("REMARK")}</b>
									</div>
									<div className="card-body">
										<div className="row">
											<div className="col-lg-12">
												{creditRemarkDetail.map((data, index) => {
													return (
														<div className="col-lg-12" key={index}>
															<div className="form-group row m-b-0">
																<label className="col-md-4 col-form-label">
																	<b>{t(data.label)}</b>
																</label>
																<div
																	className="col-md-7"
																	style={{ whiteSpace: "normal" }}
																>
																	<label className="col-form-label">
																		{data.value}
																	</label>
																</div>
															</div>
														</div>
													);
												})}
											</div>
										</div>
									</div>
								</div>
							)}
							
							{txn?.approvalStatus == TransactionStatus._OPEN &&
								showDetails && (
									<>
										<div>
											{!txn.isInstant ? (
												<>
													{role3IsAllowed &&
														showDetails &&
														!txn.enableAdjustment && (
															<button
																className="btn btn-success mr-1"
																onClick={() =>
																	processTransaction(
																		TransactionStatus._APPROVED
																	)
																}
															>
																{t("APPROVE")}
															</button>
														)}
													{role3IsAllowed &&
														showDetails &&
														txn.enableAdjustment && (
															<button
																className="btn btn-success mr-1"
																onClick={() => handleWingWeiluyApproval()}
															>
																{t("APPROVE")}
															</button>
														)}
													{role4IsAllowed && showDetails && (
														<button
															className="btn btn-danger"
															onClick={() =>
																processTransaction(TransactionStatus._REJECTED)
															}
														>
															{t("REJECT")}
														</button>
													)}
												</>
											) : (
												<>
													{role1IsAllowed && (
														<button
															className="btn btn-success mr-1"
															onClick={() =>
																processTransaction(TransactionStatus._APPROVED)
															}
														>
															{t("APPROVE")}
														</button>
													)}
												</>
											)}
										</div>
									</>
								)}
						</div>
					</div>
				</div>
				<div className="col-xl-12">
					<div className="card text-truncate mb-3">
						<div className="card-header">
							<b>{t("SYSTEM_BALANCES")}</b>
						</div>
						<div className="card-body">
							<ReactTable
								data={systemBalance}
								columns={_systemBalanceColumns}
							/>
						</div>
					</div>
				</div>
				<div className="col-xl-12">
					<div className="card text-truncate mb-3">
						<div className="card-header">
							<b>{t("PREVIOUS_TRANSACTIONS")}</b>
						</div>
						<div className="card-body">
							<ReactTable
								fetchUrl={fetchPreviousDataUrl}
								//data={previousTransaction}
								columns={_previousTransactionColumns}
							/>
						</div>
					</div>
				</div>
			</div>
			<Modal
				isOpen={withdrawalModal}
				toggle={() => {
					setWithdrawalModal(!withdrawalModal);
				}}
				centered={true}
			>
				<form onSubmit={handleSubmit(submitForm)}>
					<ModalHeader
						toggle={() => {
							setWithdrawalModal(!withdrawalModal);
						}}
					>
						{t("PLEASE_SELECT_AUDIT_BANK_ACCOUNT")}
					</ModalHeader>
					<ModalBody>
						<div className="row">
							<div className="col-lg-12">
								<div className="form-group row m-b-15">
									<label className="col-md-4 col-form-label">
										<b>{t("AUDIT_BANK")}</b>
									</label>
									<div className="col-md-7">
										<Select
											name="auditBankId"
											options={auditBankOption}
											placeholder={
												auditBankOption.filter(
													(option) => option.value == auditBankId
												)[0] !== undefined
													? auditBankOption.filter(
															(option) => option.value == auditBankId
													  )[0].label
													: ""
											}
											value={auditBankOption.filter(
												(option) => option.value == auditBankId
											)}
											onChange={(e) => {
												unregister("auditBankId");
												setAuditBankId(e.value);
											}}
										/>
										{errors.auditBankId && (
											<div className="invalid-feedback">
												{t(errors.auditBankId.message)}
											</div>
										)}
									</div>
								</div>
							</div>
							<div className="col-lg-12">
								<div className="form-group row m-b-15">
									<label className="col-md-4 col-form-label">
										<b>{t("AUDIT_BANK_ACCOUNT")}</b>
									</label>
									<div className="col-md-7">
										<Select
											name="auditBankAccountId"
											options={bankAccountOption}
											placeholder={
												bankAccountOption.filter(
													(option) => option.value == auditBankAccountId
												)[0] !== undefined
													? bankAccountOption.filter(
															(option) => option.value == auditBankAccountId
													  )[0].label
													: ""
											}
											value={bankAccountOption.filter(
												(option) => option.value == auditBankAccountId
											)}
											onChange={(e) => {
												unregister("auditBankAccountId");
												setAuditBankAccountId(e.value);
											}}
										/>
										{errors.auditBankAccountId && (
											<div className="invalid-feedback">
												{t(errors.auditBankAccountId.message)}
											</div>
										)}
									</div>
								</div>
							</div>
						</div>
					</ModalBody>
					<ModalFooter>
						<button type="submit" className="btn btn-primary">
							{t("CONFIRM")}
						</button>{" "}
						<button
							type="button"
							className="btn btn-danger"
							onClick={() => {
								setWithdrawalModal(!withdrawalModal);
							}}
						>
							{t("CANCEL")}
						</button>
					</ModalFooter>
				</form>
			</Modal>

			{wingWeiluyApprovalModal && (
				<div className="row">
					<Modal
						isOpen={wingWeiluyApprovalModal}
						toggle={() => {
							setWingWeiluyApprovalModal(!wingWeiluyApprovalModal);
							setAdjustedAmount(0);
							setAdjustedAmountError(false);
						}}
						centered={true}
					>
						<ModalHeader
							toggle={() => {
								setWingWeiluyApprovalModal(!wingWeiluyApprovalModal);
								setAdjustedAmount(0);
								setAdjustedAmountError(false);
							}}
						>
							{t("PLEASE_ENTER_ADJUSTED_AMOUNT")}
						</ModalHeader>
						<ModalBody>
							<div className="row">
								<div className="col-lg-12">
									<div className="form-group row m-b-15">
										<label className="col-md-4 col-form-label">
											<b>{t("REQUEST_AMOUNT")}</b>
										</label>
										<div className="col-md-7">
											<input
												className="form-control"
												type="text"
												disabled
												value={txn.amount}
											/>
										</div>
									</div>
								</div>
								<div className="col-lg-12">
									<div className="form-group row m-b-15">
										<label className="col-md-4 col-form-label">
											<b>{t("ADJUSTED_AMOUNT")}</b>
										</label>
										<div className="col-md-7">
											<input
												className="form-control"
												type="number"
												id="adjustedAmount"
												name="adjustedAmount"
												onChange={handleAdjustedAmountChange}
											/>
											{adjustedAmount == 0 ||
												(adjustedAmountError && (
													<div className="invalid-feedback">
														{"Adjusted amount must be a number between 10% ± of " +
															txn.amount}
													</div>
												))}
										</div>
									</div>
								</div>
								{txn.transactionTypeId == TransactionType._WITHDRAWAL &&
									<div className="col-lg-12">
										<div className="form-group row m-b-15">
											<label className="col-md-4 col-form-label"><b>{t("RECEIPT")}</b></label>
											<div className="col-md-7">
												<Dropzone
													accept={"image/*"}
													onDrop={(acceptedFiles) =>
														logoDrop(acceptedFiles, true)
													}
												>
													{({ getRootProps, getInputProps }) => (
														<section>
															<div
																className="dropzone"
																style={{
																	minHeight: "200px",
																	textAlign: "center",
																}}
																{...getRootProps()}
															>
																<input {...getInputProps()} />
																<h4 style={{ color: "grey" }}>
																	{t(
																		"DRAG_N_DROP_SOME_FILES_HERE_OR_CLICK_TO_SELECT_FILES"
																	)}
																</h4>
																{!stringIsNullOrEmpty(receiptImage) && (
																	<aside className="thumbsContainer">
																		<div className="thumb">
																			<div className="thumbInner">
																				<img
																					src={receiptImage}
																					className="dropzone-img"
																				/>
																			</div>
																		</div>
																	</aside>
																)}
															</div>
														</section>
													)}
												</Dropzone>
												<br />
												<button
													type="button"
													className="btn btn-danger"
													onClick={() => {
														setReceiptImage("");
														setReceiptFile();
													}}
												>
													{t("REMOVE_IMAGE")}
												</button>
											</div>
										</div>
									</div>
								}
							</div>
						</ModalBody>
						<ModalFooter>
							<button
								type="button"
								className="btn btn-primary"
								disabled={adjustedAmount == 0 || adjustedAmountError}
								onClick={() => {
									updateWingWeiLuyStatus(txn, TransactionStatus._APPROVED);
								}}
							>
								{t("CONFIRM")}
							</button>{" "}
							<button
								type="button"
								className="btn btn-danger"
								onClick={() => {
									setWingWeiluyApprovalModal(!wingWeiluyApprovalModal);
									setAdjustedAmount(0);
									setAdjustedAmountError(false);
								}}
							>
								{t("CANCEL")}
							</button>
						</ModalFooter>
					</Modal>
				</div>
			)}
		</div>
	);
};

export default TransactionDetail;
