import React, { useState, useEffect } from "react";
import ReactTable from "../../components/constantComponent/reactTable/ReactTable";
import { Panel, PanelBody } from "./../../components/panel/panel.jsx";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { ApiKey, ApiUrl, LanguageKey } from "../../util/Constant";
import { useTranslation } from "react-i18next";
import { numberWithCurrencyFormat } from "../../util/Util";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

const ActiveMemberReport = (props) => {
	const { t } = useTranslation(LanguageKey._PRIMARY);
	const [calendarStartDate, setCalendarStartDate] = useState(
		moment().startOf("day").format("YYYY-MM-DD HH:mm:ss")
	);
	const [calendarEndDate, setCalendarEndDate] = useState(
		moment().endOf("day").format("YYYY-MM-DD HH:mm:ss")
	);
	const [activeMemberApiUrl, setActiveMemberApiUrl] = useState("");
	const [monthList, setMonthList] = useState([]);
	const _dispatch = useDispatch();
	let _history = useHistory();

	useEffect(() => {
		init();
	}, []);

	/// <summary>
	/// Author : -
	/// </summary>
	const handleEvent = (e) => {
		setCalendarStartDate(
			moment(e.target.value).startOf("month").format("YYYY-MM-DD HH:mm:ss")
		);
		setCalendarEndDate(
			moment(e.target.value).endOf("month").format("YYYY-MM-DD HH:mm:ss")
		);
	};

	const init = () => {
		generateMonth();
	};

	/// <summary>
	/// Author : -
	/// </summary>
	const getActiveMember = async () => {
		let fetchUrl = ApiUrl._API_GET_ACTIVE_MEMBER_REPORT;
		fetchUrl +=
			"?StartDate=" + calendarStartDate + "&EndDate=" + calendarEndDate;
		setActiveMemberApiUrl(fetchUrl);
	};

	let _tableColumns = [
		{
			Header: t("USERNAME"),
			accessor: "username",
			Cell: ({ row }) => {
				return <>{row.original.username}</>;
			},
			minWidth: 100,
			Footer: () => {
				return (
					<span>
						<b>{t("TOTAL")}: </b>
					</span>
				);
			},
		},
		{
			Header: t("TOTAL_TRANSACTION"),
			accessor: "totalTrans",
			Cell: ({ row }) => {
				return (
					<>
						{numberWithCurrencyFormat(parseFloat(row.original.totalTrans), 2)}
					</>
				);
			},
			style: {
				textAlign: "right",
			},
			minWidth: 100,
			Footer: ({ page }) => {
				const totalTrans = page.reduce((sum, currentValue) => {
					if (currentValue.original.totalTrans) {
						return sum + parseFloat(currentValue.original.totalTrans);
					} else {
						return sum;
					}
				}, 0);
				return (
					<span>
						<b>{numberWithCurrencyFormat(parseFloat(totalTrans))}</b>
					</span>
				);
			},
		},
		{
			Header: t("TOTAL_BET"),
			accessor: "totalBet",
			Cell: ({ row }) => {
				return (
					<>{numberWithCurrencyFormat(parseFloat(row.original.totalBet), 2)}</>
				);
			},
			style: {
				textAlign: "right",
			},
			minWidth: 100,
			Footer: ({ page }) => {
				const totalBet = page.reduce((sum, currentValue) => {
					if (currentValue.original.totalBet) {
						return sum + parseFloat(currentValue.original.totalBet);
					} else {
						return sum;
					}
				}, 0);
				return (
					<span>
						<b>{numberWithCurrencyFormat(parseFloat(totalBet))}</b>
					</span>
				);
			},
		},
		{
			Header: t("TOTAL_WIN"),
			accessor: "totalWin",
			Cell: ({ row }) => {
				return (
					<>{numberWithCurrencyFormat(parseFloat(row.original.totalWin), 2)}</>
				);
			},
			style: {
				textAlign: "right",
			},
			minWidth: 100,
			Footer: ({ page }) => {
				const totalWin = page.reduce((sum, currentValue) => {
					if (currentValue.original.totalWin) {
						return sum + parseFloat(currentValue.original.totalWin);
					} else {
						return sum;
					}
				}, 0);
				return (
					<span>
						<b>{numberWithCurrencyFormat(parseFloat(totalWin))}</b>
					</span>
				);
			},
		},
	];

	const generateMonth = () => {
		var emptyList = [];
		for (var i = 1; i <= 6; i++) {
			var date = moment().subtract(i, "months");
			var year = date.get("year");
			var month = date.format("MMMM");
			emptyList.push({ year: year, month: month });
		}
		setMonthList(emptyList);
	};

	return (
		<div>
			<h1 className="page-header">{t("ACTIVE_MEMBER_REPORT")}</h1>
			<Panel>
				<div className="row">
					<div className="col-lg-12">
						<PanelBody>
							<div className="row">
								<div className="col-lg-10">
									<div className="form-group" style={{ display: "flex" }}>
										<div
											className="input-group"
											style={{ width: "100%", flexFlow: "nowrap" }}
										>
											<div className="input-group-prepend">
												<span className="input-group-text">
													<i className="fa fa-calendar"></i>
												</span>
											</div>
											<div
												className="input-group-append"
												style={{ width: "100%" }}
											>
												<select
													id="month"
													name="month"
													onChange={(e) => handleEvent(e)}
												>
													{[...monthList].reverse().map(({ year, month }) => {
														return (
															<option value={month + " " + year}>
																{month + " " + year}
															</option>
														);
													})}
												</select>
											</div>
										</div>
									</div>
								</div>
								<div className="col-lg-2 text-align-right">
									<button
										type="button"
										onClick={() => {
											getActiveMember();
										}}
										className="btn btn-primary"
									>
										{t("SEARCH")}
									</button>
								</div>
							</div>
							<hr />
							<ReactTable
								fetchUrl={activeMemberApiUrl}
								columns={_tableColumns}
							/>
						</PanelBody>
					</div>
				</div>
			</Panel>
		</div>
	);
};

export default ActiveMemberReport;
