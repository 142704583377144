import React, { useState, useEffect, useRef, useCallback } from 'react';
import ReactTable from '../../components/constantComponent/reactTable/ReactTable';
import { Panel, PanelBody } from './../../components/panel/panel.jsx';
import { useHistory } from "react-router-dom";
import { UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import 'react-datepicker/dist/react-datepicker.css';
import NavigationButton from '../../components/constantComponent/button/NavigationButton';
import { navigateTo, stringIsNullOrEmpty, numberWithCurrencyFormat } from "../../util/Util";
import { ApiKey, ApiUrl, AccessRight, WebUrl, LanguageKey, DEFAULT_PAGE_SIZE } from "../../util/Constant";
import { checkIfPermissionExist } from "../../util/PermissionChecker";
import { setBusy, setIdle, showMessage } from '../../redux/AppAction';
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from 'react-i18next';
import ApiEngine from '../../util/ApiEngine';
import { AsyncPaginate } from 'react-select-async-paginate';
import { useForm, Controller } from "react-hook-form";
import classNames from 'classnames';

/// <summary>
/// Author : -
/// </summary>
const ManageMember = props => {

    const { register, handleSubmit, errors, setValue, watch, trigger, formState, control } = useForm();
    const { t } = useTranslation(LanguageKey._PRIMARY);
    let _history = useHistory();

    var _username = useSelector(state => state['authState']['userData']['username']);

    const _dispatch = useDispatch();
    const [memberData, setMemberData] = useState([]);

    const _SELECTED_MEMBER_KEY = 'selectedMemberKey';

    const [selectedMember, setSelectedMember] = useState('');
    const [locationStateObj, setLocationStateObj] = useState();
    const [memberId, setMemberId] = useState('');
    const [nodePathMembers, setNodePathMembers] = useState([]);
    const [memberListUrl, setMemberListUrl] = useState(`${ApiUrl._API_GET_DOWNLINE_BY_REFERRER}?memberId=${memberId}`);
    const [tableData, setTableData] = useState([]);


    const OPTIONS = [
        { value: 'all', label: 'All' },
        { value: 'vip', label: 'VIP' },
        { value: 'bonus_hunter', label: 'Bonus Hunter' },
        { value: 'duplicated_fullname', label: 'Duplicated FullName' },
        { value: 'second_user_id', label: 'Second User ID' },
        { value: 'no_welcome_bonus', label: 'NO Welcome Bonus Allowed' },
        { value: 'no_free_credit', label: 'No Free Credit' },
        { value: 'duplicated_ip', label: 'Duplicated IP' },
        { value: 'attention_betting', label: 'ATTENTION betting on SBO / IBC' },
        { value: 'claimed_50', label: 'Already Claim 50%' },
    ];

    const FILTER_OPTION = [
        { value: 'username', label: 'Username' },
        { value: 'fullname', label: 'FullName' },
        { value: 'phone', label: 'Phone' },
        { value: 'email', label: 'Email' },
        { value: 'agent', label: 'Agent' },
        { value: 'affiliate', label: 'Affiliate' },
        { value: 'channel', label: 'Channel' },
        { value: 'source', label: 'Source' },
        { value: 'campaign', label: 'Campaign' },
        { value: 'bankaccount', label: 'BankAccount' },
    ];

    let _tableColumns = [
        {
            Header: "USERNAME",
            accessor: "username",
            minWidth: 100,
            Cell: ({ row }) => {
                return <span>{row.original.username}</span>;
            }
        },
        {
            Header: "SHAREHOLDER",
            accessor: "isShareHolder",
            Cell: ({ row }) => {
                return <span>{row.original.isShareHolder ? <span className="badge badge-secondary badge-green">Yes</span> : <span className="badge badge-secondary badge-danger">No</span>}</span>
            },
            disableFilters: true,
            width: 80,
            minWidth: 10,
            maxWidth: 80
        },
        {
            Header: "SHARE %",
            accessor: "sharePercent",
            Cell: ({ row }) => {
                return <>{(row.original.isShareHolder ? <div className="form-control text-right">
                    {row.original.sharePercent}
                </div>
                    :
                    ""
                )}</>
            },
            disableFilters: true,
            width: 80,
            minWidth: 10,
            maxWidth: 80
        },
        {
            Header: "PRIVATE POOL?",
            accessor: "poolId",
            Cell: ({ row }) => {
                return <>{(row.original.poolId > 0 ? <span className="badge badge-secondary badge-green">Yes</span> : <span className="badge badge-secondary badge-danger">No</span>)}</>
            },
            disableFilters: true,
            width: 80,
            minWidth: 10,
            maxWidth: 80
        },
        {
            Header: "SHAREHOLDER DATE",
            accessor: "shareHolderDate",
            Cell: ({ row }) => {
                return <>{((row.original.isShareHolder && row.original.shareHolderDate != null) ? <div className="form-control text-left">
                    {row.original.shareHolderDate}</div>
                    :
                    ""
                )}</>
            },
            disableFilters: true,
            minWidth: 100
        },
        {
            id: "action",
            Header: "",
            Cell: ({ row }) => (
                checkIfPermissionExist(AccessRight._MEMBER_CONTROL_PERMISSION, true) && <div className="btn-group m-r-5 m-b-5">
                    <UncontrolledButtonDropdown>
                        <DropdownToggle caret color="default">
                            <i className="fas fa-cog"></i>
                        </DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem key="sh_view"
                                onClick={() => {
                                    navigateTo(
                                        _history,
                                        WebUrl._URL_SHAREHOLDERS_VIEW_ACCOUNT,
                                        row.original
                                    );
                                }}
                            >
                                <span className="text-primary">
                                    {t("EDIT_SH_DETAIL")}
                                </span>
                            </DropdownItem>
                            {
                                (row.original.isShareHolder &&
                                    <DropdownItem key="sh_vew_downline"
                                        onClick={() => {
                                            navigateTo(
                                                _history,
                                                WebUrl._URL_COMPANY_PRODUCT_ACCOUNT_INFO,
                                                row.original
                                            );
                                        }}
                                    >
                                        {t("VIEW DOWNLINE SH")}
                                    </DropdownItem>
                                )}
                        </DropdownMenu>
                    </UncontrolledButtonDropdown>
                </div>
            ),
            disableSortBy: true,
            disableFilters: true,
            width: 100,
            style: { overflow: "visible" }
        }
    ]

    /// <summary>
    /// Author : Wong
    /// </summary>
    useEffect(() => {
        let nodePath = [];
        let memId = '';
        if (tableData.memberNodePath) {
            if (tableData.memberNodePath.length > 0) {
                nodePath = tableData.memberNodePath;
                memId = nodePath[nodePath.length - 1].id;
            }
        }

        setNodePathMembers(nodePath);
        setMemberId(memId);
        setLocationStateObj({
            parentMemberId: memId
        });
    }, [tableData]);


    /*/// <summary>
    /// Author : -
    /// </summary>
    function updateMemberSH(data) {
        _dispatch(setBusy());
        fetch(
            ApiUrl._API_UPDATE_USER_SH +
            "?userId=" +
            loginUserId +
            "&memberId=" +
            data["Id"] +
            "&enable=" +
            !data["isShareHolder"],
            {
                method: ApiKey._API_POST,
                headers: {
                    "Content-Type": ApiKey._API_FORM_URLENCODED,
                    "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
                },
            }
        )
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                    getDownlineByReferrer(selectedMember);
                }
                _dispatch(setIdle());
            });

    }*/
    /// <summary>
    /// Author : -
    /// </summary>
    useEffect(() => {
        sessionStorage.setItem(_SELECTED_MEMBER_KEY, selectedMember);
        getDownlineByReferrer(selectedMember);
    }, [selectedMember]);

    /// <summary>
    /// Author : -
    /// </summary>
    async function getDownlineByReferrer(memberId = '') {
        setMemberListUrl(`${ApiUrl._API_GET_DOWNLINE_BY_REFERRER}?memberId=${memberId}`);
    }

    /// <summary>
    /// Author : -
    /// </summary>
    async function syncProductData(data) {
        var responseJson = await ApiEngine.post(`${ApiUrl._API_SYNC_MEMBER_TO_OWNER_PRODUCT_DATA}?id=${data["id"]}`);
        _dispatch(showMessage(responseJson[ApiKey._API_SUCCESS_KEY], t(responseJson[ApiKey._API_MESSAGE_KEY])));
    }

    /// <summary>
    /// Author : Wong
    /// </summary>
    async function getMemberList(search, loadOptions, { page }) {
        var responseJson = await ApiEngine.get(`${ApiUrl._API_GET_USER_MEMBER_LIST}?&start=${(page - 1) * DEFAULT_PAGE_SIZE}&count=${DEFAULT_PAGE_SIZE}&keyword=${search}`);

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            let data = responseJson[ApiKey._API_DATA_KEY]['data'];
            let formattedData = data.map((m) => { return { label: m.username, value: m.id } });

            return {
                options: formattedData,
                hasMore: formattedData.length == DEFAULT_PAGE_SIZE,
                additional: {
                    page: page + 1
                }
            };
        }
    }

    return (
        <div>
            <h1 className="page-header">{t("SHAREHOLDERS_LIST")}</h1>
            <Panel>
                <div className="row">
                    <div className="col-lg-12">
                        <PanelBody>
                            <ReactTable fetchUrl={memberListUrl} fetchedData={(data) => setTableData(data)} filterable columns={_tableColumns} />
                        </PanelBody>
                    </div>
                </div>
            </Panel>
        </div >
    )
}

export default ManageMember;