import React, { useState, useEffect } from "react";
import { Panel, PanelBody } from "./../../components/panel/panel.jsx";
import { useForm } from "react-hook-form";
import { ApiKey, ApiUrl, LanguageKey } from "../../util/Constant";
import { stringIsNullOrEmpty, createMultiPartFormBody } from "../../util/Util";
import { useLocation, useHistory } from "react-router-dom";
import NavigationButton from "../../components/constantComponent/button/NavigationButton";
import { setBusy, setIdle, showMessage } from "../../redux/AppAction";
import { useDispatch } from "react-redux";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import { fetch } from "whatwg-fetch";

/// <summary>
/// Author : -
/// </summary>
const GameDetail = (props) => {
  const { t } = useTranslation(LanguageKey._PRIMARY);
  const _location = useLocation();
  const _history = useHistory();
  const _dispatch = useDispatch();
  const { register, handleSubmit, errors, setValue, unregister } = useForm();

  const [gameOption, setGameOption] = useState([]);

  const [accountProductId, setAccountProductId] = useState("");
  const [recommended, setRecommended] = useState(false);
  const [rank, setRank] = useState("");
  const [gameId, setGameId] = useState("");
  const [settingId, setSettingId] = useState("");

  /// <summary>
  /// Author : -
  /// </summary>
  const submitForm = async (data, e) => {
    let params = {
      gameId: gameId,
      accountProductId: accountProductId,
      rank: data.rank,
      isRecommended: recommended,
    };

    if (!stringIsNullOrEmpty(settingId)) {
      params["id"] = settingId;
    }

    let formBody = createMultiPartFormBody(params);
    _dispatch(setBusy());

    fetch(ApiUrl._API_CREATE_OR_UPDATE_GAME_PRODUCT_SETTING, {
      method: ApiKey._API_POST,
      headers: {
        "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
      },
      body: formBody,
    })
      .then((response) => response.json())
      .then((responseJson) => {
        _dispatch(
          showMessage(
            responseJson[ApiKey._API_SUCCESS_KEY],
            t(responseJson[ApiKey._API_MESSAGE_KEY])
          )
        );
        _dispatch(setIdle());

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
          setSettingId(responseJson[ApiKey._API_DATA_KEY]["id"]);
        }
      });
  };

  /// <summary>
  /// Author : -
  /// </summary>
  useEffect(() => {
    register({ name: "gameId" }, { required: "PLEASE_SELECT_GAME" });
    init();
  }, []);

  /// <summary>
  /// Author : -
  /// </summary>
  async function init() {
    setAccountProductId(_location.state["accountProductId"]);
    await fetch(
      ApiUrl._API_GET_PRODUCT_GAME_BY_ACCOUNT_PRODUCT +
        "?accountProductId=" +
        _location.state["accountProductId"],
      {
        method: ApiKey._API_GET,
        headers: {
          "Content-Type": ApiKey._API_FORM_URLENCODED,
          "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
        },
      }
    )
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
          const games = [];
          responseJson[ApiKey._API_DATA_KEY].forEach(function (game) {
            games.push({ label: game.gameName, value: game.id });
          });

          setGameOption(games);
        }
      });

    if (_location.state["id"] != null && _location.state["id"] != undefined) {
      fetch(
        ApiUrl._API_GET_PRODUCT_GAME_SETTING_BY_ID +
          "?id=" +
          _location.state["id"],
        {
          method: ApiKey._API_GET,
          headers: {
            "Content-Type": ApiKey._API_FORM_URLENCODED,
            "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
          },
        }
      )
        .then((response) => response.json())
        .then((responseJson) => {
          if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            setGameId(responseJson[ApiKey._API_DATA_KEY]["gameId"]);
            setRank(responseJson[ApiKey._API_DATA_KEY]["rank"]);
            setRecommended(responseJson[ApiKey._API_DATA_KEY]["isRecommended"]);
            setSettingId(responseJson[ApiKey._API_DATA_KEY]["id"]);
            unregister("gameId");
          }
        });
    }
  }

  return (
    <div>
      <h1 className="page-header">
        {t("GAME_SETTING_DETAIL")}
        <NavigationButton history={_history} />
      </h1>
      <div className="row">
        <div className="col-xl-12">
          <Panel>
            <PanelBody>
              <form onSubmit={handleSubmit(submitForm)}>
                <div className="row">
                  <div className="col-lg-8">
                    <div className="form-group row m-b-15">
                      <label className="col-md-4 col-form-label">
                        <b>{t("GAME")}</b>
                      </label>
                      <div className="col-md-7">
                        <Select
                          name="gameId"
                          options={gameOption}
                          placeholder={
                            gameOption.filter(
                              (option) => option.value == gameId
                            )[0] !== undefined
                              ? gameOption.filter(
                                  (option) => option.value == gameId
                                )[0].label
                              : ""
                          }
                          value={gameOption.filter(
                            (option) => option.value == gameId
                          )}
                          onChange={(e) => {
                            unregister("gameId");
                            setGameId(e.value);
                          }}
                        />
                        {errors.gameId && (
                          <div className="invalid-feedback">
                            {t(errors.gameId.message)}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-8">
                    <div className="form-group row m-b-15">
                      <label className="col-md-4 col-form-label">
                        <b>{t("RECOMMEND")}</b>
                      </label>
                      <div className="col-md-7 col-form-label">
                        <div className="switcher">
                          <input
                            type="checkbox"
                            name="recommended"
                            id="recommended"
                            onChange={(e) => setRecommended(e.target.checked)}
                            value={false}
                            checked={recommended}
                            ref={register}
                          />
                          <label htmlFor="recommended"></label>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-8">
                    <div className="form-group row m-b-15">
                      <label className="col-md-4 col-form-label">
                        <b>{t("RANK")}</b>
                      </label>
                      <div className="col-md-7 col-form-label">
                        <input
                          type="number"
                          className="form-control"
                          placeholder={t("PLEASE_ENTER_RANK")}
                          name="rank"
                          defaultValue={rank}
                          ref={register({
                            required: "PLEASE_ENTER_RANK",
                            min: {
                              value: 0,
                              message: "PLEASE_ENTER_VALUE_NOT_LESS_THAN_ZERO",
                            },
                            max: {
                              value: 100,
                              message: "PLEASE_ENTER_VALUE_NOT_MORE_THAN_100",
                            },
                          })}
                        />
                        {errors.rank && (
                          <div className="invalid-feedback">
                            {t(errors.rank.message)}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <hr />
                <div className="row">
                  <div className="col-lg-3">
                    <div className="form-group">
                      <button type="submit" className="btn btn-primary">
                        {t("SUBMIT")}
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </PanelBody>
          </Panel>
        </div>
      </div>
    </div>
  );
};

export default GameDetail;
