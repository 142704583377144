import React, { useState, useEffect } from "react";
import ReactTable from "../../components/constantComponent/reactTable/ReactTable";
import { Panel, PanelBody } from "./../../components/panel/panel.jsx";
import { useHistory } from "react-router-dom";
import {
	UncontrolledButtonDropdown,
	DropdownToggle,
	DropdownMenu,
	DropdownItem,
} from "reactstrap";
import NavigationButton from "../../components/constantComponent/button/NavigationButton";
import { navigateTo, stringIsNullOrEmpty } from "../../util/Util";
import {
	ApiUrl,
	ApiKey,
	WebUrl,
	AccessRight,
	LanguageKey,
} from "../../util/Constant";
import ApiEngine from "../../util/ApiEngine";
import { checkIfPermissionExist } from "../../util/PermissionChecker";
import { setBusy, setIdle, showMessage } from "../../redux/AppAction";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { fetch } from "whatwg-fetch";

/// <summary>
/// Author : -
/// </summary>
const ManageDCItemList = (props) => {
	const { t } = useTranslation(LanguageKey._PRIMARY);
	let _history = useHistory();
	const _dispatch = useDispatch();
	const [itemData, setItemData] = useState([]);

	let _tableColumns = [
		{
			Header: "ITEM_NAME",
			accessor: "name",
			Cell: ({ row }) => (
				<div style={{ textAlign: "center" }}>{row.original.name}</div>
			),
		},
		{
			Header: "PRODUCT_TYPE",
			accessor: "productType",
			Cell: ({ row }) => (
				<div style={{ textAlign: "center" }}>{row.original.productType}</div>
			),
		},
		{
			Header: "STOCKS",
			accessor: "stock",
			Cell: ({ row }) => (
				<div style={{ textAlign: "center" }}>{row.original.stock}</div>
			),
		},
		{
			Header: "POINTS",
			accessor: "pointsNeeded",
			Cell: ({ row }) => (
				<div style={{ textAlign: "center" }}>{row.original.pointsNeeded}</div>
			),
		},
		{
			Header: "IMAGE",
			accessor: "image",
			Cell: ({ row }) => (
				<>
					<div className="dc-item-table-img">
						{!stringIsNullOrEmpty(row.original.image) ? (
							<img style={{ width: "150px" }} src={row.original.image} />
						) : (
							<span> - </span>
						)}
					</div>
				</>
			),
			disableSortBy: true,
			disableFilters: true,
		},
		{
			Header: "STATUS",
			accessor: "status",
			Cell: ({ row }) => (
				<>
					{row.original.status == 1 ? (
						<div style={{ textAlign: "center" }}>
							<span className="badge badge-secondary badge-green">
								{t("ACTIVE")}
							</span>
						</div>
					) : (
						<div style={{ textAlign: "center" }}>
							<span className="badge badge-secondary badge-danger">
								{t("SUSPENDED")}
							</span>
						</div>
					)}
				</>
			),
			disableSortBy: true,
			disableFilters: true,
			style: { overflow: "visible" },
		},
		{
			id: "action",
			Header: "",
			Cell: ({ row }) =>
				checkIfPermissionExist(AccessRight._DC_ITEM_LIST_PERMISSION, true) && (
					<div className="btn-group m-r-5 m-b-5">
						<UncontrolledButtonDropdown>
							<DropdownToggle caret color="default">
								<i className="fas fa-cog"></i>
							</DropdownToggle>
							<DropdownMenu container="body" flip={false} right>
								<DropdownItem
									onClick={() => {
										navigateTo(
											_history,
											WebUrl._URL_CREATE_OR_UPDATE_DC_ITEM,
											row.original
										);
									}}
								>
									<span className="text-warning">{t("EDIT")}</span>
								</DropdownItem>

								{row.original.status == 1 && (
									<DropdownItem
										onClick={() =>
											updateStatus(row.original.id, row.original.status)
										}
									>
										<span className="text-primary">{t("DISABLE")}</span>
									</DropdownItem>
								)}

								{row.original.status == 0 && (
									<DropdownItem
										onClick={() =>
											updateStatus(row.original.id, row.original.status)
										}
									>
										<span className="text-primary">{t("ENABLE")}</span>
									</DropdownItem>
								)}
							</DropdownMenu>
						</UncontrolledButtonDropdown>
					</div>
				),
			disableSortBy: true,
			disableFilters: true,
			width: 100,
			style: { overflow: "visible" },
		},
	];

	/// <summary>
	/// Author : -
	/// </summary>
	useEffect(() => {
		init();
	}, []);

	const init = async () => {
		fetch(ApiUrl._API_GET_ALL_DC_ITEM, {
			method: ApiKey._API_GET,
			headers: {
				"Content-Type": ApiKey._API_FORM_URLENCODED,
				"X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
			},
		})
			.then((response) => response.json())
			.then((responseJson) => {
				if (responseJson[ApiKey._API_SUCCESS_KEY]) {
					setItemData(responseJson[ApiKey._API_DATA_KEY]);
				}
			});
	};

	const updateStatus = async (id, status) => {
		_dispatch(setBusy());
		let responseJson = await ApiEngine.post(
			ApiUrl._API_UPDATE_DC_ITEM_STATUS + "?id=" + id + "&status=" + status
		);

		_dispatch(setIdle());
		_dispatch(
			showMessage(
				responseJson[ApiKey._API_SUCCESS_KEY],
				t(responseJson[ApiKey._API_MESSAGE_KEY])
			)
		);
		init();
	};

	return (
		<div>
			<h1 className="page-header">
				{t("REDEMPTION_ITEMS")}
				{checkIfPermissionExist(AccessRight._DC_ITEM_LIST_PERMISSION, true) && (
					<NavigationButton
						history={_history}
						url={WebUrl._URL_CREATE_OR_UPDATE_DC_ITEM}
						buttonText={t("ADD_NEW_REWARDS_POINTS")}
					/>
				)}
			</h1>
			<Panel>
				<PanelBody>
					<ReactTable filterable data={itemData} columns={_tableColumns} />
				</PanelBody>
			</Panel>
		</div>
	);
};

export default ManageDCItemList;
