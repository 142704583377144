export const _SAVE_LANGUAGE = "saveLanguage";

/// <summary>
/// Author : -
/// </summary>
export const addLanguage = (languages) => {
    return async dispatch => {
        dispatch({
            type: _SAVE_LANGUAGE,
            languages: languages
        });
    }
}