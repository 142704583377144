import React, { useState, useEffect } from 'react';
import { withRouter } from "react-router-dom";
import { Panel, PanelBody } from "./../../components/panel/panel.jsx";
import { useHistory, useLocation } from "react-router-dom";
import NavigationButton from "../../components/constantComponent/button/NavigationButton";
import {
    ApiKey,
    ApiUrl,
    LanguageKey,
} from "../../util/Constant";
import { setBusy, setIdle, showMessage } from "../../redux/AppAction";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import ApiEngine from "../../util/ApiEngine";
import { createFormBody, createMultiPartFormBody, stringIsNullOrEmpty } from "../../util/Util.js";
import moment from 'moment';

const Manage568WinBetLimit = props => {
    const { t } = useTranslation(LanguageKey._PRIMARY);
    const _dispatch = useDispatch();
    const _history = useHistory();
    const _location = useLocation();
    const [betLimitSettingData, setBetLimitSettingData] = useState({});
    const [betLimitStr, setBetLimitStr] = useState("");
    const [initCheck, setInitCheck] = useState(false);
    const {
        register,
        unregister,
        handleSubmit,
        errors,
        setError,
        control,
        setValue,
    } = useForm();

    /// <summary>
    /// Author : -
    /// </summary>
    useEffect(() => {
        if (_location.state) {
            init();
        }
        setInitCheck(true);
    }, []);

    async function init() {
        let apiUrl = ApiUrl._API_GET_USER_SYSTEM_SETTINGS + "?type=betLimit&productId=" + _location.state.id;
        var responseJson = await ApiEngine.get(apiUrl);
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            let data = responseJson[ApiKey._API_DATA_KEY];
            console.log(data);
            setBetLimitSettingData(data);
            setBetLimitStr(data.value);
        }
    }

    const submitForm = async (data) => {
        let params = {
            "betLimitStr": betLimitStr
        };

        let formBody = createMultiPartFormBody(params);
        let apiUrl = ApiUrl._API_CREATE_OR_UPDATE_SYSTEM_SETTINGS + "?type=betLimit&productId=" + _location.state.id;
        let responseJson = await ApiEngine.post(apiUrl, formBody);
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            init();
        }
        _dispatch(showMessage(responseJson[ApiKey._API_SUCCESS_KEY], t(responseJson[ApiKey._API_MESSAGE_KEY])));
    }

    return (
        <div>
            <h1 className="page-header">
                {t("ADJUST_BET_LIMIT")}
                <NavigationButton history={_history} />
            </h1>
            <div className="row">
                <div className="col-xl-12">
                    {initCheck && <Panel>
                        <PanelBody>
                            <form onSubmit={handleSubmit(submitForm)}>
                                <div className="col-lg-8">
                                    <div className="form-group row m-b-15">
                                        <label className="col-md-4 col-form-label">
                                            <b>{t("PRODUCT_NAME")}</b>
                                        </label>
                                        <div className="col-md-7" style={{ display: "flex", lineHeight: "2.5" }}>
                                            <div className="col-4">
                                                {_location.state.productName}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-8">
                                    <div className="form-group row m-b-15">
                                        <label className="col-md-4 col-form-label">
                                            <b>{t("BET_LIMIT_CODE")}</b>
                                        </label>
                                        <div className="col-md-7" style={{ display: "flex", lineHeight: "2.5" }}>
                                            <div className="col-4">
                                                <textarea
                                                    className="form-control"
                                                    rows="5"
                                                    style={{ marginTop: "10px", width: "275px" }}
                                                    ref={register}
                                                    name="betLimitCode"
                                                    defaultValue={betLimitStr}
                                                    onChange={(e) => {
                                                        setBetLimitStr(e.target.value)
                                                    }}
                                                ></textarea>
                                                {errors.betLimitCode && (
                                                    <div className="invalid-feedback">
                                                        {t(errors.betLimitCode.message)}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <hr />
                                <div className="row">
                                    <div className="col-lg-3">
                                        <div className="form-group">
                                            <button type="submit" className="btn btn-primary">
                                                {t("SUBMIT")}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </PanelBody>
                    </Panel>}
                </div>
            </div>
        </div>
    )
}

export default withRouter(Manage568WinBetLimit);