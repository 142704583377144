/// <summary>
/// Author : -
/// API related key or field when making ajax or api call
/// </summary>
export class ApiKey {
  static _API_SUCCESS_KEY = "success";
  static _API_MESSAGE_KEY = "message";
  static _API_DATA_KEY = "data";

  static _API_CONTENT_TYPE = 'Content-Type';
  static _API_ACCEPT = "Accept";
  static _API_APPLICATION_JSON = "application/json";
  static _API_XML_HTTP_REQUEST_KEY = "XMLHttpRequest";
  static _API_FORM_URLENCODED = "application/x-www-form-urlencoded";
  static _API_POST = "POST";
  static _API_GET = "GET";
}

/// <summary>
/// Author : -
/// Url to communicate to controller
/// </summary>
export class ApiUrl {
  static _API_LOGIN = "/Account/Login";
  static _API_CREATE_OR_UPDATE_ACCOUNT = "/Account/CreateOrUpdateAccount";
  static _API_LOGOUT = "/Account/Logout";
  static _API_GET_SUPER_COMPANY = "/Account/GetSuperCompany";
  static _API_GET_ALL_COMPANY = "/Account/GetAllCompany";
  static _API_GET_ACCOUNT = "/Account/GetAccountById";
  static _API_IS_LOGGED_IN = "/Account/IsLoggedIn";
  static _API_UPDATE_PASSWORD = "/Account/UpdatePassword";
  static _API_UPDATE_PIN_NUMBER = "/Account/UpdatePinNumber";
  static _API_GET_SYSTEM_DATETIME = "/Account/GetSystemDateTime";

  static _API_GET_USER_BANK = "/Bank/GetUserBank";
  static _API_GET_BANK_DETAIL = "/Bank/GetBankDetail";
  static _API_CREATE_OR_UPDATE_BANK_DETAIL = "/Bank/CreateOrUpdateBank";
  static _API_GET_USER_BANK_ACCOUNT = "/Bank/GetUserBankAccount";
  static _API_GET_BANK_ACCOUNT_DETAIL = "/Bank/GetBankAccountDetail";
  static _API_CREATE_OR_UPDATE_BANK_ACCOUNT_DETAIL = "/Bank/CreateOrUpdateBankAccount";
  static _API_GET_BANK_ACCOUNT_BY_BANK_ID = "/Bank/GetBankAccountByBankId";

  static _API_GET_ROLE_BY_ID = "/Account/GetRoleById";
  static _API_GET_USERS_ROLE = "/Account/GetAllRoles";
  static _API_CREATE_OR_UPDATE_ROLE = "/Account/CreateOrUpdateRole";
  static _API_GET_ROLE_ACCESS_DATA = "/Account/GetRoleAccessData";

  static _API_GET_USER_SUB_ACCOUNT = "/Account/GetUserSubAccount";
  static _API_CREATE_OR_UPDATE_SUB_ACCOUNT = "/Account/CreateOrUpdateSubAccount";
  static _API_GET_ROLLOVER_RECORD = "/Transaction/GetRolloverRecord";
  static _API_RESET_ROLLOVER_RECORD = "/Transaction/ResetRollOverRecord";
  static _API_GET_BONUS_ROLLOVER_RECORD = "/Transaction/GetBonusRolloverRecord";
  static _API_RESET_BONUS_ROLLOVER_RECORD = "/Transaction/ResetBonusRollOverRecord";

  static _API_GET_LATEST_MEMBER_DATA = "/Transaction/GetLatestMemberData";
  static _API_GET_VIP_CRITERIA = "/Configuration/GetVipCriteria";
  static _API_CREATE_OR_UPDATE_COMPANY_SETTING = "/Configuration/CreateOrUpdateCompanySetting";
  static _API_CREATE_OR_UPDATE_COMPANY_VIP_PLAYER_REBATE = "/Configuration/CreateOrUpdateCompanyVipPlayerRebate";
  static _API_GET_TOP_MEMBER_TRANSACTION_BY_STATUS = "/Transaction/GetTopMemberTransactionByStatus";


  static _API_GET_USER_VIP = "/Configuration/GetUserVip";
  static _API_GET_USER_VIP_BY_ID = "/Configuration/GetUserVipById";
  static _API_CREATE_OR_UPDATE_VIP = "/Configuration/CreateOrUpdateVip";

  static _API_GET_SYSTEM_SETTINGS = "/Configuration/GetSystemSettings";
  static _API_UPDATE_SYSTEM_SETTING = "/Configuration/UpdateSystemSetting";

  static _API_GET_USER_REMARK = "/Configuration/GetUserRemark";
  static _API_GET_USER_REMARK_BY_ID = "/Configuration/GetUserRemarkById";
  static _API_CREATE_OR_UPDATE_REMARK = "/Configuration/CreateOrUpdateRemark";
  static _API_REMARK_TYPE = "/Configuration/GetRemarkType";
  static _API_REMARK_BY_TYPE = "/Configuration/GetRemarkByType";


  static _API_GET_USER_REJECT_CODE = "/Configuration/GetUserRejectCode";
  static _API_GET_USER_REJECT_CODE_BY_ID = "/Configuration/GetUserRejectCodeById";
  static _API_CREATE_OR_UPDATE_REJECT_CODE = "/Configuration/CreateOrUpdateRejectCode";

  static _API_GET_USER_TAG = "/Configuration/GetUserTag";
  static _API_GET_USER_TAG_BY_ID = "/Configuration/GetUserTagById";
  static _API_CREATE_OR_UPDATE_TAG = "/Configuration/CreateOrUpdateTag";

  static _API_GET_PRODUCT_CATEGORY = "/Product/GetProductCategory";
  static _API_GET_PRODUCT_CATEGORY_BY_ID = "/Product/GetProductCategoryById";
  static _API_CREATE_OR_UPDATE_PRODUCT_CATEGORY = "/Product/CreateOrUpdateProductCategory";

  static _API_GET_MASTER_PRODUCT = "/Product/GetMasterProduct";
  static _API_GET_MASTER_PRODUCT_BY_ID = "/Product/GetMasterProductById";
  static _API_GET_MASTER_PRODUCT_ASSIGNMENT_DATA = "/Product/GetAssignedMasterProduct";

  static _API_GET_NON_SEAMLESS_MASTER_PRODUCT_ASSIGNMENT_DATA = "/Product/GetAssignedNonSeamlessMasterProduct";

  static _API_CREATE_OR_UPDATE_MASTER_PRODUCT = "/Product/CreateOrUpdateMasterProduct";
  static _API_UPDATE_USER_ASSIGNMENT = "/Product/UpdateProductAssignment";
  static _API_UPDATE_USER_PRODUCT_MAINTENANCE = "/Product/UpdateProductUnderMaintenance";
  static _API_UPDATE_USER_PRODUCT_ACTIVENESS = "/Product/UpdateUserProductActiveness";
  static _API_UPDATE_MEMBER_PRODUCT_ACTIVENESS = "/Product/UpdateMemberProductActiveness";
  static _API_GET_ASSIGNED_MEMBER_PRODUCT = "/Product/GetAssignedMemberProduct";
  static _API_GET_ASSIGNED_MEMBER_PRODUCT_SHOW_LIST = "/Product/GetAssignedMemberProductShowList";
  static _API_UPDATE_ALL_MEMBER_PRODUCT_ACTIVENESS = "/Product/UpdateAllMemberProductActiveness";
  static _API_UPDATE_MEMBER_PRODUCT_RATE = "/Product/UpdateMemberProductRate";
  static _API_GET_GROUP_PRODUCT_WITH_MAX_RATES = "/Product/GetGroupedProductWithMaxRates"

  static _API_UPDATE_USER_ASSIGNMENT_DETAIL = "/Product/UpdateProductAssignmentDetail";
  static _API_GET_USER_ASSIGNMENT_DETAIL = "/Product/GetProductAssignmentDetail";

  static _API_GET_PRODUCT_SETTING = "/Product/GetUserProductSetting";
  static _API_GET_PRODUCT_SETTING_BY_ID = "/Product/GetUserProductSettingById";
  static _API_CREATE_OR_UPDATE_PRODUCT_SETTING = "/Product/CreateOrUpdateInstantPaySetting";

  static _API_GET_ACCOUNT_PRODUCT_META = "/Product/GetAccountProductMeta";
  static _API_UPDATE_ACCOUNT_PRODUCT_META = "/Product/UpdateAccountProductMeta";

  static _API_GET_PRODUCT_GAME_SETTING = "/Product/GetProductGameSetting";
  static _API_GET_PRODUCT_GAME_SETTING_BY_ID = "/Product/GetProductGameSettingById";
  static _API_CREATE_OR_UPDATE_GAME_PRODUCT_SETTING = "/Product/CreateOrUpdateProductGameSetting";
  static _API_GET_PRODUCT_GAME_BY_ACCOUNT_PRODUCT = "/Product/GetProductGameByAccountProduct";

  static _API_GET_LANGUAGE = "/Configuration/GetLanguage";
  static _API_GET_LANGUAGE_BY_ID = "/Configuration/GetLanguageById";
  static _API_CREATE_OR_UPDATE_LANGUAGE = "/Configuration/CreateOrUpdateLanguage";

  static _API_GET_BANKING_CHANNEL = "/Configuration/GetBankingChannel";
  static _API_GET_BANKING_CHANNEL_BY_ID = "/Configuration/GetBankingChannelById";
  static _API_CREATE_OR_UPDATE_BANKING_CHANNEL = "/Configuration/CreateOrUpdateBankingChannel";

  static _API_GET_BONUS_TYPE = "/Configuration/GetBonusType";
  static _API_GET_BONUS_TYPE_BY_ID = "/Configuration/GetBonusTypeById";
  static _API_CREATE_OR_UPDATE_BONUS_TYPE = "/Configuration/CreateOrUpdateBonusType";

  static _API_GET_USER_BONUS = "/Configuration/GetUserBonus";
  static _API_GET_USER_BONUS_BY_ID = "/Configuration/GetUserBonusById";
  static _API_CREATE_OR_UPDATE_BONUS = "/Configuration/CreateOrUpdateBonus";
  static _API_GET_OPERATION_LOG = "/Configuration/GetOperationLog";

  static _API_DELETE_PRODUCT_GAME = "/Product/DeleteProductGame";
  static _API_GET_PRODUCT_GAME = "/Product/GetProductGame";
  static _API_GET_PRODUCT_GAME_BY_ID = "/Product/GetProductGameById";
  static _API_CREATE_OR_UPDATE_GAME_PRODUCT = "/Product/CreateOrUpdateProductGame";

  static _API_GET_USER_ANNOUNCEMENT = "/Configuration/GetUserAnnouncement";
  static _API_GET_USER_ANNOUNCEMENT_BY_ID = "/Configuration/GetUserAnnouncementById";
  static _API_CREATE_OR_UPDATE_ANNOUNCEMENT = "/Configuration/CreateOrUpdateAnnouncement";

  static _API_GET_USER_BANNER = "/Configuration/GetUserBanner";
  static _API_GET_USER_BANNER_BY_ID = "/Configuration/GetUserBannerById";
  static _API_CREATE_OR_UPDATE_BANNER = "/Configuration/CreateOrUpdateBanner";

  static _API_GET_USER_REWARD_BANNER = "/Configuration/GetUserRewardBanner";
  static _API_GET_USER_REWARD_BANNER_BY_ID = "/Configuration/GetUserRewardBannerById";
  static _API_CREATE_OR_UPDATE_REWARD_BANNER = "/Configuration/CreateOrUpdateRewardBanner";

  static _API_GET_USER_PROMOTION = "/Configuration/GetUserPromotion";
  static _API_GET_USER_PROMOTION_BY_ID = "/Configuration/GetUserPromotionById";
  static _API_CREATE_OR_UPDATE_PROMOTION = "/Configuration/CreateOrUpdatePromotion";

  static _API_GET_USER_SCRIPT = "/Configuration/GetUserScript";
  static _API_GET_USER_SCRIPT_BY_ID = "/Configuration/GetUserScriptById";
  static _API_CREATE_OR_UPDATE_SCRIPT = "/Configuration/CreateOrUpdateScript";

  static _API_GET_USER_CONTENT = "/Configuration/GetUserContent";
  static _API_GET_USER_CONTENT_BY_ID = "/Configuration/GetUserContentById";
  static _API_CREATE_OR_UPDATE_CONTENT = "/Configuration/CreateOrUpdateContent";

  static _API_GET_USER_EMAIL = "/Configuration/GetUserEmail";
  static _API_GET_USER_EMAIL_BY_ID = "/Configuration/GetUserEmailById";
  static _API_CREATE_OR_UPDATE_EMAIL = "/Configuration/CreateOrUpdateEmail";

  static _API_GET_VIP_BENEFIT = "/Configuration/GetVipBenefit";
  static _API_GET_VIP_BENEFIT_BY_ID = "/Configuration/GetVipBenefitById";
  static _API_CREATE_OR_UPDATE_VIP_BENEFIT = "/Configuration/CreateOrUpdateVipBenefit";

  static _API_GET_VIP_BENEFIT_CONTENT = "/Configuration/GetVipBenefitContent";
  static _API_GET_VIP_BENEFIT_CONTENT_BY_ID = "/Configuration/GetVipBenefitContentById";
  static _API_CREATE_OR_UPDATE_VIP_BENEFIT_CONTENT = "/Configuration/CreateOrUpdateVipBenefitContent";
  static _API_GET_VIP_CRITERIA_BY_VIP_ID = "/Configuration/GetVipCriteriaByVipId";

  static _API_CREATE_OR_UPDATE_VIP_CRITERIA = "/Configuration/CreateOrUpdateVipCriteria";

  static _API_GET_USER_INSTANT_PAY_SETTING = "/Configuration/GetUserInstantPaySetting";
  static _API_GET_USER_INSTANT_PAY_SETTING_BY_ID = "/Configuration/GetUserInstantPaySettingById";
  static _API_CREATE_OR_UPDATE_INSTANT_PAY_SETTING = "/Configuration/CreateOrUpdateInstantPaySetting";
  static _API_UPDATE_INSTANT_PAY_SETTING_STATUS = "/Configuration/UpdateInstantPaySettingStatus";
  static _API_GET_INSTANT_PAYMENT_GATEWAY = "/Configuration/GetInstantPayGateway";
  static _API_GET_INSTANT_PAY_BANK_SETTING = "/Configuration/GetInstantPayBankSetting";
  static _API_CREATE_OR_UPDATE_INSTANT_PAY_BANK_SETTING = "/Configuration/CreateOrUpdateInstantPayBankSetting";
  static _API_DELETE_INSTANT_PAYBANK_SETTING = "/Configuration/DeleteInstantPayBankSetting";

  static _API_GET_SKIN_SETTINGS = "/Configuration/GetSkinSettings";
  static _API_GET_SKIN_SETTINGS_BY_ID = "/Configuration/GetSkinSettingsById";
  static _API_CREATE_OR_UPDATE_SKIN_SETTINGS = "/Configuration/CreateOrUpdateSkinSettings";

  static _API_GET_VIDEO = "/Configuration/GetVideo";
  static _API_GET_VIDEO_BY_ID = "/Configuration/GetVideoById";
  static _API_CREATE_OR_UPDATE_VIDEO = "/Configuration/CreateOrUpdateVideo";

  static _API_GET_USER_SYSTEM_SETTINGS = "/Configuration/GetUserSystemSettings";
  static _API_CREATE_OR_UPDATE_SYSTEM_SETTINGS = "/Configuration/CreateOrUpdateSystemSettings";

  static _API_GET_USER_MEMBER = "`/GetUserMember";
  static _API_GET_USER_MEMBER_LIST = "/Account/GetUserMemberList";
  static _API_GET_DOWNLINE_BY_REFERRER = "/Account/GetDownlineByReferrer";

  static _API_GET_DOWNLINE_BY_REFERRER_USING_USERNAME = "/Account/GetDownlineByReferrerUsingUsername";

  static _API_GET_TOTAL_REGISTERED_MEMBER_UNDER_COMPANY = "/Account/GetTotalRegisteredMemberUnderCompany";
  static _API_GET_TOTAL_REGISTERED_MEMBER_UNDER_MAIN_ACCOUNT = "/Account/GetTotalRegisteredMemberUnderMainAccount";
  static _API_GET_NEW_MEMBERS_LIST_UNDER_COMPANY = "/Account/GetNewMembersListUnderCompany";
  static _API_GET_NEW_MEMBERS_LIST_UNDER_MAIN_ACCOUNT = "/Account/GetNewMembersListUnderMainAccount";


  static _API_GET_MEMBER_PRODUCT_USERNAME_LIST = "/Account/GetMemberProductUsernameList";
  static _API_GET_MEMBER_FULLNAME_LIST = "/Account/GetMemberFullnameList";

  static _API_GET_USER_MEMBER_BY_ID = "/Account/GetUserMemberById";
  static _API_GET_USER_MEMBER_BY_USERNAME = "/Account/GetUserMemberByUsername";
  static _API_CREATE_OR_UPDATE_MEMBER = "/Account/CreateOrUpdateMember";
  static _API_GET_MEMBER_HIERACHY = "/Account/GetMemberHierachy";
  static _API_GET_BLOCKED_IP_LIST = "/Account/GetBlockedIpList";
  static _API_DELETE_BLOCKED_IP = "/Account/DeleteBlockedIp";

  static _API_DELETE_REFERRAL_CODE = "/Account/DeleteReferralCode";

  static _API_GET_USER_SMS_SETTING = "/Configuration/GetUserSmsSetting";
  static _API_GET_USER_SMS_SETTING_BY_ID = "/Configuration/GetUserSmsSettingById";
  static _API_CREATE_OR_UPDATE_USER_SMS_SETTING = "/Configuration/CreateOrUpdateSmsSetting";

  static _API_GET_USER_SMS_CONTENT = "/Configuration/GetUserSmsContent";
  static _API_GET_USER_SMS_CONTENT_BY_ID = "/Configuration/GetUserSmsContentById";
  static _API_CREATE_OR_UPDATE_SMS_CONTENT = "/Configuration/CreateOrUpdateSmsContent";

  static _API_GET_USER_WEB_CONTENT_SETTING = "/Configuration/GetUserWebContentSetting";
  static _API_GET_USER_WEB_CONTENT_SETTING_BY_ID = "/Configuration/GetUserWebContentSettingById";
  static _API_CREATE_OR_UPDATE_USER_WEB_CONTENTS = "/Configuration/CreateOrUpdateWebContents";

  static _API_GET_USER_WEB_CONTENT = "/Configuration/GetUserWebContent";
  static _URL_CREATE_OR_UPDATE_WEB_CONTENT = "/configure/CreateOrUpdateWebContent";
  static _API_GET_USER_WEB_CONTENT_BY_ID = "/Configuration/GetUserManageWebContentById";
  static _API_GET_MARQUEE_TAG_CONTENT = "/Configuration/GetMarqueeTagContent";
  static _API_UPDATE_MARQUEE_TAG_CONTENT = "/Configuration/UpdateMarqueeTagContent";
  static _API_GET_MARQUEE_TAG_CONTENT_WITH_ID = "/Configuration/GetMarqueeTagContentWithId";
  static _API_GET_MARQUEE_LANGUAGE = "/Configuration/GetMarqueeLanguage";
  static _API_CREATE_MARQUEE_CONTENT_LANGUAGE = "/Configuration/CreateMarqueeContentLanguage";
  static _API_UPDATE_MARQUEE_LANGUAGE_BY_ID = "/Configuration/UpdateMarqueeLanguageById";

  static _API_CREATE_TRANSACTION = "/Transaction/CreateTransaction";
  static _API_GET_ALL_USER_TRANSACTION = "/Transaction/GetAllUserTransactions";
  static _API_GET_TRANSACTION_BY_MEMBER_ID = "/Transaction/GetTransactionByMemberId";
  static _API_UPDATE_TRANSACTION_STATUS = "/Transaction/UpdateTransactionStatus";
  static _API_GET_TOP_MEMBER_OF_TRANSACTION_BY_STATUS = "/Home/GetTopMemberOfTransactionByStatus";
  static _API_GET_TOTAL_TRANSACTION_AMOUNT_COUNT_BY_STATUS_TYPE = "/Home/GetTotalTransactionAmountAndCountByStatusAndType";
  static _API_GET_ALL_TOTAL_DEPOSIT_TRANSACTION_BY_TODAY = "/Home/GetAllTotalDepositTransactionByToday";
  static _API_GET_ALL_TOTAL_DEPOSIT_TRANSACTION_BY_TODAY_NEW = "/Home/GetAllTotalDepositTransactionByTodayNew";
  static _API_GET_TRANSACTION_BY_ID = "/Transaction/GetTransactionById";
  static _API_GET_PREVIOUS_TRANSACTION = "/Transaction/GetPreviousTransaction";
  static _API_GET_SYSTEM_BALANCE = "/Transaction/GetSystemBalance";
  static _API_PROCESS_LOCK_TRANSACTION = "/Transaction/ProcessLockTransaction";
  static _API_PROCESS_BONUS_ADJUSTMENT = "/Transaction/ProcessBonusAdjustment";
  static _API_PROCESS_COMMISSION_ADJUSTMENT = "/Transaction/ProcessCommissionAdjustment";

  static _API_GET_DAILY_SUMMARY_REPORT = "/Report/GetDailySummaryReport";
  static _API_GET_MEMBER_REPORT = "/Report/GetMemberReport";
  static _API_GET_BANK_SUMMARY_REPORT = "/Report/GetBankSummaryReport";
  static _API_GET_BANK_SUMMARY_STATEMENT = "/Report/GetBankSummaryStatement";
  static _API_GET_BET_DETAIL_REPORT = "/Report/GetBetDetailReport";
  static _API_GET_MEMBER_BET_DETAIL_REPORT = "/Report/GetMemberBetDetailReport";
  static _API_GET_PROFIT_LOSS_REPORT = "/Report/GetProfitLossReport";
  static _API_GET_WINLOSS_REPORT = "/Report/GetWinlossReport";
  static _API_GET_TALLY_WINLOSS_REPORT = "/Report/GetTallyWinlossReport";
  static _API_GET_WINLOSS_REPORT2 = "/Report/GetWinlossReport2";
  static _API_GET_WINLOSS_REPORT3 = "/Report/GetWinlossReport3";
  static _API_GET_BONUS_COMMISSION_REPORT = "/Report/GetBonusCommissionReport";
  static _API_MANAGE_TRANSFER_WALLET = "/Report/GetWalletTransfer";
  static _API_COMMISSION_TRACKING = "/Report/GetCommissionTracking";
  static _API_GET_ADJUSTMENT_REPORT = "/Report/GetAdjustmentReport";

  static _API_GET_SHAREHOLDER_PROCESS_SALES_REPORT = "/Report/GetShareholderProcessSalesReport";
  static _API_GET_MEMBER_SHAREHOLDER_DETAIL = "/Report/GetMemberShareholderDetail";

  static _API_GET_PROCESS_GAME_RECORD_REPORT = "/Report/GetProcessGameRecordReport";
  static _API_GET_568_NON_PROCESS_GAME_RECORD_REPORT = "/Report/Get568NonProcessGameRecordReport";

  static _API_GET_UNCLAIMED_SALES_REPORT = "/Report/GetUnclaimedSalesReport";
  static _API_GET_COMISSION_REPORT = "/Report/GetComissionReport";

  static _API_GET_TOTAL_SHARE_PAYOUT_REPORT = "/Report/GetTotalSharePayoutReport";

  static _API_GET_TOP_WINNERS_AND_LOSERS = "/Report/GetTopWinnersAndLosers";
  static _API_GET_TOP_WINNERS_LOSERS = "/Report/GetTopWinnersLosers";

  static _API_GET_TRANSFER_LOG = "/Report/GetTransferLog";

  static _API_GET_IP_MONITOR_RECORD = "/Account/GetIpMonitorRecord";
  static _API_GET_USER_GROUPED_IP = "/Account/GetUserGroupedIpDetail";
  static _API_GET_USER_IP_DETAIL = "/Account/GetUserIpDetail";

  static _API_GET_USER_PRODUCT_ACCOUNT = "/Account/GetUserProductAccount";
  static _API_GET_PRODUCT_ACCOUNT_LOG = "/Account/GetProductAccountLog";
  static _API_GET_PRODUCT_ACCOUNT_BALANCE = "/Account/GetProductAccountBalance";

  static _API_CREATE_BANK_TRANSACTION = "/Transaction/CreateBankTransaction";

  static _API_GET_API_KEY_DATA = "/Configuration/GetApiKeyData";

  static _API_EXECUTE_RECURRING_JOB = "/Configuration/ExecuteRecurringJob";
  static _API_EXECUTE_MANUAL_JOB = "/Configuration/ExecuteManualJob";

  static _API_SYNC_PRODUCT_DATA = "/Product/SyncProductAgainstUser";
  static _API_SYNC_MEMBER_TO_OWNER_PRODUCT_DATA = "/Product/SyncMemberToOwnerProduct";
  static _API_GET_NON_SEAMLESS_PRODUCT_BALANCE = "/Product/GetNonSeamlessProductBalance";
  static _API_CREATE_WALLET_TRANSFER_TRANSACTION = "/Transaction/CreateWalletTransferTransaction";
  static _API_GET_PRODUCT_TRANSACTION = "/Transaction/GetProductTransactionRecord";
  static _API_TRANSFER_ALL_TO_MAIN = "/Transaction/TransferAllBalanceToMain";
  static _API_GET_FIRST_GROUPED_PRODUCT = "/Product/GetFirstGroupedProduct";

  static _API_GET_PREFERENCE_LANGUAGE = "/Preference/GetLanguage";
  static _API_CREATE_OR_UPDATE_CONFIG_LANGUAGE = "/Configuration/CreateOrUpdateConfigLanguage";
  static _API_GET_CONFIG_LANGUAGE_BY_TYPE_ID = "/Configuration/GetConfigLanguageByTypeId";
  static _API_GET_CONFIG_LANGUAGE_BY_ID = "/Configuration/GetConfigLanguageById";
  static _API_GET_COMPANY_CONTACT_US = "/Configuration/GetCompanyContactUs";
  static _API_GET_COMPANY_CONTACT_US_BY_ID = "/Configuration/GetCompanyContactUsById";
  static _API_CREATE_OR_UPDATE_COMPANY_CONTACT_US = "/Configuration/CreateOrUpdateCompanyContactUs";

  static _API_GET_MEMBER_UPLINE_PRODUCT_RATES = "/Account/GetUplineProductRates";
  static _API_GENERATE_REFERRAL_CODE = "/Account/GenerateReferralCode";
  static _API_GET_MEMBER_REFERRAL_CODES = "/Account/GetMemberReferralCodes";
  static _API_PERFORM_GAME_CALCULATION = "/Configuration/PerformGameCalculation";
  static _API_PERFORM_PRODUCT_GAME_CALCULATION = "/Configuration/PerformProductGameCalculation";
  static _API_ACTIVATE_SYNC = "/Configuration/SyncGameRecords";
  static _API_TRIGGER_RANGE_SYNC = "/Configuration/TriggerRangeSyncData";
  static _API_ACTIVATE_SETTLEMENT_SYNC = "/Configuration/SettleBets";
  static _API_TRIGGER_SETTLEMENT_DATE = "/Configuration/RerunSettleBets";

  static _API_CREATE_OR_UPDATE_COMMUNICATION_CHANNEL = "/Configuration/CreateOrUpdateCommunicationChannel";
  static _API_GET_COMMUNICATION_CHANNEL_DETAIL = "/Configuration/GetCommunicationChannelById";
  static _API_GET_COMMUNICATION_CHANNELS = "/Configuration/GetCommunicationChannel";

  static _API_GET_GAME_RECORD_BY_MEMBER = "/Product/GetProductGameRecordByMember";
  static _API_UPDATE_DEVICE_TOKEN = "/Account/UpdateDeviceToken";
  static _API_GET_RECENT_NOTIFICATIONS = "/Account/RetrieveRecentNotification";
  static _API_READ_NOTIFICATION = "/Account/ReadNotification";

  static _API_CHECK_PENDING_DEPOSIT = "/Transaction/CheckPendingDeposit";
  static _API_GET_PRODUCT_GAME_PAYLOAD = "/Product/GetProductGamePayload";
  static _API_GET_BET_RESULT = "/Product/GetBetResult";
  static _API_GET_API_LOG = "/Product/GetApiLog";
  static _API_GET_CANCEL_LOG = "/Product/GetCancelLog";


  static _API_UPDATE_SHAREHOLDER_DETAIL = "/Account/UpdateShareholderMember";
  static _API_UPDATE_SHAREHOLDER_PRODUCT = "/Account/UpdateShareholderProduct";
  static _API_UPDATE_SHAREHOLDER_PAYOUT = "/Account/UpdateShareholderPayout";
  static _API_UPDATE_SHAREHOLDER_HUAT_TICKET = "/Account/UpdateShareholderHuat";
  static _API_GET_SHAREHOLDER_MASTER_PRODUCT_DATA_LIST = "/Product/GetShareholderMasterProductList";
  static _API_GET_SHAREHOLDER_MASTER_PRODUCT_DATA = "/Product/GetShareholderMasterProduct";
  static _API_GET_SHAREHOLDER_ONE_PRODUCT_DATA = "/Product/GetShareholderOneProduct";
  static _API_GET_SHAREHOLDER_HUAT_TICKET = "/Product/GetShareholderHuatTicket";
  static _API_UPDATE_SHAREHOLDER_PROCESS = "/Account/ProcessShareholderPayout";
  static _API_UPDATE_SHAREHOLDER_RELEASE = "/Account/ReleaseShareholderPayout";

  static _API_MANUAL_RESETTLE_568_PRODUCT_GAME_RECORD_FROM_BO = "/Transaction/ManualResettle568ProductGameRecordFromBo";

  static _API_CLEAR_CACHE = "/Report/ClearCacheWinLoss";

  static _API_GET_MEMBER_WALLET_STATEMENT = "/Transaction/GetMemberWalletStatement";

  static _API_GET_MEMBER_WALLET_STATEMENT_RECON = "/Transaction/GetMemberWalletStatementRecon";

  static _API_GET_TRANSACTION_QUEUE = "/Transaction/GetTransactionQueue";
  static _API_REMOVE_PLAYER_FROM_TRANSACTION_QUEUE = "/Transaction/RemovePlayerFromTransactionQueue";

  static _API_GET_LIVE_DATA = "/Report/GetLiveData";
  static _API_GET_SPORTS_LIVE_DATA = "/Report/GetSportsLiveData";

  static _API_GET_REWARDS_SETTING = "/Product/GetRewardsSetting";
  static _API_UPDATE_REWARDS_SETTING = "/Product/UpdateRewardsSetting";
  static _API_GET_REWARDS_SCHEDULE = "/Product/GetRewardsSchedule";
  static _API_UPDATE_REWARDS_SCHEDULE = "/Product/UpdateRewardsSchedule";

  static _API_GET_MEMBER_SPORTS_BET_LIMIT = "/Product/GetMemberSportsBetLimit";
  static _API_CREATE_OR_UPDATE_MEMBER_SPORTS_BET_LIMIT = "/Product/CreateOrUpdateMemberSportsBetLimit";

  static _API_GET_MEMBER_DOWNLINE_GAMES_BY_MASTERPRODUCT = "/Account/GetMemberDownlineGameByMasterProduct";
  static _API_UPDATE_DOWNLINE_MEMBER_GAME = "/Product/UpdateDownlineMemberGame";

  static _API_MANUAL_CANCEL_BET = "/Account/ManualCancelBet";

  static _API_GET_CLAIMED_UNCLAIMED_REWARDS_REPORT = "/Report/GetClaimedRewardsReport";
  static _API_GET_FORFEITED_REWARDS_REPORT = "/Report/GetForfeitedRewardsReport";

  static _API_GET_SPORTS_BET_REPORT = "/Report/GetSportsBetReport";

  static _API_UNSTUCK_SH = "/Transaction/UnstuckSH";

  static _API_RETRIEVE_MEMBER_GAME_DATA = "/Product/RetrieveMemberGameData";
  static _API_RESET_PRODUCT_PROCESS = "/Product/ResetProductProcess";

  static _API_VERIFY_USER_TRANSACTION_PIN_NUMBER = "/Account/VerifyUserTransactionPinNumber";
  static _API_GET_MEMBER_SPORTS_BET_LIMIT = "/Product/GetMemberSportsBetLimit";
  static _API_CREATE_OR_UPDATE_MEMBER_SPORTS_BET_LIMIT = "/Product/CreateOrUpdateMemberSportsBetLimit";

  static _API_GET_MEMBER_DOWNLINE_GAMES_BY_MASTERPRODUCT = "/Account/GetMemberDownlineGameByMasterProduct";
  static _API_UPDATE_DOWNLINE_MEMBER_GAME = "/Product/UpdateDownlineMemberGame";

  static _API_MANUAL_CANCEL_BET = "/Account/ManualCancelBet";

  static _API_GET_CLAIMED_UNCLAIMED_REWARDS_REPORT = "/Report/GetClaimedRewardsReport";
  static _API_GET_FORFEITED_REWARDS_REPORT = "/Report/GetForfeitedRewardsReport";

  static _API_GET_SPORTS_BET_REPORT = "/Report/GetSportsBetReport";

  static _API_UNSTUCK_SH = "/Transaction/UnstuckSH";

  static _API_RETRIEVE_MEMBER_GAME_DATA = "/Product/RetrieveMemberGameData";
  static _API_RESET_PRODUCT_PROCESS = "/Product/ResetProductProcess";

  static _API_GET_MOBILE_TAC = "/Account/GetMobileTAC";

  static _API_GET_TRANSACTION_SUMMARY_REPORT = "/Transaction/GetTransactionSummaryRep";

  static _API_GET_DEPOSIT_PROMOTION = "/Configuration/GetDepositPromotion";
  static _API_GET_DEPOSIT_PROMOTION_BY_ID = "/Configuration/GetDepositPromotionById";
  static _API_CREATE_OR_UPDATE_DEPOSIT_PROMOTION = "/Configuration/CreateOrUpdateDepositPromotion";

  static _API_GET_ACCOUNT_PRODUCT_BY_PROMOTION_ID = "/Configuration/GetAccountProductByPromotionId";

  static _API_GET_MASTER_PRODUCT_ID_NAME_BY_USER_ID = "/Product/GetMasterProductIdNameByUserId";

  static _API_CREATE_OR_UPDATE_DEPOSIT_PROMOTION_PRODUCT = "/Product/CreateOrUpdateDepositPromotionProduct";
  static _API_GET_DEPOSIT_PROMOTION_PRODUCT = "/Product/GetDepositPromotionProduct";
  static _API_DELETE_DEPOSIT_PROMOTION_PRODUCT = "/Product/DeleteDepositPromotionProduct";

  static _API_GET_MEMBER_BIRTHDAY_LIST = "/Account/GetMemberBirthdayList";
  static _API_GENERATE_BIRTHDAY_REWARD = "/Account/GenerateBirthdayReward";

  static _API_GET_MEMBER_REBATE_LIST = "/Account/GetMemberRebateList";
  static _API_GENERATE_REBATE_REWARD = "/Account/GenerateRebateReward";
  static _API_GET_WEEKLY_RESCUE = "/Configuration/GetWeeklyRescue";
  static _API_GET_WEEKLY_RESCUE_BY_ID = "/Configuration/GetWeeklyRescueById";
  static _API_CREATE_OR_UPDATE_WEEKLY_RESCUE = "/Configuration/CreateOrUpdateWeeklyRescue";

  static _API_GET_BIRTHDAY_REWARD_REPORT = "/Account/GetBirthdayRewardRep";
  static _API_GET_GIFTPACE_REPORT = "/Report/GetGiftpaceRep";
  static _API_GET_MEMBER_WEEKLY_RESCUE = "/Account/GetMemberWeeklyRescue";
  static _API_GENERATE_MEMBER_WEEKLY_RESCUE = "/Account/GenerateMemberWeeklyRescue";

  static _API_GET_DAILY_CHECK_IN = "/Configuration/GetDailyCheckIn";
  static _API_GET_DAILY_CHECK_IN_BY_ID = "/Configuration/GetDailyCheckInById";
  static _API_CREATE_OR_UPDATE_DAILY_CHECK_IN = "/Configuration/CreateOrUpdateDailyCheckIn";
  static _API_GET_DAILY_CHECK_IN_DETAILS_BY_DCID = "/Configuration/GetDailyCheckInDetailsByDCId";
  static _API_CREATE_OR_UPDATE_DAILY_CHECK_IN_DETAILS = "/Configuration/CreateOrUpdateDailyCheckInDetails"

  static _API_GET_ALL_DC_ITEM = "/Product/GetAllDCItem";
  static _API_GET_DC_ITEM_BY_ID = "/Product/GetDCItemById";
  static _API_CREATE_OR_UPDATE_DC_ITEM = "/Product/CreateOrUpdateDCItem";
  static _API_UPDATE_DC_ITEM_STATUS = "/Product/UpdateDCItemStatus";
  static _API_GET_DC_REDEMPTION_RECORD = "/Product/GetDCRedemptionRecord";
  static _API_UPDATE_DC_REDEMPTION = "/Product/UpdateDCRedemption";
  static _API_UPDATE_PROCESSING_DC_REDEMPTION = "/Product/UpdateDCProccessingRedemption";
  static _API_GET_DC_ITEM_NAME_LIST = "/Product/GetDCItemNameList";
  static _API_GET_ACCOUNT_PRODUCT = "/Product/GetAccountProduct";
  static _API_GET_ACCOUNT_PRODUCT_BY_PRODUCT_ID = "/Product/GetAccountProductByProductId";
  static _API_UPDATE_DC_POINTS = "/Product/UpdateDCPoints";
  static _API_GET_MEMBER_DC_POINTS = "/Product/GetMemberDCPoints";
  static _API_GET_DC_POINTS_HISTORY = "/Transaction/GetDCPointsHistory";

  static _API_GET_MEMBER_DAILY_RESCUE = "/Account/GetMemberDailyRescue";
  static _API_GENERATE_MEMBER_DAILY_RESCUE = "/Account/GenerateMemberDailyRescue";
  static _API_GET_ACTIVE_MEMBER_REPORT = "/Report/GetActiveMemberReport";
  static _API_RESET_WALLET_ROLLOVER_RECORD = "/Transaction/ResetWalletRollOverRecord";
  static _API_CREATE_OR_UPDATE_MARQUEE_TAG_CONTENT = "/Configuration/CreateOrUpdateMarqueeTagContent";
  static _API_CREATE_DCITEM_CONTENT_LANGUAGE = "/Configuration/CreateDCItemContentLanguage";
  static _API_GET_DCITEM_LANGUAGE = "/Configuration/GetDCItemLanguage";
  static _API_UPDATE_DCITEM_LANGUAGE_BY_ID = "/Configuration/UpdateDCItemLanguageById";
  static _API_GET_MEMBER_GAME_POINTS = "/Account/GetMemberGamePoints";
  static _API_GENERATE_MEMBER_GAME_POINTS = "/Account/GenerateMemberGamePoints";
  static _API_GET_POINTS_HISTORY = "/Transaction/GetPointsHistory";
  static _API_CHECK_PENDING_POINTS_REDEMPTION = "/Transaction/CheckPendingPointsRedemption";
  static _API_UPDATE_POINTS_ADJUSTMENT = "/Transaction/UpdatePointsAdjustment";
  static _API_GET_POINTS_ADJUSTMENT_REPORT = "/Report/GetPointsAdjustmentReport";
  static _API_GET_OVERALL_POINTS_DETAILS = "/Transaction/GetOverallPointsDetail";

  static _API_GET_DEPOSIT_PROMOTION_CATEGORY = "/Configuration/GetDepositPromotionCategory";
  static _API_GET_DEPOSIT_PROMOTION_CATEGORY_BY_ID = "/Configuration/GetDepositPromotionCategoryById";
  static _API_CREATE_OR_UPDATE_DEPOSIT_PROMOTION_CATEGORY = "/Configuration/CreateOrUpdateDepositPromotionCategory";
  static _API_GET_DEPOSIT_PROMOTION_WITHOUT_CATEGORY = "/Configuration/GetDepositPromotionWithoutCategory";
  static _API_GET_DEPOSIT_PROMOTION_WITH_CATEGORY = "/Configuration/GetDepositPromotionWithCategory";
  static _API_GET_ALL_BANK_SUMMARY_STATEMENT = "/Report/GetAllBankSummaryStatement";

  static _API_GET_USER_DOMAIN = "/Account/GetUserDomain";
  static _API_GET_USER_DOMAIN_ID = "/Account/GetUserDomainID";
  static _API_UPDATE_OR_CREATE_USER_DOMAIN =
    "/Account/UpdateOrCreateUserDomain";
  static _API_REMOVE_USER_DOMAIN = "/Account/RemoveDomain";

  static _API_GET_MEMBER_ACCOUNT_BALANCE_JOURNEY = "/Report/GetMemberAccountBalanceJourney";

  static _API_UPDATE_WING_WEILUY_TRANSACTION_STATUS = "/Transaction/UpdateWingWeiluyTransactionStatus";
  static _API_GET_MEMBER_LIST = "/Account/GetMemberList";
  static _API_GET_CREDIT_REDEMPTION_REPORT = "/Report/GetCreditRedemptionReport";
  static _API_GET_ALL_BONUS_DEPOSIT = "/Transaction/GetAllBonusDeposit";
  static _API_GET_BONUS_DEPOSIT_BY_ID = "/Transaction/GetBonusDepositById";
  static _API_CREATE_OR_UPDATE_BONUS_DEPOSIT = "/Transaction/CreateOrUpdateBonusDeposit";
  static _API_GET_BONUS_DEPOSIT_LANGUAGE = "/Configuration/GetBonusDepositLanguage";
  static _API_CREATE_BONUS_DEPOSIT_LANGUAGE = "/Configuration/CreateBonusDepositLanguage";
  static _API_UPDATE_BONUS_DEPOSIT_LANGUAGE = "/Configuration/UpdateBonusDepositLanguage";

  static _API_UPDATE_CANCEL_DC_REDEMPTION = "/Product/UpdateDCCancelRedemption";

  static _API_CLONE_DEPOSIT_PROMOTION = "/Configuration/CloneDepositPromotion";
  static _API_BONUS_TRACKING = "/Report/GetBonusTracking";
  static _API_POINTS_TRACKING = "/Report/GetPointsTracking";
  static _API_GET_PLAYER_RECONCILIATION = "/Transaction/GetPlayerReconciliation";


  static _API_SEND_CHAT = "/api/chat/member/send";
  static _API_SEND_FILE = "/api/chat/member/file";
  static _API_SEND_VOICE = "/api/chat/member/voice";
  static _API_GET_CHAT_TEMPLATE = "/api/chat/template/all";
  static _API_GET_CHAT_TEMPLATE_CODE = "/api/chat/template/get";
  static _API_CREATE_OR_UPDATE_CHAT_SYSTEM_TEMPLATE = "/api/chat/template/save";

  //ChatSphere
  static _API_GET_CHAT_SPHERE = "/Configuration/GetChatSphere";
  static _API_GET_CHAT_SPHERE_BY_ID = "/Configuration/GetChatSphereById";
  static _API_GET_CHAT_SPHERE_TYPE = "/Configuration/GetChatSphereType";
  static _API_GET_CHAT_SPHERE_TYPE_BY_ID = "/Configuration/GetChatSphereTypeById";
  static _API_GET_CHAT_SPHERE_CHANNEL = "/Configuration/GetChatSphereChannel";
  static _API_CREATE_OR_UPDATE_CHAT_SPHERE_TYPE = "/Configuration/CreateOrUpdateChatSphereType";
  static _API_GET_CHAT_SPHERE_CONTENT_BY_ID = "/Configuration/GetChatSphereContentById";
  static _API_CREATE_OR_UPDATE_CHAT_SPHERE_CONTENT = "/Configuration/CreateOrUpdateChatSphereContent";
  static _API_CREATE_CHAT_SPHERE_CONTENT_LANGUAGE = "/Configuration/CreateChatSphereContentLanguage";
  static _API_UPDATE_CHAT_SPHERE_CONTENT_LANGUAGE = "/Configuration/UpdateChatSphereContentLanguageById";
  static _API_GET_CHAT_SPHERE_CONTENT_LANGUAGE = "/Configuration/GetChatSphereLanguage";
  static _API_GET_CHAT_SPHERE_PHONE_NUMBER = "/Configuration/GetChatSpherePhoneNumber";
  static _API_GET_CHAT_SPHERE_PHONE_NUMBER_BY_ID = "/Configuration/GetChatSpherePhoneNumberById";
  static _API_CREATE_OR_UPDATE_CHAT_SPHERE_PHONE_NUMBER = "/Configuration/CreateOrUpdateChatSpherePhoneNumber";

  static _API_CREATE_BONUS_WALLET_ADJUSTMENT = "/Transaction/CreateBonusWalletAdjustment";
  static _API_GET_MEMBER_BANK_ACCOUNT = "/Account/GetMemberBankAccount";
  static _API_GET_MEMBER_BANK_ACCOUNT_BY_ID = "/Account/GetMemberBankAccountById";
  static _API_UPDATE_MEMBER_BANK_ACCOUNT_BY_ID = "/Account/UpdateMemberBankAccount";

  static _API_GET_BANK_BY_TRANSACTION_TYPE = "/Configuration/GetBankByTransactionType";

  static _API_GET_ACCOUNT_PRODUCT_RANKING = "/Product/GetAccountProductRanking";
  static _API_UPDATE_ACCOUNT_PRODUCT_RANKING = "/Product/UpdateAccountProductRanking";

  static _API_GET_CUSTOM_PRODUCT_GAME_SETTING = "/Product/GetCustomProductGameSetting";
  static _API_UPDATE_CUSTOM_PRODUCT_GAME_SETTING = "/Product/UpdateCustomProductGameSetting";


  static _API_LOGIN = "/Account/Login";
  static _API_GET_CHAT_LIST = "/api/chat/list"
  static _API_GET_CHAT_ROOM = "/api/chat/room"

  static _API_GET_MEMBER_PROMOTION = "/Account/GetMemberPromotions";
  static _API_CANCEL_PROMO = "/Account/CancelPromotion";
  static _API_GET_MEMBER_CLAIMED_PROMOTION_DETAIL = "/Account/GetMemberClaimedPromotionDetail";

  static _API_GET_MEMBER_COMMISSION_LIST = "/Account/GetMemberCommissionList";
  static _API_GENERATE_MLM_COMMISSION = "/Account/GenerateMLMCommission";

  static _API_GET_DOWNLINE_BY_REFERRER2 = "/Account/GetDownlineByReferrer2";

  static _API_READ_NOTIFICATION = "/Account/ReadNotification";
  static _API_GET_MEMBER_WITH_UNREAD_NOTIFICATIONS = "/Account/RetrieveMembersWithUnreadNotification";
}

/// <summary>
/// Author : -
/// Url to navigate to pages
/// </summary>
export class WebUrl {
  static _URL_MAIN = "/";
  static _URL_LOGIN = "/login";
  static _URL_DASHBOARD = "/dashboard";
  static _URL_HELP = "/help";
  static _URL_SUPER_ACCOUNT = "/admin/superAccount";
  static _URL_MANAGE_COMPANY = "/admin/manageCompany";
  static _URL_CREATE_OR_EDIT_COMPANY = "/admin/companyDetail";
  static _URL_COMPANY_PROFILE = "/admin/companyProfile";
  static _URL_CREATE_OR_UPDATE_MEMBER = "/account/memberDetail";
  static _URL_MANAGE_MEMBER = "/account/manageMember";
  static _URL_CHAT_ROOM = "/chat/charoom";
  static _URL_CHAT_SYSTEM_TEMPLATE = "/chat/systemTemplate";
  static _URL_CHAT_CUSTOM_TEMPLATE = "/chat/customTemplate";

  static _URL_MANAGE_MEMBER_PRODUCT_USERNAME = "/account/manageMemberMPUsername";

  static _URL_MANAGE_BANK = "/configure/manageBank";
  static _URL_CREATE_OR_EDIT_BANK = "/configure/bankDetail";
  static _URL_BANK_ACCOUNT = "/transaction/bankAccounts";
  static _URL_CREATE_OR_UPDATE_BANK_ACCOUNT = "/configure/bankAccountDetail";
  static _URL_MANAGE_ROLE = "/admin/manageRoles";
  static _URL_CREATE_OR_UPDATE_ROLE = "/account/roleDetail";
  static _URL_MANAGE_SUB_ACCOUNT = "/admin/manageSubAccount";
  static _URL_CREATE_OR_UPDATE_SUB_ACCOUNT = "/account/subAccountDetail";
  static _URL_CHANGE_PASSWORD = "/admin/changePassword";
  static _URL_CHANGE_PIN_NUMBER = "/admin/changePinNumber";
  static _URL_USER_PRODUCT = "/product/userProducts";
  static _URL_MASTER_PRODUCT = "/product/masterProducts";
  static _URL_CREATE_OR_UPDATE_MASTER_PRODUCT = "/product/masterProductsDetail";
  static _URL_MANAGE_568WIN_BET_LIMIT = "/product/manage568WinBetLimit";

  static _URL_PRODUCT_GAME = "/product/productGame";
  static _URL_CREATE_OR_UPDATE_PRODUCT_GAME = "/product/productGameDetail";

  static _URL_MANAGE_PRODUCT_GAME_SETTING = "/product/manageProductGameSetting";
  static _URL_CREATE_OR_UPDATE_PRODUCT_GAME_SETTING = "/product/productGameSettingDetail";

  static _URL_PRODUCT_CATEGORY = "/product/category";
  static _URL_CREATE_OR_UPDATE_PRODUCT_CATEGORY = "/product/categoryDetail";
  static _URL_INSTANT_PAY_SETTING = "/paymentgateway/InstantPaySettings";
  static _URL_CREATE_OR_UPDATE_INSTANT_PAY_SETTING = "/configure/instantPaySettingsDetail";
  static _URL_WITHDRAWAL = "/transaction/withdrawal";
  static _URL_DEPOSIT = "/transaction/deposit";
  static _URL_ADJUSTMENT = "/adjustment/adjustment";
  static _URL_BONUS = "/transaction/bonus";
  static _URL_TRANSACTION_ENQUIRY = "/transaction/transactionEnquiry";
  static _URL_INSTANT_TRANSFER = "/transaction/instantTransaction";

  static _URL_TNG_SETTINGS = "/transaction/TnGSettings";
  static _URL_DEPOSIT_REFERENCE = "/transaction/DepositReference";

  static _URL_CREATE_OR_UPDATE_REJECT_REASON = "/configure/rejectReasonDetail";
  static _URL_MANAGE_REJECT_REASON = "/configure/manageRejectReason";
  static _URL_CREATE_OR_UPDATE_SMS_CONTENT = "/configure/smsContentDetail";
  static _URL_MANAGE_SMS_CONTENT = "/configure/manageSmsContent";
  static _URL_SMS_SETTING = "/configure/smsSettings";
  static _URL_VIEW_ACCOUNT = "/account/viewAccount";
  static _URL_VIEW_PRODUCT_ACCOUNT = "/account/view-product-account";
  static _URL_MANAGE_ACCOUNT = "/account/manageAccount";
  static _URL_MANAGE_IP_BLOCK = "/account/manage-ip-block";

  static _URL_ANNOUNCEMENT = "/content/announcement";
  static _URL_CREATE_OR_UPDATE_ANNOUNCEMENT = "/content/announcementDetail";
  static _URL_BANNER = "/content/banner";
  static _URL_CREATE_OR_UPDATE_BANNER = "/content/bannerDetail";
  static _URL_REWARD_BANNER = "/content/rewardBanner";
  static _URL_CREATE_OR_UPDATE_REWARD_BANNER = "/content/rewardBannerDetail";
  static _URL_PROMOTION = "/content/promotion";
  static _URL_CREATE_OR_UPDATE_PROMOTION = "/content/promotionDetail";
  static _URL_SEO = "/content/seo";
  static _URL_CREATE_OR_UPDATE_SEO = "/content/seoDetail";
  static _URL_SCRIPT = "/content/script";
  static _URL_CREATE_OR_UPDATE_SCRIPT = "/content/scriptDetail";
  static _URL_EMAIL = "/content/email";
  static _URL_CREATE_OR_UPDATE_EMAIL = "/content/emailDetail";
  static _URL_CONTENT = "/content/content";
  static _URL_CREATE_OR_UPDATE_CONTENT = "/content/contentDetail";
  static _URL_WEB_CONTENT = "/configure/WebContent";
  static _URL_MANAGE_WEB_CONTENT = "/configure/ManageWebContent";
  static _URL_CREATE_OR_UPDATE_WEB_CONTENT = "/configure/webContent";
  static _URL_MARQUEE_TAG_CONTENT = "/content/marqueeTagContent";
  static _URL_MARQUEE_TAG_CONTENT_LANGUAGE = "/content/marqueeTagContentLanguage";
  static _URL_MARQUEE_TAG_CONTENT_DETAIL = "/content/marqueeTagContentDetail";
  static _URL_VIP_LEVEL = "/configure/vipLevel";
  static _URL_CREATE_OR_UPDATE_VIP_LEVEL = "/configure/vipLevelDetail";
  static _URL_REMARK = "/configure/remark";
  static _URL_CREATE_OR_UPDATE_REMARK = "/configure/remarkDetail";
  static _URL_TAG = "/configure/tag";
  static _URL_CREATE_OR_UPDATE_TAG = "/configure/tagDetail";
  static _URL_LANGUAGE = "/configure/language";
  static _URL_CREATE_OR_UPDATE_LANGUAGE = "/configure/languageDetail";
  static _URL_BANKING_CHANNEL = "/configure/bankingChannel";
  static _URL_CREATE_OR_UPDATE_BANKING_CHANNEL = "/configure/bankingChannelDetail";
  static _URL_BONUS_TYPE = "/configure/bonusType";
  static _URL_CREATE_OR_UPDATE_BONUS_TYPE = "/configure/bonusTypeDetail";
  static _URL_PRODUCT_SETTING = "/configure/productSetting";
  static _URL_CREATE_OR_UPDATE_PRODUCT_SETTING = "/configure/productSettingDetail";
  static _URL_MANAGE_VIP_BENEFIT = "/configure/vipBenefit";
  static _URL_CREATE_OR_UPDATE_VIP_BENEFIT = "/configure/vipBenefitDetail";
  static _URL_MANAGE_VIP_BENEFIT_CONTENT = "/configure/vipBenefitContent";
  static _URL_CREATE_OR_UPDATE_VIP_BENEFIT_CONTENT = "/configure/vipBenefitContentDetail";
  static _URL_MANAGE_SYSTEM_SETTING = "/configure/manage-system-settings";
  static _URL_MANAGE_RECURRING_JOB = "/configure/manageRecurringJob";
  static _URL_MANAGE_MANUAL_JOB = "/configure/manageManualJob";

  static _URL_MANAGE_SKIN_SETTINGS = "/configure/ManageSkinSettings";
  static _URL_SKIN_SETTINGS_DETAIL = "/configure/SkinSettingsDetail";

  static _URL_MANAGE_VIDEO_TUTORIAL = "/configure/ManageVideoTutorial";
  static _URL_VIDEO_TUTORIAL_DETAIL = "/configure/VideoTutorialDetail";

  static _URL_MANAGE_DEPOSIT_PROMOTION = "/configure/ManageDepositPromotion";
  static _URL_CREATE_OR_UPDATE_DEPOSIT_PROMOTION = "/configure/DepositPromotionDetail";

  static _URL_BANK_PACKAGE = "/configure/bankPackage";
  static _URL_CREATE_OR_UPDATE_BANK_PACKAGE = "/configure/bankPackageDetail";
  static _URL_DEFAULT_BET_SETTING = "/configure/defaultBetSetting";
  static _URL_REBATE = "/configure/rebate";

  static _URL_CREATE_OR_UPDATE_MASTER_PRODUCT = "/product/masterProductDetail";
  static _URL_MANAGE_BONUS = "/configure/manageBonus";
  static _URL_CREATE_OR_UPDATE_BONUS = "/configure/bonusDetail";
  static _URL_COMPANY_PRODUCT_MAINTENANCE = "/admin/companyProductMaintenance";
  static _URL_COMPANY_PRODUCT_API_INFO = "/admin/companyProductApiInfo";
  static _URL_COMPANY_PRODUCT_ACCOUNT_INFO = "/admin/companyProductAccountInfo";

  static _URL_PROFIT_LOSS_REPORT = "/report/profitLossReport";
  static _URL_BET_DETAIL_REPORT = "/account/betDetailReport";
  static _URL_DAILY_SUMMARY_REPORT = "/report/dailySummaryReport";
  static _URL_CHANNEL_SUMMARY_REPORT = "/report/channelSummaryReport";
  static _URL_BANK_SUMMARY_REPORT = "/report/bankSummaryReport";
  static _URL_TRANSACTION_REPORT = "/report/transactionReport";
  static _URL_WALLET_TRANSFER_REPORT = "/report/walletTransferReport";
  static _URL_TRANSACTION_SUMMARY_REPORT = "/report/transactionSummaryReport";
  static _URL_BIRTHDAY_REWARD_REPORT = "/report/birthdayRewardReport";
  static _URL_GIFTPACE_REPORT = "/report/giftpaceReport";

  static _URL_PROCESS_GAME_RECORD_REPORT = "/report/ProcessGameRecordReport";
  static _URL_568_NON_PROCESS_GAME_RECORD_REPORT = "/report/NonProcessGameRecordReport";

  static _URL_SHAREHOLDERS_UNCLAIMED_REPORT = "/report/ShareholderUnclaimReport";
  static _URL_SHAREHOLDERS_UNCLAIMED_REPORT_LIST = "/report/ShareholderUnclaimReportList";
  static _URL_COMISSION_REPORT_LIST = "/report/ComissionReportList";

  static _URL_TOTAL_SHARE_PAYOUT_REPORT = "/report/TotalSharePayoutReport";

  static _URL_USERNAME_BET_DETAIL_REPORT = "/report/UsernameBetDetailReport";

  static _URL_MANAGE_MEMBER_FULL_NAME = "/report/manageMemberFullname";

  static _URL_REBATE_REPORT = "/report/rebateReport";
  static _URL_MEMBER_REPORT = "/account/memberReport";
  static _URL_WIN_LOSS_REPORT = "/report/winLossReport";
  static _URL_TALLY_WIN_LOSS_REPORT = "/report/tallywinLossReport";
  static _URL_WIN_LOSS_REPORT2 = "/report/winLossReport2";
  static _URL_WIN_LOSS_REPORT3 = "/report/winLossReport3";
  static _URL_BONUS_COMMISSION_REPORT = "/report/bonusCommReport";
  static _URL_MANAGE_WALLET_REPORT = "/account/manageWalletTransferReport";
  static _URL_SERVICE_CHARGE_REPORT = "/paymentgateway/serviceChargeReport";
  static _URL_ADJUSTMENT_REPORT = "/adjustment/adjustmentReport";

  static _URL_IP_MONITOR = "/tool/ipMonitor";
  static _URL_ACTIVITY_LOG = "/admin/activityLog";
  static _URL_TRANSACTION_DETAIL = "/transaction/transactionDetail";
  static _URL_MEMBER_PRORUCT_MAINTENANCE = "/member/productMaintenance";
  static _URL_MANAGE_CONTACT_US = "/content/contactUs";
  static _URL_CREATE_OR_UPDATE_CONTACT_US = "/content/contactUsDetail";

  static _URL_COMMISSION_ADJUSTMENT = "/adjustment/commissionAdjustment";
  static _URL_BONUS_ADJUSTMENT = "/adjustment/bonusAdjustment";

  static _URL_MANAGE_COMMUNICATION = "/configuration/manageCommunicationChannels";
  static _URL_CREATE_OR_UPDATE_COMMUNICATION = "/configuration/communicationChannelDetail";

  static _URL_SHAREHOLDERS_LIST = "/shareholder/list";
  static _URL_SHAREHOLDERS_VIEW_ACCOUNT = "/shareholder/viewAccount";
  static _URL_SHAREHOLDERS_PRODUCTS = "/shareholder/products";
  static _URL_SHAREHOLDERS_PRODUCTS_EDIT = "/shareholder/viewProduct";
  static _URL_SHAREHOLDERS_PAYOUT_EDIT = "/shareholder/viewPayout";
  static _URL_SHAREHOLDERS_PROCESS_HUAT_TICKET = "/shareholder/huatTickets"
  static _URL_SHAREHOLDERS_HUAT_TICKET_EDIT = "/shareholder/viewHuatTicket";
  static _URL_SHAREHOLDERS_PROCESS = "/shareholder/process";
  static _URL_SHAREHOLDERS_REPORT = "/shareholder/report";

  static _URL_SHAREHOLDERS_DOWNLINE_REPORT = "/shareholder/downlineReport";

  static _URL_MAINTENANCE = "/maintenance";

  static _URL_MANAGE_MEMBER_WALLET_STATEMENT = "/account/manageWalletHistory";
  static _URL_MANAGE_MEMBER_WALLET_STATEMENT_RECON = "/account/manageWalletRecon";

  static _URL_TODAY_NEW_MEMBERS = "/account/TodayNewMembers";
  static _URL_TRANSFER_LOG = "/account/TransferLog";

  static _URL_LIVE_MONITOR = "/liveMonitor";
  static _URL_SPORTS_LIVE_MONITOR = "/sportsLiveMonitor";

  static _URL_SPORTS_TEAM_BET_REPORT = "/sportsTeamBetReport";

  static _URL_TRANSACTION_QUEUE = "/account/queue";

  static _URL_WITHDRAWAL_SETTINGS = "/transaction/withdrawalSettings";

  static _URL_REWARDS_SETTING = "/rewardsSetting";
  static _URL_REWARDS_SCHEDULE = "/rewardsSchedule";
  static _URL_CLAIMED_REWARDS_REPORT = "/claimedRewardsReport";
  static _URL_FORFEITED_REWARDS_REPORT = "/forfeitedRewardsReport";

  static _URL_MANAGE_MEMBER_PO = "/manageMemberPO";
  static _URL_MANAGE_MEMBER_CS = "/manageMemberCS";

  static _URL_RETRIEVE_MEMBER_BET_DATA = "/retrieveMemberBetData";

  static _URL_MANAGE_MOBILE_TAC = "/manageMobileTAC";

  static _URL_BIRTHDAY_REWARDS = "/dailytask/manageBirthdayReward";
  static _URL_MEMBER_REBATE = "/dailytask/manageMemberRebate";

  static _URL_WEEKLY_RESCUE = "/configure/manageWeeklyRescue";
  static _URL_WEEKLY_RESCUE_DETAIL = "/configure/weeklyRescueDetail";
  static _URL_MEMBER_WEEKLY_RESCUE = "/dailytask/manageMemberWeeklyRescue";
  static _URL_MANAGE_DAILY_CHECK_IN = "/dc/manageDailyCheckIn";
  static _URL_DAILY_CHECK_IN_DETAIL = "/dc/dailyCheckInDetail";

  static _URL_BIRTHDAY_REWARDS = "/dailytask/manageBirthdayReward";
  static _URL_MEMBER_REBATE = "/dailytask/manageMemberRebate";

  static _URL_WEEKLY_RESCUE = "/configure/manageWeeklyRescue";
  static _URL_WEEKLY_RESCUE_DETAIL = "/configure/weeklyRescueDetail";
  static _URL_MEMBER_WEEKLY_RESCUE = "/dailytask/manageMemberWeeklyRescue";
  static _URL_MANAGE_DAILY_CHECK_IN = "/dc/manageDailyCheckIn";
  static _URL_DAILY_CHECK_IN_DETAIL = "/dc/dailyCheckInDetail";

  static _URL_MANAGE_DC_ITEM_LIST = "/dc/manageDCItemList";
  static _URL_CREATE_OR_UPDATE_DC_ITEM = "/dc/dcItemDetail";
  static _URL_MANAGE_DC_REDEMPTION = "/dc/manageDCRedemption";
  static _URL_MANAGE_DC_POINTS = "/dc/manageDCPoints";
  static _URL_UPDATE_DC_POINTS = "/dc/dcPointDetails";
  static _URL_MANAGE_MEMBER_DC_POINTS = "/dc/manageMemberDCPoints";
  static _URL_MEMBER_DC_POINTS_DETAIL = "/dc/memberDCPointsDetail";
  static _URL_ACTIVE_MEMBER_REPORT = "/report/activeMemberReport";
  static _URL_MEMBER_GAME_POINTS = "/dailytask/manageMemberGamePoints";
  static _URL_POINTS_ADJUSTMENT = "/adjustment/pointsAdjustment";
  static _URL_POINTS_ADJUSTMENT_REPORT = "/adjustment/pointsAdjustmentReport";
  static _URL_MEMBER_POINTS_REPORT = "/report/memberPointsReport";

  static _URL_MANAGE_DEPOSIT_PROMOTION_CATEGORY = "/configure/ManageDepositPromotionCategory";
  static _URL_CREATE_OR_UPDATE_DEPOSIT_PROMOTION_CATEGORY = "/configure/DepositPromotionCategoryDetail";
  static _URL_MEMBER = "/account/members";
  static _URL_CREDIT_REDEMPTION_REPORT = "/report/creditRedemptionReport";
  static _URL_BONUS_DEPOSIT = "/transaction/ManageBonusDeposit";
  static _URL_CREATE_OR_UPDATE_BONUS_DEPOSIT = "/transaction/BonusDepositDetail";
  static _URL_PLAYER_RECONCILIATION = "/playerReconciliation";

  //Chatsphere
  static _URL_MANAGE_CAHE_SPHERE = "/configure/manageChatSphere";
  static _URL_MANAGE_CAHE_SPHERE_CONTENT_DETAIL = "/configure/ChatSphereContentDetail";
  static _URL_MANAGE_CAHE_SPHERE_TYPE = "/configure/manageChatSphereType";
  static _URL_CHAT_SPHERE_TYPE_DETAIL = "/configure/chatSphereTypeDetail";
  static _URL_CHAT_SPHERE_CONTENT_LANGUAGE = "/configure/chatSphereContentLanguage";
  static _URL_CHAT_SPHERE_PHONE_NUMBER = "/configure/ManageChatSpherePhoneNumber";
  static _URL_CHAT_SPHERE_PHONE_NUMBER_DETAIL = "/configure/chatSpherePhoneNumberDetail";

  static _URL_ADJUSTMENT_BONUS_WALLET = "/adjustment/bonusWallet";

  static _URL_MANAGE_MEMBER_BANK_ACCOUNT = "/account/manageMemberBankAccount";
  static _URL_MEMBER_BANK_ACCOUNT_DETAIL = "/account/memberBankAccountDetail";

  static _URL_MANAGE_ACCOUNT_PRODUCT_RANK = "/product/manageAccountProductRank";
  static _URL_MANAGE_PRODUCT_GAME_RANK = "/product/manageProductGameRank";
  static _URL_GAME_LIST = "/product/GameList";

  static _URL_CLAIMED_PROMOTION_DETAIL = "/account/claimed-promotion-detail";

  static _URL_MANAGE_MLM_COMMISSION = "/account/ManageMLMCommission";

}

/// <summary>
/// Author : -
/// this is following the value from db, please do not change it unless it is really needed
/// </summary>
export class Role {
  static _SUPER_COMPANY = 1;
  static _COMPANY = 2;
  static _CUSTOMER_SERVICE = 3;
  static _RISK_TEAM = 6;
  static _TESTING_ROLE = 8;
  static _SUPERVISOR = 9;
  static _PAYMENT_OFFICIAL_CS = 10; //7 for staging
}

/// <summary>
/// Author : -
/// </summary>
export class Status {
  static _ENABLED = 1;
  static _DISABLED = 0;
}
/// <summary>
/// Author : -
/// </summary>
export class TransferBackMainWallet {
  static _ENABLED = 1;
  static _DISABLED = 0;
}

/// <summary>
/// Author : -
/// Do not change, require to follow datafieldlib
/// </summary>
export class MemberStatus {
  static _INACTIVE = 0;
  static _ACTIVE = 1;
  static _BLOCKED = -1;
}

/// <summary>
/// Author : -
/// this value needed to be tally to value in server, do not change recklessly
/// </summary>
export class AccessRight {
  static _DASHBOARD_DETAILS_PERMISSION = "1.";

  static _SUPER_COMPANY_CONTROL_PERMISSION = "99.";
  static _COMPANY_CONTROL_PERMISSION = "99.1";

  static _MEMBER_CONTROL_PERMISSION = "2.1";
  static _MEMBER_PRODUCT_USERNAME_PERMISSION = "2.2";
  static _MEMBER_REPORT_PERMISSION = "2.3";
  static _MANAGE_IP_BLOCK_PERMISSION = "2.4";
  static _MEMBER_WALLET_STATEMENT_PERMISSION = "2.5";
  static _NEW_MEMBER_LIST_PERMISSION = "2.6";
  static _TRANSFER_LOG_PERMISSION = "2.7";
  static _BET_DETAIL_REPORT_PERMISSION = "2.8";
  static _TRANSACTION_QUEUE_PERMISSION = "2.9";
  static _MANAGE_MEMBER_PO_PERMISSION = "2.10";
  static _MANAGE_MEMBER_CS_PERMISSION = "2.11";
  //static _MEMBER_WALLET_STATEMENT_RECON_PERMISSION = "2.12";
  static _MANAGE_MOBILE_TAC_PERMISSION = "2.12";
  static _MANAGE_BIRTHDAY_REWRDS = "2.13";
  static _MEMBER_WEEKLY_RESCUE_PERMISSION = "2.14";
  static _MANAGE_MEMBER_WEEKLY_RESCUE = "2.15";
  static _MANAGE_MEMBER_GAME_POINTS = "2.16";
  static _MANAGE_MEMBER_BANK_ACCOUNT = "2.17";
  static _GENERATE_COMMISSION_PERMISSION = "2.18";

  static _MANUAL_CANCEL_BET_PERMISSION = "2.8.1";
  static _RESET_ROLLOVER_PERMISSION = "2.1.1";

  static _LIVE_CHAT_PERMISSION = "0.0"
  static _LIVE_CHAT_SYSTEM_TEMPLATE_PERMISSION = "0.1"
  static _LIVE_CHAT_CUSTOM_TEMPLATE_PERMISSION = "0.2"

  static _SHAREHOLDER_PERMISSION = "3.1";
  static _SHAREHOLDER_PRODUCT_SETTING_PERMISSION = "3.2";
  //static _SHAREHOLDER_ADD_HUAT_PERMISSION = "3.3";
  static _SHAREHOLDER_PROCESS_SHARE_SALES_PERMISSION = "3.3";
  static _SHAREHOLDER_REPORT_PERMISSION = "3.4";
  static _SHAREHOLDER_DOWNLINE_REPORT_PERMISSION = "3.5";
  //static _SHAREHOLDER_UNCLAIMED_SALES_PERMISSION = "3.7";
  static _SHAREHOLDER_UNCLAIMED_SALES_LIST_PERMISSION = "3.6";
  static _SHAREHOLDER_SHARE_PAYOUT_REPORT_PERMISSION = "3.7";

  //static _TNG_SETTING_PERMISSION = "4.1";
  static _TRANSACTION_ENQUIRY_PERMISSION = "4.1";
  static _PAYMENT_GATEWAY_APPROVAL_PERMISSION = "4.1.1";
  static _TRANSACTION_APPROVAL_PERMISSION = "4.1.2";
  static _TRANSACTION_REJECTION_PERMISSION = "4.1.3";
  static _BANK_SUMMARY_REPORT_PERMISSION = "4.2";
  static _DEPOSIT_TRANSACTION_PERMISSION = "4.3";
  static _WITHDRAWAL_TRANSACTION_PERMISSION = "4.4";
  // static _WITHDRAWAL_SETTINGS_PERMISSION = "4.5";
  static _BANK_ACCOUNT_SETTING_PERMISSION = "4.5";
  //static _BANKING_CHANNEL_SETTING_PERMISSION = "4.8";
  //static _DEPOSIT_REFERENCE_PERMISSION = "4.9";
  static _BONUS_DEPOSIT_PERMISSION = "4.6";

  static _INSTANT_PAY_SETTING_PERMISSION = "5.1";
  static _SERVICE_CHARGE_REPORT_PERMISSION = "5.2";

  static _ADJUSTMENT_TRANSACTION_PERMISSION = "6.1";
  //static _COMMISSION_ADJUSTMENT_PERMISSION = "6.2";
  //static _BONUS_ADJUSTMENT_PERMISSION = "6.3";
  static _ADJUSTMENT_REPORT_PERMISSION = "6.2";
  static _ADJUSTMENT_POINTS_PERMISSION = "6.3";
  static _POINTS_ADJUSTMENT_REPORT_PERMISSION = "6.4";
  //static _BONUS_TRANSACTION_PERMISSION = "6.5";
  //static _INSTANT_TRANSACTION_PERMISSION = "6.6";

  static _WINLOSS_REPORT_PERMISSION = "7.1";
  static _TALLY_WINLOSS_REPORT_PERMISSION = "7.2";
  static _PROFIT_LOSS_REPORT_PERMISSION = "7.3";
  static _DAILY_SUMMARY_REPORT_PERMISSION = "7.4";
  static _BONUS_COMM_REPORT_PERMISSION = "7.5";
  static _TRANSACTION_REPORT_PERMISSION = "7.6";
  static _WALLET_TRANSFER_REPORT_PERMISSION = "7.7";
  //static _PROCESS_GAME_RECORD_REPORT_PERMISSION = "7.8";
  //static _NON_PROCESS_568_GAME_RECORD_REPORT_PERMISSION = "7.9";
  static _COMM_REPORT_PERMISSION = "7.8";
  static _MEMBER_FULLNAME_PERMISSION = "7.9";
  //static _CHANNEL_SUMMARY_REPORT_PERMISSION = "7.10";
  //static _REBATE_REPORT_PERMISSION = "7.13";
  static _TRANSACTION_SUMMARY_REPORT_PERMISSION = "7.10";
  static _BIRTHDAY_REWARD_REPORT_PERMISSION = "7.11";
  static _GIFTPACE_REPORT_PERMISSION = "7.12";
  static _ACTIVE_MEMBER_REPORT_PERMISSION = "7.13";
  static _MEMBER_POINTS_REPORT_PERMISSION = "7.14";
  static _CREDIT_REDEMPTION_REPORT_PERMISSION = "7.15";

  static _IP_MONITOR_PERMISSION = "8.1";

  static _SUB_ACCOUNT_CONTROL_PERMISSION = "9.1";
  static _ROLE_CONTROL_PERMISSION = "9.2";
  static _ACTIVITY_LOG_PERMISSION = "9.3";
  static _CHANGE_PASSWORD_PERMISSION = "9.4";

  static _PRODUCT_CATEGORY_SETTING_PERMISSION = "10.1";
  static _MASTER_PRODUCT_CONTROL_PERMISSION = "10.2";
  static _PRODUCT_CONTROL_PERMISSION = "10.3";
  //static _RETRIEVE_GAME_DATA_PERMISSION = "10.4";

  static _SMS_CONTENT_SETTING_PERMISSION = "11.1";
  static _SMS_SETTING_PERMISSION = "11.2";
  //static _BONUS_SETTING_PERMISSION = "11.3";
  static _BANK_SETTING_PERMISSION = "11.3";
  static _REJECT_REASON_SETTING_PERMISSION = "11.4";
  //   static _PRODUCT_SETTING_PERMISSION = "11.5";
  static _VIP_SETTING_PERMISSION = "11.5";
  //static _VIP_BENEFIT_TITLE_PERMISSION = "11.8";
  //static _VIP_BENEFIT_CONTENT_PERMISSION = "11.9";
  static _REMARK_SETTING_PERMISSION = "11.6";
  //static _TAG_SETTING_PERMISSION = "11.11";
  static _LANGUAGE_SETTING_PERMISSION = "11.7";
  //   static _BONUS_TYPE_SETTING_PERMISSION = "11.8";
  static _MANAGE_COMMUNICATION_PERMISSION = "11.8";
  static _SYSTEM_SETTING_ACCESS = "11.9";
  static _RECURRING_JOB_PERMISSION = "11.10";
  //static _MANUAL_JOB_PERMISSION = "11.17";
  //static _SKIN_SETTING_PERMISSION = "11.18";
  //static _VIDEO_TUTORIAL_PERMISSION = "11.19";
  //static _BANK_PACKAGE_PERMISSION = "11.20";
  //static _BET_SETTING_PERMISSION = "11.21";
  //static _REBATE_SETTING_PERMISSION = "11.22";
  static _DEPOSIT_PROMOTION_PERMISSION = "11.11";
  static _WEEKLY_RESCUE_PERMISSION = "11.12";
  static _DEPOSIT_PROMOTION_CATEGORY_PERMISSION = "11.13";
  static _CHAT_SPHERE_PERMISSION = "11.15";
  static _CHAT_SPHERE_TYPE_PERMISSION = "11.16";
  //   static _DAILY_CHECK_IN_PERMISSION = "11.15";
  static _CHAT_SPHERE_PHONE_NUMBER_PERMISSION = "11.17";

  //static _ANNOUNCEMENT_SETTING_PERMISSION = "12.1";
  static _BANNER_SETTING_PERMISSION = "12.1";
  //static _PROMOTION_SETTING_PERMISSION = "12.3";
  //static _SEO_SETTING_PERMISSION = "12.4";
  //static _SCRIPT_SETTING_PERMISSION = "12.5";
  static _EMAIL_SETTING_PERMISSION = "12.2";
  static _CONTENT_SETTING_PERMISSION = "12.3";
  //   static _CONTACT_US_PERMISSION = "12.4";
  static _MARQUEE_TAG_CONTENT_PERMISSION = "12.4";
  //static _REWARD_BANNER_SETTING_PERMISSION = "12.9";
  //static _WEB_CONTENT_SETTING_PERMISSION = "12.10";
  static _MANAGE_WEB_CONTENT_SETTING_PERMISSION = "12.11";
  static _MARQUEE_TAG_CONTENT = "12.12";
  static _LIVE_MONITORING_PERMISSION = "13.";


  //static _REWARDS_PERMISSION = "14.";
  //static _NEW_PAGE_PERMISSION = "14.5";
  //static _REWARD_SCHEDULES = "Reward Schedules";
  //static _REWARD_SETTING = "Reward Setting";

  static _DC_ITEM_LIST_PERMISSION = "14.1";
  static _DC_REDEMPTION_PERMISSION = "14.2";
  static _DC_POINTS_PERMISSION = "14.3";
  static _MEMBER_DC_POINTS_PERMISSION = "14.4";
  static _DAILY_CHECK_IN_PERMISSION = "14.3";

  static _READ_PERMISSION_STRING = "(READ)";
  static _WRITE_PERMISSION_STRING = "(WRITE)";
  static _READ_BIT_POSITION = 1;
  static _WRITE_BIT_POSITION = 0;
}

export class Transaction {
  static _BONUS_TYPE_SELF = "Own";
  static _BONUS_TYPE_OVERRIDE = "Override";
}

export class ReadWritePermission {
  static _NO_PERMISSION = 0;
  static _READ_PERMISSION = 1;
  static _WRITE_PERMISSION = 2;
  static _ALL_PERMISSION = 3;
}

/// <summary>
/// Author : -
/// this value have to match with backend
/// </summary>
export class DisplayMode {
  static _ALL = 3;
  static _PUBLIC = 2;
  static _MEMBER = 1;
}

/// <summary>
/// Author : -
/// this value have to match with backend
/// </summary>
export class DisplayType {
  static _SLIDER = 2;
  static _POPUP = 1;
}

/// <summary>
/// Author : -
/// this value have to match with backend
/// </summary>
export class DisplayDevice {
  static _DESKTOP_MAIN = 0;
  static _DESKTOP_SUB = 1;
  static _MOBILE_POPOUT = 2;
  static _MOBILE_SLIDER = 3;
  static _DESKTOP_POPUP = 4;
}

/// <summary>
/// Author : -
/// this value have to match with backend
/// </summary>
export class Position {
  static _HEAD = 2;
  static _BODY = 1;
}

/// <summary>
/// Author : -
/// this value have to match with backend
/// </summary>
export class PageTypeOption {
  static _COMPLETE_REGISTER = 3;
  static _FORGET_PASSWORD = 2;
  static _VERIFY_EMAIL = 1;
  static _FORGOT_PIN = 4;
}

/// <summary>
/// Author : -
/// this value have to match with backend
/// </summary>
export class PaymentType {
  static _PREPAID = 2;
  static _POSTPAID = 1;
}

/// <summary>
/// Author : -
/// this value have to match with backend
/// </summary>
export class Gender {
  static _MALE = 1;
  static _FEMALE = 0;
}

/// <summary>
/// Author : -
/// this value have to match with backend
/// </summary>
export class BonusSettingType {
  static _MAIN = 2;
  static _SECONDARY = 1;
}

/// <summary>
/// Author : -
/// this value have to match with backend
/// </summary>
export class BonusMethodType {
  static _PERCENT = 2;
  static _AMOUNT = 1;
}

/// <summary>
/// Author : -
/// </summary>
export class PanelType {
  static _MEMBERSITE = "MemberSite";
  static _MOBILESITE = "MobileSite";
  static _BACKOFFICE = "BackOffice";
  static _APPS = "Apps";
  static _SYSTEM = "Systems";
}

/// <summary>
/// Author : -
/// </summary>
export class TransactionType {
  static _DEPOSIT = 1;
  static _WITHDRAWAL = 2;
  static _BONUS = 3;
  static _ADJUSTMENT = 4;
  static _REBATE = 5;
  static _UPLINE_TRANSFER = 6;
  static _REFERRAL_BONUS = 7;
  static _REFUND = 8;
  //static _SHAREHOLDER = 9;
  static _COMMISSION = 9;
  static _BIRTHDAY_REWARD = 10;
  static _VIP_COMMISSION = 11;
  static _WEEKLY_RESCUE_BONUS = 13;
  static _DAILY_CHECK_IN = 999;
  static _CREDIT_REDEMPTION = 14;
}

/// <summary>
/// Author : -
/// </summary>
export class SmsType {
  static _PHONE_VERIFICATION_TYPE = 1;
  static _RESET_PASSWORD_VEERIFICATION_TYPE = 2;
  static _DEPOSIT_TYPE = 3;
  static _WITHDRAWAL_TYPE = 4;
  static _PIN_RESET = 5;
  static _TAC_CODE = 6;
}

/// <summary>
/// Author : -
/// </summary>
export class TransactionStatus {
  static _OPEN = 0;
  static _IN_REVIEW = 1;
  static _IN_PROGRESS = 2;
  static _APPROVED = 3;
  static _REJECTED = 4;
  static _AUTO_APPROVED = 5;
}

/// <summary>
/// Author : -
/// </summary>
export class BonusTransactionMethod {
  static _WITH_DEPOSIT = 1;
  static _WITHOUT_DEPOSIT = 0;
}

/// <summary>
/// Author : -
/// </summary>
export class BankTransactionType {
  static _CREDIT_BANK_TRANSACTION = 1;
  static _DEBIT_BANK_TRANSACTION = 2;
}

/// <summary>
/// Author : -
/// </summary>
export class BetStatus {
  static _PENDING_BET_STATUS = 0;
  static _SETTLED_BET_STATUS = 1;
  static _CANCELED_BET_STATUS = 2;
}

/// <summary>
/// Author : -
/// </summary>
export class Language {
  static _ENGLISH = "en";
  static _KHMER = "kh"
}

/// <summary>
/// Author : -
/// </summary>
export class LanguageKey {
  static _PRIMARY = "common";
}

/// <summary>
/// Author : -
/// </summary>
export class BankingChannel {
  static _CHANNEL_OFFLINE_BANKING = 1;
  static _CHANNEL_PAYTRUST = 2;
  static _CHANNEL_GPAY = 3;
}

export class BankingChannelName {
  static _CHANNEL_PAYTRUST = "PayTrust";
}

/// <summary>
/// Author : -
/// </summary>
export class SessionKey {
  static _Language = "language";
}

/// <summary>
/// Author: -
/// </summary>
export class AlertTypes {
  static _DEFAULT = 'default';
  static _INFO = 'info';
  static _SUCCESS = 'success';
  static _WARNING = 'warning';
  static _DANGER = 'danger';
  static _ERROR = 'error';
  static _INPUT = 'input';
  static _CUSTOM = 'custom';
}

/// <summary>
/// Author : -
/// </summary>
export class ContentTypeString {
  static _ANNOUNCEMENT = "Announcement";
  static _BANNER = "Banner";
  static _REWARD_BANNER = "RewardBanner";
  static _PROMOTION = "Promotion";
  static _SEO = "SEO";
  static _SCRIPT = "Script";
  static _EMAIL = "Email";
  static _CONTENT = "ContentTemplate";
  static _BONUS = "Bonus";
  static _VIP_BENEFIT = "VipBenefit";
  static _VIP_BENEFIT_CONTENT = "VipBenefitContent";
  static _VIP_CRITERIA = "VipCriteria";
  static _PRODUCT_CATEGORY = "ProductCategory";
  static _VIDEO_TUTORIAL = "VideoTutorial";
  static _MARQUEE_CONTENT = "MarqueeContent";
  static _DEPOSIT_PROMOTION = "DepositPromotion";
  static _DCITEM_CONTENT = "MarqueeContent";
  static _DEPOSIT_PROMOTION_CATEGORY = "DepositPromotionCategory";
  static _CHAT_SPHERE = "ChatSphere";
}

/// <summary>
/// Author : -
/// </summary>
export class AllVipOption {
  static _VALUE = 0;
  static _LABEL = "ALL";
}

/// <summary>
/// Author : -
/// </summary>
export class TransactionPriority {
  static _NO = 0;
  static _YES = 1;
  static _FIRST_PRIORITY = 2;
}

/// <summary>
/// Author: -
/// </summary>
export class LoginType {
  static _LOGIN_TYPE_USER = 1;
  static _LOGIN_TYPE_MEMBER = 2;
}

export const _TICKET_STATUS = [
  { value: "", label: "ALL" },
  { value: "0", label: "PENDING" },
  { value: "1", label: "SETTLED" },
  { value: "2", label: "CANCELLED" }
];

export const _SYNC_INTERVAL_PATTERN = [
  { value: 0, label: "Inactive" },
  { value: 1, label: "Full" },
  { value: 2, label: "Pending & Cancelled" },
  { value: 3, label: "Settled" }
];

export const _TRANSACTION_STATUS = [
  { value: "0", label: "ALL" },
  { value: "1", label: "SUCCESSFUL" },
  { value: "2", label: "FAILED" }
];

export const _WALLET_GAME_TRANSACTION_TYPE = [
  { value: "0", label: "ALL" },
  { value: "1", label: "TO GAME" },
  { value: "2", label: "TO WALLET" }
];

/// <summary>
/// Author : -
/// </summary>
export class ChatSphereAction {
  static _REGISTERATION = "Registration";
  static _RESET_PASSWORD = "Reset Password";
}

export const DEFAULT_PAGE_SIZE = 100;

export class AdjustmentTransactionTarget {
  static _PRIMARY_CREDIT = 1;
  static _BONUS_WALLET = 2;
}



