import React from "react";
import { Panel, PanelBody } from "./../../components/panel/panel.jsx";
import { useForm } from "react-hook-form";
import { ApiKey, ApiUrl, LanguageKey } from "../../util/Constant";
import { createFormBody } from "../../util/Util";
import { setBusy, setIdle, showMessage } from "../../redux/AppAction";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { fetch } from "whatwg-fetch";

/// <summary>
/// Author : -
/// </summary>

const ChangePassword = (props) => {
  const { t } = useTranslation(LanguageKey._PRIMARY);
  const _dispatch = useDispatch();
  const { register, handleSubmit, errors } = useForm();

  /// <summary>
  /// Author : -
  /// </summary>
  const submitForm = async (data, e) => {
    if (data.password != data.confirmPassword) {
      _dispatch(
        showMessage(false, t("CONFIRM_PASSWORD_AND_PASSWORD_IS_NOT_TALLY"))
      );
      return;
    }

    let params = {
      password: data.password,
    };

    let formBody = createFormBody(params);
    _dispatch(setBusy());

    fetch(ApiUrl._API_UPDATE_PASSWORD, {
      method: ApiKey._API_POST,
      headers: {
        "Content-Type": ApiKey._API_FORM_URLENCODED,
        "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
      },
      body: formBody,
    })
      .then((response) => response.json())
      .then((responseJson) => {
        _dispatch(setIdle());
        _dispatch(
          showMessage(
            responseJson[ApiKey._API_SUCCESS_KEY],
            t(responseJson[ApiKey._API_MESSAGE_KEY])
          )
        );
      });
  };

  return (
    <div>
      <h1 className="page-header">{t("CHANGE_PASSWORD")}</h1>
      <div className="row">
        <div className="col-xl-12">
          <Panel>
            <PanelBody>
              <form onSubmit={handleSubmit(submitForm)}>
                <div className="row">
                  <div className="col-lg-8">
                    <div className="form-group row m-b-15">
                      <label className="col-md-4 col-form-label">
                        <b>{t("NEW_PASSWORD")}</b>
                      </label>
                      <div className="col-md-7">
                        <input
                          type="password"
                          className="form-control"
                          ref={register({ required: "PLEASE_ENTER_PASSWORD" })}
                          placeholder={t("PLEASE_ENTER_PASSWORD")}
                          name="password"
                        />
                        {errors.password && (
                          <div className="invalid-feedback">
                            {t(errors.password.message)}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-8">
                    <div className="form-group row m-b-15">
                      <label className="col-md-4 col-form-label">
                        <b>{t("CONFIRM_PASSWORD")}</b>
                      </label>
                      <div className="col-md-7">
                        <input
                          type="password"
                          className="form-control"
                          ref={register({
                            required: "PLEASE_ENTER_CONFIRM_PASSWORD",
                          })}
                          name="confirmPassword"
                          placeholder={t("PLEASE_ENTER_CONFIRM_PASSWORD")}
                        />
                        {errors.confirmPassword && (
                          <div className="invalid-feedback">
                            {t(errors.confirmPassword.message)}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <hr />
                <div className="row">
                  <div className="col-lg-3">
                    <div className="form-group">
                      <button type="submit" className="btn btn-primary">
                        {t("SUBMIT")}
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </PanelBody>
          </Panel>
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
