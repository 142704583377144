import React, { useState } from "react";
import Select from "react-select";
import "react-datepicker/dist/react-datepicker.css";
import ReactTable from "../../components/constantComponent/reactTable/ReactTable";
import DateRangePicker from "react-bootstrap-daterangepicker";
import moment from "moment";
import { useTranslation } from "react-i18next";
import {
	ApiKey,
	ApiUrl,
	LanguageKey,
	_TRANSACTION_STATUS,
	_WALLET_GAME_TRANSACTION_TYPE,
	Transaction,
} from "../../util/Constant";
import {
	stringIsNullOrEmpty,
	numberWithCurrencyFormat,
} from "../../util/Util";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { setBusy, setIdle, showMessage } from "../../redux/AppAction";
import { useDispatch } from "react-redux";
import ApiEngine from "../../util/ApiEngine.js";

/// <summary>
/// Author : -
/// </summary>

const BonusTracking = (props) => {
	const { register, handleSubmit, errors, setValue, trigger } = useForm();
	const _dispatch = useDispatch();
	const { t } = useTranslation(LanguageKey._PRIMARY);
	const [startDate, setStartDate] = useState(
		moment().startOf("day").format("YYYY-MM-DD HH:mm:ss")
	);
	const [endDate, setEndDate] = useState(
		moment().endOf("day").format("YYYY-MM-DD HH:mm:ss")
	);
	const [memberId, setMemberId] = useState("");
	const [reportData, setReportData] = useState([]);

	let _tableColumns = [
		{
			Header: "DATE",
			accessor: "transactionDateTime",
			Cell: ({ row }) => {
				return moment(row.original.transactionDateTime).format("YYYY-MM-DD HH:mm:ss");
			},
			disableSortBy: true,
		},
		{
			Header: "DESCRIPTION",
			accessor: "action",
			disableSortBy: true,
		},
		{
			Header: "AMOUNT",
			accessor: "bonusWalletAmount",
			Cell: ({ row }) => {
				return numberWithCurrencyFormat(parseFloat(row.original.bonusWalletAmount));
			},
			disableSortBy: true,
		},
		{
			Header: "BONUS_WALLET_BEFORE",
			accessor: "preBonusWalletBalance",
			Cell: ({ row }) => {
				return numberWithCurrencyFormat(parseFloat(row.original.preBonusWalletBalance));
			},
			disableSortBy: true,
		},
		{
			Header: "BONUS_WALLET_AFTER",
			accessor: "postBonusWalletBalance",
			Cell: ({ row }) => {
				return numberWithCurrencyFormat(parseFloat(row.original.postBonusWalletBalance));
			},
			disableSortBy: true,
		},
	];

	useEffect(() => {
		if (props.id) {
			setMemberId(props.id);
			getProductTransactionRecord();
		}
	}, [props.id]);

	const handleEvent = (event, picker) => {
		setStartDate(moment(picker.startDate).format("YYYY-MM-DD HH:mm:ss"));
		setEndDate(moment(picker.endDate).format("YYYY-MM-DD HH:mm:ss"));
	};

	const getProductTransactionRecord = async () => {
		try {
			var apiUrl =
				ApiUrl._API_BONUS_TRACKING +
				"?dateFrom=" +
				startDate +
				"&dateTo=" +
				endDate;

			apiUrl += "&memberId=" + props.id;

			var responseJson = await ApiEngine.get(apiUrl);

			if (responseJson[ApiKey._API_SUCCESS_KEY]) {
				setReportData(responseJson[ApiKey._API_DATA_KEY]);
			} else {
				throw responseJson[ApiKey._API_MESSAGE_KEY];
			}
		} catch (error) {
			_dispatch(showMessage(false, t(error)));
		}
	};

	return (
		<div>
			<form>
				<div className="row">
					<div className="col-lg-12">
						<div className="row">
							<div className="col-lg-3">
								<div className="form-group">
									<label>
										<b>{t("DATE")}</b>
									</label>
									<div
										className="input-group"
										style={{ width: "100%", flexFlow: "nowrap" }}
									>
										<div className="input-group-prepend">
											<span className="input-group-text">
												<i className="fa fa-calendar"></i>
											</span>
										</div>
										<div style={{ width: "80%" }}>
											<DateRangePicker
												containerStyles={{ width: "100%" }}
												startDate={startDate}
												endDate={endDate}
												onApply={handleEvent}
												alwaysShowCalendars={true}
												locale={{ format: "YYYY-MM-DD HH:mm:ss" }}
												timePicker={true}
												timePickerSeconds={true}
												ranges={{
													Today: [
														moment().startOf("day"),
														moment().endOf("day"),
													],
													Yesterday: [
														moment().subtract(1, "days").startOf("day"),
														moment().subtract(1, "days").endOf("day"),
													],
													"Last 7 Days": [
														moment().subtract(6, "days").startOf("day"),
														moment().endOf("day"),
													],
													"Last 30 Days": [
														moment().subtract(29, "days").startOf("day"),
														moment().endOf("day"),
													],
													"This Month": [
														moment().startOf("month"),
														moment().endOf("month"),
													],
													"Last Month": [
														moment().subtract(1, "month").startOf("month"),
														moment().subtract(1, "month").endOf("month"),
													],
													"This Year": [
														moment().startOf("year"),
														moment().endOf("year"),
													],
												}}
											>
												<input
													type="text"
													className="form-control"
													value={startDate + " - " + endDate}
												/>
											</DateRangePicker>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col-lg-2">
								<button
									type="button"
									style={{ marginLeft: "5px" }}
									className="btn btn-primary"
									onClick={() => getProductTransactionRecord()}
								>
									{t("SEARCH")}
								</button>
							</div>
						</div>
					</div>
				</div>
				<hr />
				<div className="row">
					<div className="col-lg-12">
						<ReactTable
							data={reportData}
							columns={_tableColumns}
							renderFooter={true}
						/>
					</div>
				</div>
			</form>
		</div>
	);
};

export default BonusTracking;
