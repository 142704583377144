import React, { useState, useEffect } from "react";
import ReactTable from "../../components/constantComponent/reactTable/ReactTable";
import { Panel, PanelBody } from "./../../components/panel/panel.jsx";
import "../../assets/css/custom.css";
import { useHistory } from "react-router-dom";
import {
    UncontrolledButtonDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from "reactstrap";
import { navigateTo, stringIsNullOrEmpty } from "../../util/Util";
import {
    ApiUrl,
    ApiKey,
    WebUrl,
    AccessRight,
    LanguageKey,
    AllVipOption,
} from "../../util/Constant";
import NavigationButton from "../../components/constantComponent/button/NavigationButton";
import { checkIfPermissionExist } from "../../util/PermissionChecker";
import { useTranslation } from "react-i18next";
import { fetch } from "whatwg-fetch";

/// <summary>
/// Author : -
/// </summary>

const ManageWeeklyRescue = (props) => {
    const { t } = useTranslation(LanguageKey._PRIMARY);
    let _history = useHistory();
    const [promotionData, setPromotionData] = useState([]);

    let _tableColumns = [
        {
            Header: "RANK",
            accessor: "name",
            disableSortBy: true,
        },
        {
            Header: "MIN_LOSSES",
            accessor: "minlosses",
            Cell: ({ row }) => {
                
                    return <>{row.original.minlosses}</>;
                
            },
        },
        {
            Header: "RESCUE_PERCENTAGE",
            accessor: "rescuePercentage",
            Cell: ({ row }) => {
                
                return <>{row.original.rescuePercentage}{"%"}</>;
               
            },
        },
        {
            Header: "MAX_BONUS",
            accessor: "maxBonus",
            Cell: ({ row }) => {

                return <>{row.original.maxBonus}</>;

            },
        },
        {
            Header: "TURNOVER",
            accessor: "turnover",
            Cell: ({ row }) => {

                return <>{row.original.turnover}{"x"}</>;

            },
        },
        {
            id: "action",
            Header: "",
            Cell: ({ row }) =>
                checkIfPermissionExist(
                    AccessRight._WEEKLY_RESCUE_PERMISSION,
                    true
                ) && (
                    <div className="btn-group m-r-5 m-b-5">
                        <UncontrolledButtonDropdown>
                            <DropdownToggle caret color="default">
                                <i className="fas fa-cog"></i>
                            </DropdownToggle>
                            <DropdownMenu>
                                <DropdownItem
                                    onClick={() => {
                                        navigateTo(
                                            _history,
                                            WebUrl._URL_WEEKLY_RESCUE_DETAIL,
                                            row.original
                                        );
                                    }}
                                >
                                    <span className="text-warning">{t("EDIT")}</span>
                                </DropdownItem>
                            </DropdownMenu>
                        </UncontrolledButtonDropdown>
                    </div>
                ),
            disableSortBy: true,
            disableFilters: true,
            width: 100,
            style: { overflow: "visible" },
        },
    ];

    /// <summary>
    /// Author : -
    /// </summary>
    useEffect(() => {
        fetch(ApiUrl._API_GET_WEEKLY_RESCUE + "?all=true", {
            method: ApiKey._API_GET,
            headers: {
                "Content-Type": ApiKey._API_FORM_URLENCODED,
                "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
            },
        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                    setPromotionData(responseJson[ApiKey._API_DATA_KEY]);
                }
            });
    }, []);

    return (
        <div>
            <h1 className="page-header">
                {t("WEEKLY_RESCUE")}
            </h1>
            <Panel>
                <PanelBody>
                    <ReactTable data={promotionData} columns={_tableColumns} />
                </PanelBody>
            </Panel>
        </div>
    );
};

export default ManageWeeklyRescue;
