import { WebUrl } from "../../util/Constant";

/// <summary>
/// Author : -
/// </summary>
const MemberMenu = [
    {
        path: '/report', icon: 'far fa-file-alt', title: 'REPORT', isDummy: true,
        children: [
            { path: WebUrl._URL_WIN_LOSS_REPORT, title: 'WINLOSS_REPORT' },
        ]
    },
]

export default MemberMenu;
