import React, { useState, useEffect } from "react";
import { Panel, PanelBody } from "./../../components/panel/panel.jsx";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import NavigationButton from "../../components/constantComponent/button/NavigationButton";
import {
	ApiKey,
	ApiUrl,
	DisplayMode,
	LanguageKey,
	ContentTypeString,
	AllVipOption,
} from "../../util/Constant";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import { stringIsNullOrEmpty, createFormBody } from "../../util/Util";
import { setBusy, setIdle, showMessage } from "../../redux/AppAction";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import ManageMarqueeContentLanguage from "./ManageMarqueeContentLanguage";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import classnames from "classnames";
import { fetch } from "whatwg-fetch";
import ApiEngine from "../../util/ApiEngine.js";

/// <summary>
/// Author : -
/// </summary>

const MarqueeTagContentDetail = (props) => {
	const _DETAIL_TAB = "1";
	const _LANGUAGE_TAB = "2";
	const { t } = useTranslation(LanguageKey._PRIMARY);
	let _history = useHistory();
	const _location = useLocation();
	const _dispatch = useDispatch();
	// const { register, unregister, handleSubmit, errors, setValue } = useForm();
	const [marqueeTagContentId, setMarqueeTagContentId] = useState("");
	const [announcementRefId, setAnnouncementRefId] = useState("");
	const [platformMode, setPlatformMode] = useState("");
	const [content, setContent] = useState("");

	const {
		register: marRegister,
		handleSubmit: marHandleSubmit,
		errors: marErrors,
		unregister: forgotPasswordUnregister,
	} = useForm();

	/// <summary>
	/// Author : -
	/// </summary>
	useEffect(() => {
		marRegister({ name: "content" }, { required: "PLEASE_ENTER_CONTENT" });
	}, []);

	const [activeTab, setActiveTab] = useState(_DETAIL_TAB);

	/// <summary>
	/// Author : -
	/// </summary>
	const toggle = (tab) => {
		if (activeTab !== tab) {
			setActiveTab(tab);
		}
	};

	/// <summary>
	/// Author : -
	/// </summary>
	useEffect(() => {
		getMarqueeTagContent();
	}, []);

	async function getMarqueeTagContent() {
		if (_location.state) {
			console.log(_location);
			let stateAnnouncementId = _location.state.id || _location.state.refId;

			console.log(stateAnnouncementId);
			let responseJson = await ApiEngine.get(
				ApiUrl._API_GET_MARQUEE_TAG_CONTENT_WITH_ID +
					"?MarqueeTagId=" +
					stateAnnouncementId
			);
			if (responseJson[ApiKey._API_SUCCESS_KEY]) {
				if (_location.state.id) {
					responseJson[ApiKey._API_DATA_KEY].map((option) => {
						setMarqueeTagContentId(option["id"]);
						setAnnouncementRefId("");
					});
					setAnnouncementRefId("");
				} else if (_location.state.refId) {
					responseJson[ApiKey._API_DATA_KEY].map((option) => {
						setMarqueeTagContentId("");
						setAnnouncementRefId(option["id"]);
					});
				}
				console.log(responseJson[ApiKey._API_DATA_KEY]);
				responseJson[ApiKey._API_DATA_KEY].map((option) => {
					setPlatformMode(option["platform"]);
					setContent(option["content"]);
				});

				//responseJson[ApiKey._API_DATA_KEY];
			}
		}
	}

	/// <summary>
	/// Author : -
	/// </summary>
	async function submitForm(data) {
		let params = {
			platform: data.platformMode,
			content: content,
		};

		if (!stringIsNullOrEmpty(marqueeTagContentId)) {
			params["id"] = marqueeTagContentId;
		}

		if (!stringIsNullOrEmpty(announcementRefId)) {
			params["refId"] = announcementRefId;
		}

		let formBody = createFormBody(params);
		_dispatch(setBusy());

		fetch(ApiUrl._API_CREATE_OR_UPDATE_MARQUEE_TAG_CONTENT, {
			method: ApiKey._API_POST,
			headers: {
				"Content-Type": ApiKey._API_FORM_URLENCODED,
				"X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
			},
			body: formBody,
		})
			.then((response) => response.json())
			.then((responseJson) => {
				_dispatch(setIdle());
				_dispatch(
					showMessage(
						responseJson[ApiKey._API_SUCCESS_KEY],
						t(responseJson[ApiKey._API_MESSAGE_KEY])
					)
				);

				if (responseJson[ApiKey._API_SUCCESS_KEY]) {
					setMarqueeTagContentId(responseJson[ApiKey._API_DATA_KEY]["id"]);
					setAnnouncementRefId("");

					_history.replace({
						pathname: _location.pathname,
						state: {
							id: responseJson[ApiKey._API_DATA_KEY]["id"],
						},
					});
				}
			});
		getMarqueeTagContent();
	}

	return (
		<div>
			<h1 className="page-header">
				{marqueeTagContentId
					? t("EDIT_MARQUEE_TAG_CONTENT")
					: t("ADD_MARQUEE_TAG_CONTENT")}
				<NavigationButton history={_history} />
			</h1>
			{marqueeTagContentId && (
				<Nav tabs>
					<NavItem>
						<NavLink
							className={classnames({ active: activeTab === _DETAIL_TAB })}
							onClick={() => {
								toggle(_DETAIL_TAB);
							}}
						>
							{t("DETAIL")}
						</NavLink>
					</NavItem>
					<NavItem>
						<NavLink
							className={classnames({ active: activeTab === _LANGUAGE_TAB })}
							onClick={() => {
								toggle(_LANGUAGE_TAB);
							}}
						>
							{t("LANGUAGE")}
						</NavLink>
					</NavItem>
				</Nav>
			)}
			<TabContent activeTab={activeTab}>
				<TabPane tabId={_DETAIL_TAB}>
					<div className="row">
						<div className="col-xl-12">
							<Panel>
								<PanelBody>
									<form onSubmit={marHandleSubmit(submitForm)}>
										<div className="row">
											<div className="col-lg-8">
												<div className="form-group row m-b-15">
													<label className="col-md-4 col-form-label">
														<b>{t("PLATFORM")}</b>
													</label>
													<div className="col-md-7">
														{stringIsNullOrEmpty(marqueeTagContentId) ? (
															<input
																type="text"
																className="form-control"
																placeholder={t("PLEASE_ENTER_PLATFORMMODE")}
																name="platformMode"
																ref={marRegister({
																	required: "PLEASE_ENTER_PLATFORMMODE",
																})}
																defaultValue={platformMode}
															/>
														) : (
															<input
																type="text"
																className="form-control"
																placeholder={t("PLEASE_ENTER_PLATFORMMODE")}
																name="platformMode"
																ref={marRegister({
																	required: "PLEASE_ENTER_PLATFORMMODE",
																})}
																defaultValue={platformMode}
																readOnly
															/>
														)}
													</div>
												</div>
											</div>

											<div className="col-lg-8">
												<div className="form-group row m-b-15">
													<label className="col-md-4 col-form-label">
														<b>{t("CONTENT")}</b>
													</label>
													<div className="col-md-7">
														<textarea
															className="form-control"
															rows="5"
															style={{ marginTop: "10px" }}
															ref={marRegister}
															name="content"
															defaultValue={content}
															onChange={(e) => {
																setContent(e.target.value);
																// setValue("content", e.target.value);
															}}
														></textarea>
														{marErrors.content && (
															<div className="invalid-feedback">
																{t(marErrors.content.message)}
															</div>
														)}
													</div>
												</div>
											</div>
										</div>
										<hr />
										<div className="row">
											<div className="col-lg-3">
												<div className="form-group">
													<button type="submit" className="btn btn-purple">
														{t("SUBMIT")}
													</button>
												</div>
											</div>
										</div>
									</form>
								</PanelBody>
							</Panel>
						</div>
					</div>
				</TabPane>
				<TabPane tabId={_LANGUAGE_TAB}>
					{marqueeTagContentId && (
						<div className="row">
							<div className="col-lg-12">
								<ManageMarqueeContentLanguage
									id={marqueeTagContentId}
									type={ContentTypeString._MARQUEE_CONTENT}
								/>
							</div>
						</div>
					)}
				</TabPane>
			</TabContent>
		</div>
	);
};

export default MarqueeTagContentDetail;
