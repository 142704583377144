import { _SAVE_LANGUAGE } from './LanguageAction';

const _INITIAL_STATE = {
    languages : []
};

/// <summary>
/// Author : -
/// </summary>
export default (state = _INITIAL_STATE, action) => {
    switch (action.type) {
        case _SAVE_LANGUAGE:
            return {
                languages: action.languages
            }
            break;
        default:
            return state;
            break;
    }
};