import { createStore, applyMiddleware, combineReducers } from "redux";
import authReducer from "./redux/AuthReducer";
import appReducer from "./redux/AppReducer";
import languageReducer from "./redux/LanguageReducer";
import FirebaseReducer from "./redux/FirebaseReducer";
import thunk from 'redux-thunk';

/// <summary>
/// Author : -
/// </summary>
const store = createStore(combineReducers({
    authState: authReducer,
    appState: appReducer,
    languageState: languageReducer,
    firebaseState: FirebaseReducer
}), applyMiddleware(thunk));

export default store;