import React, { useState, useEffect } from "react";
import { Panel, PanelBody } from "./../../components/panel/panel.jsx";
import { useHistory } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import {
  ApiKey,
  ApiUrl,
  TransactionType,
  PanelType,
  BonusTransactionMethod,
  LanguageKey,
  BonusMethodType,
} from "../../util/Constant";
import { stringIsNullOrEmpty, createFormBody } from "../../util/Util";
import { useForm } from "react-hook-form";
import { setBusy, setIdle, showMessage } from "../../redux/AppAction";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { fetch } from "whatwg-fetch";

/// <summary>
/// Author : -
/// </summary>

const Bonus = (props) => {
  const { t } = useTranslation(LanguageKey._PRIMARY);
  let history = useHistory();
  const _dispatch = useDispatch();
  const { register, unregister, handleSubmit, errors, reset } = useForm();
  const [bonusType, setBonusType] = useState(
    BonusTransactionMethod._WITH_DEPOSIT
  );
  const [bonusOption, setBonusOption] = useState([]);
  const [bonusId, setBonusId] = useState("");
  const [memberOption, setMemberOption] = useState([]);
  const [memberId, setMemberId] = useState("");
  const [depositTransactionOption, setDepositTransactionOption] = useState([]);
  const [depositIndex, setDepositIndex] = useState();
  const [depositTransactions, setDepositTransactions] = useState([]);

  const _BONUS_TYPE = [
    { value: BonusTransactionMethod._WITH_DEPOSIT, label: t("WITH_DEPOSIT") },
    {
      value: BonusTransactionMethod._WITHOUT_DEPOSIT,
      label: t("WITHOUT_DEPOSIT"),
    },
  ];

  /// <summary>
  /// Author : -
  /// </summary>
  useEffect(() => {
    init();
    initFormField();
  }, []);

  /// <summary>
  /// Author : -
  /// </summary>
  useEffect(() => {
    if (bonusType == BonusTransactionMethod._WITH_DEPOSIT) {
      register(
        { name: "depositIndex" },
        { required: "PLEASE_ENTER_TRANSACTION_ID" }
      );
      unregister("amount");
    } else {
      setDepositIndex();
      register(
        { name: "amount" },
        {
          required: "PLEASE_ENTER_AMOUNT",
          min: {
            value: 0,
            message: "PLEASE_ENTER_VALUE_NOT_LESS_THAN_ZERO",
          },
        }
      );
      unregister("depositIndex");
    }
  }, [bonusType]);

  /// <summary>
  /// Author : -
  /// </summary>
  useEffect(() => {
    if (!stringIsNullOrEmpty(memberId)) {
      getUserBonus();

      var apiUrl = ApiUrl._API_GET_TRANSACTION_BY_MEMBER_ID;
      apiUrl += "?memberId=" + memberId + "&type=" + TransactionType._DEPOSIT;
      fetch(apiUrl, {
        method: ApiKey._API_GET,
        headers: {
          "Content-Type": ApiKey._API_FORM_URLENCODED,
          "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
        },
      })
        .then((response) => response.json())
        .then((responseJson) => {
          if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            const depositTransaction = [];
            responseJson[ApiKey._API_DATA_KEY].forEach(function (
              transaction,
              index
            ) {
              if (transaction["isDepositWithBonus"] == false) {
                depositTransaction.push({
                  label: transaction.id,
                  value: index,
                });
              }
            });
            setDepositTransactions(responseJson[ApiKey._API_DATA_KEY]);
            setDepositTransactionOption(depositTransaction);
          }
        });
    }
  }, [memberId]);

  /// <summary>
  /// Author : -
  /// </summary>
  const getUserBonus = async () => {
    var userBonusApiUrl = ApiUrl._API_GET_USER_BONUS;
    userBonusApiUrl += "?dayCheck=" + true + "&memberId=" + memberId;
    await fetch(userBonusApiUrl, {
      method: ApiKey._API_GET,
      headers: {
        "Content-Type": ApiKey._API_FORM_URLENCODED,
        "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
          const bonusList = [];
          responseJson[ApiKey._API_DATA_KEY].forEach(function (bonus) {
            bonusList.push({
              label:
                bonus.title +
                " (" +
                bonus.amount +
                (bonus.methodId == BonusMethodType._PERCENT ? "%" : "") +
                ")",
              value: bonus.id,
            });
          });

          setBonusOption(bonusList);
        }
      });
  };

  /// <summary>
  /// Author : -
  /// </summary>
  async function init() {
    await fetch(ApiUrl._API_GET_USER_MEMBER_LIST, {
      method: ApiKey._API_GET,
      headers: {
        "Content-Type": ApiKey._API_FORM_URLENCODED,
        "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
          const memberList = [];
          responseJson[ApiKey._API_DATA_KEY].forEach(function (member) {
            memberList.push({ label: member.username, value: member.id });
          });

          setMemberOption(memberList);
        }
      });
  }

  /// <summary>
  /// Author : -
  /// </summary>
  const initFormField = () => {
    setMemberId("");
    setDepositIndex();
    setBonusType(BonusTransactionMethod._WITH_DEPOSIT);
    setBonusId("");
    setDepositTransactions([]);
    setDepositTransactionOption([]);
    setBonusOption([]);
    register({ name: "memberId" }, { required: "PLEASE_SELECT_USER" });
    register({ name: "bonusId" }, { required: "PLEASE_SELECT_BONUS" });
    register(
      { name: "depositIndex" },
      { required: "PLEASE_ENTER_TRANSACTION_ID" }
    );
  };

  /// <summary>
  /// Author : -
  /// </summary>
  const submitForm = async (data, e) => {
    let params = {
      transactionTypeId: TransactionType._BONUS,
      memberId: memberId,
      channel: "",
      panel: PanelType._BACKOFFICE,
      bonusId: bonusId,
    };

    if (!stringIsNullOrEmpty(data.amount)) {
      params["amount"] = data.amount;
    }

    if (depositIndex >= 0) {
      params["depositId"] = depositTransactions[depositIndex].id;
      params["amount"] = depositTransactions[depositIndex].amount;
    }

    let formBody = createFormBody(params);
    _dispatch(setBusy());

    await fetch(ApiUrl._API_CREATE_TRANSACTION, {
      method: ApiKey._API_POST,
      headers: {
        "Content-Type": ApiKey._API_FORM_URLENCODED,
        "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
      },
      body: formBody,
    })
      .then((response) => response.json())
      .then((responseJson) => {
        _dispatch(
          showMessage(
            responseJson[ApiKey._API_SUCCESS_KEY],
            t(responseJson[ApiKey._API_MESSAGE_KEY])
          )
        );
        _dispatch(setIdle());

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
          e.target.reset();
          initFormField();
        }
      });
  };

  return (
    <div>
      <h1 className="page-header">{t("BONUS")}</h1>
      <div className="row">
        <div className="col-xl-12">
          <Panel>
            <PanelBody>
              <form onSubmit={handleSubmit(submitForm)}>
                <div className="row">
                  <div className="col-lg-8">
                    <div className="form-group row m-b-15">
                      <label className="col-md-4 col-form-label">
                        <b>{t("TYPE")}</b>
                      </label>
                      <div className="col-md-7">
                        <Select
                          value={_BONUS_TYPE.filter(
                            (option) => option.value == bonusType
                          )}
                          options={_BONUS_TYPE}
                          onChange={(e) => {
                            setBonusType(e.value);
                          }}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-8">
                    <div className="form-group row m-b-15">
                      <label className="col-md-4 col-form-label">
                        <b>{t("USERNAME")}</b>
                      </label>
                      <div className="col-md-7">
                        <Select
                          name="memberId"
                          options={memberOption}
                          placeholder={
                            memberOption.filter(
                              (option) => option.value == memberId
                            )[0] !== undefined
                              ? memberOption.filter(
                                  (option) => option.value == memberId
                                )[0].label
                              : ""
                          }
                          value={memberOption.filter(
                            (option) => option.value == memberId
                          )}
                          onChange={(e) => {
                            unregister("memberId");
                            setMemberId(e.value);
                          }}
                        />
                        {errors.memberId && (
                          <div className="invalid-feedback">
                            {t(errors.memberId.message)}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  {bonusType == BonusTransactionMethod._WITH_DEPOSIT ? (
                    <div className="col-lg-8">
                      <div className="form-group row m-b-15">
                        <label className="col-md-4 col-form-label">
                          <b>{t("TRANSACTION_ID")}</b>
                        </label>
                        <div className="col-md-7">
                          <Select
                            name="depositIndex"
                            options={depositTransactionOption}
                            value={depositTransactionOption.filter(
                              (option) => option.value == depositIndex
                            )}
                            onChange={(e) => {
                              unregister("depositIndex");
                              setDepositIndex(e.value);
                            }}
                          />
                          {errors.depositIndex && (
                            <div className="invalid-feedback">
                              {t(errors.depositIndex.message)}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="col-lg-8">
                      <div className="form-group row m-b-15">
                        <label className="col-md-4 col-form-label">
                          <b>{t("AMOUNT")}</b>
                        </label>
                        <div className="col-md-7">
                          <input
                            type="number"
                            className="form-control"
                            placeholder={t("PLEASE_ENTER_AMOUNT")}
                            name="amount"
                            defaultValue={0}
                            ref={register}
                          />
                          {errors.amount && (
                            <div className="invalid-feedback">
                              {t(errors.amount.message)}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  )}

                  <div className="col-lg-8">
                    <div className="form-group row m-b-15">
                      <label className="col-md-4 col-form-label">
                        <b>{t("BONUS")}</b>
                      </label>
                      <div className="col-md-7">
                        <Select
                          name="bonusId"
                          options={bonusOption}
                          placeholder={
                            bonusOption.filter(
                              (option) => option.value == bonusId
                            )[0] !== undefined
                              ? bonusOption.filter(
                                  (option) => option.value == bonusId
                                )[0].label
                              : ""
                          }
                          value={bonusOption.filter(
                            (option) => option.value == bonusId
                          )}
                          onChange={(e) => {
                            unregister("bonusId");
                            setBonusId(e.value);
                          }}
                        />
                        {errors.bonusId && (
                          <div className="invalid-feedback">
                            {t(errors.bonusId.message)}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <hr />
                <div className="row">
                  <div className="col-lg-3">
                    <div className="form-group">
                      <button type="submit" className="btn btn-primary">
                        {t("SUBMIT")}
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </PanelBody>
          </Panel>
        </div>
      </div>
    </div>
  );
};

export default Bonus;
