import React from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { ApiKey, ApiUrl } from "../../../util/Constant";

class DropdownNotification extends React.Component {
    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);
        this.state = {
            dropdownOpen: false,
        };
    }

    toggle() {
        this.setState(prevState => ({
            dropdownOpen: !prevState.dropdownOpen
        }));

        if (!this.state.dropdownOpen) {
            fetch(ApiUrl._API_READ_NOTIFICATION, {
                method: ApiKey._API_GET,
                headers: {
                    "Accept": ApiKey._API_APPLICATION_JSON,
                    "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY
                }
            })
                .then((response) => response.json())
                .then((responseJson) => {
                    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                        this.props.readNotification();
                    }
                });
        }
    }

    render() {
        return (
            <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggle} className="dropdown" tag="li">
                <DropdownToggle className="f-s-14" tag="a">
                    <i className="fa fa-bell"></i>
                    {
                        this.props.notifications.filter(n => !n.isRead).length > 0 && 
                        <span className="label">{this.props.notifications.filter(n => !n.isRead).length}</span>
                    }
                </DropdownToggle>
                <DropdownMenu className="media-list dropdown-menu-right" tag="ul">
                    <DropdownItem className="dropdown-header" tag="li" header>NOTIFICATIONS ({this.props.notifications.filter(n => !n.isRead).length})</DropdownItem>
                    {
                        this.props.notifications.map((notif) => {
                            return <DropdownItem className="media" key={notif.id}>
                                <div className="media-body">
                                    <h6 className="media-heading">{notif.title}</h6>
                                    <p>{notif.description}</p>
                                    <div className="text-muted f-s-11">{notif.createdDateTime}</div>
                                </div>
                            </DropdownItem>
                        })
                    }
                </DropdownMenu>
            </Dropdown>
        );
    }
};

export default DropdownNotification;
