import React, { useState, useEffect } from "react";
import { Panel, PanelBody } from "../../components/panel/panel.jsx";
import { useHistory } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import NavigationButton from "../../components/constantComponent/button/NavigationButton";
import {
	ApiKey,
	ApiUrl,
	TransactionType,
	PanelType,
	ExternalUrl,
	LanguageKey,
	BankingChannel,
	DEFAULT_PAGE_SIZE,
} from "../../util/Constant";
import {
	stringIsNullOrEmpty,
	createFormBody,
	createMultiPartFormBody,
} from "../../util/Util";
import { useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { setBusy, setIdle, showMessage } from "../../redux/AppAction";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { fetch } from "whatwg-fetch";
import { AsyncPaginate } from "react-select-async-paginate";
import ApiEngine from "../../util/ApiEngine";
import ReactHtmlParser from "react-html-parser";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import PinInput from "react-pin-input";
import classnames from "classnames";

import { Modal, ModalHeader, ModalBody } from "reactstrap";

import moment from "moment";
import Dropzone from "react-dropzone";
import BonusDepositDetailLanguage from "./BonusDepositDetailLanguage.js";
/*import { setBusy, setIdle, showResponseMessage } from "../../redux/AppAction";*/

/// <summary>
/// Author : -
/// </summary>

const BonusDepositDetail = (props) => {
	const _DETAIL_TAB = "1";
	const _LANGUAGE_TAB = "2";
	const { t } = useTranslation(LanguageKey._PRIMARY);
	let _history = useHistory();
	const _location = useLocation();
	const _dispatch = useDispatch();
	const { register, unregister, handleSubmit, errors, setValue, trigger } =
		useForm();
	const [fullScreen, setFullScreen] = useState(false);
	const [showPinEntry, setShowPinEntry] = useState(false);

	const [bonusDepositId, setBonusDepositId] = useState("");
	const [bonus, setBonus] = useState(0);
	const [minimumDeposit, setMinimumDeposit] = useState(0);
	const [rollover, setRollover] = useState(0);
	const [maxBonus, setMaxBonus] = useState(0);
	const [status, setStatus] = useState(false);
	const [bonusName, setBonusName] = useState("");
	const [maxLimitPerMember, setMaxLimitPerMember] = useState(0);
	const [dayLimit, setDayLimit] = useState(0);
	const [bonusImage, setBonusImage] = useState();
	const [bonusImageFile, setBonusImageFile] = useState([]);
	const [isNewBonusImage, setIsNewBonusImage] = useState(false);
	const [popupImage, setPopupImage] = useState();
	const [popupImageFile, setPopupImageFile] = useState([]);
	const [isNewPopupImage, setIsNewPopupImage] = useState(false);
	const [enteredBonusDepositData, setEnteredBonusDepositData] = useState({});
	const [enteredBonusDepositE, setEnteredBonusDepositE] = useState({});
	const [activeTab, setActiveTab] = useState(_DETAIL_TAB);

	/// <summary>
	/// Author : -
	/// </summary>
	const toggle = (tab) => {
		if (activeTab !== tab) {
			setActiveTab(tab);
		}
	};
	/// <summary>
	/// Author : -
	/// </summary>
	useEffect(() => {
		/// For The Size Difference of Modal And Page
		if (props.id) {
			setFullScreen(true);
		}

		init();
		initFormField();
	}, []);

	/// <summary>
	/// Author : -
	/// Editted : -
	/// </summary>
	async function init() {
		console.log(_location);
		_dispatch(setBusy());
		if (_location.state) {
			let stateBonusDepositId = _location.state.id;
			var apiUrl = ApiUrl._API_GET_BONUS_DEPOSIT_BY_ID;
			apiUrl += "?id=" + stateBonusDepositId;
			fetch(apiUrl, {
				method: ApiKey._API_GET,
				headers: {
					"Content-Type": ApiKey._API_FORM_URLENCODED,
					"X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
				},
			})
				.then((response) => response.json())
				.then((responseJson) => {
					if (responseJson[ApiKey._API_SUCCESS_KEY]) {
						console.log(responseJson[ApiKey._API_DATA_KEY]);
						if (_location.state.id) {
							setBonusDepositId(responseJson[ApiKey._API_DATA_KEY]["id"]);
						}
						setBonus(responseJson[ApiKey._API_DATA_KEY]["bonus"]);
						setMinimumDeposit(
							responseJson[ApiKey._API_DATA_KEY]["minimumDeposit"]
						);
						setRollover(responseJson[ApiKey._API_DATA_KEY]["rollover"]);
						setMaxBonus(responseJson[ApiKey._API_DATA_KEY]["maxBonus"]);
						setStatus(responseJson[ApiKey._API_DATA_KEY]["status"]);
						setBonusName(responseJson[ApiKey._API_DATA_KEY]["bonusName"]);
						setMaxLimitPerMember(
							responseJson[ApiKey._API_DATA_KEY]["maxLimitPerMember"]
						);
						setDayLimit(responseJson[ApiKey._API_DATA_KEY]["dayLimit"]);

						if (
							!stringIsNullOrEmpty(
								responseJson[ApiKey._API_DATA_KEY]["bonusImage"]
							)
						) {
							setBonusImage(responseJson[ApiKey._API_DATA_KEY]["bonusImage"]);
						}

						if (
							!stringIsNullOrEmpty(
								responseJson[ApiKey._API_DATA_KEY]["popupImage"]
							)
						) {
							setPopupImage(responseJson[ApiKey._API_DATA_KEY]["popupImage"]);
						}
					}
				});
		}

		_dispatch(setIdle());
	}

	/// <summary>
	/// Author : -
	/// </summary>
	const initFormField = () => {
		setBonus("");
		setMinimumDeposit("");
		setRollover("");
		setMaxBonus("");
		setBonusName("");
		setMaxLimitPerMember("");
		setDayLimit("");
	};

	/// <summary>
	/// Author : -
	/// Used to set bonus image
	/// </summary>
	const bonusImageDrop = (acceptedFiles) => {
		if (acceptedFiles.length !== 0) {
			const file = acceptedFiles[0];
			setBonusImageFile(file);
			const reader = new FileReader();
			reader.onloadend = () => {
				setBonusImage(reader.result);
				setIsNewBonusImage(true);
			};
			reader.readAsDataURL(file);
		}
	};

	/// <summary>
	/// Author : -
	/// Used to set popup image
	/// </summary>
	const popupImageDrop = (acceptedFiles) => {
		if (acceptedFiles.length !== 0) {
			const file = acceptedFiles[0];
			setPopupImageFile(file);
			const reader = new FileReader();
			reader.onloadend = () => {
				setPopupImage(reader.result);
				setIsNewPopupImage(true);
			};
			reader.readAsDataURL(file);
		}
	};

	/// <summary>
	/// Author : -
	/// Editted : -
	/// </summary>
	const submitForm = async (data, e) => {
		let params = {
			bonus: data.bonus,
			minimumDeposit: data.minimumDeposit,
			rollover: data.rollover,
			maxBonus: data.maxBonus,
			status: data.status,
			bonusName: data.bonusName,
			maxLimitPerMember: data.maxLimitPerMember,
			dayLimit: data.dayLimit,
			isNewBonusImage: isNewBonusImage,
			isNewPopupImage: isNewPopupImage,
		};

		if (params.bonus > 100 || params.bonus < 0) {
			_dispatch(showMessage(false, t("INVALID_BONUS_AMONUT")));
			return;
		}

		if (!stringIsNullOrEmpty(bonusImageFile)) {
			params["bonusImage"] = bonusImageFile;
		}

		if (!stringIsNullOrEmpty(popupImageFile)) {
			params["popupImage"] = popupImageFile;
		}

		if (stringIsNullOrEmpty(bonusImage)) {
			params["bonusImageRemoved"] = true;
		}

		if (stringIsNullOrEmpty(popupImage)) {
			params["popupImageRemoved"] = true;
		}

		if (!stringIsNullOrEmpty(bonusDepositId)) {
			params["id"] = bonusDepositId;
		}

		let formBody = createMultiPartFormBody(params);
		_dispatch(setBusy());

		let responseJson = await ApiEngine.post(
			ApiUrl._API_CREATE_OR_UPDATE_BONUS_DEPOSIT,
			formBody
		);

		if (responseJson[ApiKey._API_SUCCESS_KEY]) {
			e.target.reset();
			window.location.reload();

			if (props.onComplete != null) {
				props.onComplete();
			}
		} else {
			setShowPinEntry(false);
		}
		_dispatch(setIdle());
		_dispatch(
			showMessage(
				responseJson[ApiKey._API_SUCCESS_KEY],
				t(responseJson[ApiKey._API_MESSAGE_KEY])
			)
		);
	};

	async function onPinSubmit(data, e) {
		// verify pin
		let params = { transactionPinNumber: data };
		let formBody = createFormBody(params);
		let responseJson = await ApiEngine.post(
			ApiUrl._API_VERIFY_USER_TRANSACTION_PIN_NUMBER,
			formBody
		);

		if (responseJson[ApiKey._API_SUCCESS_KEY]) {
			submitForm(enteredBonusDepositData, enteredBonusDepositE);
		} else {
			_dispatch(showMessage(false, t("INCORRECT") + " " + t("PIN_NUMBER")));
			setShowPinEntry(false);
		}
	}

	async function checkPinValue(data, e) {
		// check if pin has 4 digits (valid pin)
		// if true then submit pin
		if (data.length == 4) {
			onPinSubmit(data);
		}
	}

	async function onSubmit(data, e) {
		setEnteredBonusDepositData(data);
		setEnteredBonusDepositE(e);
		setShowPinEntry(true);
	}

	return (
		<div>
			<h1 className="page-header">
				{t("BONUS_DEPOSIT")}
				{props.id ? (
					<button
						style={{ float: "right" }}
						className="btn btn-inverse"
						onClick={() => {
							props.toggleModal(false);
						}}
					>
						<i className="fa fa-times"></i>
					</button>
				) : (
					<NavigationButton history={_history} />
				)}
			</h1>
			{bonusDepositId && (
				<Nav tabs>
					<NavItem>
						<NavLink
							className={classnames({ active: activeTab === _DETAIL_TAB })}
							onClick={() => {
								toggle(_DETAIL_TAB);
							}}
						>
							{t("DETAIL")}
						</NavLink>
					</NavItem>
					<NavItem>
						<NavLink
							className={classnames({ active: activeTab === _LANGUAGE_TAB })}
							onClick={() => {
								toggle(_LANGUAGE_TAB);
							}}
						>
							{t("LANGUAGE")}
						</NavLink>
					</NavItem>
				</Nav>
			)}
			<TabContent activeTab={activeTab}>
				<TabPane tabId={_DETAIL_TAB}>
					<div className="row">
						<div className="col-xl-12">
							<Panel>
								<PanelBody>
									<form onSubmit={handleSubmit(onSubmit)}>
										<div className="row">
											<div className={fullScreen ? "col-lg-12" : "col-lg-8"}>
												<div className="form-group row m-b-15">
													<label className="col-md-4 col-form-label">
														<b>{t("BONUS_NAME")}</b>
													</label>
													<div className="col-md-7">
														<input
															type="text"
															className="form-control"
															placeholder={t("PLEASE_ENTER_BONUS_NAME")}
															name="bonusName"
															ref={register({
																required: "PLEASE_ENTER_BONUS_NAME",
															})}
															value={bonusName}
															onChange={(e) => setBonusName(e.target.value)}
														/>
														{errors.bonusName && (
															<div className="invalid-feedback">
																{t(errors.bonusName.message)}
															</div>
														)}
													</div>
												</div>
											</div>

											<div className={fullScreen ? "col-lg-12" : "col-lg-8"}>
												<div className="form-group row m-b-15">
													<label className="col-md-4 col-form-label">
														<b>{t("BONUS")} (%)</b>
													</label>
													<div className="col-md-7">
														<input
															type="number"
															className="form-control"
															placeholder={t("PLEASE_ENTER_BONUS")}
															name="bonus"
															ref={register({
																required: "PLEASE_ENTER_BONUS",
															})}
															value={bonus}
															onChange={(e) => setBonus(e.target.value)}
														/>
														{errors.bonus && (
															<div className="invalid-feedback">
																{t(errors.bonus.message)}
															</div>
														)}
													</div>
												</div>
											</div>

											<div className={fullScreen ? "col-lg-12" : "col-lg-8"}>
												<div className="form-group row m-b-15">
													<label className="col-md-4 col-form-label">
														<b>{t("MINIMUM_DEPOSIT")}</b>
													</label>
													<div className="col-md-7">
														<input
															type="number"
															className="form-control"
															placeholder={t("PLEASE_ENTER_MINIMUM_DEPOSIT")}
															name="minimumDeposit"
															ref={register({
																required: "PLEASE_ENTER_MINIMUM_DEPOSIT",
															})}
															value={minimumDeposit}
															onChange={(e) =>
																setMinimumDeposit(e.target.value)
															}
														/>
														{errors.minimumDeposit && (
															<div className="invalid-feedback">
																{t(errors.minimumDeposit.message)}
															</div>
														)}
													</div>
												</div>
											</div>

											<div className={fullScreen ? "col-lg-12" : "col-lg-8"}>
												<div className="form-group row m-b-15">
													<label className="col-md-4 col-form-label">
														<b>{t("ROLLOVER")}</b>
													</label>
													<div className="col-md-7">
														<input
															type="number"
															className="form-control"
															placeholder={t("PLEASE_ENTER_ROLLOVER")}
															name="rollover"
															ref={register({
																required: "PLEASE_ENTER_ROLLOVER",
															})}
															value={rollover}
															onChange={(e) => setRollover(e.target.value)}
														/>
														{errors.rollover && (
															<div className="invalid-feedback">
																{t(errors.rollover.message)}
															</div>
														)}
													</div>
												</div>
											</div>

											<div className={fullScreen ? "col-lg-12" : "col-lg-8"}>
												<div className="form-group row m-b-15">
													<label className="col-md-4 col-form-label">
														<b>{t("MAX_BONUS")}</b>
													</label>
													<div className="col-md-7">
														<input
															type="number"
															className="form-control"
															placeholder={t("PLEASE_ENTER_MAX_BONUS")}
															name="maxBonus"
															ref={register({
																required: "PLEASE_ENTER_MAX_BONUS",
															})}
															value={maxBonus}
															onChange={(e) => setMaxBonus(e.target.value)}
														/>
														{errors.maxBonus && (
															<div className="invalid-feedback">
																{t(errors.maxBonus.message)}
															</div>
														)}
													</div>
												</div>
											</div>

											<div className={fullScreen ? "col-lg-12" : "col-lg-8"}>
												<div className="form-group row m-b-15">
													<label className="col-md-4 col-form-label">
														<b>{t("MAX_LIMIT_PER_MEMBER")}</b>
													</label>
													<div className="col-md-7">
														<input
															type="number"
															className="form-control"
															placeholder={t("PLEASE_ENTER_MAX_LIMIT")}
															name="maxLimitPerMember"
															ref={register({
																required: "PLEASE_ENTER_MAX_LIMIT",
															})}
															value={maxLimitPerMember}
															onChange={(e) =>
																setMaxLimitPerMember(e.target.value)
															}
														/>
														{errors.maxLimitPerMember && (
															<div className="invalid-feedback">
																{t(errors.maxLimitPerMember.message)}
															</div>
														)}
													</div>
												</div>
											</div>

											<div className={fullScreen ? "col-lg-12" : "col-lg-8"}>
												<div className="form-group row m-b-15">
													<label className="col-md-4 col-form-label">
														<b>{t("DAY_LIMIT")}</b>
													</label>
													<div className="col-md-7">
														<input
															type="number"
															className="form-control"
															placeholder={t("PLEASE_ENTER_DAY_LIMIT")}
															name="dayLimit"
															ref={register({
																required: "PLEASE_ENTER_DAY_LIMIT",
															})}
															value={dayLimit}
															onChange={(e) => setDayLimit(e.target.value)}
														/>
														{errors.dayLimit && (
															<div className="invalid-feedback">
																{t(errors.dayLimit.message)}
															</div>
														)}
													</div>
												</div>
											</div>

											<div className={fullScreen ? "col-lg-12" : "col-lg-8"}>
												<div className="form-group row m-b-15">
													<label className="col-md-4 col-form-label">
														<b>{t("STATUS")}</b>
													</label>
													<div className="col-md-7">
														<div className="switcher">
															<input
																type="checkbox"
																name="status"
																id="status"
																onChange={(e) => setStatus(e.target.checked)}
																value={status}
																checked={status}
																ref={register}
															/>
															<label htmlFor="status"></label>
														</div>
													</div>
												</div>
											</div>

											<div className={fullScreen ? "col-lg-12" : "col-lg-8"}>
												<div className="form-group row m-b-15">
													<label className="col-md-4 col-form-label">
														<b>{t("BONUS_IMAGE")}</b>
													</label>
													<div className="col-md-7">
														<Dropzone
															accept={"image/*"}
															onDrop={(acceptedFiles) =>
																bonusImageDrop(acceptedFiles)
															}
														>
															{({ getRootProps, getInputProps }) => (
																<section>
																	<div
																		className="dropzone"
																		style={{
																			minHeight: "200px",
																			textAlign: "center",
																		}}
																		{...getRootProps()}
																	>
																		<input {...getInputProps()} />
																		<h4 style={{ color: "grey" }}>
																			{t(
																				"DRAG_N_DROP_SOME_FILES_HERE_OR_CLICK_TO_SELECT_FILES"
																			)}
																		</h4>
																		{!stringIsNullOrEmpty(bonusImage) && (
																			<aside className="thumbsContainer">
																				<div className="thumb">
																					<div className="thumbInner">
																						<img
																							src={bonusImage}
																							className="dropzone-img"
																						/>
																					</div>
																				</div>
																			</aside>
																		)}
																	</div>
																</section>
															)}
														</Dropzone>
														<br />
														<button
															type="button"
															className="btn btn-danger"
															onClick={() => {
																setBonusImage("");
																setBonusImageFile();
															}}
														>
															{t("REMOVE_IMAGE")}
														</button>
													</div>
												</div>
											</div>

											<div className={fullScreen ? "col-lg-12" : "col-lg-8"}>
												<div className="form-group row m-b-15">
													<label className="col-md-4 col-form-label">
														<b>{t("POPUP_IMAGE")}</b>
													</label>
													<div className="col-md-7">
														<Dropzone
															accept={"image/*"}
															onDrop={(acceptedFiles) =>
																popupImageDrop(acceptedFiles)
															}
														>
															{({ getRootProps, getInputProps }) => (
																<section>
																	<div
																		className="dropzone"
																		style={{
																			minHeight: "200px",
																			textAlign: "center",
																		}}
																		{...getRootProps()}
																	>
																		<input {...getInputProps()} />
																		<h4 style={{ color: "grey" }}>
																			{t(
																				"DRAG_N_DROP_SOME_FILES_HERE_OR_CLICK_TO_SELECT_FILES"
																			)}
																		</h4>
																		{!stringIsNullOrEmpty(popupImage) && (
																			<aside className="thumbsContainer">
																				<div className="thumb">
																					<div className="thumbInner">
																						<img
																							src={popupImage}
																							className="dropzone-img"
																						/>
																					</div>
																				</div>
																			</aside>
																		)}
																	</div>
																</section>
															)}
														</Dropzone>
														<br />
														<button
															type="button"
															className="btn btn-danger"
															onClick={() => {
																setPopupImage("");
																setPopupImageFile();
															}}
														>
															{t("REMOVE_IMAGE")}
														</button>
													</div>
												</div>
											</div>
										</div>
										<hr />
										<div className="row">
											<div className="col-lg-3">
												<div className="form-group">
													<button type="submit" className="btn btn-primary">
														{t("SUBMIT")}
													</button>
												</div>
											</div>
										</div>
									</form>
								</PanelBody>
							</Panel>
						</div>
					</div>
				</TabPane>
				<TabPane tabId={_LANGUAGE_TAB}>
					{bonusDepositId && (
						<div className="row">
							<div className="col-lg-12">
								<BonusDepositDetailLanguage id={bonusDepositId} />
							</div>
						</div>
					)}
				</TabPane>
			</TabContent>
			<Modal
				fade={false}
				contentClassName="modal-brand modal-bottom modal-numpad"
				isOpen={showPinEntry}
				centered
			>
				<ModalBody>
					<form onSubmit={handleSubmit(onPinSubmit)}>
						<div className="display-wrapper">
							<button
								type="button"
								style={{
									alignSelf: "flex-end",
									backgroundColor: "transparent",
									border: "none",
									marginRight: "15px",
									fontSize: "2rem",
									color: "#002e6c",
									marginTop: "-15px",
									float: "right",
									fontWeight: "bold",
								}}
								onClick={() => {
									setShowPinEntry(false);
								}}
								data-dismiss="modal"
								aria-hidden="true"
							>
								&times;
							</button>
							<strong
								className="reload-title text-yellow mb-3 d-block"
								style={{ fontSize: "20px" }}
							>
								{t("ENTER_YOUR_PIN")}
							</strong>
							<PinInput
								length={4}
								initialValue=""
								secret={true}
								focus={true}
								autoSelect={true}
								type="numeric"
								onChange={(value, index) => {
									checkPinValue(value, index);
								}}
								inputMode="numeric"
								inputStyle={{
									border: "0",
									margin: "0px 10px",
									width: "40px",
									height: "40px",
									backgroundColor: "transparent",
									borderBottom: "2px solid #002e6c",
								}}
								inputFocusStyle={{ borderBottom: "2px solid #FFC159" }}
								regexCriteria={/^[ 0-9_@./#&+-]*$/}
							/>
						</div>
					</form>
				</ModalBody>
			</Modal>
		</div>
	);
};

export default BonusDepositDetail;
