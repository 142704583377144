import React, { useState, useEffect } from "react";
import ReactTable from "../../components/constantComponent/reactTable/ReactTable";
import { Panel, PanelBody } from "./../../components/panel/panel.jsx";
import moment from "moment";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { showMessage } from "../../redux/AppAction";
import { useDispatch, useSelector } from "react-redux";
import {
	ApiKey,
	ApiUrl,
	LanguageKey,
	DEFAULT_PAGE_SIZE,
} from "../../util/Constant";
import { stringIsNullOrEmpty, numberWithCurrencyFormat } from "../../util/Util";
import { useTranslation } from "react-i18next";
import ApiEngine from "../../util/ApiEngine.js";
import Select from "react-select";
import { AsyncPaginate } from "react-select-async-paginate";

/// <summary>
/// Author : -
/// </summary>

const ManageBonusCommReport = (props) => {
	let _dispatch = useDispatch();
	const { t } = useTranslation(LanguageKey._PRIMARY);
	const [reportData, setReportData] = useState([]);
	const [startDate, setStartDate] = useState(
		moment().startOf("day").format("YYYY-MM-DD HH:mm:ss")
	);
	const [endDate, setEndDate] = useState(
		moment().format("YYYY-MM-DD HH:mm:ss")
	);
	const [userHierachy, setUserHierachy] = useState([]);
	var _username = useSelector(
		(state) => state["authState"]["userData"]["username"]
	);
	const [allMembers, setAllMembers] = useState([]);
	const [selectedMember, setSelectedMember] = useState(
		useSelector((state) => state["authState"]["userData"]["userId"])
	);
	const [selectedFilterMember, setSelectedFilterMember] = useState();
	const ALL_OPTION_LABEL = "All";
	const ALL_OPTION_VALUE = "all";
	const [selectedProduct, setSelectedProduct] = useState(ALL_OPTION_VALUE);
	const [productList, setProductList] = useState([]);

	var _userId = useSelector(
		(state) => state["authState"]["userData"]["userId"]
	)

	let _tableColumns = [
		{
			Header: "USERNAME",
			accessor: "username",
			Cell: ({ row }) => {
				return (
					<button
						type="button"
						className="btn btn-link"
						onClick={() => {
							readReport(row.original.userId);
						}}
					>
						{row.original.username}
					</button>
				);
			},
			disableSortBy: true,
		},
		{
			Header: "DOWNLINE",
			accessor: "totalDownline",
			Cell: ({ row }) => {
				return row.original.totalDownline;
			},
			disableSortBy: true,
		},
		{
			Header: "TURNOVER",
			accessor: "totalTurnover",
			Cell: ({ row }) => {
				return row.original.totalTurnover.toFixed(2);
			},
			disableSortBy: true,
		},
		{
			Header: "TOTAL_BONUS",
			accessor: "totalBonus",
			Cell: ({ row }) => {
				return (
					row.original.totalBonus +
					row.original.ownBonus +
					row.original.downlineBonus
				).toFixed(2);
			},
			disableSortBy: true,
		},
		{
			Header: "OWN_COMMISSION",
			accessor: "ownCommission",
			Cell: ({ row }) => {
				return numberWithCurrencyFormat(
					parseFloat(row.original.ownCommission),
					3
				);
			},
			disableSortBy: true,
		},
		{
			Header: "DOWNLINE_COMMISSION",
			accessor: "downlineCommission",
			Cell: ({ row }) => {
				return numberWithCurrencyFormat(
					parseFloat(row.original.downlineCommission),
					3
				);
			},
			disableSortBy: true,
		},
		{
			Header: "OWN_VIP_COMMISSION",
			accessor: "vipCommission",
			Cell: ({ row }) => {
				return numberWithCurrencyFormat(
					parseFloat(row.original.vipCommission),
					3
				);
			},
			disableSortBy: true,
		},
		{
			Header: "TOTAL_COMMISSION",
			accessor: "totalCommission",
			Cell: ({ row }) => {
				return numberWithCurrencyFormat(
					row.original.vipCommission +
						row.original.ownCommission +
						row.original.downlineCommission,
					3
				);
			},
			disableSortBy: true,
		},
	];

	/// <summary>
	/// Author : -
	/// </summary>
	useEffect(() => {
		init();
	}, []);

	/// <summary>
	/// Author : -
	/// </summary>
	async function init() {
		var responseJson = await ApiEngine.get(
			`${ApiUrl._API_GET_USER_MEMBER_LIST}`
		);

		if (responseJson[ApiKey._API_SUCCESS_KEY]) {
			if (
				allMembers["length"] == 0 &&
				responseJson[ApiKey._API_DATA_KEY]["length"] > 0
			) {
				var list = [];

				list.push({
					label: _username,
					value: "",
					uplineMemberModel: {},
				});

				responseJson[ApiKey._API_DATA_KEY].map((data) => {
					list.push({
						label: data["username"],
						value: data["id"],
						uplineMemberModel: data["uplineMemberModel"],
					});
				});

				setAllMembers(list);
			}
		} else {
			_dispatch(
				showMessage(
					responseJson[ApiKey._API_SUCCESS_KEY],
					t(responseJson[ApiKey._API_MESSAGE_KEY])
				)
			);
		}
	}

	/// <summary>
	/// Author : -
	/// </summary>
	const handleEvent = (event, picker) => {
		setStartDate(moment(picker.startDate).format("YYYY-MM-DD HH:mm:ss"));
		setEndDate(moment(picker.endDate).format("YYYY-MM-DD HH:mm:ss"));
	};

	/// <summary>
	/// Author : -
	/// </summary>
	async function readReport(memberId, searchDownline = true) {
		try {
			var apiUrl =
				ApiUrl._API_GET_BONUS_COMMISSION_REPORT +
				"?dateFrom=" +
				startDate +
				"&dateTo=" +
				endDate +
				"&searchDownline=" +
				searchDownline;

			if (!stringIsNullOrEmpty(memberId)) {
				apiUrl += "&memberId=" + memberId;
			}

			if (selectedProduct != ALL_OPTION_VALUE) {
				apiUrl += "&productid=" + selectedProduct;
			}

			var responseJson = await ApiEngine.get(apiUrl);

			if (responseJson[ApiKey._API_SUCCESS_KEY]) {
				setReportData(responseJson[ApiKey._API_DATA_KEY]);
			} else {
				throw responseJson[ApiKey._API_MESSAGE_KEY];
			}

			apiUrl = ApiUrl._API_GET_MEMBER_HIERACHY;

			if (!stringIsNullOrEmpty(memberId)) {
				apiUrl += "?memberId=" + memberId;
			}

			responseJson = await ApiEngine.get(apiUrl);

			console.log(responseJson);
			if (responseJson[ApiKey._API_SUCCESS_KEY]) {
				setUserHierachy(responseJson[ApiKey._API_DATA_KEY]);
			} else {
				throw responseJson[ApiKey._API_MESSAGE_KEY];
			}
		} catch (error) {
			_dispatch(showMessage(false, t(error)));
		}
	}

	/// <summary>
	/// Author : Wong
	/// </summary>
	async function getMemberList(search, loadOptions, { page }) {
		var responseJson = await ApiEngine.get(
			`${ApiUrl._API_GET_USER_MEMBER_LIST}?start=${
				(page - 1) * DEFAULT_PAGE_SIZE
			}&count=${DEFAULT_PAGE_SIZE}&keyword=${search}`
		);

		if (responseJson[ApiKey._API_SUCCESS_KEY]) {
			let data = responseJson[ApiKey._API_DATA_KEY]["data"];
			let formattedData = data.map((m) => {
				return { label: m.username, value: m.id };
			});
			formattedData.unshift({ label: "All", value: _userId });
			return {
				options: formattedData,
				hasMore: formattedData.length == DEFAULT_PAGE_SIZE,
				additional: {
					page: page + 1,
				},
			};
		}
	}

	/// <summary>
	/// Author : -
	/// </summary>
	useEffect(() => {
		init();
	}, []);
	/// <summary>
	/// Author : -
	/// </summary>
	async function init() {
		await fetch(ApiUrl._API_GET_MASTER_PRODUCT_ASSIGNMENT_DATA, {
			method: ApiKey._API_GET,
			headers: {
				"Content-Type": ApiKey._API_FORM_URLENCODED,
				"X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
			},
		})
			.then((response) => response.json())
			.then((responseJson) => {
				if (responseJson[ApiKey._API_SUCCESS_KEY]) {
					if (responseJson[ApiKey._API_SUCCESS_KEY]) {
						const accountProductOptionList = [
							{ label: ALL_OPTION_LABEL, value: ALL_OPTION_VALUE },
						];
						responseJson[ApiKey._API_DATA_KEY].forEach(function (option) {
							accountProductOptionList.push({
								label: option.productName,
								value: option.id,
							});
						});

						setProductList(accountProductOptionList);
					}
				} else {
					_dispatch(
						showMessage(
							responseJson[ApiKey._API_SUCCESS_KEY],
							t(responseJson[ApiKey._API_MESSAGE_KEY])
						)
					);
				}
			});
	}

	return (
		<div>
			<h1 className="page-header">
				{stringIsNullOrEmpty(props.id) && t("BONUS_COMMISSION_REPORT")}
			</h1>
			<Panel>
				<PanelBody>
					<div className="row">
						<div className="col-lg-3">
							<div className="form-group">
								<label>
									<b>{t("DATE")}</b>
								</label>
								<div
									className="input-group"
									style={{ width: "100%", flexFlow: "nowrap" }}
								>
									<div className="input-group-prepend">
										<span className="input-group-text">
											<i className="fa fa-calendar"></i>
										</span>
									</div>
									<div style={{ width: "80%" }}>
										<DateRangePicker
											containerStyles={{ width: "100%" }}
											startDate={startDate}
											endDate={endDate}
											onApply={handleEvent}
											alwaysShowCalendars={true}
											locale={{ format: "YYYY-MM-DD HH:mm:ss" }}
											timePicker={true}
											timePickerSeconds={true}
											ranges={{
												Today: [moment().startOf("day"), moment().endOf("day")],
												Yesterday: [
													moment().subtract(1, "days").startOf("day"),
													moment().subtract(1, "days").endOf("day"),
												],
												"Last 7 Days": [
													moment().subtract(6, "days").startOf("day"),
													moment().endOf("day"),
												],
												"Last 30 Days": [
													moment().subtract(29, "days").startOf("day"),
													moment().endOf("day"),
												],
												"This Month": [
													moment().startOf("month"),
													moment().endOf("month"),
												],
												"Last Month": [
													moment().subtract(1, "month").startOf("month"),
													moment().subtract(1, "month").endOf("month"),
												],
												"This Year": [
													moment().startOf("year"),
													moment().endOf("year"),
												],
											}}
										>
											<input
												type="text"
												className="form-control"
												value={startDate + " - " + endDate}
											/>
										</DateRangePicker>
									</div>
								</div>
							</div>
						</div>
						<div className="col-lg-3">
							<div className="form-group">
								<label>
									<b>{t("USERNAME")}</b>
								</label>
								<AsyncPaginate
									debounceTimeout={250}
									loadOptions={getMemberList}
									additional={{
										page: 1,
									}}
									value={selectedFilterMember}
									onChange={(e) => {
										setSelectedFilterMember(e);
										setSelectedMember(e.value);
									}}
								/>
							</div>
						</div>
						<div className="col-lg-3">
							<div className="form-group">
								<label>
									<b>{t("GAME_NAME")}</b>
								</label>
								<Select
									value={productList.filter(
										(product) => product.value == selectedProduct
									)}
									options={productList}
									onChange={(e) => {
										setSelectedProduct(e.value);
									}}
								/>

								{console.log(selectedProduct)}
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-lg-2">
							<button
								type="button"
								className="btn btn-primary"
								onClick={() => {
									readReport(selectedMember, false);
								}}
							>
								<i className="fas fa-search"></i> {t("SEARCH")}
							</button>
						</div>
					</div>
					<hr />
					<div className="hierarchy-line">
						{userHierachy.map((x, i) => {
							return (
								<>
									<a
										href="javascript:;"
										onClick={() => {
											readReport(x["id"]);
										}}
									>
										{x["username"]}
									</a>
									{userHierachy.length != i + 1 ? " / " : ""}
								</>
							);
						})}
					</div>
					<ReactTable data={reportData} columns={_tableColumns} />
				</PanelBody>
			</Panel>
		</div>
	);
};

export default ManageBonusCommReport;
