import React, { useState, useEffect } from "react";
import ReactTable from "../../components/constantComponent/reactTable/ReactTable";
import { Panel, PanelBody } from "./../../components/panel/panel.jsx";
import {
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { navigateTo } from "../../util/Util";
import { useLocation, useHistory } from "react-router-dom";
import {
  ApiKey,
  WebUrl,
  ApiUrl,
  AccessRight,
  LanguageKey,
} from "../../util/Constant";
import { checkIfPermissionExist } from "../../util/PermissionChecker";
import { useDispatch } from "react-redux";
import { setBusy, setIdle, showMessage } from "../../redux/AppAction";
import { useTranslation } from "react-i18next";
import { fetch } from "whatwg-fetch";

/// <summary>
/// Author : -
/// </summary>
const ManageGame = (props) => {
  const { t } = useTranslation(LanguageKey._PRIMARY);
  let _history = useHistory();
  const _location = useLocation();
  const _dispatch = useDispatch();

  const [submitError, setSubmitError] = useState("");
  const [submitSuccess, setSubmitSuccess] = useState("");
  const [gameData, setGameData] = useState([]);

  let _tableColumns = [
    {
      Header: "NAME",
      accessor: "gameName",
    },
    {
      Header: "CODE",
      accessor: "gameCode",
    },
    {
      Header: "STATUS",
      accessor: "status",
      Cell: ({ row }) => (
        <div className="btn-group m-r-5 m-b-5">
          {row.original.status == 1 ? (
            <span className="badge badge-secondary badge-green">
              {t("ACTIVE")}
            </span>
          ) : (
            <span className="badge badge-secondary badge-danger">
              {t("INACTIVE")}
            </span>
          )}
        </div>
      ),
      disableSortBy: true,
      style: { overflow: "visible" },
    },
    {
      id: "action",
      Header: "",
      Cell: ({ row }) =>
        checkIfPermissionExist(
          AccessRight._MASTER_PRODUCT_CONTROL_PERMISSION,
          true
        ) && (
          <div className="btn-group m-r-5 m-b-5">
            <UncontrolledButtonDropdown>
              <DropdownToggle caret color="default">
                <i className="fas fa-cog"></i>
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem
                  onClick={() => {
                    navigateTo(
                      _history,
                      WebUrl._URL_CREATE_OR_UPDATE_PRODUCT_GAME,
                      { id: row.original.id, isNew: false }
                    );
                  }}
                >
                  <span className="text-warning">{t("EDIT")}</span>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledButtonDropdown>
          </div>
        ),
      disableSortBy: true,
      disableFilters: true,
      width: 100,
      style: { overflow: "visible" },
    },
  ];

  /// <summary>
  /// Author : -
  /// </summary>
  function deleteGame(id) {
    _dispatch(setBusy());

    fetch(ApiUrl._API_DELETE_PRODUCT_GAME + "?id=" + id, {
      method: ApiKey._API_POST,
      headers: {
        "Content-Type": ApiKey._API_FORM_URLENCODED,
        "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        _dispatch(
          showMessage(
            responseJson[ApiKey._API_SUCCESS_KEY],
            t(responseJson[ApiKey._API_MESSAGE_KEY])
          )
        );
        _dispatch(setIdle());

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
          readGame();
        }
      });
  }

  /// <summary>
  /// Author : -
  /// </summary>
  useEffect(() => {
    readGame();
  }, []);

  /// <summary>
  /// Author : -
  /// </summary>
  function readGame() {
    fetch(
      ApiUrl._API_GET_PRODUCT_GAME + "?productId=" + _location.state["id"],
      {
        method: ApiKey._API_GET,
        headers: {
          "Content-Type": ApiKey._API_FORM_URLENCODED,
          "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
        },
      }
    )
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
          setGameData(responseJson[ApiKey._API_DATA_KEY]);
        }
      });
  }

  return (
    <div>
      <h1 className="page-header">
        {t("GAME")}
        {checkIfPermissionExist(
          AccessRight._MASTER_PRODUCT_CONTROL_PERMISSION,
          true
        ) && (
          <button
            type="button"
            className="btn btn-primary"
            style={{ float: "right" }}
            onClick={() => {
              _history.push({
                pathname: WebUrl._URL_CREATE_OR_UPDATE_PRODUCT_GAME,
                state: { id: _location.state["id"], isNew: true },
              });
            }}
          >
            <i className="fa fa-plus-circle m-r-10"></i>
            {t("ADD_NEW_GAME")}
          </button>
        )}
      </h1>
      <Panel>
        <PanelBody>
          <ReactTable filterable data={gameData} columns={_tableColumns} />
        </PanelBody>
      </Panel>
    </div>
  );
};

export default ManageGame;
