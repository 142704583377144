import React, { useState, useEffect } from 'react';
import ReactTable from '../../components/constantComponent/reactTable/ReactTable';
import { Panel, PanelBody } from './../../components/panel/panel.jsx';
import ApiEngine from '../../util/ApiEngine';
import { ApiKey, ApiUrl, LanguageKey } from '../../util/Constant';
import { setBusy, setIdle, showMessage } from "../../redux/AppAction";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { createFormBody } from "../../util/Util";
import { withRouter } from "react-router-dom";

const TransactionQueue = props => {
    const { t } = useTranslation(LanguageKey._PRIMARY);
    const _dispatch = useDispatch();
    const [queueData, setQueueData] = useState([]);

    const _defaultTableColumns = [
        {
            Header: t("ID"),
            accessor: "id",
        },
        {
            Header: t("MEMBER_ID"),
            accessor: "memberId",
        },
        {
            Header: t("MEMBER_USERNAME"),
            accessor: "memberUsername",
        },
        {
            Header: t("WALLET_ID"),
            accessor: "walletId",
        },
        {
            Header: t("CREATED_TIME"),
            accessor: "createdTime",
        },
        {
            Header: "",
            id: "button",
            Cell: ({ row }) => {
                return (
                    <button type="button" className="btn btn-primary" onClick={() => removePlayerFromQueue(row.original)}>{t("REMOVE_FROM_QUEUE")}</button>
                );
            },
        },
    ];

    /// <summary>
    /// Author : -
    /// </summary>
    useEffect(() => {
        init();
    }, []);

    const init = async () => {
        var responseJson = await ApiEngine.get(ApiUrl._API_GET_TRANSACTION_QUEUE);
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            setQueueData(responseJson[ApiKey._API_DATA_KEY]);
        }
    }

    const removePlayerFromQueue = async (rowData) => {
        _dispatch(setBusy());
        var params = {
            "memberId": rowData.memberId,
            "walletId": rowData.walletId
        };
        var formBody = createFormBody(params);
        var responseJson = await ApiEngine.post(ApiUrl._API_REMOVE_PLAYER_FROM_TRANSACTION_QUEUE, formBody);
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                init();
        }
        _dispatch(showMessage(responseJson[ApiKey._API_SUCCESS_KEY], responseJson[ApiKey._API_MESSAGE_KEY]));
        _dispatch(setIdle());
    }

    return (
        <>
            <h1 className="page-header">{t("TRANSACTION_QUEUE") + " (" + t("PLAYERS_IN_QUEUE") + " >= 10 " + t("MINUTES") + ")"}</h1>
            <Panel>
                <PanelBody>
                    <ReactTable
                        data={queueData}
                        columns={_defaultTableColumns}
                    />
                </PanelBody>
            </Panel>
        </>
    )
}

export default withRouter(TransactionQueue);