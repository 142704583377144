import React, { useState, useEffect } from "react";
import { withRouter, useHistory } from "react-router-dom";
import NavigationButton from "../../components/constantComponent/button/NavigationButton";
import {
	ApiKey,
	ApiUrl,
	LanguageKey,
	ContentTypeString,
} from "../../util/Constant";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import { stringIsNullOrEmpty, createFormBody } from "../../util/Util";
import { setBusy, setIdle, showMessage } from "../../redux/AppAction";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { fetch } from "whatwg-fetch";
import {
	Panel,
	PanelHeader,
	PanelBody,
} from "../../components/panel/panel.jsx";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import classnames from "classnames";
import ManageConfigurationDailyCheckIn from "./ManageConfigurationDailyCheckIn";
import { AsyncPaginate } from "react-select-async-paginate";

/// <summary>
/// Author : -
/// </summary>
const DailyCheckInDetail = (props) => {
	const { t } = useTranslation(LanguageKey._PRIMARY);
	let _history = useHistory();
	const _DETAIL_TAB = "1";
	const _RATE_TAB = "2";
	const _location = useLocation();
	const _dispatch = useDispatch();

	const { register, unregister, handleSubmit, errors, setError } = useForm();

	const [dailyCheckInId, setDailyCheckInId] = useState("");
	const [title, setTitle] = useState("");
	const [status, setStatus] = useState(true);
	const [activeTab, setActiveTab] = useState(_DETAIL_TAB);
	const [isClone, setIsClone] = useState(false);
	const [errorTitle, setErrorTitle] = useState(false);
	/// <summary>
	/// Author : -
	/// </summary>
	useEffect(() => {
		init();
	}, []);

	const toggle = (tab) => {
		if (activeTab !== tab) {
			setActiveTab(tab);
		}
	};

	/// <summary>
	/// Author : -
	/// </summary>
	async function init() {
		if (_location.state) {
			if (_location.state.cloneId) {
				setIsClone(true);

				var apiUrl = ApiUrl._API_GET_DAILY_CHECK_IN_BY_ID;
				apiUrl += "?id=" + _location.state.cloneId;
				await fetch(apiUrl, {
					method: ApiKey._API_GET,
					headers: {
						"Content-Type": ApiKey._API_FORM_URLENCODED,
						"X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
					},
				})
					.then((response) => response.json())
					.then(async (responseJson) => {
						if (responseJson[ApiKey._API_SUCCESS_KEY]) {
							setDailyCheckInId(responseJson[ApiKey._API_DATA_KEY]["id"]);
							setStatus(responseJson[ApiKey._API_DATA_KEY]["status"]);
						}
					});
			} else {
				setIsClone(false);
				var apiUrl = ApiUrl._API_GET_DAILY_CHECK_IN_BY_ID;
				apiUrl += "?id=" + _location.state.refId;
				await fetch(apiUrl, {
					method: ApiKey._API_GET,
					headers: {
						"Content-Type": ApiKey._API_FORM_URLENCODED,
						"X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
					},
				})
					.then((response) => response.json())
					.then(async (responseJson) => {
						if (responseJson[ApiKey._API_SUCCESS_KEY]) {
							setDailyCheckInId(responseJson[ApiKey._API_DATA_KEY]["id"]);
							setTitle(responseJson[ApiKey._API_DATA_KEY]["title"]);
							setStatus(responseJson[ApiKey._API_DATA_KEY]["status"]);
						}
					});
			}
		}
	}

	/// <summary>
	/// Author : -
	/// </summary>
	const submitForm = async (data, e) => {
		if (!stringIsNullOrEmpty(title)) {
			let params = {
				title: title,
				status: data.status,
			};

			if (!stringIsNullOrEmpty(dailyCheckInId) && !isClone) {
				params["id"] = dailyCheckInId;
			}

			let formBody = createFormBody(params);
			_dispatch(setBusy());

			fetch(ApiUrl._API_CREATE_OR_UPDATE_DAILY_CHECK_IN, {
				method: ApiKey._API_POST,
				headers: {
					"Content-Type": ApiKey._API_FORM_URLENCODED,
					"X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
				},
				body: formBody,
			})
				.then((response) => response.json())
				.then((responseJson) => {
					_dispatch(setIdle());
					_dispatch(
						showMessage(
							responseJson[ApiKey._API_SUCCESS_KEY],
							t(responseJson[ApiKey._API_MESSAGE_KEY])
						)
					);

					if (responseJson[ApiKey._API_SUCCESS_KEY]) {
						setDailyCheckInId(responseJson[ApiKey._API_DATA_KEY]["id"]);

						_history.replace({
							pathname: _location.pathname,
							state: {
								id: responseJson[ApiKey._API_DATA_KEY]["id"],
							},
						});
					}
				});
		}
		else
		{
			setErrorTitle(true)
		}
	};

	async function getMonthList(search, loadOptions, { page }) {
		const result = Array.from({ length: 12 }, (e, i) => {
			return new Date(null, i + 1, null).toLocaleDateString("en", {
				month: "long",
			});
		});

		let formattedData = result.map((m, index) => {
			return { label: m, value: m };
		});

		return {
			options: formattedData,
			additional: {
				page: page + 1,
			},
		};
	}

	return (
		<div>
			<h1 className="page-header">
				{dailyCheckInId && !isClone
					? t("EDIT_DAILY_CHECK_IN")
					: t("ADD_DAILY_CHECK_IN")}
				<NavigationButton history={_history} />
			</h1>
			{dailyCheckInId && (
				<Nav tabs>
					<NavItem>
						<NavLink
							className={classnames({ active: activeTab === _DETAIL_TAB })}
							onClick={() => {
								toggle(_DETAIL_TAB);
							}}
						>
							{t("DETAIL")}
						</NavLink>
					</NavItem>
					<NavItem>
						<NavLink
							className={classnames({ active: activeTab === _RATE_TAB })}
							onClick={() => {
								toggle(_RATE_TAB);
							}}
						>
							{t("DAILY_CHECK_IN_RATE")}
						</NavLink>
					</NavItem>
				</Nav>
			)}
			<TabContent activeTab={activeTab}>
				<TabPane tabId={_DETAIL_TAB}>
					<div className="row">
						<div className="col-xl-12">
							<Panel>
								<PanelBody>
									<form onSubmit={handleSubmit(submitForm)}>
										<div className="row">
											<div className="col-lg-8">
												<div className="form-group row m-b-15">
													<label className="col-md-4 col-form-label">
														<b>{t("MONTHS")}</b>
													</label>
													<div className="col-md-7">
														{
															<AsyncPaginate
																placeholder={
																	!stringIsNullOrEmpty(title)
																		? title
																		: t("PLEASE_SELECT_MONTHS")
																}
																debounceTimeout={250}
																loadOptions={getMonthList}
																additional={{
																	page: 1,
																}}
																value={title}
																onChange={(e) => {
																	setTitle(e.value);
																}}
															/>
														}
														{errorTitle && (
															<div className="invalid-feedback">
																{t("PLEASE_SELECT_MONTHS")}
															</div>
														)}
													</div>
												</div>
											</div>
											<div className="col-lg-8">
												<div className="form-group row m-b-15">
													<label className="col-md-4 col-form-label">
														<b>{t("STATUS")}</b>
													</label>
													<div className="col-md-7">
														<div className="switcher">
															<input
																type="checkbox"
																name="status"
																id="status"
																onChange={(e) => setStatus(e.target.checked)}
																value={true}
																checked={status}
																ref={register}
															/>
															<label htmlFor="status"></label>
														</div>
													</div>
												</div>
											</div>
										</div>
										<hr />
										<div className="row">
											<div className="col-lg-3">
												<div className="form-group">
													<button type="submit" className="btn btn-primary">
														{t("SUBMIT")}
													</button>
												</div>
											</div>
										</div>
									</form>
								</PanelBody>
							</Panel>
						</div>
					</div>
				</TabPane>
				<TabPane tabId={_RATE_TAB}>
					{dailyCheckInId && (
						<div className="row">
							<div className="col-lg-12">
								<ManageConfigurationDailyCheckIn
									id={dailyCheckInId}
									type={ContentTypeString._DAILY_CHECK_IN}
								/>
							</div>
						</div>
					)}
				</TabPane>
			</TabContent>
		</div>
	);
};

export default withRouter(DailyCheckInDetail);
