import React, { useState } from "react";
import Select from "react-select";
import "react-datepicker/dist/react-datepicker.css";
import ReactTable from "../../components/constantComponent/reactTable/ReactTable";
import DateRangePicker from "react-bootstrap-daterangepicker";
import moment from "moment";
import { useTranslation } from "react-i18next";
import {
	ApiKey,
	ApiUrl,
	LanguageKey,
	_TRANSACTION_STATUS,
	_WALLET_GAME_TRANSACTION_TYPE,
  Transaction,
  WebUrl,
  AccessRight
} from "../../util/Constant";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { setBusy, setIdle, showMessage } from "../../redux/AppAction";
import { useDispatch } from "react-redux";
import ApiEngine from "../../util/ApiEngine.js";
import {
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { useHistory } from "react-router-dom";
import { navigateTo } from "../../util/Util";
import { checkIfPermissionExist } from "../../util/PermissionChecker";
import { Panel, PanelBody } from "./../../components/panel/panel.jsx";
/// <summary>
/// Author : -
/// </summary>

const BonusTracking = (props) => {
  let _history = useHistory();
	const { register, handleSubmit, errors, setValue, trigger } = useForm();
	const _dispatch = useDispatch();
	const { t } = useTranslation(LanguageKey._PRIMARY);
	const [startDate, setStartDate] = useState(
		moment().startOf("day").format("YYYY-MM-DD HH:mm:ss")
	);
	const [endDate, setEndDate] = useState(
		moment().endOf("day").format("YYYY-MM-DD HH:mm:ss")
	);
	const [memberId, setMemberId] = useState("");
  const [reportData, setReportData] = useState([]);
  const [usernameKeyword, setUsernameKeyword] = useState("");
  const [bankAccKeyword, setBankAccKeyword] = useState("");

  let _tableColumns = [
    {
      Header: "Bank Name",
      accessor: "bank.bankName",
      disableSortBy: true,
    },
    {
      Header: "Account Number",
      accessor: "accountNumber",
      disableSortBy: true,
    },
    {
      Header: "Account Holder Name",
      accessor: "accountHolderName",
      disableSortBy: true,
    },
    {
      Header: "STATUS",
      accessor: "status",
      Cell: ({ row }) => (
        <div className="btn-group m-r-5 m-b-5">
          {row.original.status == 1 ? (
            <span className="badge badge-secondary badge-green">
              {t("ACTIVE")}
            </span>
          ) : (
            <span className="badge badge-secondary badge-danger">
              {t("SUSPENDED")}
            </span>
          )}
        </div>
      ),
      disableSortBy: true,
      style: { overflow: "visible" },
    },

    {
      id: "action",
      Header: "",
      Cell: ({ row }) =>
        checkIfPermissionExist(
          AccessRight._MANAGE_MEMBER_BANK_ACCOUNT,
          true
        ) &&
        (
          <div className="btn-group m-r-5 m-b-5">
            <UncontrolledButtonDropdown>
              <DropdownToggle caret color="default">
                <i className="fas fa-cog"></i>
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem
                  onClick={() => {
                    navigateTo(
                      _history,
                      WebUrl._URL_MEMBER_BANK_ACCOUNT_DETAIL,
                      row.original
                    );
                  }}
                >
                  <span className="text-warning">{t("EDIT")}</span>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledButtonDropdown>
          </div>
        ),
      disableSortBy: true,
      disableFilters: true,
      width: 100,
      style: { overflow: "visible" },
    },
  ];

	const getMemberBankAccount = async () => {
		try {
      var apiUrl =
        ApiUrl._API_GET_MEMBER_BANK_ACCOUNT +
        "?username=" +
        usernameKeyword + "&bankAcc=" + bankAccKeyword;

			var responseJson = await ApiEngine.get(apiUrl);

			if (responseJson[ApiKey._API_SUCCESS_KEY]) {
				setReportData(responseJson[ApiKey._API_DATA_KEY]);
			} else {
				throw responseJson[ApiKey._API_MESSAGE_KEY];
			}
		} catch (error) {
			_dispatch(showMessage(false, t(error)));
		}
  };

  async function GetRelatedBankAccount() {
    getMemberBankAccount();
  }

	return (
    <div>
      <h1 className="page-header">
        {t("MANAGE_MEMBER_BANK_ACCOUNT") }
      </h1>
        <Panel>
				<div className="row">
          <div className="col-lg-12">
            <div className="row">
              <div className="col-lg-3" style={{ margin: "15px 0 0 15px" }}>
                <div className="form-group">
                  <label>
                    <b>{t("USERNAME")}</b>
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    onChange={(e) => {
                      setUsernameKeyword(e.target.value);
                    }}
                  />
                  <div>{t("KEY_KEYWORD_TO_CONTINUE")}</div>
                </div>
              </div>
              <div className="col-lg-3" style={{ margin: "15px 0 0 15px" }}>
                <div className="form-group">
                  <label>
                    <b>{t("ACCOUNT_NUMBER")}</b>
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    onChange={(e) => {
                      setBankAccKeyword(e.target.value);
                    }}
                  />
                </div>
              </div>
              {<div className="col-lg-2">
                <button className="btn btn-primary" style={{ marginLeft: "5px", marginTop: "40px", width: "100px" }} onClick={() => GetRelatedBankAccount()}>{t("SEARCH")}</button>
              </div>}
            </div>
            <PanelBody>
              <ReactTable
                data={reportData}
                columns={_tableColumns}
                filterable
              />
            </PanelBody>
          </div>
          </div>
        </Panel>
		</div>
	);
};

export default BonusTracking;
