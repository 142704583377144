import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import {
	Panel,
	PanelHeader,
	PanelBody,
} from "./../../components/panel/panel.jsx";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Dropzone from "react-dropzone";
import NavigationButton from "../../components/constantComponent/button/NavigationButton";
import {
	ApiKey,
	ApiUrl,
	LanguageKey,
	ContentTypeString,
} from "../../util/Constant";
import { stringIsNullOrEmpty, createMultiPartFormBody } from "../../util/Util";
import { useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { setBusy, setIdle, showMessage } from "../../redux/AppAction";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import ManageConfigurationLanguage from "./ManageConfigurationLanguage";
import {
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
	UncontrolledButtonDropdown,
	DropdownToggle,
	DropdownMenu,
	DropdownItem,
	TabContent,
	TabPane,
	Nav,
	NavItem,
	NavLink,
} from "reactstrap";
import classnames from "classnames";
import { fetch } from "whatwg-fetch";
import DateRangePicker from "react-bootstrap-daterangepicker";
import moment from "moment";
import ReactTable from "../../components/constantComponent/reactTable/ReactTable";
import SweetAlert from "react-bootstrap-sweetalert";

/// <summary>
/// Author : -
/// </summary>

const DepositPromotionDetail = (props) => {
	const _DETAIL_TAB = "1";
	const _LANGUAGE_TAB = "2";
	let _history = useHistory();
	const { t } = useTranslation(LanguageKey._PRIMARY);
	const _location = useLocation();
	const _dispatch = useDispatch();
	const { register, unregister, handleSubmit, errors, setValue } = useForm();

	const [title, setTitle] = useState("");
	const [content, setContent] = useState("");
	const [url, setUrl] = useState("");
	const [promotionId, setPromotionId] = useState("");
	const [remark, setRemark] = useState("");
	const [rank, setRank] = useState("");
	const [status, setStatus] = useState(true);
	const [activeTab, setActiveTab] = useState(_DETAIL_TAB);
	const [description, setDescription] = useState("");
	const [bonusRate, setBonusRate] = useState("");
	const [rolloverRate, setRolloverRate] = useState("");
	const [startDate, setStartDate] = useState(
		moment().startOf("day").format("YYYY-MM-DD HH:mm:ss")
	);
	const [endDate, setEndDate] = useState(
		moment().endOf("day").format("YYYY-MM-DD HH:mm:ss")
	);
	//const [maxUsage, setMaxUsage] = useState("");
	const [minDeposit, setMinDeposit] = useState("");
	const [bonusCap, setBonusCap] = useState("");
	const [redemption, setRedemption] = useState("");

	const [depositPromotionProductData, setDepositPromotionProductData] =
		useState([]);

	const {
		register: accountFormRegister,
		handleSubmit: accountFormHandleSubmit,
		errors: accountFormErrors,
		unregister: accountFormUnregister,
		setValue: accountFormSetValue,
	} = useForm();

	const {
		register: promotionFormRegister,
		handleSubmit: promotionFormHandleSubmit,
		errors: promotionFormErrors,
		unregister: promotionFormUnregister,
		setValue: promotionFormSetValue,
	} = useForm();

	const [vipList, setVipList] = useState([]);

	const [productData, setProductData] = useState([]);
	const [accountProductId, setAccountProductId] = useState("");
	const [accountProductStatus, setAccountProductStatus] = useState(true);
	const [accountProductModel, setAccountProductModel] = useState(false);
	const [accountproductName, setAccountProductName] = useState("");
	const [selectedMasterProductId, setSelectedMasterProductId] = useState("");
	const [isCreateNewAccount, setIsCreateNewAccount] = useState(true);
	const [depositPromotionProductId, setDepositPromotionProductId] =
		useState("");
	const [desktopImage, setDesktopImage] = useState();
	const [mobileImage, setMobileImage] = useState();
	const [desktopFile, setDesktopFile] = useState([]);
	const [mobileFile, setMobileFile] = useState([]);
	const [isNewDesktopImage, setIsNewDesktopImage] = useState(false);
	const [isNewMobileImage, setIsNewMobileImage] = useState(false);
	const [isBonusFixed, setIsBonusFixed] = useState(false);
	const [isClaimable, setIsClaimable] = useState(false);
	const [productCategoryData, setProductCategoryData] = useState([]);
	const [selectedProductCategoryId, setSelectedProductCategoryId] = useState(0);
	const [isMaxUsagePerDay, setIsMaxUsagePerDay] = useState(false);
	const [showConfirmation, setShowConfirmation] = useState(false);
	const [showRolloverConfirmation, setShowRolloverConfirmation] = useState(false);
	const [minBonusWalletTarget, setMinBonusWalletTarget] = useState("");
	const [maxWithdraw, setMaxWithdraw] = useState("");
	const [isRolloverFixed, setIsRolloverFixed] = useState(false);

	let _tableColumns = [
		{
			Header: "Product",
			accessor: "name",
			disableSortBy: true,
		},
		{
			Header: "STATUS",
			accessor: "status",
			Cell: ({ row }) => (
				<div className="btn-group m-r-5 m-b-5">
					{row.original.status == true ? (
						<span className="badge badge-secondary badge-green">
							{t("ACTIVE")}
						</span>
					) : (
						<span className="badge badge-secondary badge-danger">
							{t("SUSPENDED")}
						</span>
					)}
				</div>
			),
			disableSortBy: true,
			style: { overflow: "visible" },
		},
		{
			id: "action",
			Header: "",
			Cell: ({ row }) => (
				<div className="btn-group m-r-5 m-b-5">
					<UncontrolledButtonDropdown>
						<DropdownToggle caret color="default">
							<i className="fas fa-cog"></i>
						</DropdownToggle>
						<DropdownMenu>
							<DropdownItem onClick={() => loadAccountProduct(row.original)}>
								{t("EDIT")}
							</DropdownItem>
							<DropdownItem onClick={() => deleteBankSetting(row.original.id)}>
								<span className="text-warning">{t("DELETE")}</span>
							</DropdownItem>
						</DropdownMenu>
					</UncontrolledButtonDropdown>
				</div>
			),
			disableSortBy: true,
			disableFilters: true,
			width: 100,
			style: { overflow: "visible" },
		},
	];

	const [selectedViplevel, setSelectedViplevel] = useState("");
	/// <summary>
	/// Author : -
	/// </summary>
	const toggle = (tab) => {
		if (activeTab !== tab) {
			setActiveTab(tab);
		}
	};

	/// <summary>
	/// Author : -
	/// </summary>
	useEffect(() => {
		register({ name: "content" }, { required: "PLEASE_ENTER_CONTENT" });
		register({ name: "vip" }, { required: "PLEASE_SELECT_VIP_LEVEL" });
		init();
	}, []);

	const _OPTION_STYLES = {
		control: (base) => ({
			...base,
			"min-height": "34px",
			height: "34px",
		}),
		valueContainer: (base) => ({
			...base,
			height: "34px",
			"min-height": "34px",
		}),
		indicatorsContainer: (base) => ({
			...base,
			height: "34px",
			"min-height": "34px",
		}),
		input: (base) => ({
			height: "34px",
			"min-height": "34px",
		}),
	};

	/// <summary>
	/// Author : -
	/// </summary>
	async function init() {
		if (_location.state) {
			setPromotionId(_location.state.id);
			var apiUrl = ApiUrl._API_GET_DEPOSIT_PROMOTION_BY_ID;
			apiUrl += "?id=" + _location.state.id;
			await fetch(apiUrl, {
				method: ApiKey._API_GET,
				headers: {
					"Content-Type": ApiKey._API_FORM_URLENCODED,
					"X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
				},
			})
				.then((response) => response.json())
				.then(async (responseJson) => {
					if (responseJson[ApiKey._API_SUCCESS_KEY]) {
						setPromotionId(responseJson[ApiKey._API_DATA_KEY][0]["id"]);
						setContent(responseJson[ApiKey._API_DATA_KEY][0]["content"]);
						setTitle(responseJson[ApiKey._API_DATA_KEY][0]["name"]);
						setRemark(responseJson[ApiKey._API_DATA_KEY][0]["remark"]);
						setRank(responseJson[ApiKey._API_DATA_KEY][0]["rank"]);
						setBonusRate(
							parseInt(responseJson[ApiKey._API_DATA_KEY][0]["bonusRate"])
						);
						setBonusCap(
							parseInt(responseJson[ApiKey._API_DATA_KEY][0]["bonusCap"])
						);
						setDescription(
							responseJson[ApiKey._API_DATA_KEY][0]["description"]
						);
						setStartDate(
							moment(responseJson[ApiKey._API_DATA_KEY][0]["startDate"]).format(
								"YYYY-MM-DD HH:mm:ss"
							)
						);
						setEndDate(
							moment(responseJson[ApiKey._API_DATA_KEY][0]["endDate"]).format(
								"YYYY-MM-DD HH:mm:ss"
							)
						);
						setRedemption(
							parseInt(responseJson[ApiKey._API_DATA_KEY][0]["maxUsage"])
						);
						setMinDeposit(
							parseInt(responseJson[ApiKey._API_DATA_KEY][0]["minDeposit"])
						);
						setSelectedViplevel(
							parseInt(responseJson[ApiKey._API_DATA_KEY][0]["minVipLevel"])
						);
						setRolloverRate(
							parseInt(responseJson[ApiKey._API_DATA_KEY][0]["rolloverRate"])
						);
						setStatus(responseJson[ApiKey._API_DATA_KEY][0]["status"]);

						setDesktopImage(
							responseJson[ApiKey._API_DATA_KEY][0]["backgroundImage"]
						);
						setIsBonusFixed(responseJson[ApiKey._API_DATA_KEY][0]["isBonusFixed"])
						setSelectedProductCategoryId(responseJson[ApiKey._API_DATA_KEY][0]["productCategoryId"])
						setIsClaimable(responseJson[ApiKey._API_DATA_KEY][0]["isClaimable"])
						setIsMaxUsagePerDay(responseJson[ApiKey._API_DATA_KEY][0]["isMaxUsagePerDay"])
						setMinBonusWalletTarget(responseJson[ApiKey._API_DATA_KEY][0]["mincoinTarget"])
						setMaxWithdraw(responseJson[ApiKey._API_DATA_KEY][0]["maxWithdraw"])
						setIsRolloverFixed(
							responseJson[ApiKey._API_DATA_KEY][0]["isRolloverFixed"]
						);
						unregister("vip");
						unregister("content");
					}
				});
			getMasterProduct();
			getDepositPromotionProduct();
		}

		await fetch(ApiUrl._API_GET_USER_VIP + "?all=true", {
			method: ApiKey._API_GET,
			headers: {
				"Content-Type": ApiKey._API_FORM_URLENCODED,
				"X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
			},
		})
			.then((response) => response.json())
			.then((responseJson) => {
				if (responseJson[ApiKey._API_SUCCESS_KEY]) {
					const VipList = [];
					responseJson[ApiKey._API_DATA_KEY].forEach(function (vip) {
						VipList.push({ label: vip.name, value: vip.id });
					});
					setVipList(VipList);
				}
			});
		await fetch(ApiUrl._API_GET_PRODUCT_CATEGORY + "?all=true", {
			method: ApiKey._API_GET,
			headers: {
				"Content-Type": ApiKey._API_FORM_URLENCODED,
				"X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
			},
		})
			.then((response) => response.json())
			.then((responseJson) => {
				if (responseJson[ApiKey._API_SUCCESS_KEY]) {
					setProductCategoryData(responseJson[ApiKey._API_DATA_KEY]);
					const categoryList = [];
					responseJson[ApiKey._API_DATA_KEY].forEach(function (category) {
						categoryList.push({ label: category.title, value: category.id });
					});
					setProductCategoryData(categoryList);
				}
			});
	}

	/// <summary>
	/// Author : -
	/// </summary>
	const submitForm = async (data, e) => {
		let params = {
			name: data.title,
			description: data.description,
			bonusRate: data.bonusRate,
			rolloverRate: data.rolloverRate,
			startDate: startDate,
			endDate: endDate,
			//maxUsage: data.maxUsage,
			minVipLevel: selectedViplevel,
			minDeposit: data.minDeposit,
			bonusCap: data.bonusCap,
			remark: data.remark,
			rank: data.rank,
			status: data.status,
			content: content,
			isNewDesktopFile: isNewDesktopImage,
			isNewMobileFile: isNewMobileImage,
			maxUsage: data.redemption,
			isBonusFixed: data.isBonusFixed,
			productCategoryId: selectedProductCategoryId,
			isClaimable: data.isClaimable,
			isMaxUsagePerDay: data.isMaxUsagePerDay,
			mincoinTarget: data.minBonusWalletTarget,
			maxWithdraw: data.maxWithdraw,
			isRolloverFixed: data.isRolloverFixed,
		};

		if (!stringIsNullOrEmpty(desktopFile)) {
			params["desktopFile"] = desktopFile;
		}

		if (stringIsNullOrEmpty(desktopImage)) {
			params["desktopImageRemoved"] = true;
		}

		if (!stringIsNullOrEmpty(mobileFile)) {
			params["mobileFile"] = mobileFile;
		}

		if (stringIsNullOrEmpty(mobileImage)) {
			params["mobileImageRemoved"] = true;
		}

		if (!stringIsNullOrEmpty(promotionId)) {
			params["id"] = promotionId;
		}

		let formBody = createMultiPartFormBody(params);
		_dispatch(setBusy());

		fetch(ApiUrl._API_CREATE_OR_UPDATE_DEPOSIT_PROMOTION, {
			method: ApiKey._API_POST,
			headers: {
				"X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
			},
			body: formBody,
		})
			.then((response) => response.json())
			.then((responseJson) => {
				_dispatch(setIdle());
				_dispatch(
					showMessage(
						responseJson[ApiKey._API_SUCCESS_KEY],
						t(responseJson[ApiKey._API_MESSAGE_KEY])
					)
				);

				if (responseJson[ApiKey._API_SUCCESS_KEY]) {
					setPromotionId(responseJson[ApiKey._API_DATA_KEY]["id"]);
					getMasterProduct();
				}
			});
	};

	const handleEvent = (event, picker) => {
		setStartDate(moment(picker.startDate).format("YYYY-MM-DD HH:mm:ss"));
		setEndDate(moment(picker.endDate).format("YYYY-MM-DD HH:mm:ss"));
	};

	function getMasterProduct() {
		fetch(ApiUrl._API_GET_MASTER_PRODUCT_ID_NAME_BY_USER_ID, {
			method: ApiKey._API_GET,
			headers: {
				"Content-Type": ApiKey._API_FORM_URLENCODED,
				"X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
			},
		})
			.then((response) => response.json())
			.then((responseJson) => {
				if (responseJson[ApiKey._API_SUCCESS_KEY]) {
					const ProductList = [];
					responseJson[ApiKey._API_DATA_KEY].forEach(function (product) {
						ProductList.push({
							label: product.name,
							value: product.id,
							masterProductId: product.masterProductId,
						});
					});
					setProductData(ProductList);
				}
			});
	}

	function toggleModal(isAddProduct) {
		if (accountProductModel) {
			setDepositPromotionProductId("");
			setSelectedMasterProductId("");
			setAccountProductStatus(false);
		}

		if (isAddProduct) {
			setIsCreateNewAccount(true);
		}

		setAccountProductModel(!accountProductModel);
	}

	function getDepositPromotionProduct() {
		fetch(
			ApiUrl._API_GET_DEPOSIT_PROMOTION_PRODUCT +
				"?id=" +
				(promotionId == "" ? _location.state.id : promotionId),
			{
				method: ApiKey._API_GET,
				headers: {
					"X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
				},
			}
		)
			.then((response) => response.json())
			.then((responseJson) => {
				if (responseJson[ApiKey._API_SUCCESS_KEY]) {
					setDepositPromotionProductData(responseJson[ApiKey._API_DATA_KEY]);
				}
			});
	}

	function loadAccountProduct(data) {
		setDepositPromotionProductId(data.id);
		setAccountProductId(data.accountProductId);
		setAccountProductStatus(data.status);
		setAccountProductName(data.name);
		setSelectedMasterProductId(data.masterProductId);
		setIsCreateNewAccount(false);
		toggleModal();
	}

	function deleteBankSetting(id) {
		_dispatch(setBusy());

		fetch(ApiUrl._API_DELETE_DEPOSIT_PROMOTION_PRODUCT + "?id=" + id, {
			method: ApiKey._API_POST,
			headers: {
				"X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
			},
		})
			.then((response) => response.json())
			.then((responseJson) => {
				_dispatch(setIdle());
				_dispatch(
					showMessage(
						responseJson[ApiKey._API_SUCCESS_KEY],
						t(responseJson[ApiKey._API_MESSAGE_KEY])
					)
				);

				if (responseJson[ApiKey._API_SUCCESS_KEY]) {
					getDepositPromotionProduct();
				}
			});
	}

	const submitAccountSettingForm = async (data, e) => {
		let params = {
			depositPromotionId: promotionId,
			accountProductId: accountProductId,
			status: accountProductStatus,
		};

		if (!stringIsNullOrEmpty(depositPromotionProductId)) {
			params["id"] = depositPromotionProductId;
		}
		let formBody = createMultiPartFormBody(params);
		_dispatch(setBusy());

		fetch(ApiUrl._API_CREATE_OR_UPDATE_DEPOSIT_PROMOTION_PRODUCT, {
			method: ApiKey._API_POST,
			headers: {
				"X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
			},
			body: formBody,
		})
			.then((response) => response.json())
			.then((responseJson) => {
				_dispatch(setIdle());
				_dispatch(
					showMessage(
						responseJson[ApiKey._API_SUCCESS_KEY],
						t(responseJson[ApiKey._API_MESSAGE_KEY])
					)
				);

				if (responseJson[ApiKey._API_SUCCESS_KEY]) {
					getDepositPromotionProduct();
					setAccountProductId("");
					setAccountProductName("");
					setAccountProductStatus("");
					toggleModal();
				}
			});
	};

	const logoDrop = (acceptedFiles, isDesktop) => {
		if (acceptedFiles.length !== 0) {
			const file = acceptedFiles[0];

			if (isDesktop) {
				setDesktopFile(file);
			} else {
				setMobileFile(file);
			}

			const reader = new FileReader();
			reader.onloadend = () => {
				if (isDesktop) {
					setDesktopImage(reader.result);
					setIsNewDesktopImage(true);
				} else {
					setIsNewMobileImage(true);
					setMobileImage(reader.result);
				}
			};
			reader.readAsDataURL(file);
		}
	};

	return (
		<div>
			{showConfirmation && (
				<SweetAlert
					warning
					showCancel
					confirmBtnText={t("CONFIRM")}
					cancelBtnText={t("CANCEL")}
					confirmBtnBsStyle="danger"
					title={isBonusFixed ? t("BONUS_WILL_BE_CALCULATED_BY_PERCENTAGE") : t("BONUS_WILL_NOT_BE_CALCULATED_BY_PERCENTAGE")  }
					onConfirm={() => {
						setShowConfirmation(false);
						setIsBonusFixed(!isBonusFixed);
					}}
					onCancel={() => {
						setShowConfirmation(false);
					}}
				>
				</SweetAlert>
			)}
			{showRolloverConfirmation && (
				<SweetAlert
					warning
					showCancel
					confirmBtnText={t("CONFIRM")}
					cancelBtnText={t("CANCEL")}
					confirmBtnBsStyle="danger"
					title={
						isBonusFixed
							? t("ROLLOVER_WILL_BE_CALCULATED_BY_PERCENTAGE")
							: t("ROLLOVER_WILL_NOT_BE_CALCULATED_BY_PERCENTAGE")
					}
					onConfirm={() => {
						setShowRolloverConfirmation(false);
						setIsRolloverFixed(!isRolloverFixed);
					}}
					onCancel={() => {
						setShowRolloverConfirmation(false);
					}}
				></SweetAlert>
			)}
			<h1 className="page-header">
				{promotionId ? t("EDIT_DEPOSIT_PROMOTION") : t("ADD_DEPOSIT_PROMOTION")}
				<NavigationButton history={_history} />
			</h1>
			{promotionId && (
				<Nav tabs>
					<NavItem>
						<NavLink
							className={classnames({ active: activeTab === _DETAIL_TAB })}
							onClick={() => {
								toggle(_DETAIL_TAB);
							}}
						>
							{t("DETAIL")}
						</NavLink>
					</NavItem>
					<NavItem>
						<NavLink
							className={classnames({ active: activeTab === _LANGUAGE_TAB })}
							onClick={() => {
								toggle(_LANGUAGE_TAB);
							}}
						>
							{t("LANGUAGE")}
						</NavLink>
					</NavItem>
				</Nav>
			)}
			<TabContent activeTab={activeTab}>
				<TabPane tabId={_DETAIL_TAB}>
					<div className="row">
						<div className="col-xl-12">
							<Panel>
								<PanelBody>
									<form onSubmit={promotionFormHandleSubmit(submitForm)}>
										<div className="row">
											<div className="col-lg-8">
												<div className="form-group row m-b-15">
													<label className="col-md-4 col-form-label">
														<b>{t("TITLE")}</b>
													</label>
													<div className="col-md-7">
														<input
															type="text"
															className="form-control"
															placeholder={t("PLEASE_ENTER_TITLE")}
															defaultValue={title}
															ref={promotionFormRegister({
																required: "PLEASE_ENTER_TITLE",
															})}
															name="title"
															rows="5"
															style={{ marginTop: "10px" }}
														/>
														{errors.text && (
															<div className="invalid-feedback">
																{t(errors.text.message)}
															</div>
														)}
													</div>
												</div>
											</div>
											<div className="col-lg-8">
												<div className="form-group row m-b-15">
													<label className="col-md-4 col-form-label">
														<b> {isBonusFixed ? t("BONUS_AMOUNT"):t("BONUS_RATE")}</b>
													</label>
													<div className="col-md-7">
														<input
															type="text"
															className="form-control"
															rows="5"
															style={{ marginTop: "10px" }}
															ref={promotionFormRegister({
																required: "PLEASE_ENTER_BONUS_RATE",
															})}
															name="bonusRate"
															defaultValue={bonusRate}
														/>
														{errors.text && (
															<div className="invalid-feedback">
																{t(errors.text.message)}
															</div>
														)}
													</div>
												</div>
											</div>
											<div className="col-lg-8">
												<div className="form-group row m-b-15">
													<label className="col-md-4 col-form-label">
														<b>{" "}
															{isRolloverFixed
																? t("ROLLOVER_AMOUNT")
																: t("ROLLOVER_RATE")}
														</b>
													</label>
													<div className="col-md-7">
														<input
															type="text"
															className="form-control"
															rows="5"
															style={{ marginTop: "10px" }}
															ref={promotionFormRegister({
																required: "PLEASE_ENTER_ROLLOVER_RATE",
															})}
															name="rolloverRate"
															defaultValue={rolloverRate}
														/>
														{errors.text && (
															<div className="invalid-feedback">
																{t(errors.text.message)}
															</div>
														)}
													</div>
												</div>
											</div>
											<div className="col-lg-8">
												<div className="form-group row m-b-15">
													<label className="col-md-4 col-form-label">
														<b>{t("DATE")}</b>
													</label>
													<div className="col-md-7">
														<DateRangePicker
															containerStyles={{ width: "100%" }}
															startDate={startDate}
															endDate={endDate}
															onApply={handleEvent}
															alwaysShowCalendars={true}
															locale={{ format: "YYYY-MM-DD HH:mm:ss" }}
															timePicker={true}
															timePickerSeconds={true}
															ranges={{
																Today: [
																	moment().startOf("day"),
																	moment().endOf("day"),
																],
																Yesterday: [
																	moment().subtract(1, "days").startOf("day"),
																	moment().subtract(1, "days").endOf("day"),
																],
																"Last 7 Days": [
																	moment().subtract(6, "days").startOf("day"),
																	moment().endOf("day"),
																],
																"Last 30 Days": [
																	moment().subtract(29, "days").startOf("day"),
																	moment().endOf("day"),
																],
																"This Month": [
																	moment().startOf("month"),
																	moment().endOf("month"),
																],
																"Last Month": [
																	moment()
																		.subtract(1, "month")
																		.startOf("month"),
																	moment().subtract(1, "month").endOf("month"),
																],
																"This Year": [
																	moment().startOf("year"),
																	moment().endOf("year"),
																],
															}}
														>
															<input
																type="text"
																className="form-control"
																value={startDate + " - " + endDate}
															/>
														</DateRangePicker>
													</div>
												</div>
											</div>
											<div className="col-lg-8">
												<div className="form-group row m-b-15">
													<label className="col-md-4 col-form-label">
														<b>{t("MIN_VIP_LEVEL")}</b>
													</label>
													<div className="col-md-7">
														<Select
															styles={_OPTION_STYLES}
															value={vipList.filter(
																(vip) => vip.value == selectedViplevel
															)}
															options={vipList}
															onChange={(e) => {
																setSelectedViplevel(e.value);
															}}
														/>
														{errors.text && (
															<div className="invalid-feedback">
																{t(errors.text.message)}
															</div>
														)}
													</div>
												</div>
											</div>
											<div className="col-lg-8">
												<div className="form-group row m-b-15">
													<label className="col-md-4 col-form-label">
														<b>{t("MIN_DEPOSIT")}</b>
													</label>
													<div className="col-md-7">
														<input
															type="text"
															className="form-control"
															rows="5"
															style={{ marginTop: "10px" }}
															ref={promotionFormRegister({
																required: "PLEASE_ENTER_ROLLOVER_RATE",
															})}
															name="minDeposit"
															defaultValue={minDeposit}
														/>
														{errors.text && (
															<div className="invalid-feedback">
																{t(errors.text.message)}
															</div>
														)}
													</div>
												</div>
											</div>
											<div className="col-lg-8">
												<div className="form-group row m-b-15">
													<label className="col-md-4 col-form-label">
														<b>{t("MIN_BONUS_WALLET_TARGET")}</b>
													</label>
													<div className="col-md-7">
														<input
															type="text"
															className="form-control"
															rows="5"
															style={{ marginTop: "10px" }}
															ref={promotionFormRegister({
																required: "PLEASE_ENTER_MIN_BONUS_WALLET_TARGET",
															})}
															name="minBonusWalletTarget"
															defaultValue={minBonusWalletTarget}
														/>
														{promotionFormErrors.minBonusWalletTarget && (
															<div className="invalid-feedback">
																{t(promotionFormErrors.minBonusWalletTarget.message)}
															</div>
														)}
													</div>
												</div>
											</div>
											<div className="col-lg-8">
												<div className="form-group row m-b-15">
													<label className="col-md-4 col-form-label">
														<b>{t("MAX_WITHDRAW")}</b>
													</label>
													<div className="col-md-7">
														<input
															type="text"
															className="form-control"
															rows="5"
															style={{ marginTop: "10px" }}
															ref={promotionFormRegister({
																required: "PLEASE_ENTER_MAX_WITHDRAW",
															})}
															name="maxWithdraw"
															defaultValue={maxWithdraw}
														/>
														{promotionFormErrors.maxWithdraw && (
															<div className="invalid-feedback">
																{t(promotionFormErrors.maxWithdraw.message)}
															</div>
														)}
													</div>
												</div>
											</div>

											<div className="col-lg-8">
												<div className="form-group row m-b-15">
													<label className="col-md-4 col-form-label">
														<b>{t("BONUS_CAP")}</b>
													</label>
													<div className="col-md-7">
														<input
															type="text"
															className="form-control"
															rows="5"
															style={{ marginTop: "10px" }}
															ref={promotionFormRegister({
																required: "PLEASE_ENTER_ROLLOVER_RATE",
															})}
															name="bonusCap"
															defaultValue={bonusCap}
														/>
														{errors.text && (
															<div className="invalid-feedback">
																{t(errors.text.message)}
															</div>
														)}
													</div>
												</div>
											</div>
											<div className="col-lg-8">
												<div className="form-group row m-b-15">
													<label className="col-md-4 col-form-label">
														<b>{t("REDEMPTION_PER_USER")}</b>
													</label>
													<div className="col-md-7">
														<input
															type="text"
															className="form-control"
															rows="5"
															style={{ marginTop: "10px" }}
															ref={promotionFormRegister({
																required: "PLEASE_ENTER_REDEMPTION_PER_USER",
															})}
															name="redemption"
															defaultValue={redemption}
														/>
														<div className="invalid-feedback">
															{t("ZERO_EQUAL_UNLIMITED")}
														</div>
														{errors.text && (
															<div className="invalid-feedback">
																{t(errors.text.message)}
															</div>
														)}
													</div>
												</div>
											</div>
											<div className="col-lg-8">
												<div className="form-group row m-b-15">
													<label className="col-md-4 col-form-label">
														<b>{t("REMARK")}</b>
													</label>
													<div className="col-md-7">
														<input
															type="text"
															className="form-control"
															placeholder={t("PLEASE_ENTER_REMARK")}
															defaultValue={remark}
															ref={promotionFormRegister({
																required: "PLEASE_ENTER_REMARK",
															})}
															name="remark"
															style={{ marginTop: "10px" }}
														/>
														{errors.remark && (
															<div className="invalid-feedback">
																{t(errors.remark.message)}
															</div>
														)}
													</div>
												</div>
											</div>
											<div className="col-lg-8">
												<div className="form-group row m-b-15">
													<label className="col-md-4 col-form-label">
														<b>{t("RANK")}</b>
													</label>
													<div className="col-md-7">
														<input
															type="number"
															className="form-control"
															placeholder={t("PLEASE_ENTER_RANK")}
															defaultValue={rank}
															ref={promotionFormRegister({
																required: "PLEASE_ENTER_RANK",
																min: {
																	value: 0,
																	message:
																		"PLEASE_ENTER_VALUE_NOT_LESS_THAN_ZERO",
																},
																max: {
																	value: 100,
																	message:
																		"PLEASE_ENTER_VALUE_NOT_MORE_THAN_100",
																},
															})}
															name="rank"
															style={{ marginTop: "10px" }}
														/>
														{errors.rank && (
															<div className="invalid-feedback">
																{t(errors.rank.message)}
															</div>
														)}
													</div>
												</div>
											</div>
											<div className="col-lg-8">
												<div className="form-group row m-b-15">
													<label className="col-md-4 col-form-label">
														<b>{t("STATUS")}</b>
													</label>
													<div className="col-md-7">
														<div className="switcher">
															<input
																type="checkbox"
																name="status"
																id="status"
																onChange={(e) => setStatus(e.target.checked)}
																value={true}
																checked={status}
																ref={promotionFormRegister}
															/>
															<label htmlFor="status"></label>
														</div>
													</div>
												</div>
											</div>
											<div className="col-lg-8">
												<div className="form-group row m-b-15">
													<label className="col-md-4 col-form-label">
														<b>{t("IS_FIXED_BONUS")}</b><br/>
														<small>{t("NOT_CAL_BY_PERCENT")}</small>
													</label>
													<div className="col-md-7">
														<div className="switcher">
															<input
																type="checkbox"
																name="isBonusFixed"
																id="isBonusFixed"
																onChange={(e) =>  setShowConfirmation(true)}
																value={true}
																checked={isBonusFixed}
																ref={promotionFormRegister}
															/>
															<label htmlFor="isBonusFixed"></label>
														</div>
													</div>
												</div>
											</div>
											<div className="col-lg-8">
												<div className="form-group row m-b-15">
													<label className="col-md-4 col-form-label">
														<b>{t("IS_FIXED_ROLLOVER")}</b><br />
														<small>{t("NOT_CAL_BY_PERCENT")}</small>
													</label>
													<div className="col-md-7">
														<div className="switcher">
															<input
																type="checkbox"
																name="isRolloverFixed"
																id="isRolloverFixed"
																onChange={(e) => setShowRolloverConfirmation(true)}
																value={true}
																checked={isRolloverFixed}
																ref={promotionFormRegister}
															/>
															<label htmlFor="isRolloverFixed"></label>
														</div>
													</div>
												</div>
											</div>
											<div className="col-lg-8">
												<div className="form-group row m-b-15">
													<label className="col-md-4 col-form-label">
														<b>{t("IS_CLAIMABLE")}</b>
													</label>
													<div className="col-md-7">
														<div className="switcher">
															<input
																type="checkbox"
																name="isClaimable"
																id="isClaimable"
																onChange={(e) => setIsClaimable(e.target.checked)}
																value={true}
																checked={isClaimable}
																ref={promotionFormRegister}
															/>
															<label htmlFor="isClaimable"></label>
														</div>
													</div>
												</div>
											</div>
											<div className="col-lg-8">
												<div className="form-group row m-b-15">
													<label className="col-md-4 col-form-label">
														<b>{t("IS_MAX_USAGE_PER_DAY")}</b>
													</label>
													<div className="col-md-7">
														<div className="switcher">
															<input
																type="checkbox"
																name="isMaxUsagePerDay"
																id="isMaxUsagePerDay"
																onChange={(e) => setIsMaxUsagePerDay(e.target.checked)}
																value={true}
																checked={isMaxUsagePerDay}
																ref={promotionFormRegister}
															/>
															<label htmlFor="isMaxUsagePerDay"></label>
														</div>
													</div>
												</div>
											</div>
											<div className="col-lg-8">
												<div className="form-group row m-b-15">
													<label className="col-md-4 col-form-label">
														<b>{t("PRODUCT_CATEGORY")}</b><br />
														<small>{t("ALL_EQUAL_SPECIFIC_PROVIDER")}</small>
													</label>
													<div className="col-md-7">
														<Select
															styles={_OPTION_STYLES}
															value={productCategoryData.filter(
																(category) => category.value == selectedProductCategoryId
															)}
															options={productCategoryData}
															onChange={(e) => {
																setSelectedProductCategoryId(e.value);
															}}
														/>
														{errors.text && (
															<div className="invalid-feedback">
																{t(errors.text.message)}
															</div>
														)}
													</div>
												</div>
											</div>
										</div>
										<hr />
										<div className="row">
											<div className="col-lg-3">
												<div className="form-group">
													<button type="submit" className="btn btn-primary">
														{t("SUBMIT")}
													</button>
												</div>
											</div>
										</div>
									</form>
								</PanelBody>
							</Panel>
						</div>
					</div>
					{!stringIsNullOrEmpty(promotionId) && selectedProductCategoryId == -1 && (
						<div className="row">
							<div className="col-xl-12">
								<Panel>
									<PanelHeader>{t("SELECTED_PRODUCT")}</PanelHeader>
									<PanelBody>
										<div className="row">
											<div className="col-xl-12">
												<button
													type="button"
													className="btn btn-primary"
													style={{ float: "right" }}
													onClick={() => toggleModal(true)}
												>
													<i className="fa fa-plus-circle m-r-10"></i>
													{t("ADD_PRODUCT")}
												</button>
											</div>
										</div>
										<div className="row">
											<div className="col-xl-12">
												<ReactTable
													data={depositPromotionProductData}
													columns={_tableColumns}
												/>
											</div>
										</div>
									</PanelBody>
								</Panel>
							</div>
						</div>
					)}
					<div className="row">
						<Modal isOpen={accountProductModel} toggle={() => toggleModal()}>
							<form
								onSubmit={accountFormHandleSubmit(submitAccountSettingForm)}
							>
								<ModalHeader toggle={() => toggleModal()}>
									{t(isCreateNewAccount ? "ADD_PRODUCT" : "EDIT_PRODUCTS")}
								</ModalHeader>
								<ModalBody>
									<div className="row">
										<div className="col-lg-12">
											<div className="form-group row m-b-15">
												<label className="col-md-4 col-form-label">
													<b>{t("PRODUCT")}</b>
												</label>
												<div className="col-md-7">
													<Select
														name="accountProductId"
														options={productData.filter(
															(obj1) =>
																!depositPromotionProductData.some(
																	(obj2) => obj1.label === obj2.name
																)
														)}
														placeholder={
															productData.filter(
																(option) =>
																	option.value == selectedMasterProductId
															) !== undefined
																? productData.filter(
																		(option) =>
																			productData.value ==
																			selectedMasterProductId
																  ).label
																: ""
														}
														isDisabled={depositPromotionProductId != ""}
														value={productData.filter(
															(option) =>
																option.masterProductId == selectedMasterProductId
														)}
														onChange={(e) => {
															setAccountProductId(e.value);
															setSelectedMasterProductId(e.masterProductId);
															accountFormSetValue("accountProductId", e.value);
														}}
														ref={accountFormRegister}
													/>
													{accountFormErrors.accountProductId && (
														<div className="invalid-feedback">
															{t(accountFormErrors.accountProductId.message)}
														</div>
													)}
												</div>
											</div>
											<div className="form-group row m-b-15">
												<label className="col-md-4 col-form-label">
													<b>{t("STATUS")}</b>
												</label>
												<div className="col-md-7">
													<div className="switcher">
														<input
															type="checkbox"
															name="accountProductStatus"
															id="accountProductStatus"
															onChange={(e) =>
																setAccountProductStatus(e.target.checked)
															}
															value={true}
															checked={accountProductStatus}
															ref={accountFormRegister}
														/>
														<label htmlFor="accountProductStatus"></label>
													</div>
												</div>
											</div>
										</div>
									</div>
								</ModalBody>
								<ModalFooter>
									<button
										type="button"
										className="btn btn-white"
										onClick={() => toggleModal()}
									>
										{t("CLOSE")}
									</button>
									<button type="submit" className="btn btn-primary">
										{t("SUBMIT")}
									</button>
								</ModalFooter>
							</form>
						</Modal>
					</div>
				</TabPane>
				<TabPane tabId={_LANGUAGE_TAB}>
					{promotionId && (
						<div className="row">
							<div className="col-lg-12">
								<ManageConfigurationLanguage
									id={promotionId}
									type={ContentTypeString._DEPOSIT_PROMOTION}
								/>
							</div>
						</div>
					)}
				</TabPane>
			</TabContent>
		</div>
	);
};

export default withRouter(DepositPromotionDetail);
