import React, { useState } from 'react';
import { useEffect } from 'react';
import ApiEngine from '../../util/ApiEngine.js';
import { ApiKey, ApiUrl, LanguageKey } from '../../util/Constant.js';
import { createFormBody } from '../../util/Util.js';
import { Panel, PanelBody } from '../../components/panel/panel.jsx';
import ReactTable from '../../components/constantComponent/reactTable/ReactTable';
import { useTranslation } from 'react-i18next';
import { showMessage } from '../../redux/AppAction.js';
import { useDispatch } from 'react-redux';

/// <summary>
/// Author: Wong
/// </summary>
const ManageIpBlock = () => {
    const { t } = useTranslation(LanguageKey._PRIMARY);
    const _dispatch = useDispatch();

    const [blockedIpList, setBlockedIpList] = useState([]);

    const _TABLE_COLUMNS = [
        {
            Header: "IP_ADDRESS",
            accessor: "ipAddress"
        },
        {
            Header: "USERNAME",
            accessor: "username"
        },
        {
            Header: "PHONE",
            accessor: "phoneNumber"
        },
        {
            Header: "DATETIME",
            accessor: "createdAt"
        },
        {
            Header: "ACTION",
            id: "action",
            Cell: ({row}) => (
                <button type="button" className="btn btn-primary" onClick={() => deleteIp(row.original.id)}><i className="fas fa-trash"></i></button>
            ),
            width: '1%'
        }
    ];

    /// <summary>
    /// Author: Wong
    /// </summary>
    useEffect(() => {
        init();
    }, []);

    /// <summary>
    /// Author: Wong
    /// </summary>
    const init = async () => {
        await ApiEngine.get(ApiUrl._API_GET_BLOCKED_IP_LIST)
        .then((response) => {
            setBlockedIpList(response[ApiKey._API_DATA_KEY]);
        })
    }

    /// <summary>
    /// Author: Wong
    /// </summary>
    const deleteIp = async (id) => {
        await ApiEngine.post(ApiUrl._API_DELETE_BLOCKED_IP, createFormBody({ id }))
        .then((response) => {
            _dispatch(showMessage(response[ApiKey._API_SUCCESS_KEY], t(response[ApiKey._API_MESSAGE_KEY])));
            if(response[ApiKey._API_SUCCESS_KEY]){
                init();
            }
        })
    }
    
    return (
        <div>
            <h1 className="page-header">{t("MANAGE_IP_BLOCK")}</h1>
            <Panel>
                <PanelBody>
                    <ReactTable filterable data={blockedIpList} columns={_TABLE_COLUMNS} />
                </PanelBody>
            </Panel>
        </div>
    );
};

export default ManageIpBlock;