import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { Panel, PanelBody } from "./../../components/panel/panel.jsx";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import Dropzone from "react-dropzone";
import NavigationButton from "../../components/constantComponent/button/NavigationButton";
import {
  ApiKey,
  ApiUrl,
  DisplayMode,
  DisplayType,
  LanguageKey,
  ContentTypeString,
  AllVipOption,
  DisplayDevice,
  ChatSphereAction
} from "../../util/Constant";
import { useForm, Controller } from "react-hook-form";
import { useLocation } from "react-router-dom";
import {
  stringIsNullOrEmpty,
  createMultiPartFormBody,
  imagePathToFileObject,
} from "../../util/Util";
import { setBusy, setIdle, showMessage } from "../../redux/AppAction";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import ManageBannerLanguage from "./ManageBannerLanguage";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import classnames from "classnames";
import { fetch } from "whatwg-fetch";


/// <summary>
/// Author : -
/// </summary>

const BannerDetail = (props) => {
  const _DETAIL_TAB = "1";
  const _LANGUAGE_TAB = "2";
  const { t } = useTranslation(LanguageKey._PRIMARY);
  let _history = useHistory();
  const _location = useLocation();
  const _dispatch = useDispatch();

  const {
    register,
    unregister,
    handleSubmit,
    errors,
    setError,
    control,
    setValue,
  } = useForm();

  const [bannerId, setBannerId] = useState("");

  const [chatSphereTypeId, setChatSphereTypeId] = useState("")
  const [channel, setChannel] = useState("");
  const [status, setStatus] = useState(false);
  const [action, setAction] = useState("");
  const [channelList, setChannelList] = useState([]);

  /// <summary>
  /// Author : -
  /// </summary>
  useEffect(() => {
    init();
  }, []);

  const [activeTab, setActiveTab] = useState(_DETAIL_TAB);

  /// <summary>
  /// Author : -
  /// </summary>
  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  /// <summary>
  /// Author : -
  /// </summary>
  async function init() {
    if (_location.state) {
      var apiUrl = ApiUrl._API_GET_CHAT_SPHERE_TYPE_BY_ID;
      let stateId = _location.state.id || _location.state.refId;
      apiUrl += "?id=" + stateId;
      await fetch(apiUrl, {
        method: ApiKey._API_GET,
        headers: {
          "Content-Type": ApiKey._API_FORM_URLENCODED,
          "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
        },
      })
        .then((response) => response.json())
        .then(async (responseJson) => {
          if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            if (_location.state.id) {
              setChatSphereTypeId(responseJson[ApiKey._API_DATA_KEY]["id"]);
            } else if (_location.state.refId) {
              setBannerId(_location.state.refId);
            }

            setAction(responseJson[ApiKey._API_DATA_KEY]["name"])
            setChannel(responseJson[ApiKey._API_DATA_KEY]["channelId"]);
            setStatus(responseJson[ApiKey._API_DATA_KEY]["status"]);



            unregister("content");
          }
        });
    }

    await fetch(ApiUrl._API_GET_CHAT_SPHERE_CHANNEL, {
      method: ApiKey._API_GET,
      headers: {
        "Content-Type": ApiKey._API_FORM_URLENCODED,
        "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
          const channelList = [
            { label: AllVipOption._LABEL, value: AllVipOption._VALUE },
          ];
          responseJson[ApiKey._API_DATA_KEY].forEach(function (channel) {
            channelList.push({ label: channel.name, value: channel.id });
          });

          setChannelList(channelList);
        }
      });
  }

  /// <summary>
  /// Author : -
  /// </summary>
  const submitForm = async (data, e) => {
    let params = {
      name: data.action,
      chatSphereChannelId: data.channel,
      status: data.status
    };

    if (!stringIsNullOrEmpty(chatSphereTypeId)) {
      params["id"] = chatSphereTypeId;
    }

    let formBody = createMultiPartFormBody(params);
    _dispatch(setBusy());

    fetch(ApiUrl._API_CREATE_OR_UPDATE_CHAT_SPHERE_TYPE, {
      method: ApiKey._API_POST,
      headers: {
        "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
      },
      body: formBody,
    })
      .then((response) => response.json())
      .then((responseJson) => {
        _dispatch(setIdle());
        _dispatch(
          showMessage(
            responseJson[ApiKey._API_SUCCESS_KEY],
            t(responseJson[ApiKey._API_MESSAGE_KEY])
          )
        );

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
          setChatSphereTypeId(responseJson[ApiKey._API_DATA_KEY]["id"]);

          _history.replace({
            pathname: _location.pathname,
            state: {
              id: responseJson[ApiKey._API_DATA_KEY]["id"],
            },
          });
        }
      });
  };

  return (
    <div>
      <h1 className="page-header">
        {chatSphereTypeId ? t("EDIT_CHAT_SPHERE_ACTION_TYPE") : t("ADD_CHAT_SPHERE_ACTION_TYPE")}
        <NavigationButton history={_history} />
      </h1>
      {chatSphereTypeId && (
        <Nav tabs>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === _DETAIL_TAB })}
              onClick={() => {
                toggle(_DETAIL_TAB);
              }}
            >
              {t("DETAIL")}
            </NavLink>
          </NavItem>
        </Nav>
      )}
      <TabContent activeTab={activeTab}>
        <TabPane tabId={_DETAIL_TAB}>
          <div className="row">
            <div className="col-xl-12">
              <Panel>
                <PanelBody>
                  <form onSubmit={handleSubmit(submitForm)}>
                    <div className="row">
                      <div className="col-lg-8">
                        <div className="form-group row m-b-15">
                          <label className="col-md-4 col-form-label">
                            <b>{t("CHANNEL")}</b>
                          </label>
                          <div className="col-md-7">
                            <Controller
                              control={control}
                              name="channel"
                              render={({ onChange, value }) => (
                                <Select
                                  options={channelList}
                                  value={channelList.filter(
                                    (option) => option.value == channel
                                  )}
                                  onChange={(e) => {
                                    onChange(e.value);
                                    setChannel(e.value)

                                  }}
                                />
                              )}
                              rules={{ required: "PLEASE_SELECT_CHANNEL" }}
                            />
                            {errors.channel && (
                              <div className="invalid-feedback">
                                {t(errors.channel.message)}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-8">
                        <div className="form-group row m-b-15">
                          <label className="col-md-4 col-form-label">
                            <b>{t("ACTION")}</b>
                          </label>
                          <div className="col-md-7">
                            <select
                              className="form-control"
                              name="action"
                              ref={register}
                              onChange={(e) => setAction(e.target.value)}
                              value={action}
                              required
                            >
                              <option value="" selected hidden>
                                ({t("SELECT")})
                              </option>
                              <option value={ChatSphereAction._REGISTERATION}>
                                {t("REGISTRATION")}
                              </option>
                              <option value={ChatSphereAction._RESET_PASSWORD}>
                                {t("RESET_PASSWORD")}
                              </option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-8">
                        <div className="form-group row m-b-15">
                          <label className="col-md-4 col-form-label">
                            <b>{t("STATUS")}</b>
                          </label>
                          <div className="col-md-7">
                            <div className="switcher">
                              <input
                                type="checkbox"
                                name="status"
                                id="status"
                                onChange={(e) => setStatus(e.target.checked)}
                                value={true}
                                checked={status}
                                ref={register}
                              />
                              <label htmlFor="status"></label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <hr />
                    <div className="row">
                      <div className="col-lg-3">
                        <div className="form-group">
                          <button type="submit" className="btn btn-primary">
                            {t("SUBMIT")}
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </PanelBody>
              </Panel>
            </div>
          </div>
        </TabPane>
      </TabContent>
    </div>
  );
};

export default withRouter(BannerDetail);
