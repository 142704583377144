import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { Panel, PanelBody } from "./../../components/panel/panel.jsx";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import Dropzone from "react-dropzone";
import NavigationButton from "../../components/constantComponent/button/NavigationButton";
import {
  ApiKey,
  ApiUrl,
  DisplayMode,
  DisplayType,
  LanguageKey,
  ContentTypeString,
  AllVipOption,
  DisplayDevice,
  ChatSphereAction
} from "../../util/Constant";
import { useForm, Controller } from "react-hook-form";
import { useLocation } from "react-router-dom";
import {
  stringIsNullOrEmpty,
  createMultiPartFormBody,
} from "../../util/Util";
import { setBusy, setIdle, showMessage } from "../../redux/AppAction";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import ManageBannerLanguage from "./ManageBannerLanguage";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import classnames from "classnames";
import { fetch } from "whatwg-fetch";


/// <summary>
/// Author : -
/// </summary>

const BannerDetail = (props) => {
  const _DETAIL_TAB = "1";
  const _LANGUAGE_TAB = "2";
  const { t } = useTranslation(LanguageKey._PRIMARY);
  let _history = useHistory();
  const _location = useLocation();
  const _dispatch = useDispatch();

  const {
    register,
    unregister,
    handleSubmit,
    errors,
    setError,
    control,
    setValue,
  } = useForm();

  const [bannerId, setBannerId] = useState("");

  const [chatSpherePhoneId, setChatSpherePhoneId] = useState("")
  const [status, setStatus] = useState(false);

  const [phoneNumber, setPhoneNumber] = useState("");

  /// <summary>
  /// Author : -
  /// </summary>
  useEffect(() => {
    init();
  }, []);

  const [activeTab, setActiveTab] = useState(_DETAIL_TAB);

  /// <summary>
  /// Author : -
  /// </summary>
  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  /// <summary>
  /// Author : -
  /// </summary>
  async function init() {
    if (_location.state) {
      var apiUrl = ApiUrl._API_GET_CHAT_SPHERE_PHONE_NUMBER_BY_ID;
      let stateId = _location.state.id || _location.state.refId;
      apiUrl += "?id=" + stateId;
      await fetch(apiUrl, {
        method: ApiKey._API_GET,
        headers: {
          "Content-Type": ApiKey._API_FORM_URLENCODED,
          "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
        },
      })
        .then((response) => response.json())
        .then(async (responseJson) => {
          if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            if (_location.state.id) {
              setChatSpherePhoneId(responseJson[ApiKey._API_DATA_KEY]["id"]);
            } else if (_location.state.refId) {
              setBannerId(_location.state.refId);
            }

            setPhoneNumber(responseJson[ApiKey._API_DATA_KEY]["phoneNumber"]);
            setStatus(responseJson[ApiKey._API_DATA_KEY]["status"]);



            unregister("content");
          }
        });
    }
  }

  /// <summary>
  /// Author : -
  /// </summary>
  const submitForm = async (data, e) => {
    let params = {
      phoneNumber: data.phoneNumber,
      status: data.status
    };

    if (!stringIsNullOrEmpty(chatSpherePhoneId)) {
      params["id"] = chatSpherePhoneId;
    }

    let formBody = createMultiPartFormBody(params);
    _dispatch(setBusy());

    fetch(ApiUrl._API_CREATE_OR_UPDATE_CHAT_SPHERE_PHONE_NUMBER, {
      method: ApiKey._API_POST,
      headers: {
        "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
      },
      body: formBody,
    })
      .then((response) => response.json())
      .then((responseJson) => {
        _dispatch(setIdle());
        _dispatch(
          showMessage(
            responseJson[ApiKey._API_SUCCESS_KEY],
            t(responseJson[ApiKey._API_MESSAGE_KEY])
          )
        );

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
          setChatSpherePhoneId(responseJson[ApiKey._API_DATA_KEY]["id"]);

          _history.replace({
            pathname: _location.pathname,
            state: {
              id: responseJson[ApiKey._API_DATA_KEY]["id"],
            },
          });
        }
      });
  };


  return (
    <div>
      <h1 className="page-header">
        {chatSpherePhoneId ? t("EDIT_CHAT_SPHERE_PHONE_NUMBER") : t("ADD_CHAT_SPHERE_PHONE_NUMBER")}
        <NavigationButton history={_history} />
      </h1>
      {chatSpherePhoneId && (
        <Nav tabs>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === _DETAIL_TAB })}
              onClick={() => {
                toggle(_DETAIL_TAB);
              }}
            >
              {t("DETAIL")}
            </NavLink>
          </NavItem>
        </Nav>
      )}
      <TabContent activeTab={activeTab}>
        <TabPane tabId={_DETAIL_TAB}>
          <div className="row">
            <div className="col-xl-12">
              <Panel>
                <PanelBody>
                  <form onSubmit={handleSubmit(submitForm)}>
                    <div className="row">
                      <div className="col-lg-8">
                        <div className="form-group row m-b-15">
                          <label className="col-md-4 col-form-label">
                            <b>{t("PHONE_NUMBER")}</b>
                          </label>
                          <div className="col-md-7">
                            <input
                              type="text"
                              name="phoneNumber"
                              ref={register({ required: "PLEASE_ENTER_PHONE_" })}
                              className="form-control m-b-5"
                              defaultValue={phoneNumber}
                              placeholder={t("60123456789")}
                            />
                            {errors.phoneNumber && (
                              <div className="invalid-feedback">
                                {t(errors.phoneNumber.message)}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-8">
                        <div className="form-group row m-b-15">
                          <label className="col-md-4 col-form-label">
                            <b>{t("STATUS")}</b>
                          </label>
                          <div className="col-md-7">
                            <div className="switcher">
                              <input
                                type="checkbox"
                                name="status"
                                id="status"
                                onChange={(e) => setStatus(e.target.checked)}
                                value={true}
                                checked={status}
                                ref={register}
                              />
                              <label htmlFor="status"></label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <hr />
                    <div className="row">
                      <div className="col-lg-3">
                        <div className="form-group">
                          <button type="submit" className="btn btn-primary">
                            {t("SUBMIT")}
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </PanelBody>
              </Panel>
            </div>
          </div>
        </TabPane>
      </TabContent>
    </div>
  );
};

export default withRouter(BannerDetail);
