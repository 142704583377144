import i18n from '../i18n';
export const _BUSY = 'busy';
export const _IDLE = 'idle';
export const _MEMBER_SEARCH = '';

export const _ALERT_TYPES = {
    SUCCESS: 'success',
    ERROR: 'error',
    NONE: 'none'
};

/// <summary>
/// Author : -
/// </summary>
export const setBusy = (message) => {
    return async dispatch => {
        dispatch({
            type: _BUSY,
            message: message
        });
    }
}

/// <summary>
/// Author : -
/// this function is to only update user status to logout
/// </summary>
export const setIdle = () => {
    return async dispatch => {
        dispatch({
            type: _IDLE
        });
    }
}

/// <summary>
/// Author: -
/// </summary>
// decide to pop up as success / error type alert will depend on response status
export const showMessage = (responseStatus, responseMessage, disabledTranslate = false) => {
    return async dispatch => {
        dispatch({
            type: responseStatus == true ? _ALERT_TYPES.SUCCESS : _ALERT_TYPES.ERROR,
            message: disabledTranslate ? responseMessage:i18n.t(responseMessage)
        });
    }
}

/// <summary>
/// Author: -
/// </summary>
// close pop up
export const disposeMessage = () => {
    return async dispatch => {
        dispatch({
            type: _ALERT_TYPES.NONE
        });
    }
}

/// <summary>
/// Author: -
/// </summary>
// maintain member search
export const setMemberSearch = (search) => {
    return async dispatch => {
        dispatch({
            type: _MEMBER_SEARCH,
            value: search
        });
    }
}