import React, { useState, useEffect } from 'react';
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { setBusy, setIdle, showMessage } from "../../redux/AppAction";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from 'react-i18next';
import { ApiKey, ApiUrl, AccessRight, WebUrl, LanguageKey, DEFAULT_PAGE_SIZE } from "../../util/Constant";
import { Panel, PanelBody } from '../../components/panel/panel.jsx';
import ApiEngine from '../../util/ApiEngine';
import ReactTable from '../../components/constantComponent/reactTable/ReactTable';
import { numberWithCurrencyFormat } from '../../util/Util';
import { UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, Modal, ModalHeader, ModalBody } from 'reactstrap';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import moment from 'moment';
import classNames from 'classnames';
const ViewProductAccount = (props) => {
    const { t } = useTranslation(LanguageKey._PRIMARY);
    const _location = useLocation();
    const _dispatch = useDispatch();
    let _history = useHistory();
    const [productAccounts, setProductAccounts] = useState([]);
    const [productLogUrl, setProductLogUrl] = useState();
    const [viewProductLog, setViewProductLog] = useState(false);
    const [startDate, setStartDate] = useState(moment().startOf('day').format("YYYY-MM-DD HH:mm:ss"));
    const [endDate, setEndDate] = useState(moment().endOf('day').format("YYYY-MM-DD HH:mm:ss"));
    const [productId, setProductId] = useState();
    const [userId, setUserId] = useState();
    const [username, setUsername] = useState("");
    /// <summary>
    /// Author : Wong
    /// </summary>
    const _columns = [
        {
            Header: "PRODUCT",
            accessor: "accountProductModel.product.productName",
        },
        {
            Header: "USERNAME",
            accessor: "username",
        },
        {
            Header: "BONUS",
            accessor: "bonus",
            Cell: ({ row, value }) => {
                return <span>{numberWithCurrencyFormat(value, 2)}</span>
            }
        },
        {
            Header: "COMMISSION",
            accessor: "commission",
            Cell: ({ row, value }) => {
                return <span>{numberWithCurrencyFormat(value, 2)}</span>
            }
        },
        {
            Header: "STATUS",
            accessor: "status",
            Cell: ({ row }) => {
                return <span>{row.original.status ? t("ACTIVE") : t("INACTIVE")}</span>
            }
        },
        {
            Id: "Action",
            Header: "ACTION",
            width: '1%',
            sortable: false,
            Cell: ({ row }) => {
                return <div className="btn-group m-r-5 m-b-5">
                    <UncontrolledButtonDropdown>
                        <DropdownToggle caret color="default"><i className="fas fa-cog"></i></DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem onClick={() => viewLog(row.original.id)}><span className="text-primary">{t("VIEW_PRODUCT_LOG")}</span></DropdownItem>
                            <DropdownItem onClick={() => viewBalance(row.original.accountProductModel.product.id)}><span className="text-primary">{t("VIEW_BALANCE")}</span></DropdownItem>
                        </DropdownMenu>
                    </UncontrolledButtonDropdown>
                </div>
            }
        }
    ];
    /// <summary>
    /// Author : Wong
    /// </summary>
    const _logColumns = [
        {
            Header: "DATE",
            accessor: "date"
        },
        {
            Header: "AMOUNT",
            accessor: "amount",
            Cell: ({ row, value }) => {
                return <span>{numberWithCurrencyFormat(value, 2)}</span>
            }
        },
        {
            Header: "WIN_AMOUNT",
            accessor: "winAmount",
            Cell: ({ row, value }) => {
                return <span className={classNames('', { 'text-green': value > 0 })}>{numberWithCurrencyFormat(value, 2)}</span>
            }
        },
        {
            Header: "REMARK",
            accessor: "remark",
            Cell: ({ row, value }) => {
                return <div style={{ whiteSpace: "pre-line" }}>{value}</div>
            }
        },
        {
            Header: "STATUS",
            accessor: "status",
            Cell: ({ row, value }) => {
                let statusStr = "";
                let statusClass = "";
                switch (value) {
                    case 1:
                        statusStr = t("SETTLED");
                        statusClass = "badge-success";
                        break;
                    case 2:
                        statusStr = t("CANCELLED");
                        statusClass = "badge-danger";
                        break;
                    case 0:
                        statusStr = t("PENDING");
                        statusClass = "badge-default";
                        break;
                }
                return <span className={`badge ${statusClass}`}>{statusStr}</span>
            }
        }
    ];
    /// <summary>
    /// Author : Wong
    /// </summary>
    useEffect(async () => {
        if (_location.state) {
            setUserId(_location.state.id);
            setUsername(_location.state.username);
            await getProductAccount(_location.state.id);
        }
    }, []);
    /// <summary>
    /// Author : Wong
    /// </summary>
    const getProductAccount = async (userId) => {
        await ApiEngine.get(`${ApiUrl._API_GET_USER_PRODUCT_ACCOUNT}?userId=${userId}`).then((response) => {
            if (response[ApiKey._API_SUCCESS_KEY]) {
                setProductAccounts(response.data);
            }
        });
    }
    /// <summary>
    /// Author : Wong
    /// </summary>
    const viewLog = async (id) => {
        setViewProductLog(true);
        setProductId(id);
        setProductLogUrl(`${ApiUrl._API_GET_PRODUCT_ACCOUNT_LOG}?id=${id}`);
    }
    /// <summary>
    /// Author : Wong
    /// </summary>
    const viewBalance = async (id) => {
        await ApiEngine.get(`${ApiUrl._API_GET_PRODUCT_ACCOUNT_BALANCE}?userId=${userId}&id=${id}`).then((response) => {
            let responseSuccess = response[ApiKey._API_SUCCESS_KEY];
            _dispatch(showMessage(responseSuccess, responseSuccess ? `${t("CREDIT")}: ${numberWithCurrencyFormat(response[ApiKey._API_DATA_KEY], 4)}` : response[ApiKey._API_MESSAGE_KEY], true));
        });
    }
    /// <summary>
    /// Author : Wong
    /// </summary>
    const handleEvent = (event, picker) => {
        setStartDate(moment(picker.startDate).format("YYYY-MM-DD HH:mm:ss"));
        setEndDate(moment(picker.endDate).format("YYYY-MM-DD HH:mm:ss"));
    }
    /// <summary>
    /// Author : Wong
    /// </summary>
    const filter = () => {
        setProductLogUrl(`${ApiUrl._API_GET_PRODUCT_ACCOUNT_LOG}?id=${productId}&startDate=${startDate}&endDate=${endDate}`);
    }
    return (
        <>
            <div>
                <h1 className="page-header">{t("VIEW_PRODUCT_ACCOUNT")} ({username})</h1>
                <Panel>
                    <PanelBody>
                        <ReactTable data={productAccounts} filterable columns={_columns} />
                    </PanelBody>
                </Panel>
            </div>
            <Modal size="xl" isOpen={viewProductLog} toggle={setViewProductLog}>
                <ModalHeader toggle={() => { setViewProductLog(!viewProductLog) }}>{t("VIEW_PRODUCT_LOG")}</ModalHeader>
                <ModalBody>
                    <div className="form-group">
                        <label>{t("DATE")}</label>
                        <div className="d-flex">
                            <DateRangePicker
                                className="form-control"
                                containerStyles={{ width: "100%", maxWidth: '300px' }}
                                alwaysShowCalendars={true}
                                timePicker={true}
                                timePickerSeconds={true}
                                onApply={handleEvent}
                                ranges={{
                                    'Today': [moment().startOf('day'), moment().endOf('day')],
                                    'Yesterday': [moment().subtract(1, 'days').startOf('day'), moment().subtract(1, 'days').endOf('day')],
                                    'Last 7 Days': [moment().subtract(6, 'days').startOf('day'), moment().endOf('day')],
                                    'Last 30 Days': [moment().subtract(29, 'days').startOf('day'), moment().endOf('day')],
                                    'This Month': [moment().startOf('month'), moment().endOf('month')],
                                    'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
                                    'This Year': [moment().startOf('year'), moment().endOf('year')]
                                }}>
                                <input type="text" className="form-control" value={startDate + " - " + endDate} />
                            </DateRangePicker>
                            <button type="button" className="btn btn-primary ml-2" onClick={() => filter()}>{t("FILTER")}</button>
                        </div>
                    </div>
                    <ReactTable fetchUrl={productLogUrl} columns={_logColumns} />
                </ModalBody>
            </Modal>
        </>
    )
}
export default ViewProductAccount;