import React, { useState, useEffect } from "react";
import ReactTable from "../../components/constantComponent/reactTable/ReactTable";
import { Panel, PanelBody } from "./../../components/panel/panel.jsx";
import "../../assets/css/custom.css";
import { useHistory } from "react-router-dom";
import {
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { navigateTo } from "../../util/Util";
import {
  ApiUrl,
  ApiKey,
  WebUrl,
  AccessRight,
  LanguageKey,
} from "../../util/Constant";
import NavigationButton from "../../components/constantComponent/button/NavigationButton";
import { checkIfPermissionExist } from "../../util/PermissionChecker";
import { useTranslation } from "react-i18next";
import { fetch } from "whatwg-fetch";

/// <summary>
/// Author : -
/// </summary>

const ManageChatSphereType = (props) => {
  let _history = useHistory();
  const { t } = useTranslation(LanguageKey._PRIMARY);
  const [chatSphereTypeList, setChatSphereTypeList] = useState([]);

  let _tableColumns = [
    {
      Header: "Action",
      accessor: "name",
      disableSortBy: true,
    },
    {
      Header: "Channel",
      accessor: "channel",
      disableSortBy: true,
    },
    {
      Header: "STATUS",
      accessor: "status",
      Cell: ({ row }) => (
        <div className="btn-group m-r-5 m-b-5">
          {row.original.status == 1 ? (
            <span className="badge badge-secondary badge-green">
              {t("ACTIVE")}
            </span>
          ) : (
            <span className="badge badge-secondary badge-danger">
              {t("SUSPENDED")}
            </span>
          )}
        </div>
      ),
      disableSortBy: true,
      style: { overflow: "visible" },
    },

    {
      id: "action",
      Header: "",
      Cell: ({ row }) =>
        checkIfPermissionExist(
          AccessRight._CHAT_SPHERE_TYPE_PERMISSION,
          true
        ) && (
          <div className="btn-group m-r-5 m-b-5">
            <UncontrolledButtonDropdown>
              <DropdownToggle caret color="default">
                <i className="fas fa-cog"></i>
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem
                  onClick={() => {
                    navigateTo(
                      _history,
                      WebUrl._URL_CHAT_SPHERE_TYPE_DETAIL,
                      row.original
                    );
                  }}
                >
                  <span className="text-warning">{t("EDIT")}</span>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledButtonDropdown>
          </div>
        ),
      disableSortBy: true,
      disableFilters: true,
      width: 100,
      style: { overflow: "visible" },
    },
  ];

  /// <summary>
  /// Author : -
  /// </summary>
  useEffect(() => {
    fetch(ApiUrl._API_GET_CHAT_SPHERE_TYPE , {
      method: ApiKey._API_GET,
      headers: {
        "Content-Type": ApiKey._API_FORM_URLENCODED,
        "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
          setChatSphereTypeList(responseJson[ApiKey._API_DATA_KEY]);
        }
      });
  }, []);

  return (
    <div>
      <h1 className="page-header">
        {t("MANAGE_CHAT_SPHERE_ACTION_TYPE")}
        {checkIfPermissionExist(
          AccessRight._CHAT_SPHERE_TYPE_PERMISSION,
          true
        ) && (
            <NavigationButton
              history={_history}
              url={WebUrl._URL_CHAT_SPHERE_TYPE_DETAIL}
            buttonText={t("ADD_CHANNEL")}
            />
          )}
      </h1>
      <Panel>
        <PanelBody>
          <ReactTable data={chatSphereTypeList} columns={_tableColumns} />
        </PanelBody>
      </Panel>
    </div>
  );
};

export default ManageChatSphereType;
