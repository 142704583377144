import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { Panel, PanelBody } from "./../../components/panel/panel.jsx";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import NavigationButton from "../../components/constantComponent/button/NavigationButton";
import {
  ApiKey,
  ApiUrl,
  LanguageKey,
  ContentTypeString,
} from "../../util/Constant";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import { stringIsNullOrEmpty, createFormBody } from "../../util/Util";
import { setBusy, setIdle, showMessage } from "../../redux/AppAction";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import ManageConfigurationLanguage from "./ManageConfigurationLanguage";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import classnames from "classnames";
import { fetch } from "whatwg-fetch";

/// <summary>
/// Author : -
/// </summary>

const VipBenefitContentDetail = (props) => {
  const _DETAIL_TAB = "1";
  const _LANGUAGE_TAB = "2";
  const { t } = useTranslation(LanguageKey._PRIMARY);
  let _history = useHistory();
  const _location = useLocation();
  const _dispatch = useDispatch();

  const { register, unregister, handleSubmit, errors, setError } = useForm();

  const [benefitContentId, setBenefitContentId] = useState("");
  const [content, setContent] = useState("");
  const [vip, setVip] = useState("");
  const [vipOption, setVipOption] = useState([]);
  const [vipBenefitId, setVipBenefitId] = useState("");
  const [vipBenefitOption, setVipBenefitOption] = useState([]);

  /// <summary>
  /// Author : -
  /// </summary>
  useEffect(() => {
    register({ name: "content" }, { required: "PLEASE_ENTER_CONTENT" });
    register({ name: "vipBenefit" }, { required: "PLEASE_SELECT_TITLE" });
    register({ name: "vip" }, { required: "PLEASE_SELECT_VIP_LEVEL" });
    init();
  }, []);

  const [activeTab, setActiveTab] = useState(_DETAIL_TAB);

  /// <summary>
  /// Author : -
  /// </summary>
  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  /// <summary>
  /// Author : -
  /// </summary>
  async function init() {
    if (_location.state) {
      var apiUrl = ApiUrl._API_GET_VIP_BENEFIT_CONTENT_BY_ID;
      apiUrl += "?id=" + _location.state.id;
      await fetch(apiUrl, {
        method: ApiKey._API_GET,
        headers: {
          "Content-Type": ApiKey._API_FORM_URLENCODED,
          "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
        },
      })
        .then((response) => response.json())
        .then(async (responseJson) => {
          if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            setBenefitContentId(responseJson[ApiKey._API_DATA_KEY]["id"]);
            setVipBenefitId(responseJson[ApiKey._API_DATA_KEY]["vipBenefitId"]);
            setVip(responseJson[ApiKey._API_DATA_KEY]["vipId"]);
            setContent(responseJson[ApiKey._API_DATA_KEY]["content"]);
            unregister(["vip", "vipBenefit"]);
          }
        });
    }

    await fetch(ApiUrl._API_GET_USER_VIP, {
      method: ApiKey._API_GET,
      headers: {
        "Content-Type": ApiKey._API_FORM_URLENCODED,
        "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
          const vipList = [];
          responseJson[ApiKey._API_DATA_KEY].forEach(function (vipLevel) {
            vipList.push({ label: vipLevel.name, value: vipLevel.id });
          });

          setVipOption(vipList);
        }
      });

    await fetch(ApiUrl._API_GET_VIP_BENEFIT, {
      method: ApiKey._API_GET,
      headers: {
        "Content-Type": ApiKey._API_FORM_URLENCODED,
        "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
          const vipBenefitList = [];
          responseJson[ApiKey._API_DATA_KEY].forEach(function (benefit) {
            vipBenefitList.push({ label: benefit.title, value: benefit.id });
          });

          setVipBenefitOption(vipBenefitList);
        }
      });
  }

  /// <summary>
  /// Author : -
  /// </summary>
  const submitForm = async (data, e) => {
    let params = {
      content: data.content,
      vipId: vip,
      vipBenefitId: vipBenefitId,
    };

    if (!stringIsNullOrEmpty(benefitContentId)) {
      params["id"] = benefitContentId;
    }

    let formBody = createFormBody(params);
    _dispatch(setBusy());

    fetch(ApiUrl._API_CREATE_OR_UPDATE_VIP_BENEFIT_CONTENT, {
      method: ApiKey._API_POST,
      headers: {
        "Content-Type": ApiKey._API_FORM_URLENCODED,
        "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
      },
      body: formBody,
    })
      .then((response) => response.json())
      .then((responseJson) => {
        _dispatch(setIdle());
        _dispatch(
          showMessage(
            responseJson[ApiKey._API_SUCCESS_KEY],
            t(responseJson[ApiKey._API_MESSAGE_KEY])
          )
        );

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
          setBenefitContentId(responseJson[ApiKey._API_DATA_KEY]["id"]);

          _history.replace({
            pathname: _location.pathname,
            state: {
              id: responseJson[ApiKey._API_DATA_KEY]["id"],
            },
          });
        }
      });
  };

  return (
    <div>
      <h1 className="page-header">
        {benefitContentId
          ? t("EDIT_VIP_BENEFIT_CONTENT")
          : t("ADD_VIP_BENEFIT_CONTENT")}
        <NavigationButton history={_history} />
      </h1>
      {benefitContentId && (
        <Nav tabs>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === _DETAIL_TAB })}
              onClick={() => {
                toggle(_DETAIL_TAB);
              }}
            >
              {t("DETAIL")}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === _LANGUAGE_TAB })}
              onClick={() => {
                toggle(_LANGUAGE_TAB);
              }}
            >
              {t("LANGUAGE")}
            </NavLink>
          </NavItem>
        </Nav>
      )}
      <TabContent activeTab={activeTab}>
        <TabPane tabId={_DETAIL_TAB}>
          <div className="row">
            <div className="col-xl-12">
              <Panel>
                <PanelBody>
                  <form onSubmit={handleSubmit(submitForm)}>
                    <div className="row">
                      <div className="col-lg-8">
                        <div className="form-group row">
                          <label className="col-md-4 col-form-label">
                            <b>{t("VIP_LEVEL")}</b>
                          </label>
                          <div className="col-md-7">
                            <Select
                              name="vip"
                              options={vipOption}
                              placeholder={
                                vipOption.filter(
                                  (option) => option.value == vip
                                )[0] !== undefined
                                  ? vipOption.filter(
                                      (option) => option.value == vip
                                    )[0].label
                                  : ""
                              }
                              value={vipOption.filter(
                                (option) => option.value == vip
                              )}
                              onChange={(e) => {
                                unregister("vip");
                                setVip(e.value);
                              }}
                            />
                            {errors.vip && (
                              <div className="invalid-feedback">
                                {t(errors.vip.message)}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-8">
                        <div className="form-group row">
                          <label className="col-md-4 col-form-label">
                            <b>{t("VIP_BENEFIT_TITLE")}</b>
                          </label>
                          <div className="col-md-7">
                            <Select
                              name="vipBenefit"
                              options={vipBenefitOption}
                              placeholder={
                                vipBenefitOption.filter(
                                  (option) => option.value == vipBenefitId
                                )[0] !== undefined
                                  ? vipBenefitOption.filter(
                                      (option) => option.value == vipBenefitId
                                    )[0].label
                                  : ""
                              }
                              value={vipBenefitOption.filter(
                                (option) => option.value == vipBenefitId
                              )}
                              onChange={(e) => {
                                unregister("vipBenefit");
                                setVipBenefitId(e.value);
                              }}
                            />
                            {errors.vipBenefit && (
                              <div className="invalid-feedback">
                                {t(errors.vipBenefit.message)}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-8">
                        <div className="form-group row m-b-15">
                          <label className="col-md-4 col-form-label">
                            <b>{t("CONTENT")}</b>
                          </label>
                          <div className="col-md-7">
                            <textarea
                              className="form-control"
                              rows="5"
                              style={{ marginTop: "10px" }}
                              ref={register({
                                required: "PLEASE_ENTER_CONTENT",
                              })}
                              name="content"
                              defaultValue={content}
                            ></textarea>
                            {errors.content && (
                              <div className="invalid-feedback">
                                {t(errors.content.message)}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <hr />
                    <div className="row">
                      <div className="col-lg-3">
                        <div className="form-group">
                          <button type="submit" className="btn btn-primary">
                            {t("SUBMIT")}
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </PanelBody>
              </Panel>
            </div>
          </div>
        </TabPane>
        <TabPane tabId={_LANGUAGE_TAB}>
          {benefitContentId && (
            <div className="row">
              <div className="col-lg-12">
                <ManageConfigurationLanguage
                  id={benefitContentId}
                  type={ContentTypeString._VIP_BENEFIT_CONTENT}
                />
              </div>
            </div>
          )}
        </TabPane>
      </TabContent>
    </div>
  );
};

export default withRouter(VipBenefitContentDetail);
