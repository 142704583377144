import React, { useRef } from 'react';
import axios from 'axios';

function FileUpload({ url, memberId, onFileUpload, disabled }) {
  const fileInputRef = useRef(null);
  
  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (!file) {
      //alert('Please select a file to upload.');
      return;
    }

    const formData = new FormData();
    formData.append('file', file);
    formData.append('memberId', memberId);
      

    try {
      if (onFileUpload) {
        onFileUpload(false);
      }

      const response = await axios.post(url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
        
      // Call the callback function with the response data
      if (response['data'].success) {
        if (onFileUpload) {
          onFileUpload(true);
        }
      }

      // Reset the file input after upload
      fileInputRef.current.value = '';
    } catch (error) {
      console.error('Error uploading file:', error);
      alert('Error uploading file');
    }
  };
  
  return (
    <div>
        <input 
            type="file" 
            onChange={handleFileChange} 
            style={{ display: 'none' }} 
            accept="image/*" 
            ref={fileInputRef} 
        />
        <div className="t3-livechat-input-attachment-container">
            <div className="t3-livechat-input-attachment-wrapper">
                <div className={disabled?"t3-livechat-input-attachment-button disabled":"t3-livechat-input-attachment-button"} onClick={() => !disabled && fileInputRef.current && fileInputRef.current.click()}>
                    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="20" cy="20" r="20" fill="rgba(0,0,0,.05)"/><path d="M17.5221 29.5C15.9109 29.5 14.3962 28.8726 13.2571 27.7336C10.9045 25.3809 10.9038 21.5536 13.2554 19.2019L20.6549 11.8023C21.4951 10.9623 22.6096 10.5001 23.794 10.5001C23.7993 10.5 23.8048 10.5 23.8105 10.5001C24.9923 10.5001 26.1073 10.9629 26.9485 11.804C28.6833 13.5388 28.6833 16.3612 26.9486 18.096L19.9272 25.1174C19.4399 25.6046 18.7913 25.8729 18.1011 25.8729C17.4128 25.8729 16.7649 25.6039 16.2767 25.1156C15.789 24.6281 15.5204 23.9801 15.5204 23.2913C15.5204 22.6026 15.789 21.9546 16.2767 21.4669L22.3917 15.3519C22.6946 15.0492 23.1855 15.0492 23.4885 15.3519C23.7913 15.6548 23.7913 16.1458 23.4885 16.4487L17.3735 22.5637C17.1787 22.7584 17.0714 23.0169 17.0714 23.2913C17.0714 23.5658 17.1787 23.8242 17.3734 24.0189C17.5688 24.2143 17.8271 24.3218 18.1011 24.3218C18.3771 24.3218 18.6361 24.2149 18.8305 24.0206L25.8519 16.9992C26.9818 15.8693 26.9818 14.0307 25.8518 12.9008C25.3037 12.3527 24.5779 12.0511 23.8076 12.0511C23.8042 12.0511 23.8003 12.0511 23.7969 12.0511C23.0238 12.0511 22.2987 12.3521 21.7516 12.8991L14.3522 20.2986C12.6052 22.0456 12.606 24.8889 14.3539 26.6369C15.2 27.483 16.3251 27.949 17.5221 27.949C18.7191 27.949 19.8442 27.483 20.6903 26.6368L27.1835 20.1436C27.4864 19.8409 27.9774 19.8409 28.2803 20.1436C28.5831 20.4466 28.5831 20.9375 28.2803 21.2404L21.7871 27.7336C20.648 28.8726 19.1333 29.5 17.5221 29.5Z" fill="#3C3C3C"/></svg>
                </div>
            </div>
        </div>
    </div>
  );
}

export default FileUpload;
