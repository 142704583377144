import React, { useState, useEffect } from "react";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import classnames from "classnames";
import { useHistory, withRouter, useLocation } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import TransactionEnquiry from "../transaction/TransactionEnquiry.js";
import WalletTransfer from "./WalletTransfer.js";
import MemberManageBetDetailReport from "../report/MemberManageBetDetailReport.js";
import ManageProfitLossReport from "../report/ManageProfitLossReport.js";
import Product from "./Product.js";
import ReferralDetail from "./ReferralDetail";
import { LanguageKey, Role } from "../../util/Constant";
import MemberDetail from "./MemberDetail.js";
import { useTranslation } from "react-i18next";
import BonusTracking from "./BonusTracking.js";
import CommissionTracking from "./CommissionTracking.js";
import PointTracking from "./PointTracking.js";
import AdjustmentReport from "./../report/AdjustmentReport";
import WalletBalance from "./../account/WalletBalance";
import RollOverStatement from "./../account/RollOverStatement";
import BonusRollOverStatement from './BonusRollOverStatement.js';
import MemberSportsBetLimit from "./MemberSportsBetLimit.js";
import ManageMemberDownlineGames from "./ManageMemberDownlineGames.js";
//import ManageMemberBankAccount from "./ManageMemberBankAccount.js"
import { useSelector } from "react-redux";
import PromoAndCoinRollover from "./../account/PromoAndCoinRollover";
import MemberPromotionStatement from "./../account/MemberPromotionStatement";

/// <summary>
/// Author : -
/// </summary>
const ViewAccount = (props) => {
  const { t } = useTranslation(LanguageKey._PRIMARY);
  let _history = useHistory();
  const _location = useLocation();
  const _DETAIL_TAB = 1;
  const _TRANSACTION_TAB = 2;
  const _WALLET_TRANSFER_TAB = 3;
  const _BONUS_TRACKING_TAB = 4;
  const _COMMISSION_TRACKING_TAB = 5;
  const _POINT_TRACKING_TAB = 6;
  const _PROFIT_LOSS_TAB = 7;
  const _BET_DETAIL_TAB = 8;
  const _PRODUCT_TAB = 9;
  const _REFERRAL_TAB = 10;
  const _ADJUSTMENT_REPORT_TAB = 11;
  const _WALLET_BALANCE = 12;
  const _ROLL_OVER_STATEMENT = 13;
  const _SPORTS_BET_LIMIT = 14;
  const _MEMBER_DOWNLINE_GAMES = 15;
  const _BONUS_ROLL_OVER_STATEMENT = 16;
  //const _MANAGE_MEMBER_BANK_ACCOUNT = 17;
  const _PROMOTIONS = 17;
  const _PROMOTIONS_OLD = 18;
  const [username, setUsername] = useState("");
  const [memberId, setMemberId] = useState("");
  const [parentMemberId, setParentMemberId] = useState("");
  const [navigateFromRole, setNavigateFromRole] = useState("");
  const [activeTab, setActiveTab] = useState(_DETAIL_TAB);

  /// <summary>
  /// Author : -
  /// </summary>
  useEffect(() => {
    if (_location.state) {
      setMemberId(_location.state.id);
      setUsername(_location.state.username);
      setParentMemberId(_location.state.parentMemberId);
      if (_location.state.navigateFromRole) { setNavigateFromRole(_location.state.navigateFromRole); }
    }
  }, []);

  /// <summary>
  /// Author : -
  /// </summary>
  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  }

  return (
    <div>
      <h1 className="page-header">{memberId ? username : t("ADD_MEMBER_DETAIL")}<button type="button" className="btn btn-inverse" style={{ float: "right" }} onClick={() => { _history.goBack() }}><i className="fas fa-long-arrow-alt-left"></i></button></h1>
      <div className="row">
        <div className="col-xl-12">
          {memberId && <Nav tabs>
            {true ? <NavItem>
              <NavLink
                className={classnames({ active: activeTab === _DETAIL_TAB })}
                onClick={() => { toggleTab(_DETAIL_TAB); }}>
                <span className="d-sm-none">{t("ACCOUNT")}</span>
                <span className="d-sm-block d-none">{t("ACCOUNT")}</span>
              </NavLink>
            </NavItem> : <></>}
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === _TRANSACTION_TAB })}
                onClick={() => { toggleTab(_TRANSACTION_TAB); }}>
                <span className="d-sm-none">{t("TRANSACTION")}</span>
                <span className="d-sm-block d-none">{t("TRANSACTION")}</span>
              </NavLink>
            </NavItem>
            {navigateFromRole !== "CS" ? <NavItem>
              <NavLink
                className={classnames({ active: activeTab === _WALLET_TRANSFER_TAB })}
                onClick={() => { toggleTab(_WALLET_TRANSFER_TAB); }}>
                <span className="d-sm-none">{t("WALLET_TRANSFER")}</span>
                <span className="d-sm-block d-none">{t("WALLET_TRANSFER")}</span>
              </NavLink>
            </NavItem> : <></>}
            {true ? <NavItem>
              <NavLink
                className={classnames({ active: activeTab === _BONUS_TRACKING_TAB })}
                onClick={() => { toggleTab(_BONUS_TRACKING_TAB); }}>
                <span className="d-sm-none">{t("BONUS_TRACKING")}</span>
                <span className="d-sm-block d-none">{t("BONUS_TRACKING")}</span>
              </NavLink>
            </NavItem> : <></>}
            {true ? <NavItem>
              <NavLink
                className={classnames({ active: activeTab === _COMMISSION_TRACKING_TAB })}
                onClick={() => { toggleTab(_COMMISSION_TRACKING_TAB); }}>
                <span className="d-sm-none">{t("COMMISSION_TRACKING")}</span>
                <span className="d-sm-block d-none">{t("COMMISSION_TRACKING")}</span>
              </NavLink>
            </NavItem> : <></>}
            {true ? <NavItem>
              <NavLink
                className={classnames({ active: activeTab === _POINT_TRACKING_TAB })}
                onClick={() => { toggleTab(_POINT_TRACKING_TAB); }}>
                <span className="d-sm-none">{t("POINT_TRACKING")}</span>
                <span className="d-sm-block d-none">{t("POINT_TRACKING")}</span>
              </NavLink>
            </NavItem> : <></>}
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === _PROFIT_LOSS_TAB })}
                onClick={() => { toggleTab(_PROFIT_LOSS_TAB); }}>
                <span className="d-sm-none">{t("PROFIT_LOSS")}</span>
                <span className="d-sm-block d-none">{t("PROFIT_LOSS")}</span>
              </NavLink>
            </NavItem>
            {true ? <NavItem>
              <NavLink
                className={classnames({ active: activeTab === _BET_DETAIL_TAB })}
                onClick={() => { toggleTab(_BET_DETAIL_TAB); }}>
                <span className="d-sm-none">{t("BET_DETAIL")}</span>
                <span className="d-sm-block d-none">{t("BET_DETAIL")}</span>
              </NavLink>
            </NavItem> : <></>}
            {navigateFromRole !== "CS" ? <NavItem>
              <NavLink
                className={classnames({ active: activeTab === _PRODUCT_TAB })}
                onClick={() => { toggleTab(_PRODUCT_TAB); }}>
                <span className="d-sm-none">{t("PRODUCT")}</span>
                <span className="d-sm-block d-none">{t("PRODUCT")}</span>
              </NavLink>
            </NavItem> : <></>}
            {navigateFromRole !== "CS" ? <NavItem>
              <NavLink
                className={classnames({ active: activeTab === _SPORTS_BET_LIMIT })}
                onClick={() => { toggleTab(_SPORTS_BET_LIMIT); }}>
                <span className="d-sm-none">{t("SPORTS_BET_LIMIT")}</span>
                <span className="d-sm-block d-none">{t("SPORTS_BET_LIMIT")}</span>
              </NavLink>
            </NavItem> : <></>}
            {true ? <NavItem>
              <NavLink
                className={classnames({ active: activeTab === _REFERRAL_TAB })}
                onClick={() => { toggleTab(_REFERRAL_TAB); }}>
                <span className="d-sm-none">{t("GROUP")}</span>
                <span className="d-sm-block d-none">{t("GROUP")}</span>
              </NavLink>
            </NavItem> : <></>}
            {navigateFromRole !== "CS" ? <NavItem>
              <NavLink
                className={classnames({ active: activeTab === _ADJUSTMENT_REPORT_TAB })}
                onClick={() => { toggleTab(_ADJUSTMENT_REPORT_TAB); }}>
                <span className="d-sm-none">{t("ADJUSTMENT_REPORT")}</span>
                <span className="d-sm-block d-none">{t("ADJUSTMENT_REPORT")}</span>
              </NavLink>
            </NavItem> : <></>}
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === _WALLET_BALANCE })}
                onClick={() => { toggleTab(_WALLET_BALANCE); }}>
                <span className="d-sm-none">{t("Wallet Balance")}</span>
                <span className="d-sm-block d-none">{t("Wallet Balance")}</span>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === _ROLL_OVER_STATEMENT })}
                onClick={() => { toggleTab(_ROLL_OVER_STATEMENT); }}>
                <span className="d-sm-none">{t("Roll Over Statement")}</span>
                <span className="d-sm-block d-none">{t("Roll Over Statement")}</span>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === _BONUS_ROLL_OVER_STATEMENT })}
                onClick={() => { toggleTab(_BONUS_ROLL_OVER_STATEMENT); }}>
                <span className="d-sm-none">{t("Bonus Roll Over Statement")}</span>
                <span className="d-sm-block d-none">{t("Bonus Roll Over Statement")}</span>
              </NavLink>
            </NavItem>
            {/*<NavItem>*/}
            {/*  <NavLink*/}
            {/*    className={classnames({ active: activeTab === _PROMOTIONS })}*/}
            {/*    onClick={() => { toggleTab(_PROMOTIONS); }}>*/}
            {/*    <span className="d-sm-none">{t("PROMO_CLAIMS_COIN_ROLLOVER")}</span>*/}
            {/*    <span className="d-sm-block d-none">{t("PROMO_CLAIMS_COIN_ROLLOVER")}</span>*/}
            {/*  </NavLink>*/}
            {/*</NavItem>*/}
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === _PROMOTIONS_OLD })}
                onClick={() => { toggleTab(_PROMOTIONS_OLD); }}>
                <span className="d-sm-none">{t("PROMOTIONS")}</span>
                <span className="d-sm-block d-none">{t("CANCEL")} {t("PROMOTIONS")}</span>
              </NavLink>
            </NavItem>
            {navigateFromRole !== "CS" ? <NavItem>
              <NavLink
                className={classnames({ active: activeTab === _MEMBER_DOWNLINE_GAMES })}
                onClick={() => { toggleTab(_MEMBER_DOWNLINE_GAMES); }}>
                <span className="d-sm-none">{t("DOWNLINE_GAMES")}</span>
                <span className="d-sm-block d-none">{t("DOWNLINE_GAMES")}</span>
              </NavLink>
            </NavItem> : <></>}
            {/*<NavItem>*/}
            {/*    <NavLink*/}
            {/*        className={classnames({ active: activeTab === _MANAGE_MEMBER_BANK_ACCOUNT })}*/}
            {/*        onClick={() => { toggleTab(_MANAGE_MEMBER_BANK_ACCOUNT); }}>*/}
            {/*        <span className="d-sm-none">{t("MEMBER_BANK_ACCOUNT")}</span>*/}
            {/*        <span className="d-sm-block d-none">{t("MEMBER_BANK_ACCOUNT")}</span>*/}
            {/*    </NavLink>*/}
            {/*</NavItem>*/}
          </Nav>}
          <TabContent activeTab={activeTab}>
            <TabPane tabId={_DETAIL_TAB}>
              <MemberDetail id={memberId} parentMemberId={parentMemberId} setParentId={setMemberId} setParentName={setUsername} />
            </TabPane>
            {memberId && <TabPane tabId={_TRANSACTION_TAB}>
              {activeTab === _TRANSACTION_TAB && <div className="row">
                <div className="col-lg-12">
                  <TransactionEnquiry id={memberId} username={username} />
                </div>
              </div>}
            </TabPane>}
            {memberId && <TabPane tabId={_WALLET_TRANSFER_TAB}>
              {activeTab === _WALLET_TRANSFER_TAB && <div className="row">
                <div className="col-lg-12">
                  <WalletTransfer id={memberId} username={username} />
                </div>
              </div>}
            </TabPane>}
            {memberId && <TabPane tabId={_BONUS_TRACKING_TAB}>
              {activeTab === _BONUS_TRACKING_TAB && <div className="row">
                <div className="col-lg-12">
                  <BonusTracking id={memberId} username={username} />
                </div>
              </div>}
            </TabPane>}
            {memberId && <TabPane tabId={_COMMISSION_TRACKING_TAB}>
              {activeTab === _COMMISSION_TRACKING_TAB && <div className="row">
                <div className="col-lg-12">
                  <CommissionTracking id={memberId} username={username} />
                </div>
              </div>}
            </TabPane>}
            {memberId && <TabPane tabId={_POINT_TRACKING_TAB}>
              {activeTab === _POINT_TRACKING_TAB && <div className="row">
                <div className="col-lg-12">
                  <PointTracking id={memberId} username={username} />
                </div>
              </div>}
            </TabPane>}
            {memberId && <TabPane tabId={_PROFIT_LOSS_TAB}>
              {activeTab === _PROFIT_LOSS_TAB && <div className="row">
                <div className="col-lg-12">
                  <ManageProfitLossReport id={memberId} username={username} />
                </div>
              </div>}
            </TabPane>}
            {memberId && <TabPane tabId={_BET_DETAIL_TAB}>
              {activeTab === _BET_DETAIL_TAB && <div className="row">
                <div className="col-lg-12">
                  <MemberManageBetDetailReport id={memberId} username={username} />
                </div>
              </div>}
            </TabPane>}
            {memberId && <TabPane tabId={_PRODUCT_TAB}>
              {activeTab === _PRODUCT_TAB && <div className="row">
                <div className="col-lg-12">
                  <Product id={memberId} username={username} />
                </div>
              </div>}
            </TabPane>}
            {memberId && <TabPane tabId={_SPORTS_BET_LIMIT}>
              {activeTab === _SPORTS_BET_LIMIT && <div className="row">
                <div className="col-lg-12">
                  <MemberSportsBetLimit id={memberId} username={username} />
                </div>
              </div>}
            </TabPane>}
            {memberId && <TabPane tabId={_REFERRAL_TAB}>
              {activeTab === _REFERRAL_TAB && <div className="row">
                <div className="col-lg-12">
                  <ReferralDetail id={memberId} username={username} />
                </div>
              </div>}
            </TabPane>}
            {memberId && <TabPane tabId={_ADJUSTMENT_REPORT_TAB}>
              {activeTab === _ADJUSTMENT_REPORT_TAB && <div className="row">
                <div className="col-lg-12">
                  <AdjustmentReport id={memberId} />
                </div>
              </div>}
            </TabPane>}
            {memberId && <TabPane tabId={_WALLET_BALANCE}>
              {activeTab === _WALLET_BALANCE && <div className="row">
                <div className="col-lg-12">
                  <WalletBalance id={memberId} />
                </div>
              </div>}
            </TabPane>}
            {memberId && <TabPane tabId={_ROLL_OVER_STATEMENT}>
              {activeTab === _ROLL_OVER_STATEMENT && <div className="row">
                <div className="col-lg-12">
                  <RollOverStatement id={memberId} />
                </div>
              </div>}
            </TabPane>}
            {memberId && <TabPane tabId={_BONUS_ROLL_OVER_STATEMENT}>
              {activeTab === _BONUS_ROLL_OVER_STATEMENT && <div className="row">
                <div className="col-lg-12">
                  <BonusRollOverStatement id={memberId} />
                </div>
              </div>}
            </TabPane>}
            {memberId && <TabPane tabId={_MEMBER_DOWNLINE_GAMES}>
              {activeTab === _MEMBER_DOWNLINE_GAMES && <div className="row">
                <div className="col-lg-12">
                  <ManageMemberDownlineGames id={memberId} />
                </div>
              </div>}
            </TabPane>}
            {/*{memberId && <TabPane tabId={_MANAGE_MEMBER_BANK_ACCOUNT}>*/}
            {/*    {activeTab === _MANAGE_MEMBER_BANK_ACCOUNT && <div className="row">*/}
            {/*    <div className="col-lg-12">*/}
            {/*            <ManageMemberBankAccount id={memberId} />*/}
            {/*    </div>*/}
            {/*  </div>}*/}
            {/*</TabPane>}*/}
            {/*{memberId && <TabPane tabId={_PROMOTIONS}>*/}
            {/*  {activeTab === _PROMOTIONS && <div className="row">*/}
            {/*    <div className="col-lg-12">*/}
            {/*      <PromoAndCoinRollover id={memberId} username={username} />*/}
            {/*    </div>*/}
            {/*  </div>}*/}
            {/*</TabPane>}*/}
            {memberId && <TabPane tabId={_PROMOTIONS_OLD}>
              {activeTab === _PROMOTIONS_OLD && <div className="row">
                <div className="col-lg-12">
                  <MemberPromotionStatement id={memberId} username={username} />
                </div>
              </div>}
            </TabPane>}
          </TabContent>
        </div>
      </div>
    </div>
  )
}

export default withRouter(ViewAccount);
