import React, { useState } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { useDispatch, useSelector } from "react-redux";
import { performLogout } from "../../../redux/AuthAction.js";
import { withRouter } from 'react-router';
import { WebUrl, Role, LanguageKey, LoginType } from "../../../util/Constant";
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

/// <summary>
/// Author : -
/// </summary>
const DropdownProfile = props => {
	const { t } = useTranslation(LanguageKey._PRIMARY);
	const [dropdownOpen, setDropdownOpen] = useState(false);
	const _history = useHistory();
	const _userData = useSelector(state => state.authState.userData);
	const _dispatch = useDispatch();

	/// <summary>
	/// Author : -
	/// </summary>
	const toggle = () => {
		setDropdownOpen(!dropdownOpen);
	}

	return (
		<Dropdown isOpen={dropdownOpen} toggle={toggle} className="dropdown navbar-user" tag="li">
			<DropdownToggle tag="a">
				<span className="d-none d-md-inline">{_userData.username}</span> <b className="caret"></b>
			</DropdownToggle>
			<DropdownMenu className="dropdown-menu dropdown-menu-right" tag="ul">
				{_userData.loginType == LoginType._LOGIN_TYPE_USER && <><DropdownItem onClick={() => _history.push(_userData.userRoleId == Role._SUPER_COMPANY ? WebUrl._URL_SUPER_ACCOUNT : WebUrl._URL_COMPANY_PROFILE)}>{t("EDIT_PROFILE")}</DropdownItem>
				<DropdownItem onClick={() => _history.push(WebUrl._URL_CHANGE_PASSWORD)}>{t("CHANGE_PASSWORD")}</DropdownItem>
				<DropdownItem onClick={() => _history.push(WebUrl._URL_CHANGE_PIN_NUMBER)}>{t("CHANGE_PIN_NUMBER")}</DropdownItem>
				<div className="dropdown-divider"></div></>}
				<DropdownItem onClick={() => _dispatch(performLogout())}>{t("LOGOUT")}</DropdownItem>
			</DropdownMenu>
		</Dropdown>
	);
}

export default withRouter(DropdownProfile);
