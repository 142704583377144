import store from "../ApplicationStore.js";
import { AccessRight, Status } from "./Constant";

//variable here will be long live throughout the process, this is intented
var _permissionArrKey = [];

/// <summary>
/// Author : -
/// </summary>
export function checkIfPermissionExist(permissionString, isWrite) {
    //let temp = permissionString.split(' ');
    //let match = '';
    //temp.map((word) => {
    //  match += word.charAt(0);
    //});
    if (_permissionArrKey.length == 0 && store.getState()["authState"]["userData"]["accessString"] !== undefined) {
        var userPermissionData = store.getState()["authState"]["userData"]["accessString"].split(";");
        for (var i = 0; i < userPermissionData.length; i++) {
            var keyValuePermission = userPermissionData[i].split(":");
            if (keyValuePermission.length == 2) {
                if (keyValuePermission[1][AccessRight._READ_BIT_POSITION] == Status._ENABLED.toString()) {
                    _permissionArrKey.push(keyValuePermission[0] + AccessRight._READ_PERMISSION_STRING);
                }
                if (keyValuePermission[1][AccessRight._WRITE_BIT_POSITION] == Status._ENABLED.toString()) {
                    _permissionArrKey.push(keyValuePermission[0] + AccessRight._WRITE_PERMISSION_STRING);
                }
            }
        }
    }

    var completePermissionKey = permissionString + (isWrite ? AccessRight._WRITE_PERMISSION_STRING : AccessRight._READ_PERMISSION_STRING);
    //var completePermissionKey = match + (isWrite ? AccessRight._WRITE_PERMISSION_STRING : AccessRight._READ_PERMISSION_STRING);
    return _permissionArrKey.filter(item => item == completePermissionKey).length != 0;
}

/// <summary>
/// Author : -
/// this expected to be call on logout so that next login can fetch new data
/// </summary>
export function clearPermissionData() {
    _permissionArrKey = [];
}