import appsettings from "../appsettings-fe.json";
import firebase from "firebase";
import { createFormBody } from "../util/Util";
import { ApiUrl, ApiKey } from "../util/Constant";

const _FIREBASE_CONFIG = {
  appId: appsettings.ApplicationConfig.firebaseAppId,
  apiKey: appsettings.ApplicationConfig.firebaseApiKey,
  messagingSenderId: appsettings.ApplicationConfig.firebaseMessagingSenderId,
  projectId: appsettings.ApplicationConfig.firebaseProjectId,
};

export const _INIT_CONFIG = "initConfig";
export const _UPDATE_CONTENT = "updateContent";
export const _CLEAR_MESSAGE = "clearMessage";

export function initFirebase() {
  return async (dispatch) => {
    if (!firebase.apps.length) {
      firebase.initializeApp(_FIREBASE_CONFIG);
    }

    var deviceToken = "";

    try {
      navigator.serviceWorker
        .register("/firebase-messaging-sw.js")
        .then((registration) => {
          const messaging = firebase.messaging();

          messaging
            .requestPermission()
            .then(function (permission) {
              return messaging.getToken();
            })
            .then(function (token) {
              deviceToken = token;

              fetch(ApiUrl._API_UPDATE_DEVICE_TOKEN, {
                method: ApiKey._API_POST,
                headers: {
                  "Content-Type": ApiKey._API_FORM_URLENCODED,
                  "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
                },
                body: createFormBody({
                  deviceToken: deviceToken,
                }),
              });

              messaging.onMessage(async (payload) => {
                dispatch({
                  type: _UPDATE_CONTENT,
                  dataContent: payload.data,
                });
              });
            })
            .catch((err) => {
              console.error(err);
            });
        });
    } catch (error) {
      console.error(error);
    }

    dispatch({
      type: _INIT_CONFIG,
    });
  };
}

export const clearMessage = () => {
  return async (dispatch) => {
    dispatch({
      type: _CLEAR_MESSAGE,
    });
  };
};
