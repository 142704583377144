import React, { useState, useEffect } from "react";
import ReactTable from "../../components/constantComponent/reactTable/ReactTable";
import { Panel, PanelBody } from "./../../components/panel/panel.jsx";
import "../../assets/css/custom.css";
import { useHistory } from "react-router-dom";
import {
    UncontrolledButtonDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from "reactstrap";
import { navigateTo } from "../../util/Util";
import { ApiKey, ApiUrl, WebUrl, LanguageKey } from "../../util/Constant";
import { setBusy, setIdle } from "../../redux/AppAction";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { fetch } from "whatwg-fetch";

/// <summary>
/// Author : -
/// </summary>

const ProductMaintenance = (props) => {
    const { t } = useTranslation(LanguageKey._PRIMARY);
    const _dispatch = useDispatch();
    let _history = useHistory();
    const [productData, setProductData] = useState([]);
    const { loginUserId } = useSelector((state) => ({
        loginUserId: state.authState.userData.userId,
    }));

    let _tableColumns = [
        {
            Header: "NAME",
            accessor: "productName",
        },
        {
            Header: "ROYALTY",
            accessor: "royalty",
            Cell: ({ row }) => (
                 <>
                    { row.original.id > 0 && <div className="text-center">{row.original.royalty}%</div>}
                 </>
            ),
            disableSortBy: true,
            style: { overflow: "visible" },
        },
        /*{
            Header: "MARK UP",
            accessor: "markup",
            Cell: ({ row }) => (
                <div className="text-center">{row.original.markup}%</div>
            ),
            disableSortBy: true,
            style: { overflow: "visible" },
        },*/
        {
            Header: "STATUS",
            accessor: "status",
            Cell: ({ row }) => (
                 <>
                    { row.original.id > 0 && <div className="btn-group m-r-5">
                        {row.original.status == 1 ? (
                            <span className="badge badge-secondary badge-green">
                                {t("ACTIVE")}
                            </span>
                        ) : (
                                <span className="badge badge-secondary badge-danger">
                                    {t("MAINTENANCE")}
                                </span>
                            )}
                    </div>}
                 </>
            ),
            disableSortBy: true,
            style: { overflow: "visible" },
        },
        {
            id: "action",
            Header: "",
            Cell: ({ row }) => (
                <>
                    { row.original.id > 0 && <div className="btn-group m-r-5 m-b-5">
                        <UncontrolledButtonDropdown>
                            <DropdownToggle caret color="default">
                                <i className="fas fa-cog"></i>
                            </DropdownToggle>
                            <DropdownMenu>
                                <DropdownItem
                                    onClick={() => {
                                        navigateTo(
                                            _history,
                                            WebUrl._URL_SHAREHOLDERS_PRODUCTS_EDIT,
                                            row.original
                                        );
                                    }}
                                >
                                    <span className="text-primary">
                                        {t("EDIT_ROYALTY")}
                                    </span>
                                </DropdownItem>
                            </DropdownMenu>
                        </UncontrolledButtonDropdown>
                    </div> }
                </>
            ),
            disableSortBy: true,
            disableFilters: true,
            width: 100,
            style: { overflow: "visible" },
        },
    ];

    /// <summary>
    /// Author : -
    /// </summary>
    function updateProductData() {
        fetch(ApiUrl._API_GET_SHAREHOLDER_MASTER_PRODUCT_DATA_LIST, {
            method: ApiKey._API_GET,
            headers: {
                "Content-Type": ApiKey._API_FORM_URLENCODED,
                "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
            },
        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                    setProductData(responseJson[ApiKey._API_DATA_KEY].data);
                }
            });
    }

    /// <summary>
    /// Author : -
    /// </summary>
    useEffect(() => {
        updateProductData();
    }, []);

    return (
        <div>
            <h1 className="page-header">{t("SHAREHOLDERS_PRODUCTS")}</h1>
            <Panel>
                <PanelBody>
                    <ReactTable data={productData} columns={_tableColumns} />
                </PanelBody>
            </Panel>
        </div>
    );
};

export default ProductMaintenance;
