import React, { useState, useEffect } from "react";
import ReactTable from "../../components/constantComponent/reactTable/ReactTable";
import {
    ApiUrl,
    ApiKey,
    LanguageKey,
} from "../../util/Constant";
import { useTranslation } from "react-i18next";
import ApiEngine from "../../util/ApiEngine";
import { useDispatch } from "react-redux";
import Icon from '@mdi/react';
import classnames from 'classnames';
import { mdiPauseCircleOutline, mdiPlayCircleOutline } from '@mdi/js';


const LiveMonitor = props => {
    const _dispatch = useDispatch();
    const { t } = useTranslation(LanguageKey._PRIMARY);
    const [liveData, setLiveData] = useState([]);
    const [dataToDisplay, setDataToDisplay] = useState([]);
    const [isPaused, setIsPaused] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [intervalId, setIntervalId] = useState();
    const [filterAmt, setFilterAmt] = useState(0);
    const [currentCategory, setCurrentCategory] = useState("All");
    const [currentRiskLevel, setCurrentRiskLevel] = useState(-1);
    const [allAvailableCategory, SetAllAvailableCategory] = useState([]);

    const _risk_level_color = [
        { value: -1, label: "normal" },
        { value: -99, label: "purple"},
        { value: 0, label: "normal" },
        { value: 1, label: "yellow" },
        { value: 2, label: "green" },
        { value: 3, label: "red" },
        { value: 4, label: "blue" },
        { value: 5, label: "grey" }
    ];

    const _defaultTableColumns = [

        {
            Header: t("ID") + " (" + t("RISK_LEVEL_SHORT") + ") ",
            accessor: "id",
            Cell: ({ row }) => {
                let riskId = _risk_level_color.find(x => x.value === row.original.riskLevel);
                return (
                    <div className={"live-data-cell live-data-cell background-color-" + riskId.label}>
                        {row.original.id + " (" + (riskId.value === -99 ? "New" : riskId.value) + ") "}
                    </div>
                );
            },
        },
        //{
        //    Header: "Description",
        //    Cell: ({ row }) => {
        //        return (
        //            <div className={"livedata-row live-data-cell background-color-" + (_risk_level_color.find(x => x.value === row.original.riskLevel).label)}>
        //                {row.original.betId}
        //            </div>
        //        );
        //    },
        //},
        {
            Header: t("DATE"),
            accessor: "date",
            minWidth: 100,
            Cell: ({ row }) => {
                return (
                    <div className={"live-data-cell background-color-" + (_risk_level_color.find(x => x.value === row.original.riskLevel).label)}>{row.original.date}</div>
                );
            },
        },
        {
            Header: t("USERNAME"),
            accessor: "memberUsername",
            minWidth: 100,
            Cell: ({ row }) => {
                return (
                    <div className={"live-data-cell background-color-" + (_risk_level_color.find(x => x.value === row.original.riskLevel).label)}>{row.original.memberUsername}</div>
                );
            },
        },
        {
            Header: t("PRODUCT_NAME"),
            accessor: "productName",
            Cell: ({ row }) => {
                return (
                    <div className={"live-data-cell background-color-" + (_risk_level_color.find(x => x.value === row.original.riskLevel).label)}>{row.original.productName}</div>
                );
            },
        },
        //{
        //    Header: t("PLAYERID"),
        //    accessor: "username",
        //    minWidth: 100,
        //    Cell: ({ row }) => {
        //        return (
        //            <div className={"live-data-cell background-color-" + (_risk_level_color.find(x => x.value === row.original.riskLevel).label)}>{row.original.username}</div>
        //        );
        //    },
        //},
        //{
        //    Header: t("GAME_NAME"),
        //    accessor: "gameName",
        //    minWidth: 100,
        //    Cell: ({ row }) => {
        //        return (
        //            <div className={"live-data-cell background-color-" + (_risk_level_color.find(x => x.value === row.original.riskLevel).label)}>{row.original.gameName}</div>
        //        );
        //    },
        //},
        {
            Header: t("BET_AMOUNT"),
            accessor: "amount",
            minWidth: 100,
            Cell: ({ row }) => {
                return <div className={"text-align-right live-data-cell background-color-" + (_risk_level_color.find(x => x.value === row.original.riskLevel).label)}>{row.original.amount.toFixed(2)}</div>;
            },
        },
    ];

    useEffect(() => {
        //getLatestData();
        //let tempIntervalId = setInterval(() => getLatestData(), 5000);
        //setIntervalId(tempIntervalId);
    }, []);

    const getLatestData = async () => {
        var apiUrl = ApiUrl._API_GET_LIVE_DATA + "?amount=" + filterAmt + "&category=" + currentCategory + "&riskLevel=" + currentRiskLevel;
        fetch(apiUrl, {
            method: ApiKey._API_GET,
            headers: {
                "Accept": ApiKey._API_APPLICATION_JSON,
                "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY
            }
        }).then((response) => response.json()).then((responseJson) => {
            if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                if (responseJson[ApiKey._API_DATA_KEY] != null) {
                    let data = responseJson[ApiKey._API_DATA_KEY];
                    setLiveData(data);
                    var categoryApiUrl = ApiUrl._API_GET_PRODUCT_CATEGORY + "?all=true";
                    fetch(categoryApiUrl, {
                        method: ApiKey._API_GET,
                        headers: {
                            "Accept": ApiKey._API_APPLICATION_JSON,
                            "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY
                        }
                    }).then((response) => response.json()).then((responseJson) => {
                        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                            let categoryData = responseJson[ApiKey._API_DATA_KEY];
                            let categoryList = [];
                            categoryData.map((category, index) => {
                                //if (category.originalTitle === "All" || data.filter(x => x.categoryName === category.originalTitle).length > 0) {
                                categoryList.push(category.originalTitle);
                                //}
                            });
                            SetAllAvailableCategory(categoryList);
                        }
                    });
                }
                else {
                    setLiveData([]);
                }
            }
        });
        setIsLoading(false);
    }

    useEffect(() => {
        //let tempData = liveData.filter(x => (x.categoryName === currentCategory || currentCategory === "All") && x.amount >= filterAmt);
        //setDataToDisplay(tempData);
        clearInterval(intervalId);
        getLatestData();
        if (!isPaused) {
            let tempIntervalId = setInterval(() => {
                setIsLoading(true);
                getLatestData();
            }, 10000);
            setIntervalId(tempIntervalId);
        }
    }, [filterAmt, currentCategory, currentRiskLevel]);

    /// <summary>
    /// Author : -
    /// </summary>
    const toggleCategory = (categoryTitle) => {
        if (currentCategory !== categoryTitle) {
            setCurrentCategory(categoryTitle);
        }
    }

    const toggleRiskLevel = (riskLevel) => {
        if (currentRiskLevel !== riskLevel) {
            setCurrentRiskLevel(riskLevel);
        }
    }

    const togglePause = () => {
        let tempIsPaused = isPaused;
        if (tempIsPaused === false) { //clear loop before pausing
            clearInterval(intervalId);
        }
        else { //start back loop before resume
            getLatestData();
            let tempIntervalId = setInterval(() => {
                setIsLoading(true);
                getLatestData();
            }, 5000);
            setIntervalId(tempIntervalId);
        }
        setIsPaused(!isPaused);
    }

    return (
        <>
            <div id="live-monitor">
                <div className="filter-section">
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        {allAvailableCategory.length > 0 && <div style={{ display: "flex", alignItems: "center", fontSize: "16px", fontWeight: "bold" }}>
                            {t("FILTER_BY") + " " + t("CATEGORY") + " : "}
                            {allAvailableCategory.map((categoryTitle, index) => {
                                return (<>
                                    <div onClick={() => toggleCategory(categoryTitle)} className={"live-data-category " + classnames({ active: currentCategory === categoryTitle })}>{categoryTitle}</div>
                                    {index !== (allAvailableCategory.length - 1) && <hr className="vertical-hr" />}
                                </>)
                            })}
                        </div>}
                        <div style={{ display: "flex", alignItems: "center", fontSize: "16px", fontWeight: "bold" }}>
                            {t("FILTER_BY") + " " + t("RISK_LEVEL") + " : "}
                            {_risk_level_color.map((riskLevel, index) => {
                                return (<>
                                    <div onClick={() => toggleRiskLevel(riskLevel.value)} className={"live-data-risk-level " + classnames({ active: currentRiskLevel === riskLevel.value })}>{riskLevel.value === -1 ? "All" : riskLevel.value === -99 ? "New" : riskLevel.value}</div>
                                    {index !== (_risk_level_color.length - 1) && <hr className="vertical-hr" />}
                                </>)
                            })}
                        </div>
                    </div>
                    <div style={{ display: "flex" }}>
                        <label htmlFor="dataAmount" style={{ marginBottom: "0", marginRight: "5px", fontSize: "15px", fontWeight: "bolder" }}>{t("FILTER_BET_AMOUNT") + ": >="}</label>
                        <div>
                            <input
                                type="number"
                                min="0"
                                step="1"
                                id="dataAmount"
                                className="data-amount-filter"
                                onChange={(e) => {
                                    clearInterval(intervalId);
                                    setFilterAmt(e.target.valueAsNumber);
                                }}
                            />
                        </div>
                        <div onClick={() => togglePause()} style={{ marginLeft: "25px" }}>
                            <Icon
                                path={isPaused ? mdiPlayCircleOutline : mdiPauseCircleOutline}
                                size={1.33}
                                color="black"
                            />
                        </div>
                    </div>
                </div>
                <hr />
                <div>
                    <div style={{ height: "30px", fontSize: "13px" }}>{isLoading && <span>{t("LOADING_NEW_DATA")}</span>}</div>
                    <ReactTable
                        className=" live-monitor"
                        data={liveData}
                        columns={_defaultTableColumns}
                        globalFilterable={false}
                        initialPageSize={50}
                    />
                </div>
                <div style={{ marginTop: "15px" }}>
                    <div style={{ marginBottom: "10px" }}><b style={{ fontSize: "15px" }}>Legend :</b></div>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        {_risk_level_color.filter(x => x.label !== "normal").map((riskLevel, index) => {
                            return (
                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", marginBottom: "10px" }}>
                                    <div className={"background-color-" + riskLevel.label} style={{ height: "20px", width: "20px", backgroundColor: riskLevel.colorCode, marginRight: "5px" }}></div>
                                    <div style={{ fontSize: "15px" }}>{"- " + (riskLevel.value === -99 ? t("NEW_MEMBER") : t("RISK_LEVEL") + " (" + riskLevel.value + ") ")}</div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        </>
    )
}

export default LiveMonitor;