import React, { useState, useEffect } from "react";
import {
	Panel,
	PanelBody,
	PanelHeader,
} from "./../../components/panel/panel.jsx";
import { useForm } from "react-hook-form";
import { ApiKey, ApiUrl, Role, Status, LanguageKey } from "../../util/Constant";
import { stringIsNullOrEmpty, createFormBody } from "../../util/Util";
import { useLocation, useHistory } from "react-router-dom";
import NavigationButton from "../../components/constantComponent/button/NavigationButton";
import { setBusy, setIdle, showMessage } from "../../redux/AppAction";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import IntlTelInput from "react-intl-tel-input";
import "react-intl-tel-input/dist/main.css";
import { fetch } from "whatwg-fetch";
import PinInput from "react-pin-input";
import ApiEngine from "../../util/ApiEngine.js";
import { navigateTo } from "../../util/Util";
import {
	Button,
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
	FormFeedback,
	Input,
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader,
	UncontrolledButtonDropdown,
} from "reactstrap";
import ReactTable from "../../components/constantComponent/reactTable/ReactTable.js";

/// <summary>
/// Author : -
/// </summary>
const CompanyDetail = (props) => {
	const { t } = useTranslation(LanguageKey._PRIMARY);
	const _location = useLocation();
	const _history = useHistory();
	const _dispatch = useDispatch();
	const {
		register,
		handleSubmit,
		errors,
		setValue,
		getValues,
		trigger,
		formState,
		setError,
		clearErrors,
	} = useForm();
	const [username, setUsername] = useState("");
	const [password, setPassword] = useState("");
	const [confirmPassword, setConfirmPassword] = useState("");
	const [fullName, setFullName] = useState("");
	const [phone, setPhone] = useState("");
	const [email, setEmail] = useState("");
	const [userId, setUserId] = useState("");
	const [status, setStatus] = useState(true);
	const [credit, setCredit] = useState(0);
	const [timeZone, setTimeZone] = useState();
	const { userRoleId, loginUserId } = useSelector((state) => ({
		userRoleId: state.authState.userData.userRoleId,
		loginUserId: state.authState.userData.userId,
	}));
	const [transactionPinNumber, setTransactionPinNumber] = useState("");
	const [pinEntered, setPinEntered] = useState(false);
	const [domainList, setDomainList] = useState([]);
	const [selectedDomain, setSelectedDomain] = useState();
	const [isToggleDomainModal, setIsToggleDomainModal] = useState(false);
	const [isToggleDeleteDomainModal, setIsToggleDeleteDomainModal] =
		useState(false);
	const [requiredPassword, setRequiredPassword] = useState(false);
	const [requiredConfirmPassword, setRequiredConfirmPassword] = useState(false);

	const [domainUrl, setDomainUrl] = useState(null);
	const [isDomainValid, setIsDomainValid] = useState(true);
	const [isPhoneValid, setIsPhoneValid] = useState(true);

	var _TIMEZONE_OPTION = [
		{ label: "(GMT-11:00) Pago Pago", value: -11 },
		{ label: "(GMT-10:00) Pacific/Honolulu", value: -10 },
		{ label: "(GMT-08:00) America/Los_Angeles", value: -8 },
		{ label: "(GMT-07:00) America/Denver", value: -7 },
		{ label: "(GMT-06:00) America/Chicago", value: -6 },
		{ label: "(GMT-05:00) America/New_York", value: -5 },
		{ label: "(GMT-04:00) America/Guyana", value: -4 },
		{ label: "(GMT-03:00) America/Santiago", value: -3 },
		{ label: "(GMT-02:00) America/Sao_Paulo", value: -2 },
		{ label: "(GMT-01:00) Atlantic/Azores", value: -1 },
		{ label: "(GMT+00:00) Europe/London", value: 0 },
		{ label: "(GMT+01:00) Europe/Berlin", value: 1 },
		{ label: "(GMT+02:00) Europe/Athens", value: 2 },
		{ label: "(GMT+03:00) Europe/Istanbul", value: 3 },
		{ label: "(GMT+04:00) Europe/Samara", value: 4 },
		{ label: "(GMT+05:00) Asia/Karachi", value: 5 },
		{ label: "(GMT+06:00) Asia/Dhaka", value: 6 },
		{ label: "(GMT+07:00) Asia/Bangkok", value: 7 },
		{ label: "(GMT+08:00) Asia/Singapore", value: 8 },
		{ label: "(GMT+09:00) Asia/Seoul", value: 9 },
		{ label: "(GMT+10:00) Asia/Magadan", value: 10 },
		{ label: "(GMT+11:00) Australia/Sydney", value: 11 },
		{ label: "(GMT+12:00) Pacific/Majuro", value: 12 },
		{ label: "(GMT+13:00) Pacific/Auckland", value: 13 },
		{ label: "(GMT+14:00) Pacific/Apia", value: 14 },
	];

	let _tableColumns = [
		{
			Header: "Domain Url",
			accessor: "domainUrl",
			disableSortBy: true,
		},

		{
			id: "action",
			Header: "",
			Cell: ({ row }) => (
				<div className="btn-group m-r-5 m-b-5">
					<UncontrolledButtonDropdown>
						<DropdownToggle caret color="default">
							<i className="fas fa-cog"></i>
						</DropdownToggle>

						<DropdownMenu>
							<DropdownItem onClick={() => toggleDomainModal(row.original.id)}>
								<span className="text-warning">{t("EDIT")}</span>
							</DropdownItem>
							<DropdownItem
								onClick={() => toggleDeleteDomainModal(row.original.id)}
							>
								<span className="text-warning">{t("DELETE")}</span>
							</DropdownItem>
						</DropdownMenu>
					</UncontrolledButtonDropdown>
				</div>
			),
			disableSortBy: true,
			disableFilters: true,
			width: 100,
			style: { overflow: "visible" },
		},
	];

	async function checkPinValue(data, e) {
		// check if pin has 4 digits (valid pin)
		// if true then save pin
		if (data.length == 4) {
			setPinEntered(true);
		} else {
			setPinEntered(false);
		}
	}

	/// <summary>
	/// Author : -
	/// </summary>
	const submitForm = async (data, e) => {
		if (data.password) {
			if (data.password != data.confirmPassword) {
				_dispatch(
					showMessage(false, t("CONFIRM_PASSWORD_AND_PASSWORD_IS_NOT_TALLY"))
				);
				return;
			}
		}

		if (!pinEntered && transactionPinNumber != "") {
			_dispatch(showMessage(false, t("INVALID_PIN_NUMBER")));
			return;
		}

		let params = {
			username: data.username,
			// password: data.password,
			email: data.email,
			phoneNumber: phone,
			userFullName: data.fullName,
			credit: data.credit != undefined ? data.credit : credit,
			roleId: data.role,
			timeZone: data.timeZone,
			userStatus: data.status ? Status._ENABLED : Status._DISABLED,
			transactionPinNumber: transactionPinNumber,
		};

		if (requiredPassword) {
			params = {
				...params,
				password: data.password,
			};
		}

		if (Role._COMPANY != userRoleId) {
			params["isDownline"] = Status._ENABLED;
		}

		if (!stringIsNullOrEmpty(userId)) {
			params["id"] = userId;
		}

		if (Role._SUPER_COMPANY == userRoleId) {
			params["isCreateOrUpdateCompany"] = true;
		}

		let formBody = createFormBody(params);

		_dispatch(setBusy());

		fetch(ApiUrl._API_CREATE_OR_UPDATE_ACCOUNT, {
			method: ApiKey._API_POST,
			headers: {
				"Content-Type": ApiKey._API_FORM_URLENCODED,
				"X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
			},
			body: formBody,
		})
			.then((response) => response.json())
			.then((responseJson) => {
				_dispatch(setIdle());

				_dispatch(
					showMessage(
						responseJson[ApiKey._API_SUCCESS_KEY],
						t(responseJson[ApiKey._API_MESSAGE_KEY])
					)
				);
				if (responseJson[ApiKey._API_SUCCESS_KEY]) {
					setUserId(responseJson[ApiKey._API_DATA_KEY]["id"]);
				}
			});
	};

	/// <summary>
	/// Author : -
	/// </summary>
	useEffect(() => {
		register({ name: "timeZone" }, { required: "PLEASE_ENTER_TIMEZONE" });
		register({ name: "phone" }, { required: "PLEASE_ENTER_PHONE" });

		//register(
		//  { name: "phone" },
		//  {
		//    required: "PLEASE_ENTER_PHONE",
		//    validate: {
		//      exist: (value) => value != "INVALID_PHONE" || "INVALID_PHONE",
		//    },
		//  }
		//);

		setRequiredConfirmPassword("PLEASE_ENTER_CONFIRM_PASSWORD");
		setRequiredPassword("PLEASE_ENTER_PASSWORD");
		var targetId = "";

		if (_location.state) {
			var targetId = _location.state.id;
			setUserId(_location.state.id);
		}

		if (props["viewOwnProfile"]) {
			targetId = loginUserId;
		}

		if (!stringIsNullOrEmpty(targetId)) {
			setRequiredConfirmPassword(false);
			setRequiredPassword(false);
			var apiUrl = ApiUrl._API_GET_ACCOUNT;
			apiUrl += "?userId=" + targetId;

			fetch(apiUrl, {
				method: ApiKey._API_GET,
				headers: {
					"Content-Type": ApiKey._API_FORM_URLENCODED,
					"X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
				},
			})
				.then((response) => response.json())
				.then((responseJson) => {
					if (responseJson[ApiKey._API_SUCCESS_KEY]) {
						setUsername(responseJson[ApiKey._API_DATA_KEY]["username"]);
						setPhone(responseJson[ApiKey._API_DATA_KEY]["phoneNumber"]);
						setFullName(responseJson[ApiKey._API_DATA_KEY]["userFullName"]);
						setPassword(responseJson[ApiKey._API_DATA_KEY]["password"]);
						setConfirmPassword(responseJson[ApiKey._API_DATA_KEY]["password"]);
						setCredit(responseJson[ApiKey._API_DATA_KEY]["credit"]);
						setEmail(responseJson[ApiKey._API_DATA_KEY]["email"]);
						setUserId(responseJson[ApiKey._API_DATA_KEY]["id"]);
						setStatus(responseJson[ApiKey._API_DATA_KEY]["userStatus"]);
						setValue(
							"timeZone",
							responseJson[ApiKey._API_DATA_KEY]["timeZone"]
						);
						setTimeZone(responseJson[ApiKey._API_DATA_KEY]["timeZone"]);
						setValue(
							"phone",
							responseJson[ApiKey._API_DATA_KEY]["phoneNumber"]
						);
					}
				});

			fetchUserDomains(targetId);
		}
	}, []);

	/// <summary>
	/// Author -
	/// </summary>
	const fetchUserDomains = async (id) => {
		let fetchUrl = ApiUrl._API_GET_USER_DOMAIN;
		fetchUrl += "?UserId=" + id;
		var responseJson = await ApiEngine.get(fetchUrl);
		setDomainList(responseJson[ApiKey._API_DATA_KEY]);
	};
	const onMobileChange = (isValid, newNumber, countryData, fullNumber, ext) => {
		setPhone(newNumber);
		let formattedNumber = fullNumber.split(/[- ]+/).join("").replace("+", "");

		register("phone", {
			validate: (value) => {
				if (!isValid) {
					return "INVALID_PHONE";
				} else {
					clearErrors("phone");
				}
			},
		});
		if (isValid) {
			setValue("phone", formattedNumber);
		} else {
			var isEmptyNumber = stringIsNullOrEmpty(formattedNumber);
			setValue("phone", isEmptyNumber ? "" : "INVALID_PHONE");
		}

		trigger("phone");
	};
	const onChangePassword = (e) => {
		let value = e.target.value;
		if (userId) {
			if (value) {
				setRequiredConfirmPassword("PLEASE_ENTER_CONFIRM_PASSWORD");
				setRequiredPassword("PLEASE_ENTER_PASSWORD");
			} else {
				setRequiredConfirmPassword(false);
				setRequiredPassword(false);
			}
		}
	};

	const onChangeConfirmPassword = (e) => {
		let value = e.target.value;
		if (userId) {
			if (value) {
				setRequiredConfirmPassword("PLEASE_ENTER_CONFIRM_PASSWORD");
				setRequiredPassword("PLEASE_ENTER_PASSWORD");
			} else {
				setRequiredConfirmPassword(false);
				setRequiredPassword(false);
			}
		}
	};
	const onChangeDomainUrl = (e) => {
		let value = e.target.value;
		if (value) {
			setIsDomainValid(true);
		} else {
			setIsDomainValid(false);
		}
		let domainObject = {
			...selectedDomain,
			domainUrl: value,
		};
		setSelectedDomain(domainObject);
	};
	const toggleDomainModal = async (id = null) => {
		if (id) {
			let fetchUrl = ApiUrl._API_GET_USER_DOMAIN_ID;
			fetchUrl += "?Id=" + id;
			var responseJson = await ApiEngine.get(fetchUrl);
			setSelectedDomain(responseJson[ApiKey._API_DATA_KEY]);
		} else {
			setSelectedDomain(null);
		}
		setIsToggleDomainModal(!isToggleDomainModal);
	};
	const toggleDeleteDomainModal = async (id = null) => {
		if (id) {
			let fetchUrl = ApiUrl._API_GET_USER_DOMAIN_ID;
			fetchUrl += "?Id=" + id;
			var responseJson = await ApiEngine.get(fetchUrl);
			setSelectedDomain(responseJson[ApiKey._API_DATA_KEY]);
		} else {
			setSelectedDomain(null);
		}
		setIsToggleDeleteDomainModal(!isToggleDeleteDomainModal);
	};
	const updateOrCreateDomain = async () => {
		if (selectedDomain?.domainUrl) {
			let params = {
				id: selectedDomain.id ? selectedDomain.id : null,
				domainUrl: selectedDomain.domainUrl,
				userId: userId,
			};
			let formBody = createFormBody(params);
			let responseJson = await ApiEngine.post(
				ApiUrl._API_UPDATE_OR_CREATE_USER_DOMAIN,
				formBody
			);
			if (userId) {
				fetchUserDomains(userId);
			}
			setIsToggleDomainModal(false);
			_dispatch(
				showMessage(
					responseJson[ApiKey._API_SUCCESS_KEY],
					t(responseJson[ApiKey._API_MESSAGE_KEY])
				)
			);
		} else {
			setIsDomainValid(false);
		}
	};
	const deleteDomain = async () => {
		let params = {
			id: selectedDomain.id,
		};

		let formBody = createFormBody(params);
		let responseJson = await ApiEngine.post(
			ApiUrl._API_REMOVE_USER_DOMAIN,
			formBody
		);

		fetchUserDomains(userId);
		setIsToggleDeleteDomainModal(false);
		_dispatch(
			showMessage(
				responseJson[ApiKey._API_SUCCESS_KEY],
				t(responseJson[ApiKey._API_MESSAGE_KEY])
			)
		);
	};

	return (
		<div>
			<h1 className="page-header">
				{t("COMPANY_DETAIL")}
				<NavigationButton history={_history} />
			</h1>
			<div className="row">
				<div className="col-xl-12">
					<Panel>
						<PanelBody>
							<form onSubmit={handleSubmit(submitForm)}>
								<div className="row">
									<div className="col-lg-6">
										<div className="form-group d-flex justify-content-center align-items-center">
											<label className="col-lg-3 text-right">
												<b>* {t("USERNAME")}</b>
											</label>
											<div className="col-lg-9">
												<input
													type="text"
													ref={register({ required: "PLEASE_ENTER_USERNAME" })}
													name="username"
													className="form-control m-b-5"
													placeholder={t("PLEASE_ENTER_USERNAME")}
													defaultValue={username}
													readOnly={username ? true : false}
													autoComplete="off"
												/>
												{errors.username && (
													<div className="invalid-feedback">
														{t(errors.username.message)}
													</div>
												)}
											</div>
										</div>

										<div className="form-group d-flex justify-content-center align-items-center">
											<label className="col-lg-3 text-right">
												<b>* {t("FULLNAME")}</b>
											</label>
											<div className="col-lg-9">
												<input
													type="text"
													name="fullName"
													ref={register({ required: "PLEASE_ENTER_FULL_NAME" })}
													className="form-control m-b-5"
													defaultValue={fullName}
													placeholder={t("PLEASE_ENTER_FULL_NAME")}
												/>
												{errors.fullName && (
													<div className="invalid-feedback">
														{t(errors.fullName.message)}
													</div>
												)}
											</div>
										</div>

										<div className="form-group d-flex justify-content-center align-items-center">
											<label className="col-lg-3 text-right">
												<b>* {t("MOBILE")}</b>
											</label>
											<div className="col-lg-9">
												<IntlTelInput
													nationalMode={false}
													name="phone"
													fieldName={"phone"}
													preferredCountries={["my"]}
													style={{ display: "block" }}
													containerClassName="intl-tel-input"
													inputClassName="form-control"
													onPhoneNumberChange={onMobileChange}
													onPhoneNumberBlur={onMobileChange}
													customPlaceholder={(placeholder, countryData) => {
														return placeholder.split(/[- ]+/).join("");
													}}
													defaultValue={phone}
													value={phone}
													ref={register}
												/>
												{errors.phone && formState.submitCount > 0 && (
													<div className="invalid-feedback">
														{t(errors.phone.message)}
													</div>
												)}
											</div>
										</div>

										<div className="form-group d-flex justify-content-center align-items-center">
											<label className="col-lg-3 text-right">
												<b>{t("EMAIL")}</b>
											</label>
											<div className="col-lg-9">
												<input
													type="text"
													name="email"
													ref={register({
														required: "PLEASE_ENTER_EMAIL",
														pattern: {
															value:
																/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
															message: "INVALID_EMAIL",
														},
													})}
													className="form-control m-b-5"
													defaultValue={email}
													placeholder={t("PLEASE_ENTER_EMAIL")}
												/>
												{errors.email && (
													<div className="invalid-feedback">
														{t(errors.email.message)}
													</div>
												)}
											</div>
										</div>

										<hr style={{ background: "none" }} />
										<hr style={{ background: "none" }} />

										<div className="form-group d-flex justify-content-center align-items-center">
											<label className="col-lg-3 text-right">
												<b>* {t("PASSWORD")}</b>
											</label>
											<div className="col-lg-9">
												<input
													type="password"
													ref={register({
														// required: "PLEASE_ENTER_PASSWORD",
														required: requiredPassword,
														minLength: {
															value: 6,
															message: "MINIMUM_6_DIGIT_PASSWORD",
														},
													})}
													name="password"
													className="form-control m-b-5"
													placeholder={t("PLEASE_ENTER_PASSWORD")}
													defaultValue={""}
													autocomplete="off"
													onChange={onChangePassword}
												/>
												{errors.password && (
													<div className="invalid-feedback">
														{t(errors.password.message)}
													</div>
												)}
											</div>
										</div>
										<div className="form-group d-flex justify-content-center align-items-center">
											<label className="col-lg-3 text-right">
												<b>* {t("CONFIRM_PASSWORD")}</b>
											</label>
											<div className="col-lg-9">
												<input
													type="password"
													name="confirmPassword"
													ref={register({
														// required: "PLEASE_ENTER_CONFIRM_PASSWORD",
														required: requiredConfirmPassword,
														minLength: {
															value: 6,
															message: "MINIMUM_6_DIGIT_PASSWORD",
														},
													})}
													className="form-control m-b-5"
													defaultValue={""}
													placeholder={t("PLEASE_ENTER_CONFIRM_PASSWORD")}
													autocomplete="off"
													onChange={onChangeConfirmPassword}
												/>
												{errors.confirmPassword && (
													<div className="invalid-feedback">
														{t(errors.confirmPassword.message)}
													</div>
												)}
											</div>
										</div>
									</div>
									<div className="col-lg-6">
										{userRoleId == Role._SUPER_COMPANY && (
											<div className="form-group d-flex justify-content-center align-items-center">
												<label className="col-lg-3 text-right">
													<b>{t("INITIAL_CREDIT")}</b>
												</label>
												<div className="col-lg-9">
													<input
														type="number"
														name="credit"
														ref={register({
															required: "PLEASE_ENTER_INITIAL_CREDIT",
															min: {
																value: 0,
																message:
																	"PLEASE_ENTER_VALUE_NOT_LESS_THAN_ZERO",
															},
														})}
														className="form-control m-b-5"
														placeholder={t("PLEASE_ENTER_INITIAL_CREDIT")}
														defaultValue={credit}
													/>
													{errors.credit && (
														<div className="invalid-feedback">
															{t(errors.credit.message)}
														</div>
													)}
												</div>
											</div>
										)}
										<div className="form-group d-flex justify-content-center align-items-center">
											<label className="col-lg-3 text-right">
												<b>{t("TIME_ZONE")}</b>
											</label>

											<div className="col-lg-9">
												<Select
													name="timeZone"
													options={_TIMEZONE_OPTION}
													placeholder={
														_TIMEZONE_OPTION.filter(
															(option) => option.value == timeZone
														)[0] !== undefined
															? _TIMEZONE_OPTION.filter(
																	(option) => option.value == timeZone
															  )[0].label
															: ""
													}
													value={_TIMEZONE_OPTION.filter(
														(option) => option.value == timeZone
													)}
													onChange={(e) => {
														setValue("timeZone", e.value);
														setTimeZone(e.value);
														clearErrors("timeZone");
														register({ name: "timeZone" }, { required: false });
													}}
												/>
												{errors.timeZone && (
													<div className="invalid-feedback">
														{t(errors.timeZone.message)}
													</div>
												)}
											</div>
										</div>
										{userRoleId == Role._SUPER_COMPANY && (
											<div className="form-group d-flex justify-content-center align-items-center">
												<label className="col-lg-3 text-right">
													<b>{t("STATUS")}</b>
												</label>
												<div className="col-lg-9">
													<div className="switcher">
														<input
															type="checkbox"
															name="status"
															id="status"
															onChange={(e) => setStatus(e.target.checked)}
															value={true}
															checked={status}
															ref={register}
														/>
														<label
															className="col-lg-3 text-right"
															htmlFor="status"
														></label>
													</div>
												</div>
											</div>
										)}
										<div className="form-group d-flex justify-content-center align-items-center">
											<label className="col-lg-3 text-right">
												<b>{t("PIN_NUMBER")}</b>
											</label>
											<div className="col-lg-9">
												<PinInput
													length={4}
													initialValue={transactionPinNumber}
													secret={true}
													focus={true}
													ref={register}
													type="numeric"
													onChange={(value, index) => {
														setTransactionPinNumber(value);
														checkPinValue(value, index);
													}}
													inputMode="numeric"
													inputStyle={{
														border: "0",
														margin: "0px 10px",
														width: "40px",
														height: "40px",
														backgroundColor: "transparent",
														borderBottom: "2px solid #002e6c",
													}}
													inputFocusStyle={{
														borderBottom: "2px solid #FFC159",
													}}
													regexCriteria={/^[ 0-9_@./#&+-]*$/}
												/>
												{errors.transactionPinNumber && (
													<div className="invalid-feedback">
														{t(errors.transactionPinNumber.message)}
													</div>
												)}
											</div>
										</div>

										<input
											type="hidden"
											name="role"
											ref={register}
											className="form-control m-b-5"
											value={Role._COMPANY}
										/>
									</div>
								</div>

								<hr />

								<div className="row">
									<div className="col-lg-12 text-right">
										<div className="form-group">
											<button type="submit" className="btn btn-primary">
												{t("SUBMIT")}
											</button>
										</div>
									</div>
								</div>
							</form>
						</PanelBody>
					</Panel>
				</div>
			</div>

			{userRoleId == Role._SUPER_COMPANY && (
				<>
					{!stringIsNullOrEmpty(userId) && (
						<>
							<div className="row">
								<div className="col-xl-12">
									<Panel>
										<PanelHeader>{t("DOMAIN_URL")}</PanelHeader>
										<PanelBody>
											<div className="row">
												<div className="col-xl-12">
													<button
														type="button"
														className="btn btn-primary"
														style={{ float: "right" }}
														onClick={() => toggleDomainModal()}
													>
														<i className="fa fa-plus-circle m-r-10"></i>
														Add Domain
													</button>
												</div>
											</div>
											<div className="row">
												<div className="col-xl-12">
													<ReactTable
														data={domainList}
														columns={_tableColumns}
													/>
												</div>
											</div>
										</PanelBody>
									</Panel>
								</div>
							</div>
							<Modal
								isOpen={isToggleDomainModal}
								toggle={() => toggleDomainModal()}
							>
								<ModalHeader toggle={() => toggleDomainModal()}>
									{selectedDomain ? "Edit Domain" : "Add Domain"}
								</ModalHeader>
								<ModalBody>
									<Input
										type="text"
										name="settingKey"
										defaultValue={selectedDomain?.domainUrl}
										onChange={onChangeDomainUrl}
										className="form-control"
										invalid={isDomainValid ? false : true}
									/>
									{isDomainValid ? (
										""
									) : (
										<FormFeedback>Cannot be empty</FormFeedback>
									)}
								</ModalBody>
								<ModalFooter>
									<Button
										color="primary"
										onClick={() => {
											updateOrCreateDomain();
										}}
									>
										Save
									</Button>{" "}
									<Button color="secondary" onClick={() => toggleDomainModal()}>
										Cancel
									</Button>
								</ModalFooter>
							</Modal>
							<Modal
								isOpen={isToggleDeleteDomainModal}
								toggle={() => toggleDeleteDomainModal()}
							>
								<ModalHeader toggle={() => toggleDeleteDomainModal()}>
									Delete Domain
								</ModalHeader>
								<ModalBody>
									{t("ARE_YOU_SURE_DELETE")} {selectedDomain?.domainUrl}?
								</ModalBody>
								<ModalFooter>
									<Button
										color="danger"
										onClick={() => {
											deleteDomain();
										}}
									>
										Delete
									</Button>{" "}
									<Button
										color="secondary"
										onClick={() => toggleDeleteDomainModal()}
									>
										Cancel
									</Button>
								</ModalFooter>
							</Modal>
						</>
					)}
				</>
			)}
		</div>
	);
};

export default CompanyDetail;
