import React from 'react';
import { Link } from 'react-router-dom';
import { PageSettings } from './../../config/page-settings.js';
import { connect } from "react-redux";
import { WebUrl, Role, LoginType } from "../../util/Constant";

class SidebarProfile extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			profileActive: 0
		};
		this.handleProfileExpand = this.handleProfileExpand.bind(this);
	}

	handleProfileExpand(e) {
		e.preventDefault();
		this.setState(state => ({
			profileActive: !this.state.profileActive,
		}));
	}
  
    render() {
		return (
			<PageSettings.Consumer>
				{({pageSidebarMinify}) => (
					<ul className="nav">
						{/* <li className={"nav-profile " + (this.state.profileActive ? "expand " : "")}>
							<Link to="/" onClick={this.handleProfileExpand}>
								<div className="cover with-shadow"></div>
								<div className="info">
									<b className="caret pull-right"></b>
                                    {this.props.authState.userData.username}
                                    <small>{this.props.authState.userData.username}</small>
								</div>
							</Link>
						</li> */}
						<li>
                            {this.props.authState.userData.loginType == LoginType._LOGIN_TYPE_USER && <ul className={"nav nav-profile " + (this.state.profileActive && !pageSidebarMinify ? "d-block " : "")}>
                                <li><Link to={this.props.authState.userData.userRoleId == Role._COMPANY ? WebUrl._URL_COMPANY_PROFILE : WebUrl._URL_SUPER_ACCOUNT}><i className="fa fa-cog"></i> Edit profile</Link></li>
							</ul>}
						</li>
					</ul>
				)}
			</PageSettings.Consumer>
		)
	}
}

const mapStateToProps = state => ({
    ...state
});

export default connect(
    mapStateToProps,
    null
)(SidebarProfile);