import React, { useState, useEffect } from "react";
import { Panel, PanelBody } from "../../components/panel/panel.jsx";
import { ApiKey, ApiUrl, LanguageKey } from "../../util/Constant";
import { useForm } from "react-hook-form";
import { stringIsNullOrEmpty, createFormBody } from "../../util/Util";
import { setBusy, setIdle, showMessage } from "../../redux/AppAction";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { fetch } from "whatwg-fetch";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import NavigationButton from "../../components/constantComponent/button/NavigationButton";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import classnames from "classnames";

const WebContent = (props) => {
    const _DETAIL_TAB = "1";
    const { t } = useTranslation(LanguageKey._PRIMARY);
    const _dispatch = useDispatch();
    let _history = useHistory();
    const _location = useLocation();
    const { register, handleSubmit, errors } = useForm();
    const [name, setName] = useState("");
    const [content, setContent] = useState("");
    const [settingId, setSettingId] = useState("");
    const [activeTab, setActiveTab] = useState(_DETAIL_TAB);

    const toggle = (tab) => {
        if (activeTab !== tab) {
            setActiveTab(tab);
        }
    };

    useEffect(() => {
        init();
    }, []);


    async function init() {
        if (_location.state) {
            let stateWebContentId = _location.state.id;
            var apiUrl = ApiUrl._API_GET_USER_WEB_CONTENT_SETTING_BY_ID;
            apiUrl += "?id=" + stateWebContentId;
            fetch(apiUrl, {
                method: ApiKey._API_GET,
                headers: {
                    "Content-Type": ApiKey._API_FORM_URLENCODED,
                    "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
                },
            })
                .then((response) => response.json())
                .then((responseJson) => {
                    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                        if (_location.state.id) {
                            setSettingId(responseJson[ApiKey._API_DATA_KEY]["id"]);
                           
                        } {
                            setName(responseJson[ApiKey._API_DATA_KEY]["name"]);
                            setContent(responseJson[ApiKey._API_DATA_KEY]["content"]);
                        }
                       
                    }
                });
        }
    }

    /// <summary>
    /// Author : -
    /// </summary>
    const submitForm = async (data, e) => {
        let params = {
            Name: data.name,
            Content: data.content,
        };
        if (!stringIsNullOrEmpty(settingId)) {
            params["id"] = settingId;
        }

        let formBody = createFormBody(params);
        _dispatch(setBusy());


        fetch(ApiUrl._API_CREATE_OR_UPDATE_USER_WEB_CONTENTS, {
            method: ApiKey._API_POST,
            headers: {
                "Content-Type": ApiKey._API_FORM_URLENCODED,
                "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
            },
            body: formBody,
        })
            .then((response) => response.json())
            .then((responseJson) => {
                _dispatch(setIdle());
                _dispatch(
                    showMessage(
                        responseJson[ApiKey._API_SUCCESS_KEY],
                        t(responseJson[ApiKey._API_MESSAGE_KEY])
                    )
                );

                if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                    setSettingId(responseJson[ApiKey._API_DATA_KEY]["id"]);

                    _history.replace({
                        pathname: _location.pathname,
                        state: {
                            id: responseJson[ApiKey._API_DATA_KEY]["id"],
                        },
                    });

                }
            });
    };

    return (
        <div>
            <h1 className="page-header">
                {settingId ? t("EDIT_WEB_CONTENT") : t("ADD_WEB_CONTENT")}
                <NavigationButton history={_history} />
            </h1>
            {settingId && (
                <Nav tabs>
                    <NavItem>
                        <NavLink
                            className={classnames({ active: activeTab === _DETAIL_TAB })}
                            onClick={() => {
                                toggle(_DETAIL_TAB);
                            }}
                        >
                            {t("DETAIL")}
                        </NavLink>
                    </NavItem>

                </Nav>
            )}
            <TabContent activeTab={activeTab}>
                <TabPane tabId={_DETAIL_TAB}>
                    <div className="row">
                        <div className="col-xl-12">
                            <Panel>
                                <PanelBody>
                                    <form onSubmit={handleSubmit(submitForm)}>
                                        <div className="row">
                                            <div className="col-lg-8">
                                                <div className="form-group row m-b-15">
                                                    <label className="col-md-4 col-form-label">
                                                        <b>{t("NAME")}</b>
                                                    </label>
                                                    <div className="col-md-7">
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder={t("PLEASE_ENTER_NAME")}
                                                            name="name"
                                                            ref={register({ required: "PLEASE_ENTER_NAME" })}
                                                            defaultValue={name}
                                                        />
                                                        {errors.title && (
                                                            <div className="invalid-feedback">
                                                                {t(errors.name.message)}
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-8">
                                                <div className="form-group row m-b-15">
                                                    <label className="col-md-4 col-form-label">
                                                        <b>{t("CONTENT")}</b>
                                                    </label>
                                                    <div className="col-md-7">
                                                        <textarea
                                                            className="form-control"
                                                            rows="5"
                                                            style={{ marginTop: "10px" }}
                                                            ref={register({ required: "PLEASE_ENTER_CONTENT" })}
                                                            name="content"
                                                            defaultValue={content}
                                                        ></textarea>
                                                        {/*<input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder={t("PLEASE_ENTER_CONTENT")}
                                                            name="content"
                                                            ref={register({ required: "PLEASE_ENTER_CONTENT" })}
                                                            defaultValue={content}
                                                        />*/}
                                                        {errors.title && (
                                                            <div className="invalid-feedback">
                                                                {t(errors.content.message)}
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <hr />
                                        <div className="row">
                                            <div className="col-lg-3">
                                                <div className="form-group">
                                                    <button type="submit" className="btn btn-primary">
                                                        {t("SUBMIT")}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </PanelBody>
                            </Panel>
                        </div>
                    </div>
                </TabPane>
            </TabContent>
        </div>
    );
};

export default WebContent;
