import React, { useState, useEffect, useCallback } from "react";
import ReactTable from "../../components/constantComponent/reactTable/ReactTable";
import { Panel, PanelBody } from "./../../components/panel/panel.jsx";
import {
	UncontrolledButtonDropdown,
	DropdownToggle,
	DropdownMenu,
	DropdownItem,
	Modal,
	ModalHeader,
	ModalBody,
} from "reactstrap";
import moment from "moment";
import {
	ApiKey,
	ApiUrl,
	BankTransactionType,
	Role,
	LanguageKey,
} from "../../util/Constant";
import BankSummaryStatement from "./BankSummaryStatement.js";
import BankSummaryAddCreditDebit from "./bankSummary/BankSummaryAddCreditDebit.js";
import BankSummaryAdjustment from "./bankSummary/BankSummaryAdjustment.js";
import { CSVLink } from "react-csv";
import { useTranslation } from "react-i18next";
import { fetch } from "whatwg-fetch";
import { setBusy, setIdle, showMessage } from "../../redux/AppAction";
import { useDispatch, useSelector } from "react-redux";
import DateRangePicker from "react-bootstrap-daterangepicker";

/// <summary>
/// Author : -
/// </summary>

const ManageBankSummaryReport = (props) => {
	const _dispatch = useDispatch();
	const { t } = useTranslation(LanguageKey._PRIMARY);
	const [filteredDatas, setFilteredDatas] = useState([]);
	const [bankSummaryDatas, setBankSummaryDatas] = useState([]);
	const [bankSummaryDatas2, setBankSummaryDatas2] = useState([]);
	const [creditModal, setCreditModal] = useState(false);
	const [debitModal, setDebitModal] = useState(false);
	const [adjustmentModal, setAdjustmentModal] = useState(false);
	const [statementModal, setStatementModal] = useState(false);

	const [modalState, setModalState] = useState([]);
	const [exportDatas, setExportDatas] = useState([]);
	const [exportDatas2, setExportDatas2] = useState([]);
	const [bankAccountDatas, setBankAccountDatas] = useState([]);

	const _userData = useSelector((state) => state.authState.userData);
	const [startDate, setStartDate] = useState(
		moment().startOf("months").format("YYYY-MM-DD HH:mm:ss")
	);
	const [endDate, setEndDate] = useState(
		moment().endOf("months").format("YYYY-MM-DD HH:mm:ss")
	);
	/// <summary>
	/// Author : -
	/// </summary>
	useEffect(() => {
		getBankSummaryDatas();
		getAllBankStatementDatas();
	}, [startDate, endDate]);

	/// <summary>
	/// Author : -
	/// </summary>
	const getBankSummaryDatas = async () => {
		_dispatch(setBusy());
		await fetch(ApiUrl._API_GET_BANK_SUMMARY_REPORT, {
			method: ApiKey._API_GET,
			headers: {
				"Content-Type": ApiKey._API_FORM_URLENCODED,
				"X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
			},
		})
			.then((response) => response.json())
			.then((responseJson) => {
				if (responseJson[ApiKey._API_SUCCESS_KEY]) {
					setBankSummaryDatas(responseJson[ApiKey._API_DATA_KEY]);
					const bankAccountList = [];
					responseJson[ApiKey._API_DATA_KEY].map((bankAccount, index) => {
						bankAccountList.push({
							label:
								bankAccount.bankAccountNumber +
								" [" +
								bankAccount.bankName +
								"]",
							value: bankAccount.bankAccountId,
						});
					});
					setBankAccountDatas(bankAccountList);
				} else {
					_dispatch(
						showMessage(
							responseJson[ApiKey._API_SUCCESS_KEY],
							t(responseJson[ApiKey._API_MESSAGE_KEY])
						)
					);
				}
				_dispatch(setIdle());
			});
	};

	async function getAllBankStatementDatas() {
		_dispatch(setBusy());
		await fetch(
			ApiUrl._API_GET_ALL_BANK_SUMMARY_STATEMENT +
				"?StartDate=" +
				startDate +
				"&EndDate=" +
				endDate,
			{
				method: ApiKey._API_GET,
				headers: {
					"Content-Type": ApiKey._API_FORM_URLENCODED,
					"X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
				},
			}
		)
			.then((response) => response.json())
			.then((responseJson) => {
				if (responseJson[ApiKey._API_SUCCESS_KEY]) {
					setBankSummaryDatas2(responseJson[ApiKey._API_DATA_KEY]);
					updateFilteredDatas2(responseJson[ApiKey._API_DATA_KEY]);
				} else {
					_dispatch(
						showMessage(
							responseJson[ApiKey._API_SUCCESS_KEY],
							t(responseJson[ApiKey._API_MESSAGE_KEY])
						)
					);
				}
				_dispatch(setIdle());
			});
	}

	/// <summary>
	/// Author : -
	/// </summary>
	const toggleCreditModal = () => {
		setCreditModal(!creditModal);
	};

	/// <summary>
	/// Author : -
	/// </summary>
	const toggleDebitModal = () => {
		setDebitModal(!debitModal);
	};

	/// <summary>
	/// Author : -
	/// </summary>
	const toggleAdjustmentModal = () => {
		setAdjustmentModal(!adjustmentModal);
	};

	/// <summary>
	/// Author : -
	/// </summary>
	const toggleStatementModal = () => {
		setStatementModal(!statementModal);
	};

	/// <summary>
	/// Author : -
	/// </summary>
	const getFilteredRecords = useCallback(({ page }) => {
		updateFilteredDatas(page);
	}, []);

	/// <summary>
	/// Author : -
	/// </summary>
	const updateFilteredDatas = (page) => {
		let newFilteredDatas = [];
		let newExportDatas = [];
		let totalCredit = 0;
		let totalDebit = 0;
		page.map((data, index) => {
			let row = data.original;
			totalCredit += row.credit;
			totalDebit += row.debit;
			let newFilteredData = {
				bankName: row.bankName,
				bankAccountHolderName: row.bankAccountHolderName,
				bankAccountNumber: row.bankAccountNumber,
				status: row.status,
				credit: row.credit,
				debit: row.debit,
				balance: row.balance,
			};
			newFilteredDatas.push(newFilteredData);

			newFilteredData.status =
				newFilteredData.status == true ? "Active" : "Inactive";

			newExportDatas.push(newFilteredData);
		});
		setFilteredDatas(newFilteredDatas);
		newExportDatas.push(
			{
				bankName: "",
			},
			{
				bankName: "Total",
				credit: totalCredit,
				debit: totalDebit,
				balance: totalCredit - totalDebit,
			}
		);
		setExportDatas(newExportDatas);
	};

	let _exportHeaders = [
		{ label: "Bank", key: "bankName" },
		{ label: "Bank Account Holder Name", key: "bankAccountHolderName" },
		{ label: "Bank Account Number", key: "bankAccountNumber" },
		{ label: "Status", key: "status" },
		{ label: "Credit", key: "credit" },
		{ label: "Debit", key: "debit" },
		{ label: "Balance", key: "balance" },
	];

	let _exportHeaders2 = [
		{ label: "Bank", key: "bankName" },
		{ label: "Account", key: "bankAccountNumber" },
		{ label: "Date Time", key: "date" },
		{ label: "Credit", key: "credit" },
		{ label: "Debit", key: "debit" },
		{ label: "Remark", key: "remark" },
		{ label: "Admin", key: "admin" },
	];

	const updateFilteredDatas2 = (data) => {
		let newFilteredDatas = [];
		let newExportDatas = [];
		let totalCredit = 0;
		let totalDebit = 0;

		data.map((item, index) => {
			let row = item;
			totalCredit += row.credit;
			totalDebit += row.debit;
			let newFilteredData = {
				bankName: row.bankName,
				bankAccountNumber: row.bankAccountNumber,
				date: moment(row.date).format("YYYY-MM-DD HH:mm:ss"),
				credit: row.credit,
				debit: row.debit,
				remark: row.remark,
				admin: row.admin,
			};
			newFilteredDatas.push(newFilteredData);

			newFilteredData.status =
				newFilteredData.status == true ? "Active" : "Inactive";

			newExportDatas.push(newFilteredData);
		});

		//setFilteredDatas(newFilteredDatas);
		setExportDatas2(newExportDatas);
	};

	let _tableColumns = [
		{
			Header: t("BANK"),
			accessor: "bankName",
			Footer: ({ row }) => {
				return (
					<span>
						<b>{t("TOTAL")}</b>
					</span>
				);
			},
		},
		{
			Header: t("BANK_ACCOUNT_HOLDER_NAME"),
			accessor: "bankAccountHolderName",
			style: {
				whiteSpace: "unset",
				wordBreak: "break-word",
			},
		},
		{
			Header: t("BANK_ACCOUNT_NUMBER"),
			accessor: "bankAccountNumber",
			style: {
				whiteSpace: "unset",
				wordBreak: "break-word",
			},
		},
		{
			Header: t("BANK_STATUS"),
			accessor: "status",
			Cell: ({ row }) => {
				return (
					<span>
						{row.original.status == 1 ? (
							<span className="badge badge-secondary badge-green">
								{t("ACTIVE")}
							</span>
						) : (
							<span className="badge badge-secondary badge-danger">
								{t("SUSPENDED")}
							</span>
						)}
					</span>
				);
			},
		},
		{
			Header: t("CREDIT"),
			accessor: "credit",
			Cell: ({ row }) => (
				<span>
					{parseFloat(
						Math.floor(row.original.credit * Math.pow(10, 2)) / Math.pow(10, 2)
					).toFixed(2)}
				</span>
			),
			Footer: (row) => {
				let creditTotal = 0;
				for (let i = 0; i <= filteredDatas.length; i++) {
					if (filteredDatas[i]) {
						creditTotal += parseFloat(filteredDatas[i].credit);
					}
				}
				const creditT = parseFloat(
					Math.floor(creditTotal * Math.pow(10, 2)) / Math.pow(10, 2)
				).toFixed(2);
				return <span>{creditT}</span>;
			},
		},
		{
			Header: t("DEBIT"),
			accessor: "debit",
			Cell: ({ row }) => (
				<span>
					{row.original.debit > 0 ? "-" : ""}
					{parseFloat(
						Math.floor(row.original.debit * Math.pow(10, 2)) / Math.pow(10, 2)
					).toFixed(2)}
				</span>
			),
			Footer: () => {
				let debitTotal = 0;
				for (let i = 0; i <= filteredDatas.length; i++) {
					if (filteredDatas[i]) {
						debitTotal += parseFloat(filteredDatas[i].debit);
					}
				}
				const debitT = parseFloat(
					Math.floor(debitTotal * Math.pow(10, 2)) / Math.pow(10, 2)
				).toFixed(2);
				return <span>-{debitT}</span>;
			},
		},
		{
			Header: t("BALANCE"),
			accessor: "balance",
			Cell: ({ row }) => (
				<span>
					{parseFloat(
						Math.floor(row.original.balance * Math.pow(10, 2)) / Math.pow(10, 2)
					).toFixed(2)}
				</span>
			),
			Footer: () => {
				let balanceTotal = 0;

				for (let i = 0; i < filteredDatas.length; i++) {
					if (filteredDatas[i]) {
						balanceTotal += parseFloat(filteredDatas[i].balance);
					}
				}

				const balanceT = parseFloat(
					Math.floor(balanceTotal * Math.pow(10, 2)) / Math.pow(10, 2)
				).toFixed(2);
				return <span>{balanceT}</span>;
			},
		},
		{
			id: "action",
			Header: "",
			Cell: ({ row }) => (
				<div className="btn-group m-r-5 m-b-5">
					<UncontrolledButtonDropdown>
						<DropdownToggle caret color="default">
							<i className="fas fa-cog"></i>
						</DropdownToggle>
						<DropdownMenu>
							{_userData.userRoleId != Role._SUPER_COMPANY && (
								<>
									<DropdownItem
										onClick={() => {
											setModalState(row.original);
											toggleCreditModal();
										}}
									>
										<span className="text-success">{t("CREDIT")}</span>
									</DropdownItem>
									<DropdownItem
										onClick={() => {
											setModalState(row.original);
											toggleDebitModal();
										}}
									>
										<span className="text-danger">{t("DEBIT")}</span>
									</DropdownItem>
									<DropdownItem
										onClick={() => {
											setModalState(row.original);
											toggleAdjustmentModal();
										}}
									>
										<span className="text-warning">{t("ADJUSTMENT")}</span>
									</DropdownItem>
								</>
							)}
							<DropdownItem
								onClick={() => {
									setModalState(row.original);
									toggleStatementModal();
								}}
							>
								<span className="text-primary">{t("STATEMENT")}</span>
							</DropdownItem>
						</DropdownMenu>
					</UncontrolledButtonDropdown>
				</div>
			),
			disableSortBy: true,
			disableFilters: true,
			width: 100,
			style: { overflow: "visible" },
		},
	];

	const handleEvent = (event, picker) => {
		setStartDate(moment(picker.startDate).format("YYYY-MM-DD HH:mm:ss"));
		setEndDate(moment(picker.endDate).format("YYYY-MM-DD HH:mm:ss"));
	};

	return (
		<div>
			<h1 className="page-header">{t("BANK_SUMMARY_REPORT")}</h1>
			<Panel>
				<PanelBody>
					<div className="row">
						<div className="col-lg-5">
							<div className="form-group">
								<label>
									<b>Selection Date For All Bank Statement</b>
								</label>
								<div
									className="input-group"
									style={{ width: "100%", flexFlow: "nowrap" }}
								>
									<div className="input-group-prepend">
										<span className="input-group-text">
											<i className="fa fa-calendar"></i>
										</span>
									</div>
									<div style={{ width: "80%" }}>
										<DateRangePicker
											containerStyles={{ width: "100%" }}
											startDate={startDate}
											endDate={endDate}
											onApply={handleEvent}
											alwaysShowCalendars={true}
											locale={{ format: "YYYY-MM-DD HH:mm:ss" }}
											timePicker={true}
											timePickerSeconds={true}
											ranges={{
												Today: [moment().startOf("day"), moment().endOf("day")],
												Yesterday: [
													moment().subtract(1, "days").startOf("day"),
													moment().subtract(1, "days").endOf("day"),
												],
												"Last 7 Days": [
													moment().subtract(6, "days").startOf("day"),
													moment().endOf("day"),
												],
												"Last 30 Days": [
													moment().subtract(29, "days").startOf("day"),
													moment().endOf("day"),
												],
												"This Month": [
													moment().startOf("month"),
													moment().endOf("month"),
												],
												"Last Month": [
													moment().subtract(1, "month").startOf("month"),
													moment().subtract(1, "month").endOf("month"),
												],
												"This Year": [
													moment().startOf("year"),
													moment().endOf("year"),
												],
											}}
										>
											<input
												type="text"
												className="form-control"
												value={startDate + " - " + endDate}
											/>
										</DateRangePicker>
									</div>
								</div>
							</div>
						</div>
						<div className="col-lg-3">
							<div className="form-group">
								<label>
									<b>All Bank Statement</b>
								</label>
								<div
									className="input-group"
									style={{ width: "100%", flexFlow: "nowrap" }}
								>
									<div style={{ width: "80%" }}>
										<CSVLink
											className="btn btn-primary btn-lg"
											data={exportDatas2}
											headers={_exportHeaders2}
											filename={
												"AllBankStatements(" +
												moment().format("YYYY-MM-DD HH:mm:ss") +
												").csv"
											}
											target="_blank"
										>
											<i className="fas fa-file-csv"></i>
										</CSVLink>
									</div>
								</div>
							</div>
						</div>

						<div className="col-lg-3">
							<div className="form-group">
								<label>
									<b>Bank Summary Report</b>
								</label>
								<div
									className="input-group"
									style={{ width: "100%", flexFlow: "nowrap" }}
								>
									<div style={{ width: "80%" }}>
										<CSVLink
											className="btn btn-primary btn-lg"
											data={exportDatas}
											headers={_exportHeaders}
											filename={
												"BankSummaryReport(" +
												moment().format("YYYY-MM-DD HH:mm:ss") +
												").csv"
											}
											target="_blank"
										>
											<i className="fas fa-file-csv"></i>
										</CSVLink>
									</div>
								</div>
							</div>
						</div>

						<div className="col-lg-12">
							<ReactTable
								data={bankSummaryDatas}
								columns={_tableColumns}
								renderFooter={true}
								getFilteredRecords={getFilteredRecords}
							/>
						</div>
					</div>
				</PanelBody>
			</Panel>

			<Modal
				isOpen={creditModal}
				toggle={toggleCreditModal}
				size="lg"
				centered={true}
			>
				<ModalHeader toggle={toggleCreditModal}>
					{t("BANK_BALANCE_CREDIT")}
				</ModalHeader>
				<ModalBody>
					<BankSummaryAddCreditDebit
						data={modalState}
						type={BankTransactionType._CREDIT_BANK_TRANSACTION}
						getBankSummaryDatas={getBankSummaryDatas}
					/>
				</ModalBody>
			</Modal>

			<Modal
				isOpen={debitModal}
				toggle={toggleDebitModal}
				size="lg"
				centered={true}
			>
				<ModalHeader toggle={toggleDebitModal}>
					{t("BANK_BALANCE_DEBIT")}
				</ModalHeader>
				<ModalBody>
					<BankSummaryAddCreditDebit
						data={modalState}
						type={BankTransactionType._DEBIT_BANK_TRANSACTION}
						getBankSummaryDatas={getBankSummaryDatas}
					/>
				</ModalBody>
			</Modal>

			<Modal
				isOpen={adjustmentModal}
				toggle={toggleAdjustmentModal}
				size="lg"
				centered={true}
			>
				<ModalHeader toggle={toggleAdjustmentModal}>
					{t("BANK_BALANCE_TRANSFER")}
				</ModalHeader>
				<ModalBody>
					<BankSummaryAdjustment
						data={modalState}
						bankAccounts={bankAccountDatas}
						getBankSummaryDatas={getBankSummaryDatas}
					/>
				</ModalBody>
			</Modal>

			<Modal
				isOpen={statementModal}
				toggle={toggleStatementModal}
				size="xl"
				centered={true}
			>
				<ModalHeader toggle={toggleStatementModal}>
					{t("ACCOUNT_STATEMENT")}
				</ModalHeader>
				<ModalBody>
					<BankSummaryStatement data={modalState} />
				</ModalBody>
			</Modal>
		</div>
	);
};

export default ManageBankSummaryReport;
