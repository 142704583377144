import React, { useState, useEffect } from "react";
import ReactTable from "../../components/constantComponent/reactTable/ReactTable.js";
import { Panel, PanelBody } from "../../components/panel/panel.jsx";
import { useHistory } from "react-router-dom";
import {
	UncontrolledButtonDropdown,
	DropdownToggle,
	DropdownMenu,
	DropdownItem,
} from "reactstrap";
import "react-datepicker/dist/react-datepicker.css";
import NavigationButton from "../../components/constantComponent/button/NavigationButton.jsx";
import {
	navigateTo,
	stringIsNullOrEmpty,
	numberWithCurrencyFormat,
} from "../../util/Util.js";
import {
	ApiKey,
	ApiUrl,
	AccessRight,
	WebUrl,
	LanguageKey,
	DEFAULT_PAGE_SIZE,
	Role,
} from "../../util/Constant.js";
import { checkIfPermissionExist } from "../../util/PermissionChecker.js";
import { showMessage, setBusy, setIdle } from "../../redux/AppAction.js";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import ApiEngine from "../../util/ApiEngine.js";
import { AsyncPaginate } from "react-select-async-paginate";
import classNames from "classnames";
import moment from "moment";
import { DateRangePicker } from "react-bootstrap-daterangepicker";

/// <summary>
/// Author : -
/// </summary>
const ManageMember = (props) => {
	const { t } = useTranslation(LanguageKey._PRIMARY);
	let _history = useHistory();
	const _dispatch = useDispatch();
	const [memberData, setMemberData] = useState([]);
	const _userData = useSelector((state) => state.authState.userData);

	const _SELECTED_USERNAME_KEY = "selectedUsernameKey";
	const _SELECTED_FULLNAME_KEY = "selectedFullnameKey";
	const _SELECTED_MOBILE_KEY = "selectedMobileKey";
	const _SELECTED_UPLINE_KEY = "selectedUplineKey";
	const _SELECTED_STATUS_KEY = "selectedStatusKey";

	const [selectedMember, setSelectedMember] = useState("");
	const [selectedMemberFullname, setSelectedMemberFullname] = useState("");
	const [selectedMemberPhone, setSelectedMemberPhone] = useState("");
	const [selectedUplineMember, setSelectedUplineMember] = useState("");
	const [selectedStatus, setSelectedStatus] = useState("");
	const [locationStateObj, setLocationStateObj] = useState();

	const [labelUsername, setLabelUsername] = useState("All");
	const [labelFullname, setLabelFullname] = useState("All");
	const [labelUserPhone, setLabelUserPhone] = useState("All");
	const [labelUpline, setLabelUpline] = useState("All");
	const [labelStatus, setLabelStatus] = useState("All");
	const [startJoinedDate, setStartJoinedDate] = useState();
	const [endJoinedDate, setEndJoinedDate] = useState();
	const [lastLoginStartDate, setLastLoginStartDate] = useState();
	const [lastLoginEndDate, setLastLoginEndDate] = useState();
	const [searchTrigger, setSearchTrigger] = useState("");
	const [balance, setBalance] = useState();
	const [balanceLabel, setBalanceLabel] = useState();

	let _tableColumns = [
		{
			Header: "USERNAME",
			accessor: "username",
			minWidth: 100,
			Cell: ({ row }) => {
				return <span>{row.original.username}</span>;
			},
		},
		{
			Header: "FULLNAME",
			accessor: "userFullName",
			minWidth: 100,
		},
		{
			Header: "MOBILE",
			accessor: "phoneNumber",
			minWidth: 100,
		},
		{
			Header: "BALANCE",
			accessor: "primaryCredit",
			Cell: ({ row }) => {
				return (
					<>
						{numberWithCurrencyFormat(
							parseFloat(row.original.primaryCredit),
							3
						)}
					</>
				);
			},
			minWidth: 100,
		},
		{
			Header: "DIRECT_UPLINE",
			accessor: "uplineMemberModel",
			Cell: ({ row }) => {
				return <span>{row.original.uplineMemberModel?.username}</span>;
			},
			minWidth: 100,
		},
		{
			Header: "DATE_JOINED",
			accessor: "createdTime",
			Cell: ({ row }) => {
				return (
					<span>
						{moment(row.original.createdTime)
							.add(_userData.userTimeZone, "hour")
							.format("YYYY-MM-DD HH:mm:ss") +
							" GMT+" +
							_userData.userTimeZone +
							":00"}
					</span>
				);
			},
			minWidth: 100,
		},
		{
			Header: "PREVIOUS_LOGIN",
			accessor: "lastLogin",
			Cell: ({ row }) => {
				return (
					<span>
						{moment(row.original.lastLogin)
							.add(_userData.userTimeZone, "hour")
							.format("YYYY-MM-DD HH:mm:ss") +
							" GMT+" +
							_userData.userTimeZone +
							":00"}
					</span>
				);
			},
			minWidth: 100,
		},
		{
			Header: "STATUS",
			accessor: "status",
			Cell: ({ row }) => {
				return (
					<span>
						{row.original.status == 1 ? (
							<span className="badge badge-secondary badge-green">
								{t("ACTIVE")}
							</span>
						) : (
							<span className="badge badge-secondary badge-danger">
								{t("SUSPENDED")}
							</span>
						)}
					</span>
				);
			},
			minWidth: 100,
		},
		{
			id: "action",
			Header: "",
			Cell: ({ row }) =>
				checkIfPermissionExist(
					AccessRight._MEMBER_CONTROL_PERMISSION,
					true
				) && (
					<div className="btn-group m-r-5 m-b-5">
						<UncontrolledButtonDropdown>
							<DropdownToggle caret color="default">
								<i className="fas fa-cog"></i>
							</DropdownToggle>
							<DropdownMenu>
								<DropdownItem
									onClick={() => {
										navigateTo(
											_history,
											WebUrl._URL_VIEW_ACCOUNT,
											row.original
										);
									}}
								>
									<span className="text-warning">{t("EDIT")}</span>
								</DropdownItem>
								<DropdownItem
									onClick={() => {
										navigateTo(
											_history,
											WebUrl._URL_VIEW_PRODUCT_ACCOUNT,
											row.original
										);
									}}
								>
									<span className="text-warning">
										{t("VIEW_PRODUCT_ACCOUNT")}
									</span>
								</DropdownItem>
								<DropdownItem onClick={() => syncProductData(row.original)}>
									<span className="text-primary">{t("SYNC_PRODUCT")}</span>
								</DropdownItem>
								<DropdownItem
									onClick={() => {
										navigateTo(
											_history,
											WebUrl._URL_MEMBER_PRORUCT_MAINTENANCE,
											row.original
										);
									}}
								>
									<span className="text-primary">{t("VIEW_PRODUCT")}</span>
								</DropdownItem>
							</DropdownMenu>
						</UncontrolledButtonDropdown>
					</div>
				),
			disableSortBy: true,
			disableFilters: true,
			width: 100,
			style: { overflow: "visible" },
		},
	];

	useEffect(() => {
		init();
	}, []);

	const init = async () => {
		let responseJson = await ApiEngine.get(
			ApiUrl._API_GET_MEMBER_LIST + "?directUserOnly=true"
		);

		if (responseJson[ApiKey._API_SUCCESS_KEY]) {
			setMemberData(responseJson[ApiKey._API_DATA_KEY]);
		}
	};

	const searchBtnHandler = async () => {
		let apiUrl = ApiUrl._API_GET_MEMBER_LIST + "?directUserOnly=true";

		if (selectedMember !== "") {
			apiUrl += "&memberId=" + selectedMember;
		}

		if (selectedMemberFullname !== "") {
			apiUrl += "&userFullName=" + selectedMemberFullname;
		}

		if (selectedMemberPhone !== "") {
			apiUrl += "&phoneNumber=" + selectedMemberPhone;
		}

		if (selectedUplineMember !== "") {
			apiUrl += "&upline=" + selectedUplineMember;
		}

		if (
			!stringIsNullOrEmpty(startJoinedDate) &&
			!stringIsNullOrEmpty(endJoinedDate)
		) {
			apiUrl += "&startJoinedDate=" + startJoinedDate;
			apiUrl += "&endJoinedDate=" + endJoinedDate;
		}

		if (
			!stringIsNullOrEmpty(lastLoginStartDate) &&
			!stringIsNullOrEmpty(lastLoginEndDate)
		) {
			apiUrl += "&lastLoginStartDate=" + lastLoginStartDate;
			apiUrl += "&lastLoginEndDate=" + lastLoginEndDate;
		}

		if (selectedStatus !== "") {
			apiUrl += "&status=" + selectedStatus;
		}

		if (!stringIsNullOrEmpty(balance)) {
			apiUrl += "&balance=" + balance;
		}

		setSearchTrigger(apiUrl);
	};

	const searchAllBtnHandler = async () => {
		setSelectedMember("");
		setSelectedMemberFullname("");
		setSelectedMemberPhone("");
		setSelectedUplineMember("");
		setSelectedStatus("");
		setStartJoinedDate();
		setEndJoinedDate();
		setLastLoginStartDate();
		setLastLoginEndDate();
		setBalance();
		setBalanceLabel("");

		setLabelUsername("All");
		setLabelFullname("All");
		setLabelUserPhone("All");
		setLabelUpline("All");
		setLabelStatus("All");

		let apiUrl = ApiUrl._API_GET_MEMBER_LIST + "?directUserOnly=true";
		setSearchTrigger(apiUrl);
	};

	useEffect(async () => {
		_dispatch(setBusy());
		let responseJson = await ApiEngine.get(searchTrigger);

		if (responseJson[ApiKey._API_SUCCESS_KEY]) {
			setMemberData(responseJson[ApiKey._API_DATA_KEY]);
		}

		_dispatch(setIdle());
	}, [searchTrigger]);

	async function syncProductData(data) {
		var responseJson = await ApiEngine.post(
			`${ApiUrl._API_SYNC_MEMBER_TO_OWNER_PRODUCT_DATA}?id=${data["id"]}`
		);
		_dispatch(
			showMessage(
				responseJson[ApiKey._API_SUCCESS_KEY],
				t(responseJson[ApiKey._API_MESSAGE_KEY])
			)
		);
	}

	async function getMemberList(search, loadOptions, { page }) {
		var responseJson = await ApiEngine.get(
			`${ApiUrl._API_GET_USER_MEMBER_LIST}?&start=${
				(page - 1) * DEFAULT_PAGE_SIZE
			}&count=${DEFAULT_PAGE_SIZE}&keyword=${search}`
		);

		if (responseJson[ApiKey._API_SUCCESS_KEY]) {
			let data = responseJson[ApiKey._API_DATA_KEY]["data"];
			let formattedData = data.map((m) => {
				return { label: m.username, value: m.id };
			});
			formattedData.unshift({ label: "All", value: "" });

			return {
				options: formattedData,
				hasMore: formattedData.length == DEFAULT_PAGE_SIZE,
				additional: {
					page: page + 1,
				},
			};
		}
	}

	async function getMemberFullnameList(search, loadOptions, { page }) {
		var responseJson = await ApiEngine.get(
			`${ApiUrl._API_GET_USER_MEMBER_LIST}?&start=${
				(page - 1) * DEFAULT_PAGE_SIZE
			}&count=${DEFAULT_PAGE_SIZE}&keyword=${search}`
		);

		if (responseJson[ApiKey._API_SUCCESS_KEY]) {
			let data = responseJson[ApiKey._API_DATA_KEY]["data"];
			let filterData = data.filter((m) => {
				if (!stringIsNullOrEmpty(m.userFullName))
					return { label: m.userFullName, value: m.id };
			});

			let formattedData = filterData.map((m) => {
				return { label: m.userFullName, value: m.id };
			});
			formattedData.unshift({ label: "All", value: "" });

			return {
				options: formattedData,
				hasMore: formattedData.length == DEFAULT_PAGE_SIZE,
				additional: {
					page: page + 1,
				},
			};
		}
	}

	async function getMemberPhoneList(search, loadOptions, { page }) {
		var responseJson = await ApiEngine.get(
			`${ApiUrl._API_GET_USER_MEMBER_LIST}?&start=${
				(page - 1) * DEFAULT_PAGE_SIZE
			}&count=${DEFAULT_PAGE_SIZE}&keyword=${search}`
		);

		if (responseJson[ApiKey._API_SUCCESS_KEY]) {
			let data = responseJson[ApiKey._API_DATA_KEY]["data"];
			let formattedData = data.map((m) => {
				return { label: m.phoneNumber, value: m.id };
			});

			return {
				options: formattedData,
				hasMore: formattedData.length == DEFAULT_PAGE_SIZE,
				additional: {
					page: page + 1,
				},
			};
		}
	}

	const handleJoinedEvent = (event, picker) => {
		setStartJoinedDate(moment(picker.startDate).format("YYYY-MM-DD HH:mm:ss"));
		setEndJoinedDate(moment(picker.endDate).format("YYYY-MM-DD HH:mm:ss"));
	};

	async function getMemberUplineList(search, loadOptions, { page }) {
		var responseJson = await ApiEngine.get(
			`${ApiUrl._API_GET_USER_MEMBER_LIST}?&start=${
				(page - 1) * DEFAULT_PAGE_SIZE
			}&count=${DEFAULT_PAGE_SIZE}&keyword=${search}`
		);

		if (responseJson[ApiKey._API_SUCCESS_KEY]) {
			let data = responseJson[ApiKey._API_DATA_KEY]["data"];
			
			let formattedData = data.map((m) => {
				return { label: m.username, value: m.id };
			});
			formattedData.unshift({ label: "All", value: "" });

			return {
				options: formattedData,
				hasMore: formattedData.length == DEFAULT_PAGE_SIZE,
				additional: {
					page: page + 1,
				},
			};
		}
	}

	const handleLastLoginEvent = (event, picker) => {
		setLastLoginStartDate(
			moment(picker.startDate).format("YYYY-MM-DD HH:mm:ss")
		);
		setLastLoginEndDate(moment(picker.endDate).format("YYYY-MM-DD HH:mm:ss"));
	};

	async function getMemberStatus(search, loadOptions, { page }) {
		let formattedData = [
			{
				label: t("ACTIVE"),
				value: "1",
			},
			{
				label: t("SUSPENDED"),
				value: "0",
			},
		];

		return {
			options: formattedData,
			hasMore: formattedData.length == DEFAULT_PAGE_SIZE,
			additional: {
				page: page + 1,
			},
		};
	}

	return (
		<div>
			<h1 className="page-header">
				{t("MANAGE_ACCOUNT")}
				{checkIfPermissionExist(AccessRight._MEMBER_CONTROL_PERMISSION, true) &&
					_userData.userRole == "Company" &&
					_userData.userRole == "Marketing Manager" && (
						<NavigationButton
							history={_history}
							url={WebUrl._URL_VIEW_ACCOUNT}
							state={locationStateObj}
							buttonText={t("ADD_NEW_ACCOUNT")}
						/>
					)}
			</h1>
			<Panel style={{ padding: "15px" }}>
				<div className="row">
					<label className="col-lg-3 text-right">
						<b>{t("FILTERS")} : </b>
					</label>
					<div className="col-lg-3">
						<div className="form-group">
							<label>
								<b>{t("USERNAME")}</b>
							</label>
							{
								<AsyncPaginate
									placeholder={labelUsername}
									debounceTimeout={250}
									loadOptions={getMemberList}
									additional={{
										page: 1,
									}}
									value={selectedMember}
									onChange={(e) => {
										if (!stringIsNullOrEmpty(e.value)) {
											sessionStorage.setItem(_SELECTED_USERNAME_KEY, e.value);
										} else {
											sessionStorage.removeItem(_SELECTED_USERNAME_KEY);
										}
										setLabelUsername(e.label);
										setSelectedMember(e.value);
									}}
								/>
							}
						</div>
					</div>

					<div className="col-lg-3">
						<div className="form-group">
							<label>
								<b>{t("FULLNAME")}</b>
							</label>
							{
								<AsyncPaginate
									placeholder={labelFullname}
									debounceTimeout={250}
									loadOptions={getMemberFullnameList}
									additional={{
										page: 1,
									}}
									value={selectedMemberFullname}
									onChange={(e) => {
										if (!stringIsNullOrEmpty(e.value)) {
											sessionStorage.setItem(_SELECTED_FULLNAME_KEY, e.value);
										} else {
											sessionStorage.removeItem(_SELECTED_FULLNAME_KEY);
										}
										setLabelFullname(e.label);
										setSelectedMemberFullname(e.value);
									}}
								/>
							}
						</div>
					</div>

					<div className="col-lg-3">
						<div className="form-group">
							<label>
								<b>{t("MOBILE")}</b>
							</label>
							{
								<AsyncPaginate
									placeholder={labelUserPhone}
									debounceTimeout={250}
									loadOptions={getMemberPhoneList}
									additional={{
										page: 1,
									}}
									value={selectedMemberPhone}
									onChange={(e) => {
										if (!stringIsNullOrEmpty(e.value)) {
											sessionStorage.setItem(_SELECTED_MOBILE_KEY, e.value);
										} else {
											sessionStorage.removeItem(_SELECTED_MOBILE_KEY);
										}
										setLabelUserPhone(e.label);
										setSelectedMemberPhone(e.value);
									}}
								/>
							}
						</div>
					</div>
				</div>
				<div className="row">
					<label className="col-lg-3 text-right"></label>

					<div className="col-lg-3">
						<div className="form-group">
							<label>
								<b>{t("BALANCE")}</b>
							</label>
							<input
								className="form-control"
								type="text"
								onChange={(e) => {
									let parts = e.target.value.split(".");
									let v = parts[0].replace(/\D/g, ""),
										dec = parts[1];
									let calc_num = Number(dec !== undefined ? v + "." + dec : v);

									let n = new Intl.NumberFormat("en-EN").format(v);
									n = dec !== undefined ? n + "." + dec : n;

									setBalance(calc_num);
									setBalanceLabel(n);
								}}
								value={balanceLabel}
								pattern="[0-9.,]+"
							/>
						</div>
					</div>

					<div className="col-lg-3">
						<div className="form-group">
							<label>
								<b>{t("DIRECT_UPLINE")}</b>
							</label>
							{
								<AsyncPaginate
									placeholder={labelUpline}
									debounceTimeout={250}
									loadOptions={getMemberUplineList}
									additional={{
										page: 1,
									}}
									value={selectedUplineMember}
									onChange={(e) => {
										if (!stringIsNullOrEmpty(e.value)) {
											sessionStorage.setItem(_SELECTED_UPLINE_KEY, e.value);
										} else {
											sessionStorage.removeItem(_SELECTED_UPLINE_KEY);
										}
										setLabelUpline(e.label);
										setSelectedUplineMember(e.value);
									}}
								/>
							}
						</div>
					</div>

					<div className="col-lg-3">
						<div className="form-group">
							<label>
								<b>{t("DATE_JOINED")}</b>
							</label>
							<DateRangePicker
								containerStyles={{ width: "100%" }}
								//startDate={startJoinedDate}
								//endDate={endJoinedDate}
								onApply={handleJoinedEvent}
								alwaysShowCalendars={true}
								locale={{ format: "YYYY-MM-DD HH:mm:ss" }}
								timePicker={true}
								timePickerSeconds={true}
								ranges={{
									Today: [moment().startOf("day"), moment().endOf("day")],
									Yesterday: [
										moment().subtract(1, "days").startOf("day"),
										moment().subtract(1, "days").endOf("day"),
									],
									"Last 7 Days": [
										moment().subtract(6, "days").startOf("day"),
										moment().endOf("day"),
									],
									"Last 30 Days": [
										moment().subtract(29, "days").startOf("day"),
										moment().endOf("day"),
									],
									"This Month": [
										moment().startOf("month"),
										moment().endOf("month"),
									],
									"Last Month": [
										moment().subtract(1, "month").startOf("month"),
										moment().subtract(1, "month").endOf("month"),
									],
									"This Year": [
										moment().startOf("year"),
										moment().endOf("year"),
									],
								}}
							>
								<input
									type="text"
									className="form-control"
									value={
										stringIsNullOrEmpty(startJoinedDate)
											? ""
											: startJoinedDate + " - " + endJoinedDate
									}
								/>
							</DateRangePicker>
						</div>
					</div>
				</div>
				<div className="row">
					<label className="col-lg-3 text-right"></label>

					<div className="col-lg-3">
						<div className="form-group">
							<label>
								<b>{t("PREVIOUS_LOGIN")}</b>
							</label>
							<DateRangePicker
								containerStyles={{ width: "100%" }}
								//startDate={lastLoginStartDate}
								//endDate={lastLoginEndDate}
								onApply={handleLastLoginEvent}
								alwaysShowCalendars={true}
								locale={{ format: "YYYY-MM-DD HH:mm:ss" }}
								timePicker={true}
								timePickerSeconds={true}
								ranges={{
									Today: [moment().startOf("day"), moment().endOf("day")],
									Yesterday: [
										moment().subtract(1, "days").startOf("day"),
										moment().subtract(1, "days").endOf("day"),
									],
									"Last 7 Days": [
										moment().subtract(6, "days").startOf("day"),
										moment().endOf("day"),
									],
									"Last 30 Days": [
										moment().subtract(29, "days").startOf("day"),
										moment().endOf("day"),
									],
									"This Month": [
										moment().startOf("month"),
										moment().endOf("month"),
									],
									"Last Month": [
										moment().subtract(1, "month").startOf("month"),
										moment().subtract(1, "month").endOf("month"),
									],
									"This Year": [
										moment().startOf("year"),
										moment().endOf("year"),
									],
								}}
							>
								<input
									type="text"
									className="form-control"
									value={
										stringIsNullOrEmpty(lastLoginStartDate)
											? ""
											: lastLoginStartDate + " - " + lastLoginEndDate
									}
								/>
							</DateRangePicker>
						</div>
					</div>

					<div className="col-lg-3">
						<div className="form-group">
							<label>
								<b>{t("STATUS")}</b>
							</label>
							{
								<AsyncPaginate
									placeholder={labelStatus}
									debounceTimeout={250}
									loadOptions={getMemberStatus}
									additional={{
										page: 1,
									}}
									value={selectedMember}
									onChange={(e) => {
										if (!stringIsNullOrEmpty(e.value)) {
											sessionStorage.setItem(_SELECTED_STATUS_KEY, e.value);
										} else {
											sessionStorage.removeItem(_SELECTED_STATUS_KEY);
										}
										setLabelStatus(e.label);
										setSelectedStatus(e.value);
									}}
								/>
							}
						</div>
					</div>
				</div>

				<div className="row">
					<div className="col-lg-12 d-flex justify-content-end align-items-center pr-4">
						<button
							className="btn btn-primary"
							style={{
								marginLeft: "5px",
								marginTop: "40px",
								width: "100px",
							}}
							onClick={() => {
								searchAllBtnHandler();
							}}
						>
							{t("RESET")}
						</button>

						<button
							className="btn btn-primary"
							style={{
								marginLeft: "5px",
								marginTop: "40px",
								width: "100px",
							}}
							onClick={() => {
								searchBtnHandler();
							}}
						>
							{t("FILTER")}
						</button>
					</div>
				</div>
				<PanelBody>
					<ReactTable
						globalFilterable={false}
						data={memberData}
						columns={_tableColumns}
					/>
				</PanelBody>
			</Panel>
		</div>
	);
};

export default ManageMember;
