import React, { useState, useEffect } from "react";
import {
	ApiKey,
	ApiUrl,
	LanguageKey,
} from "../../util/Constant";
import { stringIsNullOrEmpty, createFormBody } from "../../util/Util";
import { setBusy, setIdle, showMessage } from "../../redux/AppAction";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import ApiEngine from "../../util/ApiEngine";

/// <summary>
/// Author : -
/// </summary>
const ManageConfigurationDailyCheckIn = (props) => {
	const { t } = useTranslation(LanguageKey._PRIMARY);
	const _dispatch = useDispatch();
	const {
		register,
		handleSubmit,
		errors,
		watch,
		trigger,
		setValue,
		getValues,
	} = useForm({ mode: "onChange" });
	const [data, setData] = useState([]);
	const [DCDId, setDCDId] = useState("");
	/// <summary>
	/// Author : -
	/// </summary>
	useEffect(() => {
		init();
	}, []);

	/// <summary>
	/// Author : -
	/// </summary>
	async function init() {
		let processedData = [];
		let responseJson = await ApiEngine.get(
			ApiUrl._API_GET_DAILY_CHECK_IN_DETAILS_BY_DCID + "?DCId=" + props.id
		);

		if (responseJson[ApiKey._API_SUCCESS_KEY]) {
			if (responseJson[ApiKey._API_DATA_KEY].length > 0) {
				const result = responseJson[ApiKey._API_DATA_KEY];

				result.map((x) => {
					processedData.push({
						id: x.id,
						label: x.days,
						points: x.points,
						isBigPrize: x.isBigPrize,
					});
				});
			} else {
				for (let i = 0; i < 28; i++) {
					const index = i + 1;
					let option = {
						id: index,
						label: t("DAY") + " " + index,
						points: 0,
						isBigPrize: false,
					};

					processedData.push(option);
				}
			}
		}

		setData(processedData);
	}

	const handlePointsOnChange = (id, points) => {
		setData(
			data.map((item) => (item.id === id ? { ...item, points: points } : item))
		);
	};

	const handleCheckboxChange = (id) => {
		setData(
			data.map((item) =>
				item.id === id ? { ...item, isBigPrize: !item.isBigPrize } : item
			)
		);
	};

	async function handleOnSubmit(data) {
		let labelList = [];
		let pointsList = [];
		let isBigPrizeList = [];
		for (var i = 0; i < data.length; i++) {
			labelList.push(data[i]["label"]);
			pointsList.push(data[i]["points"]);
			isBigPrizeList.push(data[i]["isBigPrize"]);
		}

		let params = {
			label: labelList,
			points: pointsList,
			isBigPrize: isBigPrizeList,
		};

		if (!stringIsNullOrEmpty(DCDId)) {
			params["id"] = DCDId;
		}

		if (!stringIsNullOrEmpty(props.id)) {
			params["DCID"] = props.id;
		}

		let formBody = createFormBody(params);
		_dispatch(setBusy());

		fetch(ApiUrl._API_CREATE_OR_UPDATE_DAILY_CHECK_IN_DETAILS, {
			method: ApiKey._API_POST,
			headers: {
				"Content-Type": ApiKey._API_FORM_URLENCODED,
				"X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
			},
			body: formBody,
		})
			.then((response) => response.json())
			.then((responseJson) => {
				_dispatch(setIdle());
				_dispatch(
					showMessage(
						responseJson[ApiKey._API_SUCCESS_KEY],
						t(responseJson[ApiKey._API_MESSAGE_KEY])
					)
				);

				if (responseJson[ApiKey._API_SUCCESS_KEY]) {
					init();
				}
			});
	}

	return (
		<div>
			<div className="row">
				{data.map((item, i) => {
					return (
						<div className="col-lg-3" key={i}>
							<div className="card m-b-5">
								<div
									className="card-header"
									style={{
										whiteSpace: "normal",
										wordBreak: "break-word",
										textAlign: "center",
									}}
								>
									<b>{item.label}</b>
								</div>
								<div className="row">
									<div className="col-lg-12">
										<div className="row m-5">
											<div
												className="col-lg-6"
												style={{ display: "flex", alignItems: "center" }}
											>
												<b>{t("POINTS")}</b>
											</div>
											<div className="col-lg-6">
												<input
													type="number"
													className="form-control m-b-5"
													ref={register({
														min: {
															value: 1,
															message: "PLEASE_ENTER_VALUE_NOT_LESS_THAN_ONE",
														},
														max: {
															value: 1000,
															message: "PLEASE_ENTER_VALUE_NOT_MORE_THAN_1000",
														},
													})}
													step="0.01"
													onChange={(e) => {
														handlePointsOnChange(item.id, e.target.value);
													}}
													defaultValue={item.points}
													name={"day-" + item.id}
													pattern="^\d+(?:\.\d{1,2})?$"
												/>
												{errors["day-" + item.id] && (
													<div className="invalid-feedback">
														{t(errors["day-" + item.id].message, {
															value: 100,
														})}
													</div>
												)}
											</div>
										</div>
										<div className="row m-5">
											<div
												className="col-lg-6"
												style={{ display: "flex", alignItems: "center" }}
											>
												<b>{t("ISBIGPRIZE")}</b>
											</div>
											<div className="col-lg-6">
												<div className="switcher">
													<input
														type="checkbox"
														name="isBigPrize"
														id="isBigPrize"
														onChange={() => {
															// handleCheckboxChange(item.id);
														}}
														value={item.isBigPrize}
														checked={item.isBigPrize}
														ref={register}
													/>
													<label
														htmlFor="isBigPrize"
														onClick={() => {
															handleCheckboxChange(item.id);
														}}
													></label>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					);
				})}
			</div>
			<div className="row">
				<div className="col-lg-12 m-5 text-right">
					<button
						type="submit"
						className="btn btn-primary"
						onClick={() => handleOnSubmit(data)}
					>
						{t("SUBMIT")}
					</button>
				</div>
			</div>
		</div>
	);
};

export default ManageConfigurationDailyCheckIn;
