import React, { useState, useEffect, useMemo } from "react";
import { withRouter } from "react-router-dom";
import { Panel, PanelBody } from "./../../components/panel/panel.jsx";
import NavigationButton from "../../components/constantComponent/button/NavigationButton";
import { ApiKey, ApiUrl, LanguageKey } from "../../util/Constant";
import { createFormBody } from "../../util/Util";
import { useLocation, useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { showMessage } from "../../redux/AppAction";
import { useTranslation } from "react-i18next";
import SweetAlert from "react-bootstrap-sweetalert";
import ApiEngine from "../../util/ApiEngine";

/// <summary>
/// Author : -
/// </summary>
const AccountShareHolder = (props) => {
    const { t } = useTranslation(LanguageKey._PRIMARY);
    let _history = useHistory();
    const _location = useLocation();
    const _dispatch = useDispatch();
    const { register, handleSubmit, errors, setValue, watch } = useForm({ mode: "onBlur" });
    const [isShareHolder, setIsShareHolder] = useState(false);
    const [shareHolderDate, setShareHolderDate] = useState(null);
    const [minPercent, setMinPercent] = useState(0);
    const [maxPercent, setMaxPercent] = useState(0);
    const [sharePercent, setSharePercent] = useState(0);
    const [commission, setCommission] = useState();
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [username, setUsername] = useState('');
    const [memberId, setMemberId] = useState('');


    /// <summary>
    /// Author : -
    /// </summary>
    useEffect(() => {
        init();
    }, []);


    /// <summary>
    /// Author : -
    /// </summary>
    function init() {
        if (_location.state) {
            console.log(_location.state);
            setMemberId(_location.state.id);
            setUsername(_location.state.username);

            console.log(_location.state.id);
            console.log('vs');
            console.log(_location.state.referralMemberId);

            if (_location.state.id == _location.state.referralMemberId) {
                setMinPercent(10)
                setMaxPercent(80);
            }
            else {
                setMinPercent(10);
                setMaxPercent(80);
            }

            setSharePercent(_location.state.sharePercent);
            setIsShareHolder(_location.state.isShareHolder);
            setShareHolderDate(_location.state.shareHolderDate);

            if (_location.state.shareHolderDate == null) {
                setSharePercent(80);
            }
        }
        else {
            _history.push("/shareholder/ShareholdersList")
        }
    }

    /// <summary>
    /// Author : -
    /// </summary>
    const submitForm = async (data, e) => {
        let params = {
            memberId: memberId,
            isShareHolder: data.isShareHolder,
            sharePercent: data.sharepercent,
        };

        let formBody = createFormBody(params);
        let responseJson = await ApiEngine.post(
            ApiUrl._API_UPDATE_SHAREHOLDER_DETAIL,
            formBody
        );
        _dispatch(
            showMessage(
                responseJson[ApiKey._API_SUCCESS_KEY],
                t(responseJson[ApiKey._API_MESSAGE_KEY])
            )
        );

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            setSharePercent(watch("sharepercent"));
            _history.push("/shareholder/ShareholdersList");
        }
    };

    /// <summary>
    /// Author : -
    /// </summary>
    function preSubmit() {
        if (sharePercent != watch("sharepercent")) {
            setShowConfirmation(true);
        } else {
            handleSubmit(submitForm)();
        }
    }

    return (
        <div>
            {showConfirmation && (
                <SweetAlert
                    warning
                    showCancel
                    confirmBtnText={t("CONFIRM")}
                    cancelBtnText={t("CANCEL")}
                    confirmBtnBsStyle="danger"
                    title={t("BONUS_COMMISSION_CHANGE_CONFIRMATION")}
                    onConfirm={() => {
                        setShowConfirmation(false);
                        handleSubmit(submitForm)();
                    }}
                    onCancel={() => {
                        setShowConfirmation(false);
                    }}
                ></SweetAlert>
            )}
            <h1 className="page-header">
                {t("SHAREHOLDER_VIEW_ACCOUNT")}
                <NavigationButton history={_history} />
            </h1>
            <div className="row">
                <div className="col-xl-12">
                    <Panel>
                        <PanelBody>
                            <form onSubmit={handleSubmit(submitForm)}>
                                <div className="row">
                                    <div className="col-lg-2">
                                        <h4>{t("MEMBER_DETAIL")}</h4>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-2">
                                        <label>
                                            <b>{t("USERNAME")}</b>
                                        </label>
                                        <input type="text" className="form-control" value={username} disabled />
                                    </div>
                                </div>

                                <hr />
                                <div className="row">
                                    <div className="col-lg-4">
                                        <label>
                                            <b>{t("IS_SH")}</b>
                                        </label>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-4">
                                        <div className="switcher">
                                            <input
                                                type="checkbox"
                                                name="isShareHolder"
                                                id="isShareHolder"
                                                onChange={(e) => setIsShareHolder(e.target.checked)}
                                                value={isShareHolder}
                                                checked={isShareHolder}
                                                ref={register}
                                            />
                                            <label htmlFor="isShareHolder"></label>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-lg-2">
                                        <label>
                                            <b>{t("SHARE_PERCENT")}</b>
                                        </label>

                                        <br />
                                        <input
                                            type="number"
                                            name="sharepercent"
                                            ref={register({
                                                required: "PLEASE_ENTER_SHARE_PERCENT",
                                                min: {
                                                    value: minPercent,
                                                    message: "PLEASE_ENTER_VALUE_NOT_LESS_THAN",
                                                },
                                                max: {
                                                    value: maxPercent,
                                                    message: "PLEASE_ENTER_VALUE_NOT_MORE_THAN",
                                                },
                                            })}
                                            className="form-control col-lg-6 m-b-5"
                                            placeholder={t("SHARE_PERCENT")}
                                            value={sharePercent}
                                            onChange={(e) => setSharePercent(e.target.value)}
                                            readOnly={!isShareHolder}
                                        />
                                        {errors.sharepercent && errors.sharepercent.type == "max" && (
                                            <div className="invalid-feedback">
                                                {t(errors.sharepercent.message, {
                                                    value: maxPercent,
                                                })}
                                            </div>
                                        )}
                                        {errors.sharepercent && errors.sharepercent.type == "min" && (
                                            <div className="invalid-feedback">
                                                {t(errors.sharepercent.message, {
                                                    value: minPercent,
                                                })}
                                            </div>
                                        )}
                                    </div>
                                </div>

                                {shareHolderDate != null && <div className="panel panel-default border m-t-20">
                                        <div className="panel-body">
                                            <div className="row">
                                                <div className="col-lg-2">
                                                    <h4>{t("SH_DETAILS")}</h4>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-2">
                                                    <label>
                                                        <b>{t("SH_QTY")}</b>
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        defaultValue="0"
                                                        disabled
                                                    />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-2">
                                                    <label>
                                                        <b>{t("SH_DATE")}</b>
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        defaultValue={shareHolderDate}
                                                        disabled
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                                <hr />
                                <div className="row">
                                    <div className="col-lg-3">
                                        <div className="form-group">
                                            <button
                                                type="button"
                                                onClick={() => preSubmit()}
                                                className="btn btn-primary"
                                            >
                                                {t("SUBMIT")}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </PanelBody>
                    </Panel>
                </div>
            </div>
        </div>
    );
};

export default withRouter(AccountShareHolder);
