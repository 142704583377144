import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';

function AudioRecorder({ url, memberId, onAudioRecorded, onAudioRecording, disabled }) {
  const [isRecording, setIsRecording] = useState(false);
  const mediaRecorderRef = useRef(null);
  const audioChunksRef = useRef([]);
  const recordingTimeoutRef = useRef(null);
  const cancelRecordinglRef = useRef(null);
  const [countDown, setCountDown] = useState(0);
  const countDownRef = useRef(0);

  useEffect(() => {
    // Clean up and potentially stop recording on component unmount
    return () => {
      if (mediaRecorderRef.current && isRecording) {
        mediaRecorderRef.current.stop();
      }
    };
  }, [isRecording]);

  const startRecording = async () => {
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
        try {

            if (onAudioRecording) {
                onAudioRecording(true);
            }

            const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
            const recorder = new MediaRecorder(stream);

            recorder.ondataavailable = (event) => {
                audioChunksRef.current.push(event.data);
            };

            recorder.onstop = async () => {

                if (!cancelRecordinglRef.current) {

                    try {
                        if (onAudioRecorded) {
                            onAudioRecorded(false);
                        }

                        const audioBlob = new Blob(audioChunksRef.current, { type: 'audio/ogg; codecs=opus' });

                        // Here you can also prepare the audioBlob for uploading, e.g., via FormData
                        const formData = new FormData();
                        formData.append('file', audioBlob, 'audio.ogg');
                        formData.append('memberId', memberId);


                        const response = await axios.post(url, formData, {
                            headers: {
                                'Content-Type': 'multipart/form-data',
                            },
                        });

                        // Call the callback function with the response data
                        if (response['data'].success) {
                            if (onAudioRecorded) {
                                onAudioRecorded(true);
                            }

                        }

                    } catch (error) {
                        console.error('Error uploading file:', error);
                        console.log(error);
                        alert('Error uploading file');
                    }

                }
                else {
                    if (onAudioRecorded) {
                        onAudioRecorded(true);
                    }
                }
            };

            mediaRecorderRef.current = recorder;
            audioChunksRef.current = [];
            recorder.start();
            setIsRecording(true);
            countDownRef.current = 30;
            setCountDown(30);

            recordingTimeoutRef.current = setInterval(() => {
                countDownRef.current--;
                setCountDown(i => i - 1);
                if (countDownRef.current <= 0)
                    stopRecording();
            }, 1000);
            // KT CHANGE TO COUNTDOWN

        } catch (error) {
            console.error('Error accessing audio devices:', error);
        }
    } else {
      alert('Audio recording is not supported in this browser.');
    }
  };

  const stopRecording = () => {
    if (onAudioRecording) {
        onAudioRecording(false);
    }
    cancelRecordinglRef.current = false;
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
      setIsRecording(false);
      clearInterval(recordingTimeoutRef.current);
    }
  };
  
  const cancelRecording = () => {
    if (onAudioRecording) {
        onAudioRecording(false);
    }
    cancelRecordinglRef.current = true;
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
      setIsRecording(false);
      clearInterval(recordingTimeoutRef.current);
    }
  };
  
  return (
    <div className={disabled?"disabled":""}>
      {!isRecording && <span onClick={!disabled && startRecording}>
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="20" cy="20" r="20" fill="rgba(0,0,0,0.05)"/>
          <path d="M20.5001 23.8458C21.6108 23.8458 22.5614 23.4692 23.3522 22.7163C24.1429 21.9635 24.5383 21.0577 24.5383 20V13.8463C24.5383 12.7885 24.1432 11.8832 23.3522 11.1299C22.5614 10.3768 21.6108 10 20.5001 10C19.3895 10 18.4389 10.3768 17.648 11.1299C16.8571 11.883 16.4617 12.7885 16.4617 13.8463V20C16.4617 21.0577 16.8572 21.9635 17.648 22.7163C18.4387 23.4692 19.3895 23.8458 20.5001 23.8458Z" fill="#3C3C3C"/>
          <path d="M27.5292 17.9208C27.3698 17.7685 27.1801 17.6924 26.9613 17.6924C26.7428 17.6924 26.5535 17.7685 26.3935 17.9208C26.2338 18.073 26.1538 18.2533 26.1538 18.4616V20.0001C26.1538 21.4825 25.6005 22.7505 24.4941 23.8041C23.3881 24.8578 22.0566 25.3847 20.5 25.3847C18.9434 25.3847 17.612 24.8578 16.5056 23.8041C15.3993 22.7508 14.8462 21.4826 14.8462 20.0001V18.4616C14.8462 18.2533 14.7662 18.073 14.6064 17.9208C14.4466 17.7685 14.2575 17.6924 14.0386 17.6924C13.8197 17.6924 13.6303 17.7685 13.4706 17.9208C13.3106 18.073 13.2307 18.2533 13.2307 18.4616V20.0001C13.2307 21.7709 13.8513 23.3115 15.0922 24.6214C16.3331 25.9314 17.8664 26.6826 19.6923 26.8747V28.4614H16.4615C16.2428 28.4614 16.0535 28.5377 15.8937 28.69C15.7338 28.8421 15.6538 29.0224 15.6538 29.2308C15.6538 29.4388 15.7338 29.6195 15.8937 29.7716C16.0535 29.9238 16.2428 30.0001 16.4615 30.0001H24.5382C24.7569 30.0001 24.9464 29.9238 25.1061 29.7716C25.2661 29.6195 25.3461 29.4388 25.3461 29.2308C25.3461 29.0225 25.2661 28.8421 25.1061 28.69C24.9465 28.5377 24.7569 28.4614 24.5382 28.4614H21.3079V26.8747C23.1334 26.6826 24.6666 25.9314 25.9076 24.6214C27.1487 23.3115 27.7694 21.7709 27.7694 20.0001V18.4616C27.7694 18.2533 27.6893 18.0732 27.5292 17.9208Z" fill="#3C3C3C"/>
        </svg>
      </span>}
      {isRecording && 
              <>
              <div className="t3-recording-countdown">{countDown}s</div>
              <div className="t3-audio-wave">
                  <div className="t3-audio-inner-wave"></div>
              </div>
              <div className="t3-audio-cancel" onClick={cancelRecording}><svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="20" cy="20" r="20" fill="#7E2F2F"/><g clip-path="url(#clip0_2628_2808)"><path d="M21.8291 20L29.6207 12.2084C29.7414 12.0883 29.8371 11.9454 29.9021 11.7881C29.9672 11.6307 30.0005 11.462 29.9999 11.2918C30.002 11.1219 29.9695 10.9533 29.9043 10.7964C29.8391 10.6396 29.7426 10.4976 29.6207 10.3793C29.5024 10.2574 29.3604 10.1609 29.2036 10.0957C29.0467 10.0305 28.8781 9.99797 28.7083 10.0001C28.538 9.99954 28.3693 10.0328 28.2119 10.0979C28.0546 10.1629 27.9117 10.2586 27.7916 10.3793L20 18.1709L12.2084 10.3793C12.0883 10.2586 11.9454 10.1629 11.7881 10.0979C11.6307 10.0328 11.462 9.99954 11.2918 10.0001C11.1219 9.99797 10.9533 10.0305 10.7964 10.0957C10.6396 10.1609 10.4976 10.2574 10.3793 10.3793C10.2574 10.4976 10.1609 10.6396 10.0957 10.7964C10.0305 10.9533 9.99797 11.1219 10.0001 11.2918C9.99954 11.462 10.0328 11.6307 10.0979 11.7881C10.1629 11.9454 10.2586 12.0883 10.3793 12.2084L18.1709 20L10.3793 27.7916C10.2586 27.9117 10.1629 28.0546 10.0979 28.2119C10.0328 28.3693 9.99954 28.538 10.0001 28.7083C9.99797 28.8781 10.0305 29.0467 10.0957 29.2036C10.1609 29.3604 10.2574 29.5024 10.3793 29.6207C10.4976 29.7426 10.6396 29.8391 10.7964 29.9043C10.9533 29.9695 11.1219 30.002 11.2918 29.9999C11.462 30.0005 11.6307 29.9672 11.7881 29.9021C11.9454 29.8371 12.0883 29.7414 12.2084 29.6207L20 21.8291L27.7916 29.6207C27.9117 29.7414 28.0546 29.8371 28.2119 29.9021C28.3693 29.9672 28.538 30.0005 28.7083 29.9999C29.0505 29.9988 29.3784 29.8624 29.6204 29.6204C29.8624 29.3784 29.9988 29.0505 29.9999 28.7083C30.0005 28.538 29.9672 28.3693 29.9021 28.2119C29.8371 28.0546 29.7414 27.9117 29.6207 27.7916L21.8291 20Z" fill="whitez"/></g><defs><clipPath id="clip0_2628_2808"><rect width="20" height="20" fill="white" transform="translate(10 10)"/></clipPath></defs></svg></div>
              <div className="t3-audio-confirm" onClick={stopRecording}><svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="20" cy="20" r="20" fill="#2F797E"/><g clip-path="url(#clip0_2674_1302)"><path d="M18.2051 26.6667C17.7945 26.6667 17.4084 26.5067 17.1174 26.2162L10.4507 19.5494C10.1603 19.2588 10 18.8725 10 18.4616C10 18.0507 10.1603 17.6643 10.4507 17.3737C10.7412 17.0831 11.1278 16.9233 11.5385 16.9233C11.9491 16.9233 12.3357 17.0831 12.6262 17.3736L18.2052 22.9525L27.3738 13.784C27.6643 13.4934 28.0509 13.3335 28.4615 13.3335C28.8722 13.3335 29.2588 13.4934 29.5493 13.7838C29.8397 14.0746 30 14.4609 30 14.8719C30 15.2828 29.8397 15.6691 29.5493 15.9597L19.2929 26.2161C19.0019 26.5067 18.6158 26.6667 18.2051 26.6667Z" fill="white"/></g><defs><clipPath id="clip0_2674_1302"><rect width="20" height="20" fill="white" transform="translate(10 10)"/></clipPath></defs></svg></div>
              </>
          }
    </div>
  );
}

export default AudioRecorder;
