import React, { useState, useEffect, useContext } from "react";
//import "react-simple-keyboard/build/css/index.css";
import ApiEngine from "../../util/ApiEngine";
import {
	ApiKey,
	ApiUrl,
	WebUrl,
	LanguageKey,
	Role,
	AccessRight,
} from "../../util/Constant";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation, withRouter } from "react-router-dom";
import moment from "moment";
//import ContentHeader from "components/content/header";

import "../../assets/css/custom.css";
import appsettings from "../../appsettings-fe.json";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { PageSettings } from "./../../config/page-settings.js";
import { performLogin } from "../../redux/AuthAction.js";
import { showMessage } from "../../redux/AppAction.js";
import {
	loadCaptchaEnginge,
	LoadCanvasTemplateNoReload,
	validateCaptcha,
} from "react-simple-captcha";
import store from "./../../ApplicationStore.js";
import { checkIfPermissionExist } from "../../util/PermissionChecker.js";

const Login = (props) => {
	var { t } = useTranslation(LanguageKey._PRIMARY);
	var _history = useHistory();
	var _location = useLocation();

	const _TAB_OPTIONS = {
		USER: 1,
		MEMBER: 2,
	};

	const _captcha_length = 4;
	const _context = useContext(PageSettings);
	const _dispatch = useDispatch();
	const { isLoggedIn } = useSelector((state) => ({
		isLoggedIn: state.authState.isLoggedIn,
	}));

	const { register, handleSubmit, errors } = useForm();
	const [activeTab, setActiveTab] = useState(
		appsettings.ApplicationConfig.isMemberPortal == "true"
			? _TAB_OPTIONS["MEMBER"]
			: _TAB_OPTIONS["USER"]
	);

	useEffect(() => {
		loadCaptchaEnginge(_captcha_length, "black", "white", "numbers");
		_context.handleSetPageSidebar(false);
		_context.handleSetPageHeader(false);
		_context.handleSetPageContentFullWidth(true);

		return () => {
			_context.handleSetPageSidebar(true);
			_context.handleSetPageHeader(true);
			_context.handleSetPageContentFullWidth(false);
		};
	}, []);

	/// <summary>
	/// Author : -
	/// </summary>
	useEffect(() => {
		if (isLoggedIn) {
			let tempUserData = store.getState()["authState"]["userData"];
			if (tempUserData["userRole"].includes("Customer Service")) {
				_history.push(WebUrl._URL_TRANSACTION_REPORT); //7.6
			} else if (tempUserData["userRole"].includes("Payment Official")) {
				_history.push(WebUrl._URL_TRANSACTION_ENQUIRY); //4.2
			} else if (
				!checkIfPermissionExist(AccessRight._DASHBOARD_DETAILS_PERMISSION)
			) {
				_history.push(WebUrl._URL_HELP);
			} else {
				_history.push(WebUrl._URL_MAIN);
			}
		}
	}, [isLoggedIn]);

	/// <summary>
	/// Author : -
	/// </summary>
	const submitForm = async (data, e) => {
		var temp = validateCaptcha(data.loginCaptcha);
		if (temp === true) {
			await _dispatch(
				performLogin(
					data.username,
					data.password,
					activeTab == _TAB_OPTIONS["USER"],
					data.companyCode ?? ""
				)
			);
		} else {
			_dispatch(showMessage(false, "CAPTCHA_FAILED"));
		}
	};

	return (
		<>
			<div className="login login-with-news-feed">
				<section
					id="richwin-section"
					style={{
						background: "#1e90ff",
						// backgroundImage: `url(${require("../../assets/img/login-bg/OKGO_Login_Wallpaper_2.jpg")})`,
					}}
				>
					<div className="richwin-box">
						<div className="">
							<form onSubmit={handleSubmit(submitForm)}>
								<div className="d-flex flex-column">
									<h2 className="text-center">
										<span>COMMAND CENTER</span>
									</h2>
									<div className="demo">
										<div className="login">
											<div className="login__check">
												{/* <h2 className="mb-4 text-center">
													OKGO <span>admin</span>
												</h2> */}
											</div>
											<div className="login__form">
												<div className="login__row">
													<svg
														className="login__icon name svg-icon"
														viewBox="0 0 20 20"
													>
														<path d="M0,20 a10,8 0 0,1 20,0z M10,0 a4,4 0 0,1 0,8 a4,4 0 0,1 0,-8" />
													</svg>
													<input
														id="username"
														key="username"
														type="text"
														name="username"
														ref={register({
															required: "PLEASE_ENTER_USERNAME",
														})}
														className="login__input name"
														placeholder={t("USERNAME")}
													/>
													{errors.username && (
														<div className="invalid-feedback">
															{t(errors.username.message)}
														</div>
													)}
												</div>

												<div className="login__row">
													<svg
														className="login__icon pass svg-icon"
														viewBox="0 0 20 20"
													>
														<path d="M0,20 20,20 20,8 0,8z M10,13 10,16z M4,8 a6,8 0 0,1 12,0" />
													</svg>
													<input
														id="password"
														key="password"
														type="password"
														name="password"
														ref={register({
															required: "PLEASE_ENTER_PASSWORD",
														})}
														className="login__input pass"
														placeholder={t("PASSWORD")}
													/>
													{errors.password && (
														<div className="invalid-feedback">
															{t(errors.password.message)}
														</div>
													)}
												</div>

												<div
													className="login__row"
													style={{ display: "flex", alignItems: "center" }}
												>
													<div className="recaptcha-text">
														<div>
															<LoadCanvasTemplateNoReload />
														</div>
													</div>
													<input
														name="loginCaptcha"
														ref={register({
															required: "PLEASE_ENTER_CAPTCHA",
															minLength: {
																value: _captcha_length,
																message: "PLEASE_FOLLOW_CAPTCHA_LENGTH",
															},
															maxLength: {
																value: _captcha_length,
																message: "PLEASE_FOLLOW_CAPTCHA_LENGTH",
															},
														})}
														type="text"
														className="login__input captcha"
														placeholder={t("PLEASE_ENTER_CAPTCHA")}
													/>
													{errors.loginCaptcha && (
														<div className="invalid-feedback">
															{t(errors.loginCaptcha.message)}
														</div>
													)}
												</div>

												{/* <div className="login__row"></div> */}
												{activeTab == _TAB_OPTIONS["MEMBER"] && (
													<div className="login__row mb-4">
														<input
															id="companyCode"
															key="companyCode"
															type="companyCode"
															name="companyCode"
															ref={register({
																required: "PLEASE_ENTER_COMPANY_CODE",
															})}
															className="form-white-input input-bg-white"
															placeholder={t("COMPANY_CODE")}
														/>
														{errors.companyCode && (
															<div className="invalid-feedback">
																{t(errors.companyCode.message)}
															</div>
														)}
													</div>
												)}

												<button type="submit" className="login__submit">
													{t("LOGIN")}
												</button>
											</div>
										</div>
									</div>
									<div className="version-number">
										Ver. {process.env.REACT_APP_VERSION}
									</div>
								</div>
							</form>
						</div>
					</div>
				</section>
			</div>
		</>
	);
};
export default withRouter(Login);
