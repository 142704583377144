import React, { useState, useEffect } from "react";
import ReactTable from "../../components/constantComponent/reactTable/ReactTable";
import { Panel, PanelBody } from "./../../components/panel/panel.jsx";
import "../../assets/css/custom.css";
import {
    UncontrolledButtonDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from "reactstrap";
import {
    ApiUrl,
    ApiKey,
    ContentTypeString,
    LanguageKey,
} from "../../util/Constant";
import { stringIsNullOrEmpty } from "../../util/Util";
import { useTranslation } from "react-i18next";
import ConfigurationLanguageDetail from "./ConfigurationLanguageDetail";
import { fetch } from "whatwg-fetch";

/// <summary>
/// Author : -
/// </summary>
const  ManageBannerLanguage  = (props) => {
    const { t } = useTranslation(LanguageKey._PRIMARY);
    const [data, setData] = useState([]);
    const [
        languageDetailModalVisibility,
        setLanguageDetailModalVisibility,
    ] = useState(false);

    const _EXCLUDE_TITLE_TYPE = [
        ContentTypeString._VIP_BENEFIT_CONTENT,
        ContentTypeString._VIP_CRITERIA,
    ];
    const _EXCLUDE_CONTENT_TYPE = [
        ContentTypeString._VIP_BENEFIT,
        ContentTypeString._PRODUCT_CATEGORY,
    ];

    const [languageDetailId, setLanguageDetailId] = useState("");
    const [tableColumns, setTableColumns] = useState([]);

    let _tableColumns = [
        {
            Header: "LANGUAGE",
            accessor: "languageName",
            disableSortBy: true,
        },
        {
            Header: "CONTENT",
            accessor: "content",
            disableSortBy: true,
        },
        {
            Header: "TITLE",
            accessor: "title",
            disableSortBy: true,
        },
        {
            Header: "DESKTOP_IMAGE",
            accessor: "desktopImage",
            Cell: ({ row }) => (
                <div style={{textAlign: "center", margin:"18px"}}>{!stringIsNullOrEmpty(row.original.desktopImage) ? <i className="icon-green-tick fa fa-check"></i> : "" }</div>
                ),
            disableSortBy: true,
        },
        {
            Header: "MOBILE_IMAGE",
            accessor: "mobileImage",
            Cell: ({ row }) => (
                <div style={{ textAlign: "center", margin: "18px" }}>{!stringIsNullOrEmpty(row.original.mobileImage) ? <i className="icon-green-tick fa fa-check"></i> : ""}</div>
                ),
            disableSortBy: true,
        },
        {
            id: "action",
            Header: "",
            Cell: ({ row }) => (
                <div className="btn-group m-r-5 m-b-5">
                    <UncontrolledButtonDropdown>
                        <DropdownToggle caret color="default">
                            <i className="fas fa-cog"></i>
                        </DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem
                                onClick={() => {
                                    setLanguageDetailId(row.original.id);
                                    setLanguageDetailModalVisibility(true);
                                }}
                            >
                                <span className="text-warning">{t("EDIT")}</span>
                            </DropdownItem>
                        </DropdownMenu>
                    </UncontrolledButtonDropdown>
                </div>
            ),
            disableSortBy: true,
            disableFilters: true,
            width: 100,
            style: { overflow: "visible" },
        },
    ];

    /// <summary>
    /// Author : -
    /// </summary>
    useEffect(() => {
        getConfigLanguage();
        /// Used to Exclude the column of table
        if (_EXCLUDE_CONTENT_TYPE.includes(props.type)) {
            _tableColumns.splice(1, 1);
        } else if (_EXCLUDE_TITLE_TYPE.includes(props.type)) {
            _tableColumns.splice(2, 1);
        }
        setTableColumns(_tableColumns);
    }, []);

    /// <summary>
    /// Author : -
    /// </summary>
    const getConfigLanguage = async () => {
        await fetch(
            ApiUrl._API_GET_CONFIG_LANGUAGE_BY_TYPE_ID +
            "?type=" +
            props.type +
            "&id=" +
            props.id,
            {
                method: ApiKey._API_GET,
                headers: {
                    "Content-Type": ApiKey._API_FORM_URLENCODED,
                    "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
                },
            }
        )
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                    setData(responseJson[ApiKey._API_DATA_KEY]);
                }
            });
    };

    return (
        <div>
            <div className="row">
                <ConfigurationLanguageDetail
                    visible={languageDetailModalVisibility}
                    onFinish={() => {
                        setLanguageDetailModalVisibility(false);
                        getConfigLanguage();
                    }}
                    dataTypeId={props.id}
                    languageDetailId={languageDetailId}
                    languageType={props.type}
                    dataDisplayDevice={props.displayDevice}
                />
            </div>
            <Panel>
                <PanelBody>
                    <button
                        type="button"
                        onClick={() => {
                            setLanguageDetailId("");
                            setLanguageDetailModalVisibility(true);
                        }}
                        className="btn btn-purple"
                    >
                        {t("ADD_LANGUAGE")}
                    </button>
                    <ReactTable data={data} columns={tableColumns} minRows={1} />
                </PanelBody>
            </Panel>
        </div>
    );
};

export default ManageBannerLanguage;
