import React, { useState } from "react";
import ReactTable from "../../components/constantComponent/reactTable/ReactTable";
import { Panel, PanelBody } from "./../../components/panel/panel.jsx";
import moment from "moment";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { useTranslation } from "react-i18next";
import { LanguageKey } from "../../util/Constant";
/// <summary>
/// Author : -
/// </summary>

const ManageChannelSummaryReport = (props) => {
  const { t } = useTranslation(LanguageKey._PRIMARY);
  const [calendarStartDate, setCalendarStartDate] = useState(
    moment().format("YYYY-MM-DD")
  );
  const [calendarEndDate, setCalendarEndDate] = useState(
    moment().format("YYYY-MM-DD")
  );

  let _tableColumns = [
    {
      Header: t("DATE"),
      accessor: "date",
      Footer: () => {
        return (
          <span>
            <b>{t("TOTAL")}</b>
          </span>
        );
      },
      minWidth: 100,
    },
    {
      Header: t("REGISTER"),
      accessor: "register",
    },
    {
      Header: t("FIRST_DEPOSIT"),
      accessor: "firstDeposit1",
      Cell: ({ row }) => {
        return (
          <span>
            {row.original.firstDeposit1} / {row.original.firstDeposit2} /{" "}
            {row.original.firstDeposit3}
          </span>
        );
      },
      minWidth: 200,
    },
    {
      Header: t("DEPOSIT"),
      accessor: "deposit1",
      Cell: ({ row }) => {
        return (
          <span>
            {row.original.deposit1} / {row.original.deposit2} /{" "}
            {row.original.deposit3}
          </span>
        );
      },
      minWidth: 200,
    },
    {
      Header: t("WITHDRAWAL"),
      accessor: "withdrawal1",
      Cell: ({ row }) => {
        return (
          <span>
            {row.original.withdrawal1} / {row.original.withdrawal2} /{" "}
            {row.original.withdrawal3}
          </span>
        );
      },
      minWidth: 200,
    },
    {
      Header: t("ADJUSTMENT"),
      accessor: "adjustment1",
      Cell: ({ row }) => {
        return (
          <span>
            {row.original.adjustment1} / {row.original.adjustment2} /{" "}
            {row.original.adjustment3}
          </span>
        );
      },
      minWidth: 200,
    },
    {
      Header: t("GROSS_BALANCE"),
      accessor: "grossBalance",
    },
    {
      Header: t("BONUS"),
      accessor: "bonus",
    },
    {
      Header: t("REBATE"),
      accessor: "rebate",
    },
    {
      Header: t("TURNOVER"),
      accessor: "turnover1",
      Cell: ({ row }) => {
        return (
          <span>
            {row.original.turnover1} / {row.original.turnover2}
          </span>
        );
      },
      minWidth: 200,
    },
    {
      Header: t("PROFIT_LOSS"),
      accessor: "profitLoss",
      Cell: ({ row }) => {
        if (row.original.profitLoss < 0) {
          return <p style={{ color: "red" }}>{row.original.profitLoss}</p>;
        } else {
          return <p>{row.original.profitLoss}</p>;
        }
      },
    },
  ];

  const handleEvent = (event, picker) => {
    setCalendarStartDate(moment(picker.startDate).format("YYYY-MM-DD"));
    setCalendarEndDate(moment(picker.endDate).format("YYYY-MM-DD"));
  };

  return (
    <div>
      <h1 className="page-header">{t("CHANNEL_SUMMARY_REPORT")}</h1>
      <Panel>
        <PanelBody>
          <div className="row">
            <div className="col-lg-12">
              <div className="row">
                <div className="col-lg-4">
                  <div
                    className="form-group"
                    style={{ display: "flex", width: "100%" }}
                  >
                    <div className="input-group" style={{ width: "100%" }}>
                      <div className="input-group-prepend">
                        <span className="input-group-text">
                          <i className="fa fa-calendar"></i>
                        </span>
                      </div>
                      <div style={{ width: "80%" }}>
                        <DateRangePicker
                          containerStyles={{ width: "100%" }}
                          startDate={calendarStartDate}
                          endDate={calendarEndDate}
                          onApply={handleEvent}
                          alwaysShowCalendars={true}
                          locale={{ format: "YYYY-MM-DD" }}
                          ranges={{
                            Today: [moment(), moment()],
                            Yesterday: [
                              moment().subtract(1, "days"),
                              moment().subtract(1, "days"),
                            ],
                            "Last 7 Days": [
                              moment().subtract(6, "days"),
                              moment(),
                            ],
                            "Last 30 Days": [
                              moment().subtract(29, "days"),
                              moment(),
                            ],
                            "This Month": [
                              moment().startOf("month"),
                              moment().endOf("month"),
                            ],
                            "Last Month": [
                              moment().subtract(1, "month").startOf("month"),
                              moment().subtract(1, "month").endOf("month"),
                            ],
                          }}
                        >
                          <input
                            type="text"
                            className="form-control"
                            value={calendarStartDate + " - " + calendarEndDate}
                          />
                        </DateRangePicker>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="form-group row m-b-15">
                    <label
                      className="col-md-5 col-form-label"
                      style={{ marginRight: "-20px" }}
                    >
                      <b>{t("REGISTER_CHANNEL")}</b>
                    </label>
                    <div className="col-md-7" style={{ marginLeft: "-20px" }}>
                      <input type="text" className="form-control" />
                    </div>
                  </div>
                </div>
                <div className="col-lg-3">
                  <button type="button" className="btn btn-primary">
                    {t("SEARCH")}
                  </button>
                </div>
              </div>
              <hr />
              <ReactTable
                data={[]}
                columns={_tableColumns}
                renderFooter={true}
              />
            </div>
          </div>
        </PanelBody>
      </Panel>
    </div>
  );
};

export default ManageChannelSummaryReport;
