import React, { useState, useEffect, useCallback } from "react";
import ReactTable from "../../components/constantComponent/reactTable/ReactTable";
import { Panel, PanelBody } from "./../../components/panel/panel.jsx";
import { ApiKey, ApiUrl, LanguageKey } from "../../util/Constant";
import { useDispatch } from "react-redux";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { fetch } from "whatwg-fetch";
import { setBusy, setIdle, showMessage } from "../../redux/AppAction";
import { CSVLink } from "react-csv";
/// <summary>
/// Author : -
/// </summary>

const BankSummaryStatement = (props) => {
	const [statementDatas, setStatementDatas] = useState([]);
	const { t } = useTranslation(LanguageKey._PRIMARY);
	const _dispatch = useDispatch();
	const [exportDatas, setExportDatas] = useState([]);
	const [filteredDatas, setFilteredDatas] = useState([]);
	/// <summary>
	/// Author : -
	/// </summary>
	useEffect(() => {
		init();
	}, []);

	/// <summary>
	/// Author : -
	/// </summary>
	const init = async () => {
		_dispatch(setBusy());
		await fetch(
			ApiUrl._API_GET_BANK_SUMMARY_STATEMENT +
				"?bankAccountId=" +
				props.data.bankAccountId,
			{
				method: ApiKey._API_GET,
				headers: {
					"Content-Type": ApiKey._API_FORM_URLENCODED,
					"X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
				},
			}
		)
			.then((response) => response.json())
			.then((responseJson) => {
				if (responseJson[ApiKey._API_SUCCESS_KEY]) {
					setStatementDatas(responseJson[ApiKey._API_DATA_KEY]);
				} else {
					_dispatch(
						showMessage(
							responseJson[ApiKey._API_SUCCESS_KEY],
							t(responseJson[ApiKey._API_MESSAGE_KEY])
						)
					);
				}
				_dispatch(setIdle());
			});
	};

	let _exportHeaders = [
		{ label: "Bank", key: "bankName" },
		{ label: "Account", key: "bankAccountNumber" },
		{ label: "Date Time", key: "date" },
		{ label: "Credit", key: "credit" },
		{ label: "Debit", key: "debit" },
		{ label: "Remark", key: "remark" },
		{ label: "Admin", key: "admin" },
	];

	let _tableColumns = [
		{
			Header: "ID",
			accessor: "id",
			Cell: ({ row }) => {
				return <>{row.index + 1}</>;
			},
		},
		{
			Header: t("BANK"),
			accessor: "bankName",
		},
		{
			Header: t("ACCOUNT"),
			accessor: "bankAccountNumber",
		},
		{
			Header: t("DATETIME"),
			accessor: "date",
			Cell: ({ row }) => {
				return <>{moment(row.original.date).format("YYYY-MM-DD HH:mm:ss")}</>;
			},
		},
		{
			Header: t("CREDIT"),
			accessor: "credit",
			Cell: ({ row }) => {
				if (row.original.credit > 0) {
					return <>{row.original.credit.toFixed(2)}</>;
				} else {
					return <span></span>;
				}
			},
		},
		{
			Header: t("DEBIT"),
			accessor: "debit",
			Cell: ({ row }) => {
				if (row.original.debit > 0) {
					return (
						<span style={{ color: "red" }}>
							-{row.original.debit.toFixed(2)}
						</span>
					);
				} else {
					return <span></span>;
				}
			},
		},
		{
			Header: t("REMARK"),
			accessor: "remark",
		},
		{
			Header: t("ADMIN"),
			accessor: "admin",
		},
	];

	/// <summary>
	/// Author : -
	/// </summary>
	const getFilteredRecords = useCallback(({ page }) => {
		updateFilteredDatas(page);
	}, []);

	/// <summary>
	/// Author : -
	/// </summary>
	const updateFilteredDatas = (page) => {
		let newFilteredDatas = [];
		let newExportDatas = [];
		let totalCredit = 0;
		let totalDebit = 0;
		page.map((data, index) => {
			let row = data.original;
			totalCredit += row.credit;
			totalDebit += row.debit;
			let newFilteredData = {
				bankName: row.bankName,
				bankAccountNumber: row.bankAccountNumber,
				date: moment(row.date).format("YYYY-MM-DD HH:mm:ss"),
				credit: row.credit,
				debit: row.debit,
				remark: row.remark,
				admin: row.admin,
			};
			newFilteredDatas.push(newFilteredData);

			newFilteredData.status =
				newFilteredData.status == true ? "Active" : "Inactive";

			newExportDatas.push(newFilteredData);
		});

		setFilteredDatas(newFilteredDatas);
		newExportDatas.push(
			{
				bankName: "",
			},
			{
				bankName: "Total",
				credit: totalCredit,
				debit: totalDebit,
				balance: totalCredit - totalDebit,
			}
		);
		setExportDatas(newExportDatas);
	};

	return (
		<div>
			<Panel>
				<PanelBody>
					<div className="row">
						<div className="col-lg-3">
							<CSVLink
								className="btn btn-primary btn-lg"
								data={exportDatas}
								headers={_exportHeaders}
								filename={
									"BankSummaryStatement(" +
									moment().format("YYYY-MM-DD HH:mm:ss") +
									").csv"
								}
								target="_blank"
							>
								<i className="fas fa-file-csv"></i>
							</CSVLink>
						</div>
						<div className="col-lg-12">
							<ReactTable
								data={statementDatas}
								columns={_tableColumns}
								getFilteredRecords={getFilteredRecords}
							/>
						</div>
					</div>
				</PanelBody>
			</Panel>
		</div>
	);
};

export default BankSummaryStatement;
