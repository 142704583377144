import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { Panel, PanelHeader, PanelBody } from "./../../components/panel/panel.jsx";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Dropzone from "react-dropzone";
import NavigationButton from "../../components/constantComponent/button/NavigationButton";
import {
  ApiKey,
  ApiUrl,
  DisplayMode,
  LanguageKey,
  ContentTypeString,
  AllVipOption,
} from "../../util/Constant";
import {
  stringIsNullOrEmpty,
  createMultiPartFormBody,
  imagePathToFileObject,
} from "../../util/Util";
import { useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { setBusy, setIdle, showMessage } from "../../redux/AppAction";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import classnames from "classnames";
import { fetch } from "whatwg-fetch";
import ApiEngine from "../../util/ApiEngine"
import moment from "moment";
import ReactTable from "../../components/constantComponent/reactTable/ReactTable";

/// <summary>
/// Author : -
/// </summary>

const PromotionDetail = (props) => {
  const _DETAIL_TAB = "1";
  const _LANGUAGE_TAB = "2";
  let _history = useHistory();
  const { t } = useTranslation(LanguageKey._PRIMARY);
  const _location = useLocation();
  const _dispatch = useDispatch();
  const { register, unregister, handleSubmit, errors } = useForm();
  const [displayMode, setDisplayMode] = useState("");

  const [description, setDescription] = useState("");
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [url, setUrl] = useState("");
  const [promotionId, setPromotionId] = useState(props.id);
  const [promotionRefId, setPromotionRefId] = useState("");
  const [status, setStatus] = useState(true);

  const [desktopFile, setDesktopFile] = useState([]);
  const [mobileFile, setMobileFile] = useState([]);
  const [desktopImage, setDesktopImage] = useState();
  const [mobileImage, setMobileImage] = useState();
  const [isNewDesktopImage, setIsNewDesktopImage] = useState(false);
  const [isNewMobileImage, setIsNewMobileImage] = useState(false);
  const [activeTab, setActiveTab] = useState(_DETAIL_TAB);
  const [vip, setVip] = useState();
  const [vipOption, setVipOption] = useState([]);
  const [claimedPromo, setClaimedPromo] = useState();
  const [promoData, setPromoData] = useState();
  const [productCategoryData, setProductCategoryData] = useState([]);
  const [vipList, setVipList] = useState([]);

  let _tableColumns = [
    {
      Header: t("GAME_PROVIDER"),
      accessor: "productName",
      disableSortBy: true,
    },
    {
      Header: t("STATUS"),
      accessor: "status",
      Cell: ({ row }) => (
        <div className="btn-group m-r-5 m-b-5">
          {row.original.status == true ? (
            <span className="text-approved font-bold">
              {t("ACTIVE")}
            </span>
          ) : (
            <span className="text-rejected font-bold">
              {t("INACTIVE")}
            </span>
          )}
        </div>
      ),
      disableSortBy: true,
      style: { overflow: "visible" },
    }
  ];

  /// <summary>
  /// Author : -
  /// </summary>
  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  /// <summary>
  /// Author : -
  /// </summary>
  useEffect(() => {
    init();
  }, []);

  /// <summary>
  /// Author : -
  /// </summary>
  async function init() {

    await fetch(ApiUrl._API_GET_USER_VIP + "?all=true", {
      method: ApiKey._API_GET,
      headers: {
        "Content-Type": ApiKey._API_FORM_URLENCODED,
        "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
          const VipList = [];
          responseJson[ApiKey._API_DATA_KEY].forEach(function (vip) {
            VipList.push({ label: vip.name, value: vip.id });
          });
          setVipList(VipList);
        }
      });
    await fetch(ApiUrl._API_GET_PRODUCT_CATEGORY + "?all=true", {
      method: ApiKey._API_GET,
      headers: {
        "Content-Type": ApiKey._API_FORM_URLENCODED,
        "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
          setProductCategoryData(responseJson[ApiKey._API_DATA_KEY]);
          const categoryList = [];
          responseJson[ApiKey._API_DATA_KEY].forEach(function (category) {
            categoryList.push({ label: category.title, value: category.id });
          });
          setProductCategoryData(categoryList);
        }
      });

    const queryParams = new URLSearchParams(window.location.search);
    const id = queryParams.get('id') || "";
    var responseJson = await ApiEngine.get(ApiUrl._API_GET_MEMBER_CLAIMED_PROMOTION_DETAIL + "?id=" + id);

    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
      setClaimedPromo(JSON.parse(responseJson[ApiKey._API_DATA_KEY]["remark"]));
      console.log(JSON.parse(responseJson[ApiKey._API_DATA_KEY]["remark"]))
      setPromoData(responseJson[ApiKey._API_DATA_KEY]);


    }
  }

  const _OPTION_STYLES = {
    control: (base) => ({
      ...base,
      "min-height": "34px",
      height: "34px",
    }),
    valueContainer: (base) => ({
      ...base,
      height: "34px",
      "min-height": "34px",
    }),
    indicatorsContainer: (base) => ({
      ...base,
      height: "34px",
      "min-height": "34px",
    }),
    input: (base) => ({
      height: "34px",
      "min-height": "34px",
    }),
  };


  return (
    <div>
      <h1 className="page-header">
        {t("View Promotion")}
      </h1>
      <TabContent activeTab={activeTab}>
        <TabPane tabId={_DETAIL_TAB}>
          {claimedPromo && promoData &&
            <div>
              <div className="row">
                <div className="col-xl-12">
                  <Panel>
                    <PanelBody>
                      <form >
                        <div className="row">
                          <div className="col-lg-8">
                            <div className="form-group row m-b-15">
                              <label className="col-md-4 col-form-label">
                                <b>{t("TITLE")}</b>
                              </label>
                              <div className="col-md-7">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder={t("PLEASE_ENTER_TITLE")}
                                  defaultValue={claimedPromo.Name}
                                  name="title"
                                  rows="5"
                                  style={{ marginTop: "10px" }}
                                  disabled
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-8">
                            <div className="form-group row m-b-15">
                              <label className="col-md-4 col-form-label">
                                <b>
                                  {" "}
                                  {claimedPromo.IsBonusFixed
                                    ? t("BONUS_AMOUNT")
                                    : t("BONUS_RATE")}
                                </b>
                              </label>
                              <div className="col-md-7">
                                <input
                                  type="text"
                                  className="form-control"
                                  rows="5"
                                  style={{ marginTop: "10px" }}
                                  name="bonusRate"
                                  defaultValue={claimedPromo.BonusRate}
                                  disabled
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-8">
                            <div className="form-group row m-b-15">
                              <label className="col-md-4 col-form-label">
                                <b>{t("ROLLOVER_RATE")}</b>
                              </label>
                              <div className="col-md-7">
                                <input
                                  type="text"
                                  className="form-control"
                                  rows="5"
                                  style={{ marginTop: "10px" }}
                                  name="rolloverRate"
                                  defaultValue={claimedPromo.RolloverRate}
                                  disabled
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-8">
                            <div className="form-group row m-b-15">
                              <label className="col-md-4 col-form-label">
                                <b>{t("DATE")}</b>
                              </label>
                              <div className="col-md-7">
                                <input
                                  type="text"
                                  className="form-control"
                                  value={moment(claimedPromo.StartDate).format("YYYY-MM-DD HH:mm:ss") + " - " + moment(claimedPromo.EndDate).format("YYYY-MM-DD HH:mm:ss")}
                                  disabled
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-8">
                            <div className="form-group row m-b-15">
                              <label className="col-md-4 col-form-label">
                                <b>{t("MIN_VIP_LEVEL")}</b>
                              </label>
                              <div className="col-md-7">
                                <input
                                  type="text"
                                  className="form-control"
                                  rows="5"
                                  style={{ marginTop: "10px" }}
                                  name="minVipLevel"
                                  defaultValue={vipList.filter(
                                    (vip) => vip.value == claimedPromo.MinVipLevel
                                  )[0].label}
                                  disabled
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-8">
                            <div className="form-group row m-b-15">
                              <label className="col-md-4 col-form-label">
                                <b>{t("MIN_DEPOSIT")}</b>
                              </label>
                              <div className="col-md-7">
                                <input
                                  type="text"
                                  className="form-control"
                                  rows="5"
                                  style={{ marginTop: "10px" }}
                                  name="minDeposit"
                                  defaultValue={claimedPromo.MinDeposit}
                                  disabled
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-8">
                            <div className="form-group row m-b-15">
                              <label className="col-md-4 col-form-label">
                                <b>{t("MINCOIN_TARGET")}</b>
                              </label>
                              <div className="col-md-7">
                                <input
                                  type="text"
                                  className="form-control"
                                  rows="5"
                                  style={{ marginTop: "10px" }}
                                  name="mincoinTarget"
                                  defaultValue={claimedPromo.MinCoinTarget}
                                  disabled
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-8">
                            <div className="form-group row m-b-15">
                              <label className="col-md-4 col-form-label">
                                <b>{t("MAX_WITHDRAW")}</b>
                              </label>
                              <div className="col-md-7">
                                <input
                                  type="text"
                                  className="form-control"
                                  rows="5"
                                  style={{ marginTop: "10px" }}
                                  name="maxWithdraw"
                                  defaultValue={claimedPromo.MaxWithdraw}
                                  disabled
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-8">
                            <div className="form-group row m-b-15">
                              <label className="col-md-4 col-form-label">
                                <b>{t("BONUS_CAP")}</b>
                              </label>
                              <div className="col-md-7">
                                <input
                                  type="text"
                                  className="form-control"
                                  rows="5"
                                  style={{ marginTop: "10px" }}
                                  name="bonusCap"
                                  defaultValue={claimedPromo.BonusCap}
                                  disabled
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-8">
                            <div className="form-group row m-b-15">
                              <label className="col-md-4 col-form-label">
                                <b>{t("REDEMPTION_PER_USER")}</b>
                              </label>
                              <div className="col-md-7">
                                <input
                                  type="text"
                                  className="form-control"
                                  rows="5"
                                  style={{ marginTop: "10px" }}
                                  name="redemption"
                                  defaultValue={claimedPromo.MaxUsage}
                                  disabled
                                />
                                <div className="invalid-feedback">
                                  {t("ZERO_EQUAL_UNLIMITED")}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-8">
                            <div className="form-group row m-b-15">
                              <label className="col-md-4 col-form-label">
                                <b>{t("REMARK")}</b>
                              </label>
                              <div className="col-md-7">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder={t("PLEASE_ENTER_REMARK")}
                                  defaultValue={claimedPromo.Remark}
                                  name="remark"
                                  style={{ marginTop: "10px" }}
                                  disabled
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-8">
                            <div className="form-group row m-b-15">
                              <label className="col-md-4 col-form-label">
                                <b>{t("RANK")}</b>
                              </label>
                              <div className="col-md-7">
                                <input
                                  type="number"
                                  className="form-control"
                                  placeholder={t("PLEASE_ENTER_RANK")}
                                  defaultValue={claimedPromo.Rank}
                                  name="rank"
                                  style={{ marginTop: "10px" }}
                                  disabled
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-8">
                            <div className="form-group row m-b-15">
                              <label className="col-md-4 col-form-label">
                                <b>{t("IS_FIXED_BONUS")}</b>
                                <br />
                                <small>{t("NOT_CAL_BY_PERCENT")}</small>
                              </label>
                              <div className="col-md-7">
                                <div className="switcher">
                                  <input
                                    type="checkbox"
                                    name="isBonusFixed"
                                    id="isBonusFixed"
                                    value={true}
                                    checked={claimedPromo.IsBonusFixed}
                                  />
                                  <label htmlFor="isBonusFixed"></label>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-8">
                            <div className="form-group row m-b-15">
                              <label className="col-md-4 col-form-label">
                                <b>{t("IS_CLAIMABLE")}</b>
                              </label>
                              <div className="col-md-7">
                                <div className="switcher">
                                  <input
                                    type="checkbox"
                                    name="isClaimable"
                                    id="isClaimable"
                                    value={true}
                                    checked={claimedPromo.IsClaimable}
                                  />
                                  <label htmlFor="isClaimable"></label>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-8">
                            <div className="form-group row m-b-15">
                              <label className="col-md-4 col-form-label">
                                <b>{t("IS_MAX_USAGE_PER_DAY")}</b>
                              </label>
                              <div className="col-md-7">
                                <div className="switcher">
                                  <input
                                    type="checkbox"
                                    name="isMaxUsagePerDay"
                                    id="isMaxUsagePerDay"
                                    value={true}
                                    checked={claimedPromo.IsMaxUsagePerDay}
                                  />
                                  <label htmlFor="isMaxUsagePerDay"></label>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-8">
                            <div className="form-group row m-b-15">
                              <label className="col-md-4 col-form-label">
                                <b>{t("PRODUCT_CATEGORY")}</b>
                                <br />
                                <small>{t("ALL_EQUAL_SPECIFIC_PROVIDER")}</small>
                              </label>
                              <div className="col-md-7">
                                {console.log(productCategoryData.filter(
                                  (category) =>
                                    category.value == promoData.productCategoryId
                                )[0].label)}
                                <input
                                  type="text"
                                  className="form-control"
                                  rows="5"
                                  style={{ marginTop: "10px" }}
                                  name="bonusCap"
                                  defaultValue={productCategoryData.filter(
                                    (category) =>
                                      category.value == promoData.productCategoryId
                                  )[0].label}
                                  disabled
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <hr />
                      </form>
                    </PanelBody>
                  </Panel>
                </div>
              </div>
              {!stringIsNullOrEmpty(promoData) &&
                promoData.productCategoryId == 1 &&
                promoData.depositPromotionProducts &&
                (
                  <div className="row">
                    <div className="col-xl-12">
                      <Panel>
                        <PanelHeader>{t("SELECTED_PRODUCT")}</PanelHeader>
                        <PanelBody>
                          <div className="row">
                            <div className="col-xl-12">
                              <ReactTable
                                data={promoData.depositPromotionProducts}
                                columns={_tableColumns}
                              />
                            </div>
                          </div>
                        </PanelBody>
                      </Panel>
                    </div>
                  </div>
                )}
            </div>
          }
        </TabPane>
      </TabContent>
    </div>
  );
};

export default withRouter(PromotionDetail);
