import React, { useState, useEffect } from "react";
import ReactTable from "../../components/constantComponent/reactTable/ReactTable";
import { Panel, PanelBody } from "../../components/panel/panel.jsx";
import { useHistory } from "react-router-dom";
import {
	UncontrolledButtonDropdown,
	DropdownToggle,
	DropdownMenu,
	DropdownItem,
} from "reactstrap";
import "react-datepicker/dist/react-datepicker.css";
import NavigationButton from "../../components/constantComponent/button/NavigationButton";
import { navigateTo, numberWithCurrencyFormat } from "../../util/Util";
import {
	ApiKey,
	ApiUrl,
	AccessRight,
	WebUrl,
	LanguageKey,
	DEFAULT_PAGE_SIZE,
	Role,
} from "../../util/Constant";
import { checkIfPermissionExist } from "../../util/PermissionChecker";
import { setBusy, setIdle, showMessage } from "../../redux/AppAction";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import ApiEngine from "../../util/ApiEngine";
import { AsyncPaginate } from "react-select-async-paginate";
import classNames from "classnames";
import moment from "moment";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { stringIsNullOrEmpty, createFormBody } from "../../util/Util";

/// <summary>
/// Author : -
/// </summary>
const ManageMemberGamePoints = (props) => {
	const { t } = useTranslation(LanguageKey._PRIMARY);
	let _history = useHistory();
	const _dispatch = useDispatch();
	var _username = useSelector(
		(state) => state["authState"]["userData"]["username"]
	);

	const [tableData, setTableData] = useState([]);
	const [startDate, setStartDate] = useState(
		moment().startOf("isoWeek").format("YYYY-MM-DD")
	);

	const [endDate, setEndDate] = useState(
		moment().endOf("isoWeek").format("YYYY-MM-DD")
	);

	const [selectedDate, setSelectedDate] = useState(
		moment().subtract(1, "days").format("YYYY-MM-DD")
	);

	let _tableColumns = [
		{
			id: "action",
			Header: "",
			Cell: ({ row }) =>
				checkIfPermissionExist(AccessRight._MANAGE_MEMBER_GAME_POINTS, true) &&
				(row.original.isClaim == false ? (
					<div className="btn-group m-r-5 m-b-5">
						<input
							type="checkbox"
							value={row.original.id + "#" + row.original.date}
							checked={selectedCheckboxes.includes(
								row.original.id + "#" + row.original.date
							)}
							onChange={(e) => handleCheckboxChange(e)}
						/>
					</div>
				) : (
					<div className="btn-group m-r-5 m-b-5">
						<span className="badge badge-secondary badge-green">
							{t("RELEASED")}
						</span>
					</div>
				)),
			disableSortBy: true,
			disableFilters: true,
			width: 50,
			style: { overflow: "visible" },
		},
		{
			Header: "USERNAME",
			accessor: "username",
			minWidth: 100,
			Cell: ({ row }) => {
				return <span>{row.original.username}</span>;
			},
		},
		{
			Header: "DATE",
			minWidth: 100,
			Cell: ({ row }) => {
				return moment.utc(row.original.date).format("DD-MM-YYYY");
			},
		},
		{
			Header: "TOTAL_TURNOVER",
			accessor: "totalTurnover",
			minWidth: 100,
		},
		{
			Header: "GAME_POINTS",
			accessor: "gamePoints",
			minWidth: 100,
		},
	];

	const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);

	const handleCheckboxChange = (e) => {
		const { value } = e.target;
		setSelectedCheckboxes((prevSelected) => {
			if (prevSelected.includes(value)) {
				return prevSelected.filter((id) => id !== value);
			} else {
				return [...prevSelected, value];
			}
		});
	};

	/// <summary>
	/// Author : -
	/// </summary>
	useEffect(() => {
		init();
	}, []);

	const init = async () => {
		fetch(ApiUrl._API_GET_MEMBER_GAME_POINTS + "?startDate=" + selectedDate, {
			method: ApiKey._API_GET,
			headers: {
				"Content-Type": ApiKey._API_FORM_URLENCODED,
				"X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
			},
		})
			.then((response) => response.json())
			.then((responseJson) => {
				if (responseJson[ApiKey._API_SUCCESS_KEY]) {
					setTableData(responseJson[ApiKey._API_DATA_KEY].data);
				}
			});
	};

	async function getMemberGamePoints() {
		_dispatch(setBusy());
		fetch(ApiUrl._API_GET_MEMBER_GAME_POINTS + "?startDate=" + selectedDate, {
			method: ApiKey._API_GET,
			headers: {
				"Content-Type": ApiKey._API_FORM_URLENCODED,
				"X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
			},
		})
			.then((response) => response.json())
			.then((responseJson) => {
				if (responseJson[ApiKey._API_SUCCESS_KEY]) {
					setTableData(responseJson[ApiKey._API_DATA_KEY].data);
				}
				_dispatch(setIdle());
			});
	}

	async function Claim(idList, totalTurnoverList, gamePointsList, startDateList) {

		let tempList = [];
		for (var i = 0; i < idList.length; i++) {
			var splitId = idList[i].split("#");
			tempList.push(splitId[0]);
		}

		let params = {
			memberList: tempList,
			totalTurnoverList: totalTurnoverList,
			gamePointsList: gamePointsList,
			date: startDateList[0],
		};

		let formBody = createFormBody(params);
		_dispatch(setBusy());
		fetch(ApiUrl._API_GENERATE_MEMBER_GAME_POINTS, {
			method: ApiKey._API_POST,
			headers: {
				"Content-Type": ApiKey._API_FORM_URLENCODED,
				"X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
			},
			body: formBody,
		})
			.then((response) => response.json())
			.then((responseJson) => {
				getMemberGamePoints();
				_dispatch(setIdle());
				_dispatch(
					showMessage(
						responseJson[ApiKey._API_SUCCESS_KEY],
						t(responseJson[ApiKey._API_MESSAGE_KEY])
					)
				);
			});
	}

	async function ClaimAll(
		idList,
		totalTurnoverList,
		gamePointsList,
		startDateList
	) {
		let params = {
			memberList: idList,
			totalTurnoverList: totalTurnoverList,
			gamePointsList: gamePointsList,
			date: startDateList[0],
		};

		let formBody = createFormBody(params);
		_dispatch(setBusy());
		fetch(ApiUrl._API_GENERATE_MEMBER_GAME_POINTS, {
			method: ApiKey._API_POST,
			headers: {
				"Content-Type": ApiKey._API_FORM_URLENCODED,
				"X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
			},
			body: formBody,
		})
			.then((response) => response.json())
			.then((responseJson) => {
				getMemberGamePoints();
				_dispatch(setIdle());
				_dispatch(
					showMessage(
						responseJson[ApiKey._API_SUCCESS_KEY],
						t(responseJson[ApiKey._API_MESSAGE_KEY])
					)
				);
			});
	}

	const handleEvent = (event, picker) => {
		setSelectedDate(moment(picker.startDate).format("YYYY-MM-DD"));
	};

	return (
		<div>
			<h1 className="page-header">{t("MEMBER_GAME_POINTS")}</h1>
			<Panel>
				<div className="row">
					<div
						className="input-group"
						style={{
							width: "100%",
							flexFlow: "nowrap",
							marginTop: "14px",
							marginLeft: "25px",
						}}
					>
						<div className="input-group-prepend">
							<span className="input-group-text">
								<i className="fa fa-calendar"></i>
							</span>
						</div>
						<div style={{ width: "30%" }}>
							<DateRangePicker
								containerStyles={{ width: "100%" }}
								singleDatePicker={true}
								onApply={handleEvent}
								alwaysShowCalendars={true}
								locale={{ format: "YYYY-MM-DD" }}
								maxDate={moment().subtract(1, "days").format("YYYY-MM-DD")}
							>
								<input
									type="text"
									className="form-control"
									value={selectedDate}
								/>
							</DateRangePicker>
						</div>
						<button
							type="button"
							className="btn btn-primary"
							style={{ marginLeft: "12px" }}
							onClick={() => getMemberGamePoints()}
						>
							{t("SEARCH")}
						</button>
					</div>
				</div>
				<div className="row">
					<div>
						<button
							className="btn btn-primary"
							style={{
								marginLeft: "25px",
								marginTop: "30px",
								width: "100px",
								position: "absolute",
								zIndex: "1",
							}}
							onClick={() =>
								Claim(
									selectedCheckboxes,
									tableData
										.filter(
											(x) =>
												x.isClaim == false &&
												selectedCheckboxes.includes(x.id + "#" + x.date)
										)
										.map((member) => member.totalTurnover),
									tableData
										.filter(
											(x) =>
												x.isClaim == false &&
												selectedCheckboxes.includes(x.id + "#" + x.date)
										)
										.map((member) => member.gamePoints),
									tableData
										.filter(
											(x) =>
												x.isClaim == false &&
												selectedCheckboxes.includes(x.id + "#" + x.date)
										)
										.map((member) =>
											moment.utc(member.date).format("YYYY-MM-DD")
										)
								)
							}
							disabled={selectedCheckboxes.length > 0 ? false : true}
						>
							{t("RELEASE")}
						</button>
						<button
							className="btn btn-primary"
							style={{
								marginLeft: "140px",
								marginTop: "30px",
								width: "100px",
								position: "absolute",
								zIndex: "1",
							}}
							onClick={() =>
								ClaimAll(
									tableData
										.filter((x) => x.isClaim == false)
										.map((member) => member.id),
									tableData
										.filter((x) => x.isClaim == false)
										.map((member) => member.totalTurnover),
									tableData
										.filter((x) => x.isClaim == false)
										.map((member) => member.gamePoints),
									tableData
										.filter(
											(x) =>
												x.isClaim == false 
												// &&
												// selectedCheckboxes.includes(x.id + "#" + x.date)
										)
										.map((member) =>
											moment.utc(member.date).format("YYYY-MM-DD")
										)
								)
							}
							disabled={
								tableData.filter((x) => x.isClaim == false).length > 0
									? false
									: true
							}
						>
							{t("RELEASE_ALL")}
						</button>
					</div>

					<div className="col-lg-12">
						<PanelBody>
							{<ReactTable data={tableData} columns={_tableColumns} />}
						</PanelBody>
					</div>
				</div>
			</Panel>
		</div>
	);
};

export default ManageMemberGamePoints;
