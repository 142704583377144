import React, { useState, useEffect } from "react";
import ReactTable from '../../components/constantComponent/reactTable/ReactTable';
import { Panel, PanelBody } from "./../../components/panel/panel.jsx";
import { ApiKey, ApiUrl, WebUrl, LanguageKey, DEFAULT_PAGE_SIZE } from "../../util/Constant";
import { useTranslation } from "react-i18next";
import moment from "moment";
import ApiEngine from "../../util/ApiEngine.js";
import { numberWithCurrencyFormat, stringIsNullOrEmpty } from "../../util/Util";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { AsyncPaginate } from "react-select-async-paginate";
import { showMessage } from "../../redux/AppAction";
import { useDispatch } from "react-redux";

const TransferLog = () => {
    const { t, i18n } = useTranslation(LanguageKey._PRIMARY);
    const _dispatch = useDispatch();

    const [initPage, setInitPage] = useState(true);
    const [logData, setLogData] = useState([]);
    const [startDate, setStartDate] = useState(moment().startOf('day').format("YYYY-MM-DD"));
    const [endDate, setEndDate] = useState(moment().endOf('day').format("YYYY-MM-DD"));
    const [labelUsername, setLabelUsername] = useState("All");
    const [selectedMember, setSelectedMember] = useState("");
    const [memberId, setMemberId] = useState("");
    const _SELECTED_MEMBER_KEY = "selectedMemberKey";
    const [memberListUrl, setMemberListUrl] = useState(
        `${ApiUrl._API_GET_DOWNLINE_BY_REFERRER}?memberId=${memberId}`
    );

    const _TABLE_COLUMNS = [
        {
            Header: "CREATED_TIME",
            accessor: "createdTime",
            Cell: ({ row }) => {
                return (
                    <>
                        {moment(row.original.createdTime).format('YYYY-MM-DD HH:mm:ss:sss')}
                    </>
                );
            },
        },
        {
            Header: "Value",
            accessor: "value"
        }
    ]

    const getTransferLog = async (playerGuid) => {
        let playerId = playerGuid;
        let apiUrl = ApiUrl._API_GET_TRANSFER_LOG + "?startDate=" + startDate + "&endDate=" + endDate;
        if (!stringIsNullOrEmpty(playerId)) {
            apiUrl += "&player=" + playerId;
        }

        var responseJson = await ApiEngine.get(apiUrl);
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            let data = responseJson[ApiKey._API_DATA_KEY];
            setLogData(data);
        }
        else {
            _dispatch(showMessage(responseJson[ApiKey._API_SUCCESS_KEY], t(responseJson[ApiKey._API_MESSAGE_KEY])));
        }
        setInitPage(false);
    }

    /// <summary>
    /// Author : Wong
    /// </summary>
    async function getMemberList(search, loadOptions, { page }) {
        var responseJson = await ApiEngine.get(
            `${ApiUrl._API_GET_USER_MEMBER_LIST}?&start=${(page - 1) * DEFAULT_PAGE_SIZE
            }&count=${DEFAULT_PAGE_SIZE}&keyword=${search}`
        );

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            console.log(responseJson[ApiKey._API_DATA_KEY]);
            let data = responseJson[ApiKey._API_DATA_KEY]["data"];
            let formattedData = data.map((m) => {
                return { label: m.username, value: m.id };
            });
          formattedData.unshift({ label: "All", value: "" })
            return {
                options: formattedData,
                hasMore: formattedData.length == DEFAULT_PAGE_SIZE,
                additional: {
                    page: page + 1,
                },
            };
        }
    }

    /// <summary>
    /// Author : -
    /// </summary>
    async function getDownlineByReferrer(memberId = "") {
        setMemberListUrl(
            `${ApiUrl._API_GET_DOWNLINE_BY_REFERRER}?memberId=${memberId}`
        );
    }

    /// <summary>
    /// Author : -
    /// </summary>
    useEffect(() => {
        sessionStorage.setItem(_SELECTED_MEMBER_KEY, selectedMember);
        getDownlineByReferrer(selectedMember);
    }, [selectedMember]);

    /// <summary>
    /// Author : -
    /// </summary>
    const handleEvent = (event, picker) => {
        setStartDate(moment(picker.startDate).format("YYYY-MM-DD"));
        setEndDate(moment(picker.endDate).format("YYYY-MM-DD"));
    };

    return (
        <div>
            <h1 className="page-header">{t("TRANSFER_LOG")}</h1>
            <Panel>
                <div className="row">
                    <div className="col-lg-12">
                        <PanelBody>
                            <div className="row">
                                <div className="col-lg-4">
                                    <div className="form-group">
                                        <label>
                                            <b>{t("DATE")}</b>
                                        </label>
                                        <div style={{ display: "flex" }}>
                                            <div className="input-group" style={{ width: "100%" }}>
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text">
                                                        <i className="fa fa-calendar"></i>
                                                    </span>
                                                </div>
                                                <div style={{ width: "80%" }}>
                                                    <DateRangePicker
                                                        containerStyles={{ width: "100%" }}
                                                        alwaysShowCalendars={true}
                                                        onApply={handleEvent}
                                                        maxDate={moment().endOf('day')}
                                                        ranges={{
                                                            'Today': [moment().startOf('day'), moment().endOf('day')],
                                                            'Yesterday': [moment().subtract(1, 'days').startOf('day'), moment().subtract(1, 'days').endOf('day')],
                                                            'Last 7 Days': [moment().subtract(6, 'days').startOf('day'), moment().endOf('day')],
                                                            'Last 30 Days': [moment().subtract(29, 'days').startOf('day'), moment().endOf('day')],
                                                            'This Month': [moment().startOf('month'), moment().endOf('month')],
                                                            'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
                                                            'This Year': [moment().startOf('year'), moment().endOf('year')]
                                                        }}>
                                                        <input type="text" className="form-control" value={startDate + " - " + endDate} />
                                                    </DateRangePicker>
                                                </div>
                                            </div>
                                            <button
                                                type="button"
                                                className="btn btn-primary"
                                                onClick={() => getTransferLog()}
                                            >
                                                {t("SEARCH")}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-1"></div>
                                <div className="col-lg-4">
                                    <div className="form-group">
                                        <label>
                                            <b>{t("USERNAME")}</b>
                                        </label>
                                        <AsyncPaginate
                                            placeholder={labelUsername}
                                            debounceTimeout={250}
                                            loadOptions={getMemberList}
                                            additional={{
                                                page: 1,
                                            }}
                                            value={selectedMember}
                                            onChange={(e) => {
                                                if (!stringIsNullOrEmpty(e.value)) {
                                                    sessionStorage.setItem(_SELECTED_MEMBER_KEY, e.value);
                                                } else {
                                                    sessionStorage.removeItem(_SELECTED_MEMBER_KEY);
                                                }
                                                setLabelUsername(e.label);
                                                setSelectedMember(e.value);
                                                getTransferLog(e.value);
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="transfer-log-table">
                                {!initPage ? <ReactTable data={logData} columns={_TABLE_COLUMNS} /> : <div style={{ fontSize: "15px" }}>{t("SELECT_DATE_AND_CONTINUE")}</div>}
                            </div>
                        </PanelBody>
                    </div>
                </div>
            </Panel>
        </div>
    )
}

export default TransferLog;