import React from "react";
import ReactTable from "../../../components/constantComponent/reactTable/ReactTable";
import { Panel } from "./../../../components/panel/panel.jsx";
import { useHistory } from "react-router-dom";
import {
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { navigateTo } from "../../../util/Util";
import NavigationButton from "../../../components/constantComponent/button/NavigationButton";

/// <summary>
/// Author : -
/// </summary>

const ManageBankPackage = (props) => {
  let _history = useHistory();
  let _data = [];
  let _tableColumns = [
    {
      Header: "Name",
      accessor: "name",
      style: {
        whiteSpace: "unset",
        wordBreak: "break-word",
      },
      width: 150,
    },
    {
      Header: "Status",
      accessor: "status",
      width: 150,
    },
    {
      id: "empty1",
      Header: "",
      accessor: "",
    },
    {
      id: "empty2",
      Header: "",
      accessor: "",
    },
    {
      Header: "Last Updated By",
      accessor: "last_updated_by",
      width: 100,
    },
    {
      Header: "Last Updated Date",
      accessor: "last_updated_at",
      width: 100,
    },
    {
      id: "action",
      Header: "",
      Cell: ({ row }) => (
        <div className="btn-group m-r-5 m-b-5">
          <UncontrolledButtonDropdown>
            <DropdownToggle caret color="default">
              <i className="fas fa-cog"></i>
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem
                onClick={() => {
                  navigateTo(_history, "/configure/createOrEditBankPackage");
                }}
              >
                <span className="text-warning">Edit</span>
              </DropdownItem>
              <DropdownItem>
                <span className="text-danger">Delete</span>
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledButtonDropdown>
        </div>
      ),
      disableSortBy: true,
      disableFilters: true,
      width: 100,
      style: { overflow: "visible" },
    },
  ];

  return (
    <div>
      <h1 className="page-header">
        Bank Packages
        <NavigationButton
          history={_history}
          url={"/configure/createOrEditBankPackage"}
          buttonText={"Add Bank Package"}
        />
      </h1>
      <Panel>
        <ReactTable filterable data={_data} columns={_tableColumns} />
      </Panel>
    </div>
  );
};

export default ManageBankPackage;
