import React, { useState, useEffect, useRef } from 'react';
import { withRouter } from "react-router-dom";
import { Panel, PanelBody } from "./../../components/panel/panel.jsx";
import { useHistory, useLocation } from "react-router-dom";
import NavigationButton from "../../components/constantComponent/button/NavigationButton";
import {
    ApiKey,
    ApiUrl,
    LanguageKey,
} from "../../util/Constant";
import { setBusy, setIdle, showMessage } from "../../redux/AppAction";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import ApiEngine from "../../util/ApiEngine";
import { createFormBody, createMultiPartFormBody, stringIsNullOrEmpty } from "../../util/Util.js";
import moment from 'moment';
import Icon from '@mdi/react'
import { mdiContentCopy } from '@mdi/js';

const MemberSportsBetLimit = props => {
    const { t } = useTranslation(LanguageKey._PRIMARY);
    const _dispatch = useDispatch();
    const _history = useHistory();
    const _location = useLocation();
    const [betLimitData, setBetLimitData] = useState([]);
    const [betLimitStr, setBetLimitStr] = useState("");
    const [initCheck, setInitCheck] = useState(false);
    const [selectedSportsType, setSelectedSportsType] = useState(0);
    const [selectedMarketType, setSelectedMarketType] = useState(0);
    const [selectedProductName, setSelectedProductName] = useState("SBO Sports");
    const [mpUsername, setMpUsername] = useState("");
    const defaultSplitSymbol = "|";

    var _copyRef = useRef(null);
    const {
        register,
        unregister,
        handleSubmit,
        errors,
        setError,
        control,
        setValue,
    } = useForm();

    /// <summary>
    /// Author : -
    /// </summary>
    useEffect(() => {
        if (_location.state) {
            init();
        }
        setInitCheck(true);
    }, []);

    /// <summary>
    /// Author : -
    /// </summary>
    useEffect(() => {
        if (betLimitData.length > 0) {
            let tempData = betLimitData.find(x => x.sportsType === selectedSportsType && x.marketType === selectedMarketType && x.productName === selectedProductName);
            if (typeof (tempData) != "undefined") {
                let tempBetLimitStr = Math.trunc(tempData.minimumBet) + defaultSplitSymbol + Math.trunc(tempData.maximumBet) + defaultSplitSymbol + Math.trunc(tempData.maximumBetPerMatch);
                setBetLimitStr(tempBetLimitStr);
            }
            else {
                setBetLimitStr("");
            }
            setMpUsername(betLimitData.filter(x => x.productName === selectedProductName)[0]['username']);
        }
    }, [selectedSportsType, selectedMarketType, selectedProductName]);

    async function init() {
        let apiUrl = ApiUrl._API_GET_MEMBER_SPORTS_BET_LIMIT + "?id=" + props.id;
        var responseJson = await ApiEngine.get(apiUrl);
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            let data = responseJson[ApiKey._API_DATA_KEY];
            setBetLimitData(data);
            let temp = data.find(x => x.sportsType === selectedSportsType && x.marketType === selectedMarketType && x.productName === selectedProductName);
            let tempBetLimitStr = Math.trunc(temp.minimumBet) + defaultSplitSymbol + Math.trunc(temp.maximumBet) + defaultSplitSymbol + Math.trunc(temp.maximumBetPerMatch);
            setBetLimitStr(tempBetLimitStr);
            setMpUsername(data.filter(x => x.productName === selectedProductName)[0]['username']);
        }
    }

    const submitForm = async (data) => {
        let betSetting = betLimitStr.split(defaultSplitSymbol);
        let params = {
            "minBet": betSetting[0],
            "maxBet": betSetting[1],
            "maxBetPerMatch": betSetting[2],
            "memberId": props.id,
            "sportsType": selectedSportsType,
            "marketType": selectedMarketType,
            "productName": selectedProductName
        };

        let formBody = createMultiPartFormBody(params);
        let apiUrl = ApiUrl._API_CREATE_OR_UPDATE_MEMBER_SPORTS_BET_LIMIT;
        let responseJson = await ApiEngine.post(apiUrl, formBody);
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            let data = responseJson[ApiKey._API_DATA_KEY];
            setBetLimitData(data);
            let temp = data.find(x => x.sportsType === selectedSportsType && x.marketType === selectedMarketType && x.productName === selectedProductName);
            let tempBetLimitStr = Math.trunc(temp.minimumBet) + defaultSplitSymbol + Math.trunc(temp.maximumBet) + defaultSplitSymbol + Math.trunc(temp.maximumBetPerMatch);
            setBetLimitStr(tempBetLimitStr);
            setMpUsername(data.filter(x => x.productName === selectedProductName)[0]['username']);
        }
        _dispatch(showMessage(responseJson[ApiKey._API_SUCCESS_KEY], t(responseJson[ApiKey._API_MESSAGE_KEY])));
    }

    function copyUsername() {
        _copyRef.current.select();
        document.execCommand("copy");
    }

    return (
        <div>
            <h1 className="page-header">
                {t("ADJUST_BET_LIMIT")}
            </h1>
            <div className="row">
                <div className="col-xl-12">
                    {initCheck && <Panel>
                        <PanelBody>
                            <form onSubmit={handleSubmit(submitForm)}>
                                <div className="col-lg-8">
                                    <div className="form-group row m-b-15">
                                        <label className="col-md-4 col-form-label">
                                            <b>{t("SPORTS_TYPE")}</b>
                                        </label>
                                        <div className="col-md-7">
                                            <select className="form-control"
                                                name="sportsType"
                                                ref={register}
                                                onChange={(e) => setSelectedSportsType(parseInt(e.target.value))}
                                                value={selectedSportsType}>
                                                <option value={0}>{"0 (All)"}</option>
                                                <option value={1}>{"1 (Soccer Only)"}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-8">
                                    <div className="form-group row m-b-15">
                                        <label className="col-md-4 col-form-label">
                                            <b>{t("MARKET_TYPE")}</b>
                                        </label>
                                        <div className="col-md-7">
                                            <select className="form-control"
                                                name="marketType"
                                                ref={register}
                                                onChange={(e) => setSelectedMarketType(parseInt(e.target.value))}
                                                value={selectedMarketType}>
                                                <option value={0}>{"0 (All)"}</option>
                                                <option value={40}>{"40 (Mix Parlay)"}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-8">
                                    <div className="form-group row m-b-15">
                                        <label className="col-md-4 col-form-label">
                                            <b>{t("PRODUCT_NAME")}</b>
                                        </label>
                                        <div className="col-md-7">
                                            <select className="form-control"
                                                name="productName"
                                                ref={register}
                                                onChange={(e) => setSelectedProductName(e.target.value)}
                                                value={selectedProductName}>
                                                <option value={"SBO Sports"}>{"SBO Sports"}</option>
                                                <option value={"SABA Sports"}>{"SABA Sports"}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-8">
                                    <div className="form-group row m-b-15">
                                        <label className="col-md-4 col-form-label">
                                            <b>{t("SPORTS_BET_LIMIT")}</b>
                                        </label>
                                        <div className="col-md-7" style={{ display: "flex", lineHeight: "2.5" }}>
                                            <div className="col-4">
                                                <textarea
                                                    className="form-control"
                                                    rows="5"
                                                    style={{ marginTop: "10px", width: "275px" }}
                                                    ref={register}
                                                    name="betLimitCode"
                                                    defaultValue={betLimitStr}
                                                    onChange={(e) => {
                                                        setBetLimitStr(e.target.value)
                                                    }}
                                                ></textarea>
                                                {errors.betLimitCode && (
                                                    <div className="invalid-feedback">
                                                        {t(errors.betLimitCode.message)}
                                                    </div>
                                                )}
                                                {t("MIN") + ":" + defaultSplitSymbol + t("MAX") + ":" + defaultSplitSymbol + t("MAX_PER_MATCH")}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-8">
                                    <div className="form-group row m-b-15">
                                        <label className="col-md-4 col-form-label">
                                            <b>{t("MEMBER_PRODUCT_USERNAME")}</b>
                                        </label>
                                        <div className="col-md-7" style={{ display: "flex", lineHeight: "2.5" }}>
                                            <div className="bet-limit-username-wrapper col-4">
                                                {!stringIsNullOrEmpty(mpUsername) && <><input ref={_copyRef} className="bet-limit-username" readOnly value={mpUsername} />
                                                    <div style={{ marginLeft: "25px", cursor: "pointer" }} onClick={() => copyUsername()}>
                                                        <Icon path={mdiContentCopy}
                                                            size={1}
                                                            color="black"
                                                        />
                                                    </div></>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <hr />
                                <div className="row">
                                    <div className="col-lg-3">
                                        <div className="form-group">
                                            <button type="submit" className="btn btn-primary">
                                                {t("SUBMIT")}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </PanelBody>
                    </Panel>}
                </div>
            </div>
        </div>
    )
}

export default withRouter(MemberSportsBetLimit);