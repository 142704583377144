import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { Panel, PanelBody } from "./../../components/panel/panel.jsx";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import NavigationButton from "../../components/constantComponent/button/NavigationButton";
import {
  ApiKey,
  ApiUrl,
  LanguageKey,
  AllVipOption,
  TransactionType
} from "../../util/Constant";
import { useForm, Controller } from "react-hook-form";
import { useLocation } from "react-router-dom";
import {
  stringIsNullOrEmpty,
  createMultiPartFormBody,
  imagePathToFileObject,
} from "../../util/Util";
import { setBusy, setIdle, showMessage } from "../../redux/AppAction";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import classnames from "classnames";
import { fetch } from "whatwg-fetch";


/// <summary>
/// Author : -
/// </summary>

const BannerDetail = (props) => {
  const _DETAIL_TAB = "1";
  const _LANGUAGE_TAB = "2";
  const { t } = useTranslation(LanguageKey._PRIMARY);
  let _history = useHistory();
  const _location = useLocation();
  const _dispatch = useDispatch();

  const {
    register:formRegister,
    unregister ,
    handleSubmit: handleSubmit,
    errors,
    setError,
    control,
    setValue,
  } = useForm();

  const [accountHolderName, setAccountHolderName] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [bankId, setBankId] = useState("");
  const [bankList, setBankList] = useState([]);
  const [stateId, setStateId] = useState("");
  const [memberBankDetail, setMemberBankDetail] = useState([]);
  const [status, setStatus] = useState(false);

  /// <summary>
  /// Author : -
  /// </summary>
  useEffect(() => {
    init();
  }, []);

  const [activeTab, setActiveTab] = useState(_DETAIL_TAB);

  /// <summary>
  /// Author : -
  /// </summary>
  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  /// <summary>
  /// Author : -
  /// </summary>
  async function init() {
    if (_location.state) {
      var apiUrl = ApiUrl._API_GET_MEMBER_BANK_ACCOUNT_BY_ID;
      let stateId = _location.state.id || _location.state.refId;
      apiUrl += "?id=" + stateId;
      await fetch(apiUrl, {
        method: ApiKey._API_GET,
        headers: {
          "Content-Type": ApiKey._API_FORM_URLENCODED,
          "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
        },
      })
        .then((response) => response.json())
        .then(async (responseJson) => {
          if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            console.log(responseJson[ApiKey._API_DATA_KEY])
            setMemberBankDetail(responseJson[ApiKey._API_DATA_KEY])
            if (_location.state.id) {
              setStateId(responseJson[ApiKey._API_DATA_KEY][0]["id"]);
            } else if (_location.state.refId) {
              setStateId(_location.state.refId);
            }
            console.log(responseJson[ApiKey._API_DATA_KEY][0]["bank"]["id"])
            setBankId(responseJson[ApiKey._API_DATA_KEY][0]["bank"]["id"])
            setAccountHolderName(responseJson[ApiKey._API_DATA_KEY][0]["accountHolderName"]);
            setAccountNumber(responseJson[ApiKey._API_DATA_KEY][0]["accountNumber"]);
            setStatus(responseJson[ApiKey._API_DATA_KEY][0]["status"]);
            setValue("bankId", responseJson[ApiKey._API_DATA_KEY][0]["bank"]["id"]);
            unregister("content");
          }
        });
    }

    await fetch(ApiUrl._API_GET_BANK_BY_TRANSACTION_TYPE + "?transactionTypeId=" + TransactionType._DEPOSIT, {
      method: ApiKey._API_GET,
      headers: {
        "Content-Type": ApiKey._API_FORM_URLENCODED,
        "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
          const bankList = [
          ];
          responseJson[ApiKey._API_DATA_KEY].forEach(function (bank) {
            console.log(bank.id)
            bankList.push({ label: bank.bankName + " , " + (bank.status ? "Active" : "Inactive"), value: bank.id });
          });

          setBankList(bankList);
        }
      });
  }

  /// <summary>
  /// Author : -
  /// </summary>
  const submitForm = async (data, e) => {
    let params = {
      Id: stateId,
      bankId: data.bankId,
      accountNumber: data.accountNumber,
      status: data.status?1:0
    };

    let formBody = createMultiPartFormBody(params);
    _dispatch(setBusy());

    fetch(ApiUrl._API_UPDATE_MEMBER_BANK_ACCOUNT_BY_ID, {
      method: ApiKey._API_POST,
      headers: {
        "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
      },
      body: formBody,
    })
      .then((response) => response.json())
      .then((responseJson) => {
        _dispatch(setIdle());
        _dispatch(
          showMessage(
            responseJson[ApiKey._API_SUCCESS_KEY],
            t(responseJson[ApiKey._API_MESSAGE_KEY])
          )
        );

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
          setStateId(responseJson[ApiKey._API_DATA_KEY]["id"]);

          _history.replace({
            pathname: _location.pathname,
            state: {
              id: responseJson[ApiKey._API_DATA_KEY]["id"],
            },
          });
        }
      });
  };

  return (
    <div>
      <h1 className="page-header">
        {stateId ? t("EDIT_MEMBER_BANK_DETAIL") : t("ADD_MEMBER_BANK_ACCOUNT")}
        <NavigationButton history={_history} />
      </h1>
      {stateId && (
        <Nav tabs>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === _DETAIL_TAB })}
              onClick={() => {
                toggle(_DETAIL_TAB);
              }}
            >
              {t("DETAIL")}
            </NavLink>
          </NavItem>
        </Nav>
      )}
      <TabContent activeTab={activeTab}>
        <TabPane tabId={_DETAIL_TAB}>
          <div className="row">
            <div className="col-xl-12">
              <Panel>
                <PanelBody>
                  <form onSubmit={handleSubmit(submitForm)}>
                    <div className="row">
                      <div className="col-lg-8">
                        <div className="form-group row m-b-15">
                          <label className="col-md-4 col-form-label">
                            <b>{t("BANK")}</b>
                          </label>
                          <div className="col-md-7">
                            <Controller
                              control={control}
                              name="bankId"
                              render={({ onChange, value }) => (
                                <Select
                                  options={bankList}
                                  value={bankList.filter(
                                    (option) => option.value == bankId
                                  )}
                                  onChange={(e) => {
                                    onChange(e.value);
                                    setBankId(e.value)

                                  }}
                                />
                              )}
                              rules={{ required: "PLEASE_SELECT_BANK" }}
                            />
                            {errors.bankId && (
                              <div className="invalid-feedback">
                                {t(errors.bankId.message)}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-8">
                        <div className="form-group row m-b-15">
                          <label className="col-md-4 col-form-label">
                            <b>{t("ACCOUNT_NUMBER")}</b>
                          </label>
                          <div className="col-md-7">
                            <input
                              type="text"
                              name="accountNumber"
                              ref={formRegister({ required: "PLEASE_ENTER_ACCOUNT_NUMBER_" })}
                              className="form-control m-b-5"
                              defaultValue={accountNumber}
                              placeholder={t("12345678")}
                            />
                            {errors.accountNumber && (
                              <div className="invalid-feedback">
                                {t(errors.accountNumber.message)}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-8">
                        <div className="form-group row m-b-15">
                          <label className="col-md-4 col-form-label">
                            <b>{t("ACCOUNT_HOLDER_NAME")}</b>
                          </label>
                          <div className="col-md-7">
                            <input
                              type="text"
                              name="accountHolderName"
                              ref={formRegister}
                              className="form-control m-b-5"
                              defaultValue={accountHolderName}
                              placeholder={t("Eg: BenLiz")}
                              disabled
                            />
                            {errors.accountHolderName && (
                              <div className="invalid-feedback">
                                {t(errors.accountHolderName.message)}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-8">
                        <div className="form-group row m-b-15">
                          <label className="col-md-4 col-form-label">
                            <b>{t("STATUS")}</b>
                          </label>
                          <div className="col-md-7">
                            <div className="switcher">
                              <input
                                type="checkbox"
                                name="status"
                                id="status"
                                onChange={(e) => setStatus(e.target.checked)}
                                value={status}
                                checked={status}
                                ref={formRegister}
                                defaultValue={status }
                              />
                              <label htmlFor="status"></label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <hr />
                    <div className="row">
                      <div className="col-lg-3">
                        <div className="form-group">
                          <button type="submit" className="btn btn-primary">
                            {t("SUBMIT")}
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </PanelBody>
              </Panel>
            </div>
          </div>
        </TabPane>
      </TabContent>
    </div>
  );
};

export default withRouter(BannerDetail);
