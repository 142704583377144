import React, { useState, useEffect, useMemo } from "react";
import { withRouter } from "react-router-dom";
import { Panel, PanelBody } from "./../../components/panel/panel.jsx";
import { useHistory } from "react-router-dom";
import NavigationButton from "../../components/constantComponent/button/NavigationButton";
import { ApiKey, ApiUrl, LanguageKey } from "../../util/Constant";
import { createFormBody } from "../../util/Util";
import { useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { showMessage } from "../../redux/AppAction";
import { useTranslation } from "react-i18next";
import SweetAlert from "react-bootstrap-sweetalert";
import ApiEngine from "../../util/ApiEngine";

/// <summary>
/// Author : -
/// </summary>

const CompanyProductAccountInfo = (props) => {
    const { t } = useTranslation(LanguageKey._PRIMARY);
    let _history = useHistory();
    const _location = useLocation();
    const _dispatch = useDispatch();
    const { register, handleSubmit, errors, setValue, watch } = useForm();
    const [fightPercent, setFightPercent] = useState("");
    const [hotGameCount, setHotGameCount] = useState("");
    const [accountProductId, setAccountProductId] = useState("");
    const [useProxy, setUseProxy] = useState(false);
    const [useProxyAuthentication, setUseProxyAuthentication] = useState(false);
    const [proxyHost, setProxyHost] = useState("");
    const [proxyPort, setProxyPort] = useState(0);
    const [proxyUsername, setProxyUsername] = useState("");
    const [proxyPassword, setProxyPassword] = useState("");
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [isNetWin, setIsNetWin] = useState(false);
    const [maximumBonus, setMaximumBonus] = useState(0);
    const [maximumCommission, setMaximumCommission] = useState(0);
    const [bonus, setBonus] = useState();
    const [rank, setRank] = useState();
    const [commission, setCommission] = useState();
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [accountProduct, setAccountProduct] = useState([]);
    const [playerComm, setPlayerComm] = useState(0);
    const [referrerComm, setReferrerCom] = useState(0);
    const [referrerUplineComm, setReferrerUplineComm] = useState(0);

    const _MIN_PORT_RANGE = 0;
    const _MAX_PORT_RANGE = 65535;
    const _PASSWORD_ICON = {
        position: "absolute",
        top: "37.5px",
        right: "20px",
        cursor: "pointer",
    };

    /// <summary>
    /// Author : -
    /// </summary>
    const generateFightOptions = () => {
        let options = [];
        for (var i = 100; i >= 0; i--) {
            options.push(<option value={i}>{i}</option>);
        }
        return options;
    };

    const _fightOptions = useMemo(() => generateFightOptions(), []);

    /// <summary>
    /// Author : -
    /// </summary>
    async function init() {
        if (_location.state) {
            var apiUrl = ApiUrl._API_GET_USER_ASSIGNMENT_DETAIL;
            apiUrl += "?accountProductId=" + _location.state.id;
            let responseJson = await ApiEngine.get(apiUrl);
            console.log(responseJson[ApiKey._API_DATA_KEY])
            if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                setAccountProduct(responseJson[ApiKey._API_DATA_KEY]);
                setAccountProductId(responseJson[ApiKey._API_DATA_KEY]["id"]);
                setHotGameCount(responseJson[ApiKey._API_DATA_KEY]["hotGameCount"]);
                setFightPercent(responseJson[ApiKey._API_DATA_KEY]["fightPercent"]);
                setUseProxy(responseJson[ApiKey._API_DATA_KEY]["useProxy"]);
                setUseProxyAuthentication(
                    responseJson[ApiKey._API_DATA_KEY]["useProxyAuthentication"]
                );
                setProxyHost(responseJson[ApiKey._API_DATA_KEY]["proxyHost"]);
                setProxyPort(responseJson[ApiKey._API_DATA_KEY]["proxyPort"]);
                setProxyUsername(responseJson[ApiKey._API_DATA_KEY]["proxyUsername"]);
                setProxyPassword(responseJson[ApiKey._API_DATA_KEY]["proxyPassword"]);
                setIsNetWin(responseJson[ApiKey._API_DATA_KEY]["isNetWinForTurnover"]);
                setBonus(responseJson[ApiKey._API_DATA_KEY]["bonus"]);
                setRank(responseJson[ApiKey._API_DATA_KEY]["rank"]);
                setCommission(responseJson[ApiKey._API_DATA_KEY]["commission"]);
                setValue("bonus", responseJson[ApiKey._API_DATA_KEY]["bonus"]);
                setValue("rank", responseJson[ApiKey._API_DATA_KEY]["rank"]);
                setValue(
                    "commission",
                    responseJson[ApiKey._API_DATA_KEY]["commission"]
                );
                setMaximumBonus(responseJson[ApiKey._API_DATA_KEY]["product"]["bonus"]);
                setMaximumCommission(
                    responseJson[ApiKey._API_DATA_KEY]["product"]["commission"]
                );
                setPlayerComm(responseJson[ApiKey._API_DATA_KEY]["playerComm"]);
                setReferrerCom(responseJson[ApiKey._API_DATA_KEY]["referrerComm"]);
                setReferrerUplineComm(responseJson[ApiKey._API_DATA_KEY]["referrerUplineComm"])
            }
        }
    }

    /// <summary>
    /// Author : -
    /// </summary>
    useEffect(() => {
        init();
    }, []);

    /// <summary>
    /// Author : -
    /// </summary>
    const submitForm = async (data, e) => {
        let params = {
            fightPercent: data.fightPercent,
            hotGameCount: data.hotGameCount,
            accountProductId: _location.state["id"],
            useProxy: data.useProxy,
            proxyHost: data.proxyHost,
            proxyPort: data.proxyPort,
            useProxyAuthentication: data.useProxyAuthentication,
            proxyUsername: data.proxyUsername,
            proxyPassword: data.proxyPassword,
            isNetWinForTurnover: isNetWin,
            bonus: data.bonus,
            rank: data.rank,
            commission: data.commission,
            playerComm: data.playerComm,
            referrerComm: data.referrerComm,
            referrerUplineComm: data.referrerUplineComm
        };
        let formBody = createFormBody(params);
        let responseJson = await ApiEngine.post(
            ApiUrl._API_UPDATE_USER_ASSIGNMENT_DETAIL,
            formBody
        );
        _dispatch(
            showMessage(
                responseJson[ApiKey._API_SUCCESS_KEY],
                t(responseJson[ApiKey._API_MESSAGE_KEY])
            )
        );

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            setBonus(watch("bonus"));
            setCommission(watch("commission"));
        }
    };

    /// <summary>
    /// Author : -
    /// </summary>
    function preSubmit() {
        if (bonus != watch("bonus") || commission != watch("commission")) {
            setShowConfirmation(true);
        } else {
            handleSubmit(submitForm)();
        }
    }

    return (
        <div>
            {showConfirmation && (
                <SweetAlert
                    warning
                    showCancel
                    confirmBtnText={t("CONFIRM")}
                    cancelBtnText={t("CANCEL")}
                    confirmBtnBsStyle="danger"
                    title={t("BONUS_COMMISSION_CHANGE_CONFIRMATION")}
                    onConfirm={() => {
                        setShowConfirmation(false);
                        handleSubmit(submitForm)();
                    }}
                    onCancel={() => {
                        setShowConfirmation(false);
                    }}
                ></SweetAlert>
            )}
            <h1 className="page-header">
                {t("EDIT_PRODUCT_ACCOUNT_INFORMATION")}
                <NavigationButton history={_history} />
            </h1>
            <div className="row">
                <div className="col-xl-12">
                    <Panel>
                        <PanelBody>
                            <form onSubmit={handleSubmit(submitForm)}>
                                <div className="row">
                                    <div className="col-lg-4">
                                        <label>
                                            <b>{t("FIGHT_LEVEL")}</b>
                                        </label>
                                        <select
                                            className="form-control"
                                            name="fightPercent"
                                            ref={register}
                                            onChange={(e) => setFightPercent(e.target.value)}
                                            value={fightPercent}
                                        >
                                            {_fightOptions}
                                        </select>
                                    </div>
                                    <div className="col-lg-4">
                                        <label>
                                            <b>{t("HOT_GAME_COUNT")}</b>
                                        </label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            placeholder={t("PLEASE_ENTER_GAME_COUNT")}
                                            name="hotGameCount"
                                            defaultValue={hotGameCount}
                                            ref={register({
                                                required: "PLEASE_ENTER_GAME_COUNT",
                                                min: {
                                                    value: 0,
                                                    message: "PLEASE_ENTER_VALUE_NOT_LESS_THAN_ZERO",
                                                },
                                                max: {
                                                    value: 100,
                                                    message: "PLEASE_ENTER_VALUE_NOT_MORE_THAN_100",
                                                },
                                            })}
                                        />
                                        {errors.hotGameCount && (
                                            <div className="invalid-feedback">
                                                {t(errors.hotGameCount.message)}
                                            </div>
                                        )}
                                    </div>
                                    <div className="col-lg-4">
                                        <label style={{ display: "flex" }}>
                                            <b>{t("NET_WIN_FOR_TURNOVER")}</b>
                                        </label>
                                        <div className="switcher">
                                            <input
                                                type="checkbox"
                                                name="isNetWin"
                                                id="isNetWin"
                                                onChange={(e) => setIsNetWin(e.target.checked)}
                                                value={false}
                                                checked={isNetWin}
                                                ref={register}
                                            />
                                            <label htmlFor="isNetWin"></label>
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="form-group">
                                            <label>
                                                <b>{t("COMMISSION")} (%)</b>
                                            </label>
                                            <br />
                                            <input
                                                type="number"
                                                name="commission"
                                                ref={register({
                                                    required: "PLEASE_ENTER_COMMISSION",
                                                    min: {
                                                        value: 0,
                                                        message: "PLEASE_ENTER_VALUE_NOT_LESS_THAN_ZERO",
                                                    },
                                                    max: {
                                                        value: 100,
                                                        message: "PLEASE_ENTER_VALUE_NOT_MORE_THAN_100",
                                                    },
                                                    max: {
                                                        value: maximumCommission,
                                                        message: "PLEASE_ENTER_VALUE_NOT_MORE_THAN",
                                                    },
                                                })}
                                                className="form-control m-b-5"
                                                placeholder={t("PLEASE_ENTER_COMMISSION")}
                                                defaultValue={0}
                                            />
                                            {errors.commission && (
                                                <div className="invalid-feedback">
                                                    {t(errors.commission.message, {
                                                        value: maximumCommission,
                                                    })}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="form-group">
                                            <label>
                                                <b>{t("BONUS")} (%)</b>
                                            </label>
                                            <br />
                                            <input
                                                type="number"
                                                name="bonus"
                                                ref={register({
                                                    required: "PLEASE_ENTER_BONUS",
                                                    min: {
                                                        value: 0,
                                                        message: "PLEASE_ENTER_VALUE_NOT_LESS_THAN_ZERO",
                                                    },
                                                    max: {
                                                        value: 100,
                                                        message: "PLEASE_ENTER_VALUE_NOT_MORE_THAN_100",
                                                    },
                                                    max: {
                                                        value: maximumBonus,
                                                        message: "PLEASE_ENTER_VALUE_NOT_MORE_THAN",
                                                    },
                                                })}
                                                className="form-control m-b-5"
                                                placeholder={t("PLEASE_ENTER_BONUS")}
                                                defaultValue={0}
                                            />
                                            {errors.bonus && (
                                                <div className="invalid-feedback">
                                                    {t(errors.bonus.message, { value: maximumBonus })}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="form-group">
                                            <label>
                                                <b>{t("RANK")}</b>
                                            </label>
                                            <br />
                                            <input
                                                type="number"
                                                className="form-control"
                                                placeholder={t("PLEASE_ENTER_RANK")}
                                                name="rank"
                                                defaultValue={rank}
                                                ref={register({
                                                    required: "PLEASE_ENTER_RANK",
                                                    min: {
                                                        value: 0,
                                                        message: "PLEASE_ENTER_VALUE_NOT_LESS_THAN_ZERO",
                                                    },
                                                    max: {
                                                        value: 100,
                                                        message: "PLEASE_ENTER_VALUE_NOT_MORE_THAN_100",
                                                    },
                                                })}
                                            />
                                            {errors.rank && (
                                                <div className="invalid-feedback">
                                                    {t(errors.rank.message)}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>

                                <hr />
                                <div className="row">
                                    <div className="col-lg-2">
                                        <h4>{t("PROXY_SETTINGS")}</h4>
                                    </div>
                                    <div className="col-lg-2">
                                        <div className="switcher">
                                            <input
                                                type="checkbox"
                                                name="useProxy"
                                                id="useProxy"
                                                onChange={(e) => setUseProxy(e.target.checked)}
                                                value={useProxy}
                                                checked={useProxy}
                                                ref={register}
                                            />
                                            <label htmlFor="useProxy"></label>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-lg-4">
                                        <label>
                                            <b>{t("PROXY_HOST")}</b>
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder={t("PLEASE_ENTER_PROXY_HOST")}
                                            name="proxyHost"
                                            defaultValue={proxyHost}
                                            readOnly={!useProxy}
                                            ref={register}
                                        />
                                    </div>

                                    <div className="col-lg-4">
                                        <label>
                                            <b>{t("PROXY_PORT")}</b>
                                        </label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            placeholder={t("PLEASE_ENTER_PROXY_PORT")}
                                            name="proxyPort"
                                            defaultValue={proxyPort}
                                            readOnly={!useProxy}
                                            ref={register({
                                                pattern: {
                                                    value: /^\d+$/,
                                                    message: "INVALID_PORT_NUMBER",
                                                },
                                                min: {
                                                    value: _MIN_PORT_RANGE,
                                                    message: "INVALID_PORT_NUMBER",
                                                },
                                                max: {
                                                    value: _MAX_PORT_RANGE,
                                                    message: "INVALID_PORT_NUMBER",
                                                },
                                            })}
                                        />
                                        {errors.proxyPort && (
                                            <div className="invalid-feedback">
                                                {t(errors.proxyPort.message)}
                                            </div>
                                        )}
                                    </div>
                                </div>

                                <br />
                                <div className="row">
                                    <div className="col-lg-2">
                                        <h5>{t("USE_PROXY_AUTHENTICATION")}</h5>
                                    </div>
                                    <div className="col-lg-2">
                                        <div className="switcher">
                                            <input
                                                type="checkbox"
                                                name="useProxyAuthentication"
                                                id="useProxyAuthentication"
                                                readOnly={!useProxy}
                                                onChange={(e) =>
                                                    setUseProxyAuthentication(e.target.checked)
                                                }
                                                value={useProxyAuthentication}
                                                checked={useProxyAuthentication}
                                                ref={register}
                                            />
                                            <label htmlFor="useProxyAuthentication"></label>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-4">
                                        <label>
                                            <b>{t("PROXY_USERNAME")}</b>
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder={t("PLEASE_ENTER_PROXY_USERNAME")}
                                            name="proxyUsername"
                                            defaultValue={proxyUsername}
                                            readOnly={!(useProxy && useProxyAuthentication)}
                                            ref={register}
                                        />
                                    </div>

                                    <div className="col-lg-4">
                                        <label>
                                            <b>{t("PROXY_PASSWORD")}</b>
                                        </label>
                                        <input
                                            type={passwordVisible ? "text" : "password"}
                                            className="form-control"
                                            placeholder={t("PLEASE_ENTER_PROXY_PASSWORD")}
                                            name="proxyPassword"
                                            defaultValue={proxyPassword}
                                            readOnly={!(useProxy && useProxyAuthentication)}
                                            ref={register}
                                        />
                                        <i
                                            className={
                                                passwordVisible ? "fa fa-eye" : "fa fa-eye-slash"
                                            }
                                            style={_PASSWORD_ICON}
                                            onClick={() => {
                                                setPasswordVisible(!passwordVisible);
                                            }}
                                        ></i>
                                    </div>
                                </div>

                                <hr />

                                <div className="row">
                                    <div className="col-lg-4">
                                        <div className="form-group">
                                            <label>
                                                <b>{t("PLAYER_COMMISSION")} (%)</b>
                                            </label>
                                            <br />
                                            <input
                                                type="number"
                                                name="playerComm"
                                                step=".01"
                                                ref={register({
                                                    required: "PLEASE_ENTER_PLAYER_COMMISSION",
                                                    min: {
                                                        value: 0,
                                                        message: "PLEASE_ENTER_VALUE_NOT_LESS_THAN_ZERO",
                                                    },
                                                    max: {
                                                        value: 100,
                                                        message: "PLEASE_ENTER_VALUE_NOT_MORE_THAN_100",
                                                    },
                                                    // max: {
                                                    //     value: maximumCommission,
                                                    //     message: "PLEASE_ENTER_VALUE_NOT_MORE_THAN",
                                                    // },
                                                })}
                                                className="form-control m-b-5"
                                                placeholder={t("PLEASE_ENTER_COMMISSION")}
                                                value={playerComm}
                                                onChange={(e) => setPlayerComm(e.target.value)}
                                            />
                                            {errors.commission && (
                                                <div className="invalid-feedback">
                                                    {t(errors.commission.message, {
                                                        value: maximumCommission,
                                                    })}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="form-group">
                                            <label>
                                                <b>{t("REFERRER_COMMISSION")} (%)</b>
                                            </label>
                                            <br />
                                            <input
                                                type="number"
                                                name="referrerComm"
                                                step=".01"
                                                ref={register({
                                                    required: "PLEASE_ENTER__PLAYER_COMMISSION",
                                                    min: {
                                                        value: 0,
                                                        message: "PLEASE_ENTER_VALUE_NOT_LESS_THAN_ZERO",
                                                    },
                                                    max: {
                                                        value: 100,
                                                        message: "PLEASE_ENTER_VALUE_NOT_MORE_THAN_100",
                                                    },
                                                    // max: {
                                                    //     value: maximumCommission,
                                                    //     message: "PLEASE_ENTER_VALUE_NOT_MORE_THAN",
                                                    // },
                                                })}
                                                className="form-control m-b-5"
                                                placeholder={t("PLEASE_ENTER_COMMISSION")}
                                                value={referrerComm}
                                                onChange={(e) => setReferrerCom(e.target.value)}
                                            />
                                            {errors.bonus && (
                                                <div className="invalid-feedback">
                                                    {t(errors.bonus.message, { value: maximumBonus })}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="form-group">
                                            <label>
                                                <b>{t("REFERRER_UPLINE_COMMISSION")} (%)</b>
                                            </label>
                                            <br />
                                            <input
                                                type="number"
                                                name="referrerUplineComm"
                                                step=".01"
                                                ref={register({
                                                    required: "PLEASE_ENTER__PLAYER_COMMISSION",
                                                    min: {
                                                        value: 0,
                                                        message: "PLEASE_ENTER_VALUE_NOT_LESS_THAN_ZERO",
                                                    },
                                                    max: {
                                                        value: 100,
                                                        message: "PLEASE_ENTER_VALUE_NOT_MORE_THAN_100",
                                                    },
                                                    // max: {
                                                    //     value: maximumCommission,
                                                    //     message: "PLEASE_ENTER_VALUE_NOT_MORE_THAN",
                                                    // },
                                                })}
                                                className="form-control m-b-5"
                                                placeholder={t("PLEASE_ENTER_COMMISSION")}
                                                value={referrerUplineComm}
                                                onChange={(e) => setReferrerUplineComm(e.target.value)}
                                            />
                                            {errors.bonus && (
                                                <div className="invalid-feedback">
                                                    {t(errors.bonus.message, { value: maximumBonus })}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    </div>
                                <hr />
                                <div className="row">
                                    <div className="col-lg-3">
                                        <div className="form-group">
                                            <button
                                                type="button"
                                                onClick={() => preSubmit()}
                                                className="btn btn-primary"
                                            >
                                                {t("SUBMIT")}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </PanelBody>
                    </Panel>
                </div>
            </div>
        </div>
    );
};

export default withRouter(CompanyProductAccountInfo);
