import React, { useState, useEffect, useContext } from "react";
import { withRouter } from "react-router-dom";
import Accordion from 'react-bootstrap/Accordion';
import { Panel, PanelBody } from "./../../components/panel/panel.jsx";
import { useTranslation } from "react-i18next";
import { LanguageKey } from "../../util/Constant.js";

const FAQHelp = (props) => {
  const { t, i18n } = useTranslation(LanguageKey._PRIMARY);
  const [activeEventKey0, setActiveEventKey0] = useState(false);
  const [activeEventKey1, setActiveEventKey1] = useState(false);
  const [activeEventKey2, setActiveEventKey2] = useState(false);
  const [activeEventKey3, setActiveEventKey3] = useState(false);
  const [activeEventKey4, setActiveEventKey4] = useState(false);
  const [activeEventKey5, setActiveEventKey5] = useState(false);
  const [activeEventKey6, setActiveEventKey6] = useState(false);
  const [activeEventKey7, setActiveEventKey7] = useState(false);

  return (
    <div id="faq">
      <h1 className="page-header" style={{ marginLeft: "20px" }}>
        {t("FAQ")}
      </h1>
      <Panel>
        <div className="row">
          <div className="col-lg-12">
            <PanelBody>
              <Accordion defaultActiveKey={['-1']}>
                <Accordion.Item eventKey="0" onClick={() => setActiveEventKey0(!activeEventKey0)}>
                  <Accordion.Header><div>Cannot approve deposit/withdrawal</div><div style={{ fontSize: "20px", fontWeight: "bold", transform: activeEventKey0 ? "rotate(90deg)" : "unset" }}>{">"}</div></Accordion.Header>
                  <Accordion.Body>
                    1) Please check "Manage Transaction Queue" (2.9) to see if the player is stuck in queue, then clear it. Please wait a few minutes if the player is not showing in the queue page. <br /><br />
                  </Accordion.Body>
                </Accordion.Item>
                <hr style={{ margin: "0" }} />
                <Accordion.Item eventKey="1" onClick={() => setActiveEventKey1(!activeEventKey1)}>
                  <Accordion.Header><div>Player's bet(s) missing or pending</div><div style={{ fontSize: "20px", fontWeight: "bold", transform: activeEventKey1 ? "rotate(90deg)" : "unset" }}>{">"}</div></Accordion.Header>
                  <Accordion.Body>
                    1) Log on to Vendor's Backoffice to check if the player's bet is also pending over there.<br /><br />
                    2) If not, contact the IT team about the issue.
                  </Accordion.Body>
                </Accordion.Item>
                <hr style={{ margin: "0" }} />
                <Accordion.Item eventKey="2" onClick={() => setActiveEventKey2(!activeEventKey2)}>
                  <Accordion.Header><div>Set MasterProduct to UM (UM = Under Maintenance)</div><div style={{ fontSize: "20px", fontWeight: "bold", transform: activeEventKey2 ? "rotate(90deg)" : "unset" }}>{">"}</div></Accordion.Header>
                  <Accordion.Body>
                    1) Go to "Products Page" (10.3).<br /><br />
                    2) Click on Game UM button to set it on/off.<br /><br />
                  </Accordion.Body>
                </Accordion.Item>
                <hr style={{ margin: "0" }} />
                <Accordion.Item eventKey="3" onClick={() => setActiveEventKey3(!activeEventKey3)}>
                  <Accordion.Header><div>Add promotion banners (optional)</div><div style={{ fontSize: "20px", fontWeight: "bold", transform: activeEventKey3 ? "rotate(90deg)" : "unset" }}>{">"}</div></Accordion.Header>
                  <Accordion.Body>
                    1) Go to "Banner" Page (12.2).<br /><br />
                    2) Click on "Add Banner" button at the top right of the page.<br /><br />
                    3) Insert all relevant details and click submit.<br /><br />
                    4) There is currently 1 type of banner location to select from.<br /><br />
                    5) For banner in Mobile Dashboard Banner, location = Dashboard Banner Popup.<br /><br />
                    6) After create banner successfully, click on language tab.<br /><br />
                    7) Click on "Language" button and insert relevant details to create banner for different language.<br /><br />
                  </Accordion.Body>
                </Accordion.Item>
                <hr style={{ margin: "0" }} />
                <Accordion.Item eventKey="5" onClick={() => setActiveEventKey5(!activeEventKey5)}>
                  <Accordion.Header><div>Create or update product promotion</div><div style={{ fontSize: "20px", fontWeight: "bold", transform: activeEventKey5 ? "rotate(90deg)" : "unset" }}>{">"}</div></Accordion.Header>
                  <Accordion.Body>
                    1) Go to "Manage Deposit Promotion" (11.17). <br /><br />
                    2) Click on "Add Promotion", fill in all the details then submit. <br /><br />
                    3) After submit successfully, click on "Add Product" at the bottom. <br /><br />
                    4) Select the product to apply the new promotion. <br /><br />
                  </Accordion.Body>
                </Accordion.Item>
                <hr style={{ margin: "0" }} />
                <Accordion.Item eventKey="6" onClick={() => setActiveEventKey6(!activeEventKey6)}>
                  <Accordion.Header><div>How to change company logo</div><div style={{ fontSize: "20px", fontWeight: "bold", transform: activeEventKey5 ? "rotate(90deg)" : "unset" }}>{">"}</div></Accordion.Header>
                  <Accordion.Body>
                    1) Go to "Members" (2.1), then find member you want to update (only able to apply on most top upline). <br /><br />
                    2) Select member and click "Edit". <br /><br />
                    3) Update logo by dragging file or select file to upload. <br /><br />
                    4) Click "Submit". <br /><br />
                  </Accordion.Body>
                </Accordion.Item>
                <hr style={{ margin: "0" }} />
                <Accordion.Item eventKey="7" onClick={() => setActiveEventKey7(!activeEventKey7)}>
                  <Accordion.Header><div>How to calculate weekly rescue rebate</div><div style={{ fontSize: "20px", fontWeight: "bold", transform: activeEventKey5 ? "rotate(90deg)" : "unset" }}>{">"}</div></Accordion.Header>
                  <Accordion.Body>
                    1) Go to "Tally Win Lose Report" (7.2), get player's lost amount for specific week. <br /><br />
                    2) Get Player VIP Level. <br /><br />
                    3) Calculate rescue amount (lose amount x rebate percentage). <br /><br />
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </PanelBody>
          </div>
        </div>
      </Panel>
    </div>
  )
}

export default withRouter(FAQHelp);