import React from 'react';
import { LanguageKey } from "../../util/Constant";
import { useTranslation } from 'react-i18next';

const Maintenance = () => {
    const { t } = useTranslation(LanguageKey._PRIMARY);

    return (
        <div className="maintenance-wrapper">
            <div className="brand" style={{ marginBottom: "2rem", fontSize: "1.5rem" }}>
                <b>COMMAND CENTER</b>
			</div>
            <img className="maintenance-img" src={require("../../assets/img/svg/maintenance.svg")} />
            <p>{t("MAINTENANCE_DESC")}</p>
        </div>
    );
};

export default Maintenance;