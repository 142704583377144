import { _IDLE, _BUSY, _ALERT_TYPES, _MEMBER_SEARCH } from './AppAction';
import { stringIsNullOrEmpty } from '../util/Util'

const _INITIAL_STATE = {
    isBusy: false,
    message: "Please Wait",
    alertType: _ALERT_TYPES.NONE,
    responseMessage: '',
    memberSearch: ''
};

var _busyCount = 0;

/// <summary>
/// Author : -
/// </summary>
export default (state = _INITIAL_STATE, action) => {
    switch (action.type) {
        case _BUSY:
            _busyCount++;
            return {
                ...state,
                isBusy: true,
                message: stringIsNullOrEmpty(action.message) ? _INITIAL_STATE.message : action.message
            }
            break;
        case _IDLE:
            _busyCount = Math.max(--_busyCount, 0);
            return {
                ...state,
                isBusy: (_busyCount != 0),
            }
            break;
        case _ALERT_TYPES.SUCCESS:
            return {
                ...state,
                alertType: _ALERT_TYPES.SUCCESS,
                responseMessage: action.message
            };
            break;
        case _ALERT_TYPES.ERROR:
            return {
                ...state,
                alertType: _ALERT_TYPES.ERROR,
                responseMessage: action.message
            };
            break;
        case _ALERT_TYPES.NONE:
            return {
                ...state,
                alertType: _ALERT_TYPES.NONE,
                responseMessage: ''
            };
            break;
        case _MEMBER_SEARCH:
            return {
                ...state,
                memberSearch: stringIsNullOrEmpty(action.value) ? _INITIAL_STATE.memberSearch : action.value
            };
            break;
        default:
            return state;
            break;
    }
};
