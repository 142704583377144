import { _INIT_CONFIG, _UPDATE_CONTENT, _CLEAR_MESSAGE } from './FirebaseAction.js';

const _INITIAL_STATE = {
    key: '',
    dataContent: null,
};

export default (state = _INITIAL_STATE, action) => {
    switch (action.type) {
        case _INIT_CONFIG:
            return {
                ...state
            }
            break;
        case _UPDATE_CONTENT:
            return {
                ...state,
                dataContent: action.dataContent
            }
            break;
        case _CLEAR_MESSAGE:
            return {
                ...state,
                dataContent: null
            }
        default:
            return state;
            break;
    }
};